import React, { useState } from 'react';
import { Modal, Select } from 'antd';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';

function AddSource(props) {
    const { open, setOpen, user, stripeActions, dispatch } = props;
    const { firstName, lastName } = user;
    const [source, setSource] = useState('Card');
    const { Option } = Select;
    const [cardElementError, setCardElementError] = useState('');
    const [cardElementValid, setCardElementValid] = useState(true);
    const stripe = useStripe();
    const elements = useElements();

    const [country, setCountry] = useState('');
    const [currency, setCurrency] = useState('');
    const [accountNumber, setAccountNumber] = useState('');
    const [routingNumber, setRoutingNumber] = useState('');
    const [accountHolderName, setAccountHolderName] = useState('');
    const [accountHolderType, setAccountHolderType] = useState('');

    const submitForm = async () => {
        console.log('getting in', source);
        let obj = {};

        if (source == 'Card') {
            const cardElement = elements.getElement('card');
            console.log('here', cardElement);
            // const paymentMethodReq = await stripe.createPaymentMethod({
            //   type: 'card',
            //   card: cardElement,
            //   billing_details: {
            //     name: firstName.value + ' ' + lastName.value
            //   }
            // })
            // if (paymentMethodReq) {
            //   console.log(paymentMethodReq)
            // }
            const cardToken = await stripe.createToken(cardElement, {
                currency: 'usd'
            });
            console.log('card token', cardToken);
            obj = {
                sourceType: source,
                stripeToken: cardToken.token.id
                // stripePaymentMethod: paymentMethodReq.paymentMethod
            };
        } else if (source == 'Bank Account') {
            obj = {
                country: 'US',
                currency: 'usd',
                accountHolderName: 'Jenny Rosen',
                accountHolderType: 'individual',
                routingNumber: '110000000',
                accountNumber: '000123456789',
                sourceType: source
            };
        }
        let submit = await dispatch(stripeActions.addStripeSource({ obj }));
        if (submit) {
            let obj = {}
            dispatch(stripeActions.getStripeDashboard({ obj }));
            setOpen(false);
        }
    };

    const iframeStyles = {
        base: {
            color: '#000',
            fontSize: '16px',
            iconColor: '#000',
            '::placeholder': {
                color: 'gray'
            }
        },
        invalid: {
            iconColor: '#f14c48',
            color: '#f14c48'
        },
        complete: {
            iconColor: '#cbf4c9'
        }
    };

    const cardElementOpts = {
        iconStyle: 'solid',
        style: iframeStyles,
        hidePostalCode: true
    };
    const handleCardDetailsChange = (cardElement) => {
        console.log(cardElement);
        if (cardElement.error && cardElement.error.message) {
            setCardElementError(cardElement.error.message);
            setCardElementValid(false);
        } else {
            setCardElementError('');
            setCardElementValid(true);
        }
        if (cardElement.empty) {
            setCardElementValid(false);
            setCardElementError(cardElement.error.message);
        }
        // ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };
    return (
        (<Modal
            className='customModal addSource'
            title={`Add Source`}
            open={open}
            onOk={() => setOpen(false)}
            onCancel={() => setOpen(false)}
            footer={null}
        >
            <label>
                Source Type:
                <Select
                    value={source}
                    onChange={(val) => {
                        setSource(val);
                    }}
                >
                    <Option value={'Card'}>Card</Option>
                    <Option value={'Bank Account'}>Bank Account</Option>
                </Select>
            </label>
            {source == 'Bank Account' && (
                <>
                    {/* <label>
            Country:
            <input placeholder='Country' />
          </label>
          <label>
            Currency:
            <input placeholder='Currency' />
          </label> */}
                    <label>
                        Account Number:
                        <input
                            placeholder='Account Number'
                            value={accountNumber}
                            onChange={(e) => setAccountNumber(e.target.value)}
                        />
                    </label>

                    <label>
                        Routing Number:
                        <input
                            placeholder='Routing Number'
                            value={routingNumber}
                            onChange={(e) => setRoutingNumber(e.target.value)}
                        />
                    </label>

                    <label>
                        Account Holder Name:
                        <input
                            placeholder='Account Holder Name'
                            value={accountHolderName}
                            onChange={(e) => setAccountHolderName(e.target.value)}
                        />
                    </label>

                    <label>
                        Account Holder Type:
                        <input
                            placeholder='Account Holder Type'
                            value={accountHolderType}
                            onChange={(e) => setAccountHolderType(e.target.value)}
                        />
                    </label>
                </>
            )}
            {source == 'Card' && (
                <>
                    <label>
                        Card Number:
                        <div className='stripeCont'>
                            <CardElement
                                options={cardElementOpts}
                                onChange={handleCardDetailsChange}
                            />
                        </div>
                    </label>
                </>
            )}
            <button onClick={() => submitForm()}>Save</button>
        </Modal>)
    );
}

export default AddSource;
