import React from 'react';
import { RiComputerLine } from 'react-icons/ri';
import { AiOutlineFilePdf } from 'react-icons/ai';
import { FaRegLightbulb } from 'react-icons/fa';
import { ImCheckboxChecked } from 'react-icons/im';

const DocCaution = () => {
    return (
        <div className='order-caution'>
            <div className='order-tips'>
                <p>Tips for Uploading Documents</p>
                <p>
                    To ensure a successful verification process, please adhere to the following
                    document tips.
                </p>
                <div className='order-tips-grid'>
                    <div className='order-tips-box'>
                        <RiComputerLine style={{ fontSize: '2rem' }} />
                        <p>High Resolution</p>
                        <p>You must scan and save documents in high resolution.</p>
                    </div>
                    <div className='order-tips-box'>
                        <AiOutlineFilePdf style={{ fontSize: '2rem' }} />
                        <p>General Requirements</p>
                        <p>If a document is in color, please scan it in color.</p>
                    </div>
                    <div className='order-tips-box'>
                        <FaRegLightbulb style={{ fontSize: '2rem' }} />
                        <p>File Size and Type</p>
                        <p>Each individual file must be no larger than 2 MB.</p>
                    </div>
                    <div className='order-tips-box'>
                        <ImCheckboxChecked style={{ fontSize: '2rem' }} />
                        <p>Image Quality</p>
                        <p>Ensure that all the information is easily seen or read.</p>
                    </div>
                </div>
            </div>
            <p>Your information is safeguarded by state-of-the-art security measures.</p>
        </div>
    );
};

export default DocCaution;
