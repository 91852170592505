import React from 'react';
import ReactDOM from 'react-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './index.css';
import './App.css';
import App from './App';
import { Provider } from 'react-redux';
import store from './store/index';
import * as serviceWorker from './serviceWorker';
import jwtDecode from 'jwt-decode';
import * as Types from './actions/types';

import { message } from 'antd';

message.config({
    maxCount: 1
});

const token = localStorage.getItem('auth_token');
let isExpired;
if (token) {
    let decode = jwtDecode(token);
    // if(decode.exp < new Date().getTime()){
    //   isExpired = true;
    // }
    // else{
    store.dispatch({
        type: Types.SET_USER,
        payload: {
            user: decode
        }
    });
    // }
    // setAuthToken(token)
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
