import React, { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import { BiRefresh } from 'react-icons/bi';
import * as messagingActions from '@/actions/messagingActions';
import { useDispatch } from 'react-redux';
import '@/assets/css/Messaging.css';

function ChatWindow(props) {
    const { socket, appointment, user } = props;
    const dispatch = useDispatch();
    const conversation = appointment && appointment.conversation;
    const [message, setMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [count, setCount] = useState(0);

    const [limit, setLimit] = useState(10);
    const [conversationId, setConversationId] = useState('');
    const messagesEndRef = useRef(null);
    const messagesStartRef = useRef(null);
    // useEffect(() => {
    //   socket.on('getMessagesAlt', data => setMessages(prev => [...prev, data]))
    //   socket.on('helloBack', data => console.log(data))
    // }, [])

    // const sendMessage = async () =>{
    //   setMessages(prev=>[...prev, {message: message, userId: user._id}])
    //   socket.emit('sendMessageAlt', {message: message, userId: user._id, conversationId: appointment?._id})
    //   setMessage("")
    // }

    const changePage = async () => {
        socket.emit('getMessages', {
            conversationId: conversation?._id,
            limit: limit,
            maxDate: messages[0].createdDate
        });
        console.log(messages[0]);
    };
    const setData = (data) => {
        console.log('received', data);
        if (data.messagesCount) {
            setCount(data.messagesCount);
        }
        setMessages((messages) => [...data?.messages, ...messages]);
        scrollToTop();
    };

    useEffect(() => {
        socket.on('receiveMessage', (data) => {
            console.log('receive count', data);
            setCount(data.messagesCount);
            setMessages((messages) => [...messages, ...data?.message]);
            scrollToBottom();
        });
    }, []);
    useEffect(() => {
        if (conversation?._id) {
            // socket.emit('join', { conversationId: conversation?._id })
            socket.emit('getMessages', {
                conversationId: conversation?._id,
                limit: limit
            });
            socket.on(conversation?._id, setData);
        }
    }, [conversation?._id]);
    const sendMessage = async () => {
        setMessage('');
        console.log(conversation, props)
        let otherUsers = conversation?.users?.map((item) => item.user);
        let receiver = otherUsers.filter((item) => item._id != user._id)[0];
        let obj = {
            message: message,
            sender: user._id,
            senderType: user.userType,
            receiver: receiver._id,
            receiverType: receiver.userType,
            conversation: conversation._id
        };
        await dispatch(messagingActions.sendMessage({ obj, ...props }, props.history));
    };
    const scrollToBottom = () => {
        if (messagesEndRef && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToTop = () => {
        if (messagesStartRef && messagesStartRef.current) {
            messagesStartRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <div className='chatWindow'>
            <div className='head'>Chat</div>
            {messages.length != count ? (
                <div
                    onClick={() => changePage()}
                    style={{
                        padding: 5,
                        color: 'white',
                        backgroundColor: '#000',
                        cursor: 'pointer',
                        display: 'flex',
                        columnGap: 10,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <BiRefresh size={24} />
                    Load More
                </div>
            ) : (
                <div
                    onClick={() => changePage()}
                    style={{
                        padding: 5,
                        color: 'white',
                        backgroundColor: '#000',
                        cursor: 'pointer',
                        display: 'flex',
                        columnGap: 10,
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    You reached the top.
                </div>
            )}
            <div className='chatPanel'>
                <div ref={messagesStartRef} />

                {messages &&
                    messages.map((item, index) => (
                        <div
                            key={index}
                            className={`${
                                item.sender._id == user._id ? 'rightMessage' : 'leftMessage'
                            } message`}
                        >
                            <p data-time={moment(item.createdDate).format('hh:mm A')}>
                                {item.message && item.message}
                            </p>
                        </div>
                    ))}
                <div ref={messagesEndRef} />
            </div>
            <div className='chatInput'>
                <input
                    placeholder='Type your message'
                    value={message}
                    onKeyDown={(e) => {
                        if (e.key === 'Enter' && e.target.value) {
                            sendMessage();
                        }
                    }}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <button onClick={() => sendMessage()}>Send</button>
            </div>
        </div>
    );
}

export default ChatWindow;
