import * as Types from '../actions/types';

const init = {
    dashboard: {
        practiceAreas: 0,
        doctors: 0,
        branches: 0,
        consultantServices: 0,
        roles: 0,
        appointments: 0,
        services: 0,
        appointmentsToday: [],
        pendingApprovals: 0,
        upcomingAppointments: 0,
        cancelledAppointments: 0,
        failedAppointments: 0,
        missedAppointments: 0,
        rejectedAppointments: 0,
        completedAppointments: 0,
        noShowAppointments: 0
    },
    nurses: [],
    nurseSingle: {},
    nurseSingleDoctors: [],
    nurseSingleDoctorsCount: 0,
    createdDoctor: {},
    doctors: [],
    featuredHospitals: [],
    doctorsCount: 0,
    shortDoctorList: [],
    nurseForAppointment: {
        nurse: {},
        timeSlots: [],
        availableDays: [],
        availability: {}
    },
    myDoctors: {
        timeSlots: [],
        availability: {}
    },
    searchResults: {
        nurses: [],
        nursesCount: 0,
        nursesSuggestions: []
    },
    nurseStatCounts: {
        posts: 0,
        likes: 0,
        reviews: 0,
        followers: 0
    },
    lineChart: null,
    pieChart: []
};

const nurseReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_NURSES: {
            return {
                ...state,
                nurses: action.payload.nurses
            };
        }
        case Types.GET_FEATURED_NURSES: {
            return {
                ...state,
                featuredHospitals: action.payload.nurses
            };
        }
        case Types.GET_SINGLE_NURSE: {
            return {
                ...state,
                nurseSingle: action.payload.nurseSingle
            };
        }

   
        case Types.CLEAR_NURSES: {
            return {
                ...state,
                nurses: [],
                nurseSingle: {},
                createdDoctor: {}
            };
        }
        case Types.GET_NURSE_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    nurses: action.payload.nurses,
                    nursesCount: action.payload.nursesCount
                }
            };
        }
        case Types.CLEAR_NURSE_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    nurses: [],
                    nursesCount: 0
                }
            };
        }
        case Types.GET_NURSE_STAT_COUNTS: {
            return {
                ...state,
                nurseStatCounts: {
                    posts: action.payload.counts && action.payload.counts.posts,
                    likes: action.payload.counts && action.payload.counts.likes,
                    reviews: action.payload.counts && action.payload.counts.reviews,
                    followers: action.payload.counts && action.payload.counts.followers
                }
            };
        }
        case Types.GET_NURSE_EXTRACTED_TIME_SLOTS: {
            return {
                ...state,
                nurseForAppointment: {
                    ...state.nurseForAppointment,
                    availableDays: action.payload.availableDays,
                    timeSlots: action.payload.timeSlots,
                    availability: action.payload.availability
                }
            };
        }
        case Types.GET_NURSE_FOR_APPOINTMENT: {
            return {
                ...state,
                nurseForAppointment: {
                    ...state.nurseForAppointment,
                    nurse: action.payload.nurse
                }
            };
        }


        case Types.GET_LINE_CHART_DATA: {
            return {
                ...state,
                lineChart: action.payload.lineChart
            };
        }
        case Types.GET_PIE_CHART_DATA: {
            return {
                ...state,
                pieChart: action.payload.pieChart
            };
        }

        case Types.GET_NURSE_DASHBOARD: {
            return {
                ...state,
                dashboard: action.payload.dashboard
            };
        }
        default:
            return state;
    }
};

export default nurseReducer;
