import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Input, Modal } from 'antd';
import moment from 'moment';

function AssociationModal(props) {
    const {
        data,
        associationModal,
        setAssociationModal,
        setEditAssociation,
        editAssociation,
        branch
    } = props;
    const dateFormat = 'YYYY/MM/DD';
    const [organization, setOrganization] = useState();
    const [date, setDate] = useState('');
    const [status, setStatus] = useState();

    useEffect(() => {
        if (editAssociation) {
            setOrganization(editAssociation.organization);
            setDate(editAssociation.date);
            setStatus(editAssociation.status);
        }
    }, [editAssociation]);

    const handleSave = async () => {
        let profileObj = {
            data: {
                associations: [...data]
            },
            userType: props.auth.user.userType,
            userId: props.auth.user._id
        };
        if (editAssociation) {
            profileObj.data.associations[editAssociation.index] = {
                organization: organization,
                status: status,
                date: date,
                verificationStatus: 'REQUIRED'
            };
        } else {
            profileObj.data.associations = [
                ...data,
                {
                    organization: organization,
                    status: status,
                    date: date,
                    verificationStatus: 'REQUIRED'
                }
            ];
        }
        if (profileObj.data.associations.length === 1) {
            profileObj.data.associations[0].current = true;
        }
        if (branch) {
            let profileObj2 = {
                userType: 'PHARMACY',
                pharmacy: branch.pharmacy,
                branchId: branch._id,
                pharmacist: {
                    ...branch.pharmacist
                },
                minFieldsCompleted: true
            };
            if (profileObj?.data?.associations) {
                profileObj2.pharmacist.associations = [...profileObj.data.associations];
            }
            console.log('got here');
            let saved = await props.actions.saveBranches(profileObj2, props.history);
            if (saved) {
                let obj = {
                    userId: props.auth.userId,
                    populateAvailability: true,
                    userType: props.auth.userType
                };
                await props.branchActions.getBranches({ ...props, obj }, props.history);
                setEditAssociation(null);
                setAssociationModal(false);
            }
            return;
        }
        let saved = await props.actions.saveProfile(profileObj, props.history);
        if (saved) {
            let profileShort = {
                userId: props.auth.user._id,
                userType: props.auth.user.userType
            };
            await props.actions.getProfile(profileShort, props.history);
            setEditAssociation(null);
            setAssociationModal(false);
        }
    };

    return (
        <Modal
            className='customModal '
            title={`${editAssociation ? 'Edit' : 'Create'} Association`}
            open={associationModal}
            onCancel={() => {
                setEditAssociation(null);
                setAssociationModal(false);
            }}
            footer={null}
        >
            <>
                <div className='post-modal-options-grid2'>
                    <label>
                        <p>Organization name:</p>
                        <Input
                            placeholder='Organization Name'
                            value={organization}
                            onChange={(e) => setOrganization(e.target.value)}
                        />
                    </label>

                    <label>
                        <p>Status:</p>
                        <Input
                            placeholder='Status'
                            value={status}
                            onChange={(e) => setStatus(e.target.value)}
                        />
                    </label>
                </div>
                <div className='post-modal-options-grid2'>
                    <label>
                        <p>Date:</p>

                        <DatePicker
                            placeholder='Choose Date'
                            value={date ? moment(date, dateFormat) : ''}
                            onChange={(date, string) => setDate(string)}
                        />
                    </label>
                </div>
                <Button onClick={() => handleSave()} type='primary'>
                    <p>{editAssociation ? 'Update' : 'Add'}</p>
                </Button>
            </>
        </Modal>
    );
}

export default AssociationModal;
