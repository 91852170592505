import React, { useState } from 'react';
import { privacyPolicy } from '@/assets/json/MedicalDocs';
import { useDispatch, useSelector } from 'react-redux';
import DocModalLayout from './DocModalLayout';
import { updateMedicalDocsModal } from '@/actions/generalActions';
import * as Types from '@/actions/types';

const PrivacyPolicy = () => {
    const dispatch = useDispatch();
    const { privacyPolicyModal } = useSelector((state) => state.general);

    const handleClose = () => {
        dispatch(
            updateMedicalDocsModal({
                type: Types.UPDATE_PRIVACY_POLICY_MODAL,
                payload: {
                    privacyPolicyModal: false
                }
            })
        );
    };

    const handleOk = () => handleClose();

    const handleCancel = () => handleClose();
    return (
        <>
            {privacyPolicyModal && (
                <DocModalLayout
                    title={'Privacy Policy'}
                    isOpen={privacyPolicyModal}
                    onCancel={handleCancel}
                    onOk={handleOk}
                    docs={privacyPolicy}
                    lastUpdatedAt={'2023-10-03T00:00:00.000Z'}
                />
            )}
        </>
    );
};

export default PrivacyPolicy;
