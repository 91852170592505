import React, { useState } from 'react';
import Table from '@/components/NewProfile/Shared/Table';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import { Button, Pagination, Space, Tooltip } from 'antd';
import AssociationModal from './AssociationModal';
import HonorModal from './HonorModal';
import EducationModal from './EducationModal';
import ExperienceModal from './ExperienceModal';
import AdmissionModal from './AdmissionModal';
import ConfirmModal from '../../../modals/ConfirmModal';
import { StarOutlined, StarFilled } from '@ant-design/icons';

const CredentialsTable = (props) => {
    const {
        data,
        credentialType,
        limit,
        setLimit,
        page,
        setPage,
        associationModal,
        setAssociationModal,
        honorModal,
        setHonorModal,
        educationModal,
        setEducationModal,
        experienceModal,
        setExperienceModal,
        admissionModal,
        setAdmissionModal,
        editEducation,
        setEditEducation,
        editAdmission,
        setEditAdmission,
        editExperience,
        setEditExperience,
        editHonor,
        setEditHonor,
        editAssociation,
        setEditAssociation,

        deleteAssociation,
        deleteHonor,
        setDeleteAssociation,
        setDeleteHonor,

        deleteEducation,
        setDeleteEducation,

        deleteAdmission,
        setDeleteAdmission,

        deleteExperience,
        setDeleteExperience,
        makeExperienceCurrent,
        makeEducationCurrent,

        pharmacist,
        branch
    } = props;

    console.log(pharmacist, branch);
    const associationColumns = [
        {
            title: <span className='dragHandler'>Date</span>,
            width: 200,
            dataIndex: 'date',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.date) return -1; // Handle empty values
                if (!b.date) return 1; // Handle empty values
                return a.date.localeCompare(b.date);
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <span>
                        {new Date(date).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </span>
                );
            }
        },
        {
            title: <span className='dragHandler'>Organization</span>,
            width: 500,
            dataIndex: 'organization',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.organization) return -1; // Handle empty values
                if (!b.organization) return 1; // Handle empty values
                return a.organization.localeCompare(b.organization);
            }
        },
        {
            title: <span className='dragHandler'>Status</span>,
            width: 300,
            dataIndex: 'status',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.status) return -1; // Handle empty values
                if (!b.status) return 1; // Handle empty values
                return a.status.localeCompare(b.status);
            }
        },

        {
            title: <span className='dragHandler'>Action</span>,
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <Space>
                        <Tooltip title='Edit Association'>
                            <Button
                                type='default'
                                onClick={() => {
                                    setEditAssociation({
                                        ...record,
                                        index: index
                                    });
                                    setAssociationModal(true);
                                }}
                            >
                                {' '}
                                Edit{' '}
                            </Button>
                        </Tooltip>{' '}
                        <Tooltip title='Delete Association'>
                            <Button
                                type='default'
                                onClick={() => {
                                    setDeleteAssociation({
                                        ...record,
                                        index: index
                                    });
                                }}
                            >
                                {' '}
                                Delete{' '}
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    const honorsColumns = [
        {
            title: <span className='dragHandler'>Organization</span>,
            dataIndex: 'organization',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.organization) return -1; // Handle empty values
                if (!b.organization) return 1; // Handle empty values
                return a.organization.localeCompare(b.organization);
            },
            width: 500
        },
        // {
        //     title: <span className='dragHandler'>Position</span>,
        //     dataIndex: 'position',
        //     sorter: (a, b) => {
        //         if (isEmptyObject(a) || isEmptyObject(b)) {
        //             return 0; // Return 0 for empty objects to indicate no sorting
        //         }
        //         if (!a.position) return -1; // Handle empty values
        //         if (!b.position) return 1; // Handle empty values
        //         return a.position.localeCompare(b.position);
        //     },
        //     width: 300
        // },
        // {
        //     title: <span className='dragHandler'>Location</span>,
        //     dataIndex: 'location',
        //     sorter: (a, b) => {
        //         if (isEmptyObject(a) || isEmptyObject(b)) {
        //             return 0; // Return 0 for empty objects to indicate no sorting
        //         }
        //         if (!a.location) return -1; // Handle empty values
        //         if (!b.location) return 1; // Handle empty values
        //         return a.location.localeCompare(b.location);
        //     },
        //     width: 300
        // },
        {
            title: <span className='dragHandler'>Date</span>,
            dataIndex: 'date',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.date) return -1; // Handle empty values
                if (!b.date) return 1; // Handle empty values
                return a.date.localeCompare(b.date);
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <span>
                        {new Date(date).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </span>
                );
            },
            width: 300
        },
        {
            title: <span className='dragHandler'>Action</span>,
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;

                return (
                    <Space>
                        <Tooltip title='Edit Honor'>
                            <Button
                                type='default'
                                onClick={() => {
                                    setEditHonor({
                                        ...record,
                                        index: index
                                    });
                                    setHonorModal(true);
                                }}
                            >
                                Edit
                            </Button>
                        </Tooltip>{' '}
                        <Tooltip title='Delete Honor'>
                            <Button
                                type='default'
                                onClick={() => {
                                    setDeleteHonor({
                                        ...record,
                                        index: index
                                    });
                                }}
                            >
                                Delete
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    const experiencesColumns = [
        {
            title: <span className='dragHandler'>Organization</span>,
            dataIndex: 'organization',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.organization) return -1; // Handle empty values
                if (!b.organization) return 1; // Handle empty values
                return a.organization.localeCompare(b.organization);
            },
            width: 400
        },
        {
            title: <span className='dragHandler'>Position</span>,
            dataIndex: 'position',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.position) return -1; // Handle empty values
                if (!b.position) return 1; // Handle empty values
                return a.position.localeCompare(b.position);
            },
            width: 300
        },
        {
            title: <span className='dragHandler'>Location</span>,
            dataIndex: 'location',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.location) return -1; // Handle empty values
                if (!b.location) return 1; // Handle empty values
                return a.location.localeCompare(b.location);
            },
            width: 300
        },
        {
            title: <span className='dragHandler'>Duration</span>,
            dataIndex: 'duration',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.duration) return -1; // Handle empty values
                if (!b.duration) return 1; // Handle empty values
                return a.duration.localeCompare(b.duration);
            },
            width: 300
        },
        {
            title: <span className='dragHandler'>Action</span>,
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;

                return (
                    <Space>
                        <Tooltip
                            title={
                                record.current ? 'Current Experience' : 'Make Current Experience'
                            }
                        >
                            <Button
                                type='text'
                                onClick={() => {
                                    makeExperienceCurrent(record, index);
                                }}
                            >
                                {record.current ? <StarFilled /> : <StarOutlined />}
                            </Button>
                        </Tooltip>{' '}
                        <Tooltip title='Edit Experience'>
                            <Button
                                type='default'
                                onClick={() => {
                                    setEditExperience({
                                        ...record,
                                        index: index
                                    });
                                    setExperienceModal(true);
                                }}
                            >
                                Edit
                            </Button>
                        </Tooltip>{' '}
                        <Tooltip title='Delete Experience'>
                            <Button
                                type='default'
                                onClick={() => {
                                    setDeleteExperience({
                                        ...record,
                                        index: index
                                    });
                                }}
                            >
                                Delete
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    const eductionColumns = [
        {
            title: <span className='dragHandler'>School</span>,
            dataIndex: 'school',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.school) return -1; // Handle empty values
                if (!b.school) return 1; // Handle empty values
                return a.school.localeCompare(b.school);
            },
            width: 400
        },
        {
            title: <span className='dragHandler'>Degree</span>,
            dataIndex: 'degree',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.degree) return -1; // Handle empty values
                if (!b.degree) return 1; // Handle empty values
                return a.degree.localeCompare(b.degree);
            },
            width: 300
        },
        {
            title: <span className='dragHandler'>Major</span>,
            dataIndex: 'major',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.major) return -1; // Handle empty values
                if (!b.major) return 1; // Handle empty values
                return a.major.localeCompare(b.major);
            },
            width: 300
        },
        {
            title: <span className='dragHandler'>Graduation Year</span>,
            dataIndex: 'graduation',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.graduation) return -1; // Handle empty values
                if (!b.graduation) return 1; // Handle empty values
                return a.graduation.localeCompare(b.graduation);
            },
            width: 300
        },
        {
            title: <span className='dragHandler'>Action</span>,
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <Space>
                        <Tooltip
                            title={record.current ? 'Current Education' : 'Make Current Education'}
                        >
                            <Button
                                type='text'
                                onClick={() => {
                                    makeEducationCurrent(record, index);
                                }}
                            >
                                {record.current ? <StarFilled /> : <StarOutlined />}
                            </Button>
                        </Tooltip>{' '}
                        <Tooltip title='Edit Education'>
                            <Button
                                onClick={() => {
                                    setEditEducation({
                                        ...record,
                                        index: index
                                    });
                                    setEducationModal(true);
                                }}
                            >
                                Edit
                            </Button>
                        </Tooltip>{' '}
                        <Tooltip title='Delete Education'>
                            <Button
                                onClick={() => {
                                    setDeleteEducation({
                                        ...record,
                                        index: index
                                    });
                                }}
                            >
                                Delete
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    const admissionColumns = [
        {
            title: <span className='dragHandler'>Date</span>,
            dataIndex: 'date',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.date) return -1; // Handle empty values
                if (!b.date) return 1; // Handle empty values
                return a.date.localeCompare(b.date);
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <span>
                        {new Date(date).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </span>
                );
            },
            width: 100
        },
        {
            title: <span className='dragHandler'>State</span>,
            dataIndex: 'state',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.state) return -1; // Handle empty values
                if (!b.state) return 1; // Handle empty values
                return a.state.localeCompare(b.state);
            },
            width: 300
        },

        {
            title: <span className='dragHandler'>Action</span>,
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <Space>
                        <Tooltip title='Edit Admission'>
                            <Button
                                onClick={() => {
                                    setEditAdmission({
                                        ...record,
                                        index: index
                                    });
                                    setAdmissionModal(true);
                                }}
                            >
                                Edit
                            </Button>
                        </Tooltip>{' '}
                        <Tooltip title='Delete Admission'>
                            <Button
                                onClick={() => {
                                    setDeleteAdmission({
                                        ...record,
                                        index: index
                                    });
                                }}
                            >
                                Delete
                            </Button>
                        </Tooltip>
                    </Space>
                );
            }
        }
    ];

    const getColumns = (type) => {
        switch (type) {
            case 'Associations':
                return associationColumns;
            case 'Honors':
                return honorsColumns;
            case 'Experiences':
                return experiencesColumns;
            case 'Educations':
                return eductionColumns;
            case 'Admissions':
                return admissionColumns;
            default:
                return [];
        }
    };

    function modalClose() {
        setDeleteAssociation(null);
        setDeleteHonor(null);
        setDeleteEducation(null);
        setDeleteAdmission(null);
        setDeleteExperience(null);
    }

    const handleDelete = async () => {
        let profileObj = {
            data: {},
            userType: props.auth.user.userType,
            userId: props.auth.user._id
        };
        let pharmacist = { ...branch?.pharmacist };
        if (deleteAssociation) {
            let assoc = [...data];
            assoc.splice(deleteAssociation.index, 1);
            console.log(assoc, deleteAssociation.index);
            profileObj.data.associations = [...assoc];
            pharmacist.associations = [...assoc];
        }

        if (deleteHonor) {
            let hon = [...data];
            hon.splice(deleteHonor.index, 1);
            profileObj.data.honors = [...hon];
            pharmacist.honors = [...hon];
        }

        if (deleteEducation) {
            let edu = [...data];
            edu.splice(deleteEducation.index, 1);
            profileObj.data.educations = [...edu];
            pharmacist.educations = [...edu];
        }

        if (deleteAdmission) {
            let adm = [...data];
            adm.splice(deleteAdmission.index, 1);
            profileObj.data.admissions = [...adm];
            pharmacist.admissions = [...adm];
        }

        if (deleteExperience) {
            let exp = [...data];
            exp.splice(deleteExperience.index, 1);
            profileObj.data.experiences = [...exp];
            pharmacist.experiences = [...exp];
        }
        if (branch) {
            let profileObj = {
                userType: 'PHARMACY',
                userId: branch.pharmacy,
                pharmacy: branch.pharmacy,
                branchId: branch._id,
                minFieldsCompleted: true,
                pharmacist: {
                    ...branch.pharmacist,
                    ...pharmacist
                }
            };

            let saved = await props.actions.saveBranches(profileObj, props.history);
            if (saved) {
                let obj = {
                    userId: props.auth.userId,
                    populateAvailability: true,
                    userType: props.auth.userType
                };
                await props.branchActions.getBranches({ ...props, obj }, props.history);
                if (deleteAssociation) {
                    setDeleteAssociation(null);
                }
                if (deleteHonor) {
                    setDeleteHonor(null);
                }
                if (deleteEducation) {
                    setDeleteEducation(null);
                }
                if (deleteAdmission) {
                    setDeleteAdmission(null);
                }
                if (deleteExperience) {
                    setDeleteExperience(null);
                }
            }
            return;
        }
        let saved = await props.actions.saveProfile(profileObj, props.history);
        if (saved) {
            let profileShort = {
                userId: props.auth.user._id,
                userType: props.auth.user.userType
            };
            await props.actions.getProfile(profileShort, props.history);
            if (deleteAssociation) {
                setDeleteAssociation(null);
            }
            if (deleteHonor) {
                setDeleteHonor(null);
            }
            if (deleteEducation) {
                setDeleteEducation(null);
            }
            if (deleteAdmission) {
                setDeleteAdmission(null);
            }
            if (deleteExperience) {
                setDeleteExperience(null);
            }
        }
    };
    console.log('limit', limit);

    const numberOfBlankRows = limit - data.length;
    let dataSource = [...data, ...Array.from({ length: numberOfBlankRows }).map(() => ({}))];

    return (
        <>
            {' '}
            <Table
                tableName={credentialType}
                columns={getColumns(credentialType)}
                data={dataSource}
            />
            {(deleteAssociation ||
                deleteHonor ||
                deleteEducation ||
                deleteAdmission ||
                deleteExperience) && (
                <ConfirmModal
                    onClose={modalClose}
                    acceptMethod={handleDelete}
                    headerText='Delete Data'
                    bodyText='Are you sure you want to delete this data?'
                />
            )}
            {associationModal && (
                <AssociationModal
                    data={data}
                    setAssociationModal={setAssociationModal}
                    associationModal={associationModal}
                    editAssociation={editAssociation}
                    setEditAssociation={setEditAssociation}
                    branch={branch}
                    pharmacist={pharmacist}
                    {...props}
                />
            )}
            {honorModal && (
                <HonorModal
                    data={data}
                    honorModal={honorModal}
                    setHonorModal={setHonorModal}
                    editHonor={editHonor}
                    setEditHonor={setEditHonor}
                    branch={branch}
                    pharmacist={pharmacist}
                    {...props}
                />
            )}
            {educationModal && (
                <EducationModal
                    data={data}
                    educationModal={educationModal}
                    setEducationModal={setEducationModal}
                    editEducation={editEducation}
                    setEditEducation={setEditEducation}
                    branch={branch}
                    pharmacist={pharmacist}
                    {...props}
                />
            )}
            {experienceModal && (
                <ExperienceModal
                    data={data}
                    experienceModal={experienceModal}
                    setExperienceModal={setExperienceModal}
                    editExperience={editExperience}
                    setEditExperience={setEditExperience}
                    branch={branch}
                    pharmacist={pharmacist}
                    {...props}
                />
            )}
            {admissionModal && (
                <AdmissionModal
                    data={data}
                    admissionModal={admissionModal}
                    setAdmissionModal={setAdmissionModal}
                    editAdmission={editAdmission}
                    setEditAdmission={setEditAdmission}
                    branch={branch}
                    pharmacist={pharmacist}
                    {...props}
                />
            )}
            <Pagination
                style={{ margin: 20, display: 'flex', justifyContent: 'flex-end' }}
                current={page}
                total={data.length}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </>
    );
};

export default CredentialsTable;
