import React, { useEffect, useState } from 'react';
import { Button, Input, Modal } from 'antd';

const ExperienceModal = (props) => {
    const {
        data,
        experienceModal,
        setExperienceModal,
        editExperince,
        setEditExperience,
        deleteExperience,
        setDeleteExperience,
        branch
    } = props;
    const dateFormat = 'YYYY/MM/DD';
    const [organization, setOrganization] = useState();
    const [position, setPosition] = useState();
    const [location, setLocation] = useState();
    const [duration, setDuration] = useState();

    useEffect(() => {
        if (editExperince) {
            setOrganization(editExperince.organization);
            setPosition(editExperince.position);
            setLocation(editExperince.location);
            setDuration(editExperince.duration);
        }
    }, [editExperince]);

    const handleSave = async () => {
        let profileObj = {
            data: {},
            userType: props.auth.user.userType,
            userId: props.auth.user._id
        };
        if (editExperince) {
            profileObj.data.experiences[editExperince.index] = {
                location: location,
                position: position,
                duration: duration,
                organization: organization,
                verificationStatus: 'REQUIRED'
            };
        } else {
            profileObj.data.experiences = [
                ...data,
                {
                    location: location,
                    position: position,
                    duration: duration,
                    organization: organization,
                    verificationStatus: 'REQUIRED'
                }
            ];
        }

        if (profileObj.data.experiences.length === 1) {
            profileObj.data.experiences[0].current = true;
        }
        if (branch) {
            let profileObj2 = {
                userType: 'PHARMACY',
                pharmacy: branch.pharmacy,
                branchId: branch._id,
                minFieldsCompleted: true,
                pharmacist: {
                    ...branch.pharmacist
                }
            };
            if (profileObj?.data?.experiences) {
                profileObj2.pharmacist.experiences = [...profileObj.data.experiences];
            }
            console.log('got here');
            let saved = await props.actions.saveBranches(profileObj2, props.history);
            if (saved) {
                let obj = {
                    userId: props.auth.userId,
                    populateAvailability: true,
                    userType: props.auth.userType
                };
                await props.branchActions.getBranches({ ...props, obj }, props.history);
                setEditExperience(null);
                setExperienceModal(false);
            }
            return;
        }
        let saved = await props.actions.saveProfile(profileObj, props.history);
        if (saved) {
            let profileShort = {
                userId: props.auth.user._id,
                userType: props.auth.user.userType
            };
            await props.actions.getProfile(profileShort, props.history);
            setEditExperience(null);
            setExperienceModal(false);
        }
    };

    return (
        <Modal
            className='customModal '
            title={`${editExperince ? 'Edit' : 'Create'} Experience`}
            open={experienceModal}
            onCancel={() => {
                setEditExperience(null);
                setExperienceModal(false);
            }}
            footer={null}
        >
            <>
                <div className='post-modal-options-grid2'>
                    <label>
                        <p>Organization:</p>
                        <Input
                            placeholder='Organization'
                            value={organization}
                            onChange={(e) => setOrganization(e.target.value)}
                        />
                    </label>

                    <label>
                        <p>Position:</p>
                        <Input
                            placeholder='Position'
                            value={position}
                            onChange={(e) => setPosition(e.target.value)}
                        />
                    </label>
                </div>
                <div className='post-modal-options-grid2'>
                    <label>
                        <p>Location:</p>
                        <Input
                            placeholder='Location'
                            value={location}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </label>

                    <label>
                        <p>Duration:</p>

                        <Input
                            placeholder='duration in years'
                            type='Number'
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                        />
                    </label>
                </div>
                <Button onClick={() => handleSave()} type='primary'>
                    <p>{editExperince ? 'Update' : 'Add'}</p>
                </Button>
            </>
        </Modal>
    );
};

export default ExperienceModal;
