import React, { useState, useEffect } from 'react';
import '../Shared/Appointments/appointment.css';
import AppointmentHeader from '../Shared/Appointments/AppointmentHeader';
import { FaTasks } from 'react-icons/fa';
import { BsCalendar } from 'react-icons/bs';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, Pagination, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AppointmentModal from '../Shared/Appointments/modals/AppointmentModal';
import DataTable from '../Shared/Appointments/AppointmentDataTable';
import TableHeader from '../Shared/Appointments/TableHeader';
import * as appointmentActions from '@/actions/appointmentActions';
import * as userActions from '@/actions/userActions';
import * as availabilityActions from '@/actions/availabilityActions';
import * as doctorActions from '@/actions/doctorActions';
import * as hospitalActions from '@/actions/hospitalActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import CreateAppointmentModal from './Appointments/CreateAppointment';
import validator from 'validator';
import EditAppointmentModal from './Appointments/EditAppointment';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import listPlugin from '@fullcalendar/list';
import '@/assets/css/fullcalendar.css';
import FC from '../Fc';
import ShowEventModal from '../Shared/Appointments/ViewCalendarEvent';
import CurrentTime from '../Shared/Appointments/CurrentTime';
import { Link } from 'react-router-dom';
import { ExclamationOutlined } from '@ant-design/icons';

const Appointment = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [calenderView, setCalenderView] = useState(false);

    let itemsPerPage = 10;
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [appointmentModal, setAppointmentModal] = useState(false);
    const [editAppointmentModal, setEditAppointmentModal] = useState(false);
    const [editAppointmentItem, setEditAppointmentItem] = useState(null);
    const { userProfile } = useSelector((state) => state.auth);
    const { stripeVerified } = userProfile;

    const {
        myTimeSlots,
        branches,
        auth,
        appointments: {
            appointments,
            appointmentsCount,
            googleEvents,
            outlookEvents,
            calendarEvents
        },
        shortDoctorList: doctors,
        doctorTimeSlots,
        history
    } = props;
    const { user: me } = auth;
    //Appointment data
    const [loading, setLoading] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [user, setUser] = useState({ name: '', id: '' });
    const [date, setDate] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const [paymentMode, setPaymentMode] = useState('In-Person');
    const [consultationMode, setConsultationMode] = useState('In-Person');
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [chosenUser, setChosenUser] = useState({
        value: '',
        message: '',
        isValid: true
    });
    const [showEvent, setShowEvent] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);

    //error messages
    const [userEmailMsg, setUserEmailMsg] = useState('');
    const [dateMsg, setDateMsg] = useState('');
    const [doctorMsg, setDoctorMsg] = useState('');
    const [timeSlotMsg, setTimeSlotMsg] = useState('');
    const [doctorServiceMsg, setDoctorServiceMsg] = useState('');
    let tempTimezone = moment.tz.guess();

    const updateAppointmentInitialize = async (item) => {
        setSelectedTimeSlot(item.appointmentTime);
        setDate(item.appointmentDate);
        setPaymentMode(item.paymentMode);
        setSelectedDoctor(item.doctor ? item.doctor._id : null);
        setUserEmail(item.userEmail);
        setSelectedBranch(item.branch._id);
        setConsultationMode(item.consultationMode);
        if (item.service) {
            setSelectedService(item.service);
        }
    };

    const openEditAppointment = async (item) => {
        setEditAppointmentItem(item);
        updateAppointmentInitialize(item);
        setEditAppointmentModal(true);
    };

    const cancelAppointment = async (id) => {
        console.log(id);
        let obj = {
            appointmentId: id
        };
        let cancelledAppointment = await props.appointmentActions.cancelAppointment(
            { ...props, obj },
            history
        );
        if (cancelledAppointment) {
            getAppointments();
            getCalendarEvents();
        }
    };

    const approveAppointment = async (id) => {
        console.log(id);
        let obj = {
            appointmentId: id
        };
        let approvedAppointment = await props.appointmentActions.approveAppointment(
            { ...props, obj },
            history
        );
        if (approvedAppointment) {
            getAppointments();
            getCalendarEvents();
        }
    };

    useEffect(() => {
        getAppointments();
        let obj = {
            hospitalSingleDoctors: false,
            hospitalId: props.auth && props.auth.userId && props.auth.userId,
            page: 1,
            perPage: 100
        };
        // props.hospitalActions.getHospitalDoctors({ ...props, obj }, props.history);

        props.hospitalActions.getShortDoctorList({ ...props, obj }, history);
    }, []);

    useEffect(() => {
        if (branches && branches.length > 0) {
            setSelectedBranch(branches[0]._id);
        }
        if (doctors && doctors.length > 0) {
            setSelectedDoctor(doctors[0]._id);
        }
    }, [branches, doctors]);
    useEffect(() => {
        getCalendarEvents();
    }, [me?.syncGoogleCalendar, me?.syncOutlookCalendar]);

    useEffect(() => {
        if (date && selectedBranch && selectedDoctor) {
            getTimeSlots();
        }
    }, [date, selectedBranch, selectedDoctor]);

    const getTimeSlots = async () => {
        let obj = {
            doctorId: selectedDoctor,
            branchId: selectedBranch,
            // self: true,
            date: date ? moment(date).format('DD-MM-YYYY') : moment(new Date()).format('DD-MM-YYYY')
        };
        let timeSlots = await props.doctorActions.getExtractedTimeSlots({ ...props, obj }, history);
    };
    const getAppointments = async () => {
        let obj = {};
        let getAppoints = await props.appointmentActions.getMyAppointments({
            ...props,
            obj
        });
    };

    const checkValidity = async () => {
        let valid = true;
        if (!userEmail || (userEmail && !validator.isEmail(userEmail))) {
            if (!userEmail) {
                setUserEmailMsg('User email is required');
            } else {
                setUserEmailMsg('User email must be a valid email address');
            }
            valid = false;
        } else {
            setUserEmailMsg('');
        }

        if (!selectedDoctor) {
            setDoctorMsg('Doctor is required');
            valid = false;
        } else {
            setDoctorMsg('');
        }

        if (!selectedService) {
            setDoctorServiceMsg('Doctor Service is required');
            valid = false;
        } else {
            setDoctorServiceMsg('');
        }

        if (!date) {
            setDateMsg('Appointment Date is required');
            valid = false;
        } else {
            setDateMsg('');
        }

        if (!selectedTimeSlot) {
            setTimeSlotMsg('Time Slot is required');
            valid = false;
        } else {
            setTimeSlotMsg('');
        }
        return valid;
    };

    const submitForm = async () => {
        setLoading(true);
        let valid = await checkValidity();
        if (valid) {
            let obj = {
                time: selectedTimeSlot,
                date: date && moment(new Date(date).toISOString()).format('DD/MM/YYYY'),
                consultationMode: consultationMode,
                paymentMode: paymentMode,
                doctor: selectedDoctor,
                hospital: auth && auth.userId && auth.userId,
                userEmail: userEmail,
                requestTo: "PATIENT",
                branch: selectedBranch,
                timezone: tempTimezone,
                service: selectedService?._id || selectedService
            };
            console.log(obj);

            if (!editAppointmentItem) {
                let createApp = await props.appointmentActions.requestAppointmentByDoctor(
                    { ...props, obj },
                    props.history
                );

                if (createApp) {
                    setAppointmentModal(false);
                    clearAppointmentData();
                    await getAppointments();
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } else {
                obj.appointmentId = editAppointmentItem._id;
                let updateApp = await props.appointmentActions.updateAppointment(
                    { ...props, obj },
                    props.history
                );

                if (updateApp) {
                    setEditAppointmentModal(false);
                    setEditAppointmentItem(null);
                    clearAppointmentData();
                    await getAppointments();
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        }
        setLoading(false);
    };

    const appointmentVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { duration: 0.5 }
        },
        exit: {
            opacity: 0,
            transition: { ease: 'easeIn', duration: 0.5 }
        }
    };

    const onSelect = (value) => {
        setDate(value);
        setAppointmentModal(true);
    };

    const clearAppointmentData = () => {};

    const getCalendarEvents = async () => {
        let obj = {};
        await props.actions.getCalendarEvents({ ...props, obj }, props.history);
    };

    let appEvents =
        appointments &&
        appointments
            ?.filter((item) => item.appointmentStatus == 'ACCEPTED')
            .map((item, index) => {
                let obj = {};
                obj.title = `Appointment with ${item?.user?.firstName} ${item?.user?.lastName}`;
                obj.description = `Appointment with ${item?.user?.firstName} ${
                    item?.user?.lastName
                } on ${moment(item.appointmentStartTime, 'hh:mm a')}, ${
                    item.appointmentDate && moment(item.appointmentDate).format('DD/MM/YYYY')
                }`;
                obj.start = moment(
                    `${moment(item?.appointmentDate).format('DD/MM/YYYY')} ${
                        item.appointmentStartTime
                    }`,
                    'DD/MM/YYYY hh:mm'
                ).toISOString();

                obj.end = moment(
                    `${moment(item?.appointmentDate).format('DD/MM/YYYY')} ${
                        item.appointmentEndTime
                    }`,
                    'DD/MM/YYYY hh:mm'
                ).toISOString();
                obj = { ...obj, ...item };
                return obj;
            });

    let events = [...appEvents, ...calendarEvents];

    const openEvent = async (evt) => {
        setSelectedEvent(evt);
        setShowEvent(true);
    };
    return (
        <motion.div variants={appointmentVariants} initial='hidden' animate='visible' exit='exit'>
            <div className='app-headings'>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}
                >
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <p style={{ fontSize: '2rem' }}>Appointments </p>
                        {!stripeVerified && (
                            <Popover
                                content={
                                    <div>
                                        To schedule online appointments or make online payments,
                                        please ensure your Stripe account is connected. Go To{' '}
                                        <Link
                                            style={{
                                                fontWeight: 'bold',
                                                textDecoration: 'underline'
                                            }}
                                            to='/hospital/dashboard'
                                        >
                                            Dashboard
                                        </Link>
                                    </div>
                                }
                            >
                                <div
                                    style={{
                                        height: 30,
                                        width: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '50%',
                                        backgroundColor: 'var(--tertiary)',
                                        color: 'white',
                                        fontSize: 20
                                    }}
                                >
                                    <ExclamationOutlined />
                                </div>
                            </Popover>
                        )}
                    </div>

                    <CurrentTime />
                </div>
                <div className='app-switch'>
                    <FaTasks onClick={() => setCalenderView(false)} />
                    <p>|</p>
                    <BsCalendar onClick={() => setCalenderView(true)} />
                </div>
            </div>
            <AnimatePresence exitBeforeEnter>
                {calenderView && (
                    <motion.div
                        key='1'
                        variants={appointmentVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        className='appointment-calendar'
                    >
                        <FullCalendar
                            plugins={[
                                dayGridPlugin,
                                interactionPlugin,
                                timeGridPlugin,
                                momentPlugin,
                                listPlugin
                            ]}
                            windowResizeDelay={0}
                            initialView='dayGridMonth'
                            displayEventTime={true}
                            // displayEventEnd={true}
                            selectable={true}
                            editable={true}
                            dragRevertDuration={1}
                            timeFormat='hh:mm'
                            eventTimeFormat={{
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            }}
                            dateClick={(val) => onSelect(val.dateStr)}
                            eventClick={(val) => openEvent(val.event)}
                            headerToolbar={{
                                left: 'prev,next',
                                center: 'title',
                                right: 'timeGridDay,timeGridWeek,dayGridMonth,listWeek'
                            }}
                            events={events}
                        />
                    </motion.div>
                )}
                {!calenderView && (
                    <div>
                        <AppointmentHeader
                            open={appointmentModal}
                            setOpen={setAppointmentModal}
                            auth={auth}
                        />

                        <DataTable
                            userType={"HOSPITAL"}
                            auth={auth}
                            appointments={appointments}
                            open={appointmentModal}
                            setOpen={setAppointmentModal}
                            cancelAppointment={cancelAppointment}
                            approveAppointment={approveAppointment}
                            openEditAppointment={openEditAppointment}
                            limit={limit}
                            history={history}
                            page={page}
                        />
                    </div>
                )}
            </AnimatePresence>
            <AppointmentModal open={open} setOpen={setOpen} />
            {appointmentModal && (
                <CreateAppointmentModal
                    {...props}
                    branches={branches}
                    selectedBranch={selectedBranch}
                    setSelectedBranch={setSelectedBranch}
                    selectedTimeSlot={selectedTimeSlot}
                    setSelectedTimeSlot={setSelectedTimeSlot}
                    myTimeSlots={doctorTimeSlots}
                    date={date}
                    setDate={setDate}
                    appointmentModal={appointmentModal}
                    setAppointmentModal={setAppointmentModal}
                    clearAppointmentData={clearAppointmentData}
                    paymentMode={paymentMode}
                    setPaymentMode={setPaymentMode}
                    consultationMode={consultationMode}
                    setConsultationMode={setConsultationMode}
                    userEmail={userEmail}
                    setUserEmail={setUserEmail}
                    submitForm={submitForm}
                    chosenUser={chosenUser}
                    setChosenUser={setChosenUser}
                    user={user}
                    setUser={setUser}
                    doctors={doctors}
                    selectedDoctor={selectedDoctor}
                    setSelectedDoctor={setSelectedDoctor}
                    selectedService={selectedService}
                    setSelectedService={setSelectedService}
                    loading={loading}
                    setLoading={setLoading}
                    userEmailMsg={userEmailMsg}
                    dateMsg={dateMsg}
                    setDateMsg={setDateMsg}
                    doctorMsg={doctorMsg}
                    setDoctorMsg={setDoctorMsg}
                    setUserEmailMsg={setUserEmailMsg}
                    timeSlotMsg={timeSlotMsg}
                    setTimeSlotMsg={setTimeSlotMsg}
                    doctorServiceMsg={doctorServiceMsg}
                    setDoctorServiceMsg={setDoctorServiceMsg}
                />
            )}

            {editAppointmentModal && (
                <EditAppointmentModal
                    {...props}
                    branches={branches}
                    selectedBranch={selectedBranch}
                    setSelectedBranch={setSelectedBranch}
                    selectedTimeSlot={selectedTimeSlot}
                    setSelectedTimeSlot={setSelectedTimeSlot}
                    myTimeSlots={doctorTimeSlots}
                    date={date}
                    setDate={setDate}
                    selectedService={selectedService}
                    setSelectedService={setSelectedService}
                    appointmentModal={editAppointmentModal}
                    setAppointmentModal={setEditAppointmentModal}
                    clearAppointmentData={clearAppointmentData}
                    paymentMode={paymentMode}
                    setPaymentMode={setPaymentMode}
                    consultationMode={consultationMode}
                    setConsultationMode={setConsultationMode}
                    userEmail={userEmail}
                    setUserEmail={setUserEmail}
                    submitForm={submitForm}
                    chosenUser={chosenUser}
                    setChosenUser={setChosenUser}
                    user={user}
                    setUser={setUser}
                    doctors={doctors}
                    selectedDoctor={selectedDoctor}
                    setSelectedDoctor={setSelectedDoctor}
                    loading={loading}
                    setLoading={setLoading}
                    userEmailMsg={userEmailMsg}
                    dateMsg={dateMsg}
                    setDateMsg={setDateMsg}
                    doctorMsg={doctorMsg}
                    setDoctorMsg={setDoctorMsg}
                    setUserEmailMsg={setUserEmailMsg}
                    timeSlotMsg={timeSlotMsg}
                    setTimeSlotMsg={setTimeSlotMsg}
                    editAppointmentItem={editAppointmentItem}
                    doctorServiceMsg={doctorServiceMsg}
                    setDoctorServiceMsg={setDoctorServiceMsg}
                />
            )}

            {showEvent && (
                <ShowEventModal
                    showEvent={showEvent}
                    setShowEvent={setShowEvent}
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    history={history}
                    auth={auth}
                />
            )}

            <Pagination
                style={{ marginTop: 20 }}
                showQuickJumper
                current={page}
                total={appointmentsCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </motion.div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    branches: state.branch.branches,
    availability: state.availability,
    availabilities: state.availability.availabilities,
    appointments: state.appointments,
    myTimeSlots: state.availability.myTimeSlots,
    doctorTimeSlots:
        state.doctors &&
        state.doctors.doctorForAppointment &&
        state.doctors.doctorForAppointment.timeSlots,
    appointments: state.appointments,
    doctors: state.hospital && state.hospital.doctors && state.hospital.doctors,
    doctorsCount: state.hospital && state.hospital.doctorsCount && state.hospital.doctorsCount,
    shortDoctorList: state.hospital && state.hospital.shortDoctorList && state.hospital.shortDoctorList
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    availabilityActions: bindActionCreators(availabilityActions, dispatch),
    appointmentActions: bindActionCreators(appointmentActions, dispatch),
    doctorActions: bindActionCreators(doctorActions, dispatch),
    hospitalActions: bindActionCreators(hospitalActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
