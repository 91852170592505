import React, { useRef, useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@dmc4719/ckeditor5-custom-build/build/ckeditor';
// import ClassicEditor from "./build/ckeditor";
import MyCKEditorUploadAdapter from '../../NewProfile/MyCKEditorUploadAdapter';
import { CONFIG } from '../../MiniComponents/CKeditorConfig';
import { useDispatch, useSelector } from 'react-redux';
import { createEmailTemplate, getSingleTemplate } from '@/actions/adminActions';
import { useParams } from 'react-router-dom';

function CreateTemplate(props) {
    const { id } = useParams();

    const { singleEmailTemplate } = useSelector((state) => state.admin);
    useEffect(() => {
        if (id) {
            let obj = {
                templateId: id
            };
            dispatch(getSingleTemplate({ ...props, obj }));
        }
    }, [id]);

    useEffect(() => {
        if (singleEmailTemplate) {
            if (singleEmailTemplate.description) {
                setEditorValue({
                    ...editorValue,
                    value: singleEmailTemplate.description
                });
            }
            if (singleEmailTemplate.title) {
                setTitle({ ...title, value: singleEmailTemplate.title });
            }
            if (singleEmailTemplate.description) {
                setSubject({ ...subject, value: singleEmailTemplate.subject });
            }
            if (singleEmailTemplate.unique_keyword) {
                setUniqueKeyword({
                    ...uniqueKeyword,
                    value: singleEmailTemplate.unique_keyword
                });
            }
        }
    }, [singleEmailTemplate]);

    const dispatch = useDispatch();
    const [editorValue, setEditorValue] = useState({
        value: 'Hello from CkEditor',
        message: ''
    });
    const [loading, setLoading] = useState(false);
    const editor = useRef(null);
    const [subject, setSubject] = useState({
        value: '',
        message: '',
        isValid: ''
    });
    const [title, setTitle] = useState({
        value: '',
        message: '',
        isValid: ''
    });
    const [uniqueKeyword, setUniqueKeyword] = useState({
        value: '',
        message: '',
        isValid: ''
    });

    const createTemplate = async (e) => {
        e.preventDefault();
        let obj = {
            subject: subject.value,
            description: editorValue.value,
            unique_keyword: uniqueKeyword.value,
            title: title.value
        };
        let createTemplate = await dispatch(createEmailTemplate({ ...props, obj }));
        if (createTemplate) {
            props.history.push('/admin/emailTemplates');
        }
    };

    return (
        <div>
            <h1>
                {' '}
                <button onClick={() => props.history.push('/admin/emailTemplates')}>
                    <i className={`fa fa-chevron-left `} />
                </button>{' '}
                Create Email Templates{' '}
            </h1>

            <h2>Template Preview</h2>
            <div dangerouslySetInnerHTML={{ __html: editorValue.value }} />

            <div className='input-cont'>
                <input
                    value={subject.value}
                    onChange={(e) => setSubject({ ...subject, value: e.target.value })}
                    placeholder='Subject'
                />
            </div>

            <div className='input-cont'>
                <input
                    value={title.value}
                    onChange={(e) => setTitle({ ...title, value: e.target.value })}
                    placeholder='Title'
                />
            </div>
            <div className='input-cont'>
                <input
                    value={uniqueKeyword.value}
                    onChange={(e) => setUniqueKeyword({ ...uniqueKeyword, value: e.target.value })}
                    placeholder='Unique Keyword'
                />
            </div>

            <div className='input-cont2'>
                <CKEditor
                    ref={editor}
                    config={CONFIG}
                    // style={{ width: "100%", margin: "auto" }}
                    editor={ClassicEditor}
                    data={editorValue.value}
                    onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                            return new MyCKEditorUploadAdapter({ loader, ...props });
                        };
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data != '') {
                            setEditorValue({ ...editorValue, value: data, message: '' });
                        } else {
                            setEditorValue({
                                ...editorValue,
                                message: 'Body field cannot be empty',
                                value: data
                            });
                        }
                        console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />
                {editorValue.message && <p className='error-text'>{editorValue.message}</p>}
            </div>

            <button className='submitButton' onClick={(e) => createTemplate(e)}>
                Submit
            </button>
        </div>
    );
}

export default CreateTemplate;
