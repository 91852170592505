import React from 'react';
import '@/assets/css/favourites.css';
import { Button, Tooltip } from 'antd';
import Table from '@/components/NewProfile/Shared/Table';
import {isEmptyObject} from '@/components/Utilities/ObjectUtils';

const LocationDataTable = (props) => {
    const { locations, setState, state, limit } = props;

    const columns = [
        {
            title: <span className="dragHandler"> </span>,
            width: 60,
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <div>{index + 1}</div>;
            }
        },
        {
            title: <span className="dragHandler">Location</span>,
            width: 500,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.branchAddress?.value) return -1; // Handle empty values
                if (!b.branchAddress?.value) return 1; // Handle empty values
                return a.branchAddress?.value.localeCompare(b.branchAddress?.value);
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                let location = record.branchAddress && record.branchAddress.value;
                return <div>{location}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            }
        },
        {
            title: <span className="dragHandler">Email</span>,
            width: 200,
            dataIndex: 'email',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.email?.value) return -1; // Handle empty values
                if (!b.email?.value) return 1; // Handle empty values
                return a.email?.value.localeCompare(b.email?.value);
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <div>{record.email?.value}</div>;
            }
        },
        {
            title: <span className="dragHandler">Phone</span>,
            width: 200,
            dataIndex: 'phoneNo',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.phoneNo?.value) return -1; // Handle empty values
                if (!b.phoneNo?.value) return 1; // Handle empty values
                return a.phoneNo?.value.localeCompare(b.phoneNo?.value);
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {record.phoneNo.value && (
                            <p>
                                ({record.countryCode.value})-{record.phoneNo.value}
                            </p>
                        )}
                    </div>
                );
            }
        },
        {
            title: <span className="dragHandler">Action</span>,
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <Tooltip title='Edit Location'>
                        <Button
                            onClick={() => {
                                state.branches[index].accordion = !state.branches[index].accordion;
                                state.branches[index].locationModal = true;
                                setState(state);
                            }}
                            type='default'
                        >
                            Edit
                        </Button>{' '}
                    </Tooltip>
                );
            }
        }
    ];

    const numberOfBlankRows = limit - locations.length;
    let dataSource = [...locations, ...Array.from({ length: numberOfBlankRows }).map(() => ({}))];
    return (
        <>
            <Table tableName="Locations" columns={columns} data={dataSource} />
        </>
    );
};

export default LocationDataTable;
