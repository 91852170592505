import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Input, Modal } from 'antd';
import moment from 'moment';

const AdmissionModal = (props) => {
    const {
        data,
        admissionModal,
        setAdmissionModal,
        editAdmission,
        setEditAdmission,
        deleteAdmission,
        setDeleteAdmission,
        branch
    } = props;
    const dateFormat = 'YYYY/MM/DD';
    const [state, setState] = useState();
    const [date, setDate] = useState();

    useEffect(() => {
        if (editAdmission) {
            setState(editAdmission.state);
            setDate(editAdmission.date);
        }
    }, [editAdmission]);

    const handleSave = async () => {
        let profileObj = {
            data: {
                admissions: [...data]
            },
            userType: props.auth.user.userType,
            userId: props.auth.user._id
        };
        console.log(editAdmission);
        if (editAdmission) {
            profileObj.data.admissions[editAdmission.index] = {
                state: state,
                date: date,
                verificationStatus: 'REQUIRED'
            };
        } else {
            profileObj.data.admissions = [
                ...data,
                {
                    state: state,
                    date: date,
                    verificationStatus: 'REQUIRED'
                }
            ];
        }
        if (profileObj.data.admissions.length === 1) {
            profileObj.data.admissions[0].current = true;
        }
        if (branch) {
            let profileObj2 = {
                userType: 'PHARMACY',
                pharmacy: branch.pharmacy,
                branchId: branch._id,
                minFieldsCompleted: true,
                pharmacist: {
                    ...branch.pharmacist
                }
            };
            if (profileObj?.data?.admissions) {
                profileObj2.pharmacist.admissions = [...profileObj.data.admissions];
            }
            console.log('got here');
            let saved = await props.actions.saveBranches(profileObj2, props.history);
            if (saved) {
                let obj = {
                    userId: props.auth.userId,
                    populateAvailability: true,
                    userType: props.auth.userType
                };
                await props.branchActions.getBranches({ ...props, obj }, props.history);
                setEditAdmission(null);
                setAdmissionModal(false);
            }
            return;
        }
        let saved = await props.actions.saveProfile(profileObj, props.history);
        if (saved) {
            let profileShort = {
                userId: props.auth.user._id,
                userType: props.auth.user.userType
            };
            await props.actions.getProfile(profileShort, props.history);
            setEditAdmission(null);
            setAdmissionModal(false);
        }
    };

    return (
        <Modal
            className='customModal '
            title={`${editAdmission ? 'Edit' : 'Create'} Admission`}
            open={admissionModal}
            onCancel={() => {
                setEditAdmission(null);
                setAdmissionModal(false);
            }}
            footer={null}
        >
            <>
                <div className='post-modal-options-grid2'>
                    <label>
                        <p>State:</p>
                        <Input
                            placeholder='School Name'
                            value={state}
                            onChange={(e) => setState(e.target.value)}
                        />
                    </label>

                    <label>
                        <p>Date:</p>

                        <DatePicker
                            placeholder='Choose Date'
                            value={date ? moment(date, dateFormat) : ''}
                            onChange={(date, string) => setDate(string)}
                        />
                    </label>
                </div>

                <Button onClick={() => handleSave()} type='primary'>
                    <p>{editAdmission ? 'Update' : 'Add'}</p>
                </Button>
            </>
        </Modal>
    );
};

export default AdmissionModal;
