import React from 'react';
import { Modal } from 'antd';

function MessageModal({
    messageModal,
    setMessageModal,
    message,
    setMessage,
    messageError,
    sendMessage
}) {
    return (
        (<Modal
            className='addReview'
            title='Send a message'
            open={messageModal}
            onCancel={() => {
                setMessageModal(false);
            }}
            footer={null}
        >
            <label>
                <span>Message</span>
                <textarea
                    placeholder='Description'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
                <p className='error'>{messageError && messageError}</p>
            </label>
            <button className='red-btn' onClick={() => sendMessage()}>
                Send Message
            </button>
        </Modal>)
    );
}

export default MessageModal;
