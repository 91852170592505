import React, { memo } from 'react';
import { AiOutlineFileAdd } from 'react-icons/ai';
import { CgNotes } from 'react-icons/cg';
import { RiDeleteBin6Line } from 'react-icons/ri';
import './files.css';
import { Empty } from 'antd';

const Files = ({ files, setFiles }) => {
    const handleFiles = (e) => {
        setFiles(Array.from(e.target.files));
    };
    const handleDelete = (index) => {
        let allFiles = [...files];
        allFiles.splice(index, 1);
        setFiles(allFiles);
    };
    return (
        <div className='notes-main'>
            <div className='notes-header'>
                <p>Files / Documents</p>
                <label htmlFor='image-input' className='files-icon'>
                    <AiOutlineFileAdd size='1.1rem' />
                    <p>Add Files</p>
                    <input type='file' multiple={true} onChange={handleFiles} id='image-input' />
                </label>
            </div>
            <div className='notes-files-container'>
                {files && files.length ? (
                    files.map((item, index) => {
                        return (
                            <div key={(item, index)} className='notes-files-box'>
                                <div className='notes-files-header'>
                                    <CgNotes />
                                    <p>{item.name.substring(0, 10)}</p>
                                </div>
                                <div>
                                    <RiDeleteBin6Line onClick={() => handleDelete(index)} />
                                </div>
                            </div>
                        );
                    })
                ) : (
                    <Empty description={'No files uploaded'} />
                )}
            </div>
        </div>
    );
};

export default memo(Files);
