import React from 'react';
import {
    UserOutlined,
    FileOutlined,
    CalendarOutlined,
    HeartOutlined,
    BellOutlined,
    StarOutlined,
    MessageOutlined,
    EditOutlined,
    EnvironmentOutlined,
    IdcardOutlined,
    SafetyOutlined,
    TeamOutlined,
    DashboardOutlined,
    CreditCardOutlined,UsergroupAddOutlined,SettingOutlined,AreaChartOutlined,ShoppingCartOutlined,PieChartOutlined
} from '@ant-design/icons';

import Profile from '@components/NewProfile/HospitalProfile/Profile';
import Dashboard from '@components/NewProfile/HospitalProfile/Dashboard'
import Document from '@components/NewProfile/Shared/Documents';
import Verifications from '@components/NewProfile/HospitalProfile/Verifications';
import Favourites from '@components/NewProfile/Shared/Favourites';
import Reviews from '@components/NewProfile/Shared/Reviews';
import Notifications from '@components/NewProfile/Shared/Notifications/Notifications';
import Blogs from '@components/NewProfile/Shared/Blogs';
import CreateBlog from '@components/NewProfile/Shared/Blogs/Create';
import EditBlog from '@components/NewProfile/Shared/Blogs/Edit';
import SingleBlog from '@components/Blogs/SingleView';
import Prospects from '@components/NewProfile/Shared/Prospects';
import Messaging from '@components/NewProfile/Shared/Messaging/Messaging';
import Appointments from '@components/NewProfile/HospitalProfile/Appointment';
import Marketing from '@components/NewProfile/Shared/Marketing';
import Management from '@components/NewProfile/HospitalProfile/Management';
import Members from '@components/NewProfile/HospitalProfile/Members';
import Credentials from '@components/NewProfile/Shared/Credentials';
import VideoContainer from '@components/NewProfile/Shared/Meeting/VideoContainer';
import StripeDashboard from '@components/NewProfile/HospitalProfile/Dashboard/Stripe';
import MeetingEnded from '@components/NewProfile/Shared/Meeting/MeetingEnded';
import Locations from '@components/NewProfile/HospitalProfile/Locations';
import Subscription from '../../components/NewProfile/HospitalProfile/Subscription';
import DataVisualization from '../../components/NewProfile/HospitalProfile/DataVisualization';

export default [
    {
        name: 'Dashboard',
        path: '/hospital/dashboard',
        component: Dashboard,
        icon: <DashboardOutlined />,
    },
    {
        name: 'My Account',
        path: '/hospital/profile',
        component: Profile,
        icon: <UserOutlined />,
        exact: true
    },
    {
        name: 'Locations',
        path: '/hospital/locations',
        component: Locations,
        icon: <EnvironmentOutlined />,
        exact: true
    },
    {
        name: 'Credentials',
        path: '/hospital/credentials',
        component: Credentials,
        icon: <IdcardOutlined />,
        exact: true
    },
    {
        name: 'Verifications',
        path: '/hospital/verifications',
        component: Verifications,
        icon: <SafetyOutlined />,
        exact: true
    },
    {
        name: 'Documents',
        path: '/hospital/documents',
        icon: <FileOutlined />,
        component: Document,
        exact: true
    },

    {
        name: 'Members',
        path: '/hospital/members',
        component: Members,
        icon: <TeamOutlined />,
        exact: true
    },
    {
        name: 'Appointments',
        path: '/hospital/appointments',
        icon: <CalendarOutlined />,
        component: Appointments,
        exact: true,
        children: [
            {
                path: '/hospital/appointments/meeting/:appointmentId/:sessionId/:token',
                component: VideoContainer,
                exact: true
            },
            {
                path: '/patient/appointments/meeting/:appointmentId/meeting-ended',
                component: MeetingEnded,
                exact: true
            }
        ]
    },

    {
        name: 'Stripe',
        path: '/hospital/stripe-dashboard',
        component: StripeDashboard,
        icon: <CreditCardOutlined />,
        exact: true
    },
    {
        name: 'Prospects',
        path: '/hospital/prospects',
        component: Prospects,
        icon: <UsergroupAddOutlined />,
        exact: true
    },
    {
        name: 'Notifications',
        path: '/hospital/notifications',
        component: Notifications,
        icon: <BellOutlined />,
        exact: true
    },

    {
        name: 'Reviews',
        path: '/hospital/reviews',
        component: Reviews,
        icon: <StarOutlined />,
        exact: true
    },
    {
        name: 'Management',
        path: '/hospital/management',
        component: Management,
        icon: <SettingOutlined />,
        exact: true
    },

    {
        name: 'Data Visualization',
        path: '/hospital/data-visualization',
        component: DataVisualization,
        icon: <AreaChartOutlined />,
        exact: true
    },
    {
        name: 'Subscriptions',
        path: '/hospital/subscriptions',
        component: Subscription,
        icon: <ShoppingCartOutlined />,
        exact: true
    },

    {
        name: 'Messaging',
        path: '/hospital/chats',
        component: Messaging,
        icon: <MessageOutlined />,
        exact: true
    },
    {
        name: 'Blogs',
        path: '/hospital/blogs',
        icon: <EditOutlined />,
        component: Blogs,
        exact: true,
        children: [
            {
                path: '/hospital/blogs/createblog',
                component: CreateBlog,
                exact: true
            },
            {
                path: '/hospital/blogs/edit/:id',
                component: EditBlog,
                exact: true
            },
            {
                path: '/hospital/blogs/:postId',
                component: SingleBlog,
                exact: false
            }
        ]
    },
    {
        name: 'Marketing',
        path: '/hospital/marketing',
        component: Marketing,
        icon: <PieChartOutlined />,
        exact: true
    },
    {
        name: 'Favourites',
        path: '/hospital/favourites',
        icon: <HeartOutlined />,
        component: Favourites,
        exact: true
    }
];
