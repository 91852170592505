import React, { useState } from 'react';
import './credentials.css';
import '@/components/NewProfile/Shared/Appointments/appointment.css';
import '@/components/NewProfile/Shared/customModal.css';
import '@/components/NewProfile/HospitalProfile/Members/doctorModals.css';
import Association from './Assosication';
import Honors from './Honors';
import Education from './Education';
import Experience from './Experience';
import Admission from './Admission';
import PageHeader from '@/components/NewProfile/Shared/PageHeader';
import { useSelector } from 'react-redux';

const Credentials = ({ showHeader = true, branch }) => {
    const [credentialType, setCredentialType] = useState('Associations');
    const { userType, userProfile } = useSelector((state) => state.auth);

    let credentialTypes = ['Associations', 'Honors'];
    const allCredTypesCond =
        userType == 'DOCTOR' ||
        userType == 'NURSE' ||
        userType == 'CONSULTANT' ||
        userType == 'CAREGIVER' ||
        userType == 'THERAPIST' || branch;
    if (allCredTypesCond) {
        credentialTypes = ['Associations', 'Honors', 'Experiences', 'Educations', 'Admissions'];
    }
    return (
        <div>
            {showHeader && <PageHeader heading='Credentials' />}

            {credentialType == 'Associations' && (
                <Association
                    credentialType={credentialType}
                    credentialTypes={credentialTypes}
                    setCredentialType={setCredentialType}
                    branch={branch}
                />
            )}
            {credentialType == 'Honors' && (
                <Honors
                    credentialType={credentialType}
                    credentialTypes={credentialTypes}
                    setCredentialType={setCredentialType}
                    branch={branch}
                />
            )}
            {allCredTypesCond && (
                <>
                    {credentialType == 'Experiences' && (
                        <Experience
                            credentialType={credentialType}
                            credentialTypes={credentialTypes}
                            setCredentialType={setCredentialType}
                            branch={branch}
                        />
                    )}
                    {credentialType == 'Educations' && (
                        <Education
                            credentialType={credentialType}
                            credentialTypes={credentialTypes}
                            setCredentialType={setCredentialType}
                            branch={branch}
                        />
                    )}

                    {credentialType == 'Admissions' && (
                        <Admission
                            credentialType={credentialType}
                            credentialTypes={credentialTypes}
                            setCredentialType={setCredentialType}
                            branch={branch}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default Credentials;
