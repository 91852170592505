import { ExclamationOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ApplicationSetup = () => {
    const { dashboard } = useSelector((state) => state.pharmacy);
    const { locations, documentTypes } = dashboard;
    const links = [
        { label: 'Locations', to: '/pharmacy/locations', text: locations || 0 },
        {
            label: 'Document Types',
            to: `/pharmacy/management?tab=${encodeURIComponent('Document Types')}`,
            text: documentTypes || 0
        }
    ];

    const linkItems = links.map((link, index) => (
        <li
            key={index}
            className={link.label.toLowerCase().replace(' ', '')}
            style={{ position: 'relative' }}
        >
            <Link to={link.to} style={{ height: '100%', width: '100%' }}>
                {link.text === 0 && (
                    <div
                        style={{
                            backgroundColor: 'red',
                            height: 25,
                            width: 25,
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '0px',
                            right: 10,
                            transform: 'translateY(-50%)'
                        }}
                    >
                        <ExclamationOutlined style={{ fontSize: 15, color: 'white' }} />
                    </div>
                )}
                <div style={{ textAlign: 'left' }}>{link.label}</div>{' '}
                <div style={{ position: 'absolute', bottom: 10, right: 10, fontSize: 24 }}>
                    {link.text}
                </div>
            </Link>
        </li>
    ));

    return (
        <div id='applicationSetup'>
            <h4 className='sectionTitle' style={{ fontSize: 14 }}>
                Application Setup
            </h4>
            <div className='sectionContainer'>
                <ul>{linkItems}</ul>
            </div>
        </div>
    );
};

export default ApplicationSetup;
