import React, { useState, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import StarRatingComponent from 'react-star-rating-component';
import { message as toast } from 'antd';
import { useDispatch } from 'react-redux';
import './ProfileDetail.css';
import * as messagingActions from '@/actions/messagingActions';
import MessageModal from '../../../../modals/MessageModal';

const Marker = ({ text }) => (
    <div
        className='marker'
        style={{
            color: 'white',
            padding: '15px 10px',
            display: 'inline-flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '100%',
            transform: 'translate(-50%, -50%)'
        }}
    >
        <i
            className='fe fe-map-pin'
            style={{ fontSize: '30px', color: '#f1433f', position: 'relative' }}
        ></i>
    </div>
);
const ProfileDetail = (props) => {
    const dispatch = useDispatch();
    const { doctors, hospital, history } = props;

    const { hospitalForAppointment: apptHospital } = hospital;
    const { doctorForAppointment: apptDoctor } = doctors;

    let chosenUser;
    if (apptHospital && apptHospital.hospital && Object.keys(apptHospital.hospital).length != 0) {
        chosenUser = apptHospital.hospital;
    } else if (apptDoctor && apptDoctor.doctor && Object.keys(apptDoctor.doctor).length != 0) {
        chosenUser = apptDoctor.doctor;
    }
    const [center, setCenter] = useState({
        lat: 59.95,
        lng: 30.33
    });
    const [zoom, setZoom] = useState(11);
    const loc =
        chosenUser && chosenUser.branch && chosenUser.branch.location && chosenUser.branch.location;

    const [messageModal, setMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const [messageError, setMessageError] = useState('');

    const sendMessage = async () => {
        if (!message) {
            setMessageError('Message field cannot be empty');
        } else {
            setMessageError('');
            let obj = {
                message: message,
                receiver: chosenUser && chosenUser._id && chosenUser._id,
                receiverType: chosenUser && chosenUser.userType && chosenUser.userType
            };
            let sendMessage = await dispatch(
                messagingActions.createConversation({ ...props, obj }, history)
            );
            if (sendMessage) {
                setMessageModal(false);
                setMessage('');
            } else {
                console.log('error sending message');
            }
        }
    };

    const openMessageModal = async () => {
        if (!chosenUser.allowChat) {
            if (chosenUser.userType == "DOCTOR") {
                toast.error(
                    `${chosenUser.firstName} ${chosenUser.lastName} wil not be accepting any messages at the moment!`
                );
            } else {
                toast.error(
                    `${chosenUser.hospitalName} wil not be accepting any messages at the moment!`
                );
            }
            return;
        } else {
            setMessageModal(true);
        }
    };

    // useEffect(() => {
    //     if (!chosenUser || chosenUser == {}) {
    //         history.goBack();
    //     }
    // }, []);
    return (
        <div className='profileDetail'>
            <div className='firstCard'>
                <div className='avatar'>
                    {chosenUser && chosenUser.profileImage && <img src={chosenUser.profileImage} />}
                </div>

                <div>
                    {chosenUser && chosenUser.userType == "DOCTOR" ? (
                        <>
                            <p className='name'>
                                {chosenUser.firstName && chosenUser.firstName}{' '}
                                {chosenUser.lastName && chosenUser.lastName}
                            </p>
                            <p className='hospitalName' style={{ fontSize: 12, color: 'gray' }}>
                                {chosenUser.hospitalName && chosenUser.hospitalName}
                            </p>
                        </>
                    ) : chosenUser && chosenUser.userType == "HOSPITAL" ? (
                        <p className='name'>{chosenUser.hospitalName && chosenUser.hospitalName}</p>
                    ) : (
                        <>
                            <p className='name'>User Name</p>
                            <p className='hospitalName'>Firm Name</p>
                        </>
                    )}
                </div>
                <StarRatingComponent name='rate2' editing={false} starCount={5} value={3} />
                <div className='twoPart'>
                    <div>
                        <p>15</p>
                        <span>Reviews</span>
                    </div>

                    <div>
                        <p>5</p>
                        <span>Following</span>
                    </div>
                </div>

                <div
                    className={`sendMessage ${chosenUser?.allowChat ? '' : 'disabled'}`}
                    onClick={() => openMessageModal()}
                >
                    Send Message
                </div>
            </div>

            <div className='secondCard'>
                <GoogleMapReact
                    bootstrapURLKeys={{ key: 'AIzaSyBgxSzayC4ePVoGH_DKLBSRzPtszez1G2w' }}
                    defaultCenter={center}
                    defaultZoom={zoom}
                    center={
                        loc && loc.branchCoords && loc.branchCoords && loc.branchCoords.lat
                            ? loc.branchCoords
                            : center
                    }
                >
                    {loc && loc.branchCoords && loc.branchCoords && loc.branchCoords.lat ? (
                        <Marker
                            lat={loc && loc.branchCoords && loc.branchCoords.lat}
                            lng={loc && loc.branchCoords && loc.branchCoords.lng}
                            text='My Marker'
                        />
                    ) : (
                        <Marker lat={center.lat} lng={center.lng} text='My Marker' />
                    )}
                </GoogleMapReact>
                <div className='container'>
                    <label>
                        <p>Practice Areas</p>
                        {chosenUser && chosenUser.userType == "DOCTOR" ? (
                            <>
                                {chosenUser.practiceAreas &&
                                    chosenUser.practiceAreas.slice(0, 2).map((item, index) => {
                                        return (
                                            <p
                                                style={{ cursor: 'pointer' }}
                                                key={index}
                                                onClick={() =>
                                                    props.history.push(
                                                        `/doctor-results?practiceArea=${item}`
                                                    )
                                                }
                                            >
                                                {item}{' '}
                                                {index < chosenUser.practiceAreas.length && '|'}{' '}
                                            </p>
                                        );
                                    })}{' '}
                            </>
                        ) : (
                            <p>Administrative Law, Aviation, Divorce </p>
                        )}
                    </label>
                    <label>
                        <p>Address</p>
                        <p>
                            {loc ? (
                                <>
                                    {loc && (
                                        <>
                                            {loc.branchAddress}, {loc.branchCity} ,{' '}
                                            {loc.branchState}, {loc.branchZip}, {loc.branchCountry}
                                        </>
                                    )}
                                </>
                            ) : (
                                'Kalabagan 2nd, lane, Dhaka, Bangladesh'
                            )}
                        </p>
                    </label>
                    <label>
                        <p>Gender</p>
                        <p>{chosenUser && chosenUser.gender ? chosenUser.gender : 'Female'}</p>
                    </label>
                    <label>
                        <p>Email</p>
                        <p>
                            {chosenUser && chosenUser.email
                                ? chosenUser.email
                                : 'DianeJohnson@cooper.com'}
                        </p>
                    </label>
                    {chosenUser && chosenUser.userType == "DOCTOR" && (
                        <label>
                            <p>Specialized Role</p>
                            <p>
                                {chosenUser.specializedRoles
                                    ? chosenUser.specializedRoles
                                    : 'Assisstant'}
                            </p>
                        </label>
                    )}
                    {!chosenUser && (
                        <label>
                            <p>Specialized Role</p>
                            <p>Assisstant</p>
                        </label>
                    )}

                    <label>
                        <p>Phone Number</p>
                        <p>
                            {chosenUser && chosenUser.phoneNo && chosenUser.countryCode ? (
                                <>
                                    ({chosenUser.countryCode}){chosenUser.phoneNo}
                                </>
                            ) : (
                                '(239)555-0108'
                            )}
                        </p>
                    </label>
                </div>
            </div>
            <MessageModal
                messageModal={messageModal}
                setMessageModal={setMessageModal}
                message={message}
                setMessage={setMessage}
                messageError={messageError}
                sendMessage={sendMessage}
            />
        </div>
    );
}

export default ProfileDetail;
