import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { privacyPolicy } from '@/assets/json/MedicalDocs';
import parse from 'html-react-parser';
import { useRef } from 'react';

const privacy = [
    'Introduction',
    'What information do we collect?',
    'How we use the information we collect',
    'Managing your preferences',
    'Requests for information',
    'Listing information',
    'Third-party websites',
    'Transfers of information',
    'Children',
    'California privacy rights',
    'Changes to the policy'
];

const DocModalLayout = ({ title, isOpen, onCancel, onOk, docs, lastUpdatedAt }) => {
    const containerRef = useRef(null);

    const [okButtonDisabled, setOkButtonDisabled] = useState(true);
    // check if the containerRef has scrolled to its end and only then enable the ok button
    const handleScroll = (e) => {
        const { scrollHeight, scrollTop, clientHeight } = e.target;
        const scrollThreshold = 2; // You can adjust this threshold as needed
        if (scrollHeight - scrollTop <= clientHeight + scrollThreshold) {
            setOkButtonDisabled(false);
        }
    };

    return (
        <Modal
            cancelText={'I do not Agree.'}
            okText={`I Agree to the ${title}.`}
            title={title}
            centered
            open={isOpen}
            onOk={onOk}
            onCancel={onCancel}
            okButtonProps={{ disabled: okButtonDisabled }}
        >
            <div
                onScroll={handleScroll}
                ref={containerRef}
                style={{
                    height: 500,
                    overflowY: 'auto',
                    border: '1px solid #ddd',
                    borderRadius: 8,
                    padding: 10,
                    paddingRight: 20
                }}
            >
                {lastUpdatedAt && (
                    <p style={{ fontWeight: 600, marginBottom: 20 }}>
                        Last Updated At:{' '}
                        {new Date(lastUpdatedAt).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </p>
                )}
                {Object.keys(docs).map((key) => (
                    <div key={key}>
                        <h3
                            style={{
                                marginBottom: 10,
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            {key}
                        </h3>
                        <div style={{ marginBottom: 20 }}>
                            {docs[key].map((item, index) => (
                                <p key={index} style={{ marginBottom: 20 }}>{parse(item.line)}</p>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </Modal>
    );
};

export default DocModalLayout;
