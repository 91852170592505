import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';

let { REACT_APP_API_URI } = process.env;
if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const getConsultants = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (props && props.obj) {
            Axios.post(`${REACT_APP_API_URI}/api/consultants/getConsultants`, props.obj)
                .then((res) => {
                    if (res) {
                        if (props.obj.featured) {
                            dispatch({
                                type: Types.GET_FEATURED_CONSULTANTS,
                                payload: {
                                    consultants: res.data.consultants
                                }
                            });
                        } else {
                            dispatch({
                                type: Types.GET_CONSULTANTS,
                                payload: {
                                    consultants: res.data.consultants
                                }
                            });
                        }

                        return resolve(true);
                    }
                })
                .catch((error) => {
                    if (error && error.response) {
                        console.log(error.response.data);
                        // message.error("Consultants Data fetching failed");

                        if (props.obj.featured) {
                            dispatch({
                                type: Types.GET_FEATURED_CONSULTANTS,
                                payload: {
                                    consultants: []
                                }
                            });
                        } else {
                            dispatch({
                                type: Types.GET_CONSULTANTS,
                                payload: {
                                    consultants: []
                                }
                            });
                        }

                        // message.error(error.response.data.message[0].messages[0].message);
                        return resolve(false);
                    }
                });
        }
    });
};
export const getConsultantById = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/consultants/getConsultantDetail/${props.consultantId}`)
            .then((res) => {
                if (res) {
                    console.log('in here', history);

                    dispatch({
                        type: Types.GET_SINGLE_CONSULTANT,
                        payload: {
                            consultantSingle: res.data.consultantSingle
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Consultant Data fetching failed');
                    history.push('/notFound');
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};


export const getConsultantSearchResults = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/consultants/getConsultantSearchResults`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_CONSULTANT_SEARCH_RESULTS,
                        payload: {
                            consultants: res.data.consultants,
                            consultantsCount: res.data.consultantsCount
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Consultants retrieval failed');
                    dispatch({
                        type: Types.GET_CONSULTANT_SEARCH_RESULTS,
                        payload: {
                            consultants: [],
                            consultantsCount: 0
                        }
                    });
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getConsultantStatCounts = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/consultants/getConsultantStatCounts`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_CONSULTANT_STAT_COUNTS,
                        payload: {
                            counts: res.data.counts
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getExtractedTimeSlots = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/consultants/getExtractedTimeSlots`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_CONSULTANT_EXTRACTED_TIME_SLOTS,
                        payload: {
                            availableDays: res.data.availableDays,
                            timeSlots: res.data.timeSlots,
                            consultantAvailability: res.data.consultantAvailability
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    // message.error(error.response.data.message? error.response.data.message : "Server error");
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getMyDoctorTimeSlots = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getExtractedTimeSlots`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_MY_DOCTOR_TIME_SLOTS,
                        payload: {
                            timeSlots: res.data.timeSlots,
                            doctorAvailability: res.data.doctorAvailability
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    // message.error(error.response.data.message? error.response.data.message : "Server error");
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getConsultantForAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/consultants/getConsultantForAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_CONSULTANT_FOR_APPOINTMENT,
                        payload: {
                            consultant: res.data.consultant
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getShortDoctorList = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/consultants/getShortDoctorList`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_SHORT_DOCTOR_LIST,
                        payload: {
                            doctors: res.data.doctors
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getConsultantDashboardInfo = (props, history) => async (dispatch) => {
    try {
        const response = await Axios.get(`${REACT_APP_API_URI}/api/consultants/getConsultantDashboard`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        });
        dispatch({
            type: Types.GET_CONSULTANT_DASHBOARD,
            payload: {
                dashboard: response.data.data
            }
        });
        return true;
    } catch (error) {
        if (error && error.response) {
            console.log(error.response.data);
            message.error(
                error.response.data.message ? error.response.data.message : 'Server error'
            );
            // message.error(error.response.data.message[0].messages[0].message);
            return false;
        }
    }
};

export const getLineChartData = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/consultants/getLineChartData`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_LINE_CHART_DATA,
                        payload: {
                            lineChart: res.data.lineChartData
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    dispatch({
                        type: Types.GET_LINE_CHART_DATA,
                        payload: {
                            lineChart: null
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getPieChartData = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/consultants/getPieChartData`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_PIE_CHART_DATA,
                        payload: {
                            pieChart: res.data.pieChartData
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    dispatch({
                        type: Types.GET_PIE_CHART_DATA,
                        payload: {
                            pieChart: []
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

