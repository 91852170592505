import React, { useState, useEffect, lazy, memo } from 'react';
import '@/assets/css/newProfile.css';
import '../Shared/customModal.css';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as blogActions from '@/actions/blogActions';
import * as branchActions from '@/actions/branchActions';
import * as generalActions from '@/actions/generalActions';
import * as availabilityActions from '@/actions/availabilityActions';
import Routes from '@/routes/private/Doctor';
import LayoutComponent from '../Shared/Layout';

const DoctorLayout = (props) => {
    const [minFields, setMinFields] = useState(false);
    const {userType} = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const {
        location: { pathname },
        profile
    } = props;

    useEffect(() => {
        if(userType !== 'DOCTOR') {
            history.push('/');
        }
        let profileData = {
            userId: props.auth.user._id,
            userType: props.auth.user.userType
        };
        props.actions.getProfile(profileData, props.history);
        props.availabilityActions.getAvailability({ ...props }, props.history);
        let obj = {
            userId: props.auth.userId,
            populateAvailability: true,
            userType: props.auth.userType
        };
        props.branchActions.getBranches({ ...props, obj }, props.history);
        props.generalActions.getHospitalServices({ ...props, obj }, history);
        props.generalActions.getPracticeAreas({ ...props, obj }, history);
    }, []);

    useEffect(() => {
        if (
            profile.firstName &&
            profile.lastName &&
            profile.gender &&
            // prof.currency &&
            profile.dob &&
            (profile?.languages.length || profile?.language) &&
            profile.profileSummary &&
            profile.currency
        ) {
            setMinFields(true);

            dispatch({
                type: 'CLEAR_PROFILE_COMPLETION_ERROR',
                payload: {
                    profileError: ''
                }
            });
        } else {
            if (profile && Object.keys(profile).length > 0) {
                setMinFields(false);
                props.history.push('/doctor/profile');
                dispatch({
                    type: 'PROFILE_COMPLETION_ERROR',
                    payload: {
                        profileError:
                            'Please complete & save all the required fields to enable all features.'
                    }
                });
            }
        }
    }, [profile]);

    return (
        <LayoutComponent
            minFields={minFields}
            routes={Routes.filter((route) => {
                if (minFields || route.name.includes('Account')) {
                    return route;
                }
            })}
        />
    );
    // }
};

// export default UserProfile;
const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    availability: state.availability
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    blogActions: bindActionCreators(blogActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch),
    availabilityActions: bindActionCreators(availabilityActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(DoctorLayout));
