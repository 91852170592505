import React from 'react';
import PBlogCard from './pBlogCard';
import './popularBlogs.css';
import placeholderImg from '../../assets/img/placeholderCover.png';

const PopularBlogs = (props) => {
    const blogs = [1, 2, 3];
    return (
        <div className='pop-blogs-box'>
            <div className='po-box-img'>
                <img
                    src={props.blog.coverImage}
                    alt='Cover image'
                    onError={(e) => (e.target.src = placeholderImg)}
                />
            </div>
            <PBlogCard
                blog={props.blog}
                type={props.type ? props.type : 'normal'}
                body={props.body}
                background={props.background}
            />
        </div>
    );
};

export default PopularBlogs;
