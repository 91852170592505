import React from 'react';
import { Progress } from 'antd';

const ProgressBar = ({ percent, currentVal, totalVal, ...rest }) => {
    return (
        <div style={{ paddingBottom: 10, width: '100%', position: 'relative' }}>
            <Progress style={{ width: '100%' }} percent={percent} {...rest}/>
            <div style={{ position: 'absolute', left: 0, bottom: 5 }}>{0}</div>
            <div style={{ position: 'absolute', right: 0, bottom: 5 }}>{totalVal}</div>
        </div>
    );
};

export default ProgressBar;
