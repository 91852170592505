import React, { useState, memo, useEffect } from 'react';
import './experience.css';
import * as userActions from '@/actions/userActions';
import * as branchActions from '@/actions/branchActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CredentialHeader from './CredentialHeader';
import CredentialsTable from '@/components/NewProfile/Shared/Credentials/CredentialsTable';

const Honor = (props) => {
    const { auth, credentialType, setCredentialType, credentialTypes, branch } = props;
    const [limit, setLimit] = useState(branch ? 5 : 10);
    const [page, setPage] = useState(1);
    const [honorModal, setHonorModal] = useState(false);
    const [editHonor, setEditHonor] = useState(null);
    const [deleteHonor, setDeleteHonor] = useState(null);
    const { pharmacist } = branch ?? {};
    const [formInputs, setFormInputs] = useState([]);
    const dateFormat = 'YYYY/MM/DD';

    useEffect(() => {
        if (
            props.profile &&
            props.profile.honors &&
            props.profile.honors.length !== 0 &&
            !pharmacist
        ) {
            setFormInputs(props.profile.honors);
        } else if (!pharmacist) {
            setFormInputs([]);
        }

        if (branch) {
            if (pharmacist && pharmacist?.honors) {
                setFormInputs(pharmacist.honors);
            } else {
                setFormInputs([]);
            }
        }
    }, [props.profile.honors, branch]);

    return (
        <>
            <CredentialHeader
                auth={auth}
                credentialType={credentialType}
                credentialTypes={credentialTypes}
                setCredentialType={setCredentialType}
                honorModal={honorModal}
                setHonorModal={setHonorModal}
                {...props}
            />
            <CredentialsTable
                auth={auth}
                credentialType={credentialType}
                data={formInputs}
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                honorModal={honorModal}
                setHonorModal={setHonorModal}
                editHonor={editHonor}
                setEditHonor={setEditHonor}
                deleteHonor={deleteHonor}
                setDeleteHonor={setDeleteHonor}
                {...props}
                pharmacist={pharmacist}
                branch={branch}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Honor));
