import React from 'react';
import { Form, Input, Button, Select } from 'antd';
import '../medicalHelp.css';
import { specializationAreas } from '@/assets/json/DoctorPracticeAreas';
import { useHistory } from 'react-router-dom';

const { Option } = Select;

const GetMedicalAdvice = () => {
    const history = useHistory()
    const onFinish = (values) => {
        console.log('Received values of form: ', values);
    };

    return (
        <div className='medicalAdviceContainer'>
            <h2>Get Medical Advice</h2>
            <Form name='medical_advice' className='medicalAdviceForm' onFinish={onFinish}>
                <Form.Item
                    name='title'
                    rules={[
                        {
                            required: true,
                            message: 'Please input the title of your question!'
                        }
                    ]}
                >
                    <Input placeholder='Title of Your Question' />
                </Form.Item>
                <Form.Item
                    name='body'
                    rules={[
                        {
                            required: true,
                            message: 'Please input the body of your question!'
                        }
                    ]}
                >
                    <Input.TextArea placeholder='Body of Your Question' />
                </Form.Item>

                <Form.Item
                    name='jurisdiction'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a jurisdiction!'
                        }
                    ]}
                >
                    <Select placeholder='Select a Jurisdiction'>
                        {/* Add options for jurisdictions here */}
                        <Option value='jurisdiction1'>Jurisdiction 1</Option>
                        <Option value='jurisdiction2'>Jurisdiction 2</Option>
                        {/* ... */}
                    </Select>
                </Form.Item>
                <Form.Item
                    name='practiceArea'
                    rules={[
                        {
                            required: true,
                            message: 'Please select a practice area!'
                        }
                    ]}
                >
                    <Select placeholder='Select a Practice Area'>
                        {specializationAreas.map((practiceArea) => (
                            <Option value='practiceArea1'>{practiceArea}</Option>
                        ))}
                    </Select>
                </Form.Item>

                <Form.Item>
                    <Button type='primary' htmlType='submit'>
                        Submit
                    </Button>
                    <Button
                        type='default'
                        style={{ marginLeft: 10 }}
                        onClick={() => history.goBack()}
                    >
                        Go Back
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default GetMedicalAdvice;
