import React, { useEffect, useState } from 'react';
import { Line, Doughnut } from 'react-chartjs-2';
import * as hospitalActions from '@/actions/hospitalActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Select } from 'antd';
const { Option } = Select;

const data = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
        {
            label: 'First dataset',
            data: [33, 53, 85, 41, 44, 65],
            fill: true,
            backgroundColor: 'rgba(75,192,192,0.2)',
            borderColor: 'rgba(75,192,192,1)'
        },
        {
            label: 'Second dataset',
            data: [33, 25, 35, 51, 54, 76],
            fill: false,
            borderColor: '#742774'
        }
    ]
};

const DataVisualization = (props) => {
    const dispatch = useDispatch();
    const { lineChart, pieChart } = useSelector((state) => state.hospital);
    // const [lineData, setLineData] = useState(data);
    // const [pieData, setPieData] = useState([]);
    const [currentYearLineChart, setCurrentYearLineChart] = useState(moment().year());
    const [currentYearPieChart, setCurrentYearPieChart] = useState(moment().year());

    const getLineChartData = async () => {
        let obj = {
            year: currentYearLineChart
        };
        let res = await dispatch(hospitalActions.getLineChartData({ ...props, obj }));
    };

    const getPieChartData = async () => {
        let obj = {
            year: currentYearPieChart
        };
        let res = await dispatch(hospitalActions.getPieChartData({ ...props, obj }));
    };

    // useEffect(() => {
    //     getLineChartData();
    // }, [currentYearLineChart]);

    // useEffect(() => {
    //     getPieChartData();
    // }, [currentYearPieChart]);


    // useEffect(() => {
    //     if (lineChart) {
    //         setLineData(
    //             lineChart?.monthly?.map((item) => {
    //                 return {
    //                     month: getMonth(item.month),
    //                     value: item.daily.length
    //                 };
    //             })
    //         );
    //     } else {
    //         setLineData([]);
    //     }
    // }, [lineChart]);

    // useEffect(() => {
    //     if (pieChart) {
    //         setPieData(
    //             pieChart?.map((item) => {
    //                 return {
    //                     type: item.doctorName,
    //                     value: item.totalAppointments
    //                 };
    //             })
    //         );
    //     } else {
    //         setPieData([]);
    //     }
    // }, [pieChart]);

    const pieChartData = {
        labels: ['Missed', 'Cancelled',  'Reviewed', 'Success'],
        datasets: [
            {
                data: [30, 30, 15, 20],
                backgroundColor: [
                    'rgb(242,165,152)',
                    'rgb(255,232,157)',
                    'rgb(122,231,125)',
                    'rgb(195,233,151)'
                ],
                hoverBackgroundColor: ['#FF6384', '#36A2EB', '#FFCE56']
            }
        ],

        plugins: {
            labels: {
                render: 'percentage',
                fontColor: ['green', 'white', 'red'],
                precision: 2
            }
        },
        text: '23%'
    };

    return (
        <div id='applicationSetup'>
            <h4 className='sectionTitle' style={{ fontSize: 14 }}>
                Statistics
            </h4>

            <div
                style={{
                    display: 'flex',
                    columnGap: 20,
                    rowGap: 20,
                    flexWrap: 'wrap',
                    backgroundColor: 'white'
                }}
            >
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 10,
                        width: '100%',
                        minWidth: 350,
                        flex: 1,
                        //   boxShadow: '0px 6px 20px rgba(0,0,0,0.1)',
                        padding: '20px'
                    }}
                >
                    <h4 style={{ fontWeight: 400, fontSize: 18 }}>
                        No. of Appointments per month for a given year
                    </h4>
                    <label style={{ display: 'flex', flexDirection: 'column' }}>
                        Choose Year
                        <Select
                            style={{ width: '100% !important' }}
                            value={currentYearLineChart}
                            onChange={(val) => setCurrentYearLineChart(val)}
                        >
                            <Option value={2022}> 2022 </Option>
                            <Option value={2023}> 2023 </Option>
                            <Option value={2024}> 2024 </Option>
                            <Option value={2025}> 2025 </Option>
                        </Select>
                    </label>

                    <Line data={data} />
                </div>

                <div
                    style={{
                        width: '100%',
                        minWidth: 350,
                        flex: 1,
                        justifyContent: 'center',
                        padding: '20px'
                    }}
                >
                    <h4 style={{ fontWeight: 400, fontSize: 18 }}>
                        Number of Appointments according to status
                    </h4>
                    <Doughnut
                        data={pieChartData}
                        options={{
                            elements: {
                                center: {
                                    legend: { display: true, position: 'right' },
                                    text: 'Red is 2/3 the total numbers',
                                    color: '#FF6384', // Default is #000000
                                    fontStyle: 'Arial', // Default is Arial
                                    sidePadding: 20, // Default is 20 (as a percentage)
                                    minFontSize: 20, // Default is 20 (in px), set to false and text will not wrap.
                                    lineHeight: 25 // Default is 25 (in px), used for when text wraps
                                }
                            }
                        }}
                    />
                </div>
            </div>
        </div>
    );
};
export default DataVisualization;
