import React from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';

const ViewDocumentModal = (props) => {
    const { setViewDocument, viewDocument, downloadDocument } = props;
    const { documentTypes } = useSelector((state) => state.documents);

    const foundDocumentType =
        documentTypes.find(
            (documentType) => documentType._id === viewDocument?.document?.documentType
        )?.name || 'General Document';
    return (
        <Modal
            className='customModal '
            title={'View Document'}
            open={viewDocument}
            onOk={() => {
                setViewDocument(null);
            }}
            onCancel={() => {
                setViewDocument(null);
            }}
            footer={null}
        >
            <label
                className='buttonLabel'
                onClick={() => downloadDocument(viewDocument.document && viewDocument.document)}
            >
                Download Document
                <i className='fe fe-download' />
            </label>
            <label>
                Name:
                <p>{viewDocument && viewDocument.document && viewDocument.document.originalname}</p>
            </label>
            <label>
                Type:
                <p>{viewDocument?.document?.documentType && foundDocumentType}</p>
            </label>
            <label>
                Size:
                <p>
                    {viewDocument && viewDocument.document && viewDocument.document.size && (
                        <>{viewDocument.document.size / 1000}Kb</>
                    )}
                </p>
            </label>
            <label>
                Emails shared with:
                <div className='emailItems'>
                    {viewDocument &&
                        viewDocument.receiverEmails &&
                        viewDocument.receiverEmails.map((item) => (
                            <p className='emailItem'>{item}</p>
                        ))}
                </div>
            </label>
        </Modal>
    );
};

export default ViewDocumentModal;
