import React, { useState, useEffect } from 'react';
import '../Shared/Appointments/appointment.css';
import AppointmentHeader from '../Shared/Appointments/AppointmentHeader';
import { FaTasks } from 'react-icons/fa';
import { BsCalendar } from 'react-icons/bs';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, Pagination, Alert, Popover, Button } from 'antd';
import DataTable from '../Shared/Appointments/AppointmentDataTable';
import * as appointmentActions from '@/actions/appointmentActions';
import * as userActions from '@/actions/userActions';
import * as availabilityActions from '@/actions/availabilityActions';
import * as doctorActions from '@/actions/doctorActions';
import AvailabilitySidebar from './locationContent/AvailabilitySidebar';
import AvailabilityModal from './locationContent/AvailabilityModal';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import validator from 'validator';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import listPlugin from '@fullcalendar/list';
import '@/assets/css/fullcalendar.css';
import ShowEventModal from '../Shared/Appointments/ViewCalendarEvent';
import CurrentTime from '../Shared/Appointments/CurrentTime';
import CreateAppointmentModal from '../Shared/Appointments/modals/CreateAppointment';
import EditAppointmentModal from '../Shared/Appointments/modals/EditAppointment';
import CompleteAppointmentConfirmation from '../Shared/Appointments/modals/CompleteAppointmentConfirmation';
import PageHeader from '../Shared/PageHeader';
import { ExclamationOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Appointment = (props) => {
    const [open, setOpen] = useState(false);
    const [calenderView, setCalenderView] = useState(false);
    // const [appointments, setAppointments] = useState ([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [appointmentModal, setAppointmentModal] = useState(false);
    const [editAppointmentItem, setEditAppointmentItem] = useState(null);
    const [editAppointmentModal, setEditAppointmentModal] = useState(false);
    const [createAvailabilityModal, setCreateAvailabilityModal] = useState(false);
    const [selectedService, setSelectedService] = useState({
        value: '',
        isValid: true,
        message: ''
    });
    const [sidebar, setSidebar] = useState(false);
    const {
        branches,
        auth,
        doctorTimeSlots,
        availabilities,
        appointments: { appointments, appointmentsCount, calendarEvents },
        history
    } = props;
    const { user: me } = auth;
    const [showEvent, setShowEvent] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    //Appointment data
    const [loading, setLoading] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [user, setUser] = useState({ name: '', id: '' });
    const [date, setDate] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
    const [paymentMode, setPaymentMode] = useState('In-Person');
    const [consultationMode, setConsultationMode] = useState('In-Person');
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [availabilityData, setAvailabilityData] = useState(null);
    const [schedule, setSchedule] = useState(null);
    const [chosenUser, setChosenUser] = useState({
        value: '',
        message: '',
        isValid: true
    });
    //error messages
    const [userEmailMsg, setUserEmailMsg] = useState('');
    const [dateMsg, setDateMsg] = useState('');
    const [doctorMsg, setDoctorMsg] = useState('');
    const [timeSlotMsg, setTimeSlotMsg] = useState('');
    const { userType } = auth;
    let tempTimezone = moment.tz.guess();
    const { userProfile } = useSelector((state) => state.auth);
    const { stripeVerified } = userProfile;
    const { hospitalServices:services } = useSelector((state) => state.general);

    const [completeAppointmentModal, setCompleteAppointmentModal] = useState(false);
    const [completeAppointmentModalItem, setCompleteAppointmentModalItem] = useState(null);

    const updateAppointmentInitialize = async (item) => {
        setSelectedTimeSlot(item.appointmentTime);
        setDate(item.appointmentDate);
        setPaymentMode(item.paymentMode);
        setUserEmail(item.userEmail);
        setSelectedBranch(item.branch._id);
        setConsultationMode(item.consultationMode);
    };

    useEffect(() => {
        setSelectedService({
            ...selectedService,
            value: services.filter((item) => item.name.includes('Free'))[0]?._id
        });
    }, [services]);
    useEffect(() => {
        let tab = new URLSearchParams(window.location.search).get('tab');
        if (tab == 'list') {
            setCalenderView(false);
        } else if (tab == 'calendar') {
            setCalenderView(true);
        }
    }, []);
    useEffect(() => {
        if (selectedTimeSlot && doctorTimeSlots && !doctorTimeSlots.includes(selectedTimeSlot)) {
            setSelectedTimeSlot('');
        }
    }, [date, doctorTimeSlots]);

    useEffect(() => {
        if (branches && branches[selectedAddressIndex] && branches[selectedAddressIndex]._id) {
            setSchedule(
                availabilities &&
                    availabilities.filter((item, index) => item.branch == selectedAddress)[0]
            );
        } else {
            setSchedule(availabilities[0]);
        }
        if (branches && branches[selectedAddressIndex]) {
            setSelectedAddress(branches[selectedAddressIndex]._id);
            setAvailabilityData(branches[selectedAddressIndex]);
        }
    }, [branches && branches[selectedAddressIndex], availabilities, selectedAddress]);
    const getCalendarEvents = async () => {
        let obj = {};
        await props.actions.getCalendarEvents({ ...props, obj }, props.history);
    };
    const cancelAppointment = async (id) => {
        let obj = {
            appointmentId: id
        };
        let cancelledAppointment = await props.appointmentActions.cancelAppointment(
            { ...props, obj },
            history
        );
        if (cancelledAppointment) {
            getAppointments();
            getCalendarEvents();
        }
    };

    const approveAppointment = async (id) => {
        let obj = {
            appointmentId: id
        };
        let approvedAppointment = await props.appointmentActions.approveAppointment(
            { ...props, obj },
            history
        );
        if (approvedAppointment) {
            getAppointments();
            getCalendarEvents();
        }
    };
    const getMyServices = async () => {
        let obj = {
            doctorId: auth.userId
        };
        await props.doctorActions.getMyServices({ ...props, obj }, history);
    };

    useEffect(() => {
        getAppointments();
        getMyServices();
    }, []);

    useEffect(() => {
        getCalendarEvents();
    }, [me?.syncGoogleCalendar, me?.syncOutlookCalendar]);

    useEffect(() => {
        if (date && selectedBranch) {
            getTimeSlots();
        }
    }, [date, selectedBranch]);

    useEffect(() => {
        if (branches && branches.length > 0) {
            setSelectedBranch(branches[0]._id);
        }
    }, [branches]);

    const getTimeSlots = async () => {
        let obj = {
            doctorId: props.auth.userId,
            branchId: selectedBranch,
            date: date ? moment(date).format('DD-MM-YYYY') : moment(new Date()).format('DD-MM-YYYY')
        };
        props.doctorActions.getExtractedTimeSlots({ ...props, obj }, history);
    };

    const getAppointments = async () => {
        let obj = {
            page: page,
            perPage: limit
        };
        await props.appointmentActions.getMyAppointments({
            ...props,
            obj
        });
    };

    const openEditAppointment = async (item) => {
        setEditAppointmentItem(item);
        updateAppointmentInitialize(item);
        setEditAppointmentModal(true);
    };

    const appointmentVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { duration: 0.5 }
        },
        exit: {
            opacity: 0,
            transition: { ease: 'easeIn', duration: 0.5 }
        }
    };

    const onSelect = (value) => {
        setDate(value);
        setAppointmentModal(true);
    };

    const clearAppointmentData = () => {
        setDate('');
        setChosenUser({ value: '', message: '', isValid: true });
        setUser({ name: '', id: '' });
        if (branches && branches.length > 0) {
            setSelectedBranch(branches[0]._id);
        }
    };

    const checkValidity = async () => {
        let valid = true;
        if (!userEmail || (userEmail && !validator.isEmail(userEmail))) {
            if (!userEmail) {
                setUserEmailMsg('User email is required');
            } else {
                setUserEmailMsg('User email must be a valid email address');
            }
            valid = false;
        } else {
            setUserEmailMsg('');
        }

        if (!date) {
            setDateMsg('Appointment Date is required');
            valid = false;
        } else {
            setDateMsg('');
        }

        if (!selectedTimeSlot) {
            setTimeSlotMsg('Time Slot is required');
            valid = false;
        } else {
            setTimeSlotMsg('');
        }

        if (!selectedService.value) {
            setSelectedService({
                ...selectedService,
                isValid: false,
                message: 'Service is required'
            });
            valid = false;
        } else {
            setSelectedService({ ...selectedService, isValid: true, message: '' });
        }
        return valid;
    };
    const submitForm = async () => {
        let validity = checkValidity();
        setLoading(true);

        if (validity) {
            let obj = {
                time: selectedTimeSlot,
                date: date && moment(new Date(date).toISOString()).format('DD/MM/YYYY'),
                consultationMode: consultationMode,
                paymentMode: paymentMode,
                service: selectedService.value,
                doctor: auth?.userId,
                hospital: auth?.user?.hospital?._id,
                userEmail: userEmail,
                requestTo: 'PATIENT',
                branch: selectedBranch,
                timezone: availabilityData.availability.timezone || tempTimezone
            };

            if (!editAppointmentItem) {
                let createApp = await props.appointmentActions.requestAppointmentByDoctor(
                    { ...props, obj },
                    props.history
                );

                if (createApp) {
                    setAppointmentModal(false);
                    clearAppointmentData();
                    await getAppointments();
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } else {
                obj.appointmentId = editAppointmentItem._id;
                let updateApp = await props.appointmentActions.updateAppointment(
                    { ...props, obj },
                    props.history
                );

                if (updateApp) {
                    setEditAppointmentModal(false);
                    setEditAppointmentItem(null);
                    clearAppointmentData();
                    await getAppointments();
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        } else {
            setLoading(false);
        }
    };

    const openEvent = async (evt) => {
        setSelectedEvent(evt);
        setShowEvent(true);
    };
    let appEvents =
        appointments &&
        appointments
            ?.filter((item) => item.appointmentStatus == 'ACCEPTED')
            .map((item, index) => {
                let obj = {};
                obj.title = `Appointment with ${item?.user?.firstName} ${item?.user?.lastName}`;
                obj.description = `Appointment with ${item?.user?.firstName} ${
                    item?.user?.lastName
                } on ${moment(item.appointmentStartTime, 'hh:mm a')}, ${
                    item.appointmentDate && moment(item.appointmentDate).format('DD/MM/YYYY')
                }`;
                obj.start = moment(
                    `${moment(item?.appointmentDate).format('DD/MM/YYYY')} ${
                        item.appointmentStartTime
                    }`,
                    'DD/MM/YYYY hh:mm'
                ).toISOString();

                obj.end = moment(
                    `${moment(item?.appointmentDate).format('DD/MM/YYYY')} ${
                        item.appointmentEndTime
                    }`,
                    'DD/MM/YYYY hh:mm'
                ).toISOString();
                obj = { ...obj, ...item };
                return obj;
            });

    let events = [...appEvents, ...calendarEvents];

    return (
        <div>
            <PageHeader
                heading={
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        Appointments{' '}
                        {!stripeVerified && (
                            <Popover
                                content={
                                    <div>
                                        To schedule online appointments or make online payments,
                                        please ensure your Stripe account is connected. Go To{' '}
                                        <Link
                                            style={{
                                                fontWeight: 'bold',
                                                textDecoration: 'underline'
                                            }}
                                            to='/doctor/dashboard'
                                        >
                                            Dashboard
                                        </Link>
                                    </div>
                                }
                            >
                                <div
                                    style={{
                                        height: 30,
                                        width: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '50%',
                                        backgroundColor: 'var(--tertiary)',
                                        color: 'white',
                                        fontSize: 20
                                    }}
                                >
                                    <ExclamationOutlined />
                                </div>
                            </Popover>
                        )}
                    </div>
                }
                subHeading={
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        <CurrentTime />
                        <Button onClick={() => setSidebar(true)} type='primary'>
                            Set Availability
                        </Button>
                    </div>
                }
                actions={() => (
                    <div className='app-switch'>
                        <FaTasks
                            onClick={() => {
                                setCalenderView(false);
                                history.push(
                                    `/${
                                        userType == 'DOCTOR'
                                            ? 'doctor'
                                            : userType == 'HOSPITAL'
                                            ? 'hospital'
                                            : userType == 'PATIENT' && 'user'
                                    }/appointments?tab=list`
                                );
                            }}
                        />
                        <p>|</p>
                        <BsCalendar
                            onClick={() => {
                                setCalenderView(true);
                                history.push(
                                    `/${
                                        userType == 'DOCTOR'
                                            ? 'doctor'
                                            : userType == 'HOSPITAL'
                                            ? 'hospital'
                                            : userType == 'PATIENT' && 'user'
                                    }/appointments?tab=calendar`
                                );
                            }}
                        />
                    </div>
                )}
            />

            <AnimatePresence>
                {sidebar && (
                    <AvailabilitySidebar
                        {...props}
                        setSidebar={setSidebar}
                        sidebar={sidebar}
                        setCreateAvailabilityModal={setCreateAvailabilityModal}
                        createAvailabilityModal={createAvailabilityModal}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        selectedAddressIndex={selectedAddressIndex}
                        setSelectedAddressIndex={setSelectedAddressIndex}
                        schedule={schedule}
                        setSchedule={setSchedule}
                        branches={branches}
                    />
                )}
            </AnimatePresence>
            <AnimatePresence exitBeforeEnter>
                {calenderView && (
                    <motion.div
                        key='1'
                        variants={appointmentVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        className='appointment-calendar'
                    >
                        {/* <Calendar onSelect={onSelect}  /> */}
                        <FullCalendar
                            plugins={[
                                dayGridPlugin,
                                interactionPlugin,
                                timeGridPlugin,
                                momentPlugin,
                                listPlugin
                            ]}
                            initialView='dayGridMonth'
                            displayEventTime={true}
                            // displayEventEnd={true}
                            selectable={true}
                            editable={true}
                            dragRevertDuration={1}
                            timeFormat='hh:mm'
                            eventTimeFormat={{
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            }}
                            dateClick={(val) => onSelect(val.dateStr)}
                            eventClick={(val) => openEvent(val.event)}
                            headerToolbar={{
                                left: 'prev,next',
                                center: 'title',
                                right: 'timeGridDay,timeGridWeek,dayGridMonth,listWeek'
                            }}
                            events={events}
                        />
                    </motion.div>
                )}
                {!calenderView && (
                    <div>
                        <AppointmentHeader
                            open={appointmentModal}
                            setOpen={setAppointmentModal}
                            auth={auth}
                        />

                        <DataTable
                            appointments={appointments}
                            open={open}
                            setOpen={setOpen}
                            auth={auth}
                            openEditAppointment={openEditAppointment}
                            cancelAppointment={cancelAppointment}
                            approveAppointment={approveAppointment}
                            limit={limit}
                            page={page}
                            userType={userType}
                            history={history}
                        />
                    </div>
                )}
            </AnimatePresence>
            {/* <AppointmentModal open={open} setOpen={setOpen} /> */}
            <CreateAppointmentModal
                {...props}
                branches={branches}
                selectedBranch={selectedBranch}
                setSelectedBranch={setSelectedBranch}
                selectedTimeSlot={selectedTimeSlot}
                setSelectedTimeSlot={setSelectedTimeSlot}
                myTimeSlots={doctorTimeSlots}
                date={date}
                setDate={setDate}
                appointmentModal={appointmentModal}
                setAppointmentModal={setAppointmentModal}
                clearAppointmentData={clearAppointmentData}
                paymentMode={paymentMode}
                setPaymentMode={setPaymentMode}
                consultationMode={consultationMode}
                setConsultationMode={setConsultationMode}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                submitForm={submitForm}
                chosenUser={chosenUser}
                setChosenUser={setChosenUser}
                user={user}
                setUser={setUser}
                loading={loading}
                setLoading={setLoading}
                userEmailMsg={userEmailMsg}
                dateMsg={dateMsg}
                setDateMsg={setDateMsg}
                doctorMsg={doctorMsg}
                setDoctorMsg={setDoctorMsg}
                setUserEmailMsg={setUserEmailMsg}
                timeSlotMsg={timeSlotMsg}
                setTimeSlotMsg={setTimeSlotMsg}
                services={services || []}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
            />
            {createAvailabilityModal && (
                <AvailabilityModal
                    schedule={schedule}
                    createAvailabilityModal={createAvailabilityModal}
                    selectedAddress={selectedAddress}
                    selectedAddressIndex={selectedAddressIndex}
                    setSelectedAddress={setSelectedAddress}
                    setSelectedAddressIndex={setSelectedAddressIndex}
                    setCreateAvailabilityModal={setCreateAvailabilityModal}
                    {...props}
                />
            )}

            <EditAppointmentModal
                {...props}
                branches={branches}
                selectedBranch={selectedBranch}
                setSelectedBranch={setSelectedBranch}
                selectedTimeSlot={selectedTimeSlot}
                setSelectedTimeSlot={setSelectedTimeSlot}
                myTimeSlots={doctorTimeSlots}
                date={date}
                setDate={setDate}
                appointmentModal={editAppointmentModal}
                setEditAppointmentModal={setEditAppointmentModal}
                clearAppointmentData={clearAppointmentData}
                paymentMode={paymentMode}
                setPaymentMode={setPaymentMode}
                consultationMode={consultationMode}
                setConsultationMode={setConsultationMode}
                userEmail={userEmail}
                setUserEmail={setUserEmail}
                submitForm={submitForm}
                chosenUser={chosenUser}
                setChosenUser={setChosenUser}
                user={user}
                setUser={setUser}
                loading={loading}
                setLoading={setLoading}
                userEmailMsg={userEmailMsg}
                dateMsg={dateMsg}
                setDateMsg={setDateMsg}
                setUserEmailMsg={setUserEmailMsg}
                timeSlotMsg={timeSlotMsg}
                setTimeSlotMsg={setTimeSlotMsg}
                editAppointmentItem={editAppointmentItem}
                services={services || []}
                selectedService={selectedService}
                setSelectedService={setSelectedService}
            />

            <ShowEventModal
                showEvent={showEvent}
                setShowEvent={setShowEvent}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
                history={history}
                auth={auth}
            />
            <Pagination
                style={{ marginTop: 20 }}
                showQuickJumper
                current={page}
                total={appointmentsCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />

            {completeAppointmentModal && completeAppointmentModalItem && (
                <CompleteAppointmentConfirmation
                    {...props}
                    userType={userType}
                    completeAppointmentModal={completeAppointmentModal}
                    appointment={completeAppointmentModalItem}
                    getAppointments={getAppointments}
                    onClose={() => {}}
                    acceptMethod={() => {}}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    // branches: state.auth.userProfile.hospitalBranches,
    branches: state.branch.branches,
    availability: state.availability,
    availabilities: state.availability.availabilities,
    myTimeSlots: state.availability.myTimeSlots,
    doctorTimeSlots:
        state.doctors &&
        state.doctors.doctorForAppointment &&
        state.doctors.doctorForAppointment.timeSlots,
    appointments: state.appointments,
    services: state.doctors?.services
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    availabilityActions: bindActionCreators(availabilityActions, dispatch),
    appointmentActions: bindActionCreators(appointmentActions, dispatch),
    doctorActions: bindActionCreators(doctorActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
