import React, { useState } from 'react';
import { Select, Popover, Row, Col } from 'antd';
import userTypes from '@/assets/json/UserTypes';
import '@/assets/css/AppointmentHeader.css';

const { Option } = Select;
const sortby = ['Highest Rated', 'Newest Rated', 'Newest', 'Oldest'];

const sortOrder = ['Ascending', 'Descending'];
const types = [...userTypes.map((userType)=> userType.label), 'Blogs'].slice(1);


const AppointmentHeader = (props) => {
    const { type, setType } = props;
    const [popover, setPopover] = useState(true);
    return (
        <Row style={{marginBottom: 20, justifyContent: 'flex-end'}} gutter={[10, 10]}>
            <Col xs={24} lg={6}>
                <Popover
                    overlayClassName={'favouritePopover'}
                    placement='topRight'
                    content={
                        <div
                            style={{
                                width: 300,
                                color: 'white',
                                fontSize: 11,
                                alignItems: 'flex-start',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '6px'
                            }}
                        >
                            Click the dropdown to select between different favourite types: Doctors,
                            Hospitals and Blogs{' '}
                            <button
                                style={{
                                    borderRadius: 20,
                                    backgroundColor: 'var(--primary)',
                                    color: 'white',
                                    padding: '3px 15px',
                                    margin: '5px 10px 5px auto',
                                    cursor: 'pointer'
                                }}
                                onClick={() => setPopover(false)}
                            >
                                Got it
                            </button>
                        </div>
                    }
                    open={popover}
                >
                    <Select
                        value={type}
                        onChange={(val) => setType(val)}
                        placeholder='Types'
                        showSearch
                        style={{ width: '100%' }}
                    >
                        {types.map((item, index) => (
                            <Option disabled={!['Patient', 'Hospital/Clinic', 'Doctor', 'Nurse', 'Blogs', 'Consultant', 'Pharmacy'].includes(item)} key={index} value={item}>
                                {item}
                            </Option>
                        ))}
                    </Select>
                </Popover>
            </Col>

            <Col xs={24} lg={6}>
                <Select placeholder='Sort Order' showSearch style={{ width: '100%' }}>
                    {sortOrder.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Col>
        </Row>
    );
};

export default AppointmentHeader;
