import React, { useState, useEffect } from 'react';
import * as doctorActions from '@/actions/doctorActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as blogActions from '@/actions/blogActions';
import { useParams } from 'react-router-dom';
import BlogCard from '../../../Shared/Blogs/PopCard';
import { Select, Pagination, Empty } from 'antd';
import '@/assets/css/blogGallery.css';

const HospitalBlogs = (props) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [category, setCategory] = useState(null);
    const [search, setSearch] = useState('');
    const [count, setCount] = useState('');

    //  const [posts, setPosts] = useState([]);
    const [title, setTitle] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [cats, setCats] = useState(null);
    const [sort, setSort] = useState(null);
    const [date, setDate] = useState(null);
    const [criteria, setCriteria] = useState('');
    const [disableInput, setDisableInput] = useState({
        title: false,
        category: false,
        date: false,
        sortby: false
    });
    const { id } = useParams();

    const { Option } = Select;
    const dateFormat = 'YYYY/MM/DD';

    const getUserPosts = async () => {
        let obj = {
            category: cats,
            date,
            skip: 0,
            page: page,
            perPage: limit,
            userId: id,
            userType: "HOSPITAL",
            //   hospital: props.auth.user.hospital,
            //   sortBy: sort,
            search: searchTerm
        };

        if (sort) {
            obj.sort = sort;
        }

        if (search) {
            obj.search = search;
        }
        props.blogActions.getUserPosts({ obj, ...props });
    };

    useEffect(() => {
        getUserPosts();
    }, [cats, date, page, limit, sort, searchTerm]);

    let { posts, postsCount } = props.blogs;
    return (
        <div className='view-container' style={{ backgroundColor: 'transparent' }}>
            <div className='bgallery-body-container' style={{ backgroundColor: 'transparent' }}>
                {posts &&
                    posts.length !== 0 &&
                    posts.map((item, index) => {
                        return (
                            <BlogCard
                                blog={item}
                                key={index}
                                history={props.history}
                                index={index}
                            />
                        );
                    })}
            </div>

            {posts && !posts.length && <Empty description='No Blogs Available' />}

            <Pagination
                style={{
                    margin: 20,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                }}
                current={page}
                total={postsCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    hospital: state.hospital.hospitalSingle,
    blogs: state.blog,
    profile: state.auth.userProfile
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(doctorActions, dispatch),
    blogActions: bindActionCreators(blogActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(HospitalBlogs);
