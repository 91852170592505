import { Col, Row, Select } from 'antd';
import React from 'react';

const { Option } = Select;
const sortby = ['Highest Rated', 'Newest Rated', 'Newest', 'Oldest'];

const sortOrder = ['Ascending', 'Descending'];
const ProspectsHeader = () => {
    return (
        <Row gutter={[10, 10]} style={{marginBottom: 20, justifyContent: 'flex-end'}}>
            <Col flex={1} xs={24} lg={6}>
                {' '}
                <Select placeholder='Sort By' showSearch style={{ width: '100%' }}>
                    {sortby.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Col>

            <Col flex={1} xs={24} lg={6}>
                {' '}
                <Select placeholder='Sort Order' showSearch style={{ width: '100%' }}>
                    {sortOrder.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Col>
        </Row>
    );
};

export default ProspectsHeader;
