import React, { useEffect, useState } from 'react';
import * as userActions from '@/actions/userActions';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Modal } from 'antd';
import Table from '../Table';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import {Link} from 'react-router-dom'


const Hospitals = (props) => {
    const { limit, page } = props;
    const [messageModal, setMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const [messageHospital, setMessageHospital] = useState(null);
    const [messageError, setMessageError] = useState('');
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const {
        favourites: { hospitals }
    } = auth;

    const getHospitals = () => {
        let obj = {
            favouriteType: 'Hospitals',
            userId: auth.userId,
            perPage: limit,
            page: page
        };
        dispatch(userActions.getFavourites({ ...props, obj }));
    }

    useEffect(() => {
        getHospitals()
    }, [limit, page]);

    useEffect(() => {
        if (!messageModal) {
            setMessageHospital(false);
        }
    }, [messageModal]);

    const sendMessage = async (hospital) => {
        if (!message) {
            setMessageError('Message field cannot be empty');
        } else {
            setMessageError('');
            let obj = {
                message: message,
                receiver: messageHospital && messageHospital._id && messageHospital._id,
                receiverType: messageHospital && messageHospital.userType && messageHospital.userType
            };
            let sendMessage = await props.messagingActions.createConversation(
                { ...props, obj },
                props.history
            );
            if (sendMessage) {
                setMessageModal(false);
                setMessage('');
            } else {
                console.log('error');
            }
        }
    };

    const removeFromFavourites = async (id) => {
        let obj = {
            favouriteId: id
        };
        let removeFavItem = await dispatch(
            userActions.removeFromFavourites({ obj, ...props }, props.history)
        );
        if (removeFavItem) {
            getHospitals();
        }
    };


    const columns = [
        {
            title: <span className="dragHandler">Hospital</span>,
            width: 500,
            dataIndex: 'hospitalName',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.hospitalName) return -1; // Handle empty values
                if (!b.hospitalName) return 1; // Handle empty values
                return a.hospitalName.localeCompare(b.hospitalName);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            },
            render: (hospitalName, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <Link to={`/hospital-view/${record._id}`}><div>{hospitalName}</div></Link>;
            }
        },
        {
            title: <span className="dragHandler">Email</span>,
            width: 200,
            dataIndex: 'email',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.email) return -1; // Handle empty values
                if (!b.email) return 1; // Handle empty values
                return a.email.localeCompare(b.email);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            }
        },
        {
            title: <span className="dragHandler">Phone No.</span>,
            width:200,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.phone) return -1; // Handle empty values
                if (!b.phone) return 1; // Handle empty values
                return a.phone.localeCompare(b.phone);
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                if (!record.phoneNo) return <div>Not available</div>;
                return <div>{'(' + record.countryCode + ') -' + record.phoneNo}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            }
        },
        {
            title: <span className="dragHandler">Action</span>,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        <Button type='default' onClick={() => removeFromFavourites(record._id)}>
                            Delete
                        </Button>
                    </div>
                );
            }
        }
    ];

    const numberOfBlankRows = limit - hospitals.length;
    let dataSource = [
        ...hospitals?.map((item) => item.favouriteItem),
        ...Array.from({ length: numberOfBlankRows }).map(() => ({}))
    ];
    return (
        <>
            <Table tableName={'Favourite Hospitals'} columns={columns} data={dataSource} />
            {messageModal && (
                <Modal
                    className='addReview'
                    title='Send a message'
                    open={messageModal}
                    onCancel={() => {
                        setMessageModal(false);
                    }}
                    footer={null}
                >
                    <label>
                        <span>Message</span>
                        <textarea
                            placeholder='Description'
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                        />
                        <p className='error'>{messageError && messageError}</p>
                    </label>
                    <button className='red-btn' onClick={() => sendMessage()}>
                        Send Message
                    </button>
                </Modal>
            )}
        </>
    );
};

export default Hospitals;
