import React, { useState, useEffect } from 'react';
import { Button, Input, Modal, message } from 'antd';

const DocumentTypesModal = (props) => {
    const [documentTypeName, setDocumentTypeName] = useState('');
    const { setIsModalOpen, isModalOpen, documentTypeData, item } = props;
    const [id, setId] = useState(null);

    useEffect(() => {
        if (item) {
            setDocumentTypeName(item.name);
            setId(item.id);
        }
    }, [item]);

    const submit = async () => {
        if (!documentTypeName) return message.error('Please enter document type name');
        let obj = {
            userId: props.auth.userId,
            name: documentTypeName
        };
        if (id) {
            obj.documentTypeId = item._id;
        }
        let createDocumentType = await props.generalActions.createDocumentType(
            { ...props, obj },
            props.history
        );
        if (createDocumentType) {
            await props.generalActions.getDocumentTypes({ ...props, obj });
            setIsModalOpen(false);
            setDocumentTypeName('');
        }
    };

    return (
        <Modal
            className='customModal'
            centered
            title={item ? 'Edit Document Type' : 'Add Document Type'}
            open={isModalOpen}
            onOk={() => {
                setIsModalOpen(false);
                setDocumentTypeName('');
            }}
            onCancel={() => {
                setIsModalOpen(false);
                setDocumentTypeName('');
            }}
            footer={null}
        >
            <div className='post-modal-options-grid2'>
                <label>
                    <p>Type Name:</p>

                    <Input
                        placeholder='Type Name'
                        value={documentTypeName}
                        onChange={(e) => setDocumentTypeName(e.target.value)}
                    />
                </label>
            </div>
            <Button type='primary' onClick={submit}>
                <p>{item ? 'Update' : 'Add'}</p>
            </Button>
        </Modal>
    );
};

export default DocumentTypesModal;
