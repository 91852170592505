import React from 'react';
import { PublicClientApplication } from '@azure/msal-browser';

function MicrosoftLogin(props) {
    const { authCallback, children, clientId, redirectUri } = props;

    const Auth = async () => {
        const msalConfig = {
            auth: {
                clientId: clientId,
                authority: 'https://login.microsoftonline.com/common/',
                redirectUri: redirectUri ? redirectUri : 'https://localhost:3000/'
            },
            cache: {
                cacheLocation: 'localStorage', // This configures where your cache will be stored
                storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
            }
        };
        const userAgentApplication = new PublicClientApplication(msalConfig);

        const loginRequest = {
            scopes: ['profile'],
            prompt: 'consent'
        };
        let response;
        try {
            response = await userAgentApplication.loginPopup(loginRequest);
            console.log(response);
        } catch (error) {
            console.log(error);
        }

        return authCallback(response);
    };
    return <div onClick={() => Auth()}>{children}</div>;
}

export default MicrosoftLogin;
