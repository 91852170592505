import { useState, useEffect } from 'react';

const useWindowSize = () => {
    const isClient = typeof window === 'object';

    const [windowSize, setWindowSize] = useState({
        width: isClient ? window.innerWidth : undefined,
        height: isClient ? window.innerHeight : undefined
    });

    useEffect(() => {
        if (!isClient) {
            return; // Do nothing if not running in a browser environment
        }

        // Function to update window size
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }

        // Add event listener to listen for window resize events
        window.addEventListener('resize', handleResize);

        // Remove event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [isClient]); // Pass isClient as a dependency to re-run the effect when it changes

    return windowSize;
};

export default useWindowSize;
