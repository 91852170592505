// const hospitalWorkTypes = [
//     { id: 1, title: 'Owner of a hospital/ clinic' },
//     { id: 2, title: 'I am an independent doctor' },
//     { id: 3, title: 'I work for a hospital/ clinic' }
// ];

const hospitalWorkTypes = [
    { id: 1, title: 'Owner of a Hospital / Clinic' },
    { id: 2, title: 'Owner of a Nursing Agency' },
    { id: 3, title: 'Owner of a Consulting Agency' },
    { id: 4, title: 'Owner of a Caregiver Agency' },
    { id: 5, title: 'Owner of a Therapeutic Agency' }
];

const doctorWorkTypes = [
    { id: 1, title: 'Independent doctor' },
    { id: 2, title: 'Employed by a healthcare organization' },
    { id: 3, title: 'Owner of a Hospital or a Clinic' }
];

const nurseWorkTypes = [
    { id: 1, title: 'Independent nursing services provider' },
    { id: 2, title: 'Employed by a healthcare organization' },
    { id: 3, title: 'Owner of a Nursing Agency' }
];
const caregiverWorkTypes = [
    { id: 1, title: 'Independent Caregiver' },
    { id: 2, title: 'Employed by a healthcare organization' },
    { id: 3, title: 'Owner of a Caregiver Agency' }
];
const therapistWorkTypes = [
    { id: 1, title: 'Independent Therapist' },
    { id: 2, title: 'Employed by a healthcare organization' },
    { id: 3, title: 'Owner of a Therapeutic Agency' }
];
const consultantWorkTypes = [
    { id: 1, title: 'Independent Consultant' },
    { id: 2, title: 'Employed by a Healthcare Organization' },
    { id: 3, title: 'Owner of a Consulting Agency' }
];

const getWorkTypes = (userType) => {
    let workTypes = [];
    switch (userType) {
        case 'DOCTOR':
            workTypes = doctorWorkTypes;
            break;
        case 'NURSE':
            workTypes = nurseWorkTypes;
            break;
        case 'CAREGIVER':
            workTypes = caregiverWorkTypes;
            break;
        case 'THERAPIST':
            workTypes = therapistWorkTypes;
            break;
        case 'CONSULTANT':
            workTypes = consultantWorkTypes;
            break;
        default:
            workTypes = hospitalWorkTypes;
            break;
    }
    return workTypes;
};

export {
    hospitalWorkTypes,
    nurseWorkTypes,
    caregiverWorkTypes,
    therapistWorkTypes,
    consultantWorkTypes,
    getWorkTypes
};
