import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './Admin/Login';
import Dashboard from './Admin/Dashboard';

function AdminRoutes() {
    return (
        <Switch>
            <Route exact path='/admin/' component={Login} />
            <Route path='/admin/' component={Dashboard} />
        </Switch>
    );
}

export default AdminRoutes;
