import React from 'react';
import { DatePicker, Row, Col, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const AppointmentHeader = (props) => {
    const { type, history, handleModal, userType, setOpen } = props;

    return (
        <Row gutter={[10, 10]} style={{ marginBottom: 20, justifyContent: 'flex-end' }}>
            <Col xs={24} lg={6}>
                <DatePicker style={{ width: '100%' }} name='dob' placeholder='Search by date' />
            </Col>
            <Col xs={24} lg={6}>
                <Button
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                    type='primary'
                    onClick={() => {
                        if (userType == "PATIENT") {
                            history.push('/patient/appointments/new');
                        } else {
                            setOpen && setOpen(true);
                        }
                    }}
                >
                    New Appointment
                </Button>
            </Col>
        </Row>
    );
};

export default AppointmentHeader;
