import React, { useState, useEffect } from 'react';
import FileDownload from 'js-file-download';
import Axios from 'axios';
import { Modal } from 'antd';

function CheckFiles(props) {
    const { appointment, viewDocumentModal, setViewDocumentModal, view, setView } = props;

    const downloadDocument = (document) => {
        if (document) {
            console.log(document);
            Axios({
                url: `/api/documents/files/${document.filename}`,
                method: 'GET',
                responseType: 'blob'
            }).then((res) => FileDownload(res.data, `${document.originalname}`));
        }
    };
    return (
        (<Modal
            className='customModal '
            title={view == 'Files' ? 'View Submitted Files' : 'View Submitted Notes'}
            open={viewDocumentModal}
            onOk={() => {
                setViewDocumentModal(null);
            }}
            onCancel={() => {
                setViewDocumentModal(null);
            }}
            footer={null}
        >
            <div className='viewAppFiles'>
                {view == 'Files' ? (
                    <>
                        {appointment?.files.length > 0 ? (
                            appointment?.files?.map((item, index) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        padding: '10px 0px'
                                    }}
                                    key={index}
                                >
                                    {index + 1}.
                                    <p onClick={() => downloadDocument(item)}>
                                        {item.originalname}
                                    </p>
                                    <i
                                        className='fe fe-download'
                                        onClick={() => downloadDocument(item)}
                                    />
                                </div>
                            ))
                        ) : (
                            <p>No Submitted Documents!</p>
                        )}
                    </>
                ) : (
                    <>
                        {appointment?.notes.length > 0 ? (
                            appointment?.notes?.map((item, index) => (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        columnGap: 10,
                                        padding: '10px 0px'
                                    }}
                                    key={index}
                                >
                                    {index + 1}.<p onClick={() => console.log(item)}>{item}</p>
                                    {/* <i
              className='fe fe-download'
              onClick={() =>  console.log(item)}
            /> */}
                                </div>
                            ))
                        ) : (
                            <p>No Submitted Notes!</p>
                        )}
                    </>
                )}
            </div>
        </Modal>)
    );
}

export default CheckFiles;
