import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '@/actions/adminActions';

function Customers(props) {
    const [stripeCustomers, setStripeCustomers] = useState();
    useEffect(() => {
        props.adminActions.getStripeCustomers(props, props.history);
        return () => {};
    }, []);

    useEffect(() => {
        if (props.admin && props.admin.stripeCustomers && props.admin.stripeCustomers.data) {
            setStripeCustomers(props.admin.stripeCustomers.data);
        }
    }, [props.admin.stripeCustomers]);
    return (
        <div className='adminTable'>
            <h1>
                <i
                    style={{ cursor: 'pointer' }}
                    onClick={() => props.history.push('/admin/stripe')}
                    className='fe fe-chevron-left'
                />{' '}
                Stripe Customers
            </h1>

            <div className='table'>
                <table>
                    <thead>
                        <tr className='tableHeader'>
                            <th>CUSTOMER ID</th>
                            <th>FULL NAME</th>
                            <th>EMAIL</th>
                            <th>PHONE</th>
                            <th>PAYMENT METHOD</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stripeCustomers &&
                            stripeCustomers.map((item, index) => (
                                <tr className='breakrow'>
                                    <td>
                                        <div>{item.id}</div>
                                    </td>
                                    <td>
                                        <div>{item.name}</div>
                                    </td>
                                    <td>
                                        <div>{item.email && item.email}</div>
                                    </td>
                                    <td>
                                        <div></div>
                                    </td>
                                    <td>
                                        <div></div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        admin: state.admin
    };
}

function mapDispatchToProps(dispatch) {
    return {
        adminActions: bindActionCreators(adminActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
