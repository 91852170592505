import React, { Component } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import girl2 from '../../assets/img/girl2.jpg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '../../actions/adminActions';
import * as userActions from '../../actions/userActions';
import { Select } from 'antd';
import Currencies from '../../assets/json/Currencies.json';
import languageOptions from '../../assets/json/Languages.json';
import Countries from '../../assets/json/Countries.json';
import validator from 'validator';

const gender = [
    { key: 'male', text: 'Male', value: 'Male' },
    { key: 'female', text: 'Female', value: 'Female' },
    { key: 'other', text: 'Other', value: 'Other' }
];
const statusOptions = ['ACTIVATED', 'DEACTIVATED', 'SUSPENDED'];
export class EditAdminProfile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            infoActive: false,
            profCompDiv: true,
            locality: localStorage.getItem('locality') ? localStorage.getItem('locality') : '',
            localityCountry: localStorage.getItem('localityCountry')
                ? localStorage.getItem('localityCountry')
                : '',
            formDirty: false,
            fixedFirstName: '',
            fixedLastName: '',
            businessName: { value: '', message: '', isValid: true },
            firstName: { value: '', message: '', isValid: true },
            lastName: { value: '', message: '', isValid: true },
            phoneNo: { value: '', message: '', isValid: true },
            countryCode: { value: '', message: '', isValid: true },
            email: { value: '', message: '', isValid: true },
            password: { value: '', message: '', isValid: true },
            gender: { value: '', message: '', isValid: true },
            language: { value: '', message: '', isValid: true },
            currency: { value: '', message: '', isValid: true },
            activeStatus: { value: '', message: '', isValid: true },
            dob: { value: '', message: '', isValid: true },
            profileSummary: { value: '', isValid: true, message: '' },
            currencyDrop: false,
            countryDrop: false,
            countryDrop1: false,
            countryDrop2: false,
            filteredCurrencies: Currencies,
            filteredCountryCode: Countries
        };
    }

    static getDerivedStateFromProps(props, state) {
        // if (props.adminProfile && props.adminProfile.token) {
        //   localStorage.setItem("auth_token", props.adminProfile.token);
        // }

        const { adminProfile } = props.admin;
        if (state.formDirty == false) {
            if (props.auth.isAuthenticated && adminProfile) {
                if (adminProfile.firstName) {
                    state.firstName.value = adminProfile.firstName;
                    state.fixedFirstName = adminProfile.firstName;
                }
                if (adminProfile.lastName) {
                    state.lastName.value = adminProfile.lastName;
                    state.fixedLastName = adminProfile.lastName;
                }
                if (adminProfile.dob) {
                    state.dob.value = adminProfile.dob;
                }
                if (adminProfile.phoneNo) {
                    state.phoneNo.value = adminProfile.phoneNo;
                }
                if (adminProfile.countryCode) {
                    state.countryCode.value = adminProfile.countryCode;
                }
                if (adminProfile.email) {
                    state.email.value = adminProfile.email;
                }
                if (localStorage.getItem('currency')) {
                    state.currency.value = localStorage.getItem('currency');
                }
                if (adminProfile.social) {
                    state.social = adminProfile.social;
                }
                if (adminProfile.gender) {
                    state.gender.value = adminProfile.gender;
                }

                if (adminProfile.dob) {
                    state.dob.value = adminProfile.dob;
                }
                if (adminProfile.language) {
                    state.language.value = adminProfile.language;
                }

                if (adminProfile.activeStatus) {
                    state.activeStatus.value = adminProfile.activeStatus;
                }
            }
        }
        return state;
    }

    componentDidMount() {
        let profileData = {
            userId: this.props.match.params.adminId
        };
        this.props.adminActions.getAdminProfile({ profileData, ...this.props }, this.props.history);
        window.addEventListener('mouseup', (e) => {
            let ccLabel = document.querySelector('.cc-label');
            let ccLabel1 = document.getElementById('countryCode1');
            let ccLabel2 = document.getElementById('countryCode2');

            if (ccLabel && !ccLabel.contains(e.target) && this.state.countryDrop) {
                this.setState({ countryDrop: false });
            }
            if (ccLabel1 && !ccLabel1.contains(e.target) && this.state.countryDrop1) {
                this.setState({ countryDrop1: false });
            }

            if (ccLabel2 && !ccLabel2.contains(e.target) && this.state.countryDrop2) {
                this.setState({ countryDrop2: false });
            }
        });
    }

    saveProfile = async (e) => {
        let state = this.state;
        let data = null;
        let section = null;

        // if (
        //   state.businessName.value &&
        //   state.businessAddress.value &&
        //   state.businessCity.value &&
        //   state.businessCountry.value &&
        //   state.businessCountryCode.value &&
        //   state.businessPhoneNo.value &&
        //   state.businessPhoneNo.isValid &&
        //   state.businessEmail.value &&
        //   state.businessEmail.isValid &&
        //   state.businessState.value &&
        //   state.businessZip.value
        // ) {
        let profileObj = {
            firstName: state.firstName.value,
            lastName: state.lastName.value,
            gender: state.gender.value,
            dob: state.dob.value,
            email: state.email.value,
            phoneNo: state.phoneNo.value,
            countryCode: state.countryCode.value.replace('+', ''),
            language: state.language.value,
            password: state.password.value,
            activeStatus: state.activeStatus.value
        };
        console.log(profileObj);

        let profileData = {
            data: profileObj,
            userType: this.props.auth.user.userType,
            userId: this.props.match.params.adminId
            // section: section,
        };
        console.log(profileData);
        await this.props.actions.saveProfile(profileData, this.props);
        state.formDirty = false;
        this.setState(state);
        let profileShort = {
            userType: this.props.admin.adminProfile.userType,
            userId: this.props.match.params.businessId
        };
        // await this.props.actions.getProfile(profileShort, this.props);
        this.props.history.push('/admin/admins');
        // } else {
        //   message.error("Fill up your Business Information!");
        // }
    };

    onChange(e) {
        var state = this.state;
        if (e.target.name !== 'kitchenTypes' && e.target.name !== 'deliveryOpts') {
            state[e.target.name].value = e.target.value;
        }
        state.formDirty = true;

        if (e.target.name == 'firstName') {
            if (e.target.value == '') {
                state['firstName'].isValid = false;
                state['firstName'].message = 'First name cannot be left empty';
            } else {
                state['firstName'].isValid = true;
                state['firstName'].message = '';
            }
        }

        if (e.target.name == 'lastName') {
            if (e.target.value == '') {
                state['lastName'].isValid = false;
                state['lastName'].message = 'First name cannot be left empty';
            } else {
                state['lastName'].isValid = true;
                state['lastName'].message = '';
            }
        }

        if (e.target.name == 'email') {
            if (e.target.value == '') {
                state['email'].isValid = false;
                state['email'].message = 'Email cannot be left empty';
            } else if (!validator.isEmail(e.target.value)) {
                state['email'].isValid = false;
                state['email'].message = 'Not a valid email address';
            } else {
                state['email'].isValid = true;
                state['email'].message = '';
            }
        }

        if (e.target.name == 'phoneNo') {
            if (e.target.value == '') {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number cannot be left empty';
            } else if (e.target.value.length !== 10) {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number must be 10 digits';
            } else if (!e.target.value.match(/^\d+$/)) {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number must only contain numbers';
            } else {
                state['phoneNo'].isValid = true;
                state['phoneNo'].message = '';
            }
        }

        if (e.target.name == 'countryCode') {
            if (e.target.value == '') {
                state['countryCode'].isValid = false;
                state['countryCode'].message = 'Country code cannot be left empty';
            }

            if (!e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state['countryCode'].isValid = false;
                state['countryCode'].message = 'Country code must only contain numbers';
            }

            if (e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state['countryCode'].isValid = true;
                state['countryCode'].message = '';
            }
        }

        this.setState(state);
    }

    render() {
        const dateFormat = 'YYYY/MM/DD';
        let filteredCurrencies = Currencies;
        let filteredCountryCode = Countries;
        return (
            <div className='adminEditProfile'>
                <div className=' user-profile'>
                    <div className='coverImage'>
                        <img src='https://source.unsplash.com/random/1600x900' />
                        <div className='overlay'>
                            <div className='addCoverImage'>
                                <UploadOutlined style={{ fontSize: '30px', color: '#f7f7f7' }} />
                            </div>
                        </div>
                    </div>
                    <div className='profileHead'>
                        <div className='profilePic'>
                            <img src={girl2} alt='girl' />
                            <i className='fa fa-pencil' />
                        </div>
                    </div>
                    <h2>Edit Admin Profile</h2>
                    <h1>Account Holder Information</h1>
                    <div>
                        <div className='input-fields'>
                            <div className='input-row'>
                                <label className={!this.state.firstName.isValid ? 'error' : ''}>
                                    First name*:
                                    <input
                                        name='firstName'
                                        placeholder='Firstname'
                                        value={this.state.firstName.value}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    {this.state.firstName.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.firstName.message}
                                        </p>
                                    )}
                                </label>

                                <label className={!this.state.lastName.isValid ? 'error' : ''}>
                                    Last name*:
                                    <input
                                        name='lastName'
                                        placeholder='Firstname'
                                        value={this.state.lastName.value}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    {this.state.lastName.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.lastName.message}
                                        </p>
                                    )}
                                </label>
                            </div>

                            <div className='input-row'>
                                <label className={`${!this.state.email.isValid ? 'error' : ''}`}>
                                    Email:
                                    <input
                                        name='email'
                                        placeholder='Email'
                                        value={this.state.email.value}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    {this.state.email.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.email.message}
                                        </p>
                                    )}
                                </label>
                                <label className={`${!this.state.password.isValid ? 'error' : ''}`}>
                                    Password:
                                    <input
                                        name='password'
                                        type='password'
                                        placeholder='************'
                                        value={this.state.password.value}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                </label>
                            </div>
                            <div className='input-row'>
                                <label
                                    id='countryCode1'
                                    className={`${
                                        !this.state.countryCode.isValid ? 'error' : ''
                                    } cc-label ${this.state.countryDrop1 && 'focused'}`}
                                    tabIndex={0}
                                >
                                    Country Code:
                                    <input
                                        type='text'
                                        name='countryCode'
                                        placeholder='Country code'
                                        value={this.state.countryCode.value}
                                        autocomplete='autocomplete_off_hack_xfr4!k'
                                        style={
                                            this.state.countryCode.value.match(/^\d+$/) && {
                                                paddingLeft: 20
                                            }
                                        }
                                        className='cc-input'
                                        onFocus={() => this.setState({ countryDrop1: true })}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                filteredCountryCode = Countries.filter(
                                                    (item, index) => {
                                                        const regex = new RegExp(
                                                            e.target.value,
                                                            'gi'
                                                        );
                                                        return (
                                                            item.name.match(e.target.value) ||
                                                            item.dialCode.match(e.target.value) ||
                                                            item.isoCode.match(e.target.value)
                                                        );
                                                    }
                                                );
                                                this.setState({
                                                    filteredCountryCode: filteredCountryCode
                                                });
                                            } else {
                                                this.setState({ filteredCountryCode: Countries });
                                            }
                                            this.setState({
                                                formAccountDirty: true,
                                                formDirty: true,
                                                countryCode: {
                                                    ...this.state.countryCode,
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                    <i className='dropdown icon'></i>
                                    {this.state.countryCode.value.length &&
                                    this.state.countryCode.value.match(/^\d+$/) ? (
                                        <i className='plus-icon'>&#43;</i>
                                    ) : null}
                                    {this.state.countryDrop1 && (
                                        <div
                                            className='countryDrop'
                                            onBlur={() => this.setState({ countryDrop1: false })}
                                        >
                                            <ul>
                                                {this.state.filteredCountryCode &&
                                                    this.state.filteredCountryCode.map(
                                                        (item, index) => (
                                                            <li
                                                                key={index}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (
                                                                        item &&
                                                                        item.flag &&
                                                                        item.dialCode
                                                                    ) {
                                                                        this.setState({
                                                                            countryCode: {
                                                                                ...this.state
                                                                                    .countryCode,
                                                                                value: item.dialCode.split(
                                                                                    '+'
                                                                                )[1]
                                                                            },
                                                                            formDirty: true,
                                                                            countryDrop1: false
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src={item.flag}
                                                                    height='16px'
                                                                    width='24px'
                                                                />
                                                                {item.name +
                                                                    ' (' +
                                                                    item.isoCode +
                                                                    ') ' +
                                                                    item.dialCode}
                                                            </li>
                                                        )
                                                    )}
                                            </ul>
                                        </div>
                                    )}
                                    {this.state.countryCode.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.countryCode.message}
                                        </p>
                                    )}
                                </label>
                                <label className={`${!this.state.phoneNo.isValid ? 'error' : ''}`}>
                                    Phone No:
                                    <input
                                        name='phoneNo'
                                        placeholder='Phone No.'
                                        value={this.state.phoneNo.value}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    {this.state.phoneNo.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.phoneNo.message}
                                        </p>
                                    )}
                                </label>
                            </div>
                            <div className='input-row'>
                                <label>
                                    Language:
                                    <Select
                                        placeholder='Language'
                                        value={this.state.language.value}
                                        // search
                                        showSearch='true'
                                        selection='true'
                                        onChange={(e, { value }) =>
                                            this.setState({
                                                formDirty: true,
                                                language: { ...this.state.language, value: value }
                                            })
                                        }
                                        options={languageOptions}
                                    />
                                </label>
                            </div>
                            <button
                                name='profileBtn'
                                className='save-btn'
                                onClick={(e) => this.saveProfile(e)}
                            >
                                Save Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    adminActions: bindActionCreators(adminActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(EditAdminProfile);
