import React from 'react';

function PlusIcon(props) {
    return (
        <svg
            version='1.1'
            viewBox='0 0 512 512'
            xmlns='http://www.w3.org/2000/svg'
            aria-hidden='true'
            role='presentation'
            focusable='false'
            xmlns='http://www.w3.org/2000/svg'
            style={{
                display: 'block',
                height: '28',
                width: '28',
                fill: 'lightslategray'
            }}
        >
            <path
                // style={{fill:"#8DC640"}}
                style={{ fill: '#f1433f' }}
                d='M405.493,412.764c-69.689,56.889-287.289,56.889-355.556,0c-69.689-56.889-62.578-300.089,0-364.089
	s292.978-64,355.556,0S475.182,355.876,405.493,412.764z'
            />
            <g style={{ opacity: 0.2 }}>
                <path
                    style={{ fill: '#FFFFFF' }}
                    d='M229.138,313.209c-62.578,49.778-132.267,75.378-197.689,76.8
		c-48.356-82.489-38.4-283.022,18.489-341.333c51.2-52.622,211.911-62.578,304.356-29.867
		C377.049,112.676,330.116,232.142,229.138,313.209z'
                />
            </g>
            <path
                style={{ fill: '#FFFFFF' }}
                d='M362.827,227.876c0,14.222-11.378,25.6-25.6,25.6h-85.333v85.333c0,14.222-11.378,25.6-25.6,25.6
	c-14.222,0-25.6-11.378-25.6-25.6v-85.333H115.36c-14.222,0-25.6-11.378-25.6-25.6c0-14.222,11.378-25.6,25.6-25.6h85.333v-85.333
	c0-14.222,11.378-25.6,25.6-25.6c14.222,0,25.6,11.378,25.6,25.6v85.333h85.333C351.449,202.276,362.827,213.653,362.827,227.876z'
            />
            <g></g>
        </svg>
    );
}

export default PlusIcon;
