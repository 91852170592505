import React from 'react';
import { Button, Row } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';

const PageHeader = ({ heading, subHeading, actions, onBack }) => {
    return (
        <Row wrap justify={'space-between'} align={'middle'} style={{ marginBottom: 20 }}>
            <Row align={'middle'}>
                {onBack && (
                    <Button type='text' onClick={onBack}>
                        <ArrowLeftOutlined style={{ fontSize: 24 }} />
                    </Button>
                )}
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <h1 style={{ fontSize: '2rem', fontWeight: 400, marginBottom: 0 }}>
                        {heading}
                    </h1>
                    {subHeading && (
                        <h3>{typeof subHeading == 'function' ? subHeading() : subHeading}</h3>
                    )}
                </div>
            </Row>

            {actions && <Row style={{ gap: 10, alignItems: 'center' }}>{actions()}</Row>}
        </Row>
    );
};

export default PageHeader;
