import { ExclamationOutlined } from '@ant-design/icons';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ApplicationSetup = () => {
    const { userType } = useSelector((state) => state.auth);

    const { dashboard } = useSelector((state) => state[userType.toLowerCase()]);
    const {
        pendingApprovals,
        upcomingAppointments,
        cancelledAppointments,
        failedAppointments,
        missedAppointments,
        rejectedAppointments,
        completedAppointments,
        noShowAppointments,
        services
    } = dashboard;

    const userRoute = `/${userType.toLowerCase()}`;
    const links = [
        {
            label: 'Services',
            to: `${userRoute}/management?tab=Services`,
            text: services || 0
        },
        {
            label: 'Upcoming',
            to: `${userRoute}/appointments?status=UPCOMING`,
            text: upcomingAppointments || 0
        },
        {
            label: 'Pending',
            to: `${userRoute}/appointments?status=PENDING`,
            text: pendingApprovals || 0
        },
        {
            label: 'Cancelled',
            to: `${userRoute}/appointments?status=CANCELLED`,
            text: cancelledAppointments || 0
        },
        {
            label: 'Missed',
            to: `${userRoute}/appointments?status=MISSED`,
            text: missedAppointments || 0
        },
        {
            label: 'Failed',
            to: `${userRoute}/appointments?status=FAILED`,
            text: failedAppointments || 0
        },
        {
            label: 'Rejected',
            to: `${userRoute}/appointments?status=REJECTED`,
            text: rejectedAppointments || 0
        },
        {
            label: 'Completed',
            to: `${userRoute}/appointments?status=COMPLETED`,
            text: completedAppointments || 0
        },
        {
            label: 'No Show',
            to: `${userRoute}/appointments?status=NO%20SHOW`,
            text: noShowAppointments || 0
        }
    ];

    const linkItems = links.map((link, index) => (
        <li
            key={index}
            className={link.label.toLowerCase().replace(' ', '')}
            style={{ position: 'relative' }}
        >
            <Link to={link.to} style={{ height: '100%', width: '100%' }}>
                {link.text === 0 && (
                    <div
                        style={{
                            backgroundColor: 'red',
                            height: 25,
                            width: 25,
                            borderRadius: '50%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: 'absolute',
                            top: '0px',
                            right: 10,
                            transform: 'translateY(-50%)'
                        }}
                    >
                        <ExclamationOutlined style={{ fontSize: 15, color: 'white' }} />
                    </div>
                )}
                <div style={{ textAlign: 'left' }}>{link.label}</div>{' '}
                <div style={{ position: 'absolute', bottom: 10, right: 10, fontSize: 24 }}>
                    {link.text}
                </div>
            </Link>
        </li>
    ));

    return (
        <div id='applicationSetup'>
            <h4 className='sectionTitle' style={{ fontSize: 14 }}>
                Appointments
            </h4>
            <div className='sectionContainer'>
                <ul className='appointments'>{linkItems}</ul>
            </div>
        </div>
    );
};

export default ApplicationSetup;
