import * as Types from '../actions/types';
const init = {
    accountLink: null,
    externalAccounts: {
        cards: [],
        bankAccounts: [],
        paymentMethods: []
    }
};

const stripeReducer = (state = init, action) => {
    switch (action.type) {
        case Types.CHANGE_STRIPE_ACCOUNT_LINK: {
            return {
                ...state,
                accountLink: action.payload.accountLink
            };
        }

        case Types.GET_EXTERNAL_ACCOUNTS: {
            return {
                ...state,
                externalAccounts: {
                    ...state.externalAccounts,
                    bankAccounts: action.payload.bankAccounts,
                    cards: action.payload.cards
                }
            };
        }

        case Types.GET_CUSTOMER_STRIPE_PAYMENT_METHODS: {
            return {
                ...state,
                paymentMethods: action.payload.paymentMethods
            };
        }

        case Types.LOGOUT: {
            return init
        }

        default:
            return state;
    }
};

export default stripeReducer;
