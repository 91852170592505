import React from 'react';
import { uniqueId } from 'lodash';
import { Switch } from 'antd';

class CheckBox extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            id: uniqueId('Checkbox'),
            isChecked: this.props.initialChecked
        };
    }

    onChange = (event) => {
        let isChecked = event?.currentTarget?.checked;
        this.setState({ isChecked });
    };

    componentDidUpdate(prevProps, prevState) {
        if (
            prevState.isChecked !== this.state.isChecked &&
            typeof this.props.onChange === 'function'
        ) {
            this.props.onChange(this.state.isChecked);
        }
    }

    render() {
        const { label, icon } = this.props;
        return (
            <>
                <label htmlFor={this.state.id} style={{ display: 'flex', alignItems: 'center' }}>
                    {icon || label}
                </label>

                <input
                    type='checkbox'
                    checked={this.state.isChecked}
                    id={this.state.id}
                    onChange={this.onChange}
                />
                {/* <Switch  id={this.state.id}
          onChange={this.onChange} checkedChildren={label} unCheckedChildren={`${label}`} checked={this.state.isChecked} /> */}
            </>
        );
    }
}
export default CheckBox;
