import React from 'react';
import Notes from './Notes.js';
import Files from './Files.js';
import './SideDetail.css';

function SideDetail(props) {
    return (
        <div className='sideDetail'>
            <Notes {...props} />
            <Files {...props} />
        </div>
    );
}

export default SideDetail;
