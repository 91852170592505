import React, { lazy, useEffect, Suspense } from 'react';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Spin, ConfigProvider } from 'antd';
import Routes from './Routes';
import AdminRoutes from './components/AdminRoutes';
import { socket } from './components/MiniComponents/Socket';
import HelmetMetaData from './components/common/HelmetMetaData';
const ServiceUnavailable = lazy(() => import('./components/503'));
const NotFound = lazy(() => import('./components/404'));
const ServerError = lazy(() => import('./components/500'));

const App = () => {
    useEffect(() => {
        window.addEventListener('storage', function (event) {
            if (event.key === 'auth_token') {
                window.location.reload();
            }
        });
    }, []);
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: '#15B7B9',
                    colorPrimaryHover: '#000',
                    colorPrimaryText: '#fff',
                    colorPrimaryTextHover: '#fff'
                    // colorFillSecondary: '#00bce7',
                },
                components: {
                    Switch: {
                        colorPrimary: '#15B7B9',
                        algorithm: true
                    },
                    Select: {
                        optionSelectedBg: '#rgba(255,255,255,0.6)',
                        borderRadius: 4
                    },
                    Input: {
                        controlOutlineWidth: 0
                    },
                    DatePicker: {
                        width: '100%'
                    },
                    Button: {
                        colorPrimary: '#15B7B9',
                        colorLink: '#15B7B9',
                        padding: '0.5rem 1rem',
                        algorithm: true
                    },
                    Radio: {
                        colorPrimary: '#15B7B9',
                        algorithm: true
                    },
                    Steps: {
                        colorPrimary: '#15B7B9',
                        algorithm: true
                    }
                }
            }}
        >
            <Router>
                <div className={`App`}>
                    <HelmetMetaData />
                    <Suspense
                        fallback={
                            <div
                                style={{
                                    width: '100%',
                                    minHeight: '100vh',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    backgroundColor: '#fff'
                                }}
                            >
                                <Spin
                                    size='large'
                                    style={{ color: '#fff' }}
                                    tip='Loading...'
                                ></Spin>
                            </div>
                        }
                    >
                        <Switch>
                            <Route exact path='/notFound' component={NotFound} />
                            <Route
                                exact
                                path='/serviceUnavailable'
                                component={ServiceUnavailable}
                            />
                            <Route exact path='/servererror' component={ServerError} />
                            <Route path='/admin' component={AdminRoutes} />
                            <Route path='/' component={Routes} />
                            <Route exact path='*' component={NotFound} />
                        </Switch>
                    </Suspense>
                </div>
            </Router>
        </ConfigProvider>
    );
};

export default App;
