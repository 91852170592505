import CardRow from './CardRow';
import React, { useRef, useState, useEffect } from 'react';
import './FirstStage.css';
import './Card.css';
import { getLocationShorts } from '../../../../Utilities/LocationShorts';
import { useDispatch, useSelector } from 'react-redux';
import * as appointmentActions from '@/actions/appointmentActions';
import { Button, Divider } from 'antd';
import { useLocation } from 'react-router-dom';

const FirstStage = (props) => {
    const doctorRow = useRef();
    const hospitalRow = useRef();
    const dispatch = useDispatch();
    const location = useLocation();
    const auth = useSelector((state) => state.auth);
    const appointments = useSelector((state) => state.appointments);
    const { history, setPointer } = props;
    const { hospitalsAndDoctors } = appointments;

    // let doctorsArr = []

    const { doctors, hospitals } = hospitalsAndDoctors;
    //doctor page and limit
    const [userLimit, setDoctorLimit] = useState(10);
    const [doctorPage, setDoctorPage] = useState(1);

    //hospital page and limit
    const [hospitalLimit, setHospitalLimit] = useState(10);
    const [hospitalPage, setHospitalPage] = useState(1);

    const [tab, setTab] = useState('Doctors');

    useEffect(() => {
        let urlParams = new URLSearchParams(window.location.search);
        let tab = urlParams.get('tab');
        if (tab) {
            setTab(tab);
        } else {
            setTab('Doctors');
        }
    }, [location.search]);

    useEffect(() => {
        callHospitalDoctor();
    }, []);

    const callHospitalDoctor = async () => {
        let location = await getLocationShorts();
        let objDoctor = {
            page: doctorPage,
            perPage: userLimit,
            userId: auth.userId,
            ...location
        };
        let objHospital = {
            page: hospitalPage,
            perPage: hospitalLimit,
            userId: auth.userId,
            ...location
        };
        dispatch(
            appointmentActions.getPastAppointmentHospitals({
                ...props,
                obj: objHospital
            })
        );
        dispatch(appointmentActions.getPastAppointmentDoctors({ ...props, obj: objDoctor }));
    };

    return (
        <div className='firstStage'>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Button
                    style={{
                        padding: '0px 10px',
                        borderRadius: 0,
                        borderBottom:
                            tab === 'Doctors'
                                ? '2px solid var(--primary)'
                                : '2px solid transparent'
                    }}
                    type='text'
                    onClick={() => history.push(`/patient/appointments/new?tab=Doctors`)}
                >
                    Doctors
                </Button>
                <Button
                    style={{
                        padding: '0px 10px',
                        borderRadius: 0,
                        borderBottom:
                            tab === 'Hospitals'
                                ? '2px solid var(--primary)'
                                : '2px solid transparent'
                    }}
                    type='text'
                    onClick={() => history.push(`/patient/appointments/new?tab=Hospitals`)}
                >
                    Hospitals
                </Button>
            </div>
            {tab == 'Doctors' ? (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>Choose a Doctor from past appointments</p>
                        <div className='arrowBtnRow' style={{ display: 'flex', columnGap: 10 }}>
                            <div className='arrowBtns'>
                                <div
                                    onClick={() => {
                                        if (doctorRow && doctorRow.current) {
                                            doctorRow.current.scrollLeft += -300;
                                        }
                                    }}
                                >
                                    <i className='fe fe-arrow-left' />
                                </div>
                                <div
                                    onClick={() => {
                                        if (doctorRow && doctorRow.current) {
                                            doctorRow.current.scrollLeft += 300;
                                        }
                                    }}
                                >
                                    <i className='fe fe-arrow-right' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <CardRow
                        rowRef={doctorRow}
                        data={doctors}
                        searchPageLink={'/doctor-results'}
                        type='Doctor'
                        {...props}
                    />
                </div>
            ) : (
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <p>Choose a Hospital from past appointments</p>
                        <div className='arrowBtnRow' style={{ display: 'flex', columnGap: 10 }}>
                            <div className='arrowBtns'>
                                <div
                                    onClick={() => {
                                        if (hospitalRow && hospitalRow.current) {
                                            hospitalRow.current.scrollLeft += -300;
                                        }
                                    }}
                                >
                                    <i className='fe fe-arrow-left' />
                                </div>
                                <div
                                    onClick={() => {
                                        if (hospitalRow && hospitalRow.current) {
                                            hospitalRow.current.scrollLeft += 300;
                                        }
                                    }}
                                >
                                    <i className='fe fe-arrow-right' />
                                </div>
                            </div>
                        </div>
                    </div>
                    <CardRow
                        rowRef={hospitalRow}
                        data={hospitals}
                        searchPageLink={'/hospital-results'}
                        type='Hospital'
                        {...props}
                    />
                </div>
            )}

            {((tab === 'Doctors' && doctors && doctors.length > 0) ||
                (tab === 'Hospitals' && hospitals && hospitals.length > 0)) && (
                <>
                    <Divider style={{ width: '50%', margin: '0px auto', minWidth: 'unset' }} />
                    <div
                        className=''
                        style={{
                            margin: '0px auto',
                            alignItems: 'center',
                            display: 'flex',
                            height: 150,
                            flexDirection: 'column',
                            justifyContent: 'center'
                        }}
                    >
                        <p style={{ fontWeight: 600 }}>Search all {tab}.</p>
                        <Button
                            type='primary'
                            style={{ marginTop: 10 }}
                            onClick={() =>
                                props.history.push(
                                    tab === 'Doctors' ? `/doctor-results` : `/hospital-results`
                                )
                            }
                        >
                            Search Now!
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

export default FirstStage;
