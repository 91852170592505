import React, { useState } from 'react';
import { Checkbox, Select, Slider, Empty, Tag } from 'antd';
import { specializationAreas } from '@/assets/json/DoctorPracticeAreas';
import languageOptions from '@/assets/json/Languages.json';
import GeoLocate from '../../MiniComponents/Geolocate';

function LeftSide(props) {
    const {
        language,
        setLanguage,
        practiceAreas,
        setPracticeAreas,
        radius,
        setRadius,
        chooseAddress,
        filteredPracticeAreas,
        setFilteredPracticeAreas,
        addressValue,
        setAddressValue,
        handlePracticeAreas
    } = props;
    const [pa, setPa] = useState('');
    return (
        <div className='leftSide'>
            <div className='twoCont'>
                <label className='inputCont'>
                    Address
                    <GeoLocate
                        chooseAddress={chooseAddress}
                        addressValue={addressValue}
                        setAddressValue={setAddressValue}
                    />
                </label>
                <label className='inputCont'>
                    Language
                    <Select value={language} onChange={(val) => setLanguage(val)}>
                        {languageOptions.map((item, index) => (
                            <Select.Option key={index} value={item.value}>
                                {item.value}
                            </Select.Option>
                        ))}
                    </Select>
                </label>
            </div>

            <label className='inputCont'>
                Radius - {`${radius}m`}
                <Slider
                    value={radius}
                    onChange={(val) => setRadius(val)}
                    max={100000}
                    min={1000}
                    step={1000}
                />
            </label>

            {/* <label><span>Dropdown</span></label> */}
            <div className='filterLabelsCont'>
                <div className='practiceAreas'>
                    {practiceAreas && practiceAreas.length > 0
                        ? practiceAreas.map((item, index) => (
                              <Tag style={{ display: 'flex', alignItems: 'center' }}>
                                  {item}
                                  <span>
                                      <i
                                          className='fe fe-x'
                                          onClick={() =>
                                              setPracticeAreas(
                                                  practiceAreas.filter((it) => it != item)
                                              )
                                          }
                                      />
                                  </span>
                              </Tag>
                          ))
                        : 'No practice areas chosen'}
                </div>
                Practice Areas
                <input
                    placeholder='Type practice area to filter'
                    value={pa}
                    onChange={(e) => {
                        setPa(e.target.value);
                        if (e.target.value.length > 0) {
                            let regex = new RegExp(`^${e.target.value}`, 'i');

                            setFilteredPracticeAreas(specializationAreas.filter((item) => item.match(regex)));
                        } else {
                            setFilteredPracticeAreas([]);
                        }
                    }}
                />
                <div className='filterLabels'>
                    {filteredPracticeAreas && filteredPracticeAreas.length > 0 ? (
                        filteredPracticeAreas.map((item, index) => (
                            <Checkbox
                                key={index}
                                checked={practiceAreas.includes(item)}
                                onChange={handlePracticeAreas}
                                value={item}
                            >
                                {' '}
                                {item}{' '}
                            </Checkbox>
                        ))
                    ) : (
                        <Empty
                            style={{ margin: 'auto', fontWeight: 400 }}
                            description={
                                pa ? 'No practice areas found' : 'Type to search for practice areas'
                            }
                        />
                    )}
                </div>
            </div>
        </div>
    );
}

export default LeftSide;
