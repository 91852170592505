import React, { useCallback, useRef, useState } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as blogActions from '@/actions/blogActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClassicEditor from '@dmc4719/ckeditor5-custom-build/build/ckeditor';
import { CONFIG } from '../../../MiniComponents/CKeditorConfig';
import '@/assets/css/createBlog.css';
import MyCKEditorUploadAdapter from './MyCKEditorUploadAdapter';
import { Button, Select, message } from 'antd';
import { getLocationShorts } from '../../../Utilities/LocationShorts';
import PageHeader from '../PageHeader';
import { blogSpecializationAreas } from '@/assets/json/DoctorPracticeAreas';

const { Option } = Select;

const CreateBlog = (props) => {
    const editor = useRef(null);
    const [file, setCoverImage] = useState(null);
    const [coverImageError, setCoverImageError] = useState('');
    const [tempCoverImage, setTempCoverImage] = useState();
    const [title, setTitle] = useState({ value: '', message: '' });
    const [editorValue, setEditorValue] = useState({
        value: '',
        message: ''
    });
    const [category, setCategory] = useState({ value: '', message: '' });

    const [loading, setLoading] = useState(false);

    const handleChange = (value) => {
        if (value.length) {
            setCategory({ value: value, message: '' });
        } else {
            setCategory({ value: value, message: 'Catgory cannot be empty' });
        }
    };

    const handleInitEditor = useCallback((editor) => {
        // You can store the "editor" and use when it is needed.
        console.log('Editor is ready to use!', editor);
        editor.config.contentsCss = '/createBlog.css';
        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
            return new MyCKEditorUploadAdapter({ loader, ...props });
        };
    }, []);

    const createBlog = async () => {
        let error = false;

        if (!title.value || !editorValue.value || !file || !category) {
            if (!title.value) {
                setTitle({ ...title, message: 'Title field cannot be empty' });
                error = true;
            }

            if (!editorValue.value) {
                setEditorValue({
                    ...editorValue,
                    message: 'Body field cannot be empty'
                });
                error = true;
            }

            if (!category.value.length) {
                setCategory({ ...category, message: 'Catgory cannot be empty' });
                error = true;
            }

            if (!file) {
                setCoverImageError('Cover image cannot be empty');
                error = true;
            }
            return;
        }
        setLoading(true);

        let obj = new FormData();
        obj.append('title', title.value);
        obj.append('body', editorValue.value);
        for (let i = 0; i < category.value.length; i++) {
            obj.append(`category[${i}]`, category.value[i]);
        }
        obj.append('author', props.auth.user._id);
        obj.append('authorType', props.auth.user.userType);
        if(props.auth.user.userType === 'DOCTOR'){
            obj.append('identify', props.auth.user.hospital);
        }
        obj.append('image', file);
        obj.append('country', getLocationShorts().country);

        const createdPost = await props.blogActions.createPost({ obj, ...props }, props.history);
        if (createdPost) {
            props.history.goBack();
            setLoading(false);
            message.success('Post created successfully');
        } else {
            setLoading(true);
        }
    };

    return (
        <div className='createPost' style={{ width: '100%' }}>
            <PageHeader heading='Create Blog Post' onBack={() => props.history.goBack()} />

            <div className='input-cont'>
                <img className='tempCoverImage' src={tempCoverImage ? tempCoverImage : null} />
                {coverImageError && <p className='error-text'>*{coverImageError}</p>}
            </div>

            <label htmlFor='coverImage' className='chooseCover gray-btn'>
                Choose Cover
                <input
                    id='coverImage'
                    type='file'
                    onChange={(e) => {
                        setCoverImage(e.target.files[0]);
                        if (e.target.files[0]) {
                            setCoverImageError('');
                            setTempCoverImage(URL.createObjectURL(e.target.files[0]));
                        } else {
                            setCoverImageError('Cover image cannot be empty');
                        }
                    }}
                />
            </label>
            <div className='input-cont'>
                <input
                    placeholder='Post title'
                    value={title.value}
                    onChange={(e) => {
                        if (e.target.value != '') {
                            setTitle({ ...title, message: '', value: e.target.value });
                        } else {
                            setTitle({
                                ...title,
                                message: 'Title field cannot be empty',
                                value: e.target.value
                            });
                        }
                    }}
                />
                {title.message && <p className='error-text'>*{title.message}</p>}
            </div>

            <div className='input-cont'>
                <Select
                    mode='multiple'
                    placeholder='Select Area of Specialization'
                    defaultValue={[]}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                >
                    {blogSpecializationAreas.map((item, index) => (
                        <Option value={item} key={index}>
                            {item}
                        </Option>
                    ))}
                </Select>
                {category.message && <p className='error-text'>*{category.message}</p>}
            </div>

            <div className='input-cont'>
                <CKEditor
                    id='my-editor'
                    ref={editor}
                    config={CONFIG}
                    // style={{ width: "100%", margin: "auto" }}
                    editor={ClassicEditor}
                    data={editorValue.value}
                    onReady={handleInitEditor}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data != '') {
                            setEditorValue({ ...editorValue, value: data, message: '' });
                        } else {
                            setEditorValue({
                                ...editorValue,
                                message: 'Body field cannot be empty',
                                value: data
                            });
                        }
                        console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />
                {editorValue.message && <p className='error-text'>{editorValue.message}</p>}
            </div>

            <Button
                loading={loading}
                type='primary'
                disabled={loading}
                onClick={() => createBlog()}
                style={{ width: 250 }}
            >
                Post Blog
            </Button>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    blogs: state.blog
});

const mapDispatchToProps = (dispatch) => ({
    blogActions: bindActionCreators(blogActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(CreateBlog);
