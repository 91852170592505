import React, { useState, memo, useRef, useEffect } from 'react';
import './experience.css';
import * as userActions from '@/actions/userActions';
import * as branchActions from '@/actions/branchActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { message } from 'antd';
import CredentialHeader from './CredentialHeader';
import CredentialsTable from '@/components/NewProfile/Shared/Credentials/CredentialsTable';

const Admission = (props) => {
    const { auth, credentialType, setCredentialType, credentialTypes, branch } = props;
    const [limit, setLimit] = useState(branch ? 5 : 10);
    const [page, setPage] = useState(1);
    const [admissionModal, setAdmissionModal] = useState(false);
    const [editAdmission, setEditAdmission] = useState(null);
    const [deleteAdmission, setDeleteAdmission] = useState(null);
    const { pharmacist } = branch ?? {};
    const [formInputs, setFormInputs] = useState([]);

    useEffect(() => {
        if (
            props.profile &&
            props.profile.admissions &&
            props.profile.admissions.length !== 0 &&
            !pharmacist
        ) {
            setFormInputs(props.profile.admissions);
        } else if (!pharmacist) {
            setFormInputs([]);
        }

        if (branch) {
            if (pharmacist && pharmacist?.admissions) {
                setFormInputs(pharmacist.admissions);
            } else {
                setFormInputs([]);
            }
        }
    }, [props.profile.admissions, branch]);

    return (
        <>
            <CredentialHeader
                auth={auth}
                credentialType={credentialType}
                credentialTypes={credentialTypes}
                setCredentialType={setCredentialType}
                admissionModal={admissionModal}
                setAdmissionModal={setAdmissionModal}
                {...props}
            />
            <CredentialsTable
                auth={auth}
                credentialType={credentialType}
                data={formInputs}
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                admissionModal={admissionModal}
                setAdmissionModal={setAdmissionModal}
                editAdmission={editAdmission}
                setEditAdmission={setEditAdmission}
                deleteAdmission={deleteAdmission}
                setDeleteAdmission={setDeleteAdmission}
                {...props}
                pharmacist={pharmacist}
                branch={branch}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Admission));
