import React, { useState, useEffect, memo } from 'react';
import '@/assets/css/newProfile.css';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import '../Shared/customModal.css';
import { useDispatch } from 'react-redux';
import Routes from '@/routes/private/Patient';
import useWindowSize from '@/hooks/useWindowSize';
import LayoutComponent from '../Shared/Layout';
import { useLocation, useHistory } from 'react-router-dom/cjs/react-router-dom';

const UserLayout = (props) => {
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const history = useHistory();
    const [activeMenu, setActiveMenu] = useState('');
    const [minFields, setMinFields] = useState(false);
    const { width } = useWindowSize();
    const { userType } = useSelector((state) => state.auth);
    useEffect(() => {
        if(userType !== 'PATIENT') {
            history.push('/');
        }
        if (typeof window != 'undefined') {
            window.scrollTo(0, 0);
        }
        let profileData = {
            userId: props.auth.user._id,
            userType: props.auth.user.userType
        };
        props.actions.getProfile(profileData, history);
        setActiveMenu(pathname);
    }, [pathname]);

    useEffect(() => {
        let prof = props.profile;

        if (
            prof.firstName &&
            prof.lastName &&
            prof.gender &&
            prof.currency &&
            prof.dob &&
            prof.language &&
            prof.contact &&
            prof.contact.billing &&
            prof.contact.billing.billingAddress &&
            prof.contact.billing.billingCountry &&
            prof.contact.billing.billingCity &&
            prof.contact.billing.billingState &&
            prof.contact.billing.billingZip
        ) {
            setMinFields(true);

            dispatch({
                type: 'CLEAR_PROFILE_COMPLETION_ERROR',
                payload: {
                    profileError: ''
                }
            });
        } else {
            if (prof && Object.keys(prof).length > 0) {
                setMinFields(false);
                props.history.push('/patient/profile');
                dispatch({
                    type: 'PROFILE_COMPLETION_ERROR',
                    payload: {
                        profileError:
                            'Please complete & save all the required fields to enable all features.'
                    }
                });
            }
        }
    }, [props.profile]);

    return (
        <LayoutComponent
            minFields={true}
            routes={Routes.filter((route) => {
                if ((!minFields && !route.path.includes('profile')) || !route.name) {
                    return false;
                }
                if (route.path.includes('blogs') && !props.auth.user.isBlogUser) {
                    return false;
                }
                return route;
            })}
        />
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(UserLayout));
