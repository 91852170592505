import React, { useState, useEffect } from 'react';
import { Rate, Pagination, Empty } from 'antd';
import girl from '@/assets/img/girl.jpg';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import * as reviewActions from '@/actions/reviewActions';

const Reviews = (props) => {
    let { id } = useParams();
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const dispatch = useDispatch();
    const { reviews, reviewsCount } = useSelector((state) => state.general);

    useEffect(() => {
        let obj = {
            userId: id,
            perPage: limit,
            page: page
        };
        dispatch(reviewActions.getViewReviews({ ...props, obj }));
    }, [limit, page]);

    const [viewMore, setViewMore] = useState(false);
    const [viewMores, setViewMores] = useState([]);
    const [items, setItems] = useState([]);
    const reviewItem = {
        reviewer: 'Waheed Ahmed',
        rating: 5,
        shortMessage: 'Fantastic Service, Best Hospital in town!',
        message: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
         dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
          It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s 
          with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.`
    };

    useEffect(() => {
        setViewMores(reviews.map((item) => false));
    }, [reviews]);

    const handleViewMores = (index, value) => {
        let mores = [...viewMores];
        mores[index] = value;
        console.log(mores[index], index, mores, value);
        setViewMores(mores);
    };

    return (
        <div className='view-container'>
            <div className='revItemContainer'>
                {reviews && reviews.length > 0 ? (
                    reviews.map((item, index) => (
                        <div className='revItem'>
                            <div className='firstSec'>
                                <div className='avatar'>
                                    <img src={girl} />
                                </div>
                                <div>
                                    <Rate
                                        style={{ fontSize: 14 }}
                                        value={item.rating && item.rating}
                                        count={5}
                                        disabled
                                    />
                                    <p>
                                        {item.reviewer && item.reviewer.firstName && (
                                            <>
                                                {' '}
                                                {item.reviewer.userType == "PATIENT" && (
                                                    <>
                                                        <span
                                                            onClick={() =>
                                                                props.history.push(
                                                                    '/doctor-view/' +
                                                                        item.reviewedUser._id
                                                                )
                                                            }
                                                        >
                                                            {item.reviewer.firstName &&
                                                                item.reviewedUser.firstName}{' '}
                                                            {item.reviewedUser.lastName &&
                                                                item.reviewedUser.lastName}
                                                        </span>{' '}
                                                    </>
                                                )}{' '}
                                            </>
                                        )}
                                    </p>
                                </div>
                            </div>
                            <div className='secondSec'>
                                <h4>{item.shortDescription}</h4>
                                <p className={`${viewMores[index] ? 'viewMore' : ''}`}>
                                    {item.description}
                                </p>
                                <span onClick={() => handleViewMores(index, !viewMores[index])}>
                                    {viewMores[index] ? 'View less' : 'View more'}
                                </span>
                            </div>
                        </div>
                    ))
                ) : (
                    <Empty description='No Reviews Found' />
                )}
            </div>
            <Pagination
                style={{
                    margin: 20,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                }}
                current={page}
                total={reviewsCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </div>
    );
};

export default Reviews;
