import React, { useEffect, useState } from 'react';
import '../../assets/css/orders.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '../../actions/adminActions';
import { Pagination, Menu, Dropdown, Select } from 'antd';
import { Link } from 'react-router-dom';
import PlusIcon from '../../assets/img/svgs/plusIcon';
import CreateUserModal from './CreateUserModal';

function AdminUsers(props) {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);

    //create user
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userType, setUserType] = useState('ADMIN');
    const [createUserModal, setCreateUserModal] = useState(false);
    const statusOptions = ['ACTIVATED', 'DEACTIVATED', 'SUSPENDED', 'UNVERIFIED'];
    const createUser = async () => {
        let obj = {
            email: email,
            password: password,
            userType: userType
        };
        let createUser = props.adminActions.createAdminUser({ obj, ...props }, props.history);
        if (createUser) {
            getAdminUsers();
        }
    };
    const deleteAdmin = async (item) => {
        let obj = {
            email: item.email,
            adminId: item._id
        };
        let deletedAdmin = await props.adminActions.deleteAdminUser(
            { obj, ...props },
            props.history
        );
        if (deletedAdmin) {
            getAdminUsers();
        }
    };

    const adminActionMenu = (props) => (
        <Menu>
            {console.log(props)}
            <Menu.Item>
                <Link to={`/admin/admins/${props.item._id}/edit`}>Edit Admin</Link>
            </Menu.Item>
            <Menu.Item onClick={() => deleteAdmin(props.item)}>
                <a>Delete User</a>
            </Menu.Item>
        </Menu>
    );

    useEffect(() => {
        getAdminUsers();
    }, [page, limit]);

    const getAdminUsers = async () => {
        let obj = {
            page: page,
            limit: limit
        };
        props.adminActions.getAdminUsers({ obj, ...props }, props.history);
    };

    const changeActiveStatus = async (val, item) => {
        let obj = {
            userType: item.userType && item.userType,
            userId: item._id && item._id,
            activeStatus: val
        };
        let changedStatus = await props.adminActions.changeActiveStatus(
            { obj, ...props },
            props.history
        );
        if (changedStatus) {
            let obj = {
                page: page,
                limit: limit
            };
            await props.adminActions.getAdminUsers({ obj, ...props }, props.history);
        }
    };

    const { adminUsers, adminUsersCount } = props.admin;
    return (
        <div className='adminTable'>
            <h1>
                Admin Users
                <button onClick={() => setCreateUserModal(true)}>
                    <PlusIcon />
                </button>
            </h1>
            {console.log(props)}

            <div className='table'>
                <table>
                    <thead>
                        <tr className='tableHeader'>
                            <th>FULL NAME</th>
                            <th>EMAIL</th>
                            <th>PHONE</th>
                            <th>STATUS</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {adminUsers &&
                            adminUsers.map((item, index) => (
                                <tr className='breakrow'>
                                    <td>
                                        <div>
                                            {item.firstName && item.firstName}{' '}
                                            {item.lastName && item.lastName}
                                        </div>
                                    </td>
                                    <td>
                                        <div>{item.email && item.email}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.phoneNo && item.countryCode ? (
                                                <>
                                                    (+{item.countryCode && item.countryCode})-
                                                    {item.phoneNo && item.phoneNo}
                                                </>
                                            ) : null}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {' '}
                                            <Select
                                                className='activeStatusSelect'
                                                onChange={(val, obj) =>
                                                    changeActiveStatus(val, item)
                                                }
                                                placeholder='Active Status'
                                                value={item.activeStatus && item.activeStatus}
                                                selection
                                            >
                                                {statusOptions &&
                                                    statusOptions.map((item, index) => (
                                                        <Select.Option key={index} value={item}>
                                                            {item}
                                                        </Select.Option>
                                                    ))}
                                            </Select>{' '}
                                            {/* <Switch
                        size={"small"}
                        style={{ marginRight: 10 }}
                        checked={
                          item.activeStatus && item.activeStatus == "ACTIVATED"
                            ? true
                            : false
                        }
                        onChange={onChange}
                      /> */}
                                            {/* {item.activeStatus && item.activeStatus} */}
                                        </div>
                                    </td>
                                    <td>
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={() =>
                                                adminActionMenu({
                                                    item: item,
                                                    index: index
                                                })
                                            }
                                            placement='bottomLeft'
                                            arrow
                                        >
                                            <div className='three-dots'>
                                                <span></span>
                                            </div>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>

            {createUserModal && (
                <CreateUserModal
                    title={'Create Admin User'}
                    email={email}
                    password={password}
                    userType={userType}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    createUserModal={createUserModal}
                    setCreateUserModal={setCreateUserModal}
                    createUser={createUser}
                />
            )}
            <Pagination
                current={page}
                total={adminUsersCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        admin: state.admin
    };
}

function mapDispatchToProps(dispatch) {
    return {
        adminActions: bindActionCreators(adminActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
