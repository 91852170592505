import React, { useState, useEffect } from 'react';
import { Pagination, Menu, Dropdown } from 'antd';
import PlusIcon from '@/assets/img/svgs/plusIcon';
import { useDispatch, useSelector } from 'react-redux';
import * as adminActions from '@/actions/adminActions';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { deleteEmailTemplate } from '@/actions/adminActions';

function TemplateList(props) {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);

    const admin = useSelector((state) => state.admin);
    const dispatch = useDispatch();

    const { emailTemplatesCount, emailTemplates } = admin;

    const getTemplates = async () => {
        let obj = {
            page: page,
            perPage: limit
        };
        dispatch(adminActions.getEmailTemplates({ ...props, obj }));
    };

    useEffect(() => {
        getTemplates();
    }, []);

    const deleteTemplate = async (id) => {
        let obj = {
            templateId: id
        };
        let deleteTemplate = await dispatch(deleteEmailTemplate({ ...props, obj }));
        if (deleteTemplate) {
            getTemplates();
        }
    };

    const templateActionMenu = (props) => (
        <Menu>
            <Menu.Item>
                <Link to={`/admin/emailTemplates/edit/${props.item._id}`}>Edit Template</Link>
            </Menu.Item>
            <Menu.Item onClick={() => deleteTemplate(props.item._id)}>
                <a>Delete Template</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <>
            <h1>
                Email Templates{' '}
                <button onClick={() => props.history.push('/admin/emailTemplates/create')}>
                    <PlusIcon />
                </button>
            </h1>

            <div className='table'>
                <table>
                    <thead>
                        <tr className='tableHeader'>
                            <th>SUBJECT</th>
                            <th>UNIQUE_KEYWORD</th>
                            <th>CREATED DATE</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {emailTemplates &&
                            emailTemplates.map((item, index) => (
                                <tr>
                                    <td>{item.subject && item.subject}</td>
                                    <td>{item.unique_keyword && item.unique_keyword}</td>
                                    <td>
                                        {item.createdAt &&
                                            moment(item.createdAt).format('DD-MM-YYYY')}
                                    </td>
                                    <td>
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={() =>
                                                templateActionMenu({
                                                    item: item,
                                                    index: index
                                                })
                                            }
                                            placement='bottomLeft'
                                            arrow
                                        >
                                            <div className='three-dots'>
                                                <span></span>
                                            </div>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            {/* {reportModal && (
          <ReportDescModal
            reportModal={reportModal}
            setReportModal={setReportModal}
            reports={reportItem.reports}
          />
        )} */}
            <Pagination
                current={page}
                total={emailTemplatesCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </>
    );
}

export default TemplateList;
