import Axios from 'axios';
import * as Types from './types';
import setAuthToken from '../utils/setAuthToken';
import { message } from 'antd';

let { REACT_APP_API_URI } = process.env;

if (process.env.NODE_ENV === 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const login = (user, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/login`, user)
            .then((response) => {
                console.log(response);
                localStorage.setItem('auth_token', response.data.user.token);
                localStorage.setItem('refresh_token', response.data.user.refresh_token);

                setAuthToken(response.data.user.token);

                dispatch({
                    type: Types.SET_USER,
                    payload: {
                        user: response.data.user
                    }
                });
                console.log(response.data);
                history.push('/admin/dashboard');
                message.success('Signed in successfully!');
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error('Could not sign you in!');
                resolve(false);
            });
    });
};

export const logout = (history) => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('refresh_token');
    setAuthToken();
    console.log('hi', history);
    message.success('Successfully logged out!');
    return {
        type: Types.SET_USER,
        payload: {
            user: {}
        }
    };
};

export const getAllUserCount = (props, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/admin/getAllUserCount`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);

                dispatch({
                    type: Types.GET_ALL_USER_COUNT,
                    payload: {
                        allUserCount: response.data.allUserCount
                    }
                });
                console.log(response.data);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const getCustomerUsers = (props, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.get(
            `${REACT_APP_API_URI}/api/admin/getCustomerUsers?page=${props.obj.page}&limit=${props.obj.limit}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.auth_token
                }
            }
        )
            .then((response) => {
                console.log(response);

                dispatch({
                    type: Types.GET_CUSTOMER_USERS,
                    payload: {
                        customerUsers: response.data.customerUsers,
                        customerUsersCount: response.data.customerUsersCount
                    }
                });
                console.log(response.data);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const getCustomerProfile = (props, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/getCustomerProfile`, props.profileData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);

                dispatch({
                    type: Types.GET_CUSTOMER_PROFILE,
                    payload: {
                        customerProfile: response.data.customerProfile
                    }
                });
                console.log(response.data);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const getHospitalUsers = (props, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.get(
            `${REACT_APP_API_URI}/api/admin/getHospitalUsers?page=${props.obj.page}&limit=${props.obj.limit}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.auth_token
                }
            }
        )
            .then((response) => {
                console.log(response);

                dispatch({
                    type: Types.GET_HOSPITAL_USERS,
                    payload: {
                        lawfirmUsers: response.data.lawfirmUsers,
                        lawfirmUsersCount: response.data.lawfirmUsersCount
                    }
                });
                console.log(response.data);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const getHospitalProfile = (props, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/getHospitalProfile`, props.profileData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);

                dispatch({
                    type: Types.GET_HOSPITAL_PROFILE,
                    payload: {
                        lawfirmProfile: response.data.lawfirmProfile
                    }
                });
                console.log(response.data);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const getDoctorUsers = (props, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.get(
            `${REACT_APP_API_URI}/api/admin/getDoctorUsers?page=${props.obj.page}&limit=${props.obj.limit}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.auth_token
                }
            }
        )
            .then((response) => {
                console.log(response);

                dispatch({
                    type: Types.GET_DOCTOR_USERS,
                    payload: {
                        doctorUsers: response.data.doctorUsers,
                        doctorUsersCount: response.data.doctorUsersCount
                    }
                });
                console.log(response.data);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const getDoctorProfile = (props, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/getDoctorProfile`, props.profileData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);

                dispatch({
                    type: Types.GET_DOCTOR_PROFILE,
                    payload: {
                        doctorProfile: response.data.doctorProfile
                    }
                });
                console.log(response.data);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const getAdminProfile = (props, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/getAdminProfile`, props.profileData, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log('adminProfile', response);

                dispatch({
                    type: Types.GET_ADMIN_PROFILE,
                    payload: {
                        adminProfile: response.data.adminProfile
                    }
                });
                console.log(response.data);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const saveAdminProfile = (props, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/saveAdminProfile`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);

                dispatch({
                    type: Types.SAVE_ADMIN_PROFILE,
                    payload: {
                        savedAdminProfile: response.data.savedAdminProfile
                    }
                });
                console.log(response.data);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const deleteUser = (props, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/deleteUser`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response.data);
                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const getAdminUsers = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(
            `${REACT_APP_API_URI}/api/admin/getAdminUsers?page=${props.obj.page}&limit=${props.obj.limit}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.auth_token
                }
            }
        )
            .then((response) => {
                console.log(response);

                dispatch({
                    type: Types.GET_ADMIN_USERS,
                    payload: {
                        adminUsers: response.data.adminUsers,
                        adminUsersCount: response.data.adminUsersCount
                    }
                });
                console.log(response.data);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const getAllBlogsAdmin = (props, history) => (dispatch) => {
    console.log(history);
    return new Promise((resolve, reject) => {
        Axios.get(
            `${REACT_APP_API_URI}/api/admin/getAllBlogsAdmin?page=${props.obj.page}&limit=${props.obj.limit}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.auth_token
                }
            }
        )
            .then((response) => {
                console.log(response);

                dispatch({
                    type: Types.GET_ALL_BLOGS_ADMIN,
                    payload: {
                        blogs: response.data.blogs,
                        allBlogsCount: response.data.allBlogsCount
                    }
                });
                console.log(response.data);
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                message.error(error.response.data.message);
                resolve(false);
            });
    });
};

export const updatePost = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/updateBlog/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                resolve(false);
                console.log(error.response);
            });
    });
};

export const getReportedPortfolios = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(
            `${REACT_APP_API_URI}/api/admin/getReportedPortfolioItems?page=${props.obj.page}&limit=${props.obj.limit}`,
            props.obj,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.auth_token
                }
            }
        )
            .then((response) => {
                console.log(response);
                dispatch({
                    type: Types.GET_REPORTED_PORTFOLIO_ITEMS,
                    payload: {
                        reportedPortfolios: response.data.reportedPortfolios,
                        allPortfolioItemCount: response.data.allPortfolioItemCount
                    }
                });
                // message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                resolve(false);
                console.log(error.response);
            });
    });
};

export const updatePortfolioItem = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/updateReportedPortfolioItem/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);
                console.log('portfolio item', response.data.updatedPortfolioItem);

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                message.error('Portfolio retrieval failed');
                resolve(false);
                console.log(error.response);
            });
    });
};

export const createAdminUser = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/createAdminUser/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);
                console.log('created admin', response.data.admin);

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                message.error('Admin user creation failed');
                resolve(false);
                console.log(error.response);
            });
    });
};

export const deleteAdminUser = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/deleteAdminUser/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);
                console.log('deleted admin', response.data.deletedAdmin);

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                if (error.response.data.message) {
                    message.error(error.response.data.message);
                } else {
                    message.error('Admin user deletion failed');
                }

                resolve(false);
                console.log(error.response);
            });
    });
};

//change user active status
export const changeActiveStatus = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/changeActiveStatus/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);
                console.log('deleted admin', response.data.deletedAdmin);

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                if (error.response.data.message) {
                    message.error(error.response.data.message);
                } else {
                    message.error('Admin user active status could not be changed');
                }

                resolve(false);
                console.log(error.response);
            });
    });
};

export const getEmailTemplates = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/emailTemplates/getTemplates/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);
                dispatch({
                    type: Types.GET_EMAIL_TEMPLATES,
                    payload: {
                        emailTemplates: response.data && response.data.emailTemplates,
                        emailTemplatesCount: response.data && response.data.emailTemplatesCount
                    }
                });

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    message.error(error.response.data.message);
                } else {
                    message.error('Email templates retrieval failed');
                }

                resolve(false);
                console.log(error.response);
            });
    });
};

export const createEmailTemplate = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/emailTemplates/createTemplate/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    message.error(error.response.data.message);
                } else {
                    message.error('Email template creation failed');
                }

                resolve(false);
                console.log(error.response);
            });
    });
};

export const deleteEmailTemplate = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/emailTemplates/deleteTemplate/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    message.error(error.response.data.message);
                } else {
                    message.error('Email template deletion failed');
                }

                resolve(false);
                console.log(error.response);
            });
    });
};

export const getSingleTemplate = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/emailTemplates/getSingleTemplate/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);
                dispatch({
                    type: Types.GET_SINGLE_EMAIL_TEMPLATE,
                    payload: {
                        singleEmailTemplate: response.data.singleEmailTemplate
                    }
                });
                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    message.error(error.response.data.message);
                } else {
                    message.error('Email template retrieval failed');
                }

                resolve(false);
                console.log(error.response);
            });
    });
};

// export const getStripeCustomers = (props) => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     Axios.get(`${REACT_APP_API_URI}/api/admin/getStripeCustomers/`, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + localStorage.auth_token
//       }
//     })
//       .then((response) => {
//         console.log(response);
//         dispatch({
//           type: Types.GET_STRIPE_CUSTOMERS,
//           payload: {
//             stripeCustomers: response.data && response.data.stripeCustomers
//           }
//         });

//         message.success(response.data.message);
//         resolve(true);
//       })
//       .catch((error) => {
//         if (
//           error.response &&
//           error.response.data &&
//           error.response.data.message
//         ) {
//           message.error(error.response.data.message);
//         } else {
//           message.error("Stripe Customers retrieval failed");
//         }

//         resolve(false);
//         console.log(error.response);
//       });
//   });
// };

// export const getStripeProducts = (props) => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     Axios.get(`${REACT_APP_API_URI}/api/admin/getStripeProducts/`, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + localStorage.auth_token
//       }
//     })
//       .then((response) => {
//         console.log(response);
//         dispatch({
//           type: Types.GET_STRIPE_PRODUCTS,
//           payload: {
//             stripeProducts: response.data && response.data.stripeProducts
//           }
//         });

//         message.success(response.data.message);
//         resolve(true);
//       })
//       .catch((error) => {
//         console.log(error);
//         if (
//           error.response &&
//           error.response.data &&
//           error.response.data.message
//         ) {
//           message.error(error.response.data.message);
//         } else {
//           message.error("Stripe Products retrieval failed");
//         }

//         resolve(false);
//         console.log(error.response);
//       });
//   });
// };

// export const getStripeSubscriptions = (props, history) => (dispatch) => {
//   return new Promise((resolve, reject) => {
//     Axios.get(`${REACT_APP_API_URI}/api/admin/getStripeSubscriptions/`, {
//       headers: {
//         "Content-Type": "application/json",
//         Authorization: "Bearer " + localStorage.auth_token
//       }
//     })
//       .then((response) => {
//         console.log("subscriptions", response);
//         dispatch({
//           type: Types.GET_STRIPE_SUBSCRIPTIONS,
//           payload: {
//             stripeSubscriptions:
//               response.data && response.data.stripeSubscriptions
//           }
//         });

//         message.success(response.data.message);
//         resolve(true);
//       })
//       .catch((error) => {
//         if (
//           error.response &&
//           error.response.data &&
//           error.response.data.message
//         ) {
//           message.error(error.response.data.message);
//         } else {
//           message.error("Stripe Subscriptions retrieval failed");
//         }

//         resolve(false);
//         console.log(error.response);
//       });
//   });
// };

export const getMailListings = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/getMailListings/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log('subscriptions', response);
                let data = [];
                if (response.data && response.data.mailDataList && response.data.type) {
                    if (response.data.type === 'Support') {
                        data.support = response.data.mailDataList;
                        if (response.data.mailDataListCount) {
                            data.supportCount = response.data.mailDataListCount;
                        }
                    }
                    if (response.data.type === 'Feedback') {
                        data.feedbacks = response.data.mailDataList;
                        if (response.data.mailDataListCount) {
                            data.feedbacksCount = response.data.mailDataListCount;
                        }
                    }
                    if (response.data.type === 'Sales') {
                        data.sales = response.data.mailDataList;
                        if (response.data.mailDataListCount) {
                            data.salesCount = response.data.mailDataListCount;
                        }
                    }
                    if (response.data.type === 'General') {
                        data.general = response.data.mailDataList;
                        if (response.data.mailDataListCount) {
                            data.generalCount = response.data.mailDataListCount;
                        }
                    }
                    if (response.data.type === 'Complaints') {
                        data.complaints = response.data.mailDataList;
                        if (response.data.mailDataListCount) {
                            data.complaintsCount = response.data.mailDataListCount;
                        }
                    }
                }
                console.log('data', data);
                dispatch({
                    type: Types.GET_MAIL_LISTINGS,
                    payload: {
                        mailDataList: data
                    }
                });

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    message.error(error.response.data.message);
                } else {
                    message.error('Stripe Subscriptions retrieval failed');
                }

                resolve(false);
                console.log(error.response);
            });
    });
};

export const getMailListingsCount = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/admin/getMailListingsCount/`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log('subscriptions', response);
                dispatch({
                    type: Types.GET_MAIL_LISTINGS_COUNT,
                    payload: {
                        mailCounts: response.data && response.data.mailCounts
                    }
                });

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    message.error(error.response.data.message);
                } else {
                    message.error('Stripe Subscriptions retrieval failed');
                }

                resolve(false);
                console.log(error.response);
            });
    });
};

export const updateMail = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/admin/updateMail/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log('subscriptions', response);

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                if (error.response && error.response.data && error.response.data.message) {
                    message.error(error.response.data.message);
                } else {
                    message.error('Stripe Subscriptions retrieval failed');
                }

                resolve(false);
                console.log(error.response);
            });
    });
};
