export const CAREGIVER_TYPES = [
    'Certified Nursing Assistant (CNA)',
    'Home Health Aide (HHA)',
    'Personal Care Aide (PCA)',
    'Companion Caregiver',
    'Live-In Caregiver',
    'Respite Caregiver',
    'Hospice Caregiver',
    'Palliative Caregiver',
    "Alzheimer's/Dementia Caregiver",
    'Special Needs Caregiver',
    'Pediatric Caregiver',
    'Geriatric Caregiver',
    'Rehabilitation Caregiver',
    'Post-Surgery Caregiver',
    'Chronic Illness Caregiver',
    'Physical Therapy Assistant',
    'Occupational Therapy Assistant',
    'Speech-Language Pathology Assistant',
    'Behavioral Therapist Assistant',
    'Developmental Therapy Assistant',
    'Nutrition Assistant',
    'Medication Assistant',
    'Respiratory Therapy Assistant',
    'Wound Care Assistant',
    'Assistive Technology Specialist',
    'Care Coordinator',
    'Case Manager',
    'Home Care Manager',
    'Community Health Worker',
    'Patient Advocate',
    'End-of-Life Doula',
    'Family Caregiver Trainer',
    'Dietary Aide',
    'Hygiene Assistant',
    'Transportation Assistant',
    'Emotional Support Companion',
    'Crisis Intervention Specialist',
    'Mental Health Support Worker',
    'Veteran Caregiver',
    'Childcare Provider'
];

export const CaregiverTypeOptions = CAREGIVER_TYPES.reduce((acc, value, index) => {
    return (acc = [...acc, { value: value, id: index + 1 }]);
}, []);
