import React, { useRef, useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import * as blogActions from '@/actions/blogActions';
import { connect, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClassicEditor from '@dmc4719/ckeditor5-custom-build/build/ckeditor';
import { CONFIG } from '../../../MiniComponents/CKeditorConfig';
import '@/assets/css/createBlog.css';
import MyCKEditorUploadAdapter from './MyCKEditorUploadAdapter';
import { Select, message, Button } from 'antd';
import { useParams } from 'react-router-dom';
import PageHeader from '../PageHeader';
import { blogSpecializationAreas } from '@/assets/json/DoctorPracticeAreas';

const { Option } = Select;

const EditBlog = (props) => {
    const editor = useRef(null);
    const [file, setCoverImage] = useState(null);
    const [coverImageError, setCoverImageError] = useState('');
    const [tempCoverImage, setTempCoverImage] = useState();
    const [title, setTitle] = useState({ value: '', message: '' });
    const [editorValue, setEditorValue] = useState({
        value: '',
        message: ''
    });
    const [category, setCategory] = useState({ value: [], message: '' });
    const [singlePost, setSinglePost] = useState(null);
    const [oldCoverImage, setOldCoverImage] = useState('');
    const [postId, setPostId] = useState(null);
    const {userId, userType, user} = useSelector((state) => state.auth);
    const [loading, setLoading] = useState(false);

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            props.blogActions.getSinglePost({ ...props, postId: id });
        }
    }, [id]);

    useEffect(() => {
        if (singlePost) {
            console.log('hi');
            setPostId(props.blogs.singlePost._id);
            setTitle({ ...title, value: props.singlePost.title });
            setEditorValue({ ...editorValue, value: props.singlePost.body });
            setCategory({ ...category, value: props.singlePost.category });
            if (props.singlePost.coverImage) {
                setTempCoverImage(props.singlePost.coverImage);

                setOldCoverImage(props.singlePost.coverImage);
            }
        }
    }, [singlePost]);
    useEffect(() => {
        if (props.blogs.singlePost) {
            console.log(props.blogs.singlePost.id);
            if (id == props.blogs.singlePost._id) {
                console.log('matched');
                setSinglePost(props.blogs.singlePost);
            }
        }
    }, [props.blogs.singlePost]);

    function handleChange(value) {
        if (value.length) {
            setCategory({ value: value, message: '' });
        } else {
            setCategory({ value: value, message: 'Catgory cannot be empty' });
        }
    }

    const updateBlog = async () => {
        console.log('clicked');
        let error = false;

        if (!title.value || !editorValue.value || !category) {
            if (!title.value) {
                setTitle({ ...title, message: 'Title field cannot be empty' });
                error = true;
            }

            if (!editorValue.value) {
                setEditorValue({
                    ...editorValue,
                    message: 'Body field cannot be empty'
                });
                error = true;
            }

            if (!category.value.length) {
                setCategory({ ...category, message: 'Catgory cannot be empty' });
                error = true;
            }

            if (!file && !tempCoverImage && !oldCoverImage) {
                setCoverImageError('Cover image cannot be empty');
                error = true;
            }

            if (error) {
                return;
            }
        }
        setLoading(true);

        let obj = new FormData();
        obj.append('title', title.value);
        obj.append('body', editorValue.value);
        obj.append('postId', postId);
        for (let i = 0; i < category.value.length; i++) {
            obj.append(`category[${i}]`, category.value[i]);
        }
        obj.append('author', userId);
        obj.append('authorType', userType);
        if(userType === 'DOCTOR'){
            obj.append('identify', user.hospital);
        }
        obj.append('image', file);

        console.log(obj);
        const updatedPost = await props.blogActions.updateBlogPost(
            { obj, ...props },
            props.history
        );
        if (updatedPost) {
            props.history.goBack();
            setLoading(false);
            message.success('Post created successfully');
        } else {
            setLoading(true);
        }
    };

    return (
        <div className='createPost' style={{ width: '100%' }}>
            <PageHeader heading='Edit Blog Post' onBack={() => props.history.goBack()} />
            <div className='input-cont'>
                <img className='tempCoverImage' src={tempCoverImage ? tempCoverImage : null} />
                {coverImageError && <p className='error-text'>*{coverImageError}</p>}
            </div>

            <label htmlFor='coverImage' className='chooseCover gray-btn'>
                Choose Cover
                <input
                    id='coverImage'
                    type='file'
                    onChange={(e) => {
                        setCoverImage(e.target.files[0]);
                        if (e.target.files[0]) {
                            setCoverImageError('');
                            setTempCoverImage(URL.createObjectURL(e.target.files[0]));
                        }
                    }}
                />
            </label>
            <div className='input-cont'>
                <input
                    placeholder='Post title'
                    value={title.value}
                    onChange={(e) => {
                        if (e.target.value != '') {
                            setTitle({ ...title, message: '', value: e.target.value });
                        } else {
                            setTitle({
                                ...title,
                                message: 'Title field cannot be empty',
                                value: e.target.value
                            });
                        }
                    }}
                />
                {title.message && <p className='error-text'>*{title.message}</p>}
            </div>

            <div className='input-cont'>
                <Select
                    mode='multiple'
                    placeholder='Select area of Specialization'
                    value={category.value}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                >
                    {blogSpecializationAreas.map((item, index) => (
                        <Option value={item} key={index}>
                            {item}
                        </Option>
                    ))}
                </Select>
                {category.message && <p className='error-text'>*{category.message}</p>}
            </div>

            <div className='input-cont'>
                <CKEditor
                    ref={editor}
                    config={CONFIG}
                    // style={{ width: "100%", margin: "auto" }}
                    editor={ClassicEditor}
                    data={editorValue.value}
                    onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                            return new MyCKEditorUploadAdapter({ loader, ...props });
                        };
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data != '') {
                            setEditorValue({ ...editorValue, value: data, message: '' });
                        } else {
                            setEditorValue({
                                ...editorValue,
                                message: 'Body field cannot be empty',
                                value: data
                            });
                        }
                        console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />
                {editorValue.message && <p className='error-text'>{editorValue.message}</p>}
            </div>

            <Button
                style={{ width: 250 }}
                type='primary'
                loading={loading}
                disabled={loading}
                onClick={() => updateBlog()}
            >
                Update Blog
            </Button>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    blogs: state.blog,
    singlePost: state.blog.singlePost && state.blog.singlePost
});

const mapDispatchToProps = (dispatch) => ({
    blogActions: bindActionCreators(blogActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(EditBlog);
