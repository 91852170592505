import * as Types from '../actions/types';

const init = {
    dashboard: {
        practiceAreas: 0,
        branches: 0,
        pharmacyServices: 0,
        roles: 0,
        appointments: 0
    },
    pharmacies: [],
    pharmacySingle: {},
    featuredHospitals: [],
    pharmacyForAppointment: {
        pharmacy: {},
        timeSlots: [],
        availableDays: [],
        availability: {}
    },
    searchResults: {
        pharmacies: [],
        pharmaciesCount: 0,
        pharmaciesSuggestions: []
    },
    pharmacyStatCounts: {
        posts: 0,
        likes: 0,
        reviews: 0,
        followers: 0
    },
    lineChart: null,
    pieChart: []
};

const pharmacyReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_PHARMACIES: {
            return {
                ...state,
                pharmacies: action.payload.pharmacies
            };
        }
        case Types.GET_FEATURED_PHARMACIES: {
            return {
                ...state,
                featuredPharmacies: action.payload.pharmacies
            };
        }
        case Types.GET_SINGLE_PHARMACY: {
            return {
                ...state,
                pharmacySingle: action.payload.pharmacySingle
            };
        }

   
        case Types.CLEAR_PHARMACIES: {
            return {
                ...state,
                pharmacies: [],
                pharmacySingle: {},
            };
        }
        case Types.GET_PHARMACY_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    pharmacies: action.payload.pharmacies,
                    pharmaciesCount: action.payload.pharmaciesCount
                }
            };
        }
        case Types.CLEAR_PHARMACY_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    pharmacies: [],
                    pharmaciesCount: 0
                }
            };
        }
        case Types.GET_PHARMACY_STAT_COUNTS: {
            return {
                ...state,
                pharmacyStatCounts: {
                    posts: action.payload.counts && action.payload.counts.posts,
                    likes: action.payload.counts && action.payload.counts.likes,
                    reviews: action.payload.counts && action.payload.counts.reviews,
                    followers: action.payload.counts && action.payload.counts.followers
                }
            };
        }
        case Types.GET_PHARMACY_EXTRACTED_TIME_SLOTS: {
            return {
                ...state,
                pharmacyForAppointment: {
                    ...state.pharmacyForAppointment,
                    availableDays: action.payload.availableDays,
                    timeSlots: action.payload.timeSlots,
                    availability: action.payload.pharmacyAvailability
                }
            };
        }
        case Types.GET_PHARMACY_FOR_APPOINTMENT: {
            return {
                ...state,
                pharmacyForAppointment: {
                    ...state.pharmacyForAppointment,
                    pharmacy: action.payload.pharmacy
                }
            };
        }


        case Types.GET_LINE_CHART_DATA: {
            return {
                ...state,
                lineChart: action.payload.lineChart
            };
        }
        case Types.GET_PIE_CHART_DATA: {
            return {
                ...state,
                pieChart: action.payload.pieChart
            };
        }
        case Types.GET_PHARMACY_DASHBOARD: {
            return {
                ...state,
                dashboard: action.payload.dashboard
            };
        }
    
        default:
            return state;
    }
};

export default pharmacyReducer;
