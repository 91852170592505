import React, { useState } from 'react';
import '../hospitalGeneral.css';
import { connect } from 'react-redux';
import { Collapse, Select } from 'antd';
import moment from 'moment';
const { Panel } = Collapse;
const { Option } = Select;

const General= (props) => {
    const [activeKey, setActiveKey] = useState(['1', '2', '3', '4']);
    const [selectedPanel, setSelectedPanel] = useState('');
    function callback(keys) {
        console.log(keys);
        setActiveKey(keys);
    }

    function handleChange(value) {
        console.log(`selected ${value}`);
        if (value == '') {
            setSelectedPanel('');
            setActiveKey(['1', '2', '3', '4']);
        } else {
            let actKeys = [];
            actKeys = [value];
            setActiveKey(actKeys);
            setSelectedPanel(value);
        }
    }
    const { hospitalSingle, selectedAdressIndex } = props;
  

    let branchIndex = selectedAdressIndex && selectedAdressIndex;
    const selectedBranch =
        hospitalSingle.branches && hospitalSingle.branches[branchIndex ? branchIndex : 0];
    return (
        <div className='hospital-general-main'>
            <div className='activeTab'>
                <p>General</p>
            </div>
            <div className='sectionFilterCont'>
                <Select
                    defaultValue=''
                    value={selectedPanel}
                    style={{ width: 320, maxWidth: '100%' }}
                    onChange={handleChange}
                >
                    <Option value=''>All</Option>
                    <Option value='1'>Introduction</Option>
                    <Option value='2'>Associations</Option>
                    <Option value='3'>Honors & Awards</Option>
                    <Option value='4'>Verified Credentials</Option>
                </Select>
            </div>

            <Collapse defaultActiveKey={['1']} activeKey={activeKey} onChange={callback}>
                <Panel header='INTRODUCTION' key='1'>
                    <div className='general-intro-body'>
                        <p>{selectedBranch ? selectedBranch.profileSummary : 'No Summary'}</p>
                    </div>
                </Panel>
                <Panel header='ASSOCIATIONS' key='2'>
                    <div className='general-intro-body-edu'>
                        <div className='general-intro-body-edu-col'>
                            <div className='genral-colums'>
                                <p>Organization</p>
                            </div>
                            <div className='genral-colums'>
                                <p>Status</p>
                            </div>
                            <div className='genral-colums'>
                                <p>Date</p>
                            </div>
                        </div>
                    </div>
                    {hospitalSingle &&
                        hospitalSingle.associations &&
                        hospitalSingle.associations.length !== 0 &&
                        hospitalSingle.associations.map((item, index) => (
                            <div className='general-intro-body-edu'>
                                <div className='general-intro-body-edu-col2' key={index}>
                                    <div className='genral-colums'>
                                        <p>{item.organization}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.status}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.date}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </Panel>
                <Panel header='HONORS & AWARDS' key='3'>
                    <div className='general-intro-body-edu'>
                        <div className='general-intro-body-edu-col'>
                            <div className='genral-colums'>
                                <p>Name / Organization </p>
                            </div>
                            <div className='genral-colums'>
                                <p>Date</p>
                            </div>
                        </div>
                    </div>
                    {hospitalSingle &&
                        hospitalSingle.honors &&
                        hospitalSingle.honors.length !== 0 &&
                        hospitalSingle.honors.map((item, index) => (
                            <div className='general-intro-body-edu'>
                                <div className='general-intro-body-edu-col2' key={index}>
                                    <div className='genral-colums'>
                                        <p>{item.organization}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.date}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </Panel>
                <Panel header='VERIFIED CERTIFICATION' key='4'>
                    <div className='general-intro-body'>
                        {hospitalSingle &&
                            hospitalSingle.honors &&
                            hospitalSingle.honors.filter(
                                (item, index) => item.verificationStatus == 'APPROVED'
                            ).length > 0 && <p className='heading'>Honors</p>}
                        {/* <p className="heading">Honors</p> */}
                        <table>
                            {hospitalSingle &&
                                hospitalSingle.honors &&
                                hospitalSingle.honors.length !== 0 &&
                                hospitalSingle.honors.map((item, index) => {
                                    if (item.verificationStatus == 'APPROVED') {
                                        return (
                                            <tr>
                                                <td>
                                                    <div>
                                                        {item.organization && item.organization}
                                                    </div>
                                                </td>{' '}
                                                <td>
                                                    <div>
                                                        {item.date &&
                                                            moment(item.date).format(
                                                                'MMM DD, YYYY'
                                                            )}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                        </table>

                        {hospitalSingle &&
                            hospitalSingle.associations &&
                            hospitalSingle.associations.filter(
                                (item, index) => item.verificationStatus == 'APPROVED'
                            ).length > 0 && <p className='heading'>Associations</p>}

                        <table>
                            {hospitalSingle &&
                                hospitalSingle.associations &&
                                hospitalSingle.associations.length !== 0 &&
                                hospitalSingle.associations.map((item, index) => {
                                    if (item.verificationStatus == 'APPROVED') {
                                        return (
                                            <tr>
                                                <td>
                                                    <div>
                                                        {item.organization && item.organization}
                                                    </div>
                                                </td>{' '}
                                                <td>
                                                    <div>{item.status && item.status}</div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                        </table>
                    </div>
                </Panel>
            </Collapse>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    hospital: state.hospital,
    hospitalSingle: state.hospital.hospitalSingle
});

export default connect(mapStateToProps)(General);
