import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/userActions';
import PhoneInput from 'react-phone-input-2';
import OtpInput from 'react-otp-input';
import { Modal, Button } from 'antd';

const ChangePhoneOrEmail = (props) => {
    const [password, setPassword] = useState('');
    const [oldPass, setOldPass] = useState('');
    const [confPassword, setConfPassword] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [countryCode, setCountryCode] = useState({
        value: localStorage.getItem('calling_code') ? localStorage.getItem('calling_code') : '',
        message: '',
        isValid: true
    });
    const [otp, setOtp] = useState('');
    const [tab, setTab] = useState(1);

    const handleChange = (e) => {
        if (e.target.name === 'email') {
            if (e.target.value !== '') {
                setEmail(e.target.value);
            } else {
                setEmail('');
            }
        }
        if (e.target.name === 'oldPass') {
            if (e.target.value !== '') {
                setOldPass(e.target.value);
            } else {
                setOldPass('');
            }
        }
        if (e.target.name === 'password') {
            if (e.target.value !== '') {
                setPassword(e.target.value);
            } else {
                setPassword('');
            }
        }
        if (e.target.name === 'Confirm Password') {
            if (e.target.value !== '') {
                setConfPassword(e.target.value);
            } else {
                setConfPassword('');
            }
        }
        if (e.target.name === 'phone') {
            console.log('here');
            if (
                (e.target.value.length <= 10 && e.target.value.match(/^[0-9]+$/)) ||
                e.target.value.length == 0
            ) {
                setPhone(e.target.value);
            }
        }
    };

    const handleButton = async (e) => {
        e.preventDefault();
        let response;
        let user = {
            phoneNo: phone,
            countryCode: countryCode.value,
            userType: props.auth.userType,
            userId: props.auth.userId
        };

        if (props.type == 'phone') {
            if (tab == 1) {
                let checkUser = await props.actions.checkIfDiffUserWithPhoneNoExists(
                    { ...props, user },
                    props.history
                );
                if (checkUser) {
                    response = await props.actions.sendOtp(user, props.history);
                    if (response) {
                        setTab(2);
                    }
                }
            }

            if (tab == 2) {
                user.otp = otp.value;
                let otpVerified = await props.actions.verifyOtp(user, props.history);

                if (otpVerified) {
                    let changePhoneNo = await props.actions.changePhoneNumber(
                        { ...props, user },
                        props.history
                    );
                    if (changePhoneNo) {
                        let obj = {
                            userId: props.auth.userId,
                            userType: props.auth.userType
                        };
                        await props.actions.getProfile(obj, props.history);
                        props.onClose('disableAll');
                    }
                }
            }
        }
        if (props.type == 'email') {
            user.newEmail = email;

            let resetEmailRequest = await props.actions.resetEmailRequest(
                { ...props, user },
                props.history
            );
            if (resetEmailRequest) {
                props.onClose('disableAll');
            }
        }
    };

    const handleOtpChange = (otpdata) => {
        console.log(otpdata);
        setOtp({ ...otp, value: otpdata });
    };
    return (
        <Modal
            className='customModal '
            title={props.headerText && props.headerText}
            open={props.open}
            onOk={() => {
                props.onClose('disableAll');
            }}
            onCancel={() => {
                console.log('clicked');
                props.onClose('disableAll');
            }}
            footer={null}
        >
            <div className='confirmModalBody2'>
                {props.type === 'email' ? (
                    <>
                        <label
                            className='one-half'
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%'
                            }}
                        >
                            {props.input1}
                            <input
                                autoComplete='off'
                                name='email'
                                placeholder={props.input1}
                                onChange={handleChange}
                                value={email}
                                style={{
                                    width: '100%',
                                    height: 40,
                                    borderRadius: 4,
                                    paddingLeft: 20,
                                    border: '1px solid #ddd'
                                }}
                            />
                        </label>
                    </>
                ) : props.type === 'phone' ? (
                    <label
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '100%'
                        }}
                    >
                        {tab == 2 && (
                            <p
                                onClick={() => setTab(1)}
                                style={{ cursor: 'pointer', margin: '0px 0px 20px 0px' }}
                            >
                                <i className='fa fa-chevron-left' style={{ marginRight: 15 }}></i>
                                Go back
                            </p>
                        )}
                        {tab == 1 ? 'Enter your new phone no. :' : 'Enter OTP:'}
                        <div
                            style={{
                                display: 'flex',
                                gap: '.5rem',
                                flexDirection: 'column'
                            }}
                        >
                            {tab == 1 ? (
                                <>
                                    <PhoneInput
                                        inputStyle={{
                                            width: '100% !important',
                                            padding: '0px 0px !important',
                                            height: 40
                                        }}
                                        style={{ width: '8rem' }}
                                        country={
                                            localStorage.getItem('country_short')
                                                ? localStorage
                                                      .getItem('country_short')
                                                      .toLowerCase()
                                                : 'us'
                                        }
                                        onChange={(phone) =>
                                            setCountryCode({ ...countryCode, value: `+${phone}` })
                                        }
                                        placeholder='Country code'
                                    />
                                    <input
                                        autoComplete='hacked'
                                        className='doctor-phone-input'
                                        placeholder='Phone'
                                        name='phone'
                                        onChange={handleChange}
                                        style={{
                                            width: '100%',
                                            height: 40,
                                            borderRadius: 4,
                                            paddingLeft: 20,
                                            border: '1px solid #ddd'
                                        }}
                                    />
                                </>
                            ) : (
                                tab == 2 && (
                                    <OtpInput
                                        className='otp-input'
                                        value={otp.value}
                                        onChange={handleOtpChange}
                                        numInputs={6}
                                        separator={false}
                                        style={{ color: '#000' }}
                                        containerStyle={{ justifyContent: 'space-between' }}
                                    />
                                )
                            )}
                        </div>
                    </label>
                ) : null}
            </div>

            <Button type='primary' className='' onClick={handleButton}>
                Change
            </Button>
        </Modal>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ChangePhoneOrEmail);
