import React from 'react';
import { OTSession, OTStreams, preloadScript } from 'opentok-react';
import ConnectionStatus from './ConnectionStatus';
import Publisher from './Publisher';
import Subscriber from './Subscriber';
import '@/assets/css/videocall.css';
import ChatWindow from './ChatWindow';
import PrivateNotes from './PrivateNotes';
import InstructionWindow from './InstructionWindow';
import { socket } from '../../../MiniComponents/Socket';

class Meeting extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: null,
            connected: false,
            joined: false
        };
        this.sessionEvents = {
            sessionConnected: () => {
                this.setState({ connected: true });
            },
            sessionDisconnected: () => {
                this.setState({ connected: false });
            }
        };
    }

    static getDerivedStateFromProps(props, state) {
        const { appointment, user } = props;
        if (appointment && user && !state.joined) {
            // socket.emit('join', {
            //   conversationId: appointment?._id,
            //   userType: user?.userType,
            //   appointmentId: appointment?._id,
            //   joinedAt: Date.now(),
            //   userId: user?._id
            // })
            state.joined = true;
        }
        return state;
    }

    onError = (err) => {
        this.setState({ error: `Failed to connect: ${err.message}` });
    };

    render() {
        const {
            config,
            sessionId,
            token,
            remaining,
            appointment,
            user,
            history,
            leaveMeeting,
            noShow,
            privateNotes,
            setPrivateNotes
        } = this.props;
        const { apiKey } = config;
        let doctorName, userName, lawhospitalName, returnUrl;

        doctorName = appointment?.doctor?.firstName + ' ' + appointment?.doctor?.lastName;
        lawhospitalName = appointment?.lawfirm?.hospitalName && appointment?.lawfirm?.hospitalName;
        userName = appointment?.user?.firstName + ' ' + appointment?.user?.lastName;
        if (user?.userType == 'PATIENT') {
            returnUrl = `/patient/appointments`;
        } else {
            returnUrl = '/doctor/appointments';
        }

        return (
            <div className='videoMain' style={{ flexDirection: 'column' }}>
                <div className='warnings'>
                    <p>Guidelines</p>
                    <p>----------------------------------------------</p>
                    <ul style={{ listStyle: 'none' }}>
                        <li
                            style={{
                                padding: '3px 10px',
                                backgroundColor: 'orangered',
                                color: 'white',
                                width: 'fit-content',
                                borderRadius: 4
                            }}
                        >
                            *Once you leave you cannot rejoin the meeting.
                        </li>
                    </ul>
                </div>
                <OTSession
                    apiKey={apiKey}
                    // sessionId={'1_MX40NzQzOTI2MX5-MTY0ODY1NjQ5NTY5Mn4wVndMNXJLYmdvbi9IdzVLTG1MQ2N1d0F-fg'}
                    // token={"T1==cGFydG5lcl9pZD00NzQzOTI2MSZzaWc9MjFiYTBjNDRmOTkyNjY3ZjBmMGRlMzA4OWFhMjYzYTQyODg4NmUxOTpzZXNzaW9uX2lkPTFfTVg0ME56UXpPVEkyTVg1LU1UWTBPRFkxTmpRNU5UWTVNbjR3Vm5kTU5YSkxZbWR2Ymk5SWR6VkxURzFNUTJOMWQwRi1mZyZjcmVhdGVfdGltZT0xNjQ4NjU2NTIwJm5vbmNlPTAuNDIzNjY4NDc4MzIyMjI0NjUmcm9sZT1wdWJsaXNoZXImZXhwaXJlX3RpbWU9MTY0ODY2MDExOCZpbml0aWFsX2xheW91dF9jbGFzc19saXN0PQ=="}
                    sessionId={sessionId}
                    token={token}
                    eventHandlers={this.sessionEvents}
                    onError={this.onError}
                >
                    {this.state.error ? <div id='error'>{this.state.error}</div> : null}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            padding: '10px 0px'
                        }}
                    >
                        {' '}
                        <ConnectionStatus remaining={remaining} connected={this.state.connected} />
                        <div style={{ display: 'flex', columnGap: 10, flexWrap: 'wrap' }}>
                            {appointment &&
                                (!appointment.clientJoinedAt || !appointment.doctorJoinedAt) && (
                                    <button
                                        className='app-search-btn'
                                        style={{
                                            width: 'fit-content',
                                            padding: '5px 15px',
                                            cursor: 'pointer',
                                            height: 'fit-content'
                                        }}
                                        onClick={() => noShow()}
                                    >
                                        No Show
                                    </button>
                                )}

                            <button
                                className='app-search-btn'
                                style={{
                                    width: 'fit-content',
                                    padding: '5px 15px',
                                    cursor: 'pointer',
                                    height: 'fit-content'
                                }}
                                onClick={() => leaveMeeting()}
                            >
                                Leave Meeting
                            </button>
                        </div>
                    </div>
                    <div className='meeting'>
                        <div className='firstRow'>
                            <div className='videoCall'>
                                <Publisher appointment={appointment} />

                                <div className='subscriberWindow'>
                                    {/* <strong>
                {user?.userType == "PATIENT" ? doctorName : userName}
              </strong> */}
                                    <div className='subscriberCont'>
                                        {/* <h1>Not Available Yet</h1> */}
                                        <OTStreams className='streams'>
                                            <Subscriber appointment={appointment} />
                                        </OTStreams>
                                    </div>
                                </div>
                            </div>

                            <ChatWindow {...this.props} appointment={appointment} socket={socket} />
                        </div>
                        <div className='secondRow'>
                            <InstructionWindow
                                socket={socket}
                                appointmentId={appointment?._id}
                                conversationId={appointment?.conversation?._id}
                                userType={user?.userType}
                                appointment={appointment}
                            />
                            {user?.userType == 'DOCTOR' && (
                                <PrivateNotes
                                    privateNotes={privateNotes}
                                    setPrivateNotes={setPrivateNotes}
                                    socket={socket}
                                    appointment={appointment}
                                />
                            )}
                            <div
                                className='btnGrpMeeting'
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    columnGap: 10,
                                    height: 'fit-content'
                                }}
                            >
                                <button className='app-search-btn'>Update</button>
                            </div>
                        </div>
                    </div>
                </OTSession>
            </div>
        );
    }
}

export default preloadScript(Meeting);
