import { Button, Empty } from 'antd';
import React from 'react';
import useNotifications from '../../../../hooks/useNotifications';
import { DateTime } from 'luxon';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

const Notifications = () => {
    const { userType } = useSelector((state) => state.auth);
    const userRoute = `/${userType.toLowerCase()}`;
    const { notifications } = useNotifications();
    return (
        <div>
            <div
                className='sectionTitle'
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.4rem 1rem'
                }}
            >
                <h4 style={{ fontSize: 14, marginBottom: 0, fontWeight: 400 }}>Notifications</h4>
                <Link to={`${userRoute}/notifications`}>
                    <Button type='text' size='small'>
                        View All
                    </Button>
                </Link>
            </div>

            <div
                className='sectionContainer'
                style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
            >
                {notifications && notifications.length !== 0 ? (
                    notifications.map((notification) => (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 5,
                                padding: '5px 15px',
                                backgroundColor: 'white',
                                borderRadius: 4
                            }}
                        >
                            <div>{parse(notification.description)}</div>
                            <small style={{ color: 'gray' }}>
                                {DateTime.fromISO(notification.createdDate).toLocaleString(
                                    DateTime.DATETIME_FULL
                                )}
                            </small>
                        </div>
                    ))
                ) : (
                    <Empty description={'No Notifications available'} />
                )}
            </div>
        </div>
    );
};

export default Notifications;
