import React, { useState, useEffect } from 'react';
import { Modal, DatePicker, Select, Radio, AutoComplete, Alert } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import useWindowSize from '@/hooks/useWindowSize';
const { Option, OptGroup } = Select;

function EditAppointment(props) {
    const {
        set,
        index,
        branches,
        appointment,
        appointmentModal,
        clearAppointmentData,
        setAppointmentModal,
        date,
        setDate,
        myTimeSlots,
        selectedTimeSlot,
        setSelectedTimeSlot,
        paymentMode,
        setPaymentMode,
        consultationMode,
        setConsultationMode,
        userEmail,
        setUserEmail,
        selectedBranch,
        setSelectedBranch,
        submitForm,
        chosenUser,
        setChosenUser,
        actions,
        user,
        setUser,
        doctors,
        selectedDoctor,
        setSelectedDoctor,
        loading,
        setLoading,
        userEmailMsg,
        setUserEmailMsg,
        dateMsg,
        setDateMsg,
        doctorMsg,
        setDoctorMsg,
        timeSlotMsg,
        setTimeSlotMsg
    } = props;
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current.valueOf() < Date.now();
    }
    const { userProfile } = useSelector((state) => state.auth);
    const { stripeAccountId, stripeVerified } = userProfile;
    const { width } = useWindowSize();
    const [searchEmail, setSearchEmail] = useState('');
    const [searchedUsers, setSearchedUsers] = useState([]);
    const changeSearchEmail = async (val) => {
        console.log(val);
        setSearchEmail(val);
        await actions.chooseFromUsers({ ...props, searchEmail: val }, props.history);
    };

    useEffect(() => {
        console.log(props);
        if (props.auth && props.auth.searchedUsers) {
            console.log(props.auth.searchedUsers);
            setSearchedUsers(props.auth.searchedUsers);
        }
    }, [props.auth && props.auth.searchedUsers]);

    return (
        (<Modal
            className='customModal multiPage  appointmentModal'
            title={'Update Appointment'}
            open={appointmentModal}
            onOk={() => {
                clearAppointmentData();
                setAppointmentModal(false);
            }}
            onCancel={() => {
                clearAppointmentData();
                setAppointmentModal(false);
                // set (index);
            }}
            footer={null}
        >
                {!stripeVerified && (
                <Alert
                    style={{
                        width: width > 456 ? '45%' : '100%',
                        padding: '5px 10px',
                        marginBottom: 20
                    }}
                    type='error'
                    description={
                        'Online appointments are currently unavailable. Please ensure that your Stripe Account is connected.'
                    }
                />
            )}
            <p>*Select a branch to list all your doctors</p>
            <p>*Select a date, branch and doctor to list out time slots</p>
            {myTimeSlots && myTimeSlots.length == 0 && date ? (
                <p className='error'>
                    *No Timeslots available on {moment(date).format('DD/MM/YYYY')}
                </p>
            ) : (
                <p>*Time Slots available</p>
            )}
            <label>
                Search User:
                <AutoComplete
                    value={user.name}
                    style={{ width: 200 }}
                    onSelect={(val, item) => {
                        console.log(val, item);
                        setUser({ ...user, id: item.value, name: item.children });
                        setUserEmail(item.value);
                        setUserEmailMsg('');
                    }}
                    onSearch={changeSearchEmail}
                    onChange={(val) => setUser({ ...user, name: val })}
                    placeholder='Search User'
                >
                    {searchedUsers &&
                        searchedUsers.map((item, index) => (
                            <AutoComplete.Option key={item._id} value={item.email}>
                                {item.firstName + ' ' + item.lastName + ` (${item.email})`}
                            </AutoComplete.Option>
                        ))}
                </AutoComplete>
            </label>
            <label className={`${userEmailMsg ? 'error' : ''}`}>
                User Email:
                <input
                    style={{ height: 40 }}
                    placeholder='Type User Email'
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                />
                {userEmailMsg ? <p>{userEmailMsg}</p> : <p>User email is required</p>}
            </label>
            <label className={`${doctorMsg ? 'error' : ''}`}>
                Select doctor:
                <Select
                    placeholder='Select Doctor'
                    value={selectedDoctor}
                    onChange={(val) => setSelectedDoctor(val)}
                >
                    {doctors &&
                        doctors.map(
                            (item, index) =>
                                selectedBranch &&
                                item.hospitalBranches.includes(selectedBranch) && (
                                    <Option key={index} value={item._id}>
                                        {item.firstName} {item.lastName}
                                    </Option>
                                )
                        )}
                </Select>
                {doctorMsg ? <p>{doctorMsg}</p> : <p>Doctor is required</p>}
            </label>
            <label>
                Branch:
                <Select
                    placeholder='Select Branch Location'
                    value={selectedBranch}
                    onChange={(val) => setSelectedBranch(val)}
                >
                    {branches &&
                        branches.map((item, index) => (
                            <Option key={index} value={item._id}>
                                {item.location &&
                                    item.location.branchZip &&
                                    item.location.branchZip}
                                ,{' '}
                                {item.location &&
                                    item.location.branchAddress &&
                                    item.location.branchAddress}
                                ,{' '}
                                {item.location &&
                                    item.location.branchCity &&
                                    item.location.branchCity}
                                ,
                                {item.location &&
                                    item.location.branchCountry &&
                                    item.location.branchCountry}
                            </Option>
                        ))}
                </Select>
                <p>Branch location is required</p>
            </label>
            <label className={`${dateMsg ? 'error' : ''}`}>
                Appointment Date:
                <DatePicker
                    disabledDate={disabledDate}
                    placeholder='Select Appointment Date'
                    value={date ? moment(date) : ''}
                    onChange={(date, dateString) => {
                        setDate(dateString ? dateString : null);
                        setDateMsg('');
                    }}
                />
                {dateMsg ? <p>{dateMsg}</p> : <p>Appointment date is required</p>}
            </label>
            <label className={`${timeSlotMsg ? 'error' : ''}`}>
                Appointment Time slot:
                <Select
                    placeholder='Select Appointment Time Slot'
                    value={selectedTimeSlot}
                    onChange={(val) => {
                        setSelectedTimeSlot(val);
                        setTimeSlotMsg('');
                    }}
                >
                    {myTimeSlots &&
                        myTimeSlots.map(
                            (item, index) =>
                                index != myTimeSlots.length - 1 && (
                                    <Option key={index} value={item}>
                                        {item}{' '}
                                        {myTimeSlots[index + 1]
                                            ? ' - ' + myTimeSlots[index + 1]
                                            : ''}
                                    </Option>
                                )
                        )}
                </Select>
                {timeSlotMsg ? <p>{timeSlotMsg}</p> : <p>Time Slot is required</p>}
            </label>
            <label>
                Consultation Mode:
                <Radio.Group
                    onChange={(e) => setConsultationMode(e.target.value)}
                    value={consultationMode}
                >
                    <Radio disabled={!stripeVerified} value={'Online'}>Online</Radio>
                    <Radio value={'In-Person'}>In-Person</Radio>
                </Radio.Group>
            </label>
            <label>
                Payment Mode:
                <Radio.Group onChange={(e) => setPaymentMode(e.target.value)} value={paymentMode}>
                    <Radio disabled={!stripeVerified} value={'Online'}>Online</Radio>
                    <Radio value={'In-Person'}>In-Person</Radio>
                </Radio.Group>
            </label>
            <button className='ant-btn ant-btn-primary' onClick={() => submitForm()}>
                {loading ? <div className='spinner-border'></div> : 'Update Appointment'}
            </button>
        </Modal>)
    );
}

export default EditAppointment;
