import React, { useState, useEffect } from 'react';
import { Link, Redirect, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '../../actions/adminActions';
import '../../assets/css/admin.css';
import Customers from './Customers';
import HospitalUsers from './HospitalUsers';
import Blogs from './Blogs';
import Home from './Home';
import AdminUsers from './AdminUsers';
import '../../assets/css/adminTable.css';
import EditCustomerProfile from './EditCustomerProfile';
import EditBusinessProfile from './EditBusinessProfile';
import EditAdminProfile from './EditAdminProfile';
import EditBlog from './EditBlog';
import CreatePost from './CreatePost';
import StripeDash from './StripeDash';
import Products from './Stripe/Products';
import StripeCustomers from './Stripe/Customers';
import StripeSubscriptions from './Stripe/Subscriptions';
import EmailTemplates from './EmailTemplates';
import CreateTemplate from './EmailTemplates/CreateTemplate';
import MailListingCounts from './MailListings/MailListingCounts';
import FeedbackMail from './MailListings/FeedbackMail';
import SupportMail from './MailListings/SupportMail';
import GeneralMail from './MailListings/GeneralMail';
import SalesMail from './MailListings/SalesMail';
import ComplaintsMail from './MailListings/ComplaintsMail';

function Dashboard(props) {
    const [sidebarCollapse, setSidebarCollapse] = useState(true);
    const [activeMenu, setActiveMenu] = useState(1);

    useEffect(() => {
        window.scrollTo(0, 0);

        if (props.location.pathname === '/admin/dashboard') {
            setActiveMenu(1);
        }
        if (props.location.pathname === '/admin/admins') {
            setActiveMenu(2);
        }
        if (props.location.pathname === '/admin/customers') {
            setActiveMenu(3);
        }
        if (props.location.pathname === '/admin/hospitals') {
            setActiveMenu(4);
        }
        if (props.location.pathname === '/admin/blogs') {
            setActiveMenu(5);
        }
        // if (props.location.pathname === "/admin/portfolios" ) {
        //   setActiveMenu(6);
        // }
        if (props.location.pathname.match('/admin/emailTemplates')) {
            setActiveMenu(6);
        }
        if (props.location.pathname === '/admin/stripe') {
            setActiveMenu(7);
        }
        if (props.location.pathname.match('stripe')) {
            setActiveMenu(7);
        }
        if (props.location.pathname == 'mail-listing') {
            setActiveMenu(8);
        }
    }, [props.location.pathname]);

    const sidebarFunc = async () => {
        // if (window.innerWidth < 756) {
        //   setSidebarCollapse(true);
        // } else {
        //   setSidebarCollapse(false);
        // }
    };

    useEffect(() => {
        window.addEventListener('resize', sidebarFunc);

        return () => {
            window.removeEventListener('resize', sidebarFunc);
        };
    }, []);
    return (
        <div className='adminDash'>
            <div className='adminHeader'></div>
            <div className={`adminBody ${sidebarCollapse ? '' : 'sidebar-collapse'} `}>
                {!props.auth.isAuthenticated && <Redirect to='/' />}

                <div className='sidebar'>
                    <div className='sidebar-head'>
                        <h3>
                            {props.auth && props.auth.user && props.auth.user.firstName
                                ? props.auth.user.firstName
                                : ''}{' '}
                            {props.auth && props.auth.user && props.auth.user.lastName
                                ? props.auth.user.lastName
                                : 'Admin'}
                        </h3>
                        <button onClick={() => setSidebarCollapse(!sidebarCollapse)}>
                            <i
                                className={`fa  ${
                                    sidebarCollapse ? 'fa-chevron-left' : 'fa-chevron-right'
                                } `}
                            />
                        </button>
                    </div>
                    <ul>
                        <Link to='/admin/dashboard' data-tooltip='Dasboard'>
                            <li className={activeMenu === 1 ? 'active' : ''}>
                                <span></span> <i className='fe fe-home' />
                                <span className='link-title'>Dashboard</span>
                            </li>
                        </Link>
                        <Link to='/admin/admins' data-tooltip='Admin Users'>
                            <li className={activeMenu === 2 ? 'active' : ''}>
                                <span></span> <i className='fe fe-user' />
                                <span className='link-title'>Admin Users</span>
                            </li>
                        </Link>
                        <Link to='/admin/customers' data-tooltip='Customer users'>
                            <li className={activeMenu === 3 ? 'active' : ''}>
                                <span></span> <i className='fe fe-user' />
                                <span className='link-title'>Customer Users</span>
                            </li>
                        </Link>
                        <Link to='/admin/hospitals' data-tooltip='Hospitals'>
                            <li className={activeMenu === 4 ? 'active' : ''}>
                                <span></span> <i className='fe fe-user' />
                                <span className='link-title'>Hospitals</span>
                            </li>
                        </Link>
                        <Link to='/admin/blogs' data-tooltip='Blogs'>
                            <li className={activeMenu === 5 ? 'active' : ''}>
                                <span></span> <i className='fe fe-book ' />
                                <span className='link-title'>Blogs</span>
                            </li>
                        </Link>
                        <Link to='/admin/emailTemplates' data-tooltip='Blogs'>
                            <li className={activeMenu === 6 ? 'active' : ''}>
                                <span></span> <i className='fe fe-book ' />
                                <span className='link-title'>Email Templates</span>
                            </li>
                        </Link>
                        {/* <Link to="/admin/portfolios" data-tooltip="Portfolios">
              <li className={activeMenu === 6 ? "active" : ""}>
                <span></span> <i className="fe fe-image " />
                <span className="link-title">Portfolios</span>
              </li>
            </Link> */}
                        <Link to='/admin/stripe' data-tooltip='Portfolios'>
                            <li className={activeMenu === 7 ? 'active' : ''}>
                                <span></span> <i className='fe fe-dollar-sign ' />
                                <span className='link-title'>Stripe</span>
                            </li>
                        </Link>
                        <Link to='/admin/mail-listing' data-tooltip='Mail Listings'>
                            <li className={activeMenu === 8 ? 'active' : ''}>
                                <span></span> <i className='fe fe-dollar-sign ' />
                                <span className='link-title'>Mail Listings</span>
                            </li>
                        </Link>
                        <Link
                            to='#'
                            onClick={(e) => props.adminActions.logout(props, props.history)}
                            data-tooltip='Logout'
                        >
                            <li>
                                <span></span> <i className='fe fe-log-out' />
                                <span className='link-title'>Logout</span>
                            </li>
                        </Link>
                    </ul>
                </div>
                <div className='main'>
                    <>
                        <Switch>
                            <Route exact path='/admin/' component={Home} />
                            <Route exact path='/admin/dashboard' component={Home} />
                            <Route exact path='/admin/admins' component={AdminUsers} />
                            <Route
                                exact
                                path='/admin/admins/:adminId/edit'
                                component={EditAdminProfile}
                            />
                            <Route exact path='/admin/customers' component={Customers} />
                            <Route
                                exact
                                path='/admin/customers/:customerId/edit'
                                component={EditCustomerProfile}
                            />
                            <Route exact path='/admin/hospitals' component={HospitalUsers} />
                            <Route
                                exact
                                path='/admin/hospitals/:lawfirmId/edit'
                                component={EditBusinessProfile}
                            />
                            <Route exact path='/admin/blogs' component={Blogs} />
                            <Route exact path='/admin/blogs/create' component={CreatePost} />
                            <Route exact path='/admin/blogs/:postId/edit' component={EditBlog} />
                            <Route exact path='/admin/stripe' component={StripeDash} />
                            <Route exact path='/admin/stripe/products' component={Products} />
                            <Route
                                exact
                                path='/admin/stripe/customers'
                                component={StripeCustomers}
                            />
                            <Route
                                exact
                                path='/admin/stripe/subscriptions'
                                component={StripeSubscriptions}
                            />
                            <Route exact path='/admin/emailTemplates' component={EmailTemplates} />
                            <Route
                                exact
                                path='/admin/emailTemplates/create'
                                component={CreateTemplate}
                            />
                            <Route
                                exact
                                path='/admin/emailTemplates/edit/:id'
                                component={CreateTemplate}
                            />
                            <Route exact path='/admin/mail-listing' component={MailListingCounts} />
                            <Route
                                exact
                                path='/admin/mail-listing/feedback'
                                component={FeedbackMail}
                            />
                            <Route
                                exact
                                path='/admin/mail-listing/support'
                                component={SupportMail}
                            />
                            <Route
                                exact
                                path='/admin/mail-listing/general'
                                component={GeneralMail}
                            />
                            <Route exact path='/admin/mail-listing/sales' component={SalesMail} />
                            <Route
                                exact
                                path='/admin/mail-listing/complaints'
                                component={ComplaintsMail}
                            />
                        </Switch>
                    </>
                    {/* <AdminRoutes {...props} /> */}
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        adminActions: bindActionCreators(adminActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
