import { Col, Row, Select, DatePicker } from 'antd';
import React from 'react';

const { Option } = Select;

const sortby = ['Highest Rated', 'Newest Rated', 'Newest', 'Oldest'];
const sortOrder = ['Ascending', 'Descending'];
const modes = ['Online', 'In-Person'];

const PaymentHeader = () => {
    return (
        <Row gutter={[10, 10]} style={{marginBottom: 20}}>
            <Col xs={24} lg={6}>
                <Select placeholder='Modes' showSearch style={{ width: '100%' }}>
                    {modes.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} lg={6}>
                <Select placeholder='Sort By' showSearch style={{ width: '100%' }}>
                    {sortby.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} lg={6}>
                {' '}
                <Select placeholder='Sort Order' showSearch style={{ width: '100%' }}>
                    {sortOrder.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} lg={6}>
                <DatePicker
                    name='dob'
                    placeholder='Search by date'
                    style={{width: '100%'}}
                />
            </Col>
        </Row>
    );
};

export default PaymentHeader;
