import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import * as appointmentActions from '@/actions/appointmentActions';

function CompleteAppointmentConfirmation(props) {
    const {
        appointment,
        onClose,
        acceptMethod,
        completeAppointmentModal,
        getAppointments,
        userType,
    } = props;

    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { userId } = useSelector((state) => state.auth);

    const completeAppointment = async () => {
        let obj = {
            userType,
            userId,
            appointmentId: appointment?._id
        };
        let complete = await dispatch(appointmentActions.completeAppointment({ ...props, obj }));

        if (complete) {
            getAppointments();
        }
    };

    return (
        (<Modal
            className='customModal'
            title={'Mark Appointment as Complete'}
            open={completeAppointmentModal}
            onOk={() => acceptMethod()}
            onCancel={() => onClose()}
            footer={null}
        >
            {appointment && (
                <>
                    {userType == "DOCTOR" ? (
                        <p>
                            Meeting with Client{' '}
                            {`${appointment?.user?.firstName}${' '}${appointment?.user?.lastName}`}{' '}
                            on{' '}
                            {`${moment(appointment?.appointmentDate).format('DD/MM/YYYY')} at ${
                                appointment?.appointmentStartTime
                            } - ${moment(appointment?.appointmentEndTime, 'HH:mm').format(
                                'HH:mm a'
                            )}`}{' '}
                            has Ended
                        </p>
                    ) : (
                        userType == "PATIENT" && (
                            <p>
                                Meeting with Doctor{' '}
                                {`${appointment?.doctor?.firstName}${' '}${
                                    appointment?.doctor?.lastName
                                }`}{' '}
                                has Ended
                            </p>
                        )
                    )}

                    {((userType == "DOCTOR" &&
                        appointment?.appointmentStatusOfDoctor != 'COMPLETED' &&
                        appointment?.appointmentStatusOfDoctor != 'CANCELLED') ||
                        (userType == "PATIENT" &&
                            appointment?.appointmentStatusOfClient != 'COMPLETED' &&
                            appointment?.appointmentStatusOfClient != 'CANCELLED')) && (
                        <button
                            style={{ marginTop: 20 }}
                            className='ant-btn ant-btn-primary'
                            onClick={() => completeAppointment()}
                        >
                            Mark meeting as complete?
                        </button>
                    )}
                </>
            )}
        </Modal>)
    );
}

export default CompleteAppointmentConfirmation;
