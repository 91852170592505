import React, { useState, useEffect } from 'react';
import BlogSearch from './blogSearch2';
import '../blogs.css';
import './blogSearchPage.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/userActions';
import * as blogActions from '../../actions/blogActions';
import PopularBlogs from '../publicBlogs/popularBlogs';
import { Empty, Pagination } from 'antd';
import { useDispatch } from 'react-redux';

const BlogSearchPage = (props) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(8);
    const dispatch = useDispatch();
    const { searchResults, searchResultsCount } = props;

    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            dispatch({
                type: 'CLEAR_SEARCH_POSTS'
            });
        };
    }, []);
    return (
        <div className='main-blog-container'>
            <div className='blogSearch-main'>
                <div className='blogSearch-heading'></div>
                <BlogSearch
                    type='blog'
                    {...props}
                    limit={limit}
                    page={page}
                    setLimit={setLimit}
                    setPage={setPage}
                />

                <div className='popularBlogs-main'>
                    {searchResults && searchResults.length ? (
                        <div className='pop-blogs-grid'>
                            {searchResults.map((item, index) => {
                                return (
                                    <PopularBlogs
                                        {...props}
                                        blog={item}
                                        key={index}
                                        background={'white'}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <Empty />
                    )}
                    {/* <button onClick={()=>props.history.push(`/blogs/all`)}>Show all</button> */}
                </div>
            </div>

            <div className='blog-pagi'>
                <Pagination
                    current={page}
                    total={searchResultsCount}
                    onChange={(val) => setPage(val)}
                    pageSize={limit}
                    pageSizeOptions={[5, 10, 20]}
                    showSizeChanger={true}
                    responsive={true}
                    onShowSizeChange={(size, newSize) => setLimit(newSize)}
                />
            </div>
        </div>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        searchResults: state.blog.searchResults,
        searchResultsCount: state.blog.searchResultsCount,
        popularBlogs: state.blog.popularPosts,
        recentBlogs: state.blog.recentPosts,
        blogs: state.blog
    };
}
function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch),
        blogActions: bindActionCreators(blogActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(BlogSearchPage);
