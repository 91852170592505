export const getLocationShorts = () => {
    let chosenCountry = localStorage.getItem('chosenCountry');
    let chosenLocality = localStorage.getItem('chosenLocality');
    let chosenState = localStorage.getItem('chosenState');
    let locality = localStorage.getItem('locality');
    let localityCountry = localStorage.getItem('localityCountry');
    let localityState = localStorage.getItem('localityState');

    let coordinates, country, city, state;

    if (chosenCountry) {
        country = chosenCountry;
        city = chosenLocality;
        coordinates = [
            parseFloat(localStorage.getItem('chosenLng')),
            parseFloat(localStorage.getItem('chosenLat'))
        ];
        state = chosenState;
    } else {
        country = localityCountry;
        city = locality;
        coordinates = [
            parseFloat(localStorage.getItem('lng')),
            parseFloat(localStorage.getItem('lat'))
        ];
        state = localityState;
    }

    return {
        coordinates,
        country,
        city,
        state
    };
};
