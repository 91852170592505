import React, { memo, useState, useEffect } from 'react';
import { Select } from 'antd';
import Currencies from '@/assets/json/Currencies.json';

const uniqueCurrencies = [...new Set(Currencies.map((currency) => currency.currency.code))].filter(
    Boolean
);

const CurrencyDropdown = ({ setState, currency, required = false }) => {

    return (
        <label
            className={`cur-label ${!currency.isValid ? 'error' : ''}`}
            tabIndex={0}
        >
            <div>Currency{required && <span style={{ color: 'red' }}>*</span>}:</div>

            <Select
                showSearch
                value={currency.value}
                onChange={(val) =>
                    setState({
                        currency: {
                            ...currency,
                            value: val,
                            isValid: true,
                            message: ''
                        },
                        formDirty: true
                    })
                }
            >
                {uniqueCurrencies.map((item) => {
                    return <Select.Option value={item}>{item}</Select.Option>;
                })}
            </Select>
            {currency.message && (
                <p>
                    {' '}
                    <i className='fe fe-alert-triangle' /> {currency.message}
                </p>
            )}
        </label>
    );
};

export default CurrencyDropdown;
