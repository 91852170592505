import React, { useState, useRef, useEffect } from 'react';
import GoogleMapReact from 'google-map-react';
import Geolocate from '../../MiniComponents/Geolocate';
import { GetLocationUsingLatLng } from '../GetLocationUsingLatLng';
import { Radio } from 'antd';
var countries = require('country-data').countries;

function LocationFinderDropdown(props) {
    const {
        geocodeByAddress,
        mapDropdown,
        setMapDropdown,
        setLocationTray,
        locationTray,
        refreshData
    } = props;
    const Marker = ({ text }) => (
        <div
            className='marker'
            style={{
                color: 'white',
                padding: '15px 10px',
                display: 'inline-flex',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: '100%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <i
                className='fe fe-map-pin'
                style={{
                    fontSize: '30px',
                    color: 'var(--primary)',
                    position: 'relative'
                }}
            ></i>
        </div>
    );
    const [tempLocation, setTempLocation] = useState({
        address: '',
        city: '',
        cityShort: '',
        stateShort: '',
        state: '',
        countryShort: '',
        country: '',
        zip: ''
    });
    const [locality, setLocality] = useState('');
    const [localityCountry, setLocalityCountry] = useState('');
    const [localityState, setLocalityState] = useState('');
    const [adjustPin, setAdjustPin] = useState(false);
    // const [mapDropdown, setMapDropdown] = useState(false);
    const [zoom, setZoom] = useState(15);
    const geo = useRef(false);
    const [value, setValue] = useState(1);
    const [center, setCenter] = useState({
        lat: parseFloat(localStorage.getItem('lat')) || '',
        lng: parseFloat(localStorage.getItem('lng')) || ''
    });
    const [markerCenter, setMarkerCenter] = useState();
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const saveLocation = () => {
        // localStorage.setItem("chosenAddress", chosenAddress);

        localStorage.setItem('chosenLat', markerCenter.lat);
        localStorage.setItem('chosenLng', markerCenter.lng);

        console.log(tempLocation);

        if (tempLocation.countryShort) {
            localStorage.setItem('currency', countries[tempLocation.countryShort].currencies[0]);
            localStorage.setItem(
                'calling_code',
                countries[tempLocation.countryShort].countryCallingCodes[0]
            );
        }

        if (tempLocation.zip) {
            localStorage.setItem('chosenZip', tempLocation.zip);
        }
        localStorage.setItem('chosenAddress', tempLocation.address);
        localStorage.setItem('chosenLocality', tempLocation.city);
        localStorage.setItem('chosenState', tempLocation.stateShort);
        localStorage.setItem('chosenCountry', tempLocation.country);
        localStorage.setItem('chosenCountryShort', tempLocation.countryShort);
        setLocationTray(1);
        if (refreshData) {
            refreshData();
        }
        setMapDropdown(false);
    };

    const chooseAddress = async (addr) => {
        console.log(addr);
        let tempLoc = {};
        var componentForm = {
            street_number: '',
            route: '',
            postal_town: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            formattedAddress: '',
            neighbourhood: ''
        };
        let address = await geocodeByAddress(addr);
        if (address) console.log(address);
        address = address[0];
        // componentForm.formattedAddress = address.formatted_address;
        // for (var i = 0; i < address.address_components.length; i++) {
        //   var addressType = address.address_components[i].types[0];
        //   console.log(addressType);
        //   if (componentForm[addressType] === "") {
        //     let val = address.address_components[i][componentForm[addressType]];
        //     componentForm[addressType] = address.address_components[i].long_name;
        //   }
        // }
        // console.log(componentForm);

        // if (componentForm.country !=== "") {
        //   tempLoc.country = componentForm.country;
        // }

        // // debugger

        // tempLoc.locality =
        //   componentForm.postal_town != "Town" && componentForm.postal_town
        //     ? componentForm.postal_town
        //     : componentForm.locality != "locality"
        //     ? componentForm.locality
        //     : "";

        // if (componentForm.street_number && componentForm.route) {
        //   tempLoc.address = componentForm.route;
        // } else if (componentForm.route) {
        //   tempLoc.address = componentForm.route;
        // } else if (componentForm.formattedAddress) {
        //   tempLoc.address = componentForm.formattedAddress;
        // }

        // setTempLocation(tempLoc);
        let loc = await GetLocationUsingLatLng({
            lng: address.geometry.location.lng(),
            lat: address.geometry.location.lat()
        });
        setTempLocation(loc);
        if (address && address.geometry) {
            setCenter({
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng()
            });

            setMarkerCenter({
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng()
            });
        }

        setLocationTray(2);
    };
    const handleApiLoaded = (map, maps) => {
        // use map and maps objects
        map.panTo(markerCenter);
    };

    useEffect(() => {
        if (localStorage.getItem('lat') && localStorage.getItem('lng')) {
            setCenter({
                lat: parseFloat(localStorage.getItem('lat')),
                lng: parseFloat(localStorage.getItem('lng'))
            });
        }
        let localityInfo = localStorage.getItem('locality');
        let localityCountryInfo = localStorage.getItem('localityCountry');
        let localityState = localStorage.getItem('localityState');
        if (localityInfo) {
            setLocality(localityInfo);
        }
        if (localityState) {
            setLocalityState(localityState);
        }
        if (localityCountryInfo) {
            setLocalityCountry(localityCountryInfo);
        }
    }, []);
  

    let locationString = '';
    if (localStorage.getItem('chosenCountry')) {
        locationString += (localStorage.getItem('chosenLocality') ? `${localStorage.getItem('chosenLocality')} ,`  : '') + localStorage.getItem('chosenState');
    } else {
        locationString += locality + ',' + localityState;
    }

    let locationCountry = '';
    if (localStorage.getItem('chosenCountry')) {
        locationCountry += localStorage.getItem('chosenCountry');
    } else {
        locationCountry += localityCountry;
    }

    return (
        <div className='mapDropdown' tabIndex={0}>
            {locationTray === 1 && (
                <>
                    <Geolocate
                        ref={geo}
                        Autocomplete={false}
                        style={{ color: 'var(--primary)' }}
                        chooseAddress={(address) => chooseAddress(address)}
                        icon={
                            <i
                                className='fe fe-map-pin input-marker'
                                style={{
                                    fontSize: '20px',
                                    color: 'var(--primary)'
                                }}
                            ></i>
                        }
                        placeholder='Address'
                    />
                    <div className='grayDivider'></div>
                    <div className='default-location'>
                        <Radio
                            checked={true}
                            name='default-location-radio'
                            inputProps={{ 'aria-label': 'A' }}
                        />
                        <div>
                            <span>{locationString}</span>
                            <span>
                                {locationCountry}
                            </span>
                        </div>
                    </div>
                </>
            )}
            {locationTray === 2 && (
                <>
                    <div className='locationTray2'>
                        <GoogleMapReact
                            style={{
                                display: 'flex',
                                height: adjustPin === true ? 360 : 160,
                                width: '100%',
                                position: 'relative',
                                left: 0,
                                right: 0,
                                margin: '0px 0px 10px 0px'
                            }}
                            bootstrapURLKeys={{
                                key: 'AIzaSyBgxSzayC4ePVoGH_DKLBSRzPtszez1G2w'
                            }}
                            // defaultCenter={center}
                            defaultZoom={zoom}
                            center={center}
                            onDrag={(e) => {
                                if (adjustPin) {
                                    setMarkerCenter({
                                        lat: e.center.lat(),
                                        lng: e.center.lng()
                                    });
                                }
                            }}
                            onClick={(e) => {
                                // setMarkerCenter({
                                //   lat: e.lat,
                                //   lng: e.lng
                                // })
                            }}
                            yesIWantToUseGoogleMapApiInternals
                            onGoogleApiLoaded={({ map, maps }) => handleApiLoaded(map, maps)}
                        >
                            <Marker
                                lat={
                                    markerCenter && markerCenter.lat
                                        ? markerCenter.lat
                                        : localStorage.getItem('lat')
                                        ? localStorage.getItem('lat')
                                        : ''
                                }
                                lng={
                                    markerCenter && markerCenter.lng
                                        ? markerCenter.lng
                                        : localStorage.getItem('lng')
                                        ? localStorage.getItem('lng')
                                        : ''
                                }
                                text='My Marker'
                            />
                        </GoogleMapReact>

                        <div className='adjustPinBtn-container'>
                            <p>{tempLocation.address && tempLocation.address}</p>
                            <button className='grayBtn' onClick={() => setAdjustPin(true)}>
                                Adjust Pin
                            </button>
                        </div>
                        <div className='graydivider'></div>

                        <div className='mapDropActionBtns'>
                            <button
                                className='grayBtn'
                                onClick={() => {
                                    setLocationTray(1);
                                    setAdjustPin(false);
                                    setMapDropdown(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button className='pinkBtn' onClick={(e) => saveLocation(e)}>
                                Save
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

export default LocationFinderDropdown;
