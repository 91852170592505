import React, { useState, useEffect } from 'react';
import { Button, Modal, message, Form, Input, Radio } from 'antd';
import OtpInput from 'react-otp-input';
import * as userActions from '@/actions/userActions';
import { useDispatch, useSelector } from 'react-redux';
import PhoneInput from 'react-phone-input-2';
import { ArrowLeftOutlined, PhoneOutlined } from '@ant-design/icons';
import '../Documents/modal/documentLockModal.css';

const VerifyEmailOrPhoneModal = (props) => {
    const { open, onCancel, email, phoneNo, countryCode, branchId, channelProp, getLocations } =
        props;
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const { isLocked } = props;
    const { userType, user } = useSelector((state) => state.auth);

    const [disabled, setDisabled] = useState({
        phoneNo: false,
        email: false
    });

    let CHANNELS = [
        { label: 'Email', value: 'email', disabled: channelProp == 'sms' && true },
        { label: 'sms', value: 'sms', disabled: channelProp == 'email' && true }
    ];

    useEffect(() => {
        if (!phoneNo && email) {
            setChannel('email');
        }
        if (!phoneNo) {
            setDisabled({ ...disabled, phoneNo: true });
        } else {
            form.setFieldsValue({ phoneNo: phoneNo.toString() });
        }
        if (!email) {
            setDisabled({ ...disabled, email: true });
        } else {
            form.setFieldsValue({ email: email });
        }
    }, [phoneNo, email]);

    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState({
        value: '',
        message: '',
        isValid: true
    });
    const [channel, setChannel] = useState('sms');

    useEffect(() => {
        if (channelProp == 'sms') {
            setChannel('sms');
        }
    }, [channelProp]);

    const handleChannelChange = (e) => {
        setChannel(e.target.value);
    };

    const [otpInputScreen, setOtpInputScreen] = useState(false);

    const requestOTP = async (e) => {
        setLoading(true);
        e.preventDefault();

        if (channel == 'sms' && !countryCode) {
            message.error('Select country code!');
            return;
        }

        form.validateFields()
            .then(async ({ phoneNo }) => {
                console.log('got in', countryCode);
                let obj = {
                    phoneNo: phoneNo,
                    countryCode: countryCode,
                    userType: userType,
                    channel: channel,
                    email,
                    branchId
                };
                let response = await dispatch(userActions.getEmailOrPhoneOtp(obj, props.history));
                if (response) {
                    setOtpInputScreen(true);
                } else {
                    message.error(<p> Could not request OTP!!" &nbsp; &#9749;</p>);
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.log('err', err, form.getFieldValue('phoneNo'));
                setOtpInputScreen(false);
                setOtp({ value: '', isValid: true, message: '' });
                setLoading(false);
            });
    };

    const verifyOTP = async (e) => {
        e.preventDefault();
        setLoading(true);
        const { phoneNo, email } = form.getFieldsValue(['phoneNo', 'email']);
        let obj = {
            phoneNo,
            countryCode: countryCode,
            code: otp.value,
            channel: channel,
            userType: userType,
            email,
            branchId
        };
        let response = await dispatch(userActions.verifyEmailOrPhoneOtp(obj, props.history));
        console.log('dispatch response', response);

        if (response) {
            console.log('success response', response);
            getLocations();
            onCancel();
        } else {
            console.log('got in else', response);
            if (response.type == 'not_found') {
                message.error('Try again');
                setOtpInputScreen(false);
            } else if (response?.type == 'invalid') {
                message.error('Invalid OTP');
            } else if (response?.type == 'expired') {
                message.error('OTP expired');
                setOtpInputScreen(false);
            }
            setLoading(false);
        }
    };
    const handleOtpChange = (otpdata) => {
        console.log(otpdata);
        setOtp({ ...otp, value: otpdata });
    };
    return (
        <Modal
            open={open}
            onCancel={onCancel}
            className='document-lock-modal verify-otp-modal'
            centered
            footer={null}
            style={{
                width: '100%',
                maxWidth: 400,
                padding: 20,
                borderRadius: 10
            }}
        >
            <h3 style={{ marginBottom: 20 }}>
                Verify{' '}
                {channel == 'email'
                    ? channel.slice(0, 1).toUpperCase() + channel.slice(1).toLowerCase()
                    : channel == 'sms'
                    ? 'Phone Number'
                    : 'Phone Number'}
            </h3>

            {channel == 'email' ? (
                <h3
                    style={{
                        textAlign: 'left',
                        marginTop: 15,
                        marginBottom: 10,
                        fontWeight: '400'
                    }}
                >
                    Request OTP
                </h3>
            ) : !otpInputScreen && channel !== 'email' ? (
                <h3
                    style={{
                        textAlign: 'left',
                        marginTop: 15,
                        marginBottom: 10,
                        fontWeight: '400'
                    }}
                >
                    Request OTP
                </h3>
            ) : (
                channel !== 'email' && (
                    <h3
                        onClick={() => {
                            setOtpInputScreen(false);
                            setOtp({ value: '', isValid: true, message: '' });
                        }}
                        style={{
                            textAlign: 'left',
                            marginTop: 15,
                            marginBottom: 10,
                            fontWeight: '400',
                            cursor: 'pointer'
                        }}
                    >
                        <ArrowLeftOutlined style={{ fontSize: '16px', marginRight: '10px' }} />
                        Go Back
                    </h3>
                )
            )}
            <Form form={form}>
                {channel === 'email' && !otpInputScreen ? (
                    <Form.Item
                        name='email'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your email address!'
                            }
                        ]}
                    >
                        <Input placeholder='Email' />
                    </Form.Item>
                ) : !otpInputScreen && channel !== 'email' ? (
                    <Form form={form}>
                        <div className='field'>
                            <PhoneInput
                                country={'ca'}
                                value={countryCode}
                                placeholder='Country Code'
                                disabled
                                // onChange={(phone) =>
                                //     setCountryCode({ ...countryCode, value: phone })
                                // }
                            />
                        </div>
                        <Form.Item
                            name='phoneNo'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your phone number!'
                                },
                                {
                                    min: 10,
                                    message: 'Phone number must be 10 digits'
                                }
                            ]}
                        >
                            <Input
                                prefix={<PhoneOutlined className='site-form-item-icon' />}
                                placeholder='Phone number'
                            />
                        </Form.Item>
                    </Form>
                ) : (
                    <div className='field'>
                        <OtpInput
                            className='otp-input'
                            value={otp.value}
                            onChange={handleOtpChange}
                            numInputs={6}
                            separator={false}
                            style={{ color: '#000' }}
                            containerStyle={{ justifyContent: 'center' }}
                        />
                    </div>
                )}
            </Form>
            <div>
                <Button
                    type='primary'
                    onClick={(e) => {
                        otpInputScreen ? verifyOTP(e) : requestOTP(e);
                    }}
                >
                    {!otpInputScreen ? 'Request OTP' : 'Verify OTP'}
                </Button>
            </div>
        </Modal>
    );
};

export default VerifyEmailOrPhoneModal;
