import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as doctorActions from '@/actions/doctorActions';
import Appointments from './Appointments';
import '@/assets/css/dashboard.css';
import Availability from './Availability';
import Notifications from './Notifications';
import StripeDashboard from './Stripe';
import AppointmentsToday from './AppointmentsToday';
import DataVisualization from '../../HospitalProfile/DataVisualization';

const Dashboard = () => {
    const dispatch = useDispatch();
    const { userType } = useSelector((state) => state.auth);

    useEffect(() => {
        if (!userType) return;
        dispatch(doctorActions.getDoctorDashboardInfo(userType));
    }, [userType]);

    return (
        <Row gutter={[20, 20]}>
            <Col xs={24} md={17} style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                <Appointments />
                {/* <StripeDashboard /> */}
                <DataVisualization />
            </Col>
            <Col xs={24} md={7} style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                <Availability />
                <AppointmentsToday />
                <Notifications />
            </Col>
        </Row>
    );
};

export default Dashboard;
