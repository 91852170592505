import React, { useEffect, useState } from 'react';
import { Button, DatePicker, Input, Modal } from 'antd';
import moment from 'moment';

const HonorModal = (props) => {
    const { data, honorModal, setHonorModal, setEditHonor, editHonor, branch } = props;
    const [organization, setOrganization] = useState();
    const [date, setDate] = useState('');
    const dateFormat = 'YYYY/MM/DD';
    useEffect(() => {
        if (editHonor) {
            setOrganization(editHonor.organization);
            setDate(editHonor.date);
        }
    }, [editHonor]);

    const handleSave = async () => {
        let profileObj = {
            data: {
                honors: [...data]
            },
            userType: props.auth.user.userType,
            userId: props.auth.user._id
        };
        if (editHonor) {
            profileObj.data.honors[editHonor.index] = {
                organization: organization,
                date: date,
                verificationStatus: 'REQUIRED'
            };
        } else {
            profileObj.data.honors = [
                ...data,
                {
                    organization: organization,
                    date: date,
                    verificationStatus: 'REQUIRED'
                }
            ];
        }
        if (profileObj.data.honors.length === 1) {
            profileObj.data.honors[0].current = true;
        }
        if (branch) {
            let profileObj2 = {
                userType: 'PHARMACY',
                pharmacy: branch.pharmacy,
                branchId: branch._id,
                minFieldsCompleted: true,
                pharmacist: {
                    ...branch.pharmacist
                }
            };
            if (profileObj?.data?.honors) {
                profileObj2.pharmacist.honors = [...profileObj.data.honors];
            }
            console.log('got here');
            let saved = await props.actions.saveBranches(profileObj2, props.history);
            if (saved) {
                let obj = {
                    userId: props.auth.userId,
                    populateAvailability: true,
                    userType: props.auth.userType
                };
                await props.branchActions.getBranches({ ...props, obj }, props.history);
                setEditHonor(null);
                setHonorModal(false);
            }
            return;
        }
        let saved = await props.actions.saveProfile(profileObj, props.history);
        if (saved) {
            let profileShort = {
                userId: props.auth.user._id,
                userType: props.auth.user.userType
            };
            await props.actions.getProfile(profileShort, props.history);
            setEditHonor(null);
            setHonorModal(false);
        }
    };

    useEffect(() => {}, []);
    return (
        <Modal
            className='customModal '
            title={`${editHonor ? 'Edit' : 'Create'} Honor`}
            open={honorModal}
            onCancel={() => {
                setHonorModal(false);
            }}
            footer={null}
        >
            <>
                <div className='post-modal-options-grid2'>
                    <label>
                        <p>Organization name:</p>
                        <Input
                            placeholder='Organization Name'
                            value={organization}
                            onChange={(e) => setOrganization(e.target.value)}
                        />
                    </label>
                </div>
                <div className='post-modal-options-grid2'>
                    <label>
                        <p>Date:</p>
                        <DatePicker
                            placeholder='Choose Date'
                            value={date ? moment(date, dateFormat) : ''}
                            onChange={(date, string) => setDate(string)}
                        />
                    </label>
                </div>
                <Button onClick={() => handleSave()} type='primary'>
                    <p>{editHonor ? 'Update' : 'Add'}</p>
                </Button>
            </>
        </Modal>
    );
};

export default HonorModal;
