import React from 'react';
import placeholderCover from '@/assets/img/placeholderCover.png';
import './outputscreen.css';


const OutputScreen = ({ inputs, contentType = 'text' }) => {
    return (
        <div
            className='output-main'
            style={{
                marginBottom: 30,
                backgroundColor: inputs['backgroundColor']
            }}
        >
            {contentType.toLowerCase() === 'text' ? (
                <>
                    <div
                        className='common output-line'
                        style={{
                            ...(inputs?.['title']?.style || {}),
                            padding: '8px 10px',
                            borderBottom: '1px solid #ddd'
                        }}
                    >
                        {inputs['title']?.text || `No Title provided`}
                    </div>
                    {Object.keys(inputs)
                        .filter(
                            (item) =>
                                item !== 'image' && item !== 'title' && item !== 'backgroundColor'
                        )
                        .map((key) => (
                            <div
                                className='common output-line'
                                style={{ ...(inputs?.[key].style || {}), padding: '8px 10px' }}
                            >
                                {inputs[key].text || `No ${key} provided`}
                            </div>
                        ))}
                </>
            ) : (
                <>
                    {inputs.image && inputs.image instanceof File ? (
                        <img
                            src={URL.createObjectURL(inputs.image)}
                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                        />
                    ) : (
                        <img
                            src={inputs.image || placeholderCover}
                            style={{ objectFit: 'cover', width: '100%', height: '100%' }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default OutputScreen;
