import React, { useState, useEffect } from 'react';
import { Select } from 'antd';
import './searchbar.css';
import '@/assets/css/newSearchBar.css';
import LeftSide from './SearchBar/LeftSide';
import RightSide from './SearchBar/RightSide';
import * as generalActions from '@/actions/generalActions';
import { useSelector, useDispatch } from 'react-redux';
import { specializationAreas } from '@/assets/json/DoctorPracticeAreas';
import locationStateShorts from '../Utilities/locationStateShorts';
import { LocationFiller } from '../Utilities/LocationFiller';
import { getLocationShorts } from '../Utilities/LocationShorts';
import { useHistory } from 'react-router-dom';

const SearchBar = (props) => {
    const dispatch = useDispatch();
    const history = useHistory()
    const auth = useSelector((state) => state.auth);
    const { searchSuggestions } = useSelector((state) => state.general);
    const { doctors, hospitals } = searchSuggestions;
    const [language, setLanguage] = useState('');
    const [filterCont, setFilterCont] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [chosenSearchCat, setChosenSearchCat] = useState('Doctors');
    const searchCategories = ['Doctors', 'Hospitals', 'Nurses', 'Consultants', 'Pharmacies'];
    const [practiceAreas, setPracticeAreas] = useState([]);
    const [radius, setRadius] = useState(10000);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredPracticeAreas, setFilteredPracticeAreas] = useState([]);
    const [addressValue, setAddressValue] = useState('');
    const [filters, setFilters] = useState({
        coordinates: getLocationShorts().coordinates,
        city: getLocationShorts().city,
        country: getLocationShorts().country,
        language: '',
        practiceAreas: [],
        radius: 10000
    });
    const [citySearch, setCitySearch] = useState(locationStateShorts.city);
    const [coordinates, setCoordinates] = useState(locationStateShorts.coordinates);
    const [countrySearch, setCountrySearch] = useState(locationStateShorts.country);

    useEffect(() => {
        return () => {
            setLanguage('');
            setRadius(10000);
            setPracticeAreas([]);
        };
    }, []);

    useEffect(() => {
        if (searchQuery) {
            startSearch();
        } else {
            dispatch({
                type: 'GET_SEARCH_SUGGESTIONS',
                payload: {
                    hospitals: [],
                    doctors: [],
                    practiceAreas: []
                }
            });
        }
        return () => {
            dispatch({
                type: 'GET_SEARCH_SUGGESTIONS',
                payload: {
                    hospitals: [],
                    doctors: [],
                    practiceAreas: []
                }
            });
        };
    }, [searchQuery]);

    const chooseAddress = async (addr) => {
        await LocationFiller(addr, setCoordinates, setCitySearch, setCountrySearch);
    };

    useEffect(() => {
        if (filterPopUp) {
            setRadius(filters.radius);
            setAddressValue(`${filters.city}, ${filters.country}`);
            setCoordinates(filters.coordinates);
            setCitySearch(`${filters.city}`);
            setCountrySearch(`${filters.country}`);
            setPracticeAreas(filters.practiceAreas);
            setLanguage(filters.language);
        } else {
            clearInputs();
        }
    }, [filterCont]);

    const clearFilters = () => {
        let location = getLocationShorts();
        setFilterCont(false);
        setFilters({
            practiceAreas: [],
            coordinates: [location.coordinates[0], location.coordinates[1]],
            city: location.city,
            country: location.country,
            radius: 1000,
            language: language
        });
        clearInputs();
    };

    const applyFilters = () => {
        setFilters({
            practiceAreas: practiceAreas,
            coordinates: coordinates,
            city: citySearch,
            country: countrySearch,
            radius: radius,
            language: language
        });
        clearInputs();
    };

    const clearInputs = () => {
        let location = getLocationShorts();
        setRadius(10000);
        setPracticeAreas([]);
        setCitySearch(location.city);
        setCountrySearch(location.country);
        setCoordinates(location.coordinates);
        setAddressValue('');
        setLanguage('');
    };

    const goToSearchPage = async () => {
        if (searchQuery.length > 0) {
            let regex = new RegExp(`^${searchQuery}`, 'i');

            setFilteredPracticeAreas(specializationAreas.filter((item) => item.match(regex)));
        } else {
            setFilteredPracticeAreas([]);
        }

        let url = `/${
            chosenSearchCat == 'Doctors'
                ? 'doctor'
                : chosenSearchCat == 'Hospitals'
                ? 'hospital'
                : chosenSearchCat == 'Nurses'
                ? 'nurse'
                : chosenSearchCat == 'Consultants'
                ? 'consultant'
                : chosenSearchCat == 'Pharmacies'
                ? 'pharmacy'
                : 'doctor'
        }-results?`;

        if (filters.city && filters.country) {
            url += `&city=${filters.city}&country=${filters.country}`;
        }
        if (filters.coordinates) {
            url += `&lat=${filters.coordinates[1]}&lng=${filters.coordinates[0]}`;
        }
        if (searchQuery) {
            url += `&search=${searchQuery}`;
        }
        if (filters.radius) {
            url += `&radius=${filters.radius}`;
        }
        if (filters.practiceAreas) {
            url += `&practiceAreas=${filters.practiceAreas.map((val) => encodeURIComponent(val))}`;
        }
        if (filters.language) {
            url += `&language=${filters.language}`;
        }
        history.push(url);
    };

    const startSearch = async () => {
        if (searchQuery.length > 0) {
            let regex = new RegExp(`^${searchQuery}`, 'i');

            setFilteredPracticeAreas(specializationAreas.filter((item) => item.match(regex)));
        } else {
            setFilteredPracticeAreas([]);
        }
        let obj = {
            coordinates: coordinates,
            radius: radius,
            city: citySearch,
            country: countrySearch,
            practiceAreas: practiceAreas,
            search: searchQuery
        };
        dispatch(generalActions.getSearchSuggestions({ ...props, obj }, props.history));
    };

    const filterPopUp = async () => {
        if (!filterCont) {
            setFilterCont(true);
        } else {
            let filterPopUp = document.querySelector('.miniLabel .filterPopUp');
            if (filterPopUp) {
                filterPopUp.classList.add('exit');
                setFilteredPracticeAreas([]);
            }
            setTimeout(() => {
                setFilterCont(false);
                if (filterPopUp) {
                    filterPopUp.classList.remove('exit');
                }
            }, 250);
        }
    };

    const handlePracticeAreas = (e) => {
        if (!practiceAreas.includes(e.target.value) && e.target.checked) {
            setPracticeAreas([...practiceAreas, e.target.value]);
        } else if (!e.target.checked && practiceAreas.includes(e.target.value)) {
            setPracticeAreas((values) => values.filter((item) => item != e.target.value));
        }
    };

    return (
        <div className='miniLabel'>
            <Select value={chosenSearchCat} onChange={(val) => setChosenSearchCat(val)}>
                {searchCategories &&
                    searchCategories.map((item, index) => (
                        <Select.Option key={index} value={item}>
                            {item}
                        </Select.Option>
                    ))}
            </Select>
            <div className='searchBox'>
                <input
                    placeholder='Search for Doctors or Hospitals'
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                {searchQuery && (
                    <div className='suggestions'>
                        <div className='suggestionsCont'>
                            {hospitals && hospitals.length > 0 && (
                                <div>
                                    <p className='title'>Hospitals</p>
                                    <div className='suggestionItems'>
                                        {hospitals.map((item, index) => (
                                            <div
                                                className='suggestionItem'
                                                key={index}
                                                onClick={() =>
                                                    props.history.push(
                                                        `/hospital-view/${item._id}`
                                                    ) && setFilterCont(false)
                                                }
                                            >
                                                {item.profileImage ? (
                                                    <div className='avatar'>
                                                        {item.profileImage && (
                                                            <img src={item.profileImage} />
                                                        )}
                                                    </div>
                                                ) : (
                                                    <div className='placeholder'></div>
                                                )}
                                                <div className='name'>
                                                    <p>{item.hospitalName}</p>
                                                    <small>
                                                        {item.hospitalBranches &&
                                                            item.branch.location &&
                                                            item.branch.location.branchAddress &&
                                                            item.branch.location.branchAddress}
                                                    </small>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}

                            {doctors && doctors.length > 0 && (
                                <>
                                    <div>
                                        <p className='title'>Doctors</p>

                                        <div className='suggestionItems'>
                                            {doctors.map((item, index) => (
                                                <div
                                                    className='suggestionItem'
                                                    key={index}
                                                    onClick={() =>
                                                        props.history.push(
                                                            `/doctor-view/${item._id}`
                                                        ) && setFilterCont(false)
                                                    }
                                                >
                                                    {item.profileImage ? (
                                                        <div className='avatar'>
                                                            {item.profileImage && (
                                                                <img src={item.profileImage} />
                                                            )}
                                                        </div>
                                                    ) : (
                                                        <div className='placeholder'></div>
                                                    )}

                                                    <div className='name'>
                                                        <p>
                                                            {item.firstName && item.firstName}{' '}
                                                            {item.lastName && item.lastName}
                                                        </p>
                                                        <small>
                                                            {item.hospitalName && item.hospitalName},{' '}
                                                            {item.hospitalBranches &&
                                                                item.hospitalBranches.location &&
                                                                item.hospitalBranches.location
                                                                    .branchAddress &&
                                                                item.hospitalBranches.location
                                                                    .branchAddress}
                                                        </small>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </>
                            )}
                            {filteredPracticeAreas && filteredPracticeAreas.length > 0 && (
                                <div>
                                    <p className='title'>Practice Areas</p>
                                    <div className='suggestionItems'>
                                        {filteredPracticeAreas.slice(0, 2).map((item, index) => (
                                            <div className='suggestionItem'>
                                                <div className='placeholder' />
                                                <div className='name'>
                                                    <p>{item}</p>
                                                    <p>22 doctors, 10 hospitals</p>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
            <div className='divider' />
            <div className='filterBtn'>
                <i className='fa fa-sliders' onClick={filterPopUp} />
                {filterCont == true && (
                    <div className='filterPopUp'>
                        <div className='upPointerContainer'>
                            <div className='upPointer' />
                        </div>

                        <div className='buttonGroup'>
                            <button
                                className='applyFilterBtn'
                                onClick={() => {
                                    applyFilters();
                                    filterPopUp(true);
                                }}
                            >
                                Apply filters
                            </button>
                            <button className='applyFilterBtn' onClick={() => clearFilters()}>
                                Clear
                            </button>
                            <button className='applyFilterBtn' onClick={filterPopUp}>
                                Close
                            </button>
                        </div>

                        <div className='twoSides'>
                            <LeftSide
                                setChosenSearchCat={setChosenSearchCat}
                                chosenSearchCat={chosenSearchCat}
                                searchCategories={searchCategories}
                                language={language}
                                setLanguage={setLanguage}
                                practiceAreas={practiceAreas}
                                handlePracticeAreas={handlePracticeAreas}
                                setPracticeAreas={setPracticeAreas}
                                radius={radius}
                                setRadius={setRadius}
                                chooseAddress={chooseAddress}
                                filteredPracticeAreas={filteredPracticeAreas}
                                setFilteredPracticeAreas={setFilteredPracticeAreas}
                                searchTerm={searchTerm}
                                setSearchTerm={setSearchTerm}
                                citySearch={citySearch}
                                setCitySearch={setCitySearch}
                                coordinates={coordinates}
                                setCoordinates={setCoordinates}
                                countrySearch={countrySearch}
                                setCountrySearch={setCountrySearch}
                                startSearch={startSearch}
                                addressValue={addressValue}
                                setAddressValue={setAddressValue}
                            />

                            <RightSide
                                setChosenSearchCat={setChosenSearchCat}
                                chosenSearchCat={chosenSearchCat}
                                filters={filters}
                            />
                        </div>

                        {/* <button className="applyFilterBtn">Apply filters</button> */}
                    </div>
                )}
            </div>
            <div className='searchBtn' onClick={() => goToSearchPage()}>
                <i className='fa fa-search' />
            </div>
        </div>
    );
};

export default SearchBar;
