/* eslint eqeqeq: 0 */
import React, { useEffect, useState, useRef } from 'react';
import { socket } from '../../../MiniComponents/Socket';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as messagingActions from '@/actions/messagingActions';
import moment from 'moment';
import '@/assets/css/Messaging.css';
import { BiRefresh } from 'react-icons/bi';
import { Button, Space, message as toast } from 'antd';

const Chats = (props) => {
    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState('');
    const [count, setCount] = useState(0);
    const [limit, setLimit] = useState(10);
    const [chatList, setChatList] = useState([]);
    const [conversationId, setConversationId] = useState('');
    const [activeConversation, setActiveConversation] = useState(null);
    const [requestModal, setRequestModal] = useState(false);
    const messagesEndRef = useRef(null);
    const messagesStartRef = useRef(null);
    const [activeChatUser, setActiveChatUser] = useState(null);
    const { messaging, messagingActions, auth } = props;
    const { userId, userType } = auth;

    useEffect(() => {
        messagingActions.getChatList(props, props.history);
    }, []);

    useEffect(() => {
        let activeConvo = activeConversation;
        let otherUsers = activeConvo?.users?.map((item) => item.user);
        let receiver = otherUsers && otherUsers.filter((item) => item._id != userId)[0];
        setActiveChatUser(receiver);
    }, [activeConversation]);

    useEffect(() => {
        if (messaging && messaging.chatList) {
            setChatList(messaging.chatList);
            setConversationId(messaging?.chatList[0]?._id);
            setActiveConversation(messaging?.chatList[0]);

            //getting the active conversation user
            let activeConvo = messaging?.chatList[0];
            let otherUsers =
                activeConvo?.users &&
                activeConvo?.users.length > 0 &&
                activeConvo.users.map((item) => item.user);
            let receiver = otherUsers && otherUsers.filter((item) => item._id != userId)[0];

            //setting the active conversation user
            setActiveChatUser(receiver);

            if (messaging?.chatList[0]?.request == 'PENDING') {
                setRequestModal(true);
            }
        }
    }, [messaging]);

    const changePage = async () => {
        socket.emit('getMessages', {
            conversationId: conversationId,
            limit: limit,
            maxDate: messages[0].createdDate
        });
    };

    const setData = (data) => {
        if (data.messagesCount) {
            setCount(data.messagesCount);
        }

        setMessages((messages) => {
            const uniqueIds = new Set(messages.map((message) => message._id));
            const newMessages = data?.messages.filter((message) => !uniqueIds.has(message._id));
            return [...newMessages, ...messages].sort((a, b) =>
                a.createdDate.localeCompare(b.createdDate)
            );
        });
        scrollToTop();
    };

    useEffect(() => {
        socket.on('receiveMessage', (data) => {
            setCount(data.messagesCount);
            setMessages((messages) => [...messages, ...data?.message]);
            scrollToBottom();
        });
    }, []);

    useEffect(() => {
        if (conversationId) {
            socket.emit('join', { conversationId: conversationId });
            socket.emit('getMessages', {
                conversationId: conversationId,
                limit: limit
            });
            socket.on(conversationId, (data) => {
                setData(data);
            });
        }
        if (activeConversation && activeConversation._id) {
            socket.on(`requestAccepted-${activeConversation._id}`, (data) => {
                setActiveConversation(data);
            });

            socket.on(`requestBlocked-${activeConversation._id}`, (data) => {
                setActiveConversation(data);
            });
        }
    }, [conversationId]);

    const sendMessage = async () => {
        setMessage('');
        if (activeConversation.request == 'BLOCKED' && activeConversation.requestBy !== userId) {
            toast.error(`You are blocked by this user`);
            return;
        } else if (
            activeConversation.request == 'BLOCKED' &&
            activeConversation.requestBy == userId
        ) {
            toast.error(`You can't send a message to a user you blocked`);
            return;
        }

        let otherUsers = activeConversation?.users?.map((item) => item.user);
        let receiver = otherUsers.filter((item) => item._id != userId)[0];
        let obj = {
            message: message,
            sender: userId,
            senderType: props.auth.user.userType,
            receiver: receiver._id,
            receiverType: receiver.userType,
            conversation: conversationId
        };
        await messagingActions.sendMessage({ obj, ...props }, props.history);
    };

    const scrollToBottom = () => {
        if (messagesEndRef && messagesEndRef.current) {
            messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const scrollToTop = () => {
        if (messagesStartRef && messagesStartRef.current) {
            messagesStartRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const acceptRequest = () => {
        socket.emit('acceptRequest', {
            conversationId: activeConversation._id,
            request: 'ACCEPTED',
            userId: userId,
            userType: userType
        });
    };

    const blockRequest = () => {
        let obj = {
            conversationId: activeConversation._id,
            request: 'BLOCKED',
            requestBy: userId,
            requestByUserType: userType,
            userId: userId,
            userType: userType
        };
        socket.emit('blockRequest', obj);
    };

    const leaveConvo = (conversationId) => {
        socket.emit('leaveConversation', { conversationId: conversationId });
    };
    const { profile } = props;

    const getActiveChatUserName = (activeChatUser) => {
        if (!activeChatUser) {
            return 'No active conversation';
        }
        const { firstName, lastName, hospitalName } = activeChatUser;
        if (hospitalName) {
            if (firstName && lastName) {
                return `${firstName} ${lastName} (${hospitalName})`;
            }
            return hospitalName;
        }
        if (firstName && lastName) {
            return `${firstName} ${lastName}`;
        }
        return 'No active conversation';
    };
    return (
        <div className='chat-screen'>
            <div className='sideSection'>
                <div className='searchConvos'>
                    <div className='inputCont'>
                        <i className='fa fa-search' />
                        <input placeholder='Search Convos' />
                    </div>
                </div>
                <div className='conversations'>
                    <ul>
                        {chatList &&
                            chatList.map((item, index) => (
                                <li
                                    key={index}
                                    onClick={() => {
                                        if (item._id == conversationId) return;
                                        leaveConvo(activeConversation._id);
                                        setActiveConversation(item);
                                        setConversationId(item._id);
                                        setMessages([]);
                                    }}
                                >
                                    <div className='convo-item'>
                                        <div className='avatar'></div>
                                        <div className='convo-info'>
                                            <div className='convo-info-1'>
                                                <div className='convo-name'>
                                                    {item.users &&
                                                        item.users
                                                            .filter((it) => it.user._id != userId)
                                                            .map((otherUser, index) => (
                                                                <p key={index}>
                                                                    {otherUser?.user?.firstName}{' '}
                                                                    {otherUser?.user?.lastName}
                                                                </p>
                                                            ))}
                                                </div>
                                                <span>
                                                    {moment(item.messages.createdDate).format(
                                                        'hh:mm A'
                                                    )}
                                                </span>
                                            </div>
                                            <p>{item?.messages[0]?.message}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                    </ul>
                </div>
            </div>

            <div className='chatSection'>
                <div className='chatHeader'>
                    <div className='chatHeader-first'>
                        <div className='avatar'></div>
                        <div className='convo-info'>
                            <div className='convo-info-1'>
                                <Space className='convo-name'>
                                    {getActiveChatUserName(activeChatUser)}
                                    {activeConversation &&
                                        !(
                                            activeConversation.request === 'BLOCKED' &&
                                            activeConversation.requestBy !== userId
                                        ) && (
                                            <Button
                                                type='primary'
                                                size='small'
                                                onClick={() => {
                                                    if (
                                                        activeConversation.request === 'BLOCKED' &&
                                                        activeConversation.requestBy === userId
                                                    ) {
                                                        acceptRequest();
                                                    } else if (
                                                        activeConversation.request === 'ACCEPTED' ||
                                                        activeConversation.request === 'PENDING'
                                                    ) {
                                                        blockRequest();
                                                    }
                                                }}
                                            >
                                                {activeConversation.request === 'BLOCKED' &&
                                                activeConversation.requestBy === userId
                                                    ? 'Unblock'
                                                    : 'Block'}
                                            </Button>
                                        )}
                                </Space>
                            </div>
                            {/* <p>Nicholas C, Divan Raj, Neha D. 5+ Members</p> */}
                        </div>
                    </div>
                </div>
                <div className='chatPanel'>
                    {activeConversation &&
                        (activeConversation.request == 'BLOCKED' ||
                            activeConversation.request == 'REJECTED') &&
                        profile?.chatInputs?.map((it, ind) => {
                            console.log(it);
                            return (
                                <p
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setMessage(it.message);
                                    }}
                                    className={'shorts'}
                                >
                                    {it.short && it.short}
                                </p>
                            );
                        })}

                    <div ref={messagesStartRef} />
                    {messages.length != count ? (
                        <div
                            onClick={() => changePage()}
                            style={{
                                padding: 5,
                                color: 'white',
                                backgroundColor: 'var(--primary)',
                                cursor: 'pointer',
                                display: 'flex',
                                columnGap: 10,
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <BiRefresh size={24} />
                            Load More
                        </div>
                    ) : (
                        <div
                            onClick={() => changePage()}
                            style={{
                                padding: 5,
                                color: 'white',
                                backgroundColor: 'var(--primary)',
                                cursor: 'pointer',
                                display: 'flex',
                                columnGap: 10,
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            You reached the top.
                        </div>
                    )}

                    {messages &&
                        messages.map((item, index) => (
                            <div
                                key={index}
                                className={item.sender._id == userId ? 'right' : 'left'}
                            >
                                <p data-time={moment(item.createdDate).format('hh:mm A')}>
                                    {item.message && item.message}
                                </p>
                            </div>
                        ))}
                    <div ref={messagesEndRef} />

                    {activeConversation &&
                        Object.keys(activeConversation).length != 0 &&
                        activeConversation.request == 'PENDING' &&
                        activeConversation.requestBy != userId && (
                            <div className='requestPanel'>
                                <p>
                                    Message Request is{' '}
                                    <strong style={{ textTransform: 'capitalize' }}>
                                        {activeConversation.request}
                                    </strong>
                                </p>
                                <div className='requestBtns'>
                                    <button className='acceptReq' onClick={() => acceptRequest()}>
                                        Accept
                                    </button>
                                    <button className='rejectReq'>Reject</button>
                                    <button className='blockReq' onClick={() => blockRequest()}>
                                        Block
                                    </button>
                                </div>
                            </div>
                        )}
                    {activeConversation &&
                        Object.keys(activeConversation).length != 0 &&
                        (activeConversation.request == 'BLOCKED' ||
                            activeConversation.request == 'REJECTED') &&
                        activeConversation.requestBy == userId && (
                            <div className='requestPanel'>
                                <p>
                                    Message Request is{' '}
                                    <strong style={{ textTransform: 'capitalize' }}>
                                        {activeConversation.request}
                                    </strong>
                                </p>
                                <div className='requestBtns'>
                                    <button className='acceptReq' onClick={() => acceptRequest()}>
                                        {activeConversation.request == 'BLOCKED'
                                            ? 'Unblock'
                                            : 'Accept'}
                                    </button>
                                    <button className='rejectReq'>Reject</button>
                                    <button className='blockReq' onClick={() => blockRequest()}>
                                        Block
                                    </button>
                                </div>
                            </div>
                        )}
                    {activeConversation &&
                        activeConversation.request == 'PENDING' &&
                        activeConversation.requestBy == userId && (
                            <div className='requestPanel' style={{ justifyContent: 'flex-start' }}>
                                <p style={{ textAlign: 'center' }}>
                                    Waiting for approval from receiver.
                                </p>
                            </div>
                        )}
                    {activeConversation &&
                        Object.keys(activeConversation).length != 0 &&
                        (activeConversation.request == 'BLOCKED' ||
                            activeConversation.request == 'REJECTED') &&
                        activeConversation.requestBy != userId && (
                            <div className='requestPanel' style={{ justifyContent: 'flex-start' }}>
                                <p style={{ textAlign: 'center' }}>
                                    You have been{' '}
                                    <strong>
                                        {activeConversation.request && activeConversation.request}
                                    </strong>
                                    .
                                </p>
                            </div>
                        )}
                </div>
                <div className='messageSend'>
                    <div className='inputCont'>
                        <input
                            placeholder='Type something'
                            value={message}
                            onKeyPress={(e) => {
                                if (e.which == '13' && e.target.value) {
                                    sendMessage();
                                }
                            }}
                            onChange={(e) => {
                                setMessage(e.target.value);
                            }}
                        />
                        <i className='fa fa-paper-plane' onClick={() => sendMessage()} />
                    </div>
                </div>
            </div>

            {/* {activeConversation && activeConversation.request == "PENDING" && 
      <Modal
      className="menusModal passwordModal"
      title="Accept Request"
      visible={requestModal}
      onOk={() => setRequestModal(false)}
      onCancel={() => setRequestModal(false)}
      footer={<></>}
      >
        <h3>Request</h3>
        <button onClick={()=>acceptRequest()}>Accept</button>
        <button>Reject</button>
        <button>Block</button>
        <button>Ignore for now</button>
      </Modal>
      } */}
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    messaging: state.messaging,
    profile: state.auth.userProfile
});

const mapDispatchToProps = (dispatch) => ({
    messagingActions: bindActionCreators(messagingActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Chats);
