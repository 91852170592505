import React from 'react';
import Table from '@/components/NewProfile/Shared/Table';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import { Button, Popover, Space } from 'antd';

const Management = ({
    data,
    setOpen,
    type,
    deleteRole,
    deletePA,
    setItem,
    deleteLS,
    deleteDT,
    limit
}) => {
    console.log('data', data, type);
    const servicesColumns = [
        {
            title: <span className='dragHandler'>Service Name</span>,
            width: 300,
            dataIndex: 'name',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.name) return -1; // Handle empty values
                if (!b.name) return 1; // Handle empty values
                return a.name.localeCompare(b.name);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 250 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Locations</span>,
            width: 350,
            dataIndex: 'branches',
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 350 }
                };
            },
            render: (branches, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <>
                        {branches &&
                            branches.map((branch, index) => (
                                <>
                                    <span>
                                        {' '}
                                        {branch?.location?.branchAddress}{' '}
                                        {branch?.location?.branchState}{' '}
                                        {branch?.location?.branchCity}{' '}
                                        {branch?.location?.branchCountry}
                                    </span>
                                    {branches.length > index + 1 && ' | '}
                                </>
                            ))}
                    </>
                );
            }
        },
        {
            title: <span className='dragHandler'>Rate ($)</span>,
            width: 200,
            dataIndex: 'rate',
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            },
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.rate) return -1; // Handle empty values
                if (!b.rate) return 1; // Handle empty values
                return a.rate > b.rate;
            }
        },
        {
            title: <span className='dragHandler'>Actions</span>,
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            },
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <Space>
                        <Popover content={'Edit Service'}>
                            <Button
                                style={{ justifyContent: 'center' }}
                                onClick={() => {
                                    setOpen(true);
                                    setItem(record);
                                }}
                            >
                                Edit
                            </Button>
                        </Popover>
                        <Popover content={'Delete Service'}>
                            <Button
                                style={{ justifyContent: 'center' }}
                                onClick={() => {
                                    deleteLS(record, index);
                                }}
                            >
                                Delete
                            </Button>
                        </Popover>
                    </Space>
                );
            }
        }
    ];

    const rolesColumns = [
        {
            title: <span className='dragHandler'>Role</span>,
            width: 300,
            dataIndex: 'roleName',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.name) return -1; // Handle empty values
                if (!b.name) return 1; // Handle empty values
                return a.name.localeCompare(b.name);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 250 }
                };
            },
            width: 200
        },
        {
            title: <span className='dragHandler'>Actions</span>,
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <Space>
                        {!record.default && (
                            <>
                                <Popover content={`Edit Role`}>
                                    <Button
                                        style={{ justifyContent: 'center' }}
                                        onClick={() => {
                                            setOpen(true);
                                            setItem(record);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Popover>
                                <Popover content={`Delete Role`}>
                                    <Button
                                        style={{ justifyContent: 'center' }}
                                        onClick={() => {
                                            deleteRole(record, index);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Popover>
                            </>
                        )}
                    </Space>
                );
            }
        }
    ];

    const practiceAreaColumns = [
        {
            title: <span className='dragHandler'>Practice Area</span>,
            width: 300,
            dataIndex: 'practiceArea',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.practiceArea) return -1; // Handle empty values
                if (!b.practiceArea) return 1; // Handle empty values
                return a.practiceArea.localeCompare(b.practiceArea);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 250 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Actions</span>,
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <Space>
                        {!record.default && (
                            <>
                                <Popover content={'Edit Practice Area'}>
                                    <Button
                                        style={{ justifyContent: 'center' }}
                                        onClick={() => {
                                            setOpen(true);
                                            setItem(record);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Popover>
                                <Popover content={'Delete Practice Area'}>
                                    <Button
                                        style={{ justifyContent: 'center' }}
                                        onClick={() => {
                                            deletePA(record, index);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Popover>
                            </>
                        )}
                    </Space>
                );
            }
        }
    ];

    const documentTypeColumns = [
        {
            title: <span className='dragHandler'>Document Type</span>,
            width: 200,
            dataIndex: 'name',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.name) return -1; // Handle empty values
                if (!b.name) return 1; // Handle empty values
                return a.name.localeCompare(b.name);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 250 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Actions</span>,
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            },
            render: (_, record, index) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <Space>
                        {!record.default && (
                            <>
                                <Popover content={'Edit Document Type'}>
                                    <Button
                                        style={{ justifyContent: 'center' }}
                                        onClick={() => {
                                            setOpen(true);
                                            setItem(record);
                                        }}
                                    >
                                        Edit
                                    </Button>
                                </Popover>
                                <Popover content={'Delete Document Type'}>
                                    <Button
                                        style={{ justifyContent: 'center' }}
                                        onClick={() => {
                                            deleteDT(record, index);
                                        }}
                                    >
                                        Delete
                                    </Button>
                                </Popover>
                            </>
                        )}
                    </Space>
                );
            }
        }
    ];

    const getColumns = () => {
        if (type === 'Services') return servicesColumns;
        else if (type === 'Roles') return rolesColumns;
        else if (type === 'Practice Areas') return practiceAreaColumns;
        else if (type === 'Document Types') return documentTypeColumns;
        else return [];
    };

    const numberOfBlankRows = limit - data.length;
    let dataSource = [...data, ...Array.from({ length: numberOfBlankRows }).map(() => ({}))];
    console.log('dataSource', dataSource, getColumns(), type);
    return <Table tableName={type} columns={[...getColumns()]} data={dataSource} />;
};

export default Management;
