import React, { useState } from 'react';
import { AutoComplete, Col, Form, Input, Row } from 'antd';
import { MailOutlined, UserOutlined } from '@ant-design/icons';
import * as hospitalActions from '@/actions/hospitalActions';
import { useDispatch, useSelector } from 'react-redux';

const RequestMode = ({ form, organization, setOrganization }) => {
    const { organizationList } = useSelector((state) => state.hospital);
    const dispatch = useDispatch();

    const changeSearchOrganization = async (val) => {
        await dispatch(
            hospitalActions.getOrganizationMatch(
                {
                    search: val,
                    country: localStorage.getItem('chosenCountry')
                        ? localStorage.getItem('chosenCountry')
                        : localStorage.getItem('localityCountry')
                        ? localStorage.getItem('localityCountry')
                        : null
                },
                {}
            )
        );
    };
    return (
        <Form form={form} initialValues={{ remember: true }}>
            <AutoComplete
                value={organization.name}
                style={{ width: '100%', marginBottom: 20 }}
                onSelect={(_, item) => {
                    setOrganization({ value: item.key, name: item.value });
                }}
                onSearch={changeSearchOrganization}
                onChange={(val) => setOrganization(val)}
                placeholder='Search Organization'
            >
                {organizationList &&
                    organizationList.map((item, index) => (
                        <AutoComplete.Option key={item._id} value={item.hospitalName}>
                            {item.hospitalName}
                        </AutoComplete.Option>
                    ))}
            </AutoComplete>
            <Form.Item
                name='email'
                rules={[
                    { required: true, message: 'Please provide your email.' },
                    {
                        type: 'email',
                        message: 'Please provide a valid email address.'
                    }
                ]}
            >
                <Input
                    prefix={<MailOutlined className='site-form-item-icon' />}
                    placeholder='Email'
                />
            </Form.Item>
            <Row gutter={12}>
                <Col span={12}>
                    <Form.Item
                        name='firstName'
                        rules={[{ required: true, message: 'Please provide your firstName.' }]}
                        style={{ fontSize: 12 }}
                    >
                        <Input
                            prefix={<UserOutlined className='site-form-item-icon' />}
                            placeholder='First Name'
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        name='lastName'
                        rules={[{ required: true, message: 'Please provide your lastName.' }]}
                        style={{ fontSize: 12 }}
                    >
                        <Input
                            prefix={<UserOutlined className='site-form-item-icon' />}
                            placeholder='Last Name'
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    );
};

export default RequestMode;
