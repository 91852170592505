import React, { useState, memo, useEffect } from 'react';
import './experience.css';
import * as userActions from '@/actions/userActions';
import * as branchActions from '@/actions/branchActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CredentialHeader from './CredentialHeader';
import CredentialsTable from '@/components/NewProfile/Shared/Credentials/CredentialsTable';

const Association = (props) => {
    const { auth, credentialType, setCredentialType, credentialTypes, branch } = props;
    const [limit, setLimit] = useState(branch ? 5 : 10);
    const [page, setPage] = useState(1);
    const [associationModal, setAssociationModal] = useState(false);
    const [editAssociation, setEditAssociation] = useState(null);
    const [deleteAssociation, setDeleteAssociation] = useState(null);
    const { pharmacist } = branch ?? {};
    const [formInputs, setFormInputs] = useState([]);
    console.log(branch);
    useEffect(() => {
        if (
            props.profile &&
            props.profile.associations &&
            props.profile.associations.length !== 0 &&
            !pharmacist
        ) {
            setFormInputs(props.profile.associations);
        } else if (!pharmacist) {
            setFormInputs([]);
        }

        if (branch) {
            if (branch?.pharmacist && branch?.pharmacist?.associations) {
                setFormInputs(branch?.pharmacist.associations);
            } else {
                setFormInputs([]);
            }
        }
    }, [props.profile.associations, branch, branch?.pharmacist?.associations]);

    return (
        <>
            <CredentialHeader
                auth={auth}
                credentialType={credentialType}
                credentialTypes={credentialTypes}
                setCredentialType={setCredentialType}
                associationModal={associationModal}
                setAssociationModal={setAssociationModal}
                {...props}
            />
            <CredentialsTable
                auth={auth}
                credentialType={credentialType}
                data={formInputs}
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                associationModal={associationModal}
                setAssociationModal={setAssociationModal}
                editAssociation={editAssociation}
                setEditAssociation={setEditAssociation}
                deleteAssociation={deleteAssociation}
                setDeleteAssociation={setDeleteAssociation}
                {...props}
                pharmacist={pharmacist}
                branch={branch}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Association));
