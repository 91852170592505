import Geocode from 'react-geocode';

export async function GetLocationUsingLatLng({ lng, lat }) {
    let returnObj = {
        state: '',
        stateShort: '',
        zip: '',
        country: '',
        countryShort: '',
        city: '',
        cityShort: '',
        address: ''
    };

    Geocode.setApiKey('AIzaSyAdko-SWObgLgKd-2GGQutJkNxVTps5FsU');
    let response;
    try {
        response = await Geocode.fromLatLng(lat, lng);
        // .then (function (response) {
        if (response) {
            returnObj.address = response.results[0].formatted_address.split(',')[0];

            for (let i = 0; i < response.results[0].address_components.length; i++) {
                for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
                    switch (response.results[0].address_components[i].types[j]) {
                        case 'locality':
                            returnObj.city = response.results[0].address_components[i].long_name;
                            returnObj.cityShort =
                                response.results[0].address_components[i].short_name;
                            break;
                        case 'administrative_area_level_1':
                            returnObj.state = response.results[0].address_components[i].long_name;
                            returnObj.stateShort =
                                response.results[0].address_components[i].short_name;
                            break;
                        case 'country':
                            returnObj.country = response.results[0].address_components[i].long_name;
                            returnObj.countryShort =
                                response.results[0].address_components[i].short_name;
                            break;

                        case 'postal_code':
                            returnObj.zip = response.results[0].address_components[i].long_name;
                            break;
                    }
                }
            }
        }
    } catch (error) {
        console.log(error);
    }

    if (response) {
        return returnObj;
    } else return null;
}

export default GetLocationUsingLatLng;
