import React from 'react';
import { Modal, Switch, TimePicker, Select, Button, Alert } from 'antd';
import moment from 'moment';
const { Option, OptGroup } = Select;

const CreateAvailability = (props) => {
    const {
        createAvailabilityModal,
        setCreateAvailabilityModal,
        sunday,
        setSunday,
        monday,
        setMonday,
        tuesday,
        setTuesday,
        wednesday,
        setWednesday,
        thursday,
        setThursday,
        friday,
        setFriday,
        saturday,
        setSaturday,
        saveTimings,
        clearAvailabilityData,
        availability,
        closeModal,
        timezone,
        setTimezone,
        index,
        alwaysAvailable,
        setAlwaysAvailable
    } = props;

    const renderOptions = () => {
        const timezones = moment.tz.names();
        let mappedValues = {};
        let regions = [];

        timezones.map((timezone) => {
            const splitTimezone = timezone.split('/');
            const region = splitTimezone[0];
            if (!mappedValues[region]) {
                mappedValues[region] = [];
                regions.push(region);
            }
            mappedValues[region].push(timezone);
        });
        return regions.map((region) => {
            const options = mappedValues[region].map((timezone) => {
                return <Option key={timezone}>{timezone}</Option>;
            });
            return (
                <OptGroup key={region} title={<div style={{ fontSize: 30 }}>{region}</div>}>
                    {options}
                </OptGroup>
            );
        });
    };

    const onChangeTimeRanges = (name, value) => {
        const days = {
            Sunday: setSunday,
            Monday: setMonday,
            Tuesday: setTuesday,
            Wednesday: setWednesday,
            Thursday: setThursday,
            Friday: setFriday,
            Saturday: setSaturday
        };

        if (days.hasOwnProperty(name)) {
            console.log(name, value);
            days[name]((prev) => ({
                ...prev,
                startTime: value[0],
                endTime: value[1],
                message: ''
            }));
        }
    };
    const onChange = async (name, value) => {
        const days = {
            Sunday: setSunday,
            Monday: setMonday,
            Tuesday: setTuesday,
            Wednesday: setWednesday,
            Thursday: setThursday,
            Friday: setFriday,
            Saturday: setSaturday
        };

        if (name === 'timezone') {
            setTimezone({
                ...timezone,
                value: value,
                message: ''
            });
        } else if (name === 'alwaysAvailable') {
            setAlwaysAvailable(value);
            // if (value) {
            //     Object.keys(days).forEach((day) => {
            //         const currentDay = days[day];
            //         currentDay((prev) => ({
            //             ...prev,
            //             active: false,
            //             message: ''
            //         }));
            //     });
            // }
        } else if (days.hasOwnProperty(name)) {
            setAlwaysAvailable(false);
            const currentDay = days[name];
            currentDay((prev) => ({
                ...prev,
                active: value,
                startTime: value ? (prev.startTime || '09:00') : '',
                endTime: value ? (prev.endTime || '17:00') : '',
                message: value && !prev.startTime ? 'Time range is required' : ''
            }));
        }
    };
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const avail = {
        Sunday: sunday,
        Monday: monday,
        Tuesday: tuesday,
        Wednesday: wednesday,
        Thursday: thursday,
        Friday: friday,
        Saturday: saturday
    };
    return (
        <Modal
            centered
            className='customModal multiPage availability'
            title={
                Object.keys(availability).length > 0 ? 'Update Availability' : 'Create Availability'
            }
            open={createAvailabilityModal}
            onOk={() => {
                clearAvailabilityData();
                setCreateAvailabilityModal(false);
            }}
            onCancel={() => {
                clearAvailabilityData();
                setCreateAvailabilityModal(false);
                closeModal(index);
            }}
            footer={null}
        >
            {console.log('availability', availability)}
            <p style={{ textAlign: 'center' }}>
                Select the hours during which you wish to offer your services.
            </p>
            <Select
                style={{ margin: '20px 0px', height: '32px !important' }}
                placeholder='Select Timezone'
                value={timezone.value || undefined}
                onChange={(val) => {
                    onChange('timezone', val);
                }}
                showSearch
            >
                {renderOptions()}
            </Select>
            {timezone.message && (
                <p className='error'>
                    <i className='fe fe-alert-triangle' /> {timezone.message && timezone.message}
                </p>
            )}
            <label htmlFor='none'>
                <div style={{ marginBottom: 10 }}>
                    <Switch
                        name='Sunday'
                        checked={alwaysAvailable}
                        onChange={(value) => onChange('alwaysAvailable', value)}
                        style={{ marginRight: 20 }}
                    />
                    Open 24/7
                </div>
            </label>
            {days.map((day) => (
                <label htmlFor='none' key={day}>
                    <div style={{ marginBottom: 10 }}>
                        <Switch
                            name={day}
                            checked={avail?.[day].active && !alwaysAvailable}
                            onChange={(value) => onChange(day, value)}
                            style={{ marginRight: 20 }}
                        />
                        {day}
                    </div>
                    <TimePicker.RangePicker
                        value={
                            avail?.[day].startTime &&
                            avail?.[day].endTime && [
                                moment(avail[day].startTime, 'HH:mm'),
                                moment(avail[day].endTime, 'HH:mm')
                            ]
                        }
                        onChange={(e, value) => onChangeTimeRanges(day, value)}
                        disabled={!avail?.[day].active || alwaysAvailable}
                        minuteStep={30}
                        format={'HH:mm'}
                    />
                    {avail?.[day].message && (
                        <p className='error'>
                            <i className='fe fe-alert-triangle' />{' '}
                            {avail[day].message && avail[day].message}
                        </p>
                    )}
                </label>
            ))}
            <div className='action'>
                <Alert
                    message='Please indicate whether you operate 24/7 or specify your operating hours.'
                    type='warning'
                />
                <Button type='primary' className='action-btn' onClick={() => saveTimings()}>
                    Save Timings
                </Button>
            </div>
        </Modal>
    );
};

export default CreateAvailability;
