import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';

let { REACT_APP_API_URI } = process.env;

if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const getMyReviews = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        console.log(props.obj);
        Axios.post(`${REACT_APP_API_URI}/api/reviews/getMyReviews/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);
                console.log('reviews', response.data.reviews);
                dispatch({
                    type: Types.GET_MY_REVIEWS,
                    payload: {
                        reviews: response.data.reviews
                    }
                });
                resolve(true);
            })
            .catch((error) => {
                message.error('Posts retrieval failed');
                resolve(false);
                console.log(error.response);
            });
    });
};

export const postReview = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        console.log(props.obj);
        Axios.post(`${REACT_APP_API_URI}/api/reviews/postReview/`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);
                console.log('createdReview', response.data.createdReview);
                dispatch({
                    type: Types.POST_REVIEW,
                    payload: {
                        createdReview: response.data.createdReview
                    }
                });
                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                message.error('Review not posted');
                resolve(false);
                console.log(error.response);
            });
    });
};

export const approveReview = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        console.log(props.obj);
        Axios.post(`${REACT_APP_API_URI}/api/reviews/approveReview`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                message.error('Review approval failed');
                resolve(false);
                console.log(error.response);
            });
    });
};

export const archiveReview = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        console.log(props.obj);
        Axios.post(`${REACT_APP_API_URI}/api/reviews/archiveReview`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log(response);

                message.success(response.data.message);
                resolve(true);
            })
            .catch((error) => {
                message.error('Review archiving failed');
                resolve(false);
                console.log(error.response);
            });
    });
};

export const getRecentReviews = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        console.log(props.obj);
        Axios.post(`${REACT_APP_API_URI}/api/reviews/getRecentReviews`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log('recentReview', response.data.reviews);
                dispatch({
                    type: Types.GET_RECENT_REVIEWS,
                    payload: {
                        recentReviews: response.data.reviews
                    }
                });
                resolve(true);
            })
            .catch((error) => {
                message.error('Reviews fetching failed');
                resolve(false);
                console.log(error.response);
            });
    });
};

export const getClientReviews = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        console.log(props.obj);
        Axios.post(`${REACT_APP_API_URI}/api/reviews/getClientReviews`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log('recentReview', response.data.reviews);
                dispatch({
                    type: Types.GET_CLIENT_REVIEWS,
                    payload: {
                        reviews: response.data.reviews,
                        reviewsCount: response.data.count
                    }
                });
                resolve(true);
            })
            .catch((error) => {
                message.error('Reviews fetching failed');
                resolve(false);
                console.log(error.response);
            });
    });
};

export const getViewReviews = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        console.log(props.obj);
        Axios.post(`${REACT_APP_API_URI}/api/reviews/getViewReviews`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((response) => {
                console.log('recentReview', response.data.reviews);
                dispatch({
                    type: Types.GET_CLIENT_REVIEWS,
                    payload: {
                        reviews: response.data.reviews,
                        reviewsCount: response.data.count
                    }
                });
                resolve(true);
            })
            .catch((error) => {
                message.error('Reviews fetching failed');
                dispatch({
                    type: Types.GET_CLIENT_REVIEWS,
                    payload: {
                        reviews: [],
                        reviewsCount: 0
                    }
                });
                resolve(false);
                console.log(error.response);
            });
    });
};
