import React from 'react';
import { Row, Col, Button, Select } from 'antd';

const ReviewsHeader = () => {
    return (
        <Row gutter={[10, 10]} style={{ marginBottom: 20, justifyContent: 'flex-end' }}>
            <Col flex={1} xs={24} lg={4}>
                <Button style={{ width: '100%' }} type='default'>
                    Archive All
                </Button>
            </Col>
            <Col flex={1} xs={24} lg={4}>
                <Button style={{ width: '100%' }} type='default'>
                    Approve All
                </Button>
            </Col>
        </Row>
    );
};

export default ReviewsHeader;
