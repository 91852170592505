import React, { useState } from 'react';
import { IoPricetagOutline, IoHeart, IoShare } from 'react-icons/io5';
import moment from 'moment';
import { message, Menu, Dropdown } from 'antd';
import ConfirmModal from '@/components/modals/ConfirmModal';
import he from 'he';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import malePlaceholder from '@/assets/img/malePlaceholder.png';
import femalePlaceholder from '@/assets/img/femalePlaceholder.jpg';
import './pBlogCard.css';

const PBlogCard = (props) => {
    const history = useHistory();
    const { userType, user } = useSelector((state) => state.auth);
    const { blog } = props;

    const link = `/blogs/${blog._id}`;

    const route =
        userType === 'HOSPITAL'
            ? 'hospital'
            : userType === 'DOCTOR'
            ? 'doctor'
            : userType === 'PATIENT'
            ? 'user'
            : userType === 'NURSE'
            ? 'nurse'
            : userType === 'CONSULTANT'
            ? 'consultant'
            : userType === 'PHARMACY' && 'pharmacy';

    let genderPlaceholderImg = malePlaceholder;

    if (blog && blog.author.gender && blog.author.gender == 'Female') {
        genderPlaceholderImg = femalePlaceholder;
    }

    const [modalOpen, setModalOpen] = useState(false);
    const [itemToBeDeleted, setItemToBeDeleted] = useState(null);

    let date = moment(blog.createdAt).format('MMMM, D, YYYY').split(',');
    const menu = (props) => (
        <Menu>
            <Menu.Item
                onClick={() => {
                    setItemToBeDeleted({
                        item: props.item,
                        index: props.index
                    });
                    setModalOpen(true);
                }}
            >
                <a>Delete post</a>
            </Menu.Item>
            <Menu.Item onClick={() => history.push(`/${route}/blogs/edit/${props.item._id}`)}>
                <a>Edit post</a>
            </Menu.Item>
        </Menu>
    );
    const modalClose = () => {
        setModalOpen(false);
    };

    const deletePost = async (post, index) => {
        let obj = {
            blogId: post._id
        };

        let deletedPost = await props.blogActions.deletePost({ ...props, obj }, history);
        if (deletedPost) {
            message.success('Post Successfully deleted');
        }
        setItemToBeDeleted(null);
        setModalOpen(false);
    };
    return (
        <>
            <div
                className={
                    props.type === 'normal' ? 'header-blogs-upper-normal' : 'header-blogs-upper'
                }
                style={{
                    backgroundColor: props.background,
                    position: 'relative'
                }}
            >
                <div
                    className={
                        props.type === 'normal'
                            ? 'header-blogs-cat-normal pointer'
                            : 'header-blogs-cat pointer'
                    }
                >
                    <IoPricetagOutline />
                    {blog
                        ? blog.category.map((item, index) => {
                              if (index < 2) {
                                  return (
                                      <p
                                          onClick={() =>
                                              history.push({
                                                  pathname: `/blogs/search`,
                                                  search: `?category=${item}`
                                              })
                                          }
                                          key={index}
                                      >
                                          {item}
                                          {index == blog.category.length && ','}
                                      </p>
                                  );
                              }
                          })
                        : 'Civil'}
                </div>
                <div>
                    <div
                        className={
                            props.type === 'normal'
                                ? 'header-blog-title-normal'
                                : 'header-blog-title'
                        }
                        onClick={() => {
                            // console.log(history, link)
                            history.push(link);
                        }}
                    >
                        {blog ? blog.title : 'Blog Title'}
                    </div>

                    {blog && blog.author && user && blog.author._id === user._id ? (
                        <Dropdown
                            trigger={['click']}
                            overlay={() =>
                                menu({
                                    item: blog,
                                    index: props.index,
                                    ...props
                                })
                            }
                            placement='bottomLeft'
                            arrow
                        >
                            <div
                                style={{
                                    position: 'absolute',
                                    fontSize: '18px',
                                    top: 15,
                                    right: 10
                                }}
                            >
                                <i className='fe fe-more-vertical' />
                            </div>
                        </Dropdown>
                    ) : null}
                </div>
                {blog && blog.body && (
                    <div
                        className={
                            props.type === 'normal' ? 'header-blog-body-normal' : 'header-blog-body'
                        }
                    >
                        <p>
                            {blog &&
                                blog.body &&
                                he.decode(blog && blog.body.replace(/<[^>]+>/g, ''))}
                        </p>
                    </div>
                )}
                <div
                    className={
                        props.type === 'normal' ? 'header-blog-lower-normal' : 'header-blog-lower'
                    }
                >
                    <div className='header-blog-img-grid'>
                        <div className='header-blog-img'>
                            <img
                                src={
                                    blog && blog.author && blog.author.profileImage
                                        ? blog.author.profileImage
                                        : genderPlaceholderImg
                                }
                                onError={(e) => (e.target.src = { genderPlaceholderImg })}
                            />
                        </div>
                        <div
                            className={
                                props.type === 'normal'
                                    ? 'header-blog-date-normal'
                                    : 'header-blog-date'
                            }
                        >
                            <>
                                {' '}
                                {blog.author.userType == 'HOSPITAL' && blog.author.hospitalName ? (
                                    <p
                                        onClick={() =>
                                            history.push({
                                                pathname: `/blogs/search`,
                                                search: `?author=${
                                                    blog.author &&
                                                    blog.author._id &&
                                                    blog.author._id
                                                }`
                                            })
                                        }
                                    >
                                        {' '}
                                        {blog.author.hospitalName}{' '}
                                    </p>
                                ) : (
                                    <>
                                        <p
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/blogs/search`,
                                                    search: `?author=${
                                                        blog.author &&
                                                        blog.author._id &&
                                                        blog.author._id
                                                    }`
                                                })
                                            }
                                        >
                                            {blog &&
                                                blog.author &&
                                                blog.author.firstName &&
                                                blog.author.firstName}{' '}
                                            {blog &&
                                                blog.author &&
                                                blog.author.lastName &&
                                                blog.author.lastName}
                                        </p>
                                        <small
                                            onClick={() =>
                                                history.push({
                                                    pathname: `/blogs/search`,
                                                    search: `?author=${
                                                        blog.identity && blog.identity
                                                    }`
                                                })
                                            }
                                            style={{ display: 'block' }}
                                        >
                                            {blog.author.hospitalName && blog.author.hospitalName}
                                        </small>
                                    </>
                                )}
                            </>
                            <p
                                className='orange-text'
                                onClick={() =>
                                    history.push({
                                        pathname: `/blogs/search`,
                                        search: `?date=${blog.createdDate}`
                                    })
                                }
                            >
                                {blog && moment(blog.createdDate).format('MMMM D YYYY').split(',')}
                            </p>
                        </div>
                    </div>
                    <div className='header-blogs-option-grid pointer'>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '10px',
                                color: '#778da9'
                            }}
                        >
                            <IoHeart /> {blog && blog.likesCount ? blog.likesCount : 0}
                            <p style={{ marginLeft: '5px' }}>likes</p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginLeft: '10px',
                                color: '#ff4500'
                            }}
                        >
                            <IoShare /> {blog && blog.shares ? blog.shares : 0}
                            <p style={{ marginLeft: '5px' }}>shares</p>
                        </div>
                    </div>
                </div>
            </div>
            {modalOpen && (
                <ConfirmModal
                    {...props}
                    onClose={modalClose}
                    acceptMethod={() => deletePost(itemToBeDeleted.item, itemToBeDeleted.index)}
                    headerText='Delete Blog post'
                    bodyText='Are you sure you want to delete this post?'
                />
            )}
        </>
    );
};

export default PBlogCard;
