import React, { memo } from 'react';
import '../doctorList.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import '@/components/NewProfile/Shared/Appointments/appointment.css';
import { Tag, Tooltip, Button } from 'antd';
import Table from '@/components/NewProfile/Shared/Table';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import MemberHeader from './MemberHeader';

const MemberList = (props) => {
    const {
        auth,
        members,
        setMemberToBeUpdated,
        setCreateDoctorModal,
        limit,
        memberType,
        onMemberChange
    } = props;

    const columns = [
        {
            title: <span className='dragHandler'>First name</span>,
            width: 400,
            dataIndex: 'firstName',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.firstName) return -1; // Handle empty values
                if (!b.firstName) return 1; // Handle empty values
                return a.firstName.localeCompare(b.firstName);
            },
            render: (firstName, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <div>{firstName}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 },
                    width: '150px'
                };
            }
        },
        {
            title: <span className='dragHandler'>Last name</span>,
            width: 400,
            dataIndex: 'lastName',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.lastName) return -1; // Handle empty values
                if (!b.lastName) return 1; // Handle empty values
                return a.lastName.localeCompare(b.lastName);
            },
            render: (lastName, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <div>{lastName}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 },
                    width: '150px'
                };
            }
        },
        {
            title: <span className='dragHandler'>Email</span>,
            width: 200,
            dataIndex: 'email',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.email) return -1; // Handle empty values
                if (!b.email) return 1; // Handle empty values
                return a.email.localeCompare(b.email);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            },
            render: (email, record) => {
                if (isEmptyObject(record)) return <div></div>;
                return <div>{email}</div>;
            }
        },
        {
            title: <span className='dragHandler'>Phone No</span>,
            width: 200,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.phoneNo) return -1; // Handle empty values
                if (!b.phoneNo) return 1; // Handle empty values
                return a.phoneNo.localeCompare(b.phoneNo);
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div></div>;
                if (!record.phoneNo) return <div>Not available</div>;
                return <div>{'(' + record.countryCode + ') -' + record.phoneNo}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Status</span>,
            width: 150,
            dataIndex: 'activeStatus',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.activeStatus) return -1; // Handle empty values
                if (!b.activeStatus) return 1; // Handle empty values
                return a.activeStatus.localeCompare(b.activeStatus);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            },
            render: (activeStatus, record) => {
                if (isEmptyObject(record)) return <div></div>;
                return <Tag>{activeStatus}</Tag>;
            }
        },
        {
            title: <span className='dragHandler'>Action</span>,
            render: (data, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                const { userType } = record;
                const formattedUserType = userType.slice(0, 1) + userType.slice(1).toLowerCase();
                return (
                    <div>
                        <Tooltip title={`Edit ${formattedUserType}`}>
                            <Button onClick={() => setMemberToBeUpdated(record)} type='default'>
                                Edit
                            </Button>
                        </Tooltip>
                    </div>
                );
            }
        }
    ];
    const numberOfBlankRows = limit - members?.length;
    let dataSource = [...members, ...Array.from({ length: numberOfBlankRows }).map(() => ({}))];

    return (
        <>
            <MemberHeader
                auth={auth}
                setCreateDoctorModal={setCreateDoctorModal}
                memberType={memberType}
                onMemberChange={onMemberChange}
            />
            <Table tableName='Members' columns={columns} data={dataSource} />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.profile
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(MemberList));
