import React from 'react';
import { Modal, Select } from 'antd';
const { Option } = Select;

function CreateUserModal(props) {
    const {
        createUser,
        createUserModal,
        setCreateUserModal,
        email,
        password,
        setEmail,
        setPassword,
        userType,
        setUserType,
        title
    } = props;
    return (
        (<Modal
            className='menusModal'
            title={title ? title : 'Create User'}
            open={createUserModal}
            onOk={() => setCreateUserModal(false)}
            onCancel={() => setCreateUserModal(false)}
            footer={<></>}
        >
            <label className='input-row'>
                <span>User Type</span>
                <Select
                    mode='single'
                    placeholder='Select STATUS'
                    defaultValue={[]}
                    value={userType && userType}
                    onChange={(val) => setUserType(val)}
                    style={{ width: '100%', marginBottom: 10 }}
                >
                    <Option value={'ADMIN'}>ADMIN</Option>
                </Select>
            </label>
            <label className='input-row'>
                <span>Email</span>
                <input
                    placeholder='Email'
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </label>
            <label className='input-row'>
                <span>Password</span>
                <input
                    placeholder='Password'
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
            </label>
            <button
                onClick={() => createUser()}
                className='ant-btn ant-btn-primary'
                style={{ marginTop: 20 }}
            >
                Submit
            </button>
        </Modal>)
    );
}

export default CreateUserModal;
