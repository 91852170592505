import React, { useEffect } from 'react';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import * as hospitalActions from '@/actions/hospitalActions';
import ApplicationSetup from './ApplicationSetup';
import '@/assets/css/dashboard.css';
import Availability from './Availability';
import Notifications from './Notifications';
import StripeDashboard from './Stripe';
import DataVisualization from '../DataVisualization';

const Dashboard = () => {
    const dashboardInfo = useSelector((state) => state.hospital.dashboard);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(hospitalActions.getHospitalDashboardInfo());
    }, []);

    return (
        <Row gutter={[20, 20]}>
            <Col xs={24} md={17} style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                <ApplicationSetup />
                {/* <StripeDashboard /> */}

                <DataVisualization />
            </Col>
            <Col xs={24} md={7} style={{display: 'flex', flexDirection: 'column', gap: 20}}>
                <Availability />
                <Notifications />
            </Col>
        </Row>
    );
};

export default Dashboard;
