import * as Types from '../actions/types';

const init = {
    dashboard: {
        practiceAreas: 0,
        doctors: 0,
        branches: 0,
        consultantServices: 0,
        roles: 0,
        appointments: 0,
        services: 0,
        appointmentsToday: [],
        pendingApprovals: 0,
        upcomingAppointments: 0,
        cancelledAppointments: 0,
        failedAppointments: 0,
        missedAppointments: 0,
        rejectedAppointments: 0,
        completedAppointments: 0,
        noShowAppointments: 0
    },
    therapists: [],
    therapistSingle: {},
    therapistSingleDoctors: [],
    therapistSingleDoctorsCount: 0,
    createdDoctor: {},
    doctors: [],
    featuredHospitals: [],
    doctorsCount: 0,
    shortDoctorList: [],
    therapistForAppointment: {
        therapist: {},
        timeSlots: [],
        availableDays: [],
        availability: {}
    },
    myDoctors: {
        timeSlots: [],
        availability: {}
    },
    searchResults: {
        therapists: [],
        therapistsCount: 0,
        therapistsSuggestions: []
    },
    therapistStatCounts: {
        posts: 0,
        likes: 0,
        reviews: 0,
        followers: 0
    },
    lineChart: null,
    pieChart: []
};

const therapistReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_THERAPISTS: {
            return {
                ...state,
                therapists: action.payload.therapists
            };
        }
        case Types.GET_FEATURED_THERAPISTS: {
            return {
                ...state,
                featuredHospitals: action.payload.therapists
            };
        }
        case Types.GET_SINGLE_THERAPIST: {
            return {
                ...state,
                therapistSingle: action.payload.therapistSingle
            };
        }

   
        case Types.CLEAR_THERAPISTS: {
            return {
                ...state,
                therapists: [],
                therapistSingle: {},
                createdDoctor: {}
            };
        }
        case Types.GET_THERAPIST_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    therapists: action.payload.therapists,
                    therapistsCount: action.payload.therapistsCount
                }
            };
        }
        case Types.CLEAR_THERAPIST_SEARCH_RESULTS: {
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    therapists: [],
                    therapistsCount: 0
                }
            };
        }
        case Types.GET_THERAPIST_STAT_COUNTS: {
            return {
                ...state,
                therapistStatCounts: {
                    posts: action.payload.counts && action.payload.counts.posts,
                    likes: action.payload.counts && action.payload.counts.likes,
                    reviews: action.payload.counts && action.payload.counts.reviews,
                    followers: action.payload.counts && action.payload.counts.followers
                }
            };
        }
        case Types.GET_THERAPIST_EXTRACTED_TIME_SLOTS: {
            return {
                ...state,
                therapistForAppointment: {
                    ...state.therapistForAppointment,
                    availableDays: action.payload.availableDays,
                    timeSlots: action.payload.timeSlots,
                    availability: action.payload.therapistAvailability
                }
            };
        }
        case Types.GET_THERAPIST_FOR_APPOINTMENT: {
            return {
                ...state,
                therapistForAppointment: {
                    ...state.therapistForAppointment,
                    therapist: action.payload.therapist
                }
            };
        }


        case Types.GET_LINE_CHART_DATA: {
            return {
                ...state,
                lineChart: action.payload.lineChart
            };
        }
        case Types.GET_PIE_CHART_DATA: {
            return {
                ...state,
                pieChart: action.payload.pieChart
            };
        }
        case Types.GET_THERAPIST_DASHBOARD: {
            return {
                ...state,
                dashboard: action.payload.dashboard
            };
        }
    
        default:
            return state;
    }
};

export default therapistReducer;
