import React, { useEffect } from 'react';
import ProfileDetail from './ProfileDetail';
import SideDetail from './SideDetail';
import ChooseDateAndTime from './ChooseDateAndTime';

import './DetailPage.css';

function DetailPage(props) {
    const {
        consultationMode,
        setConsultationMode,
        paymentMode,
        setPaymentMode,
        appointmentDate,
        setAppointmentDate,
        refreshDetails,
        auth,
        hospital,
        doctors,
        chosenServiceObj,
        history,
        location,
        setPointer,
        notes,
        setNotes,
        files,
        setFiles
    } = props;

    const handleRedirect = async () => {
        try {
            let searchQuery = new URLSearchParams(location.search);
            let hospital = searchQuery.get('hospital');
            let doctor = searchQuery.get('doctor');
            let branch = searchQuery.get('branch');
            let flag = (hospital || doctor) && branch;
            console.log('flag', flag)
            if (!flag) {
                console.log(
                    'about to redirect',
                    searchQuery.get('hospital'),
                    searchQuery.get('branch')
                );
                setPointer(0);
                history.push(`/patient/appointments/new?step=1&date=${appointmentDate}`);
            }
        } catch (error) {
            setPointer(0);
            history.push(`/patient/appointments/new?step=1&date=${appointmentDate}`);
        }
    };

    useEffect(() => {
        handleRedirect();
    }, []);

    return (
        <div className='detailPage'>
            <div className='mainContent'>
                <ProfileDetail
                    {...props}
                    auth={auth}
                    hospital={hospital}
                    doctors={doctors}
                    history={history}
                />
                <ChooseDateAndTime
                    {...props}
                    auth={auth}
                    hospital={hospital}
                    doctors={doctors}
                    consultationMode={consultationMode}
                    setConsultationMode={setConsultationMode}
                    paymentMode={paymentMode}
                    setPaymentMode={setPaymentMode}
                    apptDate={appointmentDate}
                    setApptDate={setAppointmentDate}
                    refreshDetails={refreshDetails}
                    chosenServiceObj={chosenServiceObj}
                />
            </div>
            <SideDetail notes={notes} files={files} setNotes={setNotes} setFiles={setFiles} />
        </div>
    );
}

export default DetailPage;
