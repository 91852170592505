import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { changePassword } from '../../actions/userActions';

export default function ChangePassword({ closePasswordModal, passwordModal, history }) {
    const dispatch = useDispatch();
    const { user, userType } = useSelector((state) => state.auth);
    const [password, setPassword] = useState({
        oldPassword: {
            value: '',
            message: '',
            isValid: true,
            show: false,
            label: 'Old Password'
        },
        newPassword: {
            value: '',
            message: '',
            isValid: true,
            show: false,
            label: 'New Password'
        },
        confirmPassword: {
            value: '',
            message: '',
            isValid: true,
            show: false,
            label: 'Confirm Password'
        }
    });
    const changePasswordSubmit = async () => {
        let state = { ...password };
        if (state.newPassword.value != state.confirmPassword.value) {
            state.confirmPassword.message = 'Passwords do not match';
            state.confirmPassword.isValid = false;
        }

        if (
            // state.oldPassword.isValid &&
            // state.oldPassword.value != "" &&
            state.newPassword.isValid &&
            state.newPassword.value != '' &&
            state.confirmPassword.isValid &&
            state.confirmPassword.value != ''
        ) {
            console.log('got in');
            let obj = {
                userId: user?._id,
                userType: userType,
                old_password: state.oldPassword.value,
                new_password: state.newPassword.value,
                confirm_password: state.confirmPassword.value
            };
            let changedPassword = await dispatch(changePassword({ obj, history }, history));

            if (changedPassword) {
                setPassword({
                    oldPassword: { ...state.oldPassword, value: '' },
                    newPassword: { ...state.newPassword, value: '' },
                    confirmPassword: { ...state.confirmPassword, value: '' }
                });
                closePasswordModal();
            }
        }
    };

    const changePasswordInput = (e) => {
        console.log(e.target.name);
        let state = { ...password };
        state[e.target.name].value = e.target.value;

        if (e.target.value === '') {
            state[e.target.name].message = `${state[e.target.name].label} is required`;
            state[e.target.name].isValid = false;
        } else if (e.target.value.length < 6) {
            state[e.target.name].message = 'Minimum eight characters are required';
            state[e.target.name].isValid = false;
        } else {
            state[e.target.name].message = '';
            state[e.target.name].isValid = true;
        }

        if (e.target.name == 'oldPassword') {
            if (e.target.value == '') {
                state[e.target.name].message = 'Old Password is required';
                state[e.target.name].isValid = false;
            } else if (e.target.value.length < 6) {
                state[e.target.name].message = 'Minimum eight characters are required';
                state[e.target.name].isValid = false;
            } else {
                state[e.target.name].message = '';
                state[e.target.name].isValid = true;
            }
        }

        if (e.target.name == 'newPassword') {
            if (e.target.value == '') {
                state[e.target.name].message = 'New Password is required';
                state[e.target.name].isValid = false;
            } else if (e.target.value.length < 6) {
                state[e.target.name].message = 'Minimum eight characters are required';
                state[e.target.name].isValid = false;
            } else {
                state[e.target.name].message = '';
                state[e.target.name].isValid = true;
            }

            if (
                state[e.target.name].value != '' &&
                state.oldPassword.value != '' &&
                state[e.target.name].value == state.oldPassword.value
            ) {
                state[e.target.name].message = 'New Password and Old Password should not match';
                state[e.target.name].isValid = false;
            }
            if (
                state[e.target.name].value != '' &&
                state[e.target.name].value != state.confirmPassword.value
            ) {
                state.confirmPassword.message = 'New Password and Confirm Password should match';
                state.confirmPassword.isValid = false;
            } else {
                state.confirmPassword.message = '';
                state.confirmPassword.isValid = true;
            }
        }

        if (e.target.name == 'confirmPassword') {
            if (e.target.value == '') {
                state[e.target.name].message = 'Confirm Password is required';
                state[e.target.name].isValid = false;
            } else if (
                state[e.target.name].value != '' &&
                state[e.target.name].value != state.newPassword.value
            ) {
                state[e.target.name].message = 'Confirm Password and New Password should match';
                state[e.target.name].isValid = false;
            } else if (e.target.value.length < 6) {
                state[e.target.name].message = 'Minimum eight characters are required';
                state[e.target.name].isValid = false;
            } else {
                state[e.target.name].message = '';
                state[e.target.name].isValid = true;
            }
        }

        setPassword(state);
    };
    const { oldPassword, newPassword, confirmPassword } = password;
    return (
        <Modal
            className='menusModal passwordModal'
            title='Change Password'
            open={passwordModal}
            onOk={() => closePasswordModal()}
            onCancel={() => closePasswordModal()}
            footer={<></>}
        >
            <p>*Leave current password empty if you don't have a password</p>
            {Object.keys(password).map((key, index) => {
                return (
                    <label
                        key={index}
                        className='input-row'
                        style={{
                            flexDirection: 'column !important',
                            alignItems: 'flex-start'
                        }}
                    >
                        <div>
                            <span>{password[key].label}:</span>
                            <div className='inputContainer'>
                                <input
                                    autoComplete='new-password'
                                    name={key}
                                    type={password[key].show ? 'text' : 'password'}
                                    placeholder={password[key].label}
                                    value={password[key].value}
                                    onChange={(e) => changePasswordInput(e)}
                                />
                                <div
                                    className='showPassword'
                                    onClick={() =>
                                        setPassword({
                                            ...password,
                                            [key]: { ...password[key], show: !password[key].show }
                                        })
                                    }
                                >
                                    <i className='fe fe-eye' />
                                </div>
                            </div>
                        </div>
                        {password[key].message && (
                            <p className='errorText'>{password[key].message}</p>
                        )}
                    </label>
                );
            })}

            <Button
                type='primary'
                onClick={(e) => {
                    e.preventDefault();
                    changePasswordSubmit();
                }}
                className='ant-btn ant-btn-primary'
                style={{
                    marginTop: 20
                }}
            >
                Submit
            </Button>
        </Modal>
    );
}
