import React, { useState, useEffect } from 'react';
import CreateAvailability from './CreateAvailability';
import { message } from 'antd';
import * as userActions from '@/actions/userActions';
import * as availabilityActions from '@/actions/availabilityActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const AvailabilityModal = (props) => {
    // const [createAvailabilityModal, setCreateAvailabilityModal] = useState(false);
    const { createAvailabilityModal, setCreateAvailabilityModal, selectedAddressIndex, branches } =
        props;

    let hospitalSchedule =
        branches &&
        branches?.length > 0 &&
        branches[selectedAddressIndex] &&
        branches[selectedAddressIndex].availability &&
        branches[selectedAddressIndex].availability;

    const [hospital, setHospital] = useState({
        value: null,
        isValid: true,
        message: ''
    });
    // const [branch, setBranch] = useState(null);
    const [timezone, setTimezone] = useState({
        value: '',
        message: ''
    });
    const [sunday, setSunday] = useState({
        active: false,
        startTime: '',
        endTime: '',
        message: ''
    });
    const [monday, setMonday] = useState({
        active: false,
        startTime: '',
        endTime: '',
        message: ''
    });
    const [tuesday, setTuesday] = useState({
        active: false,
        startTime: '',
        endTime: '',
        message: ''
    });
    const [wednesday, setWednesday] = useState({
        active: false,
        startTime: '',
        endTime: '',
        message: ''
    });
    const [thursday, setThursday] = useState({
        active: false,
        startTime: '',
        endTime: '',
        message: ''
    });
    const [friday, setFriday] = useState({
        active: false,
        startTime: '',
        endTime: '',
        message: ''
    });
    const [saturday, setSaturday] = useState({
        active: false,
        startTime: '',
        endTime: '',
        message: ''
    });

    const clearAvailabilityData = async () => {
        setSunday({ active: false, startTime: '', endTime: '', message: '' });
        setMonday({ active: false, startTime: '', endTime: '', message: '' });
        setTuesday({ active: false, startTime: '', endTime: '', message: '' });
        setWednesday({ active: false, startTime: '', endTime: '', message: '' });
        setThursday({ active: false, startTime: '', endTime: '', message: '' });
        setFriday({ active: false, startTime: '', endTime: '', message: '' });
        setSaturday({ active: false, startTime: '', endTime: '', message: '' });
    };

    const initializeUpdateAvailability = async () => {
        let Avail = props.schedule;
        const daysOfWeek = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
        ];

        if (Avail) {
            const updatedDays = {};

            daysOfWeek.forEach((day) => {
                const scheduleDay = Avail[day.toLowerCase()];

                updatedDays[day] = {
                    active: scheduleDay ? true : false,
                    startTime: scheduleDay ? scheduleDay.startTime : '',
                    endTime: scheduleDay ? scheduleDay.endTime : '',
                    message: ''
                };
            });
            setSunday(updatedDays['Sunday']);
            setMonday(updatedDays['Monday']);
            setTuesday(updatedDays['Tuesday']);
            setWednesday(updatedDays['Wednesday']);
            setThursday(updatedDays['Thursday']);
            setFriday(updatedDays['Friday']);
            setSaturday(updatedDays['Saturday']);
            setTimezone({
                value: Avail.timezone
                    ? Avail.timezone
                    : hospitalSchedule.timezone
                    ? hospitalSchedule.timezone
                    : ''
            });
            setHospital({
                value: Avail.hospital ? Avail.hospital : ''
            });
        } else if (hospitalSchedule) {
            console.log('came in here', hospitalSchedule);
            const updatedDays = {};

            daysOfWeek.forEach((day) => {
                updatedDays[day] = {
                    active: hospitalSchedule[day.toLowerCase()] ? true : false,
                    startTime: hospitalSchedule[day.toLowerCase()]
                        ? hospitalSchedule[day.toLowerCase()].startTime
                        : '',
                    endTime: hospitalSchedule[day.toLowerCase()]
                        ? hospitalSchedule[day.toLowerCase()].endTime
                        : '',
                    message: ''
                };
            });

            setSunday(updatedDays['Sunday']);
            setMonday(updatedDays['Monday']);
            setTuesday(updatedDays['Tuesday']);
            setWednesday(updatedDays['Wednesday']);
            setThursday(updatedDays['Thursday']);
            setFriday(updatedDays['Friday']);
            setSaturday(updatedDays['Saturday']);

            setTimezone({
                value: hospitalSchedule.timezone ? hospitalSchedule.timezone : ''
            });
        }
        setCreateAvailabilityModal(true);
    };

    const saveTimings = async () => {
        let validity = await validate();
        if (validity == true) {
            let obj = {
                sunday: sunday.active ? sunday : null,
                monday: monday.active ? monday : null,
                tuesday: tuesday.active ? tuesday : null,
                wednesday: wednesday.active ? wednesday : null,
                thursday: thursday.active ? thursday : null,
                friday: friday.active ? friday : null,
                saturday: saturday.active ? saturday : null,
                timezone: timezone.value,
                hospital: props?.auth?.user?.hospital?._id,
                branch: props?.selectedAddress,
                updateBranch: true
            };
            let response;
            if (props.schedule && props.schedule._id) {
                obj.availabilityId = props.schedule._id;
                response = await props.availabilityActions.createUserAvailability(
                    { ...props, obj },
                    props.history
                );
            } else {
                response = await props.availabilityActions.createUserAvailability(
                    { ...props, obj },
                    props.history
                );
            }

            if (response) {
                clearAvailabilityData();
                setCreateAvailabilityModal(false);
                props.availabilityActions.getAvailability({ ...props, obj }, props.history);
                let profileData = {
                    userId: props.auth.user._id,
                    userType: props.auth.user.userType
                };
                props.actions.getProfile(profileData, props.history);
            }
        } else {
            message.error('Fix the errors below');
        }
    };
    const validate = async () => {
        let validity = true;
        let noTimingChosen = false;
        if (
            sunday &&
            !sunday.active &&
            monday &&
            !monday.active &&
            tuesday &&
            !tuesday.active &&
            wednesday &&
            !wednesday.active &&
            thursday &&
            !thursday.active &&
            friday &&
            !friday.active &&
            saturday &&
            !saturday.active
        ) {
            noTimingChosen = true;
            validity = false;
        }
        if (
            (sunday && sunday.active && !sunday.startTime) ||
            (monday && !monday.active && !monday.startTime) ||
            (tuesday && !tuesday.active && !tuesday.startTime) ||
            (wednesday && !wednesday.active && !wednesday.startTime) ||
            (thursday && !thursday.active && !thursday.startTime) ||
            (friday && !friday.active && !friday.startTime) ||
            (saturday && !saturday.active && !saturday.startTime)
        ) {
            if (sunday && sunday.active && !sunday.startTime) {
                setSunday({
                    ...sunday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (monday && monday.active && !monday.startTime) {
                setMonday({
                    ...monday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (tuesday && tuesday.active && !tuesday.startTime) {
                setTuesday({
                    ...tuesday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (wednesday && wednesday.active && !wednesday.startTime) {
                setWednesday({
                    ...wednesday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (thursday && thursday.active && !thursday.startTime) {
                setThursday({
                    ...thursday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (friday && friday.active && !friday.startTime) {
                setFriday({
                    ...friday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
            if (saturday && saturday.active && !saturday.startTime) {
                setSaturday({
                    ...saturday,
                    message: 'Time Range is required for the active day'
                });
                validity = false;
            }
        }
        return validity;
    };

    useEffect(() => {
        if (props.createAvailabilityModal && props.createAvailabilityModal == true) {
            setCreateAvailabilityModal(true);
        } else {
            setCreateAvailabilityModal(false);
        }
        initializeUpdateAvailability();
    }, []);

    return (
        <div>
            {createAvailabilityModal && (
                <CreateAvailability
                    Availability={props.availability}
                    createAvailabilityModal={createAvailabilityModal}
                    setCreateAvailabilityModal={setCreateAvailabilityModal}
                    sunday={sunday}
                    setSunday={setSunday}
                    monday={monday}
                    setMonday={setMonday}
                    tuesday={tuesday}
                    setTuesday={setTuesday}
                    wednesday={wednesday}
                    setWednesday={setWednesday}
                    thursday={thursday}
                    setThursday={setThursday}
                    friday={friday}
                    setFriday={setFriday}
                    saturday={saturday}
                    setSaturday={setSaturday}
                    saveTimings={saveTimings}
                    clearAvailabilityData={clearAvailabilityData}
                    initializeUpdateAvailability={initializeUpdateAvailability}
                    timezone={timezone}
                    setTimezone={setTimezone}
                    {...props}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    branches: state.branch.branches
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    availabilityActions: bindActionCreators(availabilityActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityModal);
