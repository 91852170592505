import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';

let { REACT_APP_API_URI } = process.env;
if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const getHospitals = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (props && props.obj) {
            Axios.post(`${REACT_APP_API_URI}/api/hospitals/getHospitals`, props.obj)
                .then((res) => {
                    if (res) {
                        if (props.obj.featured) {
                            dispatch({
                                type: Types.GET_FEATURED_HOSPITALS,
                                payload: {
                                    hospitals: res.data.hospitals
                                }
                            });
                        } else {
                            dispatch({
                                type: Types.GET_HOSPITALS,
                                payload: {
                                    hospitals: res.data.hospitals
                                }
                            });
                        }

                        return resolve(true);
                    }
                })
                .catch((error) => {
                    if (error && error.response) {
                        console.log(error.response.data);
                        // message.error("Hospitals Data fetching failed");

                        if (props.obj.featured) {
                            dispatch({
                                type: Types.GET_FEATURED_HOSPITALS,
                                payload: {
                                    hospitals: []
                                }
                            });
                        } else {
                            dispatch({
                                type: Types.GET_HOSPITALS,
                                payload: {
                                    hospitals: []
                                }
                            });
                        }

                        // message.error(error.response.data.message[0].messages[0].message);
                        return resolve(false);
                    }
                });
        }
    });
};
export const getHospitalById = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/hospitals/getHospitalDetail/${props.hospitalId}`)
            .then((res) => {
                if (res) {
                    console.log('in here', history);

                    dispatch({
                        type: Types.GET_SINGLE_HOSPITAL,
                        payload: {
                            hospitalSingle: res.data.hospitalSingle
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Hospital Data fetching failed');
                    history.push('/notFound');
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const createDoctor = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/createUser`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    dispatch({
                        type: Types.CREATE_DOCTOR,
                        payload: {
                            createdDoctor: res.data
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message
                            ? error.response.data.message
                            : 'Doctor creation failed'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getHospitalDoctors = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getHospitalDoctors`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    if (props.obj.hospitalSingleDoctors) {
                        console.log('hi', props.obj);
                        dispatch({
                            type: Types.GET_HOSPITAL_SINGLE_DOCTORS,
                            payload: {
                                doctors: res.data.doctors,
                                doctorsCount: res.data.doctorsCount
                            }
                        });
                    } else {
                        dispatch({
                            type: Types.GET_HOSPITAL_DOCTORS,
                            payload: {
                                doctors: res.data.doctors,
                                doctorsCount: res.data.doctorsCount
                            }
                        });
                    }

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Doctors retrieval failed');
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getHospitalMembers = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getHospitalMembers`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    console.log('res data', res.data);
                    if (props.obj.hospitalSingleDoctors) {
                        dispatch({
                            type: Types.GET_HOSPITAL_SINGLE_MEMBERS,
                            payload: {
                                members: res.data.members,
                                membersCount: res.data.membersCount
                            }
                        });
                    } else {
                        dispatch({
                            type: Types.GET_HOSPITAL_MEMBERS,
                            payload: {
                                members: res.data.members,
                                membersCount: res.data.membersCount
                            }
                        });
                    }

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Doctors retrieval failed');
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getHospitalSearchResults = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getHospitalSearchResults`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_HOSPITAL_SEARCH_RESULTS,
                        payload: {
                            hospitals: res.data.hospitals,
                            hospitalsCount: res.data.hospitalsCount
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Hospitals retrieval failed');
                    dispatch({
                        type: Types.GET_HOSPITAL_SEARCH_RESULTS,
                        payload: {
                            hospitals: [],
                            hospitalsCount: 0
                        }
                    });
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getHospitalStatCounts = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getHospitalStatCounts`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_HOSPITAL_STAT_COUNTS,
                        payload: {
                            counts: res.data.counts
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getExtractedTimeSlots = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getExtractedTimeSlots`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_HOSPITAL_EXTRACTED_TIME_SLOTS,
                        payload: {
                            availableDays: res.data.availableDays,
                            timeSlots: res.data.timeSlots,
                            hospitalAvailability: res.data.hospitalAvailability
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    // message.error(error.response.data.message? error.response.data.message : "Server error");
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getMyDoctorTimeSlots = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getExtractedTimeSlots`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_MY_DOCTOR_TIME_SLOTS,
                        payload: {
                            timeSlots: res.data.timeSlots,
                            doctorAvailability: res.data.doctorAvailability
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    // message.error(error.response.data.message? error.response.data.message : "Server error");
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getHospitalForAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getHospitalForAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_HOSPITAL_FOR_APPOINTMENT,
                        payload: {
                            hospital: res.data.hospital
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getShortDoctorList = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getShortDoctorList`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_SHORT_DOCTOR_LIST,
                        payload: {
                            doctors: res.data.doctors
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getHospitalDashboardInfo = (props, history) => async (dispatch) => {
    try {
        const response = await Axios.get(
            `${REACT_APP_API_URI}/api/hospitals/getHospitalDashboard`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.auth_token
                }
            }
        );
        dispatch({
            type: Types.GET_HOSPITAL_DASHBOARD,
            payload: {
                dashboard: response.data.data
            }
        });
        return true;
    } catch (error) {
        if (error && error.response) {
            console.log(error.response.data);
            message.error(
                error.response.data.message ? error.response.data.message : 'Server error'
            );
            // message.error(error.response.data.message[0].messages[0].message);
            return false;
        }
    }
};

export const getLineChartData = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getLineChartData`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_LINE_CHART_DATA,
                        payload: {
                            lineChart: res.data.lineChartData
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    dispatch({
                        type: Types.GET_LINE_CHART_DATA,
                        payload: {
                            lineChart: null
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getPieChartData = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getPieChartData`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_PIE_CHART_DATA,
                        payload: {
                            pieChart: res.data.pieChartData
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    dispatch({
                        type: Types.GET_PIE_CHART_DATA,
                        payload: {
                            pieChart: []
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getOrganizationMatch = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        let url = `${REACT_APP_API_URI}/api/hospitals/getOrganizationMatch`;
        if (props && props.search) {
            url += `?search=${props.search}`;
        }
        if (props && props.country) {
            if (props.search) {
                url += `&`;
            } else {
                url += `?`;
            }
            url += `country=${props.country}`;
        }
        Axios.get(url, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_ORGANIZATION_MATCH,
                        payload: {
                            organizationList: res.data.organizations
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    dispatch({
                        type: Types.GET_ORGANIZATION_MATCH,
                        payload: {
                            organizationList: []
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const sendSignUpRequest = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/sendSignUpRequest`, props, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: null
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data.message
                        }
                    });
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};
