import React from 'react';
import { Row, Col, Input, Select, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const sortby = ['Highest Rated', 'Newest Rated', 'Newest', 'Oldest'];
const sortOrder = ['Ascending', 'Descending'];

const MEMBER_TYPES = ['Doctor', 'Consultant', 'Nurse', 'Caregiver', 'Therapist'];

const MemberHeader = ({ setCreateDoctorModal, memberType, onMemberChange, auth }) => {
    const { user } = auth;

    const getMemberTypes = () => {
        if (!user?.scope) {
            return MEMBER_TYPES;
        }
        else if(user.scope === 'ALL'){
            return MEMBER_TYPES;
        }
        return MEMBER_TYPES.filter((item) => user.scope === item.toUpperCase());
    };
    const memberTypes = getMemberTypes();


    return (
        <Row gutter={[10, 10]} style={{ marginBottom: 20, justifyContent: 'flex-end' }}>
            {' '}
            
            <Col xs={24} lg={6}>
                <Input placeholder='Search by Doctor name, email or phone' />
            </Col>
            <Col xs={24} lg={4}>
                <Select placeholder='Sort By' showSearch style={{ width: '100%' }}>
                    {sortby.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} lg={4}>
                <Select placeholder='Sort Order' showSearch style={{ width: '100%' }}>
                    {sortOrder.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} lg={4}>
                <Select
                    placeholder='Select Member Type'
                    value={memberType}
                    showSearch
                    style={{ width: '100%' }}
                    onChange={onMemberChange}
                >
                    {memberTypes.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}s
                        </Option>
                    ))}
                </Select>
            </Col>
            <Col xs={24} lg={6}>
                <Button
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                    type='primary'
                    onClick={() => setCreateDoctorModal(true)}
                >
                    New {memberType}
                </Button>
            </Col>
        </Row>
    );
};

export default MemberHeader;
