import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

function UnlockDashboardMenu(props) {
    const { approvedBranches, setApprovedBranches } = props;
    const { user } = useSelector((state) => state.auth);
    const { userType, branches } = user;

    console.log();
    useEffect(() => {
        let minFields = false;

        if (userType && userType == "HOSPITAL") {
            if (branches && branches.length !== 0) {
                let branchitem = branches[0];
                if (branchitem && branchitem.minFieldsCompleted) {
                    minFields = true;
                }
                if (minFields) {
                    setApprovedBranches(true);
                } else {
                    setApprovedBranches(false);
                }
            }
        }

        if (userType && userType == "DOCTOR") {
            if (
                user &&
                user.firstName &&
                user.lastName &&
                user.gender &&
                user.dob &&
                user.language &&
                user.profileSummary &&
                user.currency
            ) {
                setApprovedBranches(true);
            } else {
                setApprovedBranches(false);
            }
        }

        if (userType && userType == "PATIENT") {
            if (
                user &&
                user.firstName &&
                user.lastName &&
                user.gender &&
                user.dob &&
                user.language &&
                user.contact &&
                user.contact.billing &&
                user.contact.billing.billingAddress &&
                user.contact.billing.billingCountry &&
                user.contact.billing.billingCity &&
                user.contact.billing.billingState &&
                user.contact.billing.billingZip
            ) {
                console.log('approved');
                setApprovedBranches(true);
            } else {
                console.log('rejected');
                setApprovedBranches(false);
            }
        }
    }, [branches, user]);
    return <div></div>;
}

export default UnlockDashboardMenu;
