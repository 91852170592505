module.exports = {
    coordinates:
        localStorage.getItem('chosenLat') && localStorage.getItem('chosenLng')
            ? [localStorage.getItem('chosenLng'), localStorage.getItem('chosenLat')]
            : localStorage.getItem('lat') && localStorage.getItem('lng')
            ? [localStorage.getItem('lng'), localStorage.getItem('lat')]
            : [],

    country: localStorage.getItem('chosenCountry')
        ? localStorage.getItem('chosenCountry')
        : localStorage.getItem('localityCountry')
        ? localStorage.getItem('localityCountry')
        : null,

    city: localStorage.getItem('chosenLocality')
        ? localStorage.getItem('chosenLocality')
        : localStorage.getItem('locality')
        ? localStorage.getItem('locality')
        : null
};
