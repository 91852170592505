import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DatePicker, Select, Row, Col, Tag, Button, Input } from 'antd';
import { PlusOutlined, ReloadOutlined } from '@ant-design/icons';
import * as blogActions from '@/actions/blogActions';
import { useSelector, useDispatch } from 'react-redux';
import { specializationAreas } from '@/assets/json/DoctorPracticeAreas';
import Table from '../Table';
import ConfirmModal from '@/components/modals/ConfirmModal';
import PageHeader from '../PageHeader';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import { blogSpecializationAreas } from '@/assets/json/DoctorPracticeAreas';

const sortby = [
    { name: 'Newest', value: 'Posted: Newest First' },
    { name: 'Oldest', value: 'Posted: Oldest First' },
    { name: 'Likes', value: 'Likes: Most Liked' },
    { name: 'Comments', value: 'Comments: Most Commented' }
];

const { Option } = Select;

const Blogs = (props) => {
    const { history } = props;

    const dispatch = useDispatch();
    const { user, userType } = useSelector((state) => state.auth);
    const blogs = useSelector((state) => state.blog);

    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const [posts, setPosts] = useState([]);
    const [title, setTitle] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [cats, setCats] = useState(null);
    const [sort, setSort] = useState(null);
    const [date, setDate] = useState(null);
    const [criteria, setCriteria] = useState('');
    const [disableInput, setDisableInput] = useState({
        title: false,
        category: false,
        date: false,
        sortby: false
    });

    const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
    const [itemToBeDeleted, setItemToBeDeleted] = useState(null);

    const deletePost = async (post) => {
        let obj = {
            blogId: post._id
        };

        let deletedPost = dispatch(blogActions.deletePost({ ...props, obj }, history));
        if (deletedPost) {
            message.success('Post Successfully deleted');
        }
        setItemToBeDeleted(null);
        setConfirmDeleteModal(false);
    };
    const route =
        userType && userType == 'HOSPITAL'
            ? 'hospital'
            : userType == 'DOCTOR'
            ? 'doctor'
            : userType == 'NURSE'
            ? 'nurse'
            : userType == 'PATIENT'
            ? 'patient'
            : userType == 'CONSULTANT'
            ? 'consultant'
            : userType == 'PHARMACY' && 'pharmacy';
    const getSinglePostLink = (id) => {
        return `/${route}/blogs/${id}`;
    };

    const createPostLink =
        userType && userType == 'HOSPITAL'
            ? '/hospital/blogs/createblog'
            : userType == 'DOCTOR'
            ? '/doctor/blogs/createblog'
            : userType == 'NURSE'
            ? '/nurse/blogs/createblog'
            : userType == 'PATIENT'
            ? '/patient/blogs/createblog'
            : userType == 'CONSULTANT'
            ? '/consultant/blogs/createblog'
            : userType == 'PHARMACY' && '/pharmacy/blogs/createblog';

    const columns = [
        {
            title: <span className='dragHandler'>Date</span>,
            width: 100,
            dataIndex: 'createdDate',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.createdDate) return -1; // Handle empty values
                if (!b.createdDate) return 1; // Handle empty values
                return a.createdDate.localeCompare(b.createdDate);
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {new Date(date).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </div>
                );
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Time</span>,
            dataIndex: 'createdDate',
            width: 100,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.createdDate) return -1; // Handle empty values
                if (!b.createdDate) return 1; // Handle empty values
                return a.createdDate.localeCompare(b.createdDate);
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {new Date(date).toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric'
                        })}
                    </div>
                );
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Title</span>,
            width: 500,
            dataIndex: 'title',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.title) return -1; // Handle empty values
                if (!b.title) return 1; // Handle empty values
                return a.title.localeCompare(b.title);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 300 }
                };
            },
            render: (text, record) => {
                return <Link to={getSinglePostLink(record._id)}>{record.title}</Link>;
            }
        },
        {
            title: <span className='dragHandler'>Country</span>,
            width: 150,
            dataIndex: 'country',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.country) return -1; // Handle empty values
                if (!b.country) return 1; // Handle empty values
                return a.country.localeCompare(b.country);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Area of Specialization</span>,
            width: 300,
            dataIndex: 'category',
            sorter: (a, b) => a.title.localeCompare(b.title),
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'wrap', minWidth: 300 }
                };
            },
            render: (array, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div style={{ display: 'flex', gap: 5, flexWrap: 'wrap' }}>
                        {array?.map((item) => {
                            return (
                                <Tag
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        history.push({
                                            pathname: `/blogs/search`,
                                            search: `?category=${item}`
                                        })
                                    }
                                >
                                    {item}
                                </Tag>
                            );
                        })}
                    </div>
                );
            }
        },

        {
            title: <span className='dragHandler'>Status</span>,
            width: 150,
            dataIndex: 'status',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.status) return -1; // Handle empty values
                if (!b.status) return 1; // Handle empty values
                return a.status.localeCompare(b.status);
            },
            render: (text, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <Tag color='#87d068'>{text}</Tag>;
            }
        },
        {
            title: <span className='dragHandler'>Action</span>,
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;

                return (
                    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                        <Button
                            onClick={() => {
                                setItemToBeDeleted(record._id);
                                setConfirmDeleteModal(true);
                            }}
                            type='default'
                            size='small'
                        >
                            Delete
                        </Button>
                        <Link to={`/${route}/blogs/edit/${record._id}`}>
                            <Button size='small' type='link' style={{ color: '#000' }}>
                                Edit
                            </Button>
                        </Link>
                    </div>
                );
            }
        }
    ];

    useEffect(() => {
        getUserPosts();
    }, [cats, date, page, limit, sort, searchTerm]);

    const getUserPosts = async () => {
        let obj = {
            category: cats,
            date,
            skip: 0,
            page: page,
            perPage: limit,
            userId: user._id,
            userType: userType,
            lawfirm: user.lawfirm?._id ? user.lawfirm._id : user.lawfirm,
            sortBy: sort,
            search: searchTerm
        };

        if (searchTerm) {
            obj.search = searchTerm;
        }
        dispatch(blogActions.getUserPosts({ obj, ...props }));
    };

    const handleSearchInput = (e, date, name) => {
        if (e && name && date) {
            if (name === 'search') {
                if (e.target.value) {
                    setSearchTerm(e.target.value);
                } else {
                    setSearchTerm('');
                }
            }
            if (name === 'cats') {
                setCats(e);
            }
            if (name === 'sort') {
                setSort(e);
            }
            if (name === 'date') {
                setDate(e);
            }
        }
    };

    useEffect(() => {
        if (blogs.posts) {
            let bPosts = [];
            bPosts = [...blogs.posts];
            if (criteria && criteria === 'Newest') {
                setPosts(bPosts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
                return;
            }
            if (criteria === 'Like') {
                setPosts(bPosts.sort((a, b) => b.likes.length - a.likes.length));
                return;
            }
            if (criteria === 'Comments') {
                setPosts(bPosts.sort((a, b) => b.comments.length - a.comments.length));
                return;
            }
            setPosts(bPosts);
        } else {
            setPosts([]);
        }
    }, [blogs, criteria]);

    const handleReset = () => {
        setSearchTerm('');
        setCriteria('');
        setTitle('');
        setDisableInput({
            ...disableInput,
            title: false,
            category: false,
            date: false,
            sortby: false
        });
        setDate(null);
        setCats(null);
        setSort(null);
    };
    let { postsCount } = blogs;
    const numberOfBlankRows = limit - posts.length;
    let dataSource = [...posts, ...Array.from({ length: numberOfBlankRows }).map(() => ({}))];

    return (
        <div>
            <PageHeader
                heading='Blogs'
                actions={() => (
                    <>
                        <Button icon={<ReloadOutlined />} type='default' onClick={handleReset}>
                            Reset Filters
                        </Button>
                        <Button
                            icon={<PlusOutlined />}
                            type='primary'
                            onClick={() => history.push(createPostLink)}
                        >
                            Add Blog
                        </Button>
                    </>
                )}
            />
            <Row gutter={[20, 20]} style={{ marginBottom: 20 }}>
                <Col flex={1} xs='24' lg={6}>
                    <Input
                        disabled={disableInput.title}
                        name='title'
                        type='text'
                        placeholder='Search'
                        value={searchTerm}
                        onChange={(e) => handleSearchInput(e, 'something', 'search')}
                    />
                </Col>
                <Col flex={1} xs='24' lg={6}>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder='Area of Specialization'
                        onChange={(e, value) => handleSearchInput(e, value, 'cats')}
                        value={cats}
                        disabled={disableInput.category}
                    >
                        {blogSpecializationAreas.map((item, index) => (
                            <Option key={index} value={item}>
                                {item}
                            </Option>
                        ))}
                    </Select>{' '}
                </Col>
                <Col flex={1} xs='24' lg={6}>
                    <Select
                        showSearch
                        style={{ width: '100%' }}
                        placeholder='Sort by'
                        disabled={disableInput.sortby}
                        onChange={(e, value) => handleSearchInput(e, value, 'sort')}
                        value={sort}
                    >
                        {sortby.map((item, index) => (
                            <Option key={index} value={item.name}>
                                {item.value}
                            </Option>
                        ))}
                    </Select>{' '}
                </Col>
                <Col flex={1} xs='24' lg={6}>
                    <DatePicker
                        name='dob'
                        value={date}
                        placeholder='Search by date'
                        onChange={(e, value) => setDate(e)}
                        style={{ width: '100%', borderRadius: '4px' }}
                        disabled={disableInput.date}
                    />{' '}
                </Col>
            </Row>

            <Table
                tableName={'Blogs'}
                columns={columns}
                data={dataSource}
                pagination={{
                    style: { marginTop: 20 },
                    current: page,
                    total: postsCount,
                    onChange: (val) => setPage(val),
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20],
                    showSizeChanger: true,
                    responsive: true,
                    onShowSizeChange: (size, newSize) => setLimit(newSize)
                }}
            />
            {confirmDeleteModal && (
                <ConfirmModal
                    {...props}
                    onClose={() => setConfirmDeleteModal(false)}
                    acceptMethod={() => deletePost(itemToBeDeleted)}
                    headerText='Delete Blog post'
                    bodyText='Are you sure you want to delete this post?'
                />
            )}
        </div>
    );
};

export default Blogs;
