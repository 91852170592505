import React, { useEffect, useState } from 'react';
import { Line, Area, Column } from '@ant-design/charts';
import { Pie } from '@ant-design/plots';
import * as hospitalActions from '@/actions/hospitalActions';
import * as doctorActions from '@/actions/doctorActions';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Select } from 'antd';
const { Option } = Select;

function DataVisualization(props) {
    const dispatch = useDispatch();
    const { weeklyStatistics } = useSelector((state) => state.doctors);
    const [lineData, setLineData] = useState([]);
    const [pieData, setPieData] = useState([]);
    const [earningTimeline, setEarningTimeline] = useState('Last Week');
    const [currentYearPieChart, setCurrentYearPieChart] = useState(moment().year());

    const getWeeklyStatistics = async () => {
        let obj = {
            earningTimeline: earningTimeline,
            weekStart: '',
            weekEnd: ''
        };
        let res = await dispatch(doctorActions.getWeeklyStatistics({ ...props, obj }));
    };

    let lastWeek = [
        moment().startOf('D').subtract(6, 'days').toISOString(),
        moment().startOf('D').subtract(5, 'days').toISOString(),
        moment().startOf('D').subtract(4, 'days').toISOString(),
        moment().startOf('D').subtract(3, 'days').toISOString(),
        moment().startOf('D').subtract(2, 'days').toISOString(),
        moment().startOf('D').subtract(1, 'days').toISOString(),
        moment().startOf('D').toISOString()
    ];

    // const getPieChartData = async () => {
    //   let obj = {
    //     year: currentYearPieChart
    //   }
    //   let res = await dispatch(hospitalActions.getPieChartData({ ...props, obj }))
    // }

    useEffect(() => {
        getWeeklyStatistics();
    }, [earningTimeline]);

    // useEffect(() => {
    //   getPieChartData()
    // }, [currentYearPieChart])

    // useEffect(() => {
    //   if (lineChart) {
    //     setLineData(
    //       lineChart?.monthly?.map(item => {
    //         return {
    //           month: getMonth(item.month),
    //           value: item.daily.length
    //         }
    //       })
    //     )
    //   } else {
    //     setLineData([])
    //   }
    // }, [lineChart])

    useEffect(() => {
        if (weeklyStatistics) {
            let fillData = lastWeek;
            for (let i = 0; i < weeklyStatistics?.length; i++) {
                let it = weeklyStatistics[i];
                console.log(
                    moment(it.appointmentDate).startOf('D').toISOString(),
                    fillData.includes(moment(it.appointmentDate).startOf('D').toISOString())
                );
                if (fillData.includes(moment(it.appointmentDate).startOf('D').toISOString())) {
                    fillData = fillData.filter(
                        (item) => item != moment(it.appointmentDate).startOf('D').toISOString()
                    );
                }
            }
            console.log(
                fillData?.map((item) => {
                    return {
                        amount: 0,
                        date: moment(item).format('DD-MM-YYYY')
                    };
                })
            );
            let data = [
                ...weeklyStatistics?.map((item) => {
                    return {
                        amount: item.service,
                        date: moment(item.appointmentDate).startOf('D').format('DD-MM-YYYY')
                    };
                }),
                ...fillData?.map((item) => {
                    return {
                        amount: 0,
                        date: moment(item).format('DD-MM-YYYY')
                    };
                })
            ].sort((a, b) => {
                return moment(a.date).isBefore(moment(b.date))
                    ? -1
                    : moment(a.date).isAfter(moment(b.date))
                    ? 1
                    : 0;
            });

            setLineData(data);
        } else {
            setLineData([]);
        }
    }, [weeklyStatistics]);
    // useEffect(() => {
    //   if (pieChart) {
    //     setPieData(
    //       pieChart?.map(item => {
    //         return {
    //           type: item.doctorName,
    //           value: item.totalAppointments
    //         }
    //       })
    //     )
    //   } else {
    //     setPieData([])
    //   }
    // }, [pieChart])

    const lineConfig = {
        data: lineData,
        height: 400,
        xField: 'date',
        yField: 'amount',
        // legend: true,
        xAxis: {
            visible: true,
            title: {
                text: `Week`
            }
        },
        yAxis: {
            visible: true,
            title: {
                text: 'Earnings from services'
            }
        },

        title: {
            visible: true,
            text: 'Your Stats'
        },
        point: {
            size: 5,
            shape: 'diamond'
        }
    };

    const pieChartData = [
        {
            type: 'Test Doctor',
            value: 27
        },
        {
            type: 'Test Doctor 2',
            value: 35
        },
        {
            type: 'Waheed Ahmed',
            value: 18
        },
        {
            type: 'Saimoon Bhuiyan',
            value: 15
        },
        {
            type: 'Nabil Ahmed',
            value: 10
        },
        {
            type: 'Maruf Ahmed',
            value: 5
        }
    ];
    // const pieConfig = {
    //   appendPadding: 10,
    //   data: pieData,
    //   angleField: 'value',
    //   colorField: 'type',
    //   radius: 0.9,
    //   title: {
    //     visible: true,
    //     text: 'Your Stats'
    //   },
    //   label: {
    //     type: 'inner',
    //     offset: '-30%',
    //     content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
    //     style: {
    //       fontSize: 14,
    //       textAlign: 'center'
    //     }
    //   },
    //   interactions: [
    //     {
    //       type: 'element-active'
    //     }
    //   ]
    // }
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div className='app-headings'>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start'
                    }}
                >
                    <p style={{ fontSize: '2rem' }}>Data Visualization </p>
                </div>
            </div>
            <div style={{ display: 'flex', columnGap: 20, rowGap: 20, flexWrap: 'wrap', backgroundColor: 'white' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        rowGap: 10,
                        width: '100%',
                        minWidth: 350,
                        flex: 1,
                        //   boxShadow: '0px 6px 20px rgba(0,0,0,0.1)',
                        padding: '20px',
                        border: '1px solid #ddd'
                    }}
                >
                    <h4 style={{ fontWeight: 400, fontSize: 18 }}>
                        Total Earnings from appointments
                    </h4>
                    <label style={{ display: 'flex', flexDirection: 'column' }}>
                        Choose Year
                        <Select
                            style={{ width: '100% !important' }}
                            value={earningTimeline}
                            onChange={(val) => setEarningTimeline(val)}
                        >
                            <Option value={'Last Week'}> Last Week </Option>
                            <Option value={'Last Month'}> Last Month </Option>
                            <Option value={'All'}> All </Option>
                        </Select>
                    </label>

                    <Column {...lineConfig} />
                </div>
                {/* 
        <div
          style={{
            width: '100%',
            minWidth: 350,
            flex: 1,
            justifyContent: 'center',
            //   boxShadow: '0px 6px 20px rgba(0,0,0,0.1)',
            padding: '20px',
            border: '1px solid #ddd'
          }}
        >
          <Pie {...pieConfig} />
        </div> */}
            </div>
        </div>
    );
}
export default DataVisualization;
