import { Calendar } from 'antd';
import React from 'react';


const ProfileCalendar = () => {
    return (
        <div>
            <div className='sectionTitle' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0.4rem 1rem'}}>
                <h4 style={{ fontSize: 14, marginBottom: 0, fontWeight:400 }}>Calendar</h4>
              
            </div>

            <div
                className='sectionContainer'
                style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
            >
                <Calendar
                    fullscreen={false}
                    style={{ width: '100%' }}
                    // onPanelChange={onPanelChange}
                    // onChange={onPanelChange}
                    // value={value}
                    // onSelect={onSelect}
                    // onPanelChange={onPanelChange}
                    // dateCellRender={dateCellRender}
                    // monthCellRender={monthCellRender}
                />
            </div>
        </div>
    );
};

export default ProfileCalendar;
