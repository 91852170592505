import React, { memo } from 'react';

const DocumentsType = ({ document, remainingFiles, totalFiles, title, description }) => {
    return (
        <div className='order-required'>
            <div className='order-required-first'>
                <p>{title ?? `${document} Documents`}</p>
                {description && <p>{description}</p>}
            </div>

            <div className='order-required-second'>
                <p>
                    {remainingFiles} out of {totalFiles} files {document}
                </p>
            </div>
        </div>
    );
};

export default memo(DocumentsType);
