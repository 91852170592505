import React, { useEffect, useState } from 'react';
import * as userActions from '@/actions/userActions';
import { useSelector, useDispatch } from 'react-redux';
import '../../../HomePage/popularDoctor.css';
import { Button, Modal } from 'antd';
import Table from '../Table';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import {Link} from 'react-router-dom'

const Consultants = (props) => {
    const { limit, page } = props;
    const [messageModal, setMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const [messageDoctor, setMessageDoctor] = useState(null);
    const [messageError, setMessageError] = useState('');
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const {
        favourites: { consultants }
    } = auth;

    const getConsultants = () => {
        let obj = {
            favouriteType: 'Consultants',
            userId: auth.userId,
            perPage: limit,
            page: page
        };
        dispatch(userActions.getFavourites({ ...props, obj }));
    };

    useEffect(() => {
        getConsultants();
    }, [limit, page]);

    useEffect(() => {
        if (!messageModal) {
            setMessageDoctor(false);
        }
    }, messageModal);

    const sendMessage = async (consultant) => {
        if (!message) {
            setMessageError('Message field cannot be empty');
        } else {
            setMessageError('');
            let obj = {
                message: message,
                receiver: messageDoctor && messageDoctor._id && messageDoctor._id,
                receiverType: messageDoctor && messageDoctor.userType && messageDoctor.userType
            };
            let sendMessage = await props.messagingActions.createConversation(
                { ...props, obj },
                props.history
            );
            if (sendMessage) {
                setMessageModal(false);
                setMessage('');
            } else {
                console.log('error');
            }
        }
    };

    const removeFromFavourites = async (id) => {
        let obj = {
            favouriteId: id
        };
        let removeFavItem = await dispatch(
            userActions.removeFromFavourites({ obj, ...props }, props.history)
        );
        if (removeFavItem) {
            getConsultants();
        }
    };

    const columns = [
        {
            title: <span className="dragHandler">consultant</span>,
            width: 500,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.consultant) return -1; // Handle empty values
                if (!b.consultant) return 1; // Handle empty values
                return a.consultant.localeCompare(b.consultant);
            },
            render: (consultantObj, record) => {
                if (record == {} || !consultantObj.firstName) return <div>{''}</div>;
                let consultant = consultantObj.firstName + ' ' + consultantObj.lastName;
                return <Link to={`/consultant-view/${consultantObj._id}`}><div>{consultant}</div></Link>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            }
        },
      
        {
            title: <span className="dragHandler">Email</span>,
            width: 200,
            dataIndex: 'email',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.email) return -1; // Handle empty values
                if (!b.email) return 1; // Handle empty values
                return a.email.localeCompare(b.email);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            },
            render: (email, record) => {
                if (isEmptyObject(record)) return <div></div>;
                return <div>{email}</div>;
            }
        },
        {
            title: <span className="dragHandler">Phone No.</span>,
            width: 200,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.phoneNo) return -1; // Handle empty values
                if (!b.phoneNo) return 1; // Handle empty values
                return a.phoneNo.localeCompare(b.phoneNo);
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div></div>;
                if (!record.phoneNo) return <div>Not available</div>;
                return <div>{'(' + record.countryCode + ') -' + record.phoneNo}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            }
        },
        {
            title: <span className="dragHandler">Action</span>,
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            },
            render: (data, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        <Button type='default' onClick={() => removeFromFavourites(record._id)}>
                            Delete
                        </Button>
                    </div>
                );
            }
        }
    ];

    const numberOfBlankRows = limit - (consultants?.length || 0);
    let dataSource = [
        ...consultants?.map((item) => item.favouriteItem),
        ...Array.from({ length: numberOfBlankRows }).map(() => ({}))
    ];

    return (
        <>
            <Table tableName={'Favourite Consultants'} columns={columns} data={dataSource} />
            <Modal
                className='addReview'
                title='Send a message'
                open={messageModal}
                onCancel={() => {
                    setMessageModal(false);
                }}
                footer={null}
            >
                <label>
                    <span>Message</span>
                    <textarea
                        placeholder='Description'
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                    />
                    <p className='error'>{messageError && messageError}</p>
                </label>
                <Button type="primary" onClick={() => sendMessage()}>
                    Send Message
                </Button>
            </Modal>
        </>
    );
};

export default Consultants;
