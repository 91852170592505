import React from 'react';
import Blogs from './Tabs/Blogs';
import Reviews from './Tabs/Reviews';
import General from './Tabs/General';
import './list.css';
const List = (props) => {
    return (
        <div className='hospital-view-list'>
            {props.tab === 'reviews' ? (
                <Reviews {...props} />
            ) : props.tab === 'blogs' ? (
                <Blogs {...props} />
            ) : props.tab === 'general' ? (
                <General {...props} />
            ) : null}
        </div>
    );
};

export default List;
