import React from 'react';
import NavigationPrompt from 'react-router-navigation-prompt';
import ReactDOM from 'react-dom';

export default function NavPrompt({ formDirty }) {
    return (
        <NavigationPrompt when={formDirty}>
            {({ onConfirm, onCancel }) => (
                <>
                    {formDirty &&
                        ReactDOM.createPortal(
                            <div className='modal-overlay nav-prompt'>
                                <div className='form-modal'>
                                    <div className='modal-title'>
                                        <p>Your Profile is not saved yet</p>
                                    </div>
                                    <div className='modal-content'>
                                        <p>
                                            {' '}
                                            You need to save your unsaved changes, do you really
                                            want to leave without saving your changes?
                                        </p>
                                    </div>
                                    <div className='modal-actions'>
                                        <button
                                            className='btn--pink-outlined'
                                            style={{
                                                borderRadius: 8
                                            }}
                                            color='primary'
                                            onClick={onCancel}
                                        >
                                            No
                                        </button>
                                        <button
                                            className='btn--pink'
                                            style={{
                                                borderRadius: 8 // backgroundColor: "rgb(255,114,69)",
                                            }}
                                            color='primary'
                                            autoFocus
                                            onClick={onConfirm}
                                        >
                                            Yes
                                        </button>
                                    </div>
                                </div>
                            </div>,
                            document.querySelector('#modal-root')
                        )}
                </>
            )}
        </NavigationPrompt>
    );
}
