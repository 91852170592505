import React, { useRef, useState, useEffect } from 'react';
import { Modal, Select, Radio, Button } from 'antd';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { CgArrowLongRight } from 'react-icons/cg';
import { BiPlus, BiMinus } from 'react-icons/bi';
import '@/assets/css/wizard.css';

const ChangeSubscription = (props) => {
    const {
        setSubscriptionChangeModal,
        subscriptionChangeModal,
        productTypes,
        chosenPackage,
        setChosenPackage,
        billingType,
        setBillingType,
        yearlyBill,
        setYearlyBill,
        changeSubscription,
        featured,
        setFeatured,
        user
    } = props;
    const getPackageDetails = (selectedPackage) => {
        let packageDetails = productTypes.filter((item) => item.productName == selectedPackage)[0];
        return packageDetails;
    };
    let packages = productTypes.filter((item, index) => item.baseSubscriptionPackage == true);

    useEffect(() => {
        let chosenPackagePrice = parseFloat(
            packages.filter((item) => item.productName == chosenPackage)[0]?.productPrice
        );

        let featuredConsultantPackagePrice = featured
            ? parseFloat(
                  productTypes?.filter((item) => item.productName == 'Featured Consultant')[0]
                      ?.productPrice
              )
            : 0;

        let monthlyPrice = chosenPackagePrice + featuredConsultantPackagePrice * (featured ? 1 : 0);
        let yearlyPrice = monthlyPrice * 12;
        setYearlyBill(yearlyPrice);
    }, [featured, chosenPackage]);

    let featuredConsultantPrice = parseFloat(
        productTypes?.filter((item) => item.productName == 'Featured Consultant')[0]?.productPrice
    );
    let featuredConsultantPackage = getPackageDetails('Featured Consultant');
    let chosenPackageDetails = getPackageDetails(chosenPackage);
    let chosenPackagePrice = parseFloat(chosenPackageDetails?.productPrice);

    return (
        <Modal
            className='menusModal changePmModal customModal'
            title='Change Subscription'
            open={subscriptionChangeModal}
            onOk={() => setSubscriptionChangeModal(false)}
            onCancel={() => setSubscriptionChangeModal(false)}
            footer={<></>}
            centered
        >
            <div>
                <div>
                    <h4 style={{ marginBottom: 20 }}>Choose Package</h4>
                    <div className='cardRow'>
                        {packages?.map((item, index) => (
                            <div
                                className={`${
                                    chosenPackage == item.productName ? 'active' : ''
                                }   card`}
                                onClick={(e) => setChosenPackage(item.productName)}
                            >
                                <h3>
                                    {item.productName} <span className='checkBox'></span>
                                </h3>
                                <small style={{ color: 'gray' }}>Flat monthly fee</small>
                                <ul>
                                    <li>
                                        {' '}
                                        <i className='fe fe-check' />
                                        {item.productName == 'Essentials'
                                            ? 'No commision'
                                            : 'Grow Your Business'}
                                    </li>

                                    <li>
                                        <i className='fe fe-check' />
                                        Your listing on Medasq
                                    </li>
                                    <li>
                                        <i className='fe fe-check' />
                                        Lowest industry plan
                                    </li>
                                    <li>
                                        {' '}
                                        <i className='fe fe-check' />
                                        {item.productName == 'Essentials'
                                            ? 'Access to one or more services'
                                            : 'Access to all services'}{' '}
                                    </li>
                                </ul>
                            </div>
                        ))}
                    </div>
                </div>

                <h4 style={{ marginBottom: 5 }}>Choose Billing cycle</h4>
                <small style={{ marginBottom: 20 }}>
                    Current Billing Cycle:{' '}
                    <strong>{billingType.slice(0, 1).toUpperCase() + billingType.slice(1)}</strong>
                </small>
                <div className='billingCycleCards'>
                    <div className='billinCyleCard'>
                        <div className={`active card`} onClick={(e) => setBillingType('yearly')}>
                            <div>
                                <ul>
                                    {featured && getPackageDetails('Featured Consultant') && (
                                        <li>
                                            <i className='fe fe-check' />
                                            {featuredConsultantPackage.productName} $
                                            {featuredConsultantPrice} * 12 = $
                                            {featuredConsultantPrice * 12}
                                        </li>
                                    )}
                                    <li>
                                        <i className='fe fe-check' />
                                        {chosenPackage} ${chosenPackagePrice} * 12 = $
                                        {chosenPackagePrice * 12}
                                    </li>
                                </ul>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center'
                                }}
                            >
                                <h3 style={{ margin: 0, padding: 0 }}>
                                    {'$' + yearlyBill}/Yearly{' '}
                                </h3>
                                <small>Billed annually</small>
                            </div>
                            <span className='checkBox'></span>
                        </div>
                    </div>
                    <div className='billinCyleCard'>
                        <div className={`card`}>
                            <div>Free Trial (30 Days)</div>

                            <span className='checkBox'></span>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <h4 style={{ marginBottom: 20 }}>Featured Consultant</h4>
                <div
                    style={{
                        rowGap: 10,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        fontSize: 14, marginBottom: 20
                    }}
                >
                    Featured Consultant:
                    <Radio.Group
                        style={{ width: 'fit-content' }}
                        onChange={(e) => {
                            setFeatured(e.target.value);
                        }}
                        value={featured}
                    >
                        <Radio value={true}>Yes</Radio>
                        <Radio value={false}>No</Radio>
                    </Radio.Group>
                    <p
                        style={{
                            fontSize: 11,
                            backgroundColor: 'var(--tertiary)',
                            color: '#fff',
                            padding: '5px 10px',
                            borderRadius: 4
                        }}
                    >
                        {' '}
                        <i className='fe fe-alert-triangle' />{' '}
                        {`Setting featured true will add $${featuredConsultantPrice} to the monthly bill and $${
                            featuredConsultantPrice * 12
                        } to the yearly bill`}
                    </p>
                </div>

                <Button
                    type='primary'
                    onClick={(e) => {
                        changeSubscription();
                    }}
                    style={{ margin: 'auto' }}
                >
                    Finish <i className='fe fe-chevron-right' />
                </Button>
            </div>
        </Modal>
    );
};

export default ChangeSubscription;
