import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '@/actions/adminActions';

function Products(props) {
    const [stripeProducts, setStripeProducts] = useState();
    useEffect(() => {
        props.adminActions.getStripeProducts(props, props.history);
        return () => {};
    }, []);

    useEffect(() => {
        if (props.admin && props.admin.stripeProducts && props.admin.stripeProducts.data) {
            setStripeProducts(props.admin.stripeProducts.data);
        }
    }, [props.admin.stripeProducts]);
    return (
        <div className='adminTable'>
            <h1>
                {' '}
                <i
                    style={{ cursor: 'pointer' }}
                    onClick={() => props.history.push('/admin/stripe')}
                    className='fe fe-chevron-left'
                />{' '}
                Products
            </h1>

            <div className='table'>
                <table>
                    <thead>
                        <tr className='tableHeader'>
                            <th>ID</th>
                            <th>NAME</th>
                            <th>DESCRIPTION</th>
                            <th>STATUS</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {stripeProducts &&
                            stripeProducts.map((item, index) => (
                                <tr className='breakrow'>
                                    <td>
                                        <div>{item.id}</div>
                                    </td>
                                    <td>
                                        <div>{item.name}</div>
                                    </td>
                                    <td>
                                        <div>{item.description}</div>
                                    </td>
                                    <td>
                                        <div></div>
                                    </td>
                                    <td>
                                        <div></div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        admin: state.admin
    };
}

function mapDispatchToProps(dispatch) {
    return {
        adminActions: bindActionCreators(adminActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Products);
