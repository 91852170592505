import { Button, Empty } from 'antd';
import React from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const AppointmentsToday = () => {
    const { dashboard } = useSelector((state) => state.doctors);
    const { appointmentsToday } = dashboard;
    return (
        <div>
           <div className='sectionTitle' style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '0.4rem 1rem'}}>
                <h4 style={{ fontSize: 14, marginBottom: 0, fontWeight: 400 }}>Today's Appointments</h4>
                <Link to='/doctor/appointments'>
                    <Button type='text' size="small">View All</Button>
                </Link>
            </div>

            <div
                className='sectionContainer'
                style={{ display: 'flex', flexDirection: 'column', gap: 10 }}
            >
                {appointmentsToday && appointmentsToday.length !== 0 ? (
                    appointmentsToday.map((appointment) => (
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 5,
                                padding: '5px 15px',
                                backgroundColor: 'white',
                                borderRadius: 4
                            }}
                        >
                            <div>
                                {userType === "DOCTOR"
                                    ? `Welcome, ${doctor.firstName} ${
                                          doctor.lastName
                                      }! You have an appointment today at ${DateTime.fromISO(
                                          appointment.time
                                      ).toLocaleString(DateTime.TIME_SIMPLE)}.`
                                    : userType === "PATIENT"
                                    ? `Welcome, ${user.firstName} ${
                                          user.lastName
                                      }! You have an appointment today at ${DateTime.fromISO(
                                          appointment.time
                                      ).toLocaleString(DateTime.TIME_SIMPLE)}.`
                                    : 'Unknown user type.'}
                            </div>
                            <small style={{ color: 'gray' }}>
                                {DateTime.fromISO(notification.date).toLocaleString(
                                    DateTime.DATETIME_FULL
                                )}
                            </small>
                        </div>
                    ))
                ) : (
                    <Empty description={'No Appointments today!'} />
                )}
            </div>
        </div>
    );
};

export default AppointmentsToday;
