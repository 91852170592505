import * as Types from '../actions/types';

const init = {
    branches: [],
    branchesCount: 0,
    singleBranch: {}
};

const branchReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_BRANCHES: {
            return {
                ...state,
                branches: action.payload.branches,
                branchesCount: action.payload.branchesCount
            };
        }

        case Types.LOGOUT: {
            return init
        }

        default:
            return state;
    }
};

export default branchReducer;
