import React from 'react';
import LocationDocs from './locationDocs';
const RequiredDocs = (props) => {
    return (
        <>
            {props.experiences &&
                props.experiences.map((item, index) => {
                    if (item.verificationStatus === props.type) {
                        return (
                            <LocationDocs
                                verif={'experiences'}
                                docs={item.document}
                                id={item._id}
                                key={index}
                                ind={index}
                                hospitalName={item.organization}
                                type={props.type}
                                css={props.type}
                                proof={'Experience'}
                                {...props}
                            />
                        );
                    } else {
                        return null;
                    }
                })}
            {props.educations &&
                props.educations.map((item, index) => {
                    if (item.verificationStatus === props.type) {
                        return (
                            <LocationDocs
                                verif={'educations'}
                                docs={item.document}
                                id={item._id}
                                key={index}
                                hospitalName={item.school}
                                type={props.type}
                                css={props.type}
                                proof={'Education'}
                                {...props}
                            />
                        );
                    } else {
                        return null;
                    }
                })}
            {props.associations &&
                props.associations.map((item, index) => {
                    if (item.verificationStatus === props.type) {
                        return (
                            <LocationDocs
                                verif={'associations'}
                                docs={item.document}
                                id={item._id}
                                key={index}
                                hospitalName={item.organization}
                                type={props.type}
                                css={props.type}
                                proof={'Association'}
                                {...props}
                            />
                        );
                    } else {
                        return null;
                    }
                })}
            {props.honors &&
                props.honors.map((item, index) => {
                    if (item.verificationStatus === props.type) {
                        return (
                            <LocationDocs
                                verif={'honors'}
                                docs={item.document}
                                id={item._id}
                                key={index}
                                hospitalName={item.organization}
                                type={props.type}
                                css={props.type}
                                proof={'Honors & Awards'}
                                {...props}
                            />
                        );
                    } else {
                        return null;
                    }
                })}
            {props.admissions &&
                props.admissions.map((item, index) => {
                    if (item.verificationStatus === props.type) {
                        return (
                            <LocationDocs
                                verif={'admissions'}
                                docs={item.document}
                                id={item._id}
                                key={index}
                                hospitalName={item.state}
                                type={props.type}
                                css={props.type}
                                proof={'Admission'}
                                {...props}
                            />
                        );
                    } else {
                        return null;
                    }
                })}
        </>
    );
};

export default RequiredDocs;
