import { combineReducers } from 'redux';
import auth from './authReducer';
import admin from './adminReducer';
import blog from './blogReducer';
import messaging from './messagingReducer';
import hospital from './hospitalReducer';
import doctors from './doctorReducer';
import marketing from './marketingReducer';
import reviews from './reviewReducer';
import availability from './availabilityReducer';
import general from './generalReducer';
import prospect from './prospectReducer';
import branch from './branchReducer';
import documents from './documentReducer';
import appointments from './appointmentReducer';
import stripe from './stripeReducer';
import payments from './paymentReducer';
import nurse from './nurseReducer';
import consultant from './consultantReducer';
import pharmacy from './pharmacyReducer';
import therapist from './therapistReducer';
import caregiver from './caregiverReducer';

const rootReducer = combineReducers({
    auth,
    admin,
    blog,
    messaging,
    hospital,
    doctors,
    nurse,
    consultant,
    pharmacy,
    marketing,
    reviews,
    availability,
    general,
    prospect,
    documents,
    appointments,
    branch,
    stripe,
    payments,
    therapist,
    caregiver
});

export default rootReducer;
