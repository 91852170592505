import React, { useEffect, useState } from 'react';
import '../../assets/css/orders.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '../../actions/adminActions';
import { Pagination } from 'antd';
import moment from 'moment';
import { Image, Menu, Dropdown } from 'antd';
import { Link } from 'react-router-dom';
import PlusIcon from '../../assets/img/svgs/plusIcon';
import ReportDescModal from './ReportDescModal';
import '../../assets/css/blogsv2.css';

function Blogs(props) {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [random, setRandom] = useState();
    useEffect(() => {
        let obj = {
            page: page,
            limit: limit
        };
        props.adminActions.getAllBlogsAdmin({ obj, ...props }, props.history);
    }, [page, limit]);

    const blogActionMenu = (props) => (
        <Menu>
            <Menu.Item>
                <Link to={`/admin/blogs/${props.item._id}/edit`}>Edit Post</Link>
            </Menu.Item>
            <Menu.Item>
                <a>Delete Post</a>
            </Menu.Item>
        </Menu>
    );

    const [reportModal, setReportModal] = useState(false);
    const [reportItem, setReportItem] = useState(null);

    const showReports = async (item) => {
        setReportItem(item);
        setReportModal(true);
    };

    const { blogs, allBlogsCount } = props.admin;
    return (
        <div className='adminTable'>
            <h1>
                Blogs{' '}
                <button onClick={() => props.history.push('/admin/blogs/create')}>
                    <PlusIcon />
                </button>
            </h1>

            <div className='table'>
                <table>
                    <thead>
                        <tr className='tableHeader'>
                            <th>COVER IMAGE</th>
                            <th>BLOG TITLE</th>
                            <th>AUTHOR NAME</th>
                            <th>REPORT COUNT</th>
                            <th>STATUS</th>
                            <th>CREATED DATE</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {blogs &&
                            blogs.map((item, index) => (
                                <tr className='breakrow'>
                                    <td>
                                        <div>
                                            {/* <p>{item.coverImage && item.coverImage}</p> */}
                                            <Image
                                                width={60}
                                                alt='Cover photo'
                                                src={item.coverImage && item.coverImage}
                                                preview={{
                                                    onVisibleChange: (visible, prevVisible) =>
                                                        console.log(visible)
                                                }}
                                            />
                                        </div>
                                    </td>
                                    <td>
                                        <div>{item.title && item.title}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.author && (
                                                <>
                                                    {item.author.firstName && item.author.firstName}{' '}
                                                    {item.author.lastName && item.author.lastName}{' '}
                                                </>
                                            )}
                                        </div>
                                    </td>
                                    <td onClick={() => showReports(item)}>
                                        <div>{item.reports && item.reports.length}</div>
                                    </td>
                                    <td>
                                        <div>{item.status && item.status}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.createdDate &&
                                                moment(item.createdDate).format('DD/MMM/YYYY')}
                                        </div>
                                    </td>
                                    <td>
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={() =>
                                                blogActionMenu({
                                                    item: item,
                                                    index: index
                                                })
                                            }
                                            placement='bottomLeft'
                                            arrow
                                        >
                                            <div className='three-dots'>
                                                <span></span>
                                            </div>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            {reportModal && (
                <ReportDescModal
                    reportModal={reportModal}
                    setReportModal={setReportModal}
                    reports={reportItem.reports}
                />
            )}
            <Pagination
                current={page}
                total={allBlogsCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        admin: state.admin
    };
}

function mapDispatchToProps(dispatch) {
    return {
        adminActions: bindActionCreators(adminActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
