export const THERAPIST_TYPES = [
    'Physical Therapist (PT)',
    'Occupational Therapist (OT)',
    'Speech-Language Therapist (SLP)',
    'Respiratory Therapist (RT)',
    'Rehabilitation Therapist',
    'Behavioral Therapist',
    'Cognitive Behavioral Therapist (CBT)',
    'Dialectical Behavior Therapist (DBT)',
    'Art Therapist',
    'Music Therapist',
    'Dance/Movement Therapist',
    'Drama Therapist',
    'Play Therapist',
    'Massage Therapist',
    'Acupuncturist',
    'Chiropractor',
    'Naturopathic Physician',
    'Physical Medicine and Rehabilitation Specialist (Physiatrist)',
    'Orthopedic Surgeon',
    'Pain Management Specialist',
    'Neurologist',
    'Psychiatrist',
    'Psychologist',
    'Counselor/Therapist',
    'Clinical Social Worker',
    'Geriatric Therapist',
    'Pediatric Therapist',
    'Adolescent Therapist',
    'Addiction Therapist',
    'Sex Therapist',
    'Gender Therapist',
    'Trauma Therapist',
    'PTSD Therapist',
    'Eating Disorder Therapist',
    'Behavior Analyst',
    'Board-Certified Behavioral Analyst (BCBA)',
    'Applied Behavior Analyst (ABA)',
    'Music Therapist',
    'Art Therapist',
    'Dance/Movement Therapist',
    'Holistic Therapist',
    'Mindfulness Therapist',
    'Yoga Therapist',
    'Nutritionist/Dietitian',
    'Clinical Nutritionist',
    'Holistic Nutritionist',
    'Fitness Trainer/Therapist',
    'Sports Therapist',
    'Aquatic Therapist',
    'Wound Care Therapist'
];


export const TherapistTypeOptions = THERAPIST_TYPES.reduce((acc, value, index) => {
    return (acc = [...acc, { value: value, id: index + 1 }]);
}, []);