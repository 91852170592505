import React from 'react';
import { terms } from '@/assets/json/MedicalDocs';
import DocModalLayout from './DocModalLayout';
import { updateMedicalDocsModal } from '@/actions/generalActions';
import { useDispatch, useSelector } from 'react-redux';
import * as Types from '@/actions/types';

const Terms = () => {
    const dispatch = useDispatch();
    const { termsModal } = useSelector((state) => state.general);

    const handleClose = () => {
        dispatch(
            updateMedicalDocsModal({
                type: Types.UPDATE_TERMS_MODAL,
                payload: {
                    termsModal: false
                }
            })
        );
    };
    const handleOk = () => handleClose();
    const handleCancel = () => handleClose();

    return (
        <>
            {termsModal && (
                <DocModalLayout
                    title={'Terms of Service'}
                    isOpen={termsModal}
                    onCancel={handleCancel}
                    onOk={handleOk}
                    docs={terms}
                    lastUpdatedAt={'2023-10-03T00:00:00.000Z'}
                />
            )}{' '}
        </>
    );
};

export default Terms;
