import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';
let { REACT_APP_API_URI } = process.env;

if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const saveCredentialDocument = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/documents/saveCredentialDocument`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const uploadDocument = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/documents/uploadDocument`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve({
                        success: true,
                        docId: res.data.savedDocument && res.data.savedDocument._id
                    });
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getDocumentList = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/documents/getDocumentList`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_DOCUMENT_LIST,
                        payload: {
                            documentList: res.data.documentList,
                            count: res.data.count
                        }
                    });

                    // message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    dispatch({
                        type: Types.GET_DOCUMENT_LIST,
                        payload: {
                            documentList: [],
                            count: 0
                        }
                    });
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const shareDocument = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/documents/shareDocument`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const downloadDocument = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/documents/files/${props.obj.fileName}`, {
            headers: {
                'Content-Type': 'application/json',
                responseType: 'blob',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const changeSharedDocumentStatus = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/documents/changeSharedDocumentStatus`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const deleteDocument = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/documents/deleteDocument`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const editShareDocument = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/documents/editShareDocument`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getDocumentTypes =
    ({ hospital, pharmacy }, history) =>
    (dispatch) => {
        return new Promise((resolve, reject) => {
            Axios.get(
                `${REACT_APP_API_URI}/api/documents/getDocumentTypes${
                    hospital ? `?hospital=${hospital}` : pharmacy ? `?pharmacy=${pharmacy}` : ''
                }`,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + localStorage.auth_token
                    }
                }
            )
                .then((res) => {
                    console.log(res.data);
                    if (res.data.success) {
                        dispatch({
                            type: Types.GET_ALL_DOCUMENT_TYPES,
                            payload: {
                                documentTypes: res.data.documentTypes
                            }
                        });
                        return resolve(true);
                    }
                })
                .catch((error) => {
                    if (error && error.response) {
                        console.log(error.response.data);
                        if (error.response.data.message) {
                            message.error(error.response.data.message);
                        }
                        return resolve(false);
                    }
                });
        });
    };

export const getDocumentsOtp = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/documents/getDocumentsOtp`, props,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    message.success(res.data.message);

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const verifyDocumentsOtp = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/documents/verifyDocumentsOtp`, props,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    message.success(res.data.message);
                    dispatch({
                        type: Types.UNLOCK_DOCUMENTS
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return reject(error.response.data);
                }
            });
    });
};
