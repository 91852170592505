/* eslint eqeqeq: 0 */
import React, { Component } from 'react';
import girl2 from '../../assets/img/girl2.jpg';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '../../actions/adminActions';
import * as userActions from '../../actions/userActions';
import Currencies from '../../assets/json/Currencies.json';
import languageOptions from '../../assets/json/Languages.json';
import Countries from '../../assets/json/Countries.json';
import { DatePicker, message } from 'antd';
import { Select } from 'antd';
import validator from 'validator';
import moment from 'moment';
import NavigationPrompt from 'react-router-navigation-prompt';
import { UploadOutlined } from '@ant-design/icons';
import ReactDOM from 'react-dom';
import { geocodeByAddress } from 'react-places-autocomplete';
import Geolocate from './../MiniComponents/Geolocate';

const gender = [
    { key: 'male', text: 'Male', value: 'Male' },
    { key: 'female', text: 'Female', value: 'Female' },
    { key: 'other', text: 'Other', value: 'Other' }
];

const statusOptions = ['ACTIVATED', 'DEACTIVATED', 'SUSPENDED'];
export class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formDirty: false,
            fixedFirstName: '',
            fixedLastName: '',
            firstName: { value: '', isValid: true, message: '' },
            lastName: { value: '', isValid: true, message: '' },
            phoneNo: { value: '', isValid: true, message: '' },
            countryCode: { value: '', isValid: true, message: '' },
            dob: { stringValue: '', value: '', isValid: true, message: '' },
            email: { value: '', isValid: true, message: '' },
            password: { value: '', isValid: true, message: '' },
            social: '',
            gender: { value: '', isValid: true, message: '' },
            language: { value: '', isValid: true, message: '' },
            currency: { value: '', isValid: true, message: '' },
            activeStatus: { value: '', isValid: true, message: '' },
            toolTip: '',
            allergens: [],
            dietaryPref: [],
            profileSummary: { value: '', isValid: true, message: '' },
            currencyDrop: false,
            countryDrop: false,
            filteredCurrencies: Currencies,
            filteredCountryCode: Countries,
            profCompDiv: true,
            formContactDirty: false,
            formProfileDirty: false,
            formAccountDirty: false,
            formAllergensDirty: false,
            formSocialDirty: false,
            formDietaryPrefDirty: false,
            contactPhone: { value: '', isValid: true, message: '' },
            contactEmail: { value: '', isValid: true, message: '' },
            contactCountryCode: { value: '', isValid: true, message: '' },
            deliveryLocationInfo: [{ address: '', state: '', city: '', zip: '', disabled: false }],
            billingAddress: { value: '', isValid: true, message: '' },
            billingCity: { value: '', isValid: true, message: '' },
            billingState: { value: '', isValid: true, message: '' },
            billingZip: { value: '', isValid: true, message: '' },
            billingCountry: { value: '', isValid: true, message: '' },
            dba: false,
            profileCompletion: {},
            locality: localStorage.getItem('locality') ? localStorage.getItem('locality') : '',
            localityCountry: localStorage.getItem('localityCountry')
                ? localStorage.getItem('localityCountry')
                : ''
        };
        this.curr = React.createRef();
        this.deleteAddressInfo = this.deleteAddressInfo.bind(this);
        this.changeAddressInfo = this.changeAddressInfo.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.onChange = this.onChange.bind(this);
        this.updateBillingAddress = this.updateBillingAddress.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        // if (props.customerProfile && props.customerProfile.token) {
        //   localStorage.setItem("auth_token", props.customerProfile.token);
        // }

        const { customerProfile } = props.admin;
        if (state.formDirty == false) {
            if (props.auth.isAuthenticated && customerProfile) {
                if (customerProfile.firstName) {
                    state.firstName.value = customerProfile.firstName;
                    state.fixedFirstName = customerProfile.firstName;
                }
                if (customerProfile.lastName) {
                    state.lastName.value = customerProfile.lastName;
                    state.fixedLastName = customerProfile.lastName;
                }
                if (customerProfile.dob) {
                    state.dob.value = customerProfile.dob;
                }
                if (customerProfile.phoneNo) {
                    state.phoneNo.value = customerProfile.phoneNo;
                }
                if (customerProfile.countryCode) {
                    state.countryCode.value = customerProfile.countryCode;
                }
                if (customerProfile.email) {
                    state.email.value = customerProfile.email;
                }
                if (customerProfile.currency) {
                    state.currency.value = customerProfile.currency;
                }
                if (customerProfile.social) {
                    state.social = customerProfile.social;
                }
                if (customerProfile.gender) {
                    state.gender.value = customerProfile.gender;
                }
                if (customerProfile.profileCompletion) {
                    state.profileCompletion = customerProfile.profileCompletion;
                }

                if (customerProfile.dob) {
                    state.dob.value = customerProfile.dob;
                }
                if (customerProfile.language) {
                    state.language.value = customerProfile.language;
                }

                if (customerProfile.allergens) {
                    state.allergens = customerProfile.allergens;
                }

                if (customerProfile.dietaryPref) {
                    state.dietaryPref = customerProfile.dietaryPref;
                }

                if (customerProfile.profileSummary) {
                    state.profileSummary.value = customerProfile.profileSummary;
                }
                if (customerProfile.activeStatus) {
                    state.activeStatus.value = customerProfile.activeStatus;
                }

                if (customerProfile.contact) {
                    state.billingAddress.value = customerProfile.contact.billing.billingAddress;
                    state.billingCity.value = customerProfile.contact.billing.billingCity;
                    state.billingZip.value = customerProfile.contact.billing.billingZip;
                    state.billingState.value = customerProfile.contact.billing.billingState;
                    state.contactEmail.value = customerProfile.contact.email;
                    state.contactPhone.value = customerProfile.contact.phoneNo;
                    state.contactCountryCode.value = customerProfile.contact.countryCode;
                    state.dba = customerProfile.contact.defaultDeliSameAsBillingAddress;
                    state.deliveryLocationInfo[0] = customerProfile.contact.defaultDeliveryLoc;
                    if (customerProfile.contact.defaultDeliSameAsBillingAddress) {
                        state.deliveryLocationInfo[0].disabled =
                            customerProfile.contact.defaultDeliSameAsBillingAddress;
                    }
                    if (
                        customerProfile.contact.otherDeliveryLoc &&
                        customerProfile.contact.otherDeliveryLoc.length
                    ) {
                        for (let i = 0; i < customerProfile.contact.otherDeliveryLoc.length; i++) {
                            state.deliveryLocationInfo[i + 1] =
                                customerProfile.contact.otherDeliveryLoc[i];
                        }
                    }
                }
            }
        }

        return state;
    }

    componentDidMount() {
        let profileData = {
            userId: this.props.match.params.customerId
        };
        this.props.adminActions.getCustomerProfile(
            { profileData, ...this.props },
            this.props.history
        );

        window.addEventListener('click', (e) => {
            let curLabel = document.querySelector('.cur-label');
            let ccLabel = document.querySelector('.cc-label.account');
            let ccLabel1 = document.getElementById('contactCountryCode');

            if (curLabel && !curLabel.contains(e.target)) {
                this.setState({ currencyDrop: false });
            }
            if (ccLabel && !ccLabel.contains(e.target)) {
                console.log('closing');
                this.setState({ countryDrop: false });
            }

            if (ccLabel1 && !ccLabel1.contains(e.target)) {
                this.setState({ countryDrop1: false });
            }
        });
    }

    onChange(e) {
        var state = this.state;
        if (
            e.target.name !== 'allergens' &&
            e.target.name !== 'dietaryPref' &&
            e.target.name !== 'dba'
        ) {
            state[e.target.name].value = e.target.value;
        }
        state.formDirty = true;

        if (e.target.name == 'firstName') {
            if (e.target.value == '') {
                state['firstName'].isValid = false;
                state['firstName'].message = 'First name cannot be left empty';
            } else {
                state['firstName'].isValid = true;
                state['firstName'].message = '';
            }
        }

        if (e.target.name == 'lastName') {
            if (e.target.value == '') {
                state['lastName'].isValid = false;
                state['lastName'].message = 'First name cannot be left empty';
            } else {
                state['lastName'].isValid = true;
                state['lastName'].message = '';
            }
        }

        if (e.target.name == 'email') {
            if (e.target.value == '') {
                state['email'].isValid = false;
                state['email'].message = 'Email cannot be left empty';
            } else if (!validator.isEmail(e.target.value)) {
                state['email'].isValid = false;
                state['email'].message = 'Not a valid email address';
            } else {
                state['email'].isValid = true;
                state['email'].message = '';
            }
        }

        if (e.target.name == 'phoneNo') {
            if (e.target.value == '') {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number cannot be left empty';
            } else if (e.target.value.length !== 10) {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number must be 10 digits';
            } else if (!e.target.value.match(/^\d+$/)) {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number must only contain numbers';
            } else {
                state['phoneNo'].isValid = true;
                state['phoneNo'].message = '';
            }
        }

        if (e.target.name == 'contactPhone') {
            if (e.target.value == '') {
                state['contactPhone'].isValid = false;
                state['contactPhone'].message = 'Phone number cannot be left empty';
            } else if (e.target.value.length !== 10) {
                state['contactPhone'].isValid = false;
                state['contactPhone'].message = 'Phone number must be 10 digits';
            } else if (!e.target.value.match(/^\d+$/)) {
                state['contactPhone'].isValid = false;
                state['contactPhone'].message = 'Phone number must only contain numbers';
            } else {
                state['contactPhone'].isValid = true;
                state['contactPhone'].message = '';
            }
        }

        if (e.target.name == 'contactEmail') {
            if (e.target.value == '') {
                state['contactEmail'].isValid = false;
                state['contactEmail'].message = 'Email cannot be left empty';
            } else if (!validator.isEmail(e.target.value)) {
                state['contactEmail'].isValid = false;
                state['contactEmail'].message = 'Not a valid email address';
            } else {
                state['contactEmail'].isValid = true;
                state['contactEmail'].message = '';
            }
        }

        if (e.target.name == 'countryCode') {
            if (e.target.value == '') {
                state['countryCode'].isValid = false;
                state['countryCode'].message = 'Country code cannot be left empty';
            }

            if (!e.target.value.match(/^\d+$/)) {
                state['countryCode'].isValid = false;
                state['countryCode'].message = 'Country code must only contain numbers';
            }
        }

        if (e.target.name == 'contactCountryCode') {
            if (e.target.value == '') {
                state['contactCountryCode'].isValid = false;
                state['contactCountryCode'].message = 'Country code cannot be left empty';
            }

            if (!e.target.value.match(/^\d+$/)) {
                state['contactCountryCode'].isValid = false;
                state['contactCountryCode'].message = 'Country code must only contain numbers';
            }
        }

        if (e.target.name == 'allergens') {
            console.log(state.allergens, e.target.value);
            if (e.target.checked) {
                if (!state.allergens.includes(e.target.value)) {
                    let temp = state.allergens;
                    temp.push(e.target.value);
                    state['allergens'] = temp;
                }
            } else {
                let temp = state.allergens.filter((item) => {
                    return item !== e.target.value;
                });

                state['allergens'] = temp;
            }
        }

        if (e.target.name == 'dietaryPref') {
            console.log(state.dietaryPref, e.target.value);
            if (e.target.checked) {
                if (!state.dietaryPref.includes(e.target.value)) {
                    let temp = state.dietaryPref;
                    temp.push(e.target.value);
                    state['dietaryPref'] = temp;
                }
            } else {
                let temp = state.dietaryPref.filter((item) => {
                    return item !== e.target.value;
                });

                state['dietaryPref'] = temp;
            }
        }

        if (e.target.name == 'billingCity' && this.state.dba) {
            state['deliveryLocationInfo'][0].city = e.target.value;
        }

        if (e.target.name == 'billingState' && this.state.dba) {
            state['deliveryLocationInfo'][0].state = e.target.value;
        }

        if (e.target.name == 'billingZip' && this.state.dba) {
            state['deliveryLocationInfo'][0].zip = e.target.value;
        }

        if (e.target.name == 'billingAddress' && this.state.dba) {
            state['deliveryLocationInfo'][0].address = e.target.value;
        }

        if (e.target.name == 'dba') {
            console.log(e.target.checked);
            if (e.target.checked) {
                state['dba'] = true;
                state['deliveryLocationInfo'][0].disabled = true;
                state['deliveryLocationInfo'][0].address = state['billingAddress'].value;
                state['deliveryLocationInfo'][0].state = state['billingState'].value;
                state['deliveryLocationInfo'][0].zip = state['billingZip'].value;
                state['deliveryLocationInfo'][0].city = state['billingCity'].value;
            } else {
                state['dba'] = false;
                state['deliveryLocationInfo'][0].disabled = false;
                state['deliveryLocationInfo'][0].address = '';
                state['deliveryLocationInfo'][0].state = '';
                state['deliveryLocationInfo'][0].zip = '';
                state['deliveryLocationInfo'][0].city = '';
            }
        }

        this.setState(state);
    }

    deleteAddressInfo(index) {
        let ind = index;
        let arr = this.state.deliveryLocationInfo;
        let tempAddressInfo = this.state.deliveryLocationInfo;

        if (tempAddressInfo && tempAddressInfo.length > 1) {
            let newTemp = tempAddressInfo.splice(index, 1);
            console.log(tempAddressInfo);
            this.setState({
                formDirty: true,
                deliveryLocationInfo: tempAddressInfo
            });
        }
    }

    changeAddressInfo(e, index) {
        let temp = this.state.deliveryLocationInfo;
        console.log(temp, index);
        let name = e.target.name;
        console.log(name, index);
        if (name == 'city') {
            temp[index].city = e.target.value;
        }
        if (name == 'state') {
            temp[index].state = e.target.value;
        }
        if (name == 'zip') {
            temp[index].zip = e.target.value;
        }
        if (name == 'address') {
            temp[index].address = e.target.value;
        }

        console.log(temp);
        this.setState({ deliveryLocationInfo: temp });
    }
    onChangeDate(date, dateString) {
        console.log(date, dateString);
        this.setState({
            dob: { value: dateString, isValid: true, message: '' }
        });
    }

    saveProfile = async (e) => {
        let state = this.state;
        let data = null;
        let section = null;
        let valid = true;

        if (
            state.firstName.isValid &&
            state.lastName.isValid &&
            state.firstName.value !== '' &&
            state.lastName.value !== ''
        ) {
            let profileObj = {
                data: {
                    firstName: state.firstName.value,
                    lastName: state.lastName.value,
                    gender: state.gender.value,
                    dob: state.dob.value,
                    profileSummary: state.profileSummary.value,
                    activeStatus: state.activeStatus.value,
                    contact: {
                        email: state.contactEmail.value,
                        phoneNo: state.contactPhone.value,
                        countryCode: state.contactCountryCode.value.replace('+', ''),
                        billing: {
                            billingAddress: state.billingAddress.value,
                            billingCity: state.billingCity.value,
                            billingState: state.billingState.value,
                            billingZip: state.billingZip.value
                        },
                        defaultDeliSameAsBillingAddress: this.state.dba,
                        defaultDeliveryLoc: {
                            city: state.deliveryLocationInfo[0].city,
                            address: state.deliveryLocationInfo[0].address,
                            zip: state.deliveryLocationInfo[0].zip,
                            state: state.deliveryLocationInfo[0].state
                        },
                        otherDeliveryLoc: state.deliveryLocationInfo.filter((item, index) => {
                            if (index == 0) {
                                return false;
                            }
                        })
                    },
                    social: {
                        ...state.social
                    },
                    dietaryPref: state.dietaryPref,
                    allergens: state.allergens
                },
                userType: this.props.admin.customerProfile.userType,
                userId: this.props.match.params.customerId
            };
            console.log(profileObj);

            if (!valid == false) {
                await this.props.actions.saveProfile(profileObj, this.props.history);
                this.setState({ formDirty: false });
                let profileShort = {
                    userId: this.props.match.params.customerId,
                    userType: this.props.admin.customerProfile.userType
                };
                // await this.props.actions.getProfile(profileShort, this.props.history);
                this.props.history.push('/admin/customers');
            } else {
                message.error('Fill up required fields!');
            }
        }
    };

    async updateBillingAddress(addr) {
        console.log(addr);
        this.setState({ formDirty: true });
        var placeSearch, autocomplete;
        var componentForm = {
            street_number: '',
            route: '',
            postal_town: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            formattedAddress: '',
            neighbourhood: ''
        };
        let address = await geocodeByAddress(addr);
        if (address) console.log(address);
        address = address[0];
        componentForm.formattedAddress = address.formatted_address;
        for (var i = 0; i < address.address_components.length; i++) {
            var addressType = address.address_components[i].types[0];
            console.log(addressType);
            if (componentForm[addressType] == '') {
                let val = address.address_components[i][componentForm[addressType]];
                componentForm[addressType] = address.address_components[i].long_name;
            }
        }
        console.log(componentForm);
        let state = this.state;
        // debugger
        // if (componentForm.postal_code !== "") {
        //   state.billingAddress = {
        //     value: componentForm.postal_code,
        //     isValid: true,
        //     message: "",
        //   };
        // } else if (componentForm.locality !== "") {
        //   state.billingAddress = {
        //     value: componentForm.locality,
        //     isValid: true,
        //     message: "",
        //   };
        // } else if (componentForm.country !== "" || componentForm.postal_code !== "")
        //   state.billingAddress = {
        //     value: componentForm.country,
        //     isValid: true,
        //     message: "",
        //   };
        if (componentForm.country !== '')
            state.billingCountry = {
                value: componentForm.country,
                isValid: true,
                message: ''
            };
        // debugger
        if (componentForm.postal_code !== '')
            state.billingZip = {
                value: componentForm.postal_code,
                isValid: true,
                message: ''
            };
        state.billingCity = {
            value:
                componentForm.postal_town != 'Town' && componentForm.postal_town
                    ? componentForm.postal_town
                    : componentForm.locality != 'locality'
                    ? componentForm.locality
                    : '',
            isValid: true,
            message: ''
        };
        state.billingState = {
            value:
                componentForm.administrative_area_level_1 != 'State '
                    ? componentForm.administrative_area_level_1
                    : '',
            isValid: true,
            message: ''
        };

        if (componentForm.street_number && componentForm.route) {
            state.billingAddress = {
                value: componentForm.street_number + ' ' + componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.route) {
            state.billingAddress = {
                value: componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.formattedAddress) {
            state.billingAddress = {
                value: componentForm.formattedAddress,
                isValid: true,
                message: ''
            };
        }

        if (state['dba'] == true) {
            state['deliveryLocationInfo'][0].disabled = true;
            state['deliveryLocationInfo'][0].address = state['billingAddress'].value;
            state['deliveryLocationInfo'][0].state = state['billingState'].value;
            state['deliveryLocationInfo'][0].zip = state['billingZip'].value;
            state['deliveryLocationInfo'][0].city = state['billingCity'].value;
        }

        // debugger
        // state.latitude = address.geometry.location.lat();
        // state.longitude = address.geometry.location.lng();
        // state.showNotificationDiv = true;
        // state.isDisabled.country = state.country.value != "" ? true : false;
        // state.isDisabled.state = state.stateName.value != "" ? true : false;
        // state.isDisabled.city = state.city.value != "" ? true : false;

        this.setState(state);
    }
    render() {
        const dateFormat = 'YYYY/MM/DD';
        let filteredCurrencies = Currencies;
        let filteredCountryCode = Countries;
        return (
            <div className='adminEditProfile'>
                <div className=' user-profile'>
                    <div className='coverImage'>
                        <img src='https://source.unsplash.com/random/1600x900' />
                        <div className='overlay'>
                            <div className='addCoverImage'>
                                <UploadOutlined style={{ fontSize: '30px', color: '#f7f7f7' }} />
                            </div>
                        </div>
                    </div>
                    <div className='profileHead'>
                        <div className='profilePic'>
                            <img src={girl2} alt='girl' />
                            <i className='fa fa-pencil' />
                        </div>
                    </div>
                    <h2>Edit Customer Profile</h2>
                    <h1>Account Holder Information</h1>
                    <div>
                        <div className='input-fields'>
                            <div className='input-row'>
                                <label className={!this.state.firstName.isValid ? 'error' : ''}>
                                    First name*:
                                    <input
                                        name='firstName'
                                        placeholder='Firstname'
                                        value={this.state.firstName.value}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    {this.state.firstName.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.firstName.message}
                                        </p>
                                    )}
                                </label>

                                <label className={!this.state.lastName.isValid ? 'error' : ''}>
                                    Last name*:
                                    <input
                                        name='lastName'
                                        placeholder='Firstname'
                                        value={this.state.lastName.value}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    {this.state.lastName.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.lastName.message}
                                        </p>
                                    )}
                                </label>
                            </div>
                            <div className='input-row'>
                                <label>
                                    Date of Birth:
                                    <DatePicker
                                        name='dob'
                                        placeholder='Date of Birth'
                                        value={
                                            this.state.dob.value
                                                ? moment(this.state.dob.value, dateFormat)
                                                : ''
                                        }
                                        onChange={this.onChangeDate.bind(this)}
                                    />
                                </label>
                                <label>
                                    Gender:
                                    <Select
                                        name='gender'
                                        showSearch
                                        placeholder='Gender'
                                        value={this.state.gender.value}
                                        onChange={(e, { value }) =>
                                            this.setState({
                                                gender: { ...this.state.gender, value: value }
                                            })
                                        }
                                        options={gender}
                                    />
                                </label>
                            </div>

                            <div className='input-row '>
                                <label className='textareaLabel'>
                                    Profile Summary:
                                    <textarea
                                        placeholder=''
                                        value={this.state.profileSummary.value}
                                        onChange={(e) =>
                                            this.setState({
                                                formDirty: true,
                                                profileSummary: {
                                                    ...this.state.profileSummary,
                                                    value: e.target.value
                                                }
                                            })
                                        }
                                    />
                                </label>
                            </div>
                            {/* <button
                  name="profileBtn"
                  className="save-btn"
                    onClick={(e) => this.saveProfile(e)}
                >
                  Save Changes
                </button> */}
                        </div>

                        <div class='dividerL'></div>

                        <h1>Billing Contact</h1>
                        <div className='input-fields'>
                            <div className='input-row'>
                                <label
                                    id='contactCountryCode'
                                    className={`${
                                        !this.state.contactCountryCode.isValid ? 'error' : ''
                                    } cc-label ${this.state.countryDrop1 && 'focused'}`}
                                    tabIndex={0}
                                >
                                    Country Code:
                                    <input
                                        type='text'
                                        name='countryCode'
                                        placeholder='Country code'
                                        value={this.state.contactCountryCode.value}
                                        autocomplete='off'
                                        style={
                                            this.state.countryCode.value.match(/^\d+$/) && {
                                                paddingLeft: 20
                                            }
                                        }
                                        className='cc-input'
                                        onFocus={() => this.setState({ countryDrop1: true })}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                filteredCountryCode = Countries.filter(
                                                    (item, index) => {
                                                        const regex = new RegExp(
                                                            e.target.value,
                                                            'gi'
                                                        );
                                                        return (
                                                            item.name.match(e.target.value) ||
                                                            item.dialCode.match(e.target.value) ||
                                                            item.isoCode.match(e.target.value)
                                                        );
                                                    }
                                                );
                                                this.setState({
                                                    filteredCountryCode: filteredCountryCode
                                                });
                                            } else {
                                                this.setState({ filteredCountryCode: Countries });
                                            }
                                            this.setState({
                                                formAccountDirty: true,
                                                formDirty: true,
                                                contactCountryCode: {
                                                    ...this.state.contactCountryCode,
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                    <i className='dropdown icon'></i>
                                    {this.state.contactCountryCode.value.length &&
                                    this.state.contactCountryCode.value.match(/^\d+$/) ? (
                                        <i className='plus-icon'>&#43;</i>
                                    ) : null}
                                    {this.state.countryDrop1 && (
                                        <div
                                            className='countryDrop'
                                            onBlur={() => this.setState({ countryDrop1: false })}
                                        >
                                            <ul>
                                                {this.state.filteredCountryCode &&
                                                    this.state.filteredCountryCode.map(
                                                        (item, index) => (
                                                            <li
                                                                key={index}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (
                                                                        item &&
                                                                        item.flag &&
                                                                        item.dialCode
                                                                    ) {
                                                                        this.setState({
                                                                            contactCountryCode: {
                                                                                ...this.state
                                                                                    .contactCountryCode,
                                                                                value: item.dialCode.split(
                                                                                    '+'
                                                                                )[1]
                                                                            },
                                                                            formDirty: true,
                                                                            countryDrop1: false
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src={item.flag}
                                                                    height='16px'
                                                                    width='24px'
                                                                />
                                                                {item.name +
                                                                    ' (' +
                                                                    item.isoCode +
                                                                    ') ' +
                                                                    item.dialCode}
                                                            </li>
                                                        )
                                                    )}
                                            </ul>
                                        </div>
                                    )}
                                    {this.state.contactCountryCode.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.contactCountryCode.message}
                                        </p>
                                    )}
                                </label>
                                <label className={!this.state.contactPhone.isValid ? 'error' : ''}>
                                    Phone No:
                                    <input
                                        name='contactPhone'
                                        placeholder='Phone No.'
                                        value={this.state.contactPhone.value}
                                        onChange={this.onChange}
                                    />
                                    {this.state.contactPhone.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.contactPhone.message}
                                        </p>
                                    )}
                                </label>
                            </div>
                            <div className='input-row two-part'>
                                <label
                                    className={`${
                                        !this.state.contactEmail.isValid ? 'error' : ''
                                    } one-half`}
                                >
                                    Email Address:
                                    <input
                                        name='contactEmail'
                                        placeholder='Email address'
                                        value={this.state.contactEmail.value}
                                        onChange={this.onChange}
                                    />
                                    {this.state.contactEmail.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.contactEmail.message}
                                        </p>
                                    )}
                                </label>
                                {/* <label className="one-half">
              Billing Location:
             
                  <Geolocate chooseAddress={(address) => this.updateBillingAddress(address)}/>
            </label> */}

                                <label className='one-half'>
                                    Billing Location:
                                    <i className='fa fa-map-marker' />
                                    <Geolocate
                                        chooseAddress={(address) =>
                                            this.updateBillingAddress(address)
                                        }
                                    />
                                </label>
                            </div>
                            <div className='input-row two-part'>
                                <label className='one-half'>
                                    Billing Address:
                                    <input
                                        name='billingAddress'
                                        value={this.state.billingAddress.value}
                                        onChange={(e) => this.onChange(e)}
                                        placeholder='Billing Address'
                                    />
                                </label>
                                <label className='one-half'>
                                    Billing Country:
                                    <input
                                        name='billingCountry'
                                        value={this.state.billingCountry.value}
                                        onChange={(e) => this.onChange(e)}
                                        placeholder='Billing Address'
                                    />
                                </label>
                            </div>

                            <div className='input-row three-part'>
                                <label className='one-third'>
                                    City:
                                    <input
                                        name='billingCity'
                                        value={this.state.billingCity.value}
                                        onChange={(e) => this.onChange(e)}
                                        placeholder='City'
                                    />
                                </label>
                                <label className='one-third'>
                                    Province/State:
                                    <input
                                        name='billingState'
                                        value={this.state.billingState.value}
                                        onChange={(e) => this.onChange(e)}
                                        placeholder='Province/State'
                                    />
                                </label>
                                <label className='one-third'>
                                    Postal/Zip Code:
                                    <input
                                        name='billingZip'
                                        value={this.state.billingZip.value}
                                        onChange={(e) => this.onChange(e)}
                                        placeholder='Postal/Zip Code'
                                    />
                                </label>
                            </div>

                            {this.state.deliveryLocationInfo &&
                                this.state.deliveryLocationInfo.map((info, index) => (
                                    <>
                                        <div className='input-row'>
                                            <label className='fullLabel'>
                                                {index == 0 ? (
                                                    <>
                                                        <div
                                                            style={{
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'space-between',
                                                                flexWrap: 'wrap'
                                                            }}
                                                        >
                                                            Default delivery location:
                                                            <label
                                                                htmlFor='dba'
                                                                style={{
                                                                    marginLeft: 20,
                                                                    marginTop: 0,
                                                                    minWidth: 'fit-content'
                                                                }}
                                                            >
                                                                {' '}
                                                                <input
                                                                    type='checkbox'
                                                                    checked={this.state.dba}
                                                                    value={'dba'}
                                                                    name='dba'
                                                                    id='dba'
                                                                    onChange={this.onChange}
                                                                />
                                                                <span className='checkBox'>
                                                                    <i className='fe fe-check' />
                                                                </span>
                                                                Same as Billing Address
                                                            </label>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <i
                                                            className='fe fe-x'
                                                            onClick={(e) => {
                                                                console.log('index', index);
                                                                if (
                                                                    this.state.deliveryLocationInfo
                                                                        .length > 1
                                                                ) {
                                                                    this.deleteAddressInfo(index);
                                                                }
                                                            }}
                                                        />
                                                        {'Delivery Location ' + (index + 1)}
                                                    </>
                                                )}

                                                <input
                                                    value={
                                                        this.state.deliveryLocationInfo &&
                                                        this.state.deliveryLocationInfo[index] &&
                                                        this.state.deliveryLocationInfo[index]
                                                            .address
                                                    }
                                                    onChange={(e) =>
                                                        this.changeAddressInfo(e, index)
                                                    }
                                                    name='address'
                                                    placeholder='Delivery address'
                                                    disabled={
                                                        this.state.deliveryLocationInfo &&
                                                        this.state.deliveryLocationInfo[index] &&
                                                        this.state.deliveryLocationInfo[index]
                                                            .disabled
                                                            ? this.state.deliveryLocationInfo[index]
                                                                  .disabled
                                                            : false
                                                    }
                                                />
                                            </label>
                                        </div>

                                        <div className='input-row three-part'>
                                            <label className='one-third'>
                                                City:
                                                <input
                                                    name='city'
                                                    disabled={
                                                        this.state.deliveryLocationInfo &&
                                                        this.state.deliveryLocationInfo[index] &&
                                                        this.state.deliveryLocationInfo[index]
                                                            .disabled
                                                            ? this.state.deliveryLocationInfo[index]
                                                                  .disabled
                                                            : false
                                                    }
                                                    placeholder='City'
                                                    value={
                                                        this.state.deliveryLocationInfo[index] &&
                                                        this.state.deliveryLocationInfo[index]
                                                            .city &&
                                                        this.state.deliveryLocationInfo[index].city
                                                    }
                                                    onChange={(e) =>
                                                        this.changeAddressInfo(e, index)
                                                    }
                                                />
                                            </label>
                                            <label className='one-third'>
                                                Province/State:
                                                <input
                                                    name='state'
                                                    value={
                                                        this.state.deliveryLocationInfo[index] &&
                                                        this.state.deliveryLocationInfo[index]
                                                            .state &&
                                                        this.state.deliveryLocationInfo[index].state
                                                    }
                                                    disabled={
                                                        this.state.deliveryLocationInfo &&
                                                        this.state.deliveryLocationInfo[index] &&
                                                        this.state.deliveryLocationInfo[index]
                                                            .disabled
                                                            ? this.state.deliveryLocationInfo[index]
                                                                  .disabled
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        this.changeAddressInfo(e, index)
                                                    }
                                                    placeholder='Province/State'
                                                />
                                            </label>
                                            <label className='one-third'>
                                                Postal/Zip Code:
                                                <input
                                                    name='zip'
                                                    value={
                                                        this.state.deliveryLocationInfo[index] &&
                                                        this.state.deliveryLocationInfo[index]
                                                            .zip &&
                                                        this.state.deliveryLocationInfo[index].zip
                                                    }
                                                    disabled={
                                                        this.state.deliveryLocationInfo &&
                                                        this.state.deliveryLocationInfo[index] &&
                                                        this.state.deliveryLocationInfo[index]
                                                            .disabled
                                                            ? this.state.deliveryLocationInfo[index]
                                                                  .disabled
                                                            : false
                                                    }
                                                    onChange={(e) =>
                                                        this.changeAddressInfo(e, index)
                                                    }
                                                    placeholder='Postal/Zip Code'
                                                />
                                            </label>
                                        </div>
                                    </>
                                ))}

                            <div className='input-row-btns'>
                                {/* <button
              name="contactBtn"
              className="save-btn"
              onClick={this.saveProfile}
            >
              Save Changes
            </button> */}
                                {this.state.deliveryLocationInfo.length < 5 && (
                                    <button
                                        className='save-btn'
                                        onClick={() => {
                                            if (this.state.deliveryLocationInfo.length < 5) {
                                                this.setState({
                                                    formDirty: true,
                                                    deliveryLocationInfo: [
                                                        ...this.state.deliveryLocationInfo,
                                                        {
                                                            city: '',
                                                            address: '',
                                                            zipCode: '',
                                                            state: ''
                                                        }
                                                    ]
                                                });
                                            }
                                        }}
                                    >
                                        Add
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className='dividerL'></div>

                        <h1>Social Account Settings</h1>
                        <div className='input-fields'>
                            <div className='input-row social'>
                                <label>
                                    Facebook:
                                    <i className='fa fa-facebook' />
                                    <input
                                        name='social'
                                        placeholder='Facebook'
                                        onChange={(e) =>
                                            this.setState({
                                                formDirty: true,
                                                social: {
                                                    ...this.state.social,
                                                    facebook: e.target.value
                                                }
                                            })
                                        }
                                        value={this.state.social && this.state.social.facebook}
                                    />
                                </label>

                                <label>
                                    Instagram:
                                    <i className='fa fa-instagram' />
                                    <input
                                        name='social'
                                        placeholder='Instagram'
                                        onChange={(e) =>
                                            this.setState({
                                                formDirty: true,
                                                social: {
                                                    ...this.state.social,
                                                    instagram: e.target.value
                                                }
                                            })
                                        }
                                        value={this.state.social && this.state.social.instagram}
                                    />
                                </label>
                            </div>
                            <div className='input-row social'>
                                <label>
                                    Twitter:
                                    <i className='fa fa-twitter' />
                                    <input
                                        name='social'
                                        placeholder='Twitter'
                                        onChange={(e) =>
                                            this.setState({
                                                formDirty: true,
                                                social: {
                                                    ...this.state.social,
                                                    twitter: e.target.value
                                                }
                                            })
                                        }
                                        value={this.state.social && this.state.social.twitter}
                                    />
                                </label>
                                <label>
                                    LinkedIn
                                    <i className='fa fa-linkedin' />
                                    <input
                                        name='social'
                                        placeholder='LinkedIn'
                                        onChange={(e) =>
                                            this.setState({
                                                formDirty: true,
                                                social: {
                                                    ...this.state.social,
                                                    linkedin: e.target.value
                                                }
                                            })
                                        }
                                        value={this.state.social && this.state.social.linkedin}
                                    />
                                </label>
                            </div>

                            {/* <button
    name="socialBtn"
    className="save-btn"
    onClick={this.saveProfile}
  >
    Save
  </button> */}
                        </div>

                        <div className='dividerL'></div>
                        <h1>Dietary Preferences</h1>
                        <div className='input-fields checkBoxes'>
                            <div className='input-row checkboxRows'>
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='dietaryPref'
                                        value='dairy-free'
                                        onChange={this.onChange}
                                        checked={this.state.dietaryPref.includes('dairy-free')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    dairy-free
                                </label>

                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='dietaryPref'
                                        value='gluten-free'
                                        onChange={this.onChange}
                                        checked={this.state.dietaryPref.includes('gluten-free')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    gluten-free
                                </label>

                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='dietaryPref'
                                        value='Halal'
                                        onChange={this.onChange}
                                        checked={this.state.dietaryPref.includes('Halal')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    Halal
                                </label>
                                {/* </div>
          <div className="input-row  checkboxRows"> */}
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='dietaryPref'
                                        value='kosher'
                                        onChange={this.onChange}
                                        checked={this.state.dietaryPref.includes('kosher')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    kosher
                                </label>
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='dietaryPref'
                                        value='vegan'
                                        onChange={this.onChange}
                                        checked={this.state.dietaryPref.includes('vegan')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    vegan
                                </label>
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='dietaryPref'
                                        value='vegetarian'
                                        onChange={this.onChange}
                                        checked={this.state.dietaryPref.includes('vegetarian')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    vegetarian
                                </label>
                                {/* </div>
          <div className="input-row  checkboxRows"> */}
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='dietaryPref'
                                        value='sugar-free'
                                        onChange={this.onChange}
                                        checked={this.state.dietaryPref.includes('sugar-free')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    sugar-free
                                </label>
                            </div>

                            {/* <button
            name="dietaryPrefBtn"
            className="save-btn"
            onClick={this.saveProfile}
          >
            Save
          </button> */}
                        </div>

                        <div className='dividerL'></div>
                        <h1>Allergens</h1>
                        <div className='input-fields checkBoxes'>
                            <div className='input-row checkboxRows'>
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='allergens'
                                        value='crustaceans and molluscs'
                                        onChange={this.onChange}
                                        checked={this.state.allergens.includes(
                                            'crustaceans and molluscs'
                                        )}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    crustaceans and molluscs
                                </label>

                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='allergens'
                                        value='eggs'
                                        onChange={this.onChange}
                                        checked={this.state.allergens.includes('eggs')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    eggs
                                </label>

                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='allergens'
                                        value='fish'
                                        onChange={this.onChange}
                                        checked={this.state.allergens.includes('fish')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    fish
                                </label>
                                {/* </div>
          <div className="input-row  checkboxRows"> */}
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='allergens'
                                        value='milk'
                                        onChange={this.onChange}
                                        checked={this.state.allergens.includes('milk')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    milk
                                </label>
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='allergens'
                                        value='mustard'
                                        onChange={this.onChange}
                                        checked={this.state.allergens.includes('mustard')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    mustard
                                </label>
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='allergens'
                                        value='peanuts'
                                        onChange={this.onChange}
                                        checked={this.state.allergens.includes('peanuts')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    peanuts
                                </label>
                                {/* </div>
          <div className="input-row  checkboxRows"> */}
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='allergens'
                                        value='sesame seeds'
                                        onChange={this.onChange}
                                        checked={this.state.allergens.includes('sesame seeds')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    sesame seeds
                                </label>
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='allergens'
                                        value='soy'
                                        onChange={this.onChange}
                                        checked={this.state.allergens.includes('soy')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    soy
                                </label>
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='allergens'
                                        value='sulphites'
                                        onChange={this.onChange}
                                        checked={this.state.allergens.includes('sulphites')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    sulphites
                                </label>
                                {/* </div>
          <div className="input-row  checkboxRows"> */}
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='allergens'
                                        value='tree nuts'
                                        onChange={this.onChange}
                                        checked={this.state.allergens.includes('tree nuts')}
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    <div>
                                        tree nuts
                                        <i
                                            className='fe fe-info'
                                            onMouseOver={() => {
                                                this.setState({ toolTip: true });
                                            }}
                                            onMouseOut={() => this.setState({ toolTip: false })}
                                        />
                                        {this.state.toolTip && (
                                            <div className='infoToolTip'>
                                                <span>
                                                    (Almonds, Brazil nuts, cashews, hazelnuts,
                                                    macadamia nuts, pecans, pine nuts, pistachios
                                                    and walnuts)
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </label>
                                <label className='fullLabel'>
                                    <input
                                        type='checkbox'
                                        name='allergens'
                                        value='wheat and triticale'
                                    />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    wheat and triticale
                                </label>
                                <label className='fullLabel'>
                                    <input type='checkbox' name='allergens' value='sulphites' />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    sulphites
                                </label>
                            </div>

                            {/* <button
            name="allergensBtn"
            className="save-btn"
            onClick={this.saveProfile}
          >
            Save
          </button> */}
                        </div>

                        <div className='dividerL'></div>

                        <h1>Special Requests</h1>
                        <div className='input-fields'>
                            <div className='input-row '>
                                <label className='textareaLabel'>
                                    Request:
                                    <textarea placeholder='' rows='4' />
                                </label>
                            </div>
                            {/* <button
            name="specialReqBtn"
            className="save-btn"
            onClick={() => this.setState({ formDirty: false })}
          >
            Save
          </button> */}
                        </div>

                        <div className='dividerL'></div>

                        <h1>Account Settings</h1>
                        <div className='input-fields'>
                            <div className='input-row'>
                                <label className={!this.state.email.isValid ? 'error' : ''}>
                                    Email:
                                    <input
                                        name='email'
                                        placeholder='Email'
                                        value={this.state.email.value}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    {this.state.email.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.email.message}
                                        </p>
                                    )}
                                </label>
                                <label className={!this.state.phoneNo.isValid ? 'error' : ''}>
                                    Phone No:
                                    <input
                                        name='phoneNo'
                                        placeholder='Phone No.'
                                        value={this.state.phoneNo.value}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                    {this.state.phoneNo.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.phoneNo.message}
                                        </p>
                                    )}
                                </label>
                            </div>
                            <div className='input-row'>
                                <label
                                    className={`${
                                        !this.state.countryCode.isValid ? 'error' : ''
                                    } cc-label account ${this.state.countryDrop && 'focused'}`}
                                    tabIndex={0}
                                >
                                    Country Code:
                                    <input
                                        type='text'
                                        name='countryCode'
                                        placeholder='Country code'
                                        value={this.state.countryCode.value}
                                        autocomplete='off'
                                        style={
                                            this.state.countryCode.value.match(/^\d+$/) && {
                                                paddingLeft: 20
                                            }
                                        }
                                        className='cc-input'
                                        onFocus={() => this.setState({ countryDrop: true })}
                                        onChange={(e) => {
                                            if (e.target.value !== '') {
                                                filteredCountryCode = Countries.filter(
                                                    (item, index) => {
                                                        const regex = new RegExp(
                                                            e.target.value,
                                                            'gi'
                                                        );
                                                        return (
                                                            item.name.match(e.target.value) ||
                                                            item.dialCode.match(e.target.value) ||
                                                            item.isoCode.match(e.target.value)
                                                        );
                                                    }
                                                );
                                                this.setState({
                                                    filteredCountryCode: filteredCountryCode
                                                });
                                            } else {
                                                this.setState({ filteredCountryCode: Countries });
                                            }
                                            this.setState({
                                                formAccountDirty: true,
                                                formDirty: true,
                                                countryCode: {
                                                    ...this.state.countryCode,
                                                    value: e.target.value
                                                }
                                            });
                                        }}
                                    />
                                    <i className='dropdown icon'></i>
                                    {this.state.countryCode.value.length &&
                                    this.state.countryCode.value.match(/^\d+$/) ? (
                                        <i className='plus-icon'>&#43;</i>
                                    ) : null}
                                    {this.state.countryDrop && (
                                        <div
                                            className='countryDrop'
                                            //   onBlur={() => this.setState({ countryDrop: false })}
                                        >
                                            <ul>
                                                {this.state.filteredCountryCode &&
                                                    this.state.filteredCountryCode.map(
                                                        (item, index) => (
                                                            <li
                                                                key={index}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    if (
                                                                        item &&
                                                                        item.flag &&
                                                                        item.dialCode
                                                                    ) {
                                                                        this.setState({
                                                                            countryCode: {
                                                                                ...this.state
                                                                                    .countryCode,
                                                                                value: item.dialCode.split(
                                                                                    '+'
                                                                                )[1]
                                                                            },
                                                                            formDirty: true,
                                                                            countryDrop: false
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src={item.flag}
                                                                    height='16px'
                                                                    width='24px'
                                                                />
                                                                {item.name +
                                                                    ' (' +
                                                                    item.isoCode +
                                                                    ') ' +
                                                                    item.dialCode}
                                                            </li>
                                                        )
                                                    )}
                                            </ul>
                                        </div>
                                    )}
                                    {this.state.countryCode.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.countryCode.message}
                                        </p>
                                    )}
                                </label>
                                <label>
                                    Password:
                                    <input
                                        name='password'
                                        type='password'
                                        placeholder='************'
                                        value={this.state.password.value}
                                        onChange={(e) => this.onChange(e)}
                                    />
                                </label>
                            </div>
                            <div className='input-row'>
                                <label>
                                    Language:
                                    <Select
                                        placeholder='Language'
                                        value={this.state.language.value}
                                        // search
                                        showSearch
                                        selection
                                        onChange={(e, { value }) =>
                                            this.setState({
                                                language: { ...this.state.language, value: value }
                                            })
                                        }
                                        options={languageOptions}
                                    />
                                </label>

                                <label
                                    className={`cur-label ${this.state.currencyDrop && 'focused'}`}
                                    tabIndex={0}
                                >
                                    Currency
                                    <input
                                        className='cur-input'
                                        type='text'
                                        placeholder='Choose currency'
                                        value={this.state.currency.value}
                                        onFocus={() => this.setState({ currencyDrop: true })}
                                        onChange={(e) => {
                                            filteredCurrencies = Currencies.filter(
                                                (item, index) => {
                                                    const regex = new RegExp(e.target.value, 'gi');
                                                    return (
                                                        item.name.match(regex) ||
                                                        item.currency.code.match(regex)
                                                    );
                                                }
                                            );
                                            this.setState({
                                                filteredCurrencies: filteredCurrencies
                                            });
                                            console.log(filteredCurrencies);
                                            this.setState({ currency: e.target.value });
                                        }}
                                    />
                                    <i className='dropdown icon'></i>
                                    {this.state.currencyDrop && (
                                        <div
                                            className='currencyDrop'
                                            ref={this.curr}
                                            onBlur={() => this.setState({ currencyDrop: false })}
                                        >
                                            <ul>
                                                {this.state.filteredCurrencies &&
                                                    this.state.filteredCurrencies.map(
                                                        (item, index) => (
                                                            <li
                                                                key={index}
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    console.log(item, e);
                                                                    if (
                                                                        item &&
                                                                        item.currency &&
                                                                        item.currency.code
                                                                    ) {
                                                                        this.setState({
                                                                            currency: {
                                                                                ...this.state
                                                                                    .currency,
                                                                                value: item.currency
                                                                                    .code
                                                                            },
                                                                            formDirty: true,
                                                                            currencyDrop: false
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <img
                                                                    src={`data:image/jpeg;base64,${item.flag}`}
                                                                    height='16px'
                                                                    width='24px'
                                                                />
                                                                {item.name +
                                                                    ' - ' +
                                                                    item.currency.code +
                                                                    ` (${
                                                                        item.currency.symbol !==
                                                                            false &&
                                                                        item.currency.symbol
                                                                    })`}{' '}
                                                            </li>
                                                        )
                                                    )}
                                            </ul>
                                        </div>
                                    )}
                                </label>
                            </div>
                            <div className='input-row' style={{ width: 360, maxWidth: '100%' }}>
                                <label>
                                    Active Status:
                                    <Select
                                        placeholder='Active Status'
                                        value={this.state.activeStatus.value}
                                        selection
                                        onChange={(e, { value }) => {
                                            console.log(value);
                                            this.setState({
                                                activeStatus: {
                                                    ...this.state.activeStatus,
                                                    value: value
                                                },
                                                formDirty: true
                                            });
                                        }}
                                    >
                                        {statusOptions &&
                                            statusOptions.map((item, index) => (
                                                <Select.Option key={index} value={item}>
                                                    {item}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                </label>
                            </div>

                            <div className='input-row checkboxRows' style={{ marginTop: 20 }}>
                                <label className='fullLabel'>
                                    <input type='checkbox' />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    Private Profile
                                </label>

                                <label className='fullLabel'>
                                    <input type='checkbox' />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    Share Social info
                                </label>

                                <label className='fullLabel'>
                                    <input type='checkbox' />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    Share Favourites
                                </label>
                                <label className='fullLabel'>
                                    <input type='checkbox' />
                                    <span className='checkBox'>
                                        <i className='fe fe-check' />
                                    </span>
                                    Allow Messaging
                                </label>
                            </div>

                            <label style={{ flexDirection: 'row' }}>
                                Deactivate your account? &nbsp;{' '}
                                <span style={{ textDecoration: 'underline', color: 'red' }}>
                                    Deactivate now.
                                </span>
                            </label>

                            <button
                                name='accountBtn'
                                className='save-btn'
                                onClick={(e) => this.saveProfile(e)}
                            >
                                Save
                            </button>
                        </div>

                        <NavigationPrompt when={this.state.formDirty}>
                            {({ onConfirm, onCancel }) => (
                                <>
                                    {this.state.formDirty &&
                                        ReactDOM.createPortal(
                                            <div className='modal-overlay nav-prompt'>
                                                <div className='form-modal'>
                                                    <div className='modal-title'>
                                                        <p>Your Profile is not saved yet</p>
                                                    </div>
                                                    <div className='modal-content'>
                                                        <p>
                                                            {' '}
                                                            You need to save your unsaved changes,
                                                            do you really want to leave without
                                                            saving your changes?
                                                        </p>
                                                    </div>
                                                    <div className='modal-actions'>
                                                        <button
                                                            className='btn--pink-outlined'
                                                            style={{ borderRadius: 8 }}
                                                            color='primary'
                                                            onClick={onCancel}
                                                        >
                                                            No
                                                        </button>
                                                        <button
                                                            className='btn--pink'
                                                            style={{
                                                                borderRadius: 8
                                                                // backgroundColor: "rgb(255,114,69)",
                                                            }}
                                                            color='primary'
                                                            autoFocus
                                                            onClick={onConfirm}
                                                        >
                                                            Yes
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>,
                                            document.querySelector('#modal-root')
                                        )}
                                </>
                            )}
                        </NavigationPrompt>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    adminActions: bindActionCreators(adminActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
