import React, { useState } from 'react';
import { Button, Modal } from 'antd';

const MessageModal = ({ open, setOpen, message, setMessage, sendMessage }) => {
    const [messageError, setMessageError] = useState('');

    const submit = async () => {
        if (!message) {
            setMessageError('Message field cannot be empty');
        } else {
            setMessageError('');
            sendMessage();
        }
    };
    return (
        <Modal
            centered
            className='addReview'
            title='Send a message'
            open={open}
            onCancel={() => {
                setOpen(false);
            }}
            footer={null}
        >
            <label>
                <span>Message</span>
                <textarea
                    placeholder='Description'
                    value={message}
                    onChange={(e) => {
                        if(e.target.value !== ''){
                            setMessageError('')
                        }
                        setMessage(e.target.value)
                    }}
                />
                <p className='error'>{messageError && messageError}</p>
            </label>
            <Button type='primary' onClick={submit}>
                Send Message
            </Button>
        </Modal>
    );
};

export default MessageModal;
