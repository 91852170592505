import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';
let { REACT_APP_API_URI } = process.env;

if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const addToProspects = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/prospects/addToProspects`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        // message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const checkIfProspectAdded = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/prospects/checkIfProspectAdded`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    // message.success(res.data.message);
                    if (res.data.addedToProspect) {
                        return resolve(true);
                    } else {
                        return resolve(false);
                    }
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        // message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getProspects = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/prospects/getProspects`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_PROSPECTS,
                        payload: {
                            prospects: res.data.prospects,
                            prospectsCount: res.data.prospectsCount
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.GET_PROSPECTS,
                        payload: {
                            prospects: [],
                            prospectsCount: 0
                        }
                    });
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
