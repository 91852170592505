import React, { useState, useEffect } from 'react';
import { Button, Calendar, Radio, Select } from 'antd';
import { DateTime } from 'luxon';

import './ChooseDateAndTime.css';
import moment from 'moment';

function ChooseDateAndTime(props) {
    const {
        consultationMode,
        setConsultationMode,
        paymentMode,
        setPaymentMode,
        auth,
        doctors,
        hospital,
        apptDate,
        setApptDate,
        refreshDetails,
        selectedSlot,
        setSelectedSlot,
        pointer,
        setPointer,
        chosenService,
        setChosenService,
        setChosenServiceObj,
        chosenServiceObj
    } = props;

    const { hospitalForAppointment: apptHospital } = hospital;
    const { doctorForAppointment: apptDoctor } = doctors;
    let timeSlots = [];
    let availableDays = [];
    let avail = {};
    let services = [];
    if (apptHospital && apptHospital.hospital && Object.keys(apptHospital.hospital).length != 0) {
        timeSlots = apptHospital?.timeSlots;
        avail = apptHospital?.availability;
        availableDays = apptHospital?.availableDays;
        services = apptHospital?.hospital?.services;
    } else if (apptDoctor && apptDoctor.doctor && Object.keys(apptDoctor.doctor).length != 0) {
        timeSlots = apptDoctor?.timeSlots;
        avail = apptDoctor?.availability;
        availableDays = apptDoctor?.availableDays;
        services = apptDoctor?.doctor?.services;
    }
    let entity =
        (apptDoctor.doctor && Object.keys(apptDoctor.doctor).length != 0 && apptDoctor.doctor) ||
        apptHospital.hospital;

    console.log('entity', entity);

    const { stripeVerified } = entity;

    useEffect(() => {
        if (!stripeVerified) {
            setConsultationMode('In-Person');
            setPaymentMode('In-Person');
        }
    }, [stripeVerified]);

    useEffect(() => {
        if (consultationMode == 'Online') {
            setPaymentMode('Online');
        }
    }, [consultationMode]);

    let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    let daysAvailLength = 0;
    const dateFormat = 'DD/MM/YYYY';
    days && days.map((item) => avail[item] && daysAvailLength++);
    days = days.filter((item) => avail[item]);
    // const timeSlots = ['09:00', '09:30', '10:00', '10:30'];
    function disabledDate(current) {
        // Can not select days before today and today
        return current && current.valueOf() < Date.now();
    }

    useEffect(() => {
        if (services.length > 0 && !chosenService) {
            setChosenService(services[0]._id);
            setChosenServiceObj(services[0]);
        }
    }, [services]);

    function onPanelChange(value, mode) {}

    return (
        <div className='chooseDateAndTime'>
            <div className='title'>
                <p>Appointment Details</p>
            </div>
            <div className='twoPart'>
                <div className='calendarCard' style={{ width: '100%', borderRadius: 2 }}>
                    <Calendar
                        disabledDate={disabledDate}
                        value={apptDate ? moment(apptDate, dateFormat) : ''}
                        onSelect={(val) => {
                            setApptDate(moment(val).format(dateFormat));
                        }}
                        onChange={(val) => {
                            console.log(val, val.$d, moment(val.$d).format(dateFormat));
                        }}
                        fullscreen={false}
                        onPanelChange={onPanelChange}
                    />
                </div>
                <div className='timeSlots'>
                    <p>
                        {availableDays && availableDays.length > 0 ? (
                            <>
                                Available on{' '}
                                {availableDays &&
                                    availableDays.map((item, index) => {
                                        // if (avail[item]) {
                                        return (
                                            <strong key={index}>
                                                {item.charAt(0).toUpperCase() + item.slice(1)}
                                                {index < availableDays.length - 2 && ', '}
                                                {index == availableDays.length - 2 &&
                                                    index != availableDays.length - 1 &&
                                                    ` and `}
                                            </strong>
                                        );
                                        // }
                                    })}
                            </>
                        ) : (
                            <strong>Not Available</strong>
                        )}

                        {/* <strong>Mondays</strong> and <strong>Fridays</strong> */}
                    </p>
                    <p>
                        Choose a Time slot available on{' '}
                        {apptDate && moment(apptDate, dateFormat).format('DD-MM-YYYY')}
                    </p>

                    {timeSlots && timeSlots.length > 0 ? (
                        <>
                            <div className='ranges'>
                                {timeSlots &&
                                    timeSlots.map(
                                        (item, index) =>
                                            index != timeSlots.length - 1 && (
                                                <button
                                                    key={index}
                                                    className={`${
                                                        selectedSlot === index ? 'activeBtn' : ''
                                                    } slot`}
                                                    onClick={(e) => {
                                                        setSelectedSlot(index);
                                                    }}
                                                >
                                                    {item}{' '}
                                                    {timeSlots[index + 1]
                                                        ? ' - ' + timeSlots[index + 1]
                                                        : ''}
                                                </button>
                                            )
                                    )}
                            </div>
                            <p>Choose Service: </p>
                            <Select
                                style={{ width: '100%' }}
                                onChange={(item) => {
                                    setChosenService(item);
                                    setChosenServiceObj(
                                        services?.filter((it) => it._id == item)[0] ?? null
                                    );
                                }}
                                value={chosenService}
                            >
                                {services?.map((item, index) => (
                                    <Select.Option value={item._id} key={index}>
                                        {item.name}
                                    </Select.Option>
                                ))}
                            </Select>
                            <div>
                                <p>Consulatation Mode: </p>
                                <Radio.Group
                                    onChange={(e) => setConsultationMode(e.target.value)}
                                    value={consultationMode}
                                >
                                    <Radio disabled={!stripeVerified} value={'Online'}>
                                        Online
                                    </Radio>
                                    <Radio value={'In-Person'}>In-Person</Radio>
                                </Radio.Group>
                            </div>

                            <div>
                                <p>Payment Mode: </p>
                                <Radio.Group
                                    onChange={(e) => setPaymentMode(e.target.value)}
                                    value={paymentMode}
                                >
                                    <Radio disabled={!stripeVerified} value={'Online'}>
                                        Online
                                    </Radio>
                                    <Radio
                                        disabled={consultationMode == 'Online'}
                                        value={'In-Person'}
                                    >
                                        In-Person
                                    </Radio>
                                </Radio.Group>
                            </div>

                            <Button
                                disabled={!selectedSlot && selectedSlot !== 0}
                                type='primary'
                                onClick={() => {
                                    console.log(
                                        chosenServiceObj,
                                        parseFloat(chosenServiceObj?.rate)
                                    );
                                    if (
                                        paymentMode == 'Online' &&
                                        parseFloat(chosenServiceObj?.rate) != 0
                                    ) {
                                        setPointer(2);
                                    } else {
                                        setPointer(3);
                                    }
                                }}
                            >
                                Proceed
                            </Button>
                        </>
                    ) : (
                        <p>No Timeslots Available on this day</p>
                    )}
                </div>
            </div>
        </div>
    );
}
export default ChooseDateAndTime;
