import { Modal, Collapse } from 'antd';
import React, { useState } from 'react';

const { Panel } = Collapse;

const CredentialModal = ({ open, onCancel, user, userType }) => {
    const [activeKey, setActiveKey] = useState(['1', '2', '3', '4', '5']);
    const [selectedPanel, setSelectedPanel] = useState('');
    const callback = (keys) => {
        setActiveKey(keys);
    };

    const handleChange = (value) => {
        if (value == '') {
            setSelectedPanel('');
            setActiveKey(['1', '2', '3', '4', '5']);
        } else {
            let actKeys = [];
            actKeys = [value];
            setActiveKey(actKeys);
            setSelectedPanel(value);
        }
    };
    let credentialTypes = ['Associations', 'Honors'];
    if (
        userType == 'DOCTOR' ||
        userType == 'NURSE' ||
        userType == 'CONSULTANT' ||
        userType == 'PHARMACIST' ||
        userType == 'THERAPIST' ||
        userType == 'CAREGIVER'
    ) {
        credentialTypes = ['Associations', 'Honors', 'Experiences', 'Educations', 'Admissions'];
    }

    return (
        <Modal
            open={open}
            onCancel={onCancel}
            onOk={onCancel}
            title='Credentials'
            centered
            width={800}
        >
            <Collapse defaultActiveKey={['1']} activeKey={activeKey} onChange={callback}>
                <Panel header='EXPERIENCE' key='1'>
                    {user && user.experiences && user.experiences.length !== 0 ? (
                        user.experiences.map((item, index) => (
                            <div className='general-intro-body-edu'>
                                <div className='general-intro-body-edu-col2' key={index}>
                                    <div className='genral-colums'>
                                        <p>
                                            Worked as {item.position} at {item.organization} in{' '}
                                            {item.location} for {item.duration} years{' '}
                                            {item.current ? '( Current )' : ''}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className='general-intro-body-edu'>
                            <p>No experience records available.</p>
                        </div>
                    )}
                </Panel>
                <Panel header='EDUCATION' key='2'>
                    <ul>
                        {user && user.educations && user.educations.length !== 0 ? (
                            user.educations.map((item, index) => (
                                <li className='general-intro-body-edu'>
                                    <div className='general-intro-body-edu-col2' key={index}>
                                        <div className='genral-colums'>
                                            <p>
                                                {item.major} on {item.degree} at {item.school} at
                                                year {item.graduation}{' '}
                                                {item.current ? '( Current )' : ''}
                                            </p>
                                        </div>
                                    </div>
                                </li>
                            ))
                        ) : (
                            <div className='general-intro-body-edu'>
                                <p>No education records available.</p>
                            </div>
                        )}
                    </ul>
                </Panel>
                <Panel header='ADMISSIONS' key='3'>
                    {user && user.admissions && user.admissions.length !== 0 ? (
                        user.admissions.map((item, index) => (
                            <div className='general-intro-body-edu'>
                                <div className='general-intro-body-edu-col2' key={index}>
                                    <div className='genral-colums'>
                                        {`Admitted in ${item.state} on ${item.date}.`}
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className='general-intro-body-edu'>
                            <p>No admission records available.</p>
                        </div>
                    )}
                </Panel>
                <Panel header='ASSOCIATIONS' key='4'>
                    {user && user.associations && user.associations.length !== 0 ? (
                        user.associations.map((item, index) => (
                            <div className='general-intro-body-edu'>
                                <div className='general-intro-body-edu-col2' key={index}>
                                    <div className='genral-colums'>
                                        <p>
                                            Associated with {item.organization} with {item.status}{' '}
                                            status at {item.date}{' '}
                                            {item.current ? '( Current )' : ''}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className='general-intro-body-edu'>
                            <p>No association records available.</p>
                        </div>
                    )}
                </Panel>

                <Panel header='HONORS & AWARDS' key={'5'}>
                    {user && user.honors && user.honors.length !== 0 ? (
                        user.honors.map((item, index) => (
                            <div className='general-intro-body-edu'>
                                <div className='general-intro-body-edu-col2' key={index}>
                                    <div className='genral-colums'>
                                        <p>
                                            {`Has been awarded by ${item.organization} at ${item.date}`}{' '}
                                            {item.current ? '( Current )' : ''}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        ))
                    ) : (
                        <div className='general-intro-body-edu'>
                            <p>No honor/award records available.</p>
                        </div>
                    )}
                </Panel>
            </Collapse>
        </Modal>
    );
};

export default CredentialModal;
