import * as Types from '../actions/types';
const init = {
    reviews: [],
    reviewsCount: 0,
    createdReview: null,
    recentReviews: []
};

const reviewReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_MY_REVIEWS: {
            return {
                ...state,
                reviews: action.payload.reviews,
                reviewsCount: action.payload.reviewsCount
            };
        }

        case Types.POST_REVIEW: {
            return {
                ...state,
                createdReview: action.payload.createdReview
            };
        }

        case Types.GET_RECENT_REVIEWS: {
            return {
                ...state,
                recentReviews: action.payload.recentReviews
            };
        }

        default:
            return state;
    }
};

export default reviewReducer;
