import React from 'react';
import { Modal, Switch, TimePicker, Select, Button } from 'antd';
import moment from 'moment';
import '../../Shared/customModal.css';
import { useSelector } from 'react-redux';
// import TimezonePicker from "react-bootstrap-timezone-picker";
// import "react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css";
const { Option, OptGroup } = Select;

const CreateAvailability = (props) => {
    const {
        createAvailabilityModal,
        setCreateAvailabilityModal,
        sunday,
        setSunday,
        monday,
        setMonday,
        tuesday,
        setTuesday,
        wednesday,
        setWednesday,
        thursday,
        setThursday,
        friday,
        setFriday,
        saturday,
        setSaturday,
        saveTimings,
        clearAvailabilityData,
        Availability,
        timezone,
        setTimezone,
        branches,
        selectedAddress,
        setSelectedAddress,
        selectedAddressIndex,
        schedule
    } = props;

    const renderOptions = () => {
        const timezones = moment.tz.names();
        let mappedValues = {};
        let regions = [];

        timezones.map((timezone) => {
            const splitTimezone = timezone.split('/');
            const region = splitTimezone[0];
            if (!mappedValues[region]) {
                mappedValues[region] = [];
                regions.push(region);
            }
            mappedValues[region].push(timezone);
        });
        return regions.map((region) => {
            const options = mappedValues[region].map((timezone) => {
                return <Option key={timezone}>{timezone}</Option>;
            });
            return (
                <OptGroup key={region} title={<div style={{ fontSize: 30 }}>{region}</div>}>
                    {options}
                </OptGroup>
            );
        });
    };

    const onChangeTimeRanges = (name, value) => {
        const days = {
            Sunday: setSunday,
            Monday: setMonday,
            Tuesday: setTuesday,
            Wednesday: setWednesday,
            Thursday: setThursday,
            Friday: setFriday,
            Saturday: setSaturday
        };
        console.log(value);
        if (days.hasOwnProperty(name)) {
            days[name]((prev) => ({
                ...prev,
                startTime: value[0],
                endTime: value[1],
                message: ''
            }));
        }
    };
    const onChange = async (name, value) => {
        const days = {
            Sunday: setSunday,
            Monday: setMonday,
            Tuesday: setTuesday,
            Wednesday: setWednesday,
            Thursday: setThursday,
            Friday: setFriday,
            Saturday: setSaturday
        };

        if (name === 'timezone') {
            setTimezone({
                ...timezone,
                value: value,
                message: ''
            });
        } else if (days.hasOwnProperty(name)) {
            const currentDay = days[name];
            currentDay((prev) => ({
                ...prev,
                active: value,
                startTime: value ? prev.startTime : '',
                endTime: value ? prev.endTime : '',
                message: value && !prev.startTime ? 'Time range is required' : ''
            }));
        }
    };
    let hospitalSchedule =
        branches[selectedAddressIndex] &&
        branches[selectedAddressIndex].availability &&
        branches[selectedAddressIndex].availability;
    const { user } = useSelector((state) => state.auth);
    const { errors } = Availability;
    const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];
    return (
        <Modal
            centered
            className='customModal multiPage'
            title={schedule ? 'Update Availability' : 'Create Availability'}
            open={createAvailabilityModal}
            onOk={() => {
                clearAvailabilityData();
                setCreateAvailabilityModal(false);
            }}
            onCancel={() => {
                clearAvailabilityData();
                setCreateAvailabilityModal(false);
            }}
            footer={null}
        >
            {hospitalSchedule ? (
                <>
                    <div className='hospital-view-schedule' style={{ marginBottom: 30 }}>
                        <p>Hospital Schedule</p>
                        <small>
                            TimeZone:{' '}
                            <span>
                                {hospitalSchedule.timezone
                                    ? hospitalSchedule.timezone
                                    : 'No Timezone selected'}
                            </span>{' '}
                        </small>
                        {daysOfWeek.map((day) =>
                            hospitalSchedule && hospitalSchedule[day.toLowerCase()] ? (
                                <small key={day}>
                                    {day}:{' '}
                                    <span>
                                        {hospitalSchedule[day.toLowerCase()].startTime}-
                                        {hospitalSchedule[day.toLowerCase()].endTime}
                                    </span>
                                </small>
                            ) : null
                        )}
                    </div>
                </>
            ) : user.hospital && (
                <div className='hospital-view-timezone'>
                    <p>Schedule not fixed yet</p>
                </div>
            )}

            <p style={{ textAlign: 'center' }}>
                Select the hours during which you wish to offer your services.
            </p>
            {errors && (
                <div className='errorMsgs'>
                    {errors && errors.map((it, ind) => <p className='error'>{it}</p>)}
                </div>
            )}
            {branches &&
                        branches.length !== 0 && (
                <Select
                    style={{ marginBottom: 20 }}
                    placeholder='Select Address'
                    value={selectedAddress}
                    disabled
                    onChange={(val, obj) => {
                        setSelectedAddress(val);
                    }}
                >
                    {branches &&
                        branches.length !== 0 &&
                        branches.map((it, ind) => (
                            <Option key={ind} value={it._id}>
                                location {ind + 1} -{' '}
                                {it.location &&
                                    it.location.branchAddress &&
                                    it.location.branchAddress}
                            </Option>
                        ))}
                </Select>
            )}

            {!user.hospitalBranches.length && (
                <>
                    <Select
                        style={{ margin: '20px 0px', height: '32px !important' }}
                        placeholder='Select Timezone'
                        value={timezone.value || undefined}
                        onChange={(val) => {
                            onChange('timezone', val);
                        }}
                        showSearch
                    >
                        {renderOptions()}
                    </Select>
                    {timezone.message && (
                        <p className='error'>
                            <i className='fe fe-alert-triangle' />{' '}
                            {timezone.message && timezone.message}
                        </p>
                    )}
                </>
            )}

            <label className='timePick' htmlFor='none'>
                <div style={{ marginBottom: 10 }}>
                    <Switch
                        name='Sunday'
                        checked={sunday.active}
                        onChange={(value) => onChange('Sunday', value)}
                        style={{ marginRight: 20 }}
                    />
                    Sunday
                </div>
                <TimePicker.RangePicker
                    value={
                        sunday.startTime &&
                        sunday.endTime && [
                            moment(sunday.startTime, 'HH:mm'),
                            moment(sunday.endTime, 'HH:mm')
                        ]
                    }
                    defaultValue={[
                        moment().startOf('day'), // Set default start time to the beginning of the day
                        moment().startOf('day') // Set default end time to the beginning of the day
                    ]}
                    disabled={!sunday.active}
                    onChange={(e, value) => onChangeTimeRanges('Sunday', value)}
                    minuteStep={30}
                    format={'HH:mm'}
                />
                {sunday.message && (
                    <p className='error'>
                        <i className='fe fe-alert-triangle' /> {sunday.message && sunday.message}
                    </p>
                )}
            </label>
            <label className='timePick' htmlFor='none'>
                <div style={{ marginBottom: 10 }}>
                    <Switch
                        name='Monday'
                        checked={monday.active}
                        onChange={(value) => onChange('Monday', value)}
                        style={{ marginRight: 20 }}
                    />
                    Monday
                </div>

                <TimePicker.RangePicker
                    value={
                        monday.startTime &&
                        monday.endTime && [
                            moment(monday.startTime, 'HH:mm'),
                            moment(monday.endTime, 'HH:mm')
                        ]
                    }
                    onChange={(e, value) => onChangeTimeRanges('Monday', value)}
                    disabled={!monday.active}
                    minuteStep={30}
                    format={'HH:mm'}
                />
                {monday.message && (
                    <p className='error'>
                        <i className='fe fe-alert-triangle' /> {monday.message && monday.message}
                    </p>
                )}
            </label>
            <label className='timePick' htmlFor='none'>
                <div style={{ marginBottom: 10 }}>
                    <Switch
                        name='Tuesday'
                        onChange={(value) => onChange('Tuesday', value)}
                        checked={tuesday.active}
                        style={{ marginRight: 20 }}
                    />
                    Tuesday
                </div>

                <TimePicker.RangePicker
                    value={
                        tuesday.startTime &&
                        tuesday.endTime && [
                            moment(tuesday.startTime, 'HH:mm'),
                            moment(tuesday.endTime, 'HH:mm')
                        ]
                    }
                    onChange={(e, value) => onChangeTimeRanges('Tuesday', value)}
                    disabled={!tuesday.active}
                    minuteStep={30}
                    format={'HH:mm'}
                />
                {tuesday.message && (
                    <p className='error'>
                        <i className='fe fe-alert-triangle' /> {tuesday.message && tuesday.message}
                    </p>
                )}
            </label>
            <label className='timePick' htmlFor='none'>
                <div style={{ marginBottom: 10 }}>
                    <Switch
                        name='Wednesday'
                        onChange={(value) => onChange('Wednesday', value)}
                        checked={wednesday.active}
                        style={{ marginRight: 20 }}
                    />
                    Wednesday
                </div>

                <TimePicker.RangePicker
                    value={
                        wednesday.startTime &&
                        wednesday.endTime && [
                            moment(wednesday.startTime, 'HH:mm'),
                            moment(wednesday.endTime, 'HH:mm')
                        ]
                    }
                    onChange={(e, value) => onChangeTimeRanges('Wednesday', value)}
                    disabled={!wednesday.active}
                    minuteStep={30}
                    format={'HH:mm'}
                />
                {wednesday.message && (
                    <p className='error'>
                        <i className='fe fe-alert-triangle' />{' '}
                        {wednesday.message && wednesday.message}
                    </p>
                )}
            </label>
            <label className='timePick' htmlFor='none'>
                <div style={{ marginBottom: 10 }}>
                    <Switch
                        name='Thursday'
                        checked={thursday.active}
                        onChange={(value) => onChange('Thursday', value)}
                        style={{ marginRight: 20 }}
                    />
                    Thursday
                </div>

                <TimePicker.RangePicker
                    value={
                        thursday.startTime &&
                        thursday.endTime && [
                            moment(thursday.startTime, 'HH:mm'),
                            moment(thursday.endTime, 'HH:mm')
                        ]
                    }
                    onChange={(e, value) => onChangeTimeRanges('Thursday', value)}
                    disabled={!thursday.active}
                    minuteStep={30}
                    format={'HH:mm'}
                />
                {thursday.message && (
                    <p className='error'>
                        <i className='fe fe-alert-triangle' />{' '}
                        {thursday.message && thursday.message}
                    </p>
                )}
            </label>
            <label className='timePick' htmlFor='none'>
                <div style={{ marginBottom: 10 }}>
                    <Switch
                        name='Friday'
                        checked={friday.active}
                        onChange={(value) => onChange('Friday', value)}
                        style={{ marginRight: 20 }}
                    />
                    Friday
                </div>

                <TimePicker.RangePicker
                    value={
                        friday.startTime &&
                        friday.endTime && [
                            moment(friday.startTime, 'HH:mm'),
                            moment(friday.endTime, 'HH:mm')
                        ]
                    }
                    onChange={(e, value) => onChangeTimeRanges('Friday', value)}
                    disabled={!friday.active}
                    minuteStep={30}
                    format={'HH:mm'}
                />

                {friday.message && (
                    <p className='error'>
                        <i className='fe fe-alert-triangle' /> {friday.message && friday.message}
                    </p>
                )}
            </label>
            <label className='timePick' htmlFor='none'>
                <div style={{ marginBottom: 10 }}>
                    <Switch
                        name='Saturday'
                        checked={saturday.active}
                        onChange={(value) => onChange('Saturday', value)}
                        style={{ marginRight: 20 }}
                    />
                    Saturday
                </div>

                <TimePicker.RangePicker
                    value={
                        saturday.startTime &&
                        saturday.endTime && [
                            moment(saturday.startTime, 'HH:mm'),
                            moment(saturday.endTime, 'HH:mm')
                        ]
                    }
                    onChange={(e, value) => onChangeTimeRanges('Saturday', value)}
                    disabled={!saturday.active}
                    minuteStep={30}
                    format={'HH:mm'}
                />
                {saturday.message && (
                    <p className='error'>
                        <i className='fe fe-alert-triangle' />{' '}
                        {saturday.message && saturday.message}
                    </p>
                )}
            </label>

            <Button type='primary' onClick={() => saveTimings()}>
                Save Timings
            </Button>
        </Modal>
    );
};

export default CreateAvailability;
