import HospitalLayout from '../components/NewProfile/HospitalProfile/HospitalLayout';
import PatientLayout from '../components/NewProfile/PatientProfile/PatientLayout';
import DoctorLayout from '../components/NewProfile/DoctorProfile/DoctorLayout';
import NurseLayout from '../components/NewProfile/NurseProfile/NurseLayout'
import ConsultantLayout from '../components/NewProfile/ConsultantProfile/ConsultantLayout';
import PharmacyLayout from '../components/NewProfile/PharmacyProfile/PharmacyLayout';
import CaregiverLayout from '../components/NewProfile/CaregiverProfile/CaregiverLayout';
import TherapistLayout from '../components/NewProfile/TherapistProfile/TherapistLayout';


export default [
    {
        path: '/patient/',
        component: PatientLayout
    },
    {
        path: '/hospital/',
        component: HospitalLayout
    },
    {
        path: '/doctor/',
        component: DoctorLayout
    },
    {
        path: '/nurse/',
        component: NurseLayout
    },
    {
        path: '/consultant/',
        component: ConsultantLayout   
    },
    {
        path: '/pharmacy/',
        component: PharmacyLayout
    },
    {
        path: '/caregiver/',
        component: CaregiverLayout   
    },
    {
        path: '/therapist/',
        component: TherapistLayout
    }
];
