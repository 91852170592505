import React from 'react';

function PrivateNotes(props) {
    const { privateNotes, setPrivateNotes } = props;
    return (
        <div className='noteWindow'>
            <div className='head'>Private Notes</div>

            <div className='body'>
                {' '}
                <textarea
                    value={privateNotes}
                    onChange={(e) => setPrivateNotes(e.target.value)}
                    placeholder='Private Notes'
                />
            </div>
        </div>
    );
}

export default PrivateNotes;
