import * as Types from '../actions/types';
const init = {
    myPayments: [],
    myPaymentsCount: 0
};

const paymentReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_MY_PAYMENTS: {
            return {
                ...state,
                myPayments: action.payload.myPayments,
                myPaymentsCount: action.payload.count
            };
        }

        case Types.LOGOUT: {
            return init
        }

        default:
            return state;
    }
};

export default paymentReducer;
