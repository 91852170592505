export const Allergies = [
    'Seasonal Allergic Rhinitis',
    'Perennial Allergic Rhinitis',
    'Food Allergies',
    'Insect Sting Allergies',
    'Drug Allergies',
    'Contact Dermatitis',
    'Latex Allergy',
    'Allergic Conjunctivitis',
    'Not Applicable',
    'Other'
];

export const AllergiesOptions = Allergies.reduce((acc, value, index) => {
    return (acc = [...acc, { value: value, id: index + 1 }]);
}, []);
