import io from 'socket.io-client';
var Url = window.location.protocol + '//' + window.location.host + '/';

let { REACT_APP_API_WEBSOCKETS_URI } = process.env;

if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_WEBSOCKETS_URI = 'http://localhost:5000';
}

export const socket = io(REACT_APP_API_WEBSOCKETS_URI, {
    forceNew: true,
    transports: ['websocket'],
    withCredentials: true,
    extraHeaders: {
        'my-custom-header': 'abcd'
    }
});
console.log('socket', socket)