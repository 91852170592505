import React from 'react';
import { DatePicker, Tooltip } from 'antd';
import { Input, CurrencyDropdown, SelectComponent } from '../../Shared/ProfileComponents';
import ProfileSection from '../../DoctorProfile/ProfileSection';

const AccountHolder = ({
    moment,
    dateFormat,
    genderOptions,
    languageOptions,
    filteredCurrencies,
    Currencies,
    onChange,
    setState,
    curr,
    state,
    profileSummary,
    currencyDrop,
    currency,
    onChangeDate
}) => {
    const fields = [
        { field: 'firstName', label: 'First Name', required: true },
        { field: 'lastName', label: 'Last Name', required: true }
    ];
    return (
        <ProfileSection title='Account Holder Information'>
            <div className='input-row'>
                {fields?.slice(0, 2).map((item, index) => (
                    <Input
                        key={`${index}-${item?.field}`}
                        label={item.label}
                        item={state[item.field]}
                        name={item.field}
                        placeholder={item.label}
                        type='text'
                        value={state[item.field]?.value}
                        onChange={onChange}
                        required
                    />
                ))}
            </div>
            <div className='input-row'>
                <SelectComponent
                    item={state.language}
                    value={state.language.value}
                    isValid={state.language.isValid}
                    message={state.language.message}
                    label='Language'
                    required
                    name='language'
                    setState={(obj) => {
                        console.log(obj);
                        setState(obj);
                    }}
                    options={languageOptions}
                />
                <SelectComponent
                    item={state.gender}
                    value={state.gender.value}
                    isValid={state.gender.isValid}
                    message={state.gender.message}
                    label='Gender'
                    name='gender'
                    required
                    setState={(obj) => {
                        console.log(obj);
                        setState(obj);
                    }}
                    options={genderOptions}
                />
            </div>

            <div className='input-row '>
                {/* <Input
                    value={profileSummary?.value}
                    className='textareaLabel'
                    label='Profile Summary'
                    item={profileSummary}
                    name='profileSummary'
                    placeholder='Profile Summary'
                    type='text'
                    textarea={true}
                    onChange={(e) =>
                        setState({
                            formDirty: true,
                            profileSummary: { ...profileSummary, value: e.target.value }
                        })
                    }
                /> */}

                <div className='input-row'>
                    <label className={!state.dob.isValid ? 'error' : ''}>
                        <div>
                            Date of Birth<span style={{ color: 'red' }}>*</span>:
                        </div>

                        <DatePicker
                            name='dob'
                            placeholder='Date of Birth'
                            className='profile-datepicker' // disabledDate={d => !d || d.isBefore(moment(new Date()).format('DD/MM/YYYY'))  }
                            value={state.dob.value ? moment(state.dob.value, dateFormat) : ''}
                            onChange={onChangeDate}
                        />
                        {state.dob.message && (
                            <p>
                                {' '}
                                <i className='fe fe-alert-triangle' /> {state.dob.message}
                            </p>
                        )}
                    </label>

                    <CurrencyDropdown
                        required
                        currencyDrop={currencyDrop}
                        currency={currency}
                        stateFilteredCurrencies={filteredCurrencies}
                        filteredCurrencies={filteredCurrencies}
                        setState={(obj) => setState(obj)}
                        curr={curr}
                        Currencies={Currencies}
                        value={state.currency.value}
                    />
                </div>
            </div>
        </ProfileSection>
    );
};

export default AccountHolder;
