//Auth Types
export const SET_USER = 'SET_USER';
export const USER_ERRORS = 'USER_ERRORS';
export const CREATE_USER = 'CREATE_USER';
export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SEND_OTP = 'SEND_OTP';
export const VERIFY_OTP = 'VERIFY_OTP';
export const ERRORS = 'ERRORS';
export const SET_COUNTRY_INFO = 'SET_COUNTRY_INFO';
export const LOGOUT = 'LOGOUT';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_CUSTOMERUSER_PROFILE = 'GET_CUSTOMERUSER_PROFILE';
export const GET_DOCTORUSER_PROFILE = 'GET_DOCTORUSER_PROFILE';
export const VIEW_CLIENT_PROFILE = 'VIEW_CLIENT_PROFILE';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const PROFILE_COMPLETION_ERROR = 'PROFILE_COMPLETION_ERROR';
export const CLEAR_PROFILE_COMPLETION_ERROR = 'CLEAR_PROFILE_COMPLETION_ERROR';
export const CHOOSE_FROM_USERS = 'CHOOSE_FROM_USERS';
export const GET_GOOGLE_CALENDAR_EVENTS = 'GET_GOOGLE_CALENDAR_EVENTS';
export const GET_OUTLOOK_CALENDAR_EVENTS = 'GET_OUTLOOK_CALENDAR_EVENTS';
export const GET_CALENDAR_EVENTS = 'GET_CALENDAR_EVENTS';
export const RESEND_VERIFICATION_EMAIL = 'RESEND_VERIFICATION_EMAIL'
export const VERIFY_EMAIL_OR_PHONE_OTP = 'VERIFY_EMAIL_OR_PHONE_OTP'

//Auth Modal Types
export const SET_SIGN_IN_MODAL = 'SET_SIGN_IN_MODAL';
export const SET_SIGN_UP_MODAL = 'SET_SIGN_UP_MODAL';
export const SET_SIGN_IN_WITH_PHONE_MODAL = 'SET_SIGN_IN_WITH_PHONE_MODAL';
export const SET_SIGN_UP_WITH_PHONE_MODAL = 'SET_SIGN_UP_WITH_PHONE_MODAL';
export const SET_FORGOT_PASS_MODAL = 'SET_FORGOT_PASS_MODAL';
export const SET_MODAL_USERTYPE = 'SET_MODAL_USERTYPE';
export const SET_REG_PROP = 'SET_REG_PROP';
export const SET_EMAIL_VERIFIED_MSG = 'SET_EMAIL_VERIFIED_MSG';
export const SET_RESET_PASS_MODAL = 'SET_RESET_PASS_MODAL';
export const CLEAR_AUTH_MODALS_STATE = 'CLEAR_AUTH_MODALS_STATE';

//Admin Types
export const SET_ADMIN = 'SET_ADMIN';
export const ADMINS_ERROR = 'ADMINS_ERROR';
export const GET_PACKAGES = 'GET_PACKAGES';
export const GET_HOSPITALUSER_PROFILE = 'GET_HOSPITALUSER_PROFILE';

//Blog Types
export const GET_POSTS = 'GET_POSTS';
export const GET_POPULAR_POSTS = 'GET_POPULAR_POSTS';
export const GET_RECENT_POSTS = 'GET_RECENT_POSTS';
export const GET_POSTS_USING_SEARCH = 'GET_POSTS_USING_SEARCH';
export const GET_SINGLE_POST = 'GET_SINGLE_POST';
export const GET_USER_POSTS = 'GET_USER_POSTS';
export const DELETE_POST = 'DELETE_POST';
export const CREATE_POST = 'CREATE_POST';
export const UPDATE_POST = 'UPDATE_POST';
export const GET_COMMENTS = 'GET_COMMENTS';
export const DELETE_COMMENT = 'DELETE_COMMENTS';
export const POST_COMMENT = 'DELETE_COMMENTS';
export const BLOG_USER = 'BLOG_USER';
export const GET_GENERAL_POSTS = 'GET_GENERAL_POSTS';
export const GET_HOMEPAGE_POSTS = 'GET_HOMEPAGE_POSTS';
export const GET_SEARCH_POSTS = 'GET_SEARCH_POSTS';
export const CLEAR_SEARCH_POSTS = 'CLEAR_SEARCH_POSTS';

export const GET_PRODUCT_TYPES = 'GET_PRODUCT_TYPES';

//Messaging Types
export const GET_CHATLIST = 'GET_CHATLIST';
export const CREATE_CONVERSATION = 'CREATE_CONVERSATION';
export const SEND_MESSAGE = 'SEND_MESSAGE';
export const GET_MESSAGES = 'GET_MESSAGES';

// Hospital Types
export const GET_HOSPITALS = 'GET_HOSPITALS';
export const GET_SINGLE_HOSPITAL = 'GET_SINGLE_HOSPITAL';
export const CLEAR_HOSPITALS = 'CLEAR_HOSPITALS';
export const CREATE_DOCTOR = 'CREATE_DOCTOR';
export const GET_PRACTICE_AREAS = 'GET_PRACTICE_AREAS';
export const GET_SPECIALIZED_ROLES = 'GET_SPECIALIZED_ROLES';
export const GET_HOSPITAL_SERVICES = 'GET_HOSPITAL_SERVICES';
export const GET_DOCUMENT_TYPES = 'GET_DOCUMENT_TYPES';
export const GET_HOSPITAL_SEARCH_RESULTS = 'GET_HOSPITAL_SEARCH_RESULTS';
export const CLEAR_HOSPITAL_SEARCH_RESULTS = 'CLEAR_HOSPITAL_SEARCH_RESULTS';
export const GET_HOSPITAL_SINGLE_DOCTORS = 'GET_HOSPITAL_SINGLE_DOCTORS';
export const GET_HOSPITAL_STAT_COUNTS = 'GET_HOSPITAL_STAT_COUNTS';
export const GET_HOSPITAL_EXTRACTED_TIME_SLOTS = 'GET_HOSPITAL_EXTRACTED_TIME_SLOTS';
export const GET_HOSPITAL_FOR_APPOINTMENT = 'GET_HOSPITAL_FOR_APPOINTMENT';
export const GET_SELF_TIME_SLOTS = 'GET_SELF_TIME_SLOTS';
export const GET_MY_DOCTOR_TIME_SLOTS = 'GET_MY_DOCTOR_TIME_SLOTS';
export const GET_SHORT_DOCTOR_LIST = 'GET_SHORT_DOCTOR_LIST';
export const GET_FEATURED_HOSPITALS = 'GET_FEATURED_HOSPITALS';
export const GET_LINE_CHART_DATA = 'GET_LINE_CHART_DATA';
export const GET_PIE_CHART_DATA = 'GET_PIE_CHART_DATA';
export const GET_HOSPITAL_DASHBOARD = 'GET_HOSPITAL_DASHBOARD'
export const GET_HOSPITAL_MEMBERS = 'GET_HOSPITAL_MEMBERS'
export const GET_HOSPITAL_SINGLE_MEMBERS = 'GET_HOSPITAL_SINGLE_MEMBERS'
export const GET_ORGANIZATION_MATCH = 'GET_ORGANIZATION_MATCH'

// Pharmacy Types
export const GET_PHARMACIES = 'GET_PHARMACIES';
export const GET_SINGLE_PHARMACY = 'GET_SINGLE_PHARMACY';
export const CLEAR_PHARMACIES = 'CLEAR_PHARMACIES';
export const GET_PHARMACY_SERVICES = 'GET_PHARMACY_SERVICES';
export const GET_PHARMACY_SEARCH_RESULTS = 'GET_PHARMACY_SEARCH_RESULTS';
export const CLEAR_PHARMACY_SEARCH_RESULTS = 'CLEAR_PHARMACY_SEARCH_RESULTS';
export const GET_PHARMACY_STAT_COUNTS = 'GET_PHARMACY_STAT_COUNTS';
export const GET_PHARMACY_EXTRACTED_TIME_SLOTS = 'GET_PHARMACY_EXTRACTED_TIME_SLOTS';
export const GET_PHARMACY_FOR_APPOINTMENT = 'GET_PHARMACY_FOR_APPOINTMENT';
export const GET_FEATURED_PHARMACIES = 'GET_FEATURED_PHARMACIES';
export const GET_PHARMACY_DASHBOARD = 'GET_PHARMACY_DASHBOARD'


// Doctor Types
export const GET_DOCTORS = 'GET_DOCTORS';
export const GET_SINGLE_DOCTOR = 'GET_SINGLE_DOCTOR';
export const GET_HOSPITAL_DOCTORS = 'GET_HOSPITAL_DOCTORS';
export const CLEAR_DOCTORS = 'CLEAR_DOCTORS';
export const GET_DOCTOR_SEARCH_RESULTS = 'GET_DOCTOR_SEARCH_RESULTS';
export const CLEAR_DOCTOR_SEARCH_RESULTS = 'CLEAR_DOCTOR_SEARCH_RESULTS';
export const GET_DOCTOR_STAT_COUNTS = 'GET_DOCTOR_STAT_COUNTS';
export const GET_DOCTOR_EXTRACTED_TIME_SLOTS = 'GET_DOCTOR_EXTRACTED_TIME_SLOTS';
export const GET_DOCTOR_FOR_APPOINTMENT = 'GET_DOCTOR_FOR_APPOINTMENT';
export const GET_FEATURED_DOCTORS = 'GET_FEATURED_DOCTORS';
export const GET_MY_WEEKLY_STATISTICS = 'GET_MY_WEEKLY_STATISTICS';
export const GET_MY_SERVICES = 'GET_MY_SERVICES';
export const GET_DOCTOR_DASHBOARD = 'GET_DOCTOR_DASHBOARD'


// Nurse Types
export const GET_NURSES = 'GET_NURSES';
export const GET_SINGLE_NURSE = 'GET_SINGLE_NURSE';
export const CLEAR_NURSES = 'CLEAR_NURSES';
export const GET_FEATURED_NURSES = 'GET_FEATURED_NURSES';
export const GET_NURSE_FOR_APPOINTMENT = 'GET_NURSE_FOR_APPOINTMENT';
export const GET_NURSE_EXTRACTED_TIME_SLOTS = 'GET_NURSE_EXTRACTED_TIME_SLOTS';
export const GET_NURSE_STAT_COUNTS = 'GET_NURSE_STAT_COUNTS';
export const CLEAR_NURSE_SEARCH_RESULTS = 'CLEAR_NURSE_SEARCH_RESULTS';
export const GET_NURSE_SEARCH_RESULTS = 'GET_NURSE_SEARCH_RESULTS';
export const GET_NURSE_DASHBOARD = 'GET_NURSE_DASHBOARD'

// Therapist Types
export const GET_THERAPISTS = 'GET_THERAPISTS';
export const GET_SINGLE_THERAPIST = 'GET_SINGLE_THERAPIST';
export const CLEAR_THERAPISTS = 'CLEAR_THERAPISTS';
export const GET_FEATURED_THERAPISTS = 'GET_FEATURED_THERAPISTS';
export const GET_THERAPIST_FOR_APPOINTMENT = 'GET_THERAPIST_FOR_APPOINTMENT';
export const GET_THERAPIST_EXTRACTED_TIME_SLOTS = 'GET_THERAPIST_EXTRACTED_TIME_SLOTS';
export const GET_THERAPIST_STAT_COUNTS = 'GET_THERAPIST_STAT_COUNTS';
export const CLEAR_THERAPIST_SEARCH_RESULTS = 'CLEAR_THERAPIST_SEARCH_RESULTS';
export const GET_THERAPIST_SEARCH_RESULTS = 'GET_THERAPIST_SEARCH_RESULTS';
export const GET_THERAPIST_DASHBOARD = 'GET_THERAPIST_DASHBOARD'


// Caregiver Types
export const GET_CAREGIVERS = 'GET_CAREGIVERS';
export const GET_SINGLE_CAREGIVER = 'GET_SINGLE_CAREGIVER';
export const CLEAR_CAREGIVERS = 'CLEAR_CAREGIVERS';
export const GET_FEATURED_CAREGIVERS = 'GET_FEATURED_CAREGIVERS';
export const GET_CAREGIVER_FOR_APPOINTMENT = 'GET_CAREGIVER_FOR_APPOINTMENT';
export const GET_CAREGIVER_EXTRACTED_TIME_SLOTS = 'GET_CAREGIVER_EXTRACTED_TIME_SLOTS';
export const GET_CAREGIVER_STAT_COUNTS = 'GET_CAREGIVER_STAT_COUNTS';
export const CLEAR_CAREGIVER_SEARCH_RESULTS = 'CLEAR_CAREGIVER_SEARCH_RESULTS';
export const GET_CAREGIVER_SEARCH_RESULTS = 'GET_CAREGIVER_SEARCH_RESULTS';
export const GET_CAREGIVER_DASHBOARD = 'GET_CAREGIVER_DASHBOARD'


// Consultant Types
export const GET_CONSULTANTS = 'GET_CONSULTANTS';
export const GET_SINGLE_CONSULTANT = 'GET_SINGLE_CONSULTANT';
export const CLEAR_CONSULTANTS = 'CLEAR_CONSULTANTS';
export const GET_FEATURED_CONSULTANTS = 'GET_FEATURED_CONSULTANTS';
export const GET_CONSULTANT_FOR_APPOINTMENT = 'GET_CONSULTANT_FOR_APPOINTMENT';
export const GET_CONSULTANT_EXTRACTED_TIME_SLOTS = 'GET_CONSULTANT_EXTRACTED_TIME_SLOTS';
export const GET_CONSULTANT_STAT_COUNTS = 'GET_CONSULTANT_STAT_COUNTS';
export const CLEAR_CONSULTANT_SEARCH_RESULTS = 'CLEAR_CONSULTANT_SEARCH_RESULTS';
export const GET_CONSULTANT_SEARCH_RESULTS = 'GET_CONSULTANT_SEARCH_RESULTS';
export const GET_CONSULTANT_DASHBOARD = 'GET_CONSULTANT_DASHBOARD'

// Marketing Types
export const SET_BANNER = 'SET_BANNER';

//Favourite Types
export const ADD_TO_FAVOURITES = 'ADD_TO_FAVOURITES';
export const GET_FAVOURITES = 'GET_FAVOURITES';
export const REMOVE_FROM_FAVOURITES = 'REMOVE_FROM_FAVOURITES';
export const GET_USER_VIEW_FAVOURITES = 'GET_USER_VIEW_FAVOURITES';

//Notification Types
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';

//Review Types
export const GET_MY_REVIEWS = 'GET_MY_REVIEWS';
export const POST_REVIEW = 'POST_REVIEW';
export const GET_RECENT_REVIEWS = 'GET_RECENT_REVIEWS';
export const GET_CLIENT_REVIEWS = 'GET_CLIENT_REVIEWS';

//Marketing Types
export const GET_MARKETING_INFO = 'GET_MARKETING_INFO';

//Availability types
export const GET_AVAILABILITY = 'GET_AVAILABILITY';
export const CREATE_AVAILABILITY = 'CREATE_AVAILABILITY';
export const UPDATE_AVAILABILITY = 'UPDATE_AVAILABILITY';
export const AVAILABILITY_ERROR = 'AVAILABILITY_ERROR';
export const GET_VIEW_AVAILABILITY = 'GET_VIEW_AVAILABILITY';
export const CLEAR_AVAILABILITY_ERROR = 'CLEAR_AVAILABILITY_ERROR';

//Admin types
export const GET_ALL_USER_COUNT = 'GET_ALL_USER_COUNT';
export const GET_CUSTOMER_USERS = 'GET_CUSTOMER_USERS';
export const GET_CUSTOMER_PROFILE = 'GET_CUSTOMER_PROFILE';
export const GET_HOSPITAL_USERS = 'GET_HOSPITAL_USERS';
export const GET_HOSPITAL_PROFILE = 'GET_HOSPITAL_PROFILE';
export const GET_DOCTOR_USERS = 'GET_DOCTOR_USERS';
export const GET_DOCTOR_PROFILE = 'GET_DOCTOR_PROFILE';
export const GET_ADMIN_USERS = 'GET_ADMIN_USERS';
export const GET_ADMIN_PROFILE = 'GET_ADMIN_PROFILE';
export const GET_ALL_BLOGS_ADMIN = 'GET_ALL_BLOGS_ADMIN';
export const GET_REPORTED_PORTFOLIO_ITEMS = 'GET_REPORTED_PORTFOLIO_ITEMS';
export const SAVE_ADMIN_PROFILE = 'SAVE_ADMIN_PROFILE';
export const GET_EMAIL_TEMPLATES = 'GET_EMAIL_TEMPLATES';
export const GET_SINGLE_EMAIL_TEMPLATE = 'GET_SINGLE_EMAIL_TEMPLATE';
export const GET_MAIL_LISTINGS = 'GET_MAIL_LISTINGS';
export const GET_MAIL_LISTINGS_COUNT = 'GET_MAIL_LISTINGS_COUNT';

//Stripe types
// export const GET_STRIPE_PRODUCTS = "GET_STRIPE_PRODUCTS";
// export const GET_STRIPE_CUSTOMERS = "GET_STRIPE_CUSTOMERS";
// export const GET_STRIPE_SUBSCRIPTIONS = "GET_STRIPE_SUBSCRIPTIONS";
// export const GET_STRIPE_INVOICES = "GET_STRIPE_INVOICES";
// export const CANCEL_BUSINESS_SUBSCRIPTION = "CANCEL_BUSINESS_SUBSCRIPTION";
// export const CHANGE_STRIPE_PAYMENT_METHOD = "CHANGE_STRIPE_PAYMENT_METHOD";
// export const CHANGE_STRIPE_SUBSCRIPTION = "CHANGE_STRIPE_SUBSCRIPTION";
// export const CHANGE_STRIPE_VERIFICATION_URL = "CHANGE_STRIPE_VERIFICATION_URL";
export const CHANGE_STRIPE_ACCOUNT_LINK = 'CHANGE_STRIPE_ACCOUNT_LINK';

//Prospect types
export const GET_PROSPECTS = 'GET_PROSPECTS';

//Branch types
export const GET_BRANCHES = 'GET_BRANCHES';

//General types
export const GET_CUSTOMER_MAILS = 'GET_CUSTOMER_MAILS';
export const GET_USER_STAT_COUNTS = 'GET_USER_STAT_COUNTS';
export const GET_SEARCH_SUGGESTIONS = 'GET_SEARCH_SUGGESTIONS';
export const CLEAR_APPOINTMENT_DOCTOR_HOSPITAL = 'CLEAR_APPOINTMENT_DOCTOR_HOSPITAL';
export const UPDATE_PRIVACY_POLICY_MODAL = 'UPDATE_PRIVACY_POLICY_MODAL'
export const UPDATE_TERMS_MODAL = 'UPDATE_TERMS_MODAL'
export const UPDATE_GUIDELINES_MODAL = 'UPDATE_GUIDELINES_MODAL'

//Document Types
export const GET_DOCUMENT_LIST = 'GET_DOCUMENT_LIST';
export const GET_ALL_DOCUMENT_TYPES = 'GET_ALL_DOCUMENT_TYPES';

//Appointment Types
export const GET_MY_APPOINTMENTS = 'GET_MY_APPOINTMENTS';
export const GET_APPOINTMENT_HOSPITALS = 'GET_APPOINTMENT_HOSPITALS';
export const GET_APPOINTMENT_DOCTORS = 'GET_APPOINTMENT_DOCTORS';
export const GET_VIDEO_TOKEN = 'GET_VIDEO_TOKEN';
export const GET_SINGLE_APPOINTMENT = 'GET_SINGLE_APPOINTMENT';
export const GET_AVAILABLE_SERVICES = 'GET_AVAILABLE_SERVICES';
export const SET_APPOINTMENT_ERROR = 'SET_APPOINTMENT_ERROR';

//Stripe Types
export const IS_STRIPE_VERIFIED = 'IS_STRIPE_VERIFIED';
export const GET_STRIPE_BALANCE = 'GET_STRIPE_BALANCE';
export const GET_STRIPE_DASHBOARD = 'GET_STRIPE_DASHBOARD';
export const GET_EXTERNAL_ACCOUNTS = 'GET_EXTERNAL_ACCOUNTS';
export const GET_CUSTOMER_STRIPE_PAYMENT_METHODS = 'GET_CUSTOMER_STRIPE_PAYMENT_METHODS';

export const GET_STRIPE_PRODUCTS = 'GET_STRIPE_PRODUCTS';
export const GET_STRIPE_CUSTOMERS = 'GET_STRIPE_CUSTOMERS';
export const GET_STRIPE_SUBSCRIPTIONS = 'GET_STRIPE_SUBSCRIPTIONS';
export const GET_STRIPE_INVOICES = 'GET_STRIPE_INVOICES';
export const CANCEL_BUSINESS_SUBSCRIPTION = 'CANCEL_BUSINESS_SUBSCRIPTION';
export const CHANGE_STRIPE_PAYMENT_METHOD = 'CHANGE_STRIPE_PAYMENT_METHOD';
export const CHANGE_STRIPE_SUBSCRIPTION = 'CHANGE_STRIPE_SUBSCRIPTION';

//Payment Types
export const GET_MY_PAYMENTS = 'GET_MY_PAYMENTS';


export const UNLOCK_DOCUMENTS = 'UNLOCK_DOCUMENTS';