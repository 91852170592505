import React from 'react';
import { Modal } from 'antd';
import ReactStars from 'react-rating-stars-component';

export default function ReviewModal({
    modalOpen,
    handleCancel,
    rating,
    setRating,
    shortDescription,
    setShortDescription,
    description,
    setDescription,
    addReview
}) {
    return (
        (<Modal
            className='addReview'
            title='Add a Review'
            open={modalOpen}
            onCancel={handleCancel}
            footer={null}
        >
            <label>
                <span>Rating</span>
                <ReactStars
                    style={{
                        justifyContent: 'center !important'
                    }}
                    value={rating}
                    edit={true}
                    onChange={(value) => setRating(value)}
                    count={5}
                    size={25}
                    activeColor='goldenrod'
                />
            </label>
            <label>
                <span>Short Description</span>
                <input
                    placeholder='short description'
                    value={shortDescription}
                    onChange={(e) => setShortDescription(e.target.value)}
                />
            </label>
            <label>
                <span>Description</span>
                <textarea
                    placeholder='Description'
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />
            </label>
            <button className='red-btn' onClick={() => addReview()}>
                Post Review
            </button>
        </Modal>)
    );
}
