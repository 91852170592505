import React, { useEffect } from 'react';
import * as userActions from '@/actions/userActions';
import { useSelector, useDispatch } from 'react-redux';
import Table from '../../Shared/Table';
import { Button, Tag } from 'antd';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import '../../../HomePage/popularDoctor.css';

const FavouriteBlogs = (props) => {
    const { limit, page } = props;

    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const {
        favourites: { blogs }
    } = auth;
    const getFavouriteBlogs = () => {
        let obj = {
            favouriteType: 'Blogs',
            userId: auth.userId,
            perPage: limit,
            page: page
        };
        dispatch(userActions.getFavourites({ ...props, obj }));
    };
    useEffect(() => {
        getFavouriteBlogs();
    }, [limit, page]);

    const removeFromFavourites = async (id) => {
        let obj = {
            favouriteId: id
        };
        let removeFavItem = await dispatch(
            userActions.removeFromFavourites({ obj, ...props }, props.history)
        );
        if (removeFavItem) {
            getFavouriteBlogs();
        }
    };

    const columns = [
        {
            title: <span className='dragHandler'>Date</span>,
            width: 140,
            dataIndex: 'createdDate',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.createdDate) return -1; // Handle empty values
                if (!b.createdDate) return 1; // Handle empty values
                return a.createdDate.localeCompare(b.createdDate);
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {new Date(date).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </div>
                );
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 140 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Time</span>,
            width: 100,
            dataIndex: 'createdDate',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.createdDate) return -1; // Handle empty values
                if (!b.createdDate) return 1; // Handle empty values
                return a.createdDate.localeCompare(b.createdDate);
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {new Date(date).toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric'
                        })}
                    </div>
                );
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Title</span>,
            width: 500,
            dataIndex: 'title',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.title) return -1; // Handle empty values
                if (!b.title) return 1; // Handle empty values
                return a.title.localeCompare(b.title);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 250 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Country</span>,
            width: 200,
            dataIndex: 'country',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.country) return -1; // Handle empty values
                if (!b.country) return 1; // Handle empty values
                return a.country.localeCompare(b.country);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Author</span>,
            width: 200,
            dataIndex: 'author',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.author) return -1; // Handle empty values
                if (!b.author) return 1; // Handle empty values
                return a.author.localeCompare(b.author);
            },
            render: (authorObj, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                const author = authorObj.firstName + ' ' + authorObj.lastName;
                return <div>{author}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Area of Specialization</span>,
            width: 300,
            dataIndex: 'category',
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            },
            render: (array) => {
                return array?.map((item, index) => {
                    return <Tag key={index}>{item}</Tag>;
                });
            }
        },
        {
            title: <span className='dragHandler'>Action</span>,

            render: (data, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        <Button type='default' onClick={() => removeFromFavourites(record._id)}>
                            Delete
                        </Button>
                    </div>
                );
            }
        }
    ];

    const numberOfBlankRows = limit - blogs.length;
    let dataSource = [
        ...blogs?.map((item) => item.favouriteItem),
        ...Array.from({ length: numberOfBlankRows }).map(() => ({}))
    ];

    return <Table tableName={'Favourite Blogs'} columns={columns} data={dataSource} />;
};

export default FavouriteBlogs;
