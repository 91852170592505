import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import FavouritesHeader from './FavouritesHeader';
import Blogs from './FavouriteBlogs';
import Hospitals from './FavouriteHospitals';
import Doctors from './FavouriteDoctors';
import { Pagination } from 'antd';
import PageHeader from '../PageHeader';
import '@/assets/css/favourites.css';
import Nurses from './FavouriteNurses';
import Consultants from './FavouriteConsultants';

const Favourites = (props) => {
    const [type, setType] = useState('Blogs');
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const { history } = props;
    const {
        favourites: { blogsCount, doctorsCount, hospitalsCount, consultantsCount, nursesCount }
    } = useSelector((state) => state.auth);

    return (
        <div>
            <PageHeader heading={'Favourites'} />

            <FavouritesHeader
                userType={'PATIENT'}
                history={history}
                type={type}
                setType={setType}
            />

            {type == 'Blogs' && <Blogs limit={limit} page={page} />}

            {type == 'Hospital/Clinic' && <Hospitals limit={limit} page={page} />}

            {type == 'Doctor' && <Doctors limit={limit} page={page} />}

            {type == 'Consultant' && <Consultants limit={limit} page={page} />}

            {type == 'Nurse' && <Nurses limit={limit} page={page} />}

            <Pagination
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    width: '100%',
                    marginTop: 20
                }}
                current={page}
                total={
                    type == 'Blogs'
                        ? blogsCount
                        : type == 'Hospital/Clinic'
                        ? hospitalsCount
                        : type == 'Consultants'
                        ? consultantsCount
                        : type == 'Nurse'
                        ? nursesCount
                        : doctorsCount
                }
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </div>
    );
};

export default Favourites;
