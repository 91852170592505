import React, { useState, useEffect, useRef } from 'react';
import { message, Modal, Select } from 'antd';
import { useSelector } from 'react-redux';

const EditShareDocumentModal = (props) => {
    const { document, setDocument, page, limit, editDocument, setEditDocument } = props;
    const [email, setEmail] = useState('');
    const [emails, setEmails] = useState([]);
    const [emailError, setEmailError] = useState('');
    const [documentInfo, setDocumentInfo] = useState('');
    const [docId, setDocId] = useState('');
    const [documentType, setDocumentType] = useState('');
    let inputRef = useRef();
    const { documentTypes } = useSelector((state) => state.documents);

    useEffect(() => {
        ResetFileInput();
    }, [editDocument]);

    useEffect(() => {
        if (documentTypes && documentTypes.length) {
            const generalDocuments = documentTypes.find((docType) => docType.name === "General Document");
            if (generalDocuments) {
                setDocumentType(generalDocuments._id);
            }
        }
    }, [documentTypes]);

    const ResetFileInput = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = null;
        }
    };
    useEffect(() => {
        console.log(editDocument);
        if (editDocument) {
            setDocId(editDocument.docId);
            setEmails(editDocument.receiverEmails);
            setDocumentInfo(editDocument.document && editDocument.document);
            setDocumentType(editDocument.document?.documentType)
        }
    }, [editDocument]);

    const shareDocument = async () => {
        let formObj = new FormData();
        formObj.append('userId', props.auth.userId);
        formObj.append('userType', props.auth.userType);
        formObj.append('documentType', documentType)
        formObj.append('docId', docId);
        if (document) {
            formObj.append('file', document);
        }

        emails.map((item, index) => formObj.append(`emails[${index}]`, item));
        // emails.forEach((item, index) => console.log(item, index));

        let obj = {
            userId: props.auth.userId,
            userType: props.auth.userType,
            emails: emails
        };
        let shareDoc = await props.documentActions.editShareDocument(
            { ...props, obj: formObj },
            props.history
        );
        if (shareDoc) {
            message.success('Successfully shared documents with emails');
            let obj = {
                page: page,
                perPage: limit
            };
            await props.documentActions.getDocumentList({ ...props, obj }, props.history);
            setEditDocument(null);
            setDocument(null);
            setEmails([]);
            setEmail('');
            ResetFileInput();
        } else {
            message.error('Failed to share documents with emails');
        }
    };

    const filterEmails = async (item, index) => {
        let allEmails = [...emails];
        setEmails(allEmails.filter((it) => it != item));
    };
    console.log(props);
    return (
        <Modal
            className='customModal '
            title={'Edit Document Sharing'}
            open={editDocument}
            onOk={() => {
                setEditDocument(null);
                setDocument(null);
                setEmails([]);
                setEmail('');
                ResetFileInput();
            }}
            onCancel={() => {
                setEditDocument(null);
                setDocument(null);
                setEmails([]);
                setEmail('');
                ResetFileInput();
            }}
            footer={null}
        >
            {console.log(document)}
            {!document ? (
                <p style={{ marginBottom: 10 }}>No Document uploaded</p>
            ) : (
                <>
                    {' '}
                    <p style={{ marginBottom: 10 }}>
                        <strong>Name:</strong> {document.name}{' '}
                    </p>{' '}
                    <p style={{ marginBottom: 10 }}>
                        <strong>Size:</strong> {document.size / 1000} Kb
                    </p>
                </>
            )}

            <Select placeholder='Select Document Type' style={{ width: '100%', marginBottom: 20 }} value={documentType || undefined} onChange={(value)=>setDocumentType(value)}>
                {documentTypes &&
                    documentTypes.length &&
                    documentTypes.map((item) => (
                        <Select.Option value={item._id}>{item.name}</Select.Option>
                    ))}
            </Select>
            <label className='buttonLabel' htmlFor='document'>
                <input
                    ref={inputRef}
                    id='document'
                    type='file'
                    onChange={(e) => setDocument(e.target.files[0])}
                    style={{ display: 'none' }}
                />
                Change Document
            </label>
            <label style={{marginBottom: 0}}>
                <p> Name: {documentInfo && documentInfo.originalname}</p>
            </label>
            <label style={{marginBottom: 10}}>
                <p>
                    {' '}
                    Size: {documentInfo && documentInfo.size && <>{documentInfo.size / 1000} Kb</>}
                </p>
            </label>
            <label className='inputEmails'>
                <div className=''>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} />
                    {emailError && <p>{emailError}</p>}
                </div>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        let values = [...emails];
                        if (!values.includes(email)) {
                            if (email != '') {
                                values.push(email);
                                setEmails(values);
                                setEmailError('');
                                setEmail('');
                            } else {
                                setEmailError('Provide an email to share document with');
                            }
                        } else {
                            setEmailError('Email already marked to be shared with');
                        }
                    }}
                >
                    Add Email
                </button>
            </label>
            <label>
                <p
                    style={{
                        fontSize: '12px',
                        fontWeight: 400,
                        borderBottom: '1px solid #dddd',
                        marginBottom: '5px'
                    }}
                >
                    Emails to be shared with:
                </p>
                <div className='emailItems'>
                    {emails && emails.length ? (
                        emails.map((item, index) => (
                            <p className='emailItem'>
                                {item}{' '}
                                <span onClick={() => filterEmails(item, index)}>
                                    <i className='fe fe-x' />
                                </span>
                            </p>
                        ))
                    ) : (
                        <p>No emails shared with</p>
                    )}
                </div>
            </label>
            <label className='buttonLabel' onClick={() => shareDocument()}>
                Upload Document
                <i className='fe fe-upload' />
            </label>
        </Modal>
    );
}

export default EditShareDocumentModal;
