import React, { useState, useEffect } from 'react';
import PageHeader from '../PageHeader';
import SkeletonSection from './SkeletonSection';
import PreviewSection from './Preview';
import '@/assets/css/hospitalView.css';
import { Col, Row, Switch } from 'antd';
import OutputScreen from './Output';
import * as marketingActions from '@/actions/marketingAction';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import StatsBar from './StatsBar';
import BottomBar from './BottomBar';
import TopBar from './Preview/TopBar';

const initialState = {
    text: '',
    style: {
        fontFamily: 'Inter Var',
        fontSize: '14px',
        color: '#000',
        fontWeight: 400
    }
};

const Marketing = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { userId, userType } = useSelector((state) => state.auth);
    const { displayStats, displayBottomBar, displayTopBar } =
        useSelector((state) => state.marketing.marketingInfo) || {};
    const getMarketingInfo = async () => {
        let obj = {
            userId: userId
        };
        await dispatch(marketingActions.getMarketingInfo({ ...props, obj }, history));
    };

    const saveMarketingInfo = async (section, data, contentType) => {
        let obj;

        console.log(section, data, contentType);

        obj = new FormData();
        obj.append('section', section);
        obj.append('userId', userId);
        obj.append('userType', userType);
        let tempObj;
        if (contentType == 'text' || contentType == 'Text') {
            if (section == 'TOPBAR') {
                tempObj = {
                    displayTopBar: displayTopBar,
                    topBarContentType: contentType,
                    topBarContent: { ...data }
                };
            }
            if (section == 'BOTTOMBAR') {
                tempObj = {
                    displayBottomBar: displayBottomBar,
                    bottomBarContentType: contentType,
                    bottomBarContent: { ...data }
                };
            }
            if (section == 'STATSBAR') {
                tempObj = {
                    displayStats: displayStats,
                    statsContentType: contentType,
                    statsContent: { ...data }
                };
            }
            obj.append('data', JSON.stringify(tempObj));
        } else if (contentType == 'Image' || contentType == 'image') {
            obj.append('image', data.image);
            delete data.image;
            console.log(data);

            if (section == 'TOPBAR') {
                tempObj = {
                    displayTopBar: displayTopBar,
                    topBarContentType: contentType
                };
            }
            if (section == 'BOTTOMBAR') {
                tempObj = {
                    displayBottomBar: displayBottomBar,
                    bottomBarContentType: contentType
                };
            }
            if (section == 'STATSBAR') {
                tempObj = {
                    displayStats: displayStats,
                    statsContentType: contentType
                };
            }

            console.log('tempObj', tempObj);
            obj.append('data', JSON.stringify(tempObj));
        }

        console.log(obj, contentType);
        let savedMarketingInfo = await dispatch(
            marketingActions.saveMarketingInfo({ ...props, obj }, history)
        );
        if (savedMarketingInfo) {
            getMarketingInfo();
        }
    };

    useEffect(() => {
        getMarketingInfo();
        return () => {};
    }, []);

    const handleSwitch = async (checked, section) => {
        let obj = {
            section,
            data: {},
            userId: userId,
            userType: userType
        };

        if (section == 'TOPBAR') {
            obj.data.displayTopBar = checked;
        }
        if (section == 'BOTTOMBAR') {
            obj.data.displayBottomBar = checked;
        }
        if (section == 'STATSBAR') {
            obj.data.displayStats = checked;
        }

        let savedMarketingInfo = await dispatch(
            marketingActions.saveMarketingSwitches({ ...props, obj }, history)
        );
        if (savedMarketingInfo) {
            getMarketingInfo();
        }
        // props.marketingActions.setBanner(checked);
    };

    const [data, setData] = useState({
        title: { ...initialState },
        header: { ...initialState },
        grabber: { ...initialState },
        line1: { ...initialState },
        line2: { ...initialState },
        line3: { ...initialState },
        line4: { ...initialState },
        image: '',
        backgroundColor: '#fff'
    });
    return (
        <div>
            <PageHeader heading='Marketing' />
            <TopBar handleSwitch={handleSwitch} saveMarketingInfo={saveMarketingInfo} />
            <PreviewSection handleSwitch={handleSwitch} saveMarketingInfo={saveMarketingInfo} />
            <Row gutter={[20, 20]}>
                <Col xs={24} lg={16} style={{ display: 'flex', flexDirection: 'column', gap: 60 }}>
                    <SkeletonSection />
                    <SkeletonSection />
                </Col>
                <Col xs={24} lg={8}>
                    <StatsBar handleSwitch={handleSwitch} saveMarketingInfo={saveMarketingInfo} />
                    <BottomBar handleSwitch={handleSwitch} saveMarketingInfo={saveMarketingInfo} />
                </Col>
            </Row>
        </div>
    );
};

export default Marketing;
