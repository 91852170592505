import React, { useEffect, useState, memo } from 'react';
import '@/assets/css/verifications.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import DocumentsType from '../Shared/Documents/documentsType';
import DocCaution from '../Shared/Documents/docCaution';
import RequiredDocs from './Documents/requiredDocs';
import { Progress } from 'antd';

const Verifications = (props) => {
    const [approvedBranch, setApprovedBranch] = useState(false);
    const [rejectedBranch, setRejectedBranch] = useState(false);
    const [pendingBranch, setPendingBranch] = useState(false);
    const [requiredBranch, setRequiredBranch] = useState(false);
    let [rejectedFiles, setRejectedFiles] = useState(0);
    let [requiredFiles, setRequiredFiles] = useState(0);
    let [pendingFiles, setPendingFiles] = useState(0);
    let [approvedFiles, setApprovedFiles] = useState(0);
    let [totalFiles, setTotalFiles] = useState(0);
    let [percentage, setPercentage] = useState(0);
    const { profile } = props;

    const calculate = async (array) => {
        let required = 0,
            pending = 0,
            rejected = 0,
            approved = 0;
        await array.forEach((item) => {
            if (item.verificationStatus === 'REQUIRED') {
                console.log(requiredFiles);
                // setRequiredFiles(++requiredFiles)
                required++;
                setRequiredBranch(true);
            }
            if (item.verificationStatus === 'APPROVED') {
                // setApprovedFiles(++approvedFiles)
                approved++;
                setApprovedBranch(true);
            }
            if (item.verificationStatus === 'REJECTED') {
                // setRejectedFiles(++rejectedFiles)
                rejected++;
                setRejectedBranch(true);
            }
            if (item.verificationStatus === 'PENDING') {
                // setPendingFiles(++pendingFiles)
                pending++;
                setPendingBranch(true);
            }
        });
        return { required, pending, rejected, approved };
    };
    const clearFiles = async () => {
        setRequiredFiles(0);
        setRequiredBranch(false);
        setApprovedFiles(0);
        setApprovedBranch(false);
        setRejectedFiles(0);
        setRejectedBranch(false);
        setPendingFiles(0);
        setPendingBranch(false);
    };

    const calculateFiles = async () => {
        let count = 0;
        let required = 0;
        let rejected = 0;
        let pending = 0;
        let approved = 0;

        if (profile && profile.experiences) {
            count = count + profile.experiences.length;
            let newObj = await calculate(profile.experiences);
            console.log(newObj, 'newObj');
            required = required + newObj.required;
            rejected = rejected + newObj.rejected;
            pending = pending + newObj.pending;
            approved = approved + newObj.approved;
        }

        if (profile && profile.honors) {
            count = count + profile.honors.length;
            let newObj = await calculate(profile.honors);
            required = required + newObj.required;
            rejected = rejected + newObj.rejected;
            pending = pending + newObj.pending;
            approved = approved + newObj.approved;
        }

        if (profile && profile.associations) {
            count = count + profile.associations.length;
            let newObj = await calculate(profile.associations);
            console.log(newObj, 'newObj');
            required = required + newObj.required;
            rejected = rejected + newObj.rejected;
            pending = pending + newObj.pending;
            approved = approved + newObj.approved;
        }

        if (profile && profile.educations) {
            count = count + profile.educations.length;
            let newObj = await calculate(profile.educations);
            required = required + newObj.required;
            rejected = rejected + newObj.rejected;
            pending = pending + newObj.pending;
            approved = approved + newObj.approved;
        }

        if (profile && profile.admissions) {
            count = count + profile.admissions.length;
            let newObj = await calculate(profile.admissions);
            console.log(newObj, 'newObj');
            required = required + newObj.required;
            rejected = rejected + newObj.rejected;
            pending = pending + newObj.pending;
            approved = approved + newObj.approved;
        }

        setRequiredFiles(required);
        setRejectedFiles(rejected);
        setPendingFiles(pending);
        setApprovedFiles(approved);
        setTotalFiles(count);
    };
    useEffect(() => {
        if (profile) {
            calculateFiles();
        }
    }, [profile]);

    useEffect(() => {
        if (totalFiles > 0 && approvedFiles > 0) {
            setPercentage((100 * approvedFiles) / totalFiles);
        } else {
            setPercentage(0);
        }
    }, [approvedFiles, totalFiles]);
    return (
        <div style={{ color: 'var(--bg-dark)' }}>
            <div className='heading-width-progress-bar'>
                <Progress type='circle' percent={percentage} />
                <div className='order-headings'>
                    <p>Upload Mandatory Documents for Verification</p>
                    <p>
                        We are dedicated to assisting law firms in establishing and maintaining
                        their online medical practice. Our digital software empowers you to
                        communicate and practice on a global scale. When seeking the appropriate
                        medical professionals to address your clients' needs, they will see a verified
                        badge on your public listing. Verified badges signify that the law firm has
                        been thoroughly vetted and approved by our verification agency.
                    </p>
                </div>
            </div>

            {/* <Progress done={percentage ? `${percentage}` : '0'} /> */}
            {requiredBranch ? (
                <div className='order-segments'>
                    <DocumentsType
                        title={'Mandatory Documentation'}
                        description={`Please review the following list of items required to submit your application.`}
                        document={'Required'}
                        remainingFiles={requiredFiles}
                        totalFiles={totalFiles}
                    />
                    <div className='order-sections'>
                        <RequiredDocs type={'REQUIRED'} experiences={props.profile.experiences} />
                        <RequiredDocs type={'REQUIRED'} educations={props.profile.educations} />
                        <RequiredDocs type={'REQUIRED'} associations={props.profile.associations} />
                        <RequiredDocs type={'REQUIRED'} honors={props.profile.honors} />
                        <RequiredDocs type={'REQUIRED'} admissions={props.profile.admissions} />
                    </div>
                </div>
            ) : null}
            {rejectedBranch ? (
                <div className='order-segments'>
                    <DocumentsType
                        title={'Rejected Documents'}
                        document={'Rejected'}
                        remainingFiles={rejectedFiles}
                        totalFiles={totalFiles}
                    />
                    <div className='order-sections'>
                        <RequiredDocs type={'REJECTED'} experiences={props.profile.experience} />
                        <RequiredDocs type={'REJECTED'} educations={props.profile.education} />
                        <RequiredDocs type={'REJECTED'} associations={props.profile.association} />
                        <RequiredDocs type={'REJECTED'} honors={props.profile.honors} />
                        <RequiredDocs type={'REJECTED'} admissions={props.profile.admission} />
                    </div>
                </div>
            ) : null}
            {pendingBranch ? (
                <div className='order-segments'>
                    <DocumentsType
                        title={`Pending Verification`}
                        description={`We are in the process of verifying your documents, and this may take some time. Thank you for your patience.`}
                        document={'Pending'}
                        remainingFiles={pendingFiles}
                        totalFiles={totalFiles}
                    />
                    <div className='order-sections'>
                        <RequiredDocs type={'PENDING'} experiences={props.profile.experiences} />
                        <RequiredDocs type={'PENDING'} educations={props.profile.educations} />
                        <RequiredDocs type={'PENDING'} associations={props.profile.associations} />
                        <RequiredDocs type={'PENDING'} honors={props.profile.honors} />
                        <RequiredDocs type={'PENDING'} admissions={props.profile.admissions} />
                    </div>
                </div>
            ) : null}
            {approvedBranch ? (
                <div className='order-segments'>
                    <DocumentsType
                        title={`Verification Completed`}
                        description={`We have successfully verified the following documents, and your verified badge is now displayed on your profile.`}
                        document={'Approved'}
                        remainingFiles={approvedFiles}
                        totalFiles={totalFiles}
                    />
                    <div className='order-sections'>
                        <RequiredDocs type={'APPROVED'} experiences={props.profile.experiences} />
                        <RequiredDocs type={'APPROVED'} educations={props.profile.educations} />
                        <RequiredDocs type={'APPROVED'} associations={props.profile.associations} />
                        <RequiredDocs type={'APPROVED'} honors={props.profile.honors} />
                        <RequiredDocs type={'APPROVED'} admissions={props.profile.admissions} />
                    </div>
                </div>
            ) : null}
            {console.log(requiredFiles, approvedFiles)}
            <DocCaution />
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(Verifications));
