import React, { useState, useEffect } from 'react';
import { FaTasks } from 'react-icons/fa';
import { BsCalendar } from 'react-icons/bs';
import { motion, AnimatePresence } from 'framer-motion';
import { Button, Calendar, Pagination, Popover } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import AppointmentModal from './modals/AppointmentModal';
import DataTable from './AppointmentDataTable';
import * as appointmentActions from '@/actions/appointmentActions';
import * as userActions from '@/actions/userActions';
import * as availabilityActions from '@/actions/availabilityActions';
import * as therapistActions from '@/actions/therapistActions';
import * as caregiverActions from '@/actions/caregiverActions';
import * as nurseActions from '@/actions/nurseActions';
import * as consultantActions from '@/actions/consultantActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import validator from 'validator';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import listPlugin from '@fullcalendar/list';
import '@/assets/css/fullcalendar.css';
import ShowEventModal from './ViewCalendarEvent';
import CurrentTime from './CurrentTime';
import { Link } from 'react-router-dom';
import { ExclamationOutlined } from '@ant-design/icons';
import PageHeader from '../PageHeader';
import './appointment.css';
import AppointmentHeader from './AppointmentHeader';
import CreateAppointmentModal from './modals/CreateAppointment';
import AvailabilitySidebar from '../Members/locationContent/AvailabilitySidebar';
import AvailabilityModal from '../Members/locationContent/AvailabilityModal';
import EditAppointmentModal from './modals/EditAppointment';

const Appointment = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [calenderView, setCalenderView] = useState(false);

    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [appointmentModal, setAppointmentModal] = useState(false);
    const [editAppointmentModal, setEditAppointmentModal] = useState(false);
    const [editAppointmentItem, setEditAppointmentItem] = useState(null);
    const [createAvailabilityModal, setCreateAvailabilityModal] = useState(false);
    const { userProfile } = useSelector((state) => state.auth);
    const { stripeVerified } = userProfile;

    const {
        auth,
        appointments: {
            appointments,
            appointmentsCount,
            googleEvents,
            outlookEvents,
            calendarEvents
        },
        availabilities,
        history,
        branches
    } = props;
    const { user: me } = auth;
    const [sidebar, setSidebar] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userEmail, setUserEmail] = useState('');
    const [user, setUser] = useState({ name: '', id: '' });
    const [date, setDate] = useState('');
    const [paymentMode, setPaymentMode] = useState('In-Person');
    const [consultationMode, setConsultationMode] = useState('In-Person');
    const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
    const [selectedDoctor, setSelectedDoctor] = useState('');
    const [selectedService, setSelectedService] = useState('');
    const [chosenUser, setChosenUser] = useState({
        value: '',
        message: '',
        isValid: true
    });
    const [showEvent, setShowEvent] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
    const [availabilityData, setAvailabilityData] = useState(null);
    //error messages
    const [userEmailMsg, setUserEmailMsg] = useState('');
    const [dateMsg, setDateMsg] = useState('');
    const [doctorMsg, setDoctorMsg] = useState('');
    const [timeSlotMsg, setTimeSlotMsg] = useState('');
    const [doctorServiceMsg, setDoctorServiceMsg] = useState('');
    const [schedule, setSchedule] = useState(null);
    const { hospitalServices: services } = useSelector((state) => state.general);
    const { timeSlots } = useSelector(
        (state) => state[me.userType.toLowerCase()][`${me.userType.toLowerCase()}ForAppointment`]
    );
    let tempTimezone = moment.tz.guess();
    useEffect(() => {
        setSelectedService({
            ...selectedService,
            value: services.filter((item) => item.name.includes('Free'))[0]?._id
        });
    }, [services]);

    const updateAppointmentInitialize = async (item) => {
        setSelectedTimeSlot(item.appointmentTime);
        setDate(item.appointmentDate);
        setPaymentMode(item.paymentMode);
        setUserEmail(item.userEmail);
        setConsultationMode(item.consultationMode);
        if (item.service) {
            setSelectedService(item.service);
        }
    };

    const openEditAppointment = async (item) => {
        setEditAppointmentItem(item);
        updateAppointmentInitialize(item);
        setEditAppointmentModal(true);
    };

    const cancelAppointment = async (id) => {
        console.log(id);
        let obj = {
            appointmentId: id
        };
        let cancelledAppointment = await props.appointmentActions.cancelAppointment(
            { ...props, obj },
            history
        );
        if (cancelledAppointment) {
            getAppointments();
            getCalendarEvents();
        }
    };

    const approveAppointment = async (id) => {
        console.log(id);
        let obj = {
            appointmentId: id
        };
        let approvedAppointment = await props.appointmentActions.approveAppointment(
            { ...props, obj },
            history
        );
        if (approvedAppointment) {
            getAppointments();
            getCalendarEvents();
        }
    };

    useEffect(() => {
        getAppointments();
    }, []);

    useEffect(() => {
        getCalendarEvents();
    }, [me?.syncGoogleCalendar, me?.syncOutlookCalendar]);

    useEffect(() => {
        if (date) {
            getTimeSlots();
        }
    }, [date]);

    const getTimeSlots = async () => {
        let obj = {
            memberId: me._id,
            userType: me.userType,
            date: date ? moment(date).format('DD-MM-YYYY') : moment(new Date()).format('DD-MM-YYYY')
        };
        await props.availabilityActions.getExtractedTimeSlots({ ...props, obj }, history);
    };
    const getAppointments = async () => {
        let obj = {};
        await props.appointmentActions.getMyAppointments({
            ...props,
            obj
        });
    };

    const checkValidity = async () => {
        let valid = true;
        if (!userEmail || (userEmail && !validator.isEmail(userEmail))) {
            if (!userEmail) {
                setUserEmailMsg('User email is required');
            } else {
                setUserEmailMsg('User email must be a valid email address');
            }
            valid = false;
        } else {
            setUserEmailMsg('');
        }

        // if (!selectedDoctor) {
        //     setDoctorMsg('Doctor is required');
        //     valid = false;
        // } else {
        //     setDoctorMsg('');
        // }

        // if (!selectedService.value) {
        //     console.log('got in here')
        //     setSelectedService({
        //         ...selectedService,
        //         message: `${
        //             me.userType.slice(0, 1) + me.userType.slice(1).toLowerCase()
        //         } Service is required`,
        //         isValid: false
        //     });
        //     valid = false;
        // } else {
        //     setSelectedService({
        //         ...selectedService,
        //         message: '',
        //         isValid: true
        //     });
        // }

        if (!date) {
            setDateMsg('Appointment Date is required');
            valid = false;
        } else {
            setDateMsg('');
        }

        if (!selectedTimeSlot) {
            setTimeSlotMsg('Time Slot is required');
            valid = false;
        } else {
            setTimeSlotMsg('');
        }
        return valid;
    };
    useEffect(() => {
        if (branches && branches[selectedAddressIndex] && branches[selectedAddressIndex]._id) {
            setSchedule(
                availabilities &&
                    availabilities.filter((item, index) => item.branch == selectedAddress)[0]
            );
        } else {
            setSchedule(availabilities[0]);
        }
        if (branches && branches[selectedAddressIndex]) {
            setSelectedAddress(branches[selectedAddressIndex]._id);
            setAvailabilityData(branches[selectedAddressIndex]);
        }
    }, [branches && branches[selectedAddressIndex], availabilities, selectedAddress]);
    useEffect(() => {
        if (branches && branches.length > 0) {
            setSelectedBranch(branches[0]._id);
        }
    }, [branches]);
    const submitForm = async () => {
        setLoading(true);
        let valid = await checkValidity();
        if (valid) {
            let obj = {
                time: selectedTimeSlot,
                date: date && moment(new Date(date).toISOString()).format('DD/MM/YYYY'),
                consultationMode: consultationMode,
                paymentMode: paymentMode,
                doctor: selectedDoctor,
                member: me._id,
                therapist: auth && auth.userId && auth.userId,
                userEmail: userEmail,
                requestTo: 'PATIENT',
                branch: selectedBranch,
                timezone: tempTimezone,
                service: selectedService?.value || selectedService
            };

            if (!editAppointmentItem) {
                let createApp = await props.appointmentActions.requestAppointmentByMember(
                    { ...props, obj },
                    props.history
                );

                if (createApp) {
                    setAppointmentModal(false);
                    clearAppointmentData();
                    await getAppointments();
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            } else {
                obj.appointmentId = editAppointmentItem._id;
                let updateApp = await props.appointmentActions.updateAppointment(
                    { ...props, obj },
                    props.history
                );

                if (updateApp) {
                    setEditAppointmentModal(false);
                    setEditAppointmentItem(null);
                    clearAppointmentData();
                    await getAppointments();
                    setLoading(false);
                } else {
                    setLoading(false);
                }
            }
        }
        setLoading(false);
    };

    const appointmentVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { duration: 0.5 }
        },
        exit: {
            opacity: 0,
            transition: { ease: 'easeIn', duration: 0.5 }
        }
    };

    const onSelect = (value) => {
        setDate(value);
        setAppointmentModal(true);
    };

    const clearAppointmentData = () => {};

    const getCalendarEvents = async () => {
        let obj = {};
        await props.actions.getCalendarEvents({ ...props, obj }, props.history);
    };

    let appEvents =
        appointments &&
        appointments
            ?.filter((item) => item.appointmentStatus == 'ACCEPTED')
            .map((item, index) => {
                let obj = {};
                obj.title = `Appointment with ${item?.user?.firstName} ${item?.user?.lastName}`;
                obj.description = `Appointment with ${item?.user?.firstName} ${
                    item?.user?.lastName
                } on ${moment(item.appointmentStartTime, 'hh:mm a')}, ${
                    item.appointmentDate && moment(item.appointmentDate).format('DD/MM/YYYY')
                }`;
                obj.start = moment(
                    `${moment(item?.appointmentDate).format('DD/MM/YYYY')} ${
                        item.appointmentStartTime
                    }`,
                    'DD/MM/YYYY hh:mm'
                ).toISOString();

                obj.end = moment(
                    `${moment(item?.appointmentDate).format('DD/MM/YYYY')} ${
                        item.appointmentEndTime
                    }`,
                    'DD/MM/YYYY hh:mm'
                ).toISOString();
                obj = { ...obj, ...item };
                return obj;
            });

    let events = [...appEvents, ...calendarEvents];

    const openEvent = async (evt) => {
        setSelectedEvent(evt);
        setShowEvent(true);
    };

    useEffect(() => {
        if (availabilities?.length > 0) {
            setSchedule(availabilities[0]);
        }
    }, [availabilities]);
    return (
        <motion.div variants={appointmentVariants} initial='hidden' animate='visible' exit='exit'>
            <PageHeader
                heading={
                    <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        Appointments{' '}
                        {!stripeVerified && (
                            <Popover
                                content={
                                    <div>
                                        To schedule online appointments or make online payments,
                                        please ensure your Stripe account is connected. Go To{' '}
                                        <Link
                                            style={{
                                                fontWeight: 'bold',
                                                textDecoration: 'underline'
                                            }}
                                            to='/therapist/stripe-dashboard'
                                        >
                                            stripe Dashboard
                                        </Link>
                                    </div>
                                }
                            >
                                <div
                                    style={{
                                        height: 30,
                                        width: 30,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        borderRadius: '50%',
                                        backgroundColor: 'var(--tertiary)',
                                        color: 'white',
                                        fontSize: 20
                                    }}
                                >
                                    <ExclamationOutlined />
                                </div>
                            </Popover>
                        )}
                    </div>
                }
                subHeading={
                    <div style={{ display: 'flex', gap: 10, alignItems: 'center' }}>
                        <CurrentTime />
                        <Button type='primary' onClick={() => setSidebar(true)}>
                            Set Availability
                        </Button>
                    </div>
                }
                actions={() => (
                    <div className='app-switch'>
                        <FaTasks
                            onClick={() => {
                                setCalenderView(false);
                                history.push(`/${me.userType.toLowerCase()}/appointments?tab=list`);
                            }}
                        />
                        <p>|</p>
                        <BsCalendar
                            onClick={() => {
                                setCalenderView(true);
                                history.push(
                                    `/${me.userType.toLowerCase()}/appointments?tab=calendar`
                                );
                            }}
                        />
                    </div>
                )}
            />
            {console.log(branches)}
            <AnimatePresence>
                {sidebar && (
                    <AvailabilitySidebar
                        {...props}
                        setSidebar={setSidebar}
                        sidebar={sidebar}
                        setCreateAvailabilityModal={setCreateAvailabilityModal}
                        createAvailabilityModal={createAvailabilityModal}
                        schedule={schedule}
                        setSchedule={setSchedule}
                        selectedAddress={selectedAddress}
                        setSelectedAddress={setSelectedAddress}
                        selectedAddressIndex={selectedAddressIndex}
                        setSelectedAddressIndex={setSelectedAddressIndex}
                        setSelectedBranch={setSelectedBranch}
                        selectedBranch={selectedBranch}
                        branches={branches}
                    />
                )}
            </AnimatePresence>
            {createAvailabilityModal && (
                <AvailabilityModal
                    schedule={schedule}
                    createAvailabilityModal={createAvailabilityModal}
                    setCreateAvailabilityModal={setCreateAvailabilityModal}
                    branches={branches}
                    selectedAddress={selectedAddress}
                    setSelectedAddress={setSelectedAddress}
                    selectedAddressIndex={selectedAddressIndex}
                    setSelectedAddressIndex={setSelectedAddressIndex}
                    setSelectedBranch={setSelectedBranch}
                    selectedBranch={selectedBranch}
                    setSchedule={setSchedule}
                    {...props}
                />
            )}
            <AnimatePresence exitBeforeEnter>
                {calenderView && (
                    <motion.div
                        key='1'
                        variants={appointmentVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                        className='appointment-calendar'
                    >
                        <FullCalendar
                            plugins={[
                                dayGridPlugin,
                                interactionPlugin,
                                timeGridPlugin,
                                momentPlugin,
                                listPlugin
                            ]}
                            windowResizeDelay={0}
                            initialView='dayGridMonth'
                            displayEventTime={true}
                            // displayEventEnd={true}
                            selectable={true}
                            editable={true}
                            dragRevertDuration={1}
                            timeFormat='hh:mm'
                            eventTimeFormat={{
                                hour: '2-digit',
                                minute: '2-digit',
                                hour12: false
                            }}
                            dateClick={(val) => onSelect(val.dateStr)}
                            eventClick={(val) => openEvent(val.event)}
                            headerToolbar={{
                                left: 'prev,next',
                                center: 'title',
                                right: 'timeGridDay,timeGridWeek,dayGridMonth,listWeek'
                            }}
                            events={events}
                        />
                    </motion.div>
                )}
                {!calenderView && (
                    <div>
                        <AppointmentHeader
                            open={appointmentModal}
                            setOpen={setAppointmentModal}
                            auth={auth}
                        />

                        <DataTable
                            userType={'THERAPIST'}
                            auth={auth}
                            appointments={appointments}
                            open={appointmentModal}
                            setOpen={setAppointmentModal}
                            cancelAppointment={cancelAppointment}
                            approveAppointment={approveAppointment}
                            openEditAppointment={openEditAppointment}
                            limit={limit}
                            history={history}
                            page={page}
                        />
                    </div>
                )}
            </AnimatePresence>
            <AppointmentModal open={open} setOpen={setOpen} />
            {appointmentModal && (
                <CreateAppointmentModal
                    {...props}
                    selectedTimeSlot={selectedTimeSlot}
                    setSelectedTimeSlot={setSelectedTimeSlot}
                    myTimeSlots={timeSlots}
                    date={date}
                    setDate={setDate}
                    appointmentModal={appointmentModal}
                    setAppointmentModal={setAppointmentModal}
                    clearAppointmentData={clearAppointmentData}
                    paymentMode={paymentMode}
                    setPaymentMode={setPaymentMode}
                    consultationMode={consultationMode}
                    setConsultationMode={setConsultationMode}
                    userEmail={userEmail}
                    setUserEmail={setUserEmail}
                    submitForm={submitForm}
                    chosenUser={chosenUser}
                    setChosenUser={setChosenUser}
                    user={user}
                    setUser={setUser}
                    selectedDoctor={selectedDoctor}
                    setSelectedDoctor={setSelectedDoctor}
                    selectedService={selectedService}
                    setSelectedService={setSelectedService}
                    loading={loading}
                    setLoading={setLoading}
                    userEmailMsg={userEmailMsg}
                    dateMsg={dateMsg}
                    setDateMsg={setDateMsg}
                    doctorMsg={doctorMsg}
                    setDoctorMsg={setDoctorMsg}
                    setUserEmailMsg={setUserEmailMsg}
                    timeSlotMsg={timeSlotMsg}
                    setTimeSlotMsg={setTimeSlotMsg}
                    doctorServiceMsg={doctorServiceMsg}
                    setDoctorServiceMsg={setDoctorServiceMsg}
                    services={services}
                />
            )}

            {editAppointmentModal && (
                <EditAppointmentModal
                    {...props}
                    selectedTimeSlot={selectedTimeSlot}
                    setSelectedTimeSlot={setSelectedTimeSlot}
                    myTimeSlots={timeSlots}
                    date={date}
                    setDate={setDate}
                    selectedService={selectedService}
                    setSelectedService={setSelectedService}
                    appointmentModal={editAppointmentModal}
                    setAppointmentModal={setEditAppointmentModal}
                    clearAppointmentData={clearAppointmentData}
                    paymentMode={paymentMode}
                    setPaymentMode={setPaymentMode}
                    consultationMode={consultationMode}
                    setConsultationMode={setConsultationMode}
                    userEmail={userEmail}
                    setUserEmail={setUserEmail}
                    submitForm={submitForm}
                    chosenUser={chosenUser}
                    setChosenUser={setChosenUser}
                    user={user}
                    setUser={setUser}
                    selectedDoctor={selectedDoctor}
                    setSelectedDoctor={setSelectedDoctor}
                    loading={loading}
                    setLoading={setLoading}
                    userEmailMsg={userEmailMsg}
                    dateMsg={dateMsg}
                    setDateMsg={setDateMsg}
                    doctorMsg={doctorMsg}
                    setDoctorMsg={setDoctorMsg}
                    setUserEmailMsg={setUserEmailMsg}
                    timeSlotMsg={timeSlotMsg}
                    setTimeSlotMsg={setTimeSlotMsg}
                    editAppointmentItem={editAppointmentItem}
                    doctorServiceMsg={doctorServiceMsg}
                    setDoctorServiceMsg={setDoctorServiceMsg}
                    services={services}
                />
            )}

            {showEvent && (
                <ShowEventModal
                    showEvent={showEvent}
                    setShowEvent={setShowEvent}
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    history={history}
                    auth={auth}
                />
            )}

            <Pagination
                style={{ marginTop: 20 }}
                showQuickJumper
                current={page}
                total={appointmentsCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </motion.div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    branches: state.branch.branches,
    availability: state.availability,
    availabilities: state.availability.availabilities,
    appointments: state.appointments,
    appointments: state.appointments
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    availabilityActions: bindActionCreators(availabilityActions, dispatch),
    appointmentActions: bindActionCreators(appointmentActions, dispatch),
    therapistActions: bindActionCreators(therapistActions, dispatch),
    caregiverActions: bindActionCreators(caregiverActions, dispatch),
    nurseActions: bindActionCreators(nurseActions, dispatch),
    consultantActions: bindActionCreators(consultantActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
