import * as Types from '../actions/types';

const init = {
    availabilities: [],
    errors: [],
    errorMsg: '',
    myTimeSlots: [],
    myAvailability: {},
    viewAvailabilities: []
};

const availabilityReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_AVAILABILITY: {
            return {
                ...state,
                availabilities: action.payload.availabilities
            };
        }

        case Types.AVAILABILITY_ERROR: {
            return {
                ...state,
                errors: action.payload.errors,
                errorMsg: action.payload.errorMsg
            };
        }

        case Types.GET_VIEW_AVAILABILITY: {
            return {
                ...state,
                viewAvailabilities: action.payload.viewAvailabilities
            };
        }
        case Types.GET_SELF_TIME_SLOTS: {
            return {
                ...state,
                myTimeSlots: action.payload.timeSlots,
                myAvailability: action.payload.availability
            };
        }
        case Types.CLEAR_AVAILABILITY_ERROR: {
            return {
                ...state,
                errors: []
            };
        }

        default:
            return state;
    }
};

export default availabilityReducer;
