import React from 'react';
import ProfileSection from '../../DoctorProfile/ProfileSection';
import { FaXTwitter } from "react-icons/fa6";

export default function SocialSection({ setState, social }) {
    return (
        <ProfileSection title="Social Account Settings">
       
                <div className='input-row social'>
                    <label>
                        Facebook:
                        <i className='fa fa-facebook' />
                        <input
                            name='facebook'
                            placeholder='Facebook'
                            onChange={(e) =>
                                setState({
                                    formDirty: true,
                                    social: { ...social, facebook: e.target.value }
                                })
                            }
                            value={social?.facebook}
                        />
                    </label>

                    <label>
                        Instagram:
                        <i className='fa fa-instagram' />
                        <input
                            name='instagram'
                            placeholder='Instagram'
                            onChange={(e) =>
                                setState({
                                    formDirty: true,
                                    social: { ...social, instagram: e.target.value }
                                })
                            }
                            value={social?.instagram}
                        />
                    </label>
                </div>
                <div className='input-row social'>
                    <label>
                        X (formerly Twitter)
                        <FaXTwitter style={{height: 14, width: 14, position: 'absolute', top : 39, left: 11}}/>
                        <input
                            name='twitter'
                            placeholder='X'
                            onChange={(e) =>
                                setState({
                                    formDirty: true,
                                    social: { ...social, twitter: e.target.value }
                                })
                            }
                            value={social?.twitter}
                        />
                    </label>
                    <label>
                        LinkedIn
                        <i className='fa fa-linkedin' />
                        <input
                            name='linkedin'
                            placeholder='LinkedIn'
                            onChange={(e) =>
                                setState({
                                    formDirty: true,
                                    social: { ...social, linkedin: e.target.value }
                                })
                            }
                            value={social?.linkedin}
                        />
                    </label>
                </div>
      
        </ProfileSection>
    );
}
