import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import * as userActions from '../../actions/userActions';
import {
    setUserType,
    setRegister,
    setLogin,
    setForgotPass,
    setPhoneSignIn,
    setPhoneSignUp,
    setRegProp,
    setEmailVerifiedMsg,
    clearState,
    setResetPass
} from './AuthHelper';
import SignIn from '../modals/SignInModal';
import Register from '../modals/SignUpModal';
import SignInWithPhoneModal from '../modals/SignInWithPhoneModal';
import SignUpWithPhoneModal from '../modals/SignUpWithPhoneModal';
import ForgotPass from '../modals/ForgotPassModal';
import ResetPass from '../modals/ResetPasswordModal';
import { message } from 'antd';

function Modals(props) {
    const history = useHistory();
    const dispatch = useDispatch();
    const modalRoot = document.getElementById('modal-root');
    const {
        isAuthenticated,
        user,
        authModals: {
            modalUserType,
            signInModal,
            signUpModal,
            signInWithPhoneModal,
            signUpWithPhoneModal,
            forgotPassModal,
            resetPassModal,
            emailVerifiedMsg,
            regProp
        }
    } = useSelector((state) => state.auth);

    const closeModals = async () => {
        dispatch(clearState());
    };

    const clearErrors = async () => {
        await dispatch(userActions.clearErrors(props, props.history));
    };

    const openModal = async () => {
        const params = new URLSearchParams(window.location.search);
        let uType = params.get('userType');
        setUserType(uType);
        let document_shared = params.get('document_shared');
        let reset_pass = params.get('reset_password');
        let token = params.get('token');
        let reset_email_v = params.get('reset_email');
        let email_v = params.get('email_verified_registration');
        let uId = params.get('userId');
        if (reset_pass && uId && token) {
            dispatch(setResetPass(true));
        }
        console.log(document_shared, 'document_shared',  uType,  uId==user.userId)
        if (document_shared) {
            if (uType) {
                if (isAuthenticated && uId == user._id) {
                    console.log('got in')
                    let link;
                    if (uType == "HOSPITAL") {
                        link = '/hospital/documents';
                    }
                    if (uType == "DOCTOR") {
                        link = '/doctor/documents';
                    }
                    if (uType == "PATIENT") {
                        link = '/patient/documents';
                    }
                    history.push(link);
                    // console.log(link, 'got in')
                } else {
                    dispatch(setUserType(uType));
                    dispatch(setLogin(true));
                }
            } else {
                dispatch(setRegister(true));
            }
        }
        if (reset_email_v && token) {
            let obj = {
                userType: uType,
                userId: uId,
                token: token
            };
            let verifyResetEmail = await dispatch(
                userActions.resetEmail({ ...props, obj }, history)
            );
            if (verifyResetEmail) {
                dispatch(
                    setEmailVerifiedMsg(
                        'Reset Email Successful, you can now sign in by entering your new email address and password above.'
                    )
                );
                dispatch(setUserType(uType));
                dispatch(setLogin(true));
            }
        }

        //email verification registration
        if (email_v && uId) {
            let obj = {
                userId: uId,
                userType: uType
            };
            let verify = await dispatch(userActions.verifyEmail({ ...props, obj }, history));
            if (verify) {
                dispatch(
                    setEmailVerifiedMsg(
                        `Thank you for confirming your email address! You can now access your account by entering your email address and password above.`
                    )
                );
                dispatch(setUserType(uType));
                dispatch(setLogin(true));
            } else {
                message.error('Email verification failed!');
            }
        }
    };
    useEffect(() => {
        openModal();
    }, []);
    return (
        <div>
            {!isAuthenticated && signInModal && (
                <SignIn
                    open={signInModal}
                    signUpModal={() => dispatch(setRegister(true, modalUserType))}
                    closeLogin={() => {
                        clearErrors();
                        closeModals();
                    }}
                    forgotPassModal={() => dispatch(setForgotPass(true))}
                    phoneSignInModal={() => dispatch(setPhoneSignIn(true))}
                    userTypeProp={modalUserType}
                    setUserTypeProp={(value) => dispatch(setUserType(value))}
                    regProp={regProp}
                    phoneSignUpModal={() => dispatch(setPhoneSignUp(true))}
                    setRegProp={(value) => dispatch(setRegProp(value))}
                    emailVerifiedMsg={emailVerifiedMsg}
                    setEmailVerifiedMsg={(value) => dispatch(setEmailVerifiedMsg(value))}
                    history={history}
                    {...props}
                />
            )}

            {signUpModal && !isAuthenticated && (
                <Register
                    open={signUpModal}
                    closeRegister={() => {
                        clearErrors();
                        closeModals();
                    }}
                    signInModal={() => dispatch(setLogin(true, modalUserType))}
                    forgotPassModal={() => dispatch(setForgotPass(true))}
                    phoneSignInModal={() => dispatch(setPhoneSignIn(true))}
                    userTypeProp={modalUserType}
                    setUserTypeProp={(value) => dispatch(setUserType(value))}
                    regProp={regProp}
                    phoneSignUpModal={() => dispatch(setPhoneSignUp(true))}
                    setRegProp={(value) => dispatch(setRegProp(value))}
                    emailVerifiedMsg={emailVerifiedMsg}
                    setEmailVerifiedMsg={(value) => dispatch(setEmailVerifiedMsg(value))}
                    history={history}
                    {...props}
                />
            )}

            {signInWithPhoneModal && !isAuthenticated && (
                <SignInWithPhoneModal
                    open={signInWithPhoneModal}
                    closePhoneSignIn={() => {
                        clearErrors();
                        closeModals();
                    }}
                    phoneSignInModal={() => dispatch(setPhoneSignIn(true))}
                    signInModal={() => dispatch(setLogin(true))}
                    forgotPassModal={() => dispatch(setForgotPass(true))}
                    signUpModal={() => dispatch(setRegister(true))}
                    userTypeProp={modalUserType}
                    setUserTypeProp={(value) => dispatch(setUserType(value))}
                    regProp={regProp}
                    phoneSignUpModal={() => dispatch(setPhoneSignUp(true))}
                    closePhoneSignUp={() => {
                        clearErrors();
                        dispatch(setPhoneSignUp(false));
                    }}
                    setRegProp={(value) => dispatch(setRegProp(value))}
                    emailVerifiedMsg={emailVerifiedMsg}
                    setEmailVerifiedMsg={(value) => dispatch(setEmailVerifiedMsg(value))}
                    history={history}
                    {...props}
                />
            )}

            {signUpWithPhoneModal && !isAuthenticated && (
                <SignUpWithPhoneModal
                    open={signUpWithPhoneModal}
                    closeForgotPass={() => {
                        clearErrors();
                        // closeModals();
                    }}
                    signInModal={() => dispatch(setLogin(true))}
                    signUpModal={() => dispatch(setRegister(true))}
                    userTypeProp={modalUserType}
                    setUserTypeProp={(value) => dispatch(setUserType(value))}
                    regProp={regProp}
                    forgotPassModal={() => dispatch(setForgotPass(true))}
                    phoneSignUpModal={() => dispatch(setPhoneSignUp(true))}
                    phoneSignInModal={() => dispatch(setPhoneSignIn(true))}
                    closePhoneSignIn={() => dispatch(setPhoneSignIn(false))}
                    closePhoneSignUp={() => dispatch(setPhoneSignUp(false))}
                    setRegProp={(value) => dispatch(setRegProp(value))}
                    emailVerifiedMsg={emailVerifiedMsg}
                    setEmailVerifiedMsg={(value) => dispatch(setEmailVerifiedMsg(value))}
                    history={history}
                    {...props}
                />
            )}

            {forgotPassModal && !isAuthenticated && (
                <ForgotPass
                    open={forgotPassModal}
                    closeForgotPass={() => {
                        clearErrors();
                        closeModals();
                    }}
                    signInModal={() => dispatch(setLogin(true))}
                    signUpModal={() => dispatch(setRegister(true))}
                    userTypeProp={modalUserType}
                    setUserTypeProp={(value) => dispatch(setUserType(value))}
                    phoneSignUpModal={() => dispatch(setPhoneSignUp(true))}
                    regProp={regProp}
                    setRegProp={(value) => dispatch(setRegProp(value))}
                    emailVerifiedMsg={emailVerifiedMsg}
                    setEmailVerifiedMsg={(value) => dispatch(setEmailVerifiedMsg(value))}
                    history={history}
                    {...props}
                />
            )}

            {resetPassModal && !isAuthenticated && (
                <ResetPass
                    open={resetPassModal}
                    closeResetPass={() => {
                        clearErrors();
                        closeModals();
                    }}
                    signInModal={() => dispatch(setLogin(true))}
                    signUpModal={() => dispatch(setRegister(true))}
                    userTypeProp={modalUserType}
                    setUserTypeProp={(value) => dispatch(setUserType(value))}
                    regProp={regProp}
                    setRegProp={(value) => dispatch(setRegProp(value))}
                    phoneSignUpModal={() => dispatch(setPhoneSignUp(true))}
                    emailVerifiedMsg={emailVerifiedMsg}
                    setEmailVerifiedMsg={(value) => dispatch(setEmailVerifiedMsg(value))}
                    history={history}
                    {...props}
                />
            )}
        </div>
    );
}

export default Modals;
