import React from 'react'
import { Skeleton } from 'antd'


const SkeletonSection = () => {
  return (
    <Skeleton />
  )
}

export default SkeletonSection