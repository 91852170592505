import React from 'react';
import { motion } from 'framer-motion';
import ReactDOM from 'react-dom';
import { Button, Select } from 'antd';

const modalRoot = document.getElementById('modal-root');
const { Option } = Select;

function AvailabilitySidebar(props) {
    const {
        sidebar,
        setSidebar,
        setCreateAvailabilityModal,
        availabilities,
        selectedAddress,
        setSelectedAddress,
        selectedAddressIndex,
        setSelectedAddressIndex,
        schedule,
        setSchedule,
        branches,
        profile
    } = props;

    let hospitalSchedule =
        branches &&
        branches.length > 0 &&
        branches[selectedAddressIndex] &&
        branches[selectedAddressIndex].availability &&
        branches[selectedAddressIndex].availability;

    const sidebarVariant = {
        initial: {
            x: '100%',
            transition: { duration: 0.7, easing: 'easeInOut' }
        },
        open: {
            x: 0,
            transition: { duration: 0.7, easing: 'easeInOut' }
        },
        exit: {
            x: '100%',
            transition: { duration: 0.7, easing: 'easeInOut' }
        }
    };
    return ReactDOM.createPortal(
        <motion.div className='availSidebar'>
            <motion.div className='overlay' onClick={() => setSidebar(false)}></motion.div>
            <motion.div
                variants={sidebarVariant}
                initial='initial'
                animate='open'
                exit='exit'
                className='sidebarRight'
            >
                {profile.hospitalBranches.length > 0 && (
                    <>
                        <h5>Choose Location</h5>
                        <Select
                            style={{ width: '100%' }}
                            placeholder='Select Address'
                            value={selectedAddress}
                            onChange={(val, obj) => {
                                setSelectedAddressIndex(obj.key);
                                setSelectedAddress(val);
                            }}
                        >
                            {branches &&
                                branches !== 0 &&
                                branches.map((it, ind) => (
                                    <Option key={ind} value={it._id}>
                                        {it.location &&
                                            it.location.branchAddress &&
                                            it.location.branchAddress}
                                    </Option>
                                ))}
                        </Select>
                    </>
                )}
                {profile.hospitalBranches.length > 0 && (
                    <>
                        {hospitalSchedule ? (
                            <>
                                <div
                                    className='hospital-view-schedule'
                                    style={{ marginBottom: 30, marginTop: 30 }}
                                >
                                    <h5 style={{ textAlign: 'left' }}>Hospital Schedule</h5>

                                    <p>
                                        TimeZone:{' '}
                                        <span>
                                            {hospitalSchedule.timezone && hospitalSchedule.timezone}{' '}
                                        </span>
                                    </p>
                                    {hospitalSchedule && hospitalSchedule.sunday ? (
                                        <p>
                                            Sunday:{' '}
                                            <span>
                                                {hospitalSchedule.sunday.startTime}-
                                                {hospitalSchedule.sunday.endTime}
                                            </span>
                                        </p>
                                    ) : null}
                                    {hospitalSchedule && hospitalSchedule.monday ? (
                                        <p>
                                            Monday:{' '}
                                            <span>
                                                {hospitalSchedule.monday.startTime}-
                                                {hospitalSchedule.monday.endTime}
                                            </span>
                                        </p>
                                    ) : null}
                                    {hospitalSchedule && hospitalSchedule.tuesday ? (
                                        <p>
                                            Tuesday:{' '}
                                            <span>
                                                {hospitalSchedule.tuesday.startTime}-
                                                {hospitalSchedule.tuesday.endTime}
                                            </span>
                                        </p>
                                    ) : null}
                                    {hospitalSchedule && hospitalSchedule.wednesday ? (
                                        <p>
                                            Wednesday:{' '}
                                            <span>
                                                {hospitalSchedule.wednesday.startTime}-
                                                {hospitalSchedule.wednesday.endTime}
                                            </span>
                                        </p>
                                    ) : null}
                                    {hospitalSchedule && hospitalSchedule.thursday ? (
                                        <p>
                                            Thursday:{' '}
                                            <span>
                                                {hospitalSchedule.thursday.startTime}-
                                                {hospitalSchedule.thursday.endTime}
                                            </span>
                                        </p>
                                    ) : null}
                                    {hospitalSchedule && hospitalSchedule.friday ? (
                                        <p>
                                            Friday:{' '}
                                            <span>
                                                {hospitalSchedule.friday.startTime}-
                                                {hospitalSchedule.friday.endTime}
                                            </span>
                                        </p>
                                    ) : null}
                                    {hospitalSchedule && hospitalSchedule.saturday ? (
                                        <p>
                                            Saturday:{' '}
                                            <span>
                                                {hospitalSchedule.saturday.startTime}-
                                                {hospitalSchedule.saturday.endTime}
                                            </span>
                                        </p>
                                    ) : null}
                                </div>
                            </>
                        ) : (
                            <div
                                className='hospital-view-schedule'
                                style={{ marginBottom: 30, marginTop: 30 }}
                            >
                                <h5 style={{ textAlign: 'left' }}>Hospital Schedule</h5>
                                <p>No Schedule fixed yet!</p>
                            </div>
                        )}
                    </>
                )}

                {schedule ? (
                    <>
                        {/* <div className="hospital-view-timezone">
              <p>
                TimeZone:{" "}
                <span>{schedule.timezone && schedule.timezone} </span>
              </p>
            </div> */}
                        <div className='hospital-view-schedule'>
                            <h5 style={{ textAlign: 'left' }}>Your schedule</h5>
                            <p>
                                TimeZone: <span>{schedule.timezone && schedule.timezone} </span>
                            </p>
                            {schedule && schedule.sunday ? (
                                <p>
                                    Sunday:{' '}
                                    <span>
                                        {schedule.sunday.startTime}-{schedule.sunday.endTime}
                                    </span>
                                </p>
                            ) : null}
                            {schedule && schedule.monday ? (
                                <p>
                                    Monday:{' '}
                                    <span>
                                        {schedule.monday.startTime}-{schedule.monday.endTime}
                                    </span>
                                </p>
                            ) : null}
                            {schedule && schedule.tuesday ? (
                                <p>
                                    Tuesday:{' '}
                                    <span>
                                        {schedule.tuesday.startTime}-{schedule.tuesday.endTime}
                                    </span>
                                </p>
                            ) : null}
                            {schedule && schedule.wednesday ? (
                                <p>
                                    Wednesday:{' '}
                                    <span>
                                        {schedule.wednesday.startTime}-{schedule.wednesday.endTime}
                                    </span>
                                </p>
                            ) : null}
                            {schedule && schedule.thursday ? (
                                <p>
                                    Thursday:{' '}
                                    <span>
                                        {schedule.thursday.startTime}-{schedule.thursday.endTime}
                                    </span>
                                </p>
                            ) : null}
                            {schedule && schedule.friday ? (
                                <p>
                                    Friday:{' '}
                                    <span>
                                        {schedule.friday.startTime}-{schedule.friday.endTime}
                                    </span>
                                </p>
                            ) : null}
                            {schedule && schedule.saturday ? (
                                <p>
                                    Saturday:{' '}
                                    <span>
                                        {schedule.saturday.startTime}-{schedule.saturday.endTime}
                                    </span>
                                </p>
                            ) : null}
                        </div>
                    </>
                ) : (
                    <div
                        className='hospital-view-schedule'
                        style={{ marginBottom: 30, marginTop: 30 }}
                    >
                        <h5 style={{ textAlign: 'left' }}>Your Schedule</h5>
                        <p>No Schedule fixed yet!</p>
                    </div>
                )}
                {/* {selectedAddress && hospitalSchedule && ( */}
                <Button type='primary' onClick={() => setCreateAvailabilityModal(true)}>
                    {schedule ? 'Update Schedule' : 'Create Schedule'}
                </Button>
                {/* )} */}
            </motion.div>
        </motion.div>,
        modalRoot
    );
}

export default AvailabilitySidebar;
