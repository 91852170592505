import React, { useState } from 'react';
import { LogoutOutlined, BellOutlined, UserOutlined, UserAddOutlined } from '@ant-design/icons';
import { Dropdown, Space, Divider, Button, theme, Menu, message } from 'antd';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import * as Types from '@/actions/types';
import { updateMedicalDocsModal } from '@/actions/generalActions';
import { useDispatch } from 'react-redux';
import MenuIcon from '@/assets/img/svgs/MenuIcon';
import * as userActions from '@/actions/userActions';
import { setRegister, setLogin } from '../Auth/AuthHelper';
import { useHistory } from 'react-router-dom';
import UnlockDashboardMenu from './UnlockDashboardMenu';
const { useToken } = theme;

const UserDropdown = ({}) => {
    const [approvedBranches, setApprovedBranches] = useState(false);
    const {
        isAuthenticated,
        userType,
        user: { firstName }
    } = useSelector((state) => state.auth);

    const history = useHistory();
    const dispatch = useDispatch();
    const { token } = useToken();

    const contentStyle = {
        backgroundColor: token.colorBgElevated,
        borderRadius: token.borderRadiusLG,
        boxShadow: token.boxShadowSecondary,
        width: 300
    };
    const menuStyle = {
        boxShadow: 'none'
    };
    const logout = (e) => {
        e.preventDefault();

        dispatch(userActions.logout(history));
        message.success(<p> Logged out Successfully!!" &nbsp; &#9749;</p>);
    };
    let linkPrefix = isAuthenticated ? `/${userType.toLowerCase()}/` : '';

    const menuItems = (
        <Menu style={menuStyle}>
            {isAuthenticated ? (
                <>
                    <h5 style={{ padding: '8px 12px', margin: 0, fontWeight: 400, fontSize: 14 }}>
                        Hi, {firstName}
                    </h5>
                    <Divider
                        style={{
                            margin: 0
                        }}
                    />
                    {userType !== 'HOSPITAL' && userType !== 'DOCTOR' ? (
                        <Menu.Item key='profile' style={{ padding: '8px 12px' }}>
                            <Link
                                to={{ pathname: linkPrefix + 'profile', state: { tab: 'profile' } }}
                            >
                                <Space>
                                    <UserOutlined />
                                    My Account
                                </Space>
                            </Link>
                        </Menu.Item>
                    ) : (
                        <Menu.Item key='dadshboard' style={{ padding: '8px 12px' }}>
                            <Link
                                to={{
                                    pathname: linkPrefix + 'dashboard',
                                    state: { tab: 'dashboard' }
                                }}
                            >
                                <Space>
                                    <UserOutlined />
                                    Dashboard
                                </Space>
                            </Link>
                        </Menu.Item>
                    )}

                    {approvedBranches && (
                        <Menu.Item key='notifications' style={{ padding: '8px 12px' }}>
                            <Link to={{ pathname: linkPrefix + 'notifications' }}>
                                <Space>
                                    <BellOutlined />
                                    Notifications{' '}
                                </Space>
                            </Link>
                        </Menu.Item>
                    )}

                    <Divider
                        style={{
                            margin: 0
                        }}
                    />
                    <Menu.Item key='logout' style={{ padding: '8px 12px' }}>
                        <a onClick={logout}>
                            <Space>
                                <LogoutOutlined />
                                <span>Logout</span>
                            </Space>
                        </a>
                    </Menu.Item>
                </>
            ) : (
                <>
                    <h5 style={{ padding: '8px 12px', margin: 0, fontWeight: 400, fontSize: 14 }}>
                        Hi, Guest
                    </h5>
                    <Divider
                        style={{
                            margin: 0
                        }}
                    />

                    <Menu.Item key='signIn' style={{ padding: '8px 12px' }}>
                        <a
                            onClick={() => {
                                dispatch(setLogin(true));
                            }}
                        >
                            <Space>
                                <UserOutlined />
                                Sign In
                            </Space>
                        </a>
                    </Menu.Item>
                    <Menu.Item key='signUp' style={{ padding: '8px 12px' }}>
                        <a
                            onClick={() => {
                                dispatch(setRegister(true));
                            }}
                        >
                            <Space>
                                <UserAddOutlined />
                                Sign Up
                            </Space>
                        </a>
                    </Menu.Item>
                </>
            )}
        </Menu>
    );
    return (
        <>
            {' '}
            <UnlockDashboardMenu
                approvedBranches={approvedBranches}
                setApprovedBranches={setApprovedBranches}
            />
            <Dropdown
                trigger={['click']}
                dropdownRender={() => (
                    <div style={contentStyle}>
                        {menuItems}
                        <Divider
                            style={{
                                margin: 0
                            }}
                        />
                        <Space
                            style={{
                                padding: 4,
                                width: '100%',
                                justifyContent: 'space-around',
                                gap: 0
                            }}
                        >
                            <Link to='/our-mission' type='primary'>
                                <Button style={{ padding: '0 10px' }} type='text'>
                                    About
                                </Button>
                            </Link>
                            <Button
                                type='text'
                                style={{ padding: '0 10px' }}
                                onClick={() =>
                                    dispatch(
                                        updateMedicalDocsModal({
                                            type: Types.UPDATE_GUIDELINES_MODAL,
                                            payload: {
                                                guidelinesModal: true
                                            }
                                        })
                                    )
                                }
                            >
                                Guidelines
                            </Button>
                            <Button
                                type='text'
                                style={{ padding: '0 10px' }}
                                onClick={() =>
                                    dispatch(
                                        updateMedicalDocsModal({
                                            type: Types.UPDATE_TERMS_MODAL,
                                            payload: {
                                                termsModal: true
                                            }
                                        })
                                    )
                                }
                            >
                                Terms
                            </Button>
                            <Button
                                type='text'
                                style={{ padding: '0 10px' }}
                                onClick={() =>
                                    dispatch(
                                        updateMedicalDocsModal({
                                            type: Types.UPDATE_PRIVACY_POLICY_MODAL,
                                            payload: {
                                                privacyPolicyModal: true
                                            }
                                        })
                                    )
                                }
                            >
                                Privacy
                            </Button>
                        </Space>
                    </div>
                )}
            >
                <Button
                    style={{
                        width: 64,
                        height: 64,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '4px 15px'
                    }}
                    type='text'
                    onClick={(e) => {
                        e.preventDefault();
                    }}
                >
                    <MenuIcon />
                </Button>
            </Dropdown>
        </>
    );
};
export default UserDropdown;
