import React, { useState, memo, useEffect } from 'react';
import './experience.css';
import * as userActions from '@/actions/userActions';
import * as branchActions from '@/actions/branchActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { message } from 'antd';
import CredentialHeader from './CredentialHeader';
import CredentialsTable from '@/components/NewProfile/Shared/Credentials/CredentialsTable';

const Experience = (props) => {
    const { auth, credentialType, setCredentialType, credentialTypes, branch } = props;
    const [experienceModal, setExperienceModal] = useState(false);
    const [limit, setLimit] = useState(branch ? 5 : 10);
    const [page, setPage] = useState(1);
    const [editExperience, setEditExperience] = useState(null);
    const [deleteExperience, setDeleteExperience] = useState(null);
    const { pharmacist } = branch ?? {};

    const [formInputs, setFormInputs] = useState([]);

    const handleSave = async () => {
        for (let i = 0; i < formInputs.length; i++) {
            if (
                formInputs[i].location === '' ||
                formInputs[i].organization === '' ||
                formInputs[i].position === '' ||
                formInputs[i].duration === ''
            ) {
                message.error('Cannot Save Empty Field');
                return;
            }
        }

        let profileObj = {
            data: {
                experiences: [...formInputs]
            },
            userType: props.auth.user.userType,
            userId: props.auth.user._id
        };

        await props.actions.saveProfile(profileObj, props.history);

        let profileShort = {
            userId: props.auth.user._id,
            userType: props.auth.user.userType
        };
        await props.actions.getProfile(profileShort, props.history);
    };

    useEffect(() => {
        if (
            props.profile &&
            props.profile.experiences &&
            props.profile.experiences.length !== 0 &&
            !pharmacist
        ) {
            setFormInputs(props.profile.experiences);
        } else if (!pharmacist) {
            setFormInputs([]);
        }

        if (branch) {
            if (pharmacist && pharmacist?.experiences) {
                setFormInputs(pharmacist.experiences);
            } else {
                setFormInputs([]);
            }
        }
    }, [props.profile.experiences, branch]);

    const makeExperienceCurrent = (record, index) => {
        const values = [...formInputs];
        values.forEach((experience, i) => {
            if (i === index) {
                experience.current = !experience.current;
            } else {
                experience.current = false;
            }
        });
        setFormInputs(values);
        handleSave();
    };

    return (
        <>
            <CredentialHeader
                auth={auth}
                credentialType={credentialType}
                credentialTypes={credentialTypes}
                setCredentialType={setCredentialType}
                experienceModal={experienceModal}
                setExperienceModal={setExperienceModal}
                {...props}
            />
            <CredentialsTable
                auth={auth}
                credentialType={credentialType}
                data={formInputs}
                limit={limit}
                setLimit={setLimit}
                page={page}
                setPage={setPage}
                experienceModal={experienceModal}
                setExperienceModal={setExperienceModal}
                editExperience={editExperience}
                setEditExperience={setEditExperience}
                deleteExperience={deleteExperience}
                setDeleteExperience={setDeleteExperience}
                makeExperienceCurrent={makeExperienceCurrent}
                {...props}
                pharmacist={pharmacist}
                branch={branch}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Experience));
