import React, { useRef, useState, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { storage } from '../../FirebaseConfig';
import * as blogActions from '../../actions/blogActions';
import * as adminActions from '../../actions/adminActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import ClassicEditor from "@xccjh/xccjh-ckeditor5-video-file-upload";

import ClassicEditor from '@dmc4719/ckeditor5-custom-build/build/ckeditor';
// import * as ClassicEditor from "./ckeditor5/build/ckeditor"

import { CONFIG } from '../MiniComponents/CKeditorConfig';
import '../../assets/css/createBlog.css';
import MyCKEditorUploadAdapter from '../NewProfile/MyCKEditorUploadAdapter';
import { Select, message } from 'antd';
import placeholderImage from '../../assets/img/placeholder-16-9.png';

const { Option } = Select;

function EditBlog(props) {
    const editor = useRef(null);
    const [coverImage, setCoverImage] = useState();
    const [coverImageError, setCoverImageError] = useState('');
    const [tempCoverImage, setTempCoverImage] = useState();
    const [title, setTitle] = useState({ value: '', message: '' });
    const [editorValue, setEditorValue] = useState({
        value: 'Hello from CkEditor',
        message: ''
    });
    const [category, setCategory] = useState({ value: [], message: '' });
    const [status, setStatus] = useState({ value: 'PUBLISHED', message: '' });
    const [formDirty, setFormDirty] = useState(false);
    const [singlePost, setSinglePost] = useState(null);
    const [oldCoverImage, setOldCoverImage] = useState('');
    const categories = ['Food Recipes', 'Food Reviews', 'Food and Travel', 'Food Industry News'];

    function handleChange(value) {
        console.log(value);
        if (value.length) {
            setCategory({ value: value, message: '' });
        } else {
            setCategory({ value: value, message: 'Catgory cannot be empty' });
        }
    }

    useEffect(() => {
        let postId = props.match.params.postId;
        console.log(postId);
        props.blogActions.getSinglePost({ postId, ...props });
    }, []);

    useEffect(() => {
        if (!formDirty && singlePost) {
            console.log('hi');
            setTitle({ ...title, value: props.blogs.singlePost.title });
            setEditorValue({ ...editorValue, value: props.blogs.singlePost.body });
            setCategory({ ...category, value: props.blogs.singlePost.category });
            setStatus({ ...status, value: props.blogs.singlePost.status });
            if (props.blogs.singlePost.coverImage) {
                setTempCoverImage(props.blogs.singlePost.coverImage);
                setCoverImage(props.blogs.singlePost.coverImage);
                setOldCoverImage(props.blogs.singlePost.coverImage);
            }
        }
    }, [singlePost]);

    useEffect(() => {
        if (props.blogs.posts) {
            if (props.blogs.singlePost && props.match.params.postId == props.blogs.singlePost._id)
                setSinglePost(props.blogs.singlePost);
        }
    }, [props.blogs]);

    const updatePost = async () => {
        let error = false;

        if (!title.value || !editorValue.value || !coverImage || !category) {
            if (!title.value) {
                setTitle({ ...title, message: 'Title field cannot be empty' });
                error = true;
            }

            if (!editorValue.value) {
                setEditorValue({
                    ...editorValue,
                    message: 'Body field cannot be empty'
                });
                error = true;
            }

            if (!category.value.length) {
                setCategory({ ...category, message: 'Catgory cannot be empty' });
                error = true;
            }

            if (!coverImage) {
                setCoverImageError('Cover image cannot be empty');
                error = true;
            }

            return;
        }

        console.log('here');

        let currentTimeDate = Date.now();
        if (coverImage && !error) {
            if (oldCoverImage == coverImage) {
                let obj = {
                    postId: singlePost._id,
                    title: title.value,
                    body: editorValue.value,
                    coverImage: coverImage,
                    category: category.value,
                    author: singlePost.author._id,
                    status: status.value
                };

                console.log(obj);

                try {
                    let updatedPost = await props.adminActions.updatePost({
                        obj,
                        ...props
                    });

                    if (updatedPost) {
                        console.log('updatedPost', updatedPost);
                        // await storage.refFromURL(`${singlePost.coverImage}`).delete().catch(err=>console.log(err));

                        props.history.push('/admin/blogs');
                    }
                } catch (error) {
                    console.log(error);
                    message.error('Failed to update post');
                }
            } else {
                const uploadTask = storage
                    .ref(`images/${singlePost.author._id}/blogs/`)
                    .child(`${coverImage.name}-${currentTimeDate}`)
                    .put(coverImage);
                uploadTask.on(
                    'state_changed',

                    (snapshot) => {
                        const progress = Math.round(
                            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                        );
                    },
                    (error) => {
                        console.log(error);
                    },
                    () => {
                        storage
                            .ref(`images/${singlePost.author._id}/blogs/`)
                            .child(`${coverImage.name}-${currentTimeDate}`)
                            .getDownloadURL()
                            .then(async (url) => {
                                console.log(url);
                                let obj = {
                                    postId: singlePost._id,
                                    title: title.value,
                                    body: editorValue.value,
                                    coverImage: url,
                                    category: category.value,
                                    author: singlePost.author._id,
                                    status: status.value
                                };
                                console.log(obj);

                                try {
                                    let updatedPost = await props.adminActions.updatePost({
                                        obj,
                                        ...props
                                    });

                                    if (updatedPost) {
                                        console.log('updatedPost', updatedPost);
                                        await storage
                                            .refFromURL(`${singlePost.coverImage}`)
                                            .delete()
                                            .catch((err) => console.log(err));

                                        props.history.push('/business/blogs');
                                    }
                                } catch (error) {
                                    console.log(error);
                                    message.error('Failed to update post');
                                }
                            });
                    }
                );
            }
        } else {
            message.error('Fill up required fields!');
        }
    };
    return (
        <div className='createPost' style={{ width: '100%' }}>
            {console.log(category.value)}
            <h2>
                <i
                    className='fa fa-chevron-left'
                    style={{ marginRight: 15, cursor: 'pointer' }}
                    onClick={() => props.history.goBack()}
                />
                Edit Blog Post
            </h2>
            <div className='input-cont'>
                <img
                    className='tempCoverImage'
                    onError={(e) => {
                        e.target.src = placeholderImage;
                    }}
                    src={tempCoverImage ? tempCoverImage : null}
                />
                {coverImageError && <p className='error-text'>*{coverImageError}</p>}
            </div>

            <label htmlFor='coverImage' className='chooseCover gray-btn'>
                Choose Cover
                <input
                    id='coverImage'
                    type='file'
                    onChange={(e) => {
                        setCoverImage(e.target.files[0]);
                        if (e.target.files[0]) {
                            setCoverImageError('');
                            setTempCoverImage(URL.createObjectURL(e.target.files[0]));
                        } else {
                            setCoverImageError('Cover image cannot be empty');
                        }
                    }}
                />
            </label>
            <div className='input-cont'>
                <input
                    placeholder='Post title'
                    value={title.value}
                    onChange={(e) => {
                        if (e.target.value != '') {
                            setTitle({ ...title, message: '', value: e.target.value });
                        } else {
                            setTitle({
                                ...title,
                                message: 'Title field cannot be empty',
                                value: e.target.value
                            });
                        }
                    }}
                />
                {title.message && <p className='error-text'>*{title.message}</p>}
            </div>

            <div className='input-cont'>
                <Select
                    mode='multiple'
                    placeholder='Select categories'
                    value={category.value}
                    onChange={handleChange}
                    style={{ width: '100%' }}
                >
                    {categories.map((item, index) => (
                        <Option value={item} key={index}>
                            {item}
                        </Option>
                    ))}
                </Select>
                {category.message && <p className='error-text'>*{category.message}</p>}
            </div>

            <div className='input-cont'>
                <Select
                    mode='single'
                    placeholder='Select Status'
                    value={status.value}
                    onChange={(val) => setStatus({ ...status, value: val })}
                    style={{ width: '100%' }}
                >
                    <Option value={'PUBLISHED'}>PUBLISHED</Option>
                    <Option value={'UNPUBLISHED'}>UNPUBLISHED</Option>
                </Select>
                {category.message && <p className='error-text'>*{category.message}</p>}
            </div>

            <div className='input-cont'>
                <CKEditor
                    ref={editor}
                    config={CONFIG}
                    // style={{ width: "100%", margin: "auto" }}
                    editor={ClassicEditor}
                    data={editorValue.value}
                    onReady={(editor) => {
                        // You can store the "editor" and use when it is needed.
                        console.log('Editor is ready to use!', editor);
                        editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                            return new MyCKEditorUploadAdapter({ loader, ...props });
                        };
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        if (data != '') {
                            setEditorValue({ ...editorValue, value: data, message: '' });
                        } else {
                            setEditorValue({
                                ...editorValue,
                                message: 'Body field cannot be empty',
                                value: data
                            });
                        }
                        console.log({ event, editor, data });
                    }}
                    onBlur={(event, editor) => {
                        console.log('Blur.', editor);
                    }}
                    onFocus={(event, editor) => {
                        console.log('Focus.', editor);
                    }}
                />
                {editorValue.message && <p className='error-text'>{editorValue.message}</p>}
            </div>

            <button className='red-btn' onClick={() => updatePost()}>
                Submit
            </button>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin,
    profile: state.auth.userProfile,
    blogs: state.blogs
});

const mapDispatchToProps = (dispatch) => ({
    blogActions: bindActionCreators(blogActions, dispatch),
    adminActions: bindActionCreators(adminActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(EditBlog);
