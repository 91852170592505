import React, { useEffect } from 'react';
import * as HospitalActions from '../../actions/hospitalActions';
import * as reviewActions from '../../actions/reviewActions';
import * as adminActions from '../../actions/adminActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

function Home(props) {
    useEffect(() => {
        props.adminActions.getAllUserCount(props, props.history);
    }, []);
    const { allUserCount } = props.admin;
    return (
        <div>
            <h1>Admin Dashboard</h1>

            <div className='userCardsRow'>
                <div className='userCard' onClick={() => props.history.push('/admin/admins')}>
                    <h3>
                        <span>{allUserCount.AdminUserCount ? allUserCount.AdminUserCount : 0}</span>{' '}
                        Admin Users
                    </h3>
                </div>
                <div className='userCard' onClick={() => props.history.push('/admin/hospitals')}>
                    <h3>
                        {' '}
                        <span>
                            {allUserCount.HospitalUserCount ? allUserCount.HospitalUserCount : 0}
                        </span>{' '}
                        Hospital Users
                    </h3>
                </div>
                <div className='userCard' onClick={() => props.history.push('/admin/doctors')}>
                    <h3>
                        {' '}
                        <span>
                            {allUserCount.DoctorUserCount ? allUserCount.DoctorUserCount : 0}
                        </span>{' '}
                        Doctor Users
                    </h3>
                </div>
                <div className='userCard' onClick={() => props.history.push('/admin/customers')}>
                    <h3>
                        {' '}
                        <span>
                            {allUserCount.ClientUserCount ? allUserCount.ClientUserCount : 0}
                        </span>
                        Client Users
                    </h3>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    hospitals: state.hospital,
    admin: state.admin
});

const mapDispatchToProps = (dispatch) => ({
    HospitalActions: bindActionCreators(HospitalActions, dispatch),
    reviewActions: bindActionCreators(reviewActions, dispatch),
    adminActions: bindActionCreators(adminActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Home);
