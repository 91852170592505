import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '@/actions/adminActions';

function Subscriptions(props) {
    const [stripeSubs, setStripeSubs] = useState();
    useEffect(() => {
        props.adminActions.getStripeSubscriptions(props, props.history);
    }, []);

    useEffect(() => {
        if (
            props.admin &&
            props.admin.stripeSubscriptions &&
            props.admin.stripeSubscriptions.data
        ) {
            console.log('have', props.admin.stripeSubscriptions.data);
            setStripeSubs(props.admin.stripeSubscriptions.data);
        }
    }, [props.admin.stripeSubscriptions]);
    return (
        <div className='adminTable'>
            <h1>
                <i
                    style={{ cursor: 'pointer' }}
                    onClick={() => props.history.push('/admin/stripe')}
                    className='fe fe-chevron-left'
                />{' '}
                Stripe Subscriptions
            </h1>
            {console.log('props', props.admin.stripeSubscriptions, stripeSubs)}
            <div className='table'>
                <table>
                    <thead>
                        <tr className='tableHeader'>
                            <th>SUBSCRIPTION ID</th>
                            <th>SUBSCRIPTION PACKAGE</th>
                            <th>EMAIL</th>
                            <th>PHONE</th>
                            <th>PAYMENT METHOD</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {console.log(props)}
                        {stripeSubs &&
                            stripeSubs.map((item, index) => (
                                <tr className='breakrow'>
                                    <td>
                                        <div>{item.id}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.metadata &&
                                                item.metadata.subscribedPackage &&
                                                item.metadata.subscribedPackage}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.metadata &&
                                                item.metadata.email &&
                                                item.metadata.email}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.metadata &&
                                                item.metadata.countryCode &&
                                                item.metadata.countryCode}{' '}
                                            {item.metadata &&
                                                item.metadata.phone &&
                                                item.metadata.phone}
                                        </div>
                                    </td>
                                    <td>
                                        <div></div>
                                    </td>
                                    <td>
                                        <div></div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        admin: state.admin
    };
}

function mapDispatchToProps(dispatch) {
    return {
        adminActions: bindActionCreators(adminActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Subscriptions);
