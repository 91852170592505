import React, { useState, useEffect, memo } from 'react';
import '@/assets/css/hospitalView.css';
import LowerSection from './LowerSection';
import { useParams } from 'react-router-dom';
import * as hospitalActions from '@/actions/hospitalActions';
import * as reviewActions from '@/actions/reviewActions';
import * as messagingActions from '@/actions/messagingActions';
import * as generalActions from '@/actions/generalActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import queryString from 'query-string';
import { Select } from 'antd';
import * as marketingActions from '@/actions/marketingAction';
import placeholderCover from '@/assets/images/coverPlaceholder.jpeg';
import MessageModal from '../../../modals/MessageModal';
import ReviewModal from '../../../modals/ReviewModal';
const { Option } = Select;

const HospitalView = (props) => {
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);
    const [messageModal, setMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const [rating, setRating] = useState(0);
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    let { id } = useParams();
    const {
        marketingInfo,
        hospital,
        hospitalSingle,
        hospitalStatCounts,
        auth: { isAuthenticated, userType }
    } = props;

    const gethospital = async () => {
        await props.actions.getHospitalById({ ...props, hospitalId: id }, props.history);
    };

    const [tab, setTab] = useState('general');
    const tabs = ['doctors', 'general', 'blogs', 'following', 'reviews'];
    const handleTab = (string) => {
        if (tabs.includes(string)) {
            setTab(string);
            console.log('tab', selectedAddress);
            props.history.push(`/hospital-view/${id}?branchId=${selectedAddress}&tab=${string}`);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0);
        gethospital();
        getMarketingInfo();
        getHospitalStatCounts();
        let searchQuery = queryString.parse(props.location && props.location.search);
        if (searchQuery && !searchQuery.tab) {
            setTab('general');
        } else if (searchQuery && searchQuery.tab) {
            let tab = searchQuery && searchQuery.tab;
            setTab(tab);
        }
    }, [id]);

    const getHospitalStatCounts = async () => {
        let obj = {
            hospitalId: id
        };
        await props.actions.getHospitalStatCounts({ ...props, obj }, props.history);
    };

    const getMarketingInfo = async () => {
        let obj = {
            userId: id
        };
        await props.marketingActions.getMarketingInfo({ ...props, obj }, props.history);
    };

    useEffect(() => {
        if (hospitalSingle && hospitalSingle.branches && hospitalSingle.branches.length > 0) {
            if (props.location && props.location.search) {
                let search = queryString.parse(props.location.search);
                console.log(props.location.search);
                if (search.branchId) {
                    setSelectedAddress(search.branchId);
                    for (let i = 0; i < hospitalSingle.branches.length; i++) {
                        let branch = hospitalSingle.branches[i];
                        if (branch._id === search.branchId) {
                            setSelectedAddressIndex(i);
                            console.log('selected', i, branch._id, search.branchId);
                            break;
                        }
                    }
                } else {
                    setSelectedAddress(
                        hospitalSingle.branches[0] && hospitalSingle.branches[0]._id
                    );
                    setSelectedAddressIndex(0);
                }
            } else {
                setSelectedAddress(hospitalSingle.branches[0] && hospitalSingle.branches[0]._id);
                setSelectedAddressIndex(0);
            }
        }
    }, [hospitalSingle]);
    console.log('selectedAddress', selectedAddress);

    const handleCancel = () => {
        setModalOpen(false);
    };

    const addReview = async () => {
        let obj = {
            rating: rating,
            reviewer: props.auth.user._id,
            reviewerType: props.auth.user.userType,
            reviewedUser:
                props.hospital && hospitalSingle && hospitalSingle._id && hospitalSingle._id,
            reviewedUserType:
                props.hospital &&
                hospitalSingle &&
                hospitalSingle.userType &&
                hospitalSingle.userType,
            description: description,
            shortDescription: shortDescription
        };
        let postedReview = await props.reviewActions.postReview({ ...props, obj }, props.history);
        if (postedReview) {
            setModalOpen(false);
        }
    };

    const sendMessage = async () => {
        if (!message) {
            message.error('Message field cannot be empty');
        } else {
            let obj = {
                message: message,
                receiver:
                    props.hospital && hospitalSingle && hospitalSingle._id && hospitalSingle._id,
                receiverType:
                    props.hospital &&
                    hospitalSingle &&
                    hospitalSingle.userType &&
                    hospitalSingle.userType
            };
            let sendMessage = await props.messagingActions.createConversation(
                { ...props, obj },
                props.history
            );
            if (sendMessage) {
                setMessageModal(false);
                setMessage('');
            } else {
                console.log('error');
            }
        }
    };

    let topBarStyle = {};
    if (marketingInfo && marketingInfo.topBarContent) {
        if (marketingInfo.topBarContent.backgroundColor) {
            topBarStyle.backgroundColor = marketingInfo.topBarContent.backgroundColor;
        }
    }

    const sendMail = async (email, subject, body) => {
        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };

    const makeCall = async () => {
        window.open(
            `tel:${hospitalSingle && hospitalSingle.countryCode && hospitalSingle.countryCode}${
                hospitalSingle && hospitalSingle.phoneNo && hospitalSingle.phoneNo
            }`
        );
    };
    const goToSite = async () => {
        if (hospitalSingle && hospitalSingle.website) {
            window.open(
                `https://${hospitalSingle && hospitalSingle.website && hospitalSingle.website}`
            );
        }
    };

    const lines = ['title', 'header', 'grabber', 'line1', 'line2', 'line3', 'line4', 'line5'];

    const renderLines = (content) => {
        return lines.map((line) => {
            if (content[line]) {
                return (
                    <p
                        style={{
                            ...(content?.[line].style || {}),
                            padding: '10px 0px',
                            textAlign: 'center'
                        }}
                    >
                        {content?.[line]?.text}
                    </p>
                );
            }
        });
    };

    return (
        <div className='hospital-view-background'>
            {hospitalSingle && (
                <div className='hospital-view-main'>
                    <div className='hospital-view-header'>
                        <div className='hospital-view-header-container'>
                            <div className='hospital-view-name'>{hospitalSingle.hospitalName}</div>
                            <div className='hospital-view-heder-right'>
                                <div className='hospital-view-section'>
                                    <div
                                        className='hospital-view-sec'
                                        onClick={() => handleTab('blogs')}
                                    >
                                        <p>
                                            {hospitalStatCounts && hospitalStatCounts.posts
                                                ? hospitalStatCounts.posts
                                                : 0}{' '}
                                            Posts
                                        </p>
                                    </div>
                                    <div className='hospital-view-sec'>
                                        <p>
                                            {hospitalStatCounts && hospitalStatCounts.followers
                                                ? hospitalStatCounts.followers
                                                : 0}{' '}
                                            Followers
                                        </p>
                                    </div>
                                    <div className='hospital-view-sec'>
                                        <p>
                                            {hospitalStatCounts && hospitalStatCounts.likes
                                                ? hospitalStatCounts.likes
                                                : 0}{' '}
                                            Likes
                                        </p>
                                    </div>
                                    <div
                                        className='hospital-view-sec'
                                        onClick={() => handleTab('reviews')}
                                    >
                                        <p>
                                            {hospitalStatCounts && hospitalStatCounts.reviews
                                                ? hospitalStatCounts.reviews
                                                : 0}{' '}
                                            Reviews
                                        </p>
                                    </div>
                                </div>
                                <div className='hospital-view-social'>
                                    {hospitalSingle.shareSocial && (
                                        <>
                                            <i
                                                className={
                                                    hospitalSingle.social &&
                                                    hospitalSingle.shareSocial &&
                                                    hospitalSingle.social.instagram
                                                        ? `hospital-social-insta insta link fe fe-instagram`
                                                        : 'hospital-social-insta disabledIcon fe fe-instagram'
                                                }
                                                onClick={() => {
                                                    hospitalSingle.social &&
                                                        hospitalSingle.shareSocial &&
                                                        hospitalSingle.social.instagram &&
                                                        window.open(
                                                            `https://${
                                                                hospitalSingle.social &&
                                                                hospitalSingle.social.instagram &&
                                                                hospitalSingle.social.instagram
                                                            }`
                                                        );
                                                }}
                                            />

                                            <i
                                                className={
                                                    hospitalSingle.social &&
                                                    hospitalSingle.shareSocial &&
                                                    hospitalSingle.social.twitter
                                                        ? 'hospital-social-twit twit link fe fe-twitter'
                                                        : 'hospital-social-twit disabledIcon fe fe-twitter'
                                                }
                                                onClick={() => {
                                                    hospitalSingle.social &&
                                                        hospitalSingle.shareSocial &&
                                                        hospitalSingle.social.twitter &&
                                                        window.open(
                                                            `https://${
                                                                hospitalSingle.social &&
                                                                hospitalSingle.social.twitter &&
                                                                hospitalSingle.social.twitter
                                                            }`
                                                        );
                                                }}
                                            />
                                            {
                                                <i
                                                    className={
                                                        hospitalSingle.social &&
                                                        hospitalSingle.shareSocial &&
                                                        hospitalSingle.social.linkedin
                                                            ? 'hospital-social-linked linkedin link fe fe-linkedin'
                                                            : 'hospital-social-linked disabledIcon fe fe-linkedin'
                                                    }
                                                    onClick={() => {
                                                        hospitalSingle.social &&
                                                            hospitalSingle.shareSocial &&
                                                            hospitalSingle.social.linkedin &&
                                                            window.open(
                                                                `https://${
                                                                    hospitalSingle.social &&
                                                                    hospitalSingle.social
                                                                        .linkedin &&
                                                                    hospitalSingle.social.linkedin
                                                                }`
                                                            );
                                                    }}
                                                />
                                            }

                                            <i
                                                className={
                                                    hospitalSingle.social &&
                                                    hospitalSingle.shareSocial &&
                                                    hospitalSingle.social.facebook
                                                        ? 'hospital-social-fb fb link fe fe-facebook'
                                                        : 'hospital-social-fb disabledIcon fe fe-facebook'
                                                }
                                                onClick={() => {
                                                    hospitalSingle.social &&
                                                        hospitalSingle.shareSocial &&
                                                        hospitalSingle.social.facebook &&
                                                        window.open(
                                                            `https://${
                                                                hospitalSingle.social &&
                                                                hospitalSingle.social.facebook &&
                                                                hospitalSingle.social.facebook
                                                            }`
                                                        );
                                                }}
                                            />
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='hospital-upper'>
                        {marketingInfo && marketingInfo.displayTopBar ? (
                            <div className='doctor-view-cover-split'>
                                <div className='hospital-view-img-split'>
                                    {hospitalSingle.coverImage ? (
                                        <img
                                            src={
                                                hospitalSingle.coverImage
                                                    ? hospitalSingle.coverImage
                                                    : placeholderCover
                                            }
                                        />
                                    ) : (
                                        <div></div>
                                    )}
                                </div>
                                <div
                                    className='hospital-view-img-split2'
                                    style={
                                        (marketingInfo.topBarContentType != 'image' ||
                                            marketingInfo.topBarContentType != 'Image') &&
                                        marketingInfo.topBarContent.background && {
                                            backgroundColor: marketingInfo.topBarContent.background
                                        }
                                    }
                                >
                                    {marketingInfo.topBarContentType == 'image' ||
                                    marketingInfo.topBarContentType == 'Image' ? (
                                        <img
                                            src={
                                                marketingInfo.topBarContent &&
                                                marketingInfo.topBarContent.image
                                                    ? marketingInfo.topBarContent.image
                                                    : placeholderCover
                                            }
                                        />
                                    ) : (
                                        <div
                                            style={{
                                                ...topBarStyle,
                                                padding: 10,
                                                width: '100%',
                                                textAlign: 'center'
                                            }}
                                        >
                                            {renderLines(marketingInfo.topBarContent)}
                                        </div>
                                    )}
                                </div>
                            </div>
                        ) : (
                            <div className='hospital-view-img'>
                                <img
                                    src={
                                        hospitalSingle.coverImage
                                            ? hospitalSingle.coverImage
                                            : placeholderCover
                                    }
                                />
                            </div>
                        )}

                        <div className='hospital-profile-container'>
                            <div className='hospital-upper-left'>
                                <div className='hospital-image-container'>
                                    <div className='hospital-veiw-image'>
                                        <img
                                            src={
                                                hospitalSingle.profileImage
                                                    ? hospitalSingle.profileImage
                                                    : placeholderCover
                                            }
                                            alt='proPic'
                                            style={{
                                                height: '100%',
                                                width: '100%',
                                                objectFit: 'cover'
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <LowerSection
                        openMessageModal={() => setMessageModal(true)}
                        openReviewModal={() => setModalOpen(true)}
                        hospital={hospitalSingle}
                        index={selectedAddressIndex}
                        setSelectedAddress={setSelectedAddress}
                        selectedAddress={selectedAddress}
                        selectedAddressIndex={selectedAddressIndex}
                        setSelectedAddressIndex={setSelectedAddressIndex}
                        id={id}
                        tab={tab}
                        setTab={setTab}
                        {...props}
                    />

                    {isAuthenticated && userType == 'PATIENT' && (
                        <>
                            <MessageModal
                                messageModal={messageModal}
                                setMessageModal={setMessageModal}
                                message={message}
                                setMessage={setMessage}
                                sendMessage={sendMessage}
                            />

                            <ReviewModal
                                modalOpen={modalOpen}
                                handleCancel={handleCancel}
                                rating={rating}
                                setRating={setRating}
                                shortDescription={shortDescription}
                                setShortDescription={setShortDescription}
                                description={description}
                                setDescription={setDescription}
                                addReview={addReview}
                            />
                        </>
                    )}
                </div>
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state?.auth,
    profile: state?.auth?.userProfile,
    hospital: state?.hospital,
    hospitalSingle: state?.hospital?.hospitalSingle,
    doctors: state?.hospital?.hospitalSingleDoctors,
    doctorsCount: state?.hospital?.hospitalSingleDoctorsCount,
    hospitalStatCounts: state?.hospital.hospitalStatCounts,
    marketing: state?.marketing,
    marketingInfo: state?.marketing?.marketingInfo
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(hospitalActions, dispatch),
    reviewActions: bindActionCreators(reviewActions, dispatch),
    messagingActions: bindActionCreators(messagingActions, dispatch),
    marketingActions: bindActionCreators(marketingActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(HospitalView));
