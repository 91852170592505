import React, { useState } from 'react';
import './modal.css';
import ReactDom from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import Girl from '@/assets/img/girl2.jpg';
import { CloseOutlined } from '@ant-design/icons';
import { AiFillPlusCircle } from 'react-icons/ai';
import AppointmentDropdown from './AppointmentDropdown';

const AppointmentModal = ({ open, setOpen }) => {
    const [searchDropdown, setSearchDropdown] = useState(false);
    const [clientDropdown, setClienthDropdown] = useState(false);
    const [datetDropdown, setDateDropdown] = useState(false);
    const [values, setValues] = useState({
        service: '',
        client: '',
        date: ''
    });
    const appointmentVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { duration: 0.5 }
        },
        exit: {
            opacity: 0,
            transition: { ease: 'easeIn', duration: 0.5 }
        }
    };
    const newappVariants = {
        hidden: {
            x: '100vw',
            transition: { duration: 0.5 }
        },
        visible: {
            x: '0',
            transition: { duration: 0.5 }
        },
        exit: {
            x: '100vw',
            transition: { ease: 'easeIn', duration: 0.5 }
        }
    };
    const handleClose = () => {
        setClienthDropdown(false);
        setSearchDropdown(false);
        setDateDropdown(false);
    };
    return ReactDom.createPortal(
        <AnimatePresence exitBeforeEnter>
            {open && (
                <motion.div
                    key='main'
                    className='app-modal-main'
                    variants={appointmentVariants}
                    initial='hidden'
                    animate='visible'
                    exit='exit'
                    onClick={handleClose}
                >
                    <motion.div
                        className='app-modal-container'
                        key='container'
                        variants={newappVariants}
                        initial='hidden'
                        animate='visible'
                        exit='exit'
                    >
                        <div className='app-modal-header'>
                            <p>Add an Appointment</p>
                            <CloseOutlined onClick={() => setOpen(false)} />
                        </div>
                        <div className='app-modal-upper2'>
                            <div className='app-modal-img-container'>
                                <div className='app-modal-img'>
                                    <img src={Girl} alt='doctor' />
                                </div>
                                <div className='app-modal-name'>
                                    <p>Doctor Name</p>
                                    <p>Hospital Name</p>
                                    <p>doctor@gmail.com</p>
                                </div>
                            </div>
                            <div className='app-modal-note'>
                                <p>Add Note</p>
                            </div>
                        </div>
                        <div className='app-modal-body'>
                            <div className='app-modal-left'>
                                <div
                                    className={
                                        values.service
                                            ? 'app-modal-number high'
                                            : 'app-modal-number'
                                    }
                                >
                                    <p>1</p>
                                </div>
                                <div className='app-modal-line'></div>
                                <div
                                    className={
                                        values.client ? 'app-modal-number high' : 'app-modal-number'
                                    }
                                >
                                    <p>2</p>
                                </div>
                                <div className='app-modal-line'></div>
                                <div
                                    className={
                                        values.date ? 'app-modal-number high' : 'app-modal-number'
                                    }
                                >
                                    <p>3</p>
                                </div>
                            </div>
                            <div className='app-modal-right'>
                                <div className='app-modal-box'>
                                    <div className='app-modal-time-header'>
                                        <p>FIRST YOU NEED TO ADD SERVICE</p>
                                        {values.service && (
                                            <p
                                                style={{ cursor: 'pointer' }}
                                                onClick={() =>
                                                    setValues({ ...values, service: '' })
                                                }
                                            >
                                                Edit
                                            </p>
                                        )}
                                    </div>
                                    {values.service && (
                                        <div className='app-modal-time'>
                                            <p>Consultaion, Free</p>
                                            <p>client's approval reaquired</p>
                                        </div>
                                    )}
                                    {!values.service && (
                                        <div
                                            className='app-modal-search-container'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setSearchDropdown((item) => (item = true));
                                            }}
                                        >
                                            <AiFillPlusCircle
                                                color={'var(--primary)'}
                                                size='1.2rem'
                                            />
                                            <p>Add Service</p>
                                        </div>
                                    )}
                                    <AnimatePresence exitBeforeEnter>
                                        {searchDropdown && (
                                            <AppointmentDropdown
                                                key='search'
                                                setDropdown={setSearchDropdown}
                                                setValues={setValues}
                                                values={values}
                                                type={'service'}
                                                out={searchDropdown && searchDropdown}
                                            />
                                        )}
                                    </AnimatePresence>
                                </div>
                                <div className='app-modal-box'>
                                    <div className='app-modal-time-header'>
                                        <p>THEN YOU NEED TO ADD YOUR CLIENT</p>
                                        {values.client && (
                                            <p
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => setValues({ ...values, client: '' })}
                                            >
                                                Edit
                                            </p>
                                        )}
                                    </div>
                                    {values.client && (
                                        <div className='app-modal-client'>
                                            <div className='app-modal-img'>
                                                <img src={Girl} alt='doctor' />
                                            </div>
                                            <div className='app-modal-name2'>
                                                <div>
                                                    <p>Eliana De Cruz</p>
                                                </div>

                                                <p>
                                                    Hospital Name: &nbsp;&nbsp;&nbsp;{' '}
                                                    <span style={{ color: 'grey' }}>PRVD</span>
                                                </p>
                                                <p>
                                                    Email: &nbsp;&nbsp;&nbsp;
                                                    <span style={{ color: 'grey' }}>
                                                        doctor@gmail.com
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    )}
                                    {!values.client && (
                                        <div
                                            className='app-modal-search-container'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setClienthDropdown(true);
                                            }}
                                        >
                                            <AiFillPlusCircle
                                                color={'var(--primary)'}
                                                size='1.2rem'
                                            />
                                            <p>Add Client</p>
                                        </div>
                                    )}
                                    <AnimatePresence exitBeforeEnter>
                                        {clientDropdown && (
                                            <AppointmentDropdown
                                                key='client'
                                                setDropdown={setClienthDropdown}
                                                setValues={setValues}
                                                values={values}
                                                type={'client'}
                                                out={clientDropdown}
                                            />
                                        )}
                                    </AnimatePresence>
                                </div>
                                <div className='app-modal-box'>
                                    <div className='app-modal-time-header'>
                                        <p>TIME & DATE</p>
                                        {values.date && (
                                            <p
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => setValues({ ...values, date: '' })}
                                            >
                                                Edit
                                            </p>
                                        )}
                                    </div>
                                    {values.date && (
                                        <div className='app-modal-time'>
                                            <p>Tuesday, 24 October 14:00-15:00</p>
                                            <p>client's approval reaquired</p>
                                        </div>
                                    )}
                                    {!values.date && (
                                        <div
                                            className='app-modal-search-container'
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                setDateDropdown(true);
                                            }}
                                        >
                                            <AiFillPlusCircle
                                                color={'var(--primary)'}
                                                size='1.2rem'
                                            />
                                            <p>Add Date</p>
                                        </div>
                                    )}
                                    <AnimatePresence exitBeforeEnter>
                                        {datetDropdown && (
                                            <AppointmentDropdown
                                                key='date'
                                                setDropdown={setDateDropdown}
                                                setValues={setValues}
                                                values={values}
                                                type={'date'}
                                                out={datetDropdown}
                                            />
                                        )}
                                    </AnimatePresence>
                                </div>
                                <div className='app-modal-method'>
                                    <div className='app-modal-payment'>
                                        <p>Consultation Mehthod</p>
                                        <div className='app-modal-radio-grid'>
                                            <div className='app-modal-radio'>
                                                <input type='radio' />
                                                <p>Online</p>
                                            </div>
                                            <div className='app-modal-radio'>
                                                <input type='radio' />
                                                <p>In Person</p>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='app-modal-payment'>
                                        <p>Consultation Mehthod</p>
                                        <div className='app-modal-radio-grid'>
                                            <div className='app-modal-radio'>
                                                <input type='radio' />
                                                <p>Online</p>
                                            </div>
                                            <div className='app-modal-radio'>
                                                <input type='radio' />
                                                <p>In Person</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='app-modal-btns'>
                            <div className='app-modal-req'>
                                <p>Cancel</p>
                            </div>
                            <div className='app-modal-req'>
                                <p>Request</p>
                            </div>
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>,
        document.getElementById('modal-root')
    );
};

export default AppointmentModal;
