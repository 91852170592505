import React, { useState, useEffect } from 'react';
import DoctorCard from '@/components/Cards/Doctor';
import { Empty, Pagination } from 'antd';
import '../../../../HomePage/popularDoctor.css';

const HospitalLawyes = (props) => {
    const { doctors, doctorsCount, id } = props;
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    useEffect(() => {
        let obj = {
            hospitalSingleDoctors: true,
            hospitalId: id && id,
            page: page,
            perPage: limit
        };
        props.actions.getHospitalDoctors({ ...props, obj }, props.history);
    }, []);

    return (
        <div className='view-container'>
            <div className='Featured-doctors'>
                {doctors ? (
                    doctors.map((doctor, index) => {
                        return <DoctorCard key={index} doctor={doctor} {...props} />;
                    })
                ) : (
                    <Empty description='No Doctors listed' />
                )}
            </div>
            <Pagination
                style={{
                    margin: 20,
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                }}
                current={page}
                total={doctorsCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </div>
    );
};

export default HospitalLawyes;
