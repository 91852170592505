import React, { useState } from 'react';
import '@/assets/css/favourites.css';
import { Select, Popover, Col, Row, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;

const CredentialHeader = (props) => {
    const {
        type,
        history,
        credentialTypes,
        credentialType,
        setCredentialType,
        setAssociationModal,
        setHonorModal,
        setEducationModal,
        setAdmissionModal,
        setExperienceModal
    } = props;
    const [popover, setPopover] = useState(true);

    return (
        <Row gutter={[10, 10]} style={{ marginBottom: 20, justifyContent: 'flex-end' }}>
            <Col xs={24} lg={6}>
                <Popover
                    overlayClassName={'favouritePopover'}
                    placement='topRight'
                    content={
                        <div
                            style={{
                                width: 300,
                                color: 'white',
                                fontSize: 11,
                                alignItems: 'flex-start',
                                display: 'flex',
                                flexDirection: 'column',
                                padding: '6px'
                            }}
                        >
                            Click the dropdown to select between different Credentials:
                            Associations, Honors, Experiences, Educations and Admissions{' '}
                            <button
                                style={{
                                    borderRadius: 20,
                                    backgroundColor: 'var(--primary)',
                                    color: 'white',
                                    padding: '3px 15px',
                                    margin: '5px 10px 5px auto',
                                    cursor: 'pointer'
                                }}
                                onClick={() => setPopover(false)}
                            >
                                Got it
                            </button>
                        </div>
                    }
                    open={popover}
                >
                    <Select
                        placeholder='Sort By'
                        value={credentialType}
                        onChange={(val) => setCredentialType(val)}
                        showSearch
                        style={{ width: '100%' }}
                    >
                        {credentialTypes.map((item, index) => (
                            <Option key={index} value={item}>
                                {item}
                            </Option>
                        ))}
                    </Select>
                </Popover>
            </Col>
            <Col xs={24} lg={6}>
                <Button
                    type='primary'
                    style={{ width: '100%' }}
                    icon={<PlusOutlined />}
                    onClick={() => {
                        credentialType == 'Associations'
                            ? setAssociationModal(true)
                            : credentialType == 'Honors'
                            ? setHonorModal(true)
                            : credentialType == 'Educations'
                            ? setEducationModal(true)
                            : credentialType == 'Admissions'
                            ? setAdmissionModal(true)
                            : setExperienceModal(true);
                    }}
                >
                    New {credentialType.slice(0, credentialType.length - 1)}
                </Button>
            </Col>
        </Row>
    );
};

export default CredentialHeader;
