import React from 'react';
import { Modal } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { BsDot } from 'react-icons/bs';
import { AiOutlineCalendar } from 'react-icons/ai';
import { MdFormatListBulleted } from 'react-icons/md';
import { GrStatusInfo } from 'react-icons/gr';
import { Tooltip } from 'antd';
import { AiOutlineVideoCamera } from 'react-icons/ai';
import * as appointmentActions from '@/actions/appointmentActions';

function ViewCalendarEvent(props) {
    const { showEvent, setShowEvent, selectedEvent, setSelectedEvent, history, auth } = props;
    const dispatch = useDispatch();
    const { user, userType } = auth;
    const closeEventModal = async () => {
        setShowEvent(false);
        setSelectedEvent(null);
    };

    const checkVideCallEligibility = (item) => {
        let valid = true;

        let today = moment(new Date()).format('DD/MM/YYYY');
        let now = moment(moment(new Date()).format('HH:mm'), 'HH:mm');
        let startTime = moment(item.appointmentStartTime, 'HH:mm');
        let endTime = moment(item.appointmentEndTime, 'HH:mm');
        if (moment(item.appointmentDate).format('DD/MM/YYYY') != today) {
            valid = false;
        }
        if (now.isBetween(startTime, endTime) == false) {
            valid = false;
        }
        console.log(now, startTime, endTime, now.isBetween(startTime, endTime));
        if (!item.consultationMode == 'Online') {
            valid = false;
        }
        return valid;
        // return true
    };

    const goToVideoCall = async (item) => {
        let obj = {
            sessionId: item.videoSessionId
        };
        let callTokenApi = await dispatch(appointmentActions.getVideoToken({ ...props, obj }));
        if (callTokenApi) {
            history.push(`meeting/${item._id}/${item.videoSessionId}/${callTokenApi}`);
        }
    };
    return (
        (<Modal
            className='customModal'
            title='View Calendar Event'
            open={showEvent}
            onCancel={() => closeEventModal(false)}
            footer={null}
        >
            <div className='calendarEvent'>
                <div className='calRow'>
                    <div className='left'>
                        <AiOutlineCalendar />
                    </div>
                    <div className='right'>
                        <p>{selectedEvent?.title}</p>
                        <span>
                            {moment(selectedEvent?.start).format('dddd, MMMM D')} <BsDot />{' '}
                            {moment(selectedEvent?.start).format('HH:mm a')} {' - '}
                            {moment(selectedEvent?.end).format('HH:mm a')}
                        </span>
                    </div>
                </div>

                <div className='calRow'>
                    <div className='left'>
                        <MdFormatListBulleted />
                    </div>

                    <div className='right'>
                        {selectedEvent?.extendedProps?.description
                            ? selectedEvent?.extendedProps?.description
                            : 'No Description'}
                    </div>
                </div>
                {selectedEvent?.extendedProps?.consultationMode && (
                    <div className='calRow'>
                        <div className='left'>
                            {' '}
                            <GrStatusInfo />
                        </div>
                        <div className='right'>
                            {selectedEvent?.extendedProps?.consultationMode} Mode
                        </div>
                    </div>
                )}

                {selectedEvent?.extendedProps.appointmentStatus && (
                    <div className='calRow'>
                        <div className='left'>
                            {' '}
                            <GrStatusInfo />
                        </div>
                        <div className='right'>
                            <div>
                                <span
                                    className={`${selectedEvent?.extendedProps.appointmentStatus}  coloredBox`}
                                >
                                    {selectedEvent?.extendedProps.appointmentStatus
                                        .charAt(0)
                                        .toUpperCase() +
                                        selectedEvent?.extendedProps.appointmentStatus
                                            .slice(1)
                                            .toLowerCase()}
                                </span>{' '}
                                Status
                            </div>
                        </div>
                    </div>
                )}

                {userType != "HOSPITAL" &&
                    selectedEvent &&
                    selectedEvent?.extendedProps.appointmentStatus == 'ACCEPTED' &&
                    checkVideCallEligibility(selectedEvent) == true && (
                        <Tooltip title='Start Video Call'>
                            <button
                                className='app-search-btn'
                                onClick={() => goToVideoCall(selectedEvent)}
                            >
                                {' '}
                                Join meeting
                            </button>
                        </Tooltip>
                    )}
            </div>
        </Modal>)
    );
}

export default ViewCalendarEvent;
