export const MedicalConditions = [
    // Cardiovascular Diseases
    { value: 'coronary_artery_disease', label: 'Coronary Artery Disease', group: 'Cardiovascular Diseases' },
    { value: 'hypertension', label: 'Hypertension (High Blood Pressure)', group: 'Cardiovascular Diseases' },
    { value: 'heart_failure', label: 'Heart Failure', group: 'Cardiovascular Diseases' },
    { value: 'arrhythmias', label: 'Arrhythmias (Irregular Heartbeats)', group: 'Cardiovascular Diseases' },
    { value: 'stroke', label: 'Stroke', group: 'Cardiovascular Diseases' },
    // Respiratory Diseases
    { value: 'asthma', label: 'Asthma', group: 'Respiratory Diseases' },
    { value: 'copd', label: 'Chronic Obstructive Pulmonary Disease (COPD)', group: 'Respiratory Diseases' },
    { value: 'bronchitis', label: 'Bronchitis', group: 'Respiratory Diseases' },
    { value: 'pneumonia', label: 'Pneumonia', group: 'Respiratory Diseases' },
    { value: 'pulmonary_embolism', label: 'Pulmonary Embolism', group: 'Respiratory Diseases' },
    // Neurological Disorders
    { value: 'alzheimers_disease', label: "Alzheimer's disease", group: 'Neurological Disorders' },
    { value: 'parkinsons_disease', label: "Parkinson's disease", group: 'Neurological Disorders' },
    { value: 'multiple_sclerosis', label: 'Multiple sclerosis', group: 'Neurological Disorders' },
    { value: 'epilepsy', label: 'Epilepsy', group: 'Neurological Disorders' },
    { value: 'migraine_headaches', label: 'Migraine headaches', group: 'Neurological Disorders' },
    // Musculoskeletal Disorders
    { value: 'osteoarthritis', label: 'Osteoarthritis', group: 'Musculoskeletal Disorders' },
    { value: 'rheumatoid_arthritis', label: 'Rheumatoid arthritis', group: 'Musculoskeletal Disorders' },
    { value: 'osteoporosis', label: 'Osteoporosis', group: 'Musculoskeletal Disorders' },
    { value: 'fibromyalgia', label: 'Fibromyalgia', group: 'Musculoskeletal Disorders' },
    { value: 'scoliosis', label: 'Scoliosis', group: 'Musculoskeletal Disorders' },
    // Gastrointestinal Disorders
    { value: 'irritable_bowel_syndrome', label: 'Irritable bowel syndrome (IBS)', group: 'Gastrointestinal Disorders' },
    { value: 'crohns_disease', label: "Crohn's disease", group: 'Gastrointestinal Disorders' },
    { value: 'ulcerative_colitis', label: 'Ulcerative colitis', group: 'Gastrointestinal Disorders' },
    { value: 'gerd', label: 'Gastroesophageal reflux disease (GERD)', group: 'Gastrointestinal Disorders' },
    { value: 'peptic_ulcers', label: 'Peptic ulcers', group: 'Gastrointestinal Disorders' },
    // Endocrine Disorders
    { value: 'diabetes_mellitus_type_1', label: 'Diabetes mellitus (type 1)', group: 'Endocrine Disorders' },
    { value: 'diabetes_mellitus_type_2', label: 'Diabetes mellitus (type 2)', group: 'Endocrine Disorders' },
    { value: 'thyroid_disorders_hypothyroidism', label: 'Thyroid disorders (hypothyroidism)', group: 'Endocrine Disorders' },
    { value: 'thyroid_disorders_hyperthyroidism', label: 'Thyroid disorders (hyperthyroidism)', group: 'Endocrine Disorders' },
    { value: 'cushings_syndrome', label: "Cushing's syndrome", group: 'Endocrine Disorders' },
    { value: 'addisons_disease', label: "Addison's disease", group: 'Endocrine Disorders' },
    { value: 'polycystic_ovary_syndrome', label: 'Polycystic ovary syndrome (PCOS)', group: 'Endocrine Disorders' },
    // Immune System Disorders
    { value: 'rheumatoid_arthritis_immune', label: 'Rheumatoid arthritis', group: 'Immune System Disorders' },
    { value: 'lupus', label: 'Lupus (systemic lupus erythematosus)', group: 'Immune System Disorders' },
    { value: 'type_1_diabetes', label: 'Type 1 diabetes', group: 'Immune System Disorders' },
    { value: 'multiple_sclerosis_immune', label: 'Multiple sclerosis', group: 'Immune System Disorders' },
    { value: 'psoriasis', label: 'Psoriasis', group: 'Immune System Disorders' },
    // Infectious Diseases
    { value: 'influenza', label: 'Influenza', group: 'Infectious Diseases' },
    { value: 'tuberculosis', label: 'Tuberculosis', group: 'Infectious Diseases' },
    { value: 'hiv_aids', label: 'HIV/AIDS', group: 'Infectious Diseases' },
    { value: 'hepatitis_a', label: 'Hepatitis A', group: 'Infectious Diseases' },
    { value: 'hepatitis_b', label: 'Hepatitis B', group: 'Infectious Diseases' },
    { value: 'hepatitis_c', label: 'Hepatitis C', group: 'Infectious Diseases' },
    { value: 'malaria', label: 'Malaria', group: 'Infectious Diseases' },
    // Mental Health Disorders
    { value: 'depression', label: 'Depression', group: 'Mental Health Disorders' },
    { value: 'generalized_anxiety_disorder', label: 'Anxiety disorders (generalized anxiety disorder)', group: 'Mental Health Disorders' },
    { value: 'panic_disorder', label: 'Anxiety disorders (panic disorder)', group: 'Mental Health Disorders' },
    { value: 'bipolar_disorder', label: 'Bipolar disorder', group: 'Mental Health Disorders' },
    { value: 'schizophrenia', label: 'Schizophrenia', group: 'Mental Health Disorders' },
    { value: 'adhd', label: 'Attention-deficit/hyperactivity disorder (ADHD)', group: 'Mental Health Disorders' },
    // Cancer
    { value: 'breast_cancer', label: 'Breast cancer', group: 'Cancer' },
    { value: 'lung_cancer', label: 'Lung cancer', group: 'Cancer' },
    { value: 'colorectal_cancer', label: 'Colorectal cancer', group: 'Cancer' },
    { value: 'prostate_cancer', label: 'Prostate cancer', group: 'Cancer' },
    { value: 'skin_cancer_melanoma', label: 'Skin cancer (melanoma)', group: 'Cancer' },
    { value: 'skin_cancer_basal_cell', label: 'Skin cancer (basal cell carcinoma)', group: 'Cancer' },
    { value: 'skin_cancer_squamous_cell', label: 'Skin cancer (squamous cell carcinoma)', group: 'Cancer' },
    // Genetic Disorders
    { value: 'down_syndrome', label: 'Down syndrome', group: 'Genetic Disorders' },
    { value: 'cystic_fibrosis', label: 'Cystic fibrosis', group: 'Genetic Disorders' },
    { value: 'huntingtons_disease', label: "Huntington's disease", group: 'Genetic Disorders' },
    { value: 'sickle_cell_disease', label: 'Sickle cell disease', group: 'Genetic Disorders' },
    { value: 'hemophilia', label: 'Hemophilia', group: 'Genetic Disorders' },
    // Skin Conditions
    { value: 'eczema', label: 'Eczema', group: 'Skin Conditions' },
    { value: 'psoriasis_skin', label: 'Psoriasis', group: 'Skin Conditions' },
    { value: 'acne', label: 'Acne', group: 'Skin Conditions' },
    { value: 'rosacea', label: 'Rosacea', group: 'Skin Conditions' },
    { value: 'dermatitis', label: 'Dermatitis', group: 'Skin Conditions' },
  ];
  
  
export const groupedMedicalConditions = MedicalConditions.reduce((acc, option) => {
    if (!acc[option.group]) {
      acc[option.group] = [];
    }
    acc[option.group].push(option);
    return acc;
  }, {});