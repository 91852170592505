import {useState, useEffect} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '@/actions/userActions';

const useNotifications = (props) => {
    const dispatch = useDispatch();
    const auth = useSelector((state) => state.auth);
    const { notifications, notificationsCount } = auth;
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);

    useEffect(() => {
        let obj = {
            page: page,
            perPage: limit
        };
        dispatch(userActions.getAllNotifications({ ...props, obj }));
    }, [dispatch, page, limit]);

    // Add any additional logic or return values here

    return {
        notifications,
        notificationsCount,
        limit,
        setLimit,
        page,
        setPage
    };
};

export default useNotifications;
