import React, { useState, useEffect } from 'react';
import { Button, Input, Modal } from 'antd';

const RolesModal = (props) => {
    const { setIsModalOpen, isModalOpen, roleData, setRoleData, item } = props;
    const [name, setName] = useState('');
    const [id, setId] = useState(null);

    useEffect(() => {
        if (item) {
            setName(item.roleName);
            setId(item.id);
        }
    }, [item]);

    const submit = async () => {
        if (!name) return message.error('Please enter role name');
        if (!id) {
            let arr = [
                ...roleData,
                {
                    roleName: name,
                    id: roleData.length + 1
                }
            ].map((item) => item.roleName);

            let obj = {
                userId: props.auth.userId,
                specializedRoles: arr
            };
            let updateRole = await props.generalActions.updateSpecializedRoles(
                { ...props, obj },
                props.history
            );
            if (updateRole) {
                await props.generalActions.getSpecializedRoles({ ...props, obj });
                setIsModalOpen(false);
                setName('');
            }
        } else {
            let arr = [...roleData];
            arr[id - 1].roleName = name;

            arr = arr.map((item, index) => item.roleName);

            let obj = {
                userId: props.auth.userId,
                specializedRoles: arr
            };
            let updateRole = await props.generalActions.updateSpecializedRoles(
                { ...props, obj },
                props.history
            );
            if (updateRole) {
                await props.generalActions.getSpecializedRoles({ ...props, obj });
                setIsModalOpen(false);
            }
        }
    };
    return (
        <Modal
            className='customModal '
            title={item ? 'Edit Role' : 'Add Role'}
            open={isModalOpen}
            onOk={() => {
                setIsModalOpen(false);
                setName('');
            }}
            onCancel={() => {
                setIsModalOpen(false);
                setName('');
            }}
            footer={null}
        >
            <div className='post-modal-options-grid2'>
                <label>
                    <p>Role name:</p>
                    <Input
                        placeholder='Role Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
            </div>
            <Button type='primary' onClick={submit}>
                <p>{item ? 'Update' : 'Add'}</p>
            </Button>
        </Modal>
    );
};

export default RolesModal;
