// import jwtDecode from "jwt-decode";
import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';
import setAuthToken from '../utils/setAuthToken';

let { REACT_APP_API_URI } = process.env;

if (process.env.NODE_ENV === 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const clearErrors = () => (dispatch) => {
    dispatch({
        type: Types.CLEAR_ERRORS,
        payload: {
            error: {}
        }
    });
};

export const login = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/login`, props.user)
            .then((response) => {
                localStorage.setItem('auth_token', response.data.user.token);
                localStorage.setItem('refresh_token', response.data.user.refresh_token);

                setAuthToken(response.data.user.token);

                dispatch({
                    type: Types.SET_USER,
                    payload: {
                        user: response.data.user,
                        error: {}
                    }
                });

                const userTypeToPath = {
                    PATIENT: '/patient/profile',
                    HOSPITAL: response.data.user.isSignUpWizardCompleted
                        ? '/hospital/dashboard'
                        : '/hospital/profile',
                    DOCTOR: '/doctor/profile',
                    NURSE: '/nurse/profile',
                    CONSULTANT: '/consultant/profile',
                    PHARMACY: '/pharmacy/profile',
                    CAREGIVER: '/caregiver/profile',
                    THERAPIST: '/therapist/profile'
                };

                if (response.data.user && userTypeToPath[response.data.user.userType]) {
                    history.push(userTypeToPath[response.data.user.userType]);
                }

                message.success('Signed in successfully!');
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }

                // message.error(error.response.data.data[0].messages[0].message);
                reject(error?.response?.data?.user);
            });
    });
};

export const googleLogin = (data, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/googleLogin`, data)
            .then((response) => {
                localStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('refresh_token', response.data.refresh_token);

                dispatch({
                    type: Types.SET_USER,
                    payload: {
                        user: response.data.user
                    }
                });

                const userType = response.data.user?.userType;
                if (userType) {
                    history.push(`/${userType.toLowerCase()}/profile`);
                }
                message.success('Signed in successfully!');
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            // error: error.response.data.message[0].messages[0].message,
                        }
                    });
                }
                message.error('Google Sign in failed!');
                resolve(false);
            });
    });
};

export const facebookLogin = (data, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/facebookLogin`, data)
            .then((response) => {
                localStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('refresh_token', response.data.refresh_token);

                dispatch({
                    type: Types.SET_USER,
                    payload: {
                        user: response.data.user
                    }
                });
                const userType = response.data.user?.userType;
                if (userType) {
                    history.push(`/${userType.toLowerCase()}/profile`);
                }
                message.success('Signed in successfully!');
                resolve(true);
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    // dispatch({
                    //   type: Types.USER_ERRORS,
                    //   payload: {
                    //     error: error.response.data.message[0].messages[0].message,
                    //   },
                    // });
                }
                message.error('Facebook sign in failed!');
                resolve(false);
            });
    });
};

export const microsoftLogin = (data, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/microsoftLogin`, data)
            .then((response) => {
                localStorage.setItem('auth_token', response.data.token);
                localStorage.setItem('refresh_token', response.data.refresh_token);

                dispatch({
                    type: Types.SET_USER,
                    payload: {
                        user: response.data.user
                    }
                });

                const userType = response.data.user?.userType;
                if (userType) {
                    history.push(`/${userType.toLowerCase()}/profile`);
                }
                message.success('Signed in successfully!');
                resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            // error: error.response.data.message[0].messages[0].message,
                        }
                    });
                }
                message.error('Microsoft Sign in failed!');
                resolve(false);
            });
    });
};

export const register = (user, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/register`, user)
            .then((response) => {
                dispatch({
                    type: Types.CREATE_USER,
                    payload: {
                        createdUser: response.data.user,
                        error: null
                    }
                });

                // history.push("/");
                message.success('Signed up successfully!');
                resolve(true);
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                    if (error.response.data && error.response.data.message && user.home) {
                        message.error(error.response.data.message);
                    }
                }
                console.log(error, error.response);
                // message.error(error.response.data.data[0].messages[0].message);
                resolve(false);
            });
    });
};
export const verifyEmail = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/verifyEmail`, props.obj)
            .then((response) => {
                message.success('Email Verified!');
                resolve(true);
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response);
                }
                resolve(false);
            });
    });
};
export const logout = (history) => (dispatch) => {
    localStorage.removeItem('auth_token');
    localStorage.removeItem('refresh_token');

    message.success('Successfully logged out!');
    history.push('/');
    return dispatch({
        type: Types.LOGOUT,
        payload: {
            user: {}
        }
    });
};

export const forgotPassword = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/forgot-password`, props.obj)
            .then((res) => {
                message.success('Email sent to your mail');
                // history.push("/");
                dispatch({ type: Types.FORGOT_PASSWORD });
                return resolve(true);
            })
            .catch((error) => {
                console.log(error);
                if (error && error.response) {
                    console.log(error.response.data.message);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                        dispatch({
                            type: Types.USER_ERRORS,
                            error: error.response.data.message
                        });
                    }
                    return resolve(false);
                }
            });
    });
};

export const sendOtp = (data, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/sendOtp`, data)
            .then((res) => {
                if (res) {
                    message.success('Otp sent to your phone number.');
                    dispatch({
                        type: Types.SEND_OTP,
                        payload: {
                            message: 'message sent',
                            data: data
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response);
                    message.error(error.response.data.message);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: {
                            error: error.response.data
                        }
                    });
                }
                // message.error(error.response.data.data[0].messages[0].message);
                resolve(false);
            });
    });
};

export const verifyOtp = (data, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/verifyOtp`, data)
            .then((res) => {
                if (res.data.success == true) {
                    message.success('OTP verified.');
                    dispatch({
                        type: Types.VERIFY_OTP,
                        payload: {
                            message: 'OTP verified.',
                            data: data
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                console.log(error);
                message.error('OTP could not be verified.');
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.USER_ERRORS,
                        payload: { error: error.response.data }
                    });
                    return resolve(false);
                }
            });
    });
};

export const loginWithPhone = (data, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/loginWithPhone`, data)
            .then((res) => {
                if (res.data.token) {
                    localStorage.setItem('auth_token', res.data.token);
                    localStorage.setItem('refresh_token', res.data.refresh_token);

                    dispatch({
                        type: Types.SET_USER,
                        payload: {
                            user: res.data.user
                        }
                    });
                    message.success('Logged in successfully!');

                    if (res.data.user && res.data.user.userType == 'PATIENT') {
                        history.push('/patient/profile');
                    } else if (res.data.user && res.data.user.userType == 'HOSPITAL') {
                        history.push('/hospital/profile');
                    } else if (res.data.user && res.data.user.userType == 'DOCTOR') {
                        history.push('/doctor/profile');
                    }

                    return resolve(true);
                }
            })
            .catch((error) => {
                // console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    // dispatch({ type: Types.USER_ERRORS,error:"" });
                    message.error('Sign in with phone failed');
                    return resolve(false);
                }
                // message.success("Logged in successfully!");
                // return resolve(false)
            });
    });
};

export const saveProfile = (data, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/saveProfile`, data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data) {
                    dispatch({
                        type: Types.SAVE_PROFILE,
                        payload: {
                            savedProfile: res.data
                        }
                    });
                    message.success('Changes Saved!');
                    // history.push('/profilePage')
                    return resolve(true);
                }
            })
            .catch((error) => {
                // console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // dispatch({ type: Types.USER_ERRORS,error:"" });
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
                // message.success("Logged in successfully!");
                // return resolve(false)
            });
    });
};
export const resetPassword = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/reset-password`, props.obj)
            .then((res) => {
                if (res.data.success) {
                    message.success('Password Reset Successful');
                    dispatch({
                        type: Types.RESET_PASSWORD,
                        data: 'Reset Password Successful'
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                // console.log(error);
                if (error && error.response) {
                    console.log(error.response.data.message);
                    message.error(error.response.data.message);
                    // dispatch({ type: Types.USER_ERRORS,error:"" });
                    return resolve(false);
                }
                // message.success("Logged in successfully!");
                // return resolve(false)
            });
    });
};

export const getProfile = (data, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('auth_token');
        if (token) {
            Axios.post(`${REACT_APP_API_URI}/api/auth/getProfile`, data, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
                .then((res) => {
                    if (res) {
                        dispatch({
                            type: Types.GET_PROFILE,
                            payload: {
                                retrievedProfile: res.data.user
                            }
                        });
                        // history.push('/profilePage')
                        return resolve(true);
                    }
                })
                .catch(async (error) => {
                    // console.log(error);
                    await dispatch(logout(history));
                    if (error && error.response) {
                        console.log(error.response.data);
                        // dispatch({ type: Types.USER_ERRORS,error:"" });
                        // message.error(error.response.data.message[0].messages[0].message);

                        return resolve(false);
                    }
                    // message.success("Logged in successfully!");
                    // return resolve(false)
                });
        }
    });
};

export const getPackages = (history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/auth/packages`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_PACKAGES,
                        payload: {
                            packages: res.data
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const saveHospitalSignUpWizard = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/saveHospitalSignUpWizard`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const saveDoctorSignUpWizard = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/saveDoctorSignUpWizard`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const saveNurseSignUpWizard = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/saveNurseSignUpWizard`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const saveConsultantSignUpWizard = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/saveConsultantSignUpWizard`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const saveTherapistSignUpWizard = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/saveTherapistSignUpWizard`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const saveCaregiverSignUpWizard = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/saveCaregiverSignUpWizard`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const savePharmacySignUpWizard = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/savePharmacySignUpWizard`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const saveBranches = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/saveBranches`, props, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success(res.data.message);
                    return resolve(res.data);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                        // message.error(error.response.data.message[0].messages[0].message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const saveProfileImageOrCoverImage = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/saveProfileImageOrCoverImage`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const deleteProfileImage = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/deleteProfileImage`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const deleteCoverImage = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/deleteCoverImage`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getAllNotifications = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/getAllNotifications`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_NOTIFICATIONS,
                        payload: {
                            notifications: res.data.notifications,
                            notificationsCount: res.data.count
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.GET_NOTIFICATIONS,
                        payload: {
                            notifications: [],
                            notificationsCount: 0
                        }
                    });
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getFavourites = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/favourites/getFavourites`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                // if (res.data.success) {
                //   dispatch({
                //     type: Types.GET_FAVOURITES,
                //     payload: {
                //       favourites: res.data.favourites
                //     }
                //   });
                //   return resolve(true);
                // }

                if (res.data.success) {
                    let payload = {};
                    if (props.obj.favouriteType == 'Hospitals') {
                        payload = {
                            hospitals: res.data.hospitals,
                            lawfirmsCount: res.data.lawfirmsCount,
                            favouriteType: 'Hospitals'
                        };
                    }
                    if (props.obj.favouriteType == 'Doctors') {
                        payload = {
                            doctors: res.data.doctors,
                            doctorsCount: res.data.doctorsCount,
                            favouriteType: 'Doctors'
                        };
                    }
                    if (props.obj.favouriteType == 'Consultants') {
                        payload = {
                            consultants: res.data.consultants,
                            consultantsCount: res.data.consultantsCount,
                            favouriteType: 'Consultants'
                        };
                    }
                    if (props.obj.favouriteType == 'Nurses') {
                        payload = {
                            nurses: res.data.nurses,
                            nursesCount: res.data.nursesCount,
                            favouriteType: 'Nurses'
                        };
                    }
                    if (props.obj.favouriteType == 'Pharmacies') {
                        payload = {
                            pharmacies: res.data.pharmacies,
                            pharmaciesCount: res.data.pharmaciesCount,
                            favouriteType: 'Pharmacies'
                        };
                    }
                    if (props.obj.favouriteType == 'Blogs') {
                        payload = {
                            blogs: res.data.blogs,
                            blogsCount: res.data.blogsCount,
                            favouriteType: 'Blogs'
                        };
                    }
                    dispatch({
                        type: Types.GET_FAVOURITES,
                        payload: {
                            ...payload
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getUserViewFavourites = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/favourites/getUserViewFavourites`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    let payload = {};
                    if (props.obj.favouriteType == 'Hospitals') {
                        payload = {
                            hospitals: res.data.hospitals,
                            lawfirmsCount: res.data.lawfirmsCount,
                            favouriteType: 'Hospitals'
                        };
                    }
                    if (props.obj.favouriteType == 'Doctors') {
                        payload = {
                            doctors: res.data.doctors,
                            doctorsCount: res.data.doctorsCount,
                            favouriteType: 'Doctors'
                        };
                    }
                    if (props.obj.favouriteType == 'Blogs') {
                        payload = {
                            blogs: res.data.blogs,
                            blogsCount: res.data.blogsCount,
                            favouriteType: 'Blogs'
                        };
                    }
                    dispatch({
                        type: Types.GET_USER_VIEW_FAVOURITES,
                        payload: {
                            ...payload
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const addToFavourites = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/favourites/addToFavourites`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.ADD_TO_FAVOURITES,
                        payload: {
                            addedToFavourites: res.data.addedToFavourites
                        }
                    });
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

// /removeFromFavourites/:favouriteId

export const removeFromFavourites = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.delete(
            `${REACT_APP_API_URI}/api/favourites/removeFromFavourites/${props.obj.favouriteId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.auth_token
                }
            }
        )
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const changePassword = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/change-password`, props.obj)
            .then((res) => {
                if (res.data.success) {
                    message.success('Changed Password Successful');
                    dispatch({
                        type: Types.CHANGE_PASSWORD,
                        payload: {
                            message: 'Changed Password Successful'
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                // console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    // dispatch({ type: Types.USER_ERRORS,error:"" });
                    message.error(error.response.data.message);
                    return resolve(false);
                }
                // message.success("Logged in successfully!");
                // return resolve(false)
            });
    });
};

export const subscribeToNewsletter = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/subscribeToNewsletter`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const uploadDocuments = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/documents/saveCredentialDocument`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const deactivateAccount = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/deactivateAccount`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then(async (res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getClientProfile = (props) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/getClientProfile`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.VIEW_CLIENT_PROFILE,
                        payload: {
                            patientProfile: res.data.user
                        }
                    });
                    // history.push('/profilePage')
                    return resolve(true);
                }
            })
            .catch((error) => {
                // console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    // dispatch({ type: Types.USER_ERRORS,error:"" });
                    message.error(error.response.data.message);
                    return resolve(false);
                }
            });
    });
};

export const checkIfDiffUserWithPhoneNoExists = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/checkIfDiffUserWithPhoneNoExists`, props.user, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    // history.push('/profilePage')
                    return resolve(true);
                }
            })
            .catch((error) => {
                // console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    // dispatch({ type: Types.USER_ERRORS,error:"" });
                    message.error(error.response.data.message);
                    return resolve(false);
                }
            });
    });
};

export const changePhoneNumber = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/changePhoneNumber`, props.user, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    // history.push('/profilePage')
                    return resolve(true);
                }
            })
            .catch((error) => {
                // console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    // dispatch({ type: Types.USER_ERRORS,error:"" });
                    message.error(error.response.data.message);
                    return resolve(false);
                }
            });
    });
};

export const resetEmailRequest = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/resetEmailRequest`, props.user, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    // history.push('/profilePage')
                    return resolve(true);
                }
            })
            .catch((error) => {
                // console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    // dispatch({ type: Types.USER_ERRORS,error:"" });
                    message.error(error.response.data.message);
                    return resolve(false);
                }
            });
    });
};

export const resetEmail = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/resetEmail`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    // history.push('/profilePage')
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                // console.log(error);
                if (error && error.response) {
                    console.log(error.response.data);
                    // dispatch({ type: Types.USER_ERRORS,error:"" });
                    message.error(error.response.data.message);
                    return resolve(false);
                }
            });
    });
};

export const chooseFromUsers = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/auth/chooseFromUsers/${props.searchEmail}`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.CHOOSE_FROM_USERS,
                        payload: {
                            searchedUsers: res.data.users
                        }
                    });

                    // message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.CHOOSE_FROM_USERS,
                        payload: {
                            searchedUsers: []
                        }
                    });
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const addGoogleTokenToUser = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/addGoogleTokenToUser`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getGoogleAuthorizationUrl = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/auth/getGoogleAuthorizationUrl`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    window.open(res.data.authUrl);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getGoogleCalendarEvents = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/getGoogleCalendarEvents`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_GOOGLE_CALENDAR_EVENTS,
                        payload: {
                            googleEvents: res.data.events
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.GET_GOOGLE_CALENDAR_EVENTS,
                        payload: {
                            googleEvents: []
                        }
                    });
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const unsyncGoogleCalendar = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/unsyncGoogleCalendar`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const addOutlookTokenToUser = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/addOutlookTokenToUser`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const getOutlookAuthorizationUrl = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/auth/getOutlookAuthorizationUrl`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    window.open(res.data.authUrl);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const unsyncOutlookCalendar = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/unsyncOutlookCalendar`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getOutlookCalendarEvents = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/getOutlookCalendarEvents`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_OUTLOOK_CALENDAR_EVENTS,
                        payload: {
                            outlookEvents: res.data.events
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.GET_OUTLOOK_CALENDAR_EVENTS,
                        payload: {
                            outlookEvents: []
                        }
                    });
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getCalendarEvents = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/getCalendarEvents`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_CALENDAR_EVENTS,
                        payload: {
                            calendarEvents: res.data.events
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    dispatch({
                        type: Types.GET_CALENDAR_EVENTS,
                        payload: {
                            calendarEvents: []
                        }
                    });
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getProductTypes = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/auth/getProductTypes`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_PRODUCT_TYPES,
                        payload: {
                            productTypes: res.data.productTypes
                        }
                    });

                    // message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getStripeInvoices = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/auth/getInvoices`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_STRIPE_INVOICES,
                        payload: {
                            stripeInvoices: res.data.invoices.data,
                            stripeUpcomingInvoice: res.data.upcomingInvoice
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const cancelBusinessSubscription = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.delete(
            `${REACT_APP_API_URI}/api/auth/cancelBusinessStripeSubscription/${props.subscriptionId}`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.auth_token
                }
            }
        )
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.CANCEL_BUSINESS_SUBSCRIPTION,
                        payload: {}
                    });

                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const changeStripePaymentMethod = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/changeStripePaymentMethod`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.CHANGE_STRIPE_PAYMENT_METHOD,
                        payload: {}
                    });

                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const changeSubscription = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/changeSubscription`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.CHANGE_STRIPE_SUBSCRIPTION,
                        payload: {}
                    });

                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const resendVerificationEmail = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/resendVerificationEmail`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.SET_EMAIL_VERIFIED_MSG,
                        payload: {
                            emailVerifiedMsg: `A verification email has been sent to the email address ${props.obj.email}, please check your email and click on the verification link to verify your email address before you can sign in.`
                        }
                    });

                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};
export const getEmailOrPhoneOtp = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/getEmailOrPhoneOtp`, props,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data);
                if (res.data.success) {
                    message.success(res.data.message);

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const verifyEmailOrPhoneOtp = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/auth/verifyEmailOrPhoneOtp`, props,{
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                console.log(res.data, res.data.success)
                if (res.data.success) {
                    message.success(res.data.message);
                    // dispatch({
                    //     type: Types.VERIFY_EMAIL_OR_PHONE_OTP
                    // });
                    console.log('getting in')
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return reject(error.response.data);
                }
            });
    });
};