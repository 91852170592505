import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';

const CurrentTime = () => {
    const [currentTime, setCurrentTime] = useState(DateTime.local());

    useEffect(() => {
        let timeNow;
        const timer = () => {
            timeNow = setTimeout(() => {
                setCurrentTime(DateTime.local());
                timer();
            }, 1000);
        };
        timer();
        return () => {
            clearTimeout(timeNow);
        };
    }, []);

    return (
        <>
            <span style={{ display: 'block', minWidth: 260, fontWeight: 400 }}>
                Server Time: {currentTime.toFormat('ccc MMMM yyyy, HH:mm:ss')}
            </span>
        </>
    );
}

export default CurrentTime;
