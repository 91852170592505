import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Alert, Input, Menu, message, Tooltip } from 'antd';
import { Select, Switch, Modal } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import moment from 'moment';
import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import * as userActions from '@/actions/userActions';
import languageOptions from '@/assets/json/Languages.json';
import Countries from '@/assets/json/Countries.json';
import ChangePhoneOrEmail from '../../modals/ChangePhoneOrEmail';
import { geocodeByAddress } from 'react-places-autocomplete';
import malePlaceholder from '@/assets/img/malePlaceholder.png';
import femalePlaceholder from '@/assets/img/femalePlaceholder.jpg';
import ConfirmModal from '../../modals/ConfirmModal';
import Geolocate from './../../MiniComponents/Geolocate';
import {
    CoverImage,
    ProfileImage,
    NavPrompt,
    Input as InputComponent
} from '../Shared/ProfileComponents';
import ChangePassword from '../../modals/ChangePassword';
import { SocialSection, AccountSettings } from '../Shared/ProfileSections';
import { AccountHolder } from './ProfileSections';
import ProfileCalendar from '../DoctorProfile/ProfileCalendar';
import Notifications from '../DoctorProfile/Dashboard/Notifications';
import ProfileSection from '../DoctorProfile/ProfileSection';
import { groupedMedicalConditions } from '@/assets/json/MedicalConditions';
import { AllergiesOptions } from '@/assets/json/Allergies';
import { BloodTypesOptions } from '@/assets/json/BloodTypes';

const { Option, OptGroup } = Select;
const genderOptions = [
    { key: 'male', text: 'Male', value: 'Male' },
    { key: 'female', text: 'Female', value: 'Female' },
    { key: 'other', text: 'Other', value: 'Other' }
];

export class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formDirty: false,
            fixedFirstName: '',
            fixedLastName: '',
            firstName: { value: '', isValid: true, message: '' },
            lastName: { value: '', isValid: true, message: '' },
            phoneNo: { value: '', isValid: true, message: '' },
            countryCode: { value: '', isValid: true, message: '' },
            dob: { stringValue: '', value: '', isValid: true, message: '' },
            email: { value: '', isValid: true, message: '' },
            password: { value: '', isValid: true, message: '' },
            social: {
                facebook: '',
                instagram: '',
                twitter: '',
                linkedin: ''
            },
            gender: { value: '', isValid: true, message: '' },
            language: { value: '', isValid: true, message: '' },
            currency: { value: '', isValid: true, message: '' },
            dateOfBirth: { value: '', isValid: true, message: '' },
            toolTip: '',
            allergens: [],
            dietaryPref: [],
            profileSummary: { value: '', isValid: true, message: '' },
            countryDrop: false,
            filteredCountryCode: Countries,
            profCompDiv: true,
            coverImage: null,
            tempCoverImage: null,
            profileImage: null,
            tempProfileImage: null,
            disablePhone: true,
            disableEmail: true,
            disablePass: true,
            chatInputs: [],
            chat: false,
            chatoptions: false,
            // outlook: false,
            // gmail: false,
            stripe: false,
            allowChat: false,
            contactPhone: { value: '', isValid: true, message: '' },
            contactEmail: { value: '', isValid: true, message: '' },
            contactCountryCode: { value: '', isValid: true, message: '' },
            billingAddress: { value: '', isValid: true, message: '' },
            billingCity: { value: '', isValid: true, message: '' },
            billingState: { value: '', isValid: true, message: '' },
            billingZip: { value: '', isValid: true, message: '' },
            billingCountry: { value: '', isValid: true, message: '' },
            billingCoordinates: {
                value: { lat: '', lng: '' },
                isValid: true,
                message: ''
            },
            medicalInformation: {
                height: 0,
                weight: 0,
                medicalConditions: [],
                allergies: [],
                surgeriesOrProcedures: '',
                smokingHabits: '',
                drinkingHabits: '',
                exerciseHabits: ''
            },
            dba: false,
            profileCompletion: {},
            locality: localStorage.getItem('locality') ? localStorage.getItem('locality') : '',
            localityCountry: localStorage.getItem('localityCountry')
                ? localStorage.getItem('localityCountry')
                : '',
            formImageDirty: false,
            passwordModal: false,
            showConfirmPassword: false,
            showCurrentPassword: false,
            showNewPassword: false,
            genderPlaceholderImg: malePlaceholder,
            deactivateAccountConfirmModal: false,
            deactivateAccountFlag: false,
            syncGoogleCalendar: false,
            syncOutlookCalendar: false
        };
        this.curr = React.createRef();
        this.saveProfile = this.saveProfile.bind(this);
        this.onChange = this.onChange.bind(this);
        this.updateBillingAddress = this.updateBillingAddress.bind(this);
        this.handleEnable = this.handleEnable.bind(this);
        this.handleDisable = this.handleDisable.bind(this);
        this.saveCoverImage = this.saveCoverImage.bind(this);
        this.clearCoverImage = this.clearCoverImage.bind(this);
        this.deleteCoverImage = this.deleteCoverImage.bind(this);
        this.deleteProfileImage = this.deleteProfileImage.bind(this);
        this.saveProfileImage = this.saveProfileImage.bind(this);
        this.profileImageMenu = this.profileImageMenu.bind(this);
        this.handleAddInput = this.handleAddInput.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRemoveInput = this.handleRemoveInput.bind(this);
        this.handleSelectionCanadaPost = this.handleSelectionCanadaPost.bind(this);
        this.deactivateAccount = this.deactivateAccount.bind(this);
        this.syncGoogleCalendar = this.syncGoogleCalendar.bind(this);
        this.syncOutlookCalendar = this.syncOutlookCalendar.bind(this);
        this.closePasswordModal = this.closePasswordModal.bind(this);

        this.handleAddMedicalCondtion = this.handleAddMedicalCondtion.bind(this);
        this.deleteMedicalCondition = this.deleteMedicalCondition.bind(this);
        this.handleAddAllergy = this.handleAddAllergy.bind(this);
        this.deleteAllergy = this.deleteAllergy.bind(this);
    }

    handleInputChange = (e, index) => {
        let state = this.state;
        const values = [...state.chatInputs];
        values[index][e.target.name] = e.target.value;
        state['chatInputs'] = values;

        this.setState(state);
    };

    deactivateAccount = async () => {
        if (this.state.deactivateAccountFlag) {
            let obj = {
                userId: this.props.auth.userId,
                userType: this.props.auth.userType
            };
            let deactivate = await this.props.actions.deactivateAccount(
                { ...this.props, obj },
                this.props.history
            );
            if (deactivate) {
                this.setState({ deactivateAccountConfirmModal: false });
                setTimeout(async () => {
                    let logout = await this.props.actions.logout(this.props.history);
                }, [2000]);
            }
        } else {
            this.setState({ deactivateAccountConfirmModal: true });
        }
    };

    handleAddInput = () => {
        let state = this.state;
        if (state.chatInputs.length < 3) {
            const values = [...state.chatInputs, { short: '', message: '' }];
            state['chatInputs'] = values;
        }
        this.setState(state);
    };
    handleRemoveInput = async (index) => {
        let state = this.state;

        const values = [...state.chatInputs];
        values.splice(index, 1);
        state['chatInputs'] = values;

        this.setState(state);
    };

    profileImageMenu(props) {
        return (
            <Menu>
                {this.state.tempProfileImage && (
                    <Menu.Item onClick={() => this.saveProfileImage()}>
                        <a>Save Image</a>
                    </Menu.Item>
                )}
                {this.state.profileImage && !this.state.formImageDirty && (
                    <Menu.Item
                        onClick={() => {
                            this.setState({ deleteProfileImage: true });
                        }}
                    >
                        <a>Delete Image</a>
                    </Menu.Item>
                )}
            </Menu>
        );
    }

    static getDerivedStateFromProps(props, state) {
        // if (props.profile && props.profile.token) {
        //   localStorage.setItem("auth_token", props.profile.token);
        // }

        const { profile } = props;
        if (state.formDirty == false) {
            if (props.auth.isAuthenticated && profile && props.auth.user) {
                if (profile.firstName) {
                    state.firstName.value = profile.firstName;
                    state.fixedFirstName = profile.firstName;
                }
                if (profile.lastName) {
                    state.lastName.value = profile.lastName;
                    state.fixedLastName = profile.lastName;
                }
                if (profile.dob) {
                    state.dob.value = profile.dob;
                }
                if (props.auth.user.phoneNo) {
                    state.phoneNo.value = props.auth.user.phoneNo;
                }
                if (profile.countryCode) {
                    state.countryCode.value = profile.countryCode;
                }
                if (props.auth.user.email) {
                    state.email.value = props.auth.user.email;
                }
                if (profile.currency) {
                    state.currency.value = profile.currency;
                }
                if (profile.dateOfBirth) {
                    state.dateOfBirth.value = profile.dateOfBirth;
                }
                if (profile.medicalInformation) {
                    const { medicalConditions, allergies, ...rest } = profile.medicalInformation;
                    if (medicalConditions.length > 0) {
                        state.medicalInformation.medicalConditions = medicalConditions.map(
                            (condition) => ({
                                ...condition,
                                error: ''
                            })
                        );
                    }
                    if (allergies.length > 0) {
                        state.medicalInformation.allergies = allergies.map((allergy) => ({
                            ...allergy,
                            error: ''
                        }));
                    }
                    state.medicalInformation = {
                        ...state.medicalInformation,
                        ...rest
                    };
                }
                if (profile.gender) {
                    state.gender.value = profile.gender;
                    if (profile.gender == 'Male') {
                        state.genderPlaceholderImg = malePlaceholder;
                    } else {
                        state.genderPlaceholderImg = femalePlaceholder;
                    }
                }
                if (profile.profileCompletion) {
                    state.profileCompletion = profile.profileCompletion;
                }
                if (profile.social) {
                    state.social.facebook = profile.social?.facebook || '';
                    state.social.instagram = profile.social?.instagram || '';
                    state.social.twitter = profile.social?.twitter || '';
                    state.social.linkedin = profile.social?.linkedin || '';
                }
                if (profile.allowChat) {
                    state.allowChat = profile.allowChat;
                }
                if (profile.contact) {
                    if (profile.contact.billing) {
                        state.billingAddress.value = profile.contact.billing.billingAddress
                            ? profile.contact.billing.billingAddress
                            : '';
                        state.billingCity.value = profile.contact.billing.billingCity
                            ? profile.contact.billing.billingCity
                            : '';
                        state.billingZip.value = profile.contact.billing.billingZip
                            ? profile.contact.billing.billingZip
                            : '';
                        state.billingState.value = profile.contact.billing.billingState
                            ? profile.contact.billing.billingState
                            : '';

                        state.billingCountry.value = profile.contact.billing.billingCountry
                            ? profile.contact.billing.billingCountry
                            : '';

                        state.billingCoordinates.value = profile.contact.billing.billingCoordinates
                            ? profile.contact.billing.billingCoordinates
                            : '';
                    }
                }

                if (profile.language) {
                    state.language.value = profile.language;
                }
                state.syncGoogleCalendar = profile.syncGoogleCalendar ? true : false;
                state.syncOutlookCalendar = profile.syncOutlookCalendar ? true : false;

                if (!state.formImageDirty) {
                    if (profile.coverImage) {
                        state.coverImage = profile.coverImage;
                    }
                }

                if (profile.profileImage) {
                    state.profileImage = profile.profileImage;
                }
                if (profile.coverImage) {
                    state.coverImage = profile.coverImage;
                }
                if (!state.formDPDirty) {
                    if (profile.profileImage) {
                        state.profileImage = profile.profileImage;
                    }
                }

                if (localStorage.getItem('currency') && !profile.currency) {
                    state.currency.value = localStorage.getItem('currency');
                }

                if (profile.profileSummary) {
                    state.profileSummary.value = profile.profileSummary;
                }
            }
        }

        return state;
    }

    closePasswordModal() {
        this.setState({
            passwordModal: false
        });
    }

    componentDidMount() {
        let profileDate = {
            userId: this.props.auth.user._id,
            userType: this.props.auth.user.userType
        };
        // this.props.actions.getProfile(profileDate, this.props.history);
    }
    saveCoverImage = async () => {
        console.log(this.state.coverImage);

        if (this.state.coverImage && this.state.tempCoverImage) {
            let obj = new FormData();
            obj.append('userId', this.props.auth.user._id);
            obj.append('image', this.state.coverImage);
            obj.append('profileImage', false);
            obj.append('coverImage', true);

            let upload = await this.props.actions.saveProfileImageOrCoverImage(
                { obj, ...this.props },
                this.props.history
            );

            if (upload) {
                this.setState({ tempCoverImage: null });
                let profileShort = {
                    userId: this.props.auth.user._id,
                    userType: this.props.auth.user.userType
                };
                await this.props.actions.getProfile(profileShort, this.props);
                this.setState({
                    formImageDirty: false
                });
            }
        }
    };
    async clearCoverImage() {
        this.setState({
            tempCoverImage: null,
            coverImage: null,
            formImageDirty: false
        });
    }
    async deleteCoverImage() {
        let obj = {
            userId: this.props?.auth?.user?._id,
            coverImage: ''
        };
        let deleteImage = await this.props.actions.deleteCoverImage(
            { ...this.props, obj },
            this.props.history
        );
        if (deleteImage) {
            let profileShort = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            await this.props.actions.getProfile(profileShort, this.props);
            this.setState({
                deleteCover: false,
                formImageDirty: false,
                coverImage: '',
                tempCoverImage: ''
            });
        }
    }

    async deleteProfileImage() {
        let obj = {
            userId: this.props.auth && this.props.auth.user && this.props.auth.user._id,
            coverImage: ''
        };
        let deleteImage = await this.props.actions.deleteProfileImage(
            { ...this.props, obj },
            this.props.history
        );
        if (deleteImage) {
            let profileShort = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            this.setState({
                deleteProfileImage: false,
                formImageDirty: false
            });
            await this.props.actions.getProfile(profileShort, this.props);
            this.setState({
                formImageDirty: false,
                profileImage: '',
                tempProfileImage: '',
                formDPDirty: false
            });
        }
    }

    saveProfileImage = async () => {
        console.log(this.state.profileImage);
        if (this.state.profileImage) {
            let obj = new FormData();
            obj.append('userId', this.props.auth.user._id);
            obj.append('image', this.state.profileImage);
            obj.append('profileImage', true);
            obj.append('coverImage', false);

            let upload = await this.props.actions.saveProfileImageOrCoverImage(
                { obj, ...this.props },
                this.props.history
            );

            if (upload) {
                this.setState({ tempProfileImage: null });
                let profileShort = {
                    userId: this.props.auth.user._id,
                    userType: this.props.auth.user.userType
                };
                await this.props.actions.getProfile(profileShort, this.props);
                this.setState({
                    formDPDirty: true
                });
            }
            // message.success("image uploaded");
        }
    };

    onChange(e, string) {
        var state = this.state;
        if (e && e.target && e.target.name) {
            state[e.target.name].value = e.target.value;
        }
        state.formDirty = true;
        if (!string) {
            if (e.target.name == 'firstName') {
                if (e.target.value == '') {
                    state['firstName'].isValid = false;
                    state['firstName'].message = 'First name cannot be left empty';
                } else {
                    state['firstName'].isValid = true;
                    state['firstName'].message = '';
                }
            }

            if (e.target.name == 'lastName') {
                if (e.target.value == '') {
                    state['lastName'].isValid = false;
                    state['lastName'].message = 'First name cannot be left empty';
                } else {
                    state['lastName'].isValid = true;
                    state['lastName'].message = '';
                }
            }
            if (e.target.name == 'billingCity') {
                if (e.target.value == '') {
                    state['billingCity'].isValid = false;
                    state['billingCity'].message = 'Billing City cannot be left empty';
                } else {
                    state['billingCity'].isValid = true;
                    state['billingCity'].message = '';
                }
            }
            if (e.target.name == 'billingCountry') {
                if (e.target.value == '') {
                    state['billingCountry'].isValid = false;
                    state['billingCountry'].message = 'Billing country cannot be left empty';
                } else {
                    state['billingCountry'].isValid = true;
                    state['billingCountry'].message = '';
                }
            }
            if (e.target.name == 'billingZip') {
                if (e.target.value == '') {
                    state['billingZip'].isValid = false;
                    state['billingZip'].message = 'Billing Zip cannot be left empty';
                } else {
                    state['billingZip'].isValid = true;
                    state['billingZip'].message = '';
                }
            }
            if (e.target.name == 'billingAddress') {
                if (e.target.value == '') {
                    state['billingAddress'].isValid = false;
                    state['billingAddress'].message = 'Billing Address cannot be left empty';
                } else {
                    state['billingAddress'].isValid = true;
                    state['billingAddress'].message = '';
                }
            }
            if (e.target.name == 'billingState') {
                if (e.target.value == '') {
                    state['billingState'].isValid = false;
                    state['billingState'].message = 'Billing State cannot be left empty';
                } else {
                    state['billingState'].isValid = true;
                    state['billingState'].message = '';
                }
            }
        } else {
            state[string] = e;

            state['chatoptions'] = state.chat;
        }

        this.setState(state);
    }

    onChangeDate(date, dateString) {
        console.log(date, dateString);
        this.setState({
            formDirty: true,
            dob: { value: dateString, isValid: true, message: '' }
        });
    }

    handleEnable(e) {
        let state = { ...this.state };
        if (e === 'phoneNo') {
            state.disablePhone = false;
        }
        if (e === 'email') {
            state.disableEmail = false;
        }
        if (e === 'password') {
            state.disablePass = false;
        }
        this.setState(state);
    }
    handleDisable() {
        let state = this.state;
        state.disablePhone = true;
        state.disableEmail = true;
        state.disablePass = true;
        this.setState(state);
    }
    saveProfile = async (e) => {
        let state = this.state;
        let data = null;
        let valid = true;

        if (!state.firstName.value) {
            state.firstName.isValid = false;
            state.firstName.message = 'Field is required';
        }
        if (!state.lastName.value) {
            state.lastName.isValid = false;
            state.lastName.message = 'Field is required';
        }
        if (!state.dob.value) {
            state.dob.isValid = false;
            state.dob.message = 'Field is required';
        }
        if (!state.billingAddress.value) {
            state.billingAddress.isValid = false;
            state.billingAddress.message = 'Field is required';
        }
        if (!state.billingCity.value) {
            state.billingCity.isValid = false;
            state.billingCity.message = 'Field is required';
        }
        if (!state.billingCountry.value) {
            state.billingCountry.isValid = false;
            state.billingCountry.message = 'Field is required';
        }
        if (!state.billingState.value) {
            state.billingState.isValid = false;
            state.billingState.message = 'Field is required';
        }
        if (!state.billingZip.value) {
            state.billingZip.isValid = false;
            state.billingZip.message = 'Field is required';
        }

        if (!state.language.value) {
            state.language.isValid = false;
            state.language.message = 'Field is required';
        }
        if (!state.gender.value) {
            state.gender.isValid = false;
            state.gender.message = 'Field is required';
        }

        if (!state.currency.value) {
            state.currency.isValid = false;
            state.currency.message = 'Field is required';
        }

        // check if medicalConditions have both conditionType and conditionDescription if not add the error message
        for (let i = 0; i < state.medicalInformation.medicalConditions.length; i++) {
            const condition = state.medicalInformation.medicalConditions[i];
            if (condition.conditionType === '' || condition.conditionDescription === '') {
                condition.error = 'Both fields are required';
                valid = false;
            } else {
                condition.error = '';
            }
        }

        // check if allergies have both allergyType and allergyDescription if not add the error message
        for (let i = 0; i < state.medicalInformation.allergies.length; i++) {
            const allergy = state.medicalInformation.allergies[i];
            if (allergy.allergyType === '' || allergy.allergyDescription === '') {
                allergy.error = 'Both fields are required';
                valid = false;
            } else {
                allergy.error = '';
            }
        }

        this.setState(state);

        if (!valid) {
            message.error('Fill up required fields!');
            return;
        }

        if (
            state.firstName.isValid &&
            state.lastName.isValid &&
            state.firstName.value !== '' &&
            state.lastName.value !== '' &&
            state.currency.value !== '' &&
            state.dob.value != '' &&
            state.gender.value != '' &&
            state.language.value != '' &&
            state.billingAddress.value != '' &&
            state.billingCity.value != '' &&
            state.billingCountry.value != '' &&
            state.billingState.value != '' &&
            state.billingZip.value != ''
        ) {
            let profileObj = {
                data: {
                    firstName: state.firstName.value,
                    lastName: state.lastName.value,
                    gender: state.gender.value,
                    dob: state.dob.value,
                    profileSummary: state.profileSummary.value,
                    contact: {
                        email: state.contactEmail.value,
                        phoneNo: state.contactPhone.value,
                        countryCode: state.contactCountryCode.value.replace('+', ''),
                        billing: {
                            billingAddress: state.billingAddress.value,
                            billingCity: state.billingCity.value,
                            billingState: state.billingState.value,
                            billingZip: state.billingZip.value,
                            billingCountry: state.billingCountry.value,
                            billingCoordinates: state.billingCoordinates.value
                        }
                    },
                    medicalInformation: {
                        medicalConditions: state.medicalInformation.medicalConditions.map(
                            (condition) => ({
                                conditionType: condition.conditionType,
                                conditionDescription: condition.conditionDescription
                            })
                        ),
                        allergies: state.medicalInformation.allergies.map((allergy) => ({
                            allergyType: allergy.allergyType,
                            allergyDescription: allergy.allergyDescription
                        })),
                        surgeriesOrProcedures: state.medicalInformation.surgeriesOrProcedures,
                        smokingHabits: state.medicalInformation.smokingHabits,
                        drinkingHabits: state.medicalInformation.drinkingHabits,
                        exerciseHabits: state.medicalInformation.exerciseHabits,
                        height: state.medicalInformation.height,
                        weight: state.medicalInformation.weight,
                        bloodType: state.medicalInformation.bloodType
                    },
                    social: {
                        ...state.social
                    },
                    allowChat: this.state.allowChat,
                    shareSocial: this.state.doNotShareSocial ? false : true,
                    shareFavourites: this.state.doNotShareFavourites ? false : true,
                    privateProfile: this.state.privateProfile,
                    language: this.state.language.value,
                    currency: this.state.currency.value
                },
                userType: this.props.auth.user.userType,
                userId: this.props.auth.user._id
            };
            console.log(profileObj);

            if (!valid == false) {
                await this.props.actions.saveProfile(profileObj, this.props.history);

                let profileShort = {
                    userId: this.props.auth.user._id,
                    userType: this.props.auth.user.userType
                };
                await this.props.actions.getProfile(profileShort, this.props.history);
                this.setState({ formDirty: false });
            } else {
                message.error('Fill up required fields!');
            }
        } else {
            message.error('Fill up required fields!');
        }
    };

    handleSelectionCanadaPost(item) {
        console.log('item', item);
        let state = this.state;
        state.billingAddress.value = item.Line1 ? item.Line1 : '';
        state.billingAddress.isValid = '';
        state.billingAddress.message = '';
        state.billingCity.value = item.City ? item.City : '';
        state.billingCity.isValid = '';
        state.billingCity.message = '';
        state.billingCountry.value = item.CountryName ? item.CountryName : '';
        state.billingCountry.isValid = '';
        state.billingCountry.message = '';
        state.billingState.value = item.Province ? item.Province : '';
        state.billingState.isValid = '';
        state.billingState.message = '';
        state.billingZip.value = item.PostalCode ? item.PostalCode : '';
        state.billingZip.isValid = '';
        state.billingZip.message = '';
        state.billingCoordinates.value = item.coordinates ? item.coordinates : { lat: '', lng: '' };

        state.billingCoordinates.isValid = '';
        state.billingCoordinates.message = '';
        state.formDirty = true;

        this.setState(state, () => console.log(this.state));
    }

    async updateBillingAddress(addr) {
        console.log(addr);
        this.setState({ formDirty: true });
        var placeSearch, autocomplete;
        var componentForm = {
            street_number: '',
            route: '',
            postal_town: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            formattedAddress: '',
            neighbourhood: ''
        };
        let address = await geocodeByAddress(addr);
        if (address) console.log(address);
        address = address[0];
        componentForm.formattedAddress = address.formatted_address;
        for (var i = 0; i < address.address_components.length; i++) {
            var addressType = address.address_components[i].types[0];
            console.log(addressType);
            if (componentForm[addressType] == '') {
                let val = address.address_components[i][componentForm[addressType]];
                componentForm[addressType] = address.address_components[i].long_name;
            }
        }
        let state = this.state;

        if (componentForm.country !== '')
            state.billingCountry = {
                value: componentForm.country,
                isValid: true,
                message: ''
            };
        // debugger
        if (componentForm.postal_code !== '')
            state.billingZip = {
                value: componentForm.postal_code,
                isValid: true,
                message: ''
            };
        state.billingCity = {
            value:
                componentForm.postal_town != 'Town' && componentForm.postal_town
                    ? componentForm.postal_town
                    : componentForm.locality != 'locality'
                    ? componentForm.locality
                    : '',
            isValid: true,
            message: ''
        };
        state.billingState = {
            value:
                componentForm.administrative_area_level_1 != 'State '
                    ? componentForm.administrative_area_level_1
                    : '',
            isValid: true,
            message: ''
        };

        if (componentForm.street_number && componentForm.route) {
            state.billingAddress = {
                value: componentForm.street_number + ' ' + componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.route) {
            state.billingAddress = {
                value: componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.formattedAddress) {
            state.billingAddress = {
                value: componentForm.formattedAddress,
                isValid: true,
                message: ''
            };
        }

        if (address.geometry && address.geometry.location) {
            state.billingCoordinates.value = {
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng()
            };
            console.log(state.billingCoordinates);
        }

        this.setState(state);
    }

    async syncGoogleCalendar(checked) {
        console.log(checked);
        if (checked) {
            let getAuthUrl = await this.props.actions.getGoogleAuthorizationUrl(
                {},
                this.props.history
            );
        } else {
            let obj = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            let unsync = await this.props.actions.unsyncGoogleCalendar(
                { ...this.props, obj },
                this.props.history
            );
            if (unsync) {
                this.props.actions.getProfile(obj, this.props.history);
            }
        }
    }

    async syncOutlookCalendar(checked) {
        console.log(checked);
        if (checked) {
            let getAuthUrl = await this.props.actions.getOutlookAuthorizationUrl(
                {},
                this.props.history
            );
        } else {
            let obj = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            let unsync = await this.props.actions.unsyncOutlookCalendar(
                { ...this.props, obj },
                this.props.history
            );
            if (unsync) {
                this.props.actions.getProfile(obj, this.props.history);
            }
        }
    }

    handleAddMedicalCondtion = () => {
        let state = this.state;
        state.formDirty = true;
        state.medicalInformation.medicalConditions.push({
            condtionType: '',
            conditionDescription: ''
        });
        this.setState(state);
    };

    deleteMedicalCondition = (index) => {
        let state = this.state;
        state.formDirty = true;
        state.medicalInformation.medicalConditions.splice(index, 1);
        this.setState(state);
    };

    handleAddAllergy = () => {
        let state = this.state;
        state.formDirty = true;
        state.medicalInformation.allergies.push({ allergyType: '', allergyDescription: '' });
        this.setState(state);
    };

    deleteAllergy = (index) => {
        let state = this.state;
        state.formDirty = true;
        state.medicalInformation.allergies.splice(index, 1);
        this.setState(state);
    };
    render() {
        const dateFormat = 'YYYY/MM/DD';
        const { profile } = this.props;
        const state = this.state;

        const locationFields = [
            { field: 'billingAddress', label: 'Billing Address' },
            { field: 'billingCountry', label: 'Billing Country' },
            { field: 'billingCity', label: 'Billing City' },
            { field: 'billingState', label: 'Billing State' },
            { field: 'billingZip', label: 'Billing Zip' }
        ];
        return (
            <div className='user-profile'>
                <CoverImage
                    savedCoverImage={profile.coverImage}
                    tempCoverImage={this.state.tempCoverImage}
                    coverImage={this.state.coverImage}
                    formImageDirty={this.state.formImageDirty}
                    setState={(obj) => {
                        this.setState(obj);
                    }}
                    clearCoverImage={this.clearCoverImage}
                    deleteCoverImage={this.deleteCoverImage}
                    saveCoverImage={this.saveCoverImage}
                />
                <ProfileImage
                    savedProfileImage={profile.profileImage}
                    tempProfileImage={this.state.tempProfileImage}
                    profileImage={this.state.profileImage}
                    genderPlaceholderImg={this.state.genderPlaceholderImg}
                    formDPDirty={this.state.formDPDirty}
                    setState={(obj) => {
                        this.setState(obj);
                    }}
                    saveProfileImage={this.saveProfileImage}
                    deleteProfileImage={this.deleteProfileImage}
                />
                {this.props.auth && this.props.auth.profileError && (
                    <Alert
                        style={{ marginTop: 40, padding: '10px 24px' }}
                        type='error'
                        description={this.props.auth.profileError}
                    />
                )}

                <div className='viewPublicProfile'>
                    <button
                        className=''
                        onClick={() =>
                            this.props.history.push(`/view-profile/${this.props.auth.user._id}`)
                        }
                    >
                        View Public Profile
                    </button>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <AccountHolder
                            firstName={this.state.firstName}
                            lastName={this.state.lastName}
                            gender={this.state.gender}
                            profileSummary={this.state.profileSummary}
                            language={this.state.language}
                            currency={this.state.currency}
                            filteredCurrencies={this.state.filteredCurrencies}
                            billingAddress={this.state.billingAddress}
                            billingCountry={this.state.billingCountry}
                            billingCity={this.state.billingCity}
                            billingState={this.state.billingState}
                            billingZip={this.state.billingZip}
                            moment={moment}
                            dateFormat={dateFormat}
                            onChangeDate={this.onChangeDate.bind(this)}
                            languageOptions={languageOptions}
                            genderOptions={genderOptions}
                            onChange={this.onChange}
                            setState={(obj) => this.setState(obj)}
                            state={this.state}
                            updateBillingAddress={this.updateBillingAddress}
                        />

                        <div className='dividerL'></div>

                        <ProfileSection title={'Contact Information'}>
                            <div className='input-row'>
                                <label>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <div>Billing Location:</div>
                                        <Tooltip
                                            placement='topLeft'
                                            title={`You can use the search function to locate and choose your address`}
                                        >
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    height: 14,
                                                    width: 14,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 8,
                                                    fontSize: 11,
                                                    backgroundColor: 'lavender'
                                                }}
                                            >
                                                i
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <Geolocate
                                        icon={<i className='fa fa-map-marker' />}
                                        chooseAddress={(address) =>
                                            this.updateBillingAddress(address)
                                        }
                                    />
                                </label>
                            </div>
                            <div className='input-row two-part'>
                                {locationFields?.slice(0, 2).map((item, index) => (
                                    <InputComponent
                                        key={`${index}-${item?.field}`}
                                        className={
                                            !this.state[item.field].isValid
                                                ? 'error one-half'
                                                : 'one-half'
                                        }
                                        label={item.label}
                                        required
                                        item={this.state[item.field]}
                                        name={item.field}
                                        placeholder={item.label}
                                        type='text'
                                        value={this.state[item.field]?.value}
                                        onChange={this.onChange}
                                    />
                                ))}
                            </div>

                            <div className='input-row three-part'>
                                {locationFields?.slice(2, 5).map((item, index) => (
                                    <InputComponent
                                        key={`${index}-${item?.field}`}
                                        className={
                                            !this.state[item?.field].isValid
                                                ? 'error one-half'
                                                : 'one-third'
                                        }
                                        required
                                        label={item?.label}
                                        item={this.state[item?.field]}
                                        name={item?.field}
                                        placeholder={item?.label}
                                        type='text'
                                        value={this.state[item?.field]?.value}
                                        onChange={this.onChange}
                                    />
                                ))}
                            </div>
                        </ProfileSection>
                        <div className='dividerL'></div>
                        <ProfileSection title='Medical Information'>
                            <div className='input-row two-part'>
                                <label>
                                    Height
                                    <Input
                                        suffix='cm'
                                        name={'height'}
                                        placeholder={'Height'}
                                        type='text'
                                        value={state.medicalInformation.height}
                                        onChange={(e) => {
                                            let state = this.state;
                                            state.formDirty = true;
                                            state.medicalInformation.height = e.target.value;
                                            this.setState(state);
                                        }}
                                    />
                                </label>
                                <label>
                                    Weight
                                    <Input
                                        suffix='kg'
                                        name={'weight'}
                                        placeholder={'Weight'}
                                        type='text'
                                        value={state.medicalInformation.weight}
                                        onChange={(e) => {
                                            let state = this.state;
                                            state.formDirty = true;
                                            state.medicalInformation.weight = e.target.value;
                                            this.setState(state);
                                        }}
                                    />
                                </label>
                            </div>
                            <div className='input-row two-part'>
                                <label>
                                    Blood Type
                                    <Select
                                        value={state.medicalInformation.bloodType}
                                        placeholder='Blood Type'
                                        style={{ width: '100%' }}
                                        onChange={(value) => {
                                            let state = this.state;
                                            state.formDirty = true;
                                            state.medicalInformation.bloodType = value;
                                            this.setState(state);
                                        }}
                                    >
                                        {BloodTypesOptions.map((option) => (
                                            <Option key={option.value} value={option.value}>
                                                {option.label}
                                            </Option>
                                        ))}
                                    </Select>
                                </label>
                                <label>
                                    Exercise Habits
                                    <Input
                                        value={state.medicalInformation.exerciseHabits}
                                        placeholder='Exercise Habits'
                                        style={{ width: '100%' }}
                                        onChange={(e) => {
                                            let state = this.state;
                                            state.formDirty = true;
                                            state.medicalInformation.exerciseHabits =
                                                e.target.value;
                                            this.setState(state);
                                        }}
                                    />
                                </label>
                            </div>

                            <div
                                style={{
                                    marginBottom: 20,
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 10
                                }}
                            >
                                Allergies
                                {state.medicalInformation.allergies.map((allergy, index) => (
                                    <div
                                        style={{
                                            display: 'flex',
                                            gap: 20,
                                            paddingTop: 10,
                                            marginBottom: 10,
                                            position: 'relative'
                                        }}
                                        key={index}
                                    >
                                        <Select
                                            status={
                                                allergy.error && !allergy.allergyType ? 'error' : ''
                                            }
                                            placeholder='Allergies'
                                            style={{ width: '100%', maxWidth: 250 }}
                                            value={allergy.allergyType || ''}
                                            onChange={(value) => {
                                                let state = this.state;
                                                state.formDirty = true;
                                                state.medicalInformation.allergies[
                                                    index
                                                ].allergyType = value;
                                                if (value == '') {
                                                    state.medicalInformation.allergies[
                                                        index
                                                    ].error = 'Both fields are required';
                                                    state.medicalInformation.allergies[
                                                        index
                                                    ].allergyDescription = ''
                                                } else if (
                                                    state.medicalInformation.allergies[index]
                                                        .allergyDescription !== ''
                                                ) {
                                                    state.medicalInformation.allergies[
                                                        index
                                                    ].error = '';
                                                }
                                                this.setState(state);
                                            }}
                                        >
                                            <Option value=''>Select Allergies</Option>
                                            {AllergiesOptions.map((option) => (
                                                <Option key={option.value} value={option.value}>
                                                    {option.label}
                                                </Option>
                                            ))}
                                        </Select>
                                        <Input
                                            disabled={!allergy.allergyType}
                                            status={
                                                allergy.error && !allergy.allergyDescription
                                                    ? 'error'
                                                    : ''
                                            }
                                            placeholder='Description'
                                            value={allergy.allergyDescription}
                                            onChange={(e) => {
                                                let state = this.state;
                                                state.formDirty = true;
                                                state.medicalInformation.allergies[
                                                    index
                                                ].allergyDescription = e.target.value;

                                                if (e.target.value == '') {
                                                    state.medicalInformation.allergies[
                                                        index
                                                    ].error = 'Both fields are required';
                                                   
                                                } else if (
                                                    state.medicalInformation.allergies[index]
                                                        .allergyType !== ''
                                                ) {
                                                    state.medicalInformation.allergies[
                                                        index
                                                    ].error = '';
                                                }
                                                this.setState(state);
                                            }}
                                        />
                                        <DeleteOutlined
                                            style={{ color: 'red' }}
                                            onClick={() => this.deleteAllergy(index)}
                                        />
                                        {allergy.error && (
                                            <p
                                                style={{
                                                    color: '#f14c48',
                                                    fontSize: 11,
                                                    position: 'absolute',
                                                    bottom: -20,
                                                    left: 0
                                                }}
                                            >
                                                {' '}
                                                <i className='fe fe-alert-triangle' />{' '}
                                                {allergy.error}
                                            </p>
                                        )}
                                    </div>
                                ))}
                                <div
                                    onClick={this.handleAddAllergy}
                                    style={{
                                        display: 'flex',
                                        gap: 20,
                                        marginTop: 10,
                                        marginBottom: 10,
                                        cursor: 'pointer'
                                    }}
                                >
                                    <AiOutlinePlus />
                                    <p>Add Allergy</p>
                                </div>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 10
                                }}
                            >
                                Medical Conditions
                                {state.medicalInformation.medicalConditions.map(
                                    (medicalCondition, index) => (
                                        <div
                                            style={{
                                                display: 'flex',
                                                gap: 20,
                                                paddingTop: 10,
                                                marginBottom: 10,
                                                position: 'relative'
                                            }}
                                            key={index}
                                        >
                                            <Select
                                                status={
                                                    medicalCondition.error &&
                                                    !medicalCondition.conditionType
                                                        ? 'error'
                                                        : ''
                                                }
                                                placeholder='Medical Conditions'
                                                style={{ width: '100%', maxWidth: 250 }}
                                                value={medicalCondition.conditionType || ''}
                                                onChange={(value) => {
                                                    let state = this.state;
                                                    state.formDirty = true;
                                                    state.medicalInformation.medicalConditions[
                                                        index
                                                    ].conditionType = value;

                                                    if (value == '') {
                                                        state.medicalInformation.medicalConditions[
                                                            index
                                                        ].error = 'Both fields are required';
                                                        state.medicalInformation.medicalConditions[index].conditionDescription = ''
                                                    } else if (
                                                        state.medicalInformation.medicalConditions[
                                                            index
                                                        ].conditionDescription !== ''
                                                    ) {
                                                        state.medicalInformation.medicalConditions[
                                                            index
                                                        ].error = '';
                                                    }
                                                    this.setState(state);
                                                }}
                                            >
                                                <Option value=''>Select Medical Conditions</Option>
                                                {Object.entries(groupedMedicalConditions).map(
                                                    ([group, options]) => (
                                                        <OptGroup key={group} label={group}>
                                                            {options.map((option) => (
                                                                <Option
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.label}
                                                                </Option>
                                                            ))}
                                                        </OptGroup>
                                                    )
                                                )}
                                            </Select>
                                            <Input
                                                disabled={!medicalCondition.conditionType}
                                                status={
                                                    medicalCondition.error &&
                                                    !medicalCondition.conditionDescription
                                                        ? 'error'
                                                        : ''
                                                }
                                                placeholder='Description'
                                                value={medicalCondition.conditionDescription}
                                                onChange={(e) => {
                                                    let state = this.state;
                                                    state.formDirty = true;
                                                    state.medicalInformation.medicalConditions[
                                                        index
                                                    ].conditionDescription = e.target.value;
                                                    if (e.target.value == '') {
                                                        state.medicalInformation.medicalConditions[
                                                            index
                                                        ].error = 'Both fields are required';
                                                    } else if (
                                                        state.medicalInformation.medicalConditions[
                                                            index
                                                        ].conditionType !== ''
                                                    ) {
                                                        state.medicalInformation.medicalConditions[
                                                            index
                                                        ].error = '';
                                                    }
                                                    this.setState(state);
                                                }}
                                            />
                                            <DeleteOutlined
                                                style={{ color: 'red' }}
                                                onClick={() => this.deleteMedicalCondition(index)}
                                            />
                                            {medicalCondition.error && (
                                                <p
                                                    style={{
                                                        color: '#f14c48',
                                                        fontSize: 11,
                                                        position: 'absolute',
                                                        bottom: -20,
                                                        left: 0
                                                    }}
                                                >
                                                    {' '}
                                                    <i className='fe fe-alert-triangle' />{' '}
                                                    {medicalCondition.error}
                                                </p>
                                            )}
                                        </div>
                                    )
                                )}
                                <div
                                    onClick={this.handleAddMedicalCondtion}
                                    style={{
                                        display: 'flex',
                                        gap: 20,
                                        cursor: 'pointer',
                                        marginTop: 10,
                                        marginBottom: 10
                                    }}
                                >
                                    <AiOutlinePlus />
                                    <p>Add Medical Condition</p>
                                </div>
                            </div>

                            <div className='input-row two-part'>
                                <label>
                                    Alcohol Consumption
                                    <Input
                                        name={'drinkingHabits'}
                                        placeholder={'Alcohol Consumption'}
                                        type='text'
                                        value={state.medicalInformation.drinkingHabits}
                                        onChange={(e) => {
                                            let state = this.state;
                                            state.formDirty = true;
                                            state.medicalInformation.drinkingHabits =
                                                e.target.value;
                                            this.setState(state);
                                        }}
                                    />
                                </label>

                                <label>
                                    Smoking Habits
                                    <Input
                                        name={'smokingHabits'}
                                        placeholder={'Smoking Habits'}
                                        type='text'
                                        value={state.medicalInformation.smokingHabits}
                                        onChange={(e) => {
                                            let state = this.state;
                                            state.formDirty = true;
                                            state.medicalInformation.smokingHabits = e.target.value;
                                            this.setState(state);
                                        }}
                                    />
                                </label>
                            </div>
                        </ProfileSection>
                        <div className='dividerL'></div>
                        <SocialSection
                            social={this.state.social}
                            setState={(obj) => this.setState(obj)}
                        />
                        <div className='dividerL'></div>

                        <ProfileSection title='User Settings'>
                            <div className='input-row2'>
                                <div className='user-Chats'>
                                    <p>Allow Chat Messaging</p>
                                    <div className='user-chat-option'>
                                        <p>Enable Chat messaging between logged in users</p>
                                        <div className='user-switch'>
                                            <Switch
                                                checked={this.state.allowChat}
                                                onChange={(checked) =>
                                                    this.setState({
                                                        allowChat: checked,
                                                        formDirty: true
                                                    })
                                                }
                                            />
                                            {this.state.allowChat ? (
                                                <p>Enabled</p>
                                            ) : (
                                                <p>Disabled</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {this.state.allowChat && (
                                    <div className='user-Chats'>
                                        <div onClick={this.handleAddInput} className='chats-add'>
                                            <div className='chats-add-legend'>
                                                <p>Add a custom reply</p>
                                                <sup
                                                    style={{
                                                        fontStyle: 'italic',
                                                        fontSize: 11,
                                                        marginLeft: 5,
                                                        fontFamily: "'Inter Var'"
                                                    }}
                                                >
                                                    <i
                                                        className='fe fe-info'
                                                        onMouseOver={() => {
                                                            this.setState({ toolTip: true });
                                                        }}
                                                        onMouseOut={() =>
                                                            this.setState({ toolTip: false })
                                                        }
                                                    />
                                                    {this.state.toolTip && (
                                                        <div className='infoToolTip'>
                                                            <span>
                                                                (You can not add more than three
                                                                forms)
                                                            </span>
                                                        </div>
                                                    )}
                                                    {/* (This information will be displayed on your profile) */}
                                                </sup>
                                            </div>
                                            <AiOutlinePlus />
                                        </div>

                                        {this.state.chatInputs &&
                                            this.state.chatInputs.map((input, index) => (
                                                <div key={index} className='lexp-inputs'>
                                                    <div className='lexp-input-conatiner3'>
                                                        <input
                                                            autoComplete='sdhfjsd'
                                                            className={
                                                                this.state.chatInputs[index][
                                                                    'short'
                                                                ] !== ''
                                                                    ? 'dirty'
                                                                    : 'sdsd'
                                                            }
                                                            onChange={(e) =>
                                                                this.handleInputChange(e, index)
                                                            }
                                                            name='short'
                                                            value={
                                                                this.state.chatInputs[index].short
                                                            }
                                                        />
                                                        <span className='lexp-placeholder'>
                                                            {' '}
                                                            Short Form
                                                        </span>
                                                    </div>
                                                    <div className='lexp-input-conatiner4'>
                                                        <input
                                                            autoComplete='sdhfjsd'
                                                            className={
                                                                this.state.chatInputs[index][
                                                                    'message'
                                                                ] !== ''
                                                                    ? 'dirty'
                                                                    : 'sdsd'
                                                            }
                                                            onChange={(e) =>
                                                                this.handleInputChange(e, index)
                                                            }
                                                            name='message'
                                                            value={
                                                                this.state.chatInputs[index].message
                                                            }
                                                        />
                                                        <span className='lexp-placeholder'>
                                                            {' '}
                                                            Message
                                                        </span>
                                                    </div>

                                                    <DeleteOutlined
                                                        style={{ color: 'red' }}
                                                        onClick={() =>
                                                            this.handleRemoveInput(index)
                                                        }
                                                    />
                                                </div>
                                            ))}
                                        {this.state.chatInputs &&
                                        this.state.chatInputs.length !== 0 ? (
                                            <div className='lexp-btn'>
                                                <p>Save</p>
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                                <div className='user-Chats'>
                                    <p>Enable Outlook Connection</p>
                                    <div className='user-chat-option'>
                                        <p>Synchronized Appointments with Microsoft Outlook</p>
                                        <div className='user-switch'>
                                            <Switch
                                                checked={this.state.syncOutlookCalendar}
                                                onChange={(checked) =>
                                                    this.syncOutlookCalendar(checked)
                                                }
                                            />
                                            {this.state.syncOutlookCalendar ? (
                                                <p>Enabled</p>
                                            ) : (
                                                <p>Disabled</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='user-Chats last'>
                                    <p>Enable Google Calendar</p>
                                    <div className='user-chat-option'>
                                        <p>Syncronize Appointments with Google Calendar</p>
                                        <div className='user-switch'>
                                            <Switch
                                                checked={this.state.syncGoogleCalendar}
                                                onChange={(checked) =>
                                                    this.syncGoogleCalendar(checked)
                                                }
                                            />
                                            {this.state.syncGoogleCalendar ? (
                                                <p>Enabled</p>
                                            ) : (
                                                <p>Disabled</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ProfileSection>
                        <div className='dividerL'></div>

                        <AccountSettings
                            disableEmail={this.state.disableEmail}
                            disablePass={this.state.disablePass}
                            disablePhone={this.state.disablePhone}
                            onChange={this.onChange}
                            handleEnable={this.handleEnable}
                            setState={(obj) => this.setState(obj)}
                            saveProfile={this.saveProfile}
                            deactivateAccount={this.deactivateAccount}
                            email={this.state.email}
                            phoneNo={this.state.phoneNo}
                            password={this.state.password}
                            countryCode={this.state.countryCode}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 20,
                            width: 350,
                            maxWidth: '100%'
                        }}
                    >
                        <ProfileCalendar />
                        <Notifications />
                    </div>
                </div>
                <NavPrompt formDirty={this.state.formDirty} />

                {this.state.deactivateAccountConfirmModal && (
                    <ConfirmModal
                        {...this.props}
                        acceptMethod={(e) =>
                            this.setState({ deactivateAccountFlag: true }, () =>
                                this.deactivateAccount()
                            )
                        }
                        onClose={() => this.setState({ deactivateAccountConfirmModal: false })}
                        headerText='Deactivate Account'
                        bodyText='Are you sure you want to deactivate this Account?'
                    />
                )}
                {this.state.passwordModal && (
                    <ChangePassword
                        passwordModal={this.state.passwordModal}
                        history={this.props.history}
                        closePasswordModal={this.closePasswordModal}
                    />
                )}
                {!this.state.disableEmail && (
                    <ChangePhoneOrEmail
                        open={!this.state.disableEmail}
                        onClose={this.handleDisable}
                        headerText={'Change Email'}
                        email={this.state.email.value}
                        phoneNo={this.state.password.value}
                        onChange={this.onChange}
                        input1={'New email'}
                        input2={'Password'}
                        type={'email'}
                        history={this.props.history}
                    />
                )}
                {!this.state.disablePhone && (
                    <ChangePhoneOrEmail
                        open={!this.state.disablePhone}
                        onClose={this.handleDisable}
                        headerText={'Change Phone Number'}
                        input1={'New Phone Number'}
                        input2={'Password'}
                        type={'phone'}
                        history={this.props.history}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth?.userProfile
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
