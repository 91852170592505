import React from 'react';
import PBlogCard from './PBlogCard';
import './popularBlogs.css';
import Law from '@/assets/img/civil.jpeg';

const PopCard = (props) => {
    const { blog } = props;
    return (
        <div className='pop-blogs-box'>
            <div className='po-box-img'>
                <img
                    src={blog && blog.coverImage ? blog.coverImage : Law}
                    onError={(e) => (e.target.src = { Law })}
                />
            </div>
            <PBlogCard
                blog={props.blog}
                type={props.type ? props.type : 'normal'}
                body={props.body}
                background={props.background}
            />
        </div>
    );
};

export default PopCard;
