import React from 'react';
import { Modal, Select, Empty } from 'antd';
const { Option } = Select;

function ReportDescModal(props) {
    const { reportModal, setReportModal, reports, title } = props;
    return (
        (<Modal
            className='menusModal'
            title={title ? title : 'View Reports'}
            open={reportModal}
            onOk={() => setReportModal(false)}
            onCancel={() => setReportModal(false)}
            footer={<></>}
        >
            <div>
                {reports && reports.length > 0 ? (
                    reports.map((item, index) => (
                        <div className='reportItem'>
                            <div>{index + 1}</div>
                            <div>
                                <h5>
                                    {item.reporter &&
                                        item.reporter.firstName &&
                                        item.reporter.firstName}{' '}
                                    {item.reporter &&
                                        item.reporter.lastName &&
                                        item.reporter.lastName}
                                </h5>
                                <p>{item.reportDesc && item.reportDesc}</p>
                            </div>
                        </div>
                    ))
                ) : (
                    <Empty />
                )}
            </div>
        </Modal>)
    );
}

export default ReportDescModal;
