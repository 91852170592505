import * as Types from '../actions/types';
const init = {
    patientProfile: {},
    patientFavourites: {
        blogs: [],
        blogsCount: 0,
        doctors: [],
        doctorsCount: 0,
        hospitals: [],
        hospitalsCount: 0
    },
    reviews: [],
    reviewsCount: 0,
    errors: {},
    specializedRoles: {},
    practiceAreas: {},
    hospitalServices: [],
    documentTypes: [],
    searchSuggestions: {
        doctors: [],
        hospitals: [],
        practiceAreas: []
    },
    mailDataList: {
        complaints: [],
        feedbacks: [],
        sales: [],
        general: [],
        support: [],
        supportCount: 0,
        feedbacksCount: 0,
        salesCount: 0,
        generalCount: 0,
        complaintsCount: 0
    },

    termsModal: false,
    privacyPolicyModal: false,
    guidelinesModal: false
};

const generalReducer = (state = init, action) => {
    switch (action.type) {
        case Types.VIEW_CLIENT_PROFILE: {
            return {
                ...state,
                patientProfile: action.payload.patientProfile
            };
        }
        case Types.GET_SPECIALIZED_ROLES: {
            return {
                ...state,
                specializedRoles: action.payload.specializedRoles
            };
        }
        case Types.GET_PRACTICE_AREAS: {
            return {
                ...state,
                practiceAreas: action.payload.practiceAreas
            };
        }
        case Types.GET_HOSPITAL_SERVICES: {
            return {
                ...state,
                hospitalServices: action.payload.hospitalServices
            };
        }

        case Types.GET_DOCUMENT_TYPES: {
            return {
                ...state,
                documentTypes: action.payload.documentTypes
            };
        }

        case Types.GET_CUSTOMER_MAILS: {
            return {
                ...state,
                mailDataList: {
                    ...state.mailDataList,
                    ...action.payload.mailDataList
                }
            };
        }

        case Types.GET_SEARCH_SUGGESTIONS: {
            return {
                ...state,
                searchSuggestions: {
                    hospitals: action.payload.hospitals,
                    doctors: action.payload.doctors,
                    practiceAreas: action.payload.practiceAreas
                }
            };
        }
        case Types.GET_USER_VIEW_FAVOURITES: {
            let obj = {};
            if (action.payload.favouriteType == 'Blogs') {
                obj.blogs = action.payload.blogs;
                obj.blogsCount = action.payload.blogsCount;
            }
            if (action.payload.favouriteType == 'Hospitals') {
                obj.hospitals = action.payload.hospitals;
                obj.hospitalsCount = action.payload.hospitalsCount;
            }
            if (action.payload.favouriteType == 'Doctors') {
                obj.doctors = action.payload.doctors;
                obj.doctorsCount = action.payload.doctorsCount;
            }
            return {
                ...state,
                patientFavourites: {
                    ...state.patientFavourites,
                    ...obj
                }
            };
        }

        case Types.GET_CLIENT_REVIEWS: {
            return {
                ...state,
                reviews: action.payload.reviews,
                reviewsCount: action.payload.reviewsCount
            };
        }

        case Types.UPDATE_PRIVACY_POLICY_MODAL: {
            return {
                ...state,
                guidelinesModal: false,
                termsModal: false,
                privacyPolicyModal: action.payload.privacyPolicyModal
            };
        }

        case Types.UPDATE_TERMS_MODAL: {
            return {
                ...state,
                termsModal: action.payload.termsModal,
                privacyPolicyModal: false,
                guidelinesModal: false
            };
        }

        case Types.UPDATE_GUIDELINES_MODAL: {
            return {
                ...state,
                privacyPolicyModal: false,
                termsModal: false,
                guidelinesModal: action.payload.guidelinesModal
            };
        }

        default:
            return state;
    }
};

export default generalReducer;
