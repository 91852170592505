import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/userActions';
import '../../assets/css/auth.css';
import * as Constants from './../../Constants';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { Button, Checkbox, Modal, message, Form, Input } from 'antd';
import OtpInput from 'react-otp-input';
import Logo from '@/assets/images/logo.png';
import { facebookKeys } from '../../assets/Keys';
import GoogleIcon from '../../assets/img/svgs/GoogleIcon';
import MicrosoftLogin from '../MiniComponents/MicrosoftLogin';
import EmailIcon from '../../assets/img/svgs/EmailIcon';
import FacebookIcon from '../../assets/img/svgs/FacebookIcon';
import MicrosoftIcon from '../../assets/img/svgs/MicrosoftIcon';
import { PhoneOutlined } from '@ant-design/icons';
import userTypes from '../../assets/json/UserTypes';

const LoginWithPhone = (props) => {
    const [form] = Form.useForm();
    const { userTypeProp, open, closePhoneSignIn } = props;
    var cC = Constants.PHONENOCOUNTRYCODE.map((value) => {
        return { key: value, value: value, text: value };
    });

    const [loading, setLoading] = useState(false);
    const [otp, setOtp] = useState({
        value: '',
        message: '',
        isValid: true
    });

    const [phoneNo, setPhoneNo] = useState({
        value: '',
        message: '',
        isValid: true
    });

    const [countryCode, setCountryCode] = useState({
        value: localStorage.getItem('calling_code') ? localStorage.getItem('calling_code') : '',
        message: '',
        isValid: true
    });

    const [otpInputScreen, setOtpInputScreen] = useState(false);
    const [userType, setUserType] = useState(userTypeProp ? userTypeProp : 'CUSTOMER');

    const changeUserType = (e) => {
        setUserType(e.target.value);
        props.setUserTypeProp(e.target.value);
    };

    const submitForm = async (e) => {
        setLoading(true);
        e.preventDefault();

        if (!countryCode.value) {
            message.error('Select country code!');
            return;
        }

        form.validateFields()
            .then(async ({ phoneNo }) => {
                let obj = {
                    phoneNo: phoneNo,
                    countryCode: countryCode.value,
                    userType: userType,
                    type: 'login'
                };
                let response = await props.actions.sendOtp(obj, props.history);

                if (response == true) {
                    setOtpInputScreen(true);
                    
                } else {
                    message.error(<p> Could not request OTP!!" &nbsp; &#9749;</p>);
                    
                }
                setLoading(false)
            })
            .catch((err) => setLoading(false));
    };

    const submitOtp = async (e) => {
        setLoading(true);
        e.preventDefault();
        const { phoneNo } = form.getFieldsValue(['phoneNo']);
        let obj = {
            phoneNo: phoneNo,
            countryCode: countryCode.value,
            otp: otp.value,
            userType: userType,
            signup: props.regProp
        };
        let response = await props.actions.verifyOtp(obj, props.history);
        if (response == true) {
            let res2 = await props.actions.loginWithPhone(obj, props.history);
            if (res2 == true) {
                props.closePhoneSignIn();
                let profileData = {
                    userId: props.auth.user._id,
                    userType: props.auth.user.userType
                };
                await props.actions.getProfile(profileData, props.history);
            }
        } else {
            message.error('Try again');
            setOtpInputScreen(false);
            setLoading(false);
        }
    };

    const responseFacebook = (data) => {
        console.log(data);
    };

    const responseGoogle = (data) => {
        console.log(data);
    };

    const responseMicrosoft = (response) => {
        console.log(response);
        if (response) {
            const data = {
                name: response.account.name,
                userType,
                email: response.account.userName
            };
            props.actions.microsoftLogin(data, props.history);
        }
    };

    const handleOtpChange = (otpdata) => {
        console.log(otpdata);
        setOtp({ ...otp, value: otpdata });
    };

    return (
        <Modal open={open} centered destroyOnClose onCancel={closePhoneSignIn} footer={null}>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between'
                }}
            >
                <div>
                    <img src={Logo} alt='logo' style={{ width: '8rem' }} />
                </div>
            </div>
            <div className='userType'>
                {userTypes.map((item) => (
                    <button
                        key={item.value}
                        value={item.value}
                        disabled={item.disabled}
                        onClick={changeUserType}
                        className={`${userType === item.value && 'active'}`}
                    >
                        {item.label}
                    </button>
                ))}
            </div>
            {!otpInputScreen ? (
                <h3 style={{ textAlign: 'left', marginTop: 15 }}>Sign into your account</h3>
            ) : (
                <h3
                    onClick={() => setOtpInputScreen(false)}
                    style={{ textAlign: 'left', marginTop: 15 }}
                >
                    Go Back
                </h3>
            )}

            <div className='form'>
                {!otpInputScreen ? (
                    <Form form={form}>
                        <div className='field'>
                            <PhoneInput
                                country={'ca'}
                                value={countryCode.value}
                                placeholder='Country Code'
                                onChange={(phone) =>
                                    setCountryCode({ ...countryCode, value: phone })
                                }
                            />
                        </div>
                        <Form.Item
                            name='phoneNo'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please input your phone number!'
                                },
                                {
                                    min: 10,
                                    message: 'Phone number must be 10 digits'
                                }
                            ]}
                        >
                            <Input
                                prefix={<PhoneOutlined className='site-form-item-icon' />}
                                placeholder='Phone number'
                            />
                        </Form.Item>
                    </Form>
                ) : (
                    <div className='field'>
                        <OtpInput
                            className='otp-input'
                            value={otp.value}
                            onChange={handleOtpChange}
                            numInputs={6}
                            separator={false}
                            style={{ color: '#000' }}
                            containerStyle={{ justifyContent: 'center' }}
                        />
                    </div>
                )}

                <h3>Or Sign in with</h3>
                <div
                    style={{
                        display: 'flex',
                        gap: 10,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 10
                    }}
                >
                    <Button
                        type='text'
                        onClick={(e) => {
                            e.preventDefault();
                            props.signInModal();
                        }}
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <EmailIcon />
                    </Button>
                    <FacebookLogin
                        appId={facebookKeys.appId}
                        autoLoad={false}
                        fields='name,email,picture'
                        callback={(response) => responseFacebook(response)}
                        redirectUri='https://www.jurisnexa.com/'
                        render={(renderProps) => (
                            <Button
                                type='text'
                                className='social_btn'
                                onClick={renderProps.onClick}
                            >
                                <FacebookIcon />
                            </Button>
                        )}
                    />
                    <GoogleLogin
                        clientId='769714580677-5jh37eqkl71hjkv9l53if687r0pm5s9s.apps.googleusercontent.com'
                        onSuccess={(response) => responseGoogle(response)}
                        onFailure={(response) => responseGoogle(response)}
                        className='googleBtn'
                        isSignedIn={false}
                        render={(renderProps) => (
                            <Button type='text' onClick={renderProps.onClick}>
                                <GoogleIcon />
                            </Button>
                        )}
                    />

                    <MicrosoftLogin
                        clientId={'6686bce7-4838-4fb8-8269-0c2e3005281d'}
                        authCallback={(response) => responseMicrosoft(response)}
                        prompt={'consent'}
                        graphScopes={['profile']}
                        redirectUri={'https://Medasq-frontend-inevex.herokuapp.com'}
                        children={
                            <Button type='text'>
                                <MicrosoftIcon />
                            </Button>
                        }
                    />
                </div>
                <Checkbox
                    style={{ marginBottom: 10, fontSize: 12 }}
                    // checked={checkBox}
                    // onChange={handleCheckbox}
                >
                    Save Credentials
                </Checkbox>
                {!otpInputScreen ? (
                    <Button loading={loading} type='primary' style={{ width: '100%' }} onClick={submitForm}>
                        Request OTP
                    </Button>
                ) : (
                    <Button loading={loading} onClick={submitOtp} type='primary' style={{ width: '100%' }}>
                        Sign In
                    </Button>
                )}
            </div>

            <div className='btm-links-login' style={{ display: 'flex', flexDirection: 'column' }}>
                <p
                    style={{ fontSize: 12, cursor: 'context-menu' }}
                    onClick={() => {
                        props.forgotPassModal();
                    }}
                >
                    Forgot Password?&nbsp;
                    <span style={{ fontSize: 12, fontWeight: 'bold', cursor: 'pointer', color: 'var(--primary)' }}>
                        Reset
                    </span>
                </p>
                {userType !== 'DOCTOR' && (
                    <p style={{ fontSize: 12, cursor: 'context-menu' }}>
                        Don't have an account?&nbsp;
                        <span
                            onClick={() => {
                                props.setRegProp(true);
                                props.phoneSignUpModal();
                            }}
                            style={{ fontSize: 12, fontWeight: 'bold', cursor: 'pointer', color: 'var(--primary)' }}
                        >
                            Sign Up
                        </span>
                    </p>
                )}
            </div>
        </Modal>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(LoginWithPhone);
