import React, { useEffect, useState } from 'react';
import VideoCall from './Meeting';
import { opentokKeys as config } from '@/assets/Keys';
import { useSelector, useDispatch } from 'react-redux';
import * as appointmentActions from '@/actions/appointmentActions';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import MeetingEnded from './MeetingEnded';
import { socket } from '../../../MiniComponents/Socket';

function Container(props) {
    const dispatch = useDispatch();
    const { singleAppointment } = useSelector((state) => state.appointments);
    const { user, userId, userType } = useSelector((state) => state.auth);
    const [privateNotes, setPrivateNotes] = useState('');
    const { history } = props;
    // const { videoToken } = useSelector(state => state.appointments)
    let { sessionId, token: videoToken, appointmentId } = useParams();
    const [remaining, setRemaining] = useState();
    const [loading, setLoading] = useState(true);

    const [firstTime, setFirstTime] = useState(true);

    const getVideoToken = async () => {
        // if(!sessionId){
        // sessionId = sessionIdTemp
        // }
        // console.log(sessionId, videoToken)
        let obj = {
            sessionId,
            appointmentId
        };
        dispatch(appointmentActions.getVideoToken({ ...props, obj }));
    };

    const getAppointment = async () => {
        setLoading(true);
        let obj = {
            appointmentId
        };
        let appointment = await dispatch(
            appointmentActions.getSingleAppointment({ ...props, obj })
        );
        setLoading(false);
        if (!appointment) {
            let returnUrl;
            if (user?.userType == "PATIENT") {
                returnUrl = `/patient/appointments`;
            } else if (user?.userType == "DOCTOR") {
                returnUrl = `/doctor/appointments`;
            }
            history.push(`${returnUrl}`);
        }
    };
    var update_time_remaining = function () {
        let appDate = moment(singleAppointment.appointmentDate).format('DD/MM/YY');
        let appEndTime = moment(
            appDate + ' ' + singleAppointment.appointmentEndTime,
            'DD/MM/YY HH:mm'
        );
        let appEndTimeUnix = appEndTime.valueOf();

        let remaining = Math.round((appEndTimeUnix - Date.now()) / 60000);

        setRemaining(Math.round(remaining));
    };

    useEffect(() => {
        getAppointment();
        getVideoToken();
    }, []);

    useEffect(() => {
        if (singleAppointment && firstTime) {
            setFirstTime(false);
            console.log('got in singleAppointment');
            let obj = {
                conversationId: singleAppointment?.conversation?._id,
                appointmentId: singleAppointment?._id,
                userId: userId,
                userType: userType,
                joinedAt: Date.now()
            };
            console.log('got in singleAppointment', obj);
            socket.emit('join', obj);

            socket.on('user joined', async (data) => {
                console.log('received data from join', data);
                await getAppointment();
            });

            socket.on('user left', async (data) => {
                let returnUrl;

                if (
                    data.userId ==
                        (singleAppointment?.user?._id || singleAppointment?.doctor?._id) &&
                    data.userId != userId
                ) {
                    if (user?.userType == "PATIENT") {
                        returnUrl = `/patient/meeting/${singleAppointment?._id}/meeting-ended`;
                    } else {
                        returnUrl = `/doctor/meeting/${singleAppointment?._id}/meeting-ended`;
                    }
                    history.push(`${returnUrl}`);
                }
            });
        }
    }, [singleAppointment]);

    useEffect(() => {
        if (singleAppointment) {
            update_time_remaining();
            setInterval(update_time_remaining, 10000);
        }
        return () => {
            clearInterval(update_time_remaining);
        };
    }, [singleAppointment]);

    useEffect(() => {
        if (remaining < 0) {
            // if(singleAppointment?.status != "COMPLETED")
            // history.push(
            //   `/${
            //     user?.userType == "HOSPITAL" ? 'user' : 'doctor'
            //   }/meeting/${appointmentId}/meeting-ended`
            // )
        } else if (
            moment(new Date()).format('DD/MM/YYYY') ==
                moment(singleAppointment?.appointmentDate).format('DD/MM/YYYY') &&
            moment(new Date()).format('HH:mm') >= singleAppointment?.appointmentStartTime &&
            moment(new Date()).format('HH:mm') <= singleAppointment?.appointmentEndTime
        ) {
            console.log(
                'within time range',
                moment(new Date()).format('HH:mm'),
                moment(singleAppointment?.appointmentDate).format('DD/MM/YYYY'),
                moment(new Date()).format('DD/MM/YYYY')
            );
            // history.push(
            //   `/${
            //     user?.userType == "HOSPITAL" ? 'user' : 'doctor'
            //   }/meeting/${appointmentId}/meeting-ended`
            // )
        } else {
            // history.push(
            //   `/${
            //     user?.userType == "HOSPITAL" ? 'user' : 'doctor'
            //   }/appointments?tab=list`
            // )
            // history.push(
            //   `/${
            //     user?.userType == "HOSPITAL" ? 'user' : 'doctor'
            //   }/meeting/${appointmentId}/meeting-ended`
            // )
        }
    }, [remaining]);

    const leaveMeeting = async () => {
        let returnUrl;
        if (user?.userType == "PATIENT") {
            returnUrl = `/patient/meeting/${singleAppointment?._id}/meeting-ended`;
        } else {
            returnUrl = `/doctor/meeting/${singleAppointment?._id}/meeting-ended`;
        }
        let obj = {
            appointmentId: singleAppointment?._id,
            bothJoined: singleAppointment?.doctorJoinedAt && singleAppointment?.clientJoinedAt
        };
        let completed = await dispatch(appointmentActions.appointmentCompleted({ ...props, obj }));
        if (completed) {
            socket.emit('leave meeting', {
                conversationId: singleAppointment?.conversation?._id,
                appointmentId,
                userId,
                userType
            });
            history.push(`${returnUrl}`);
        }
    };
    const noShow = async () => {
        let returnUrl;
        if (user?.userType == "PATIENT") {
            returnUrl = `/patient/meeting/${singleAppointment?._id}/meeting-ended`;
        } else {
            returnUrl = `/doctor/meeting/${singleAppointment?._id}/meeting-ended`;
        }
        let obj = {
            appointmentId: singleAppointment?._id
        };
        let completed = await dispatch(appointmentActions.noShowAppointment({ ...props, obj }));
        if (completed) {
            history.push(`${returnUrl}`);
        }
    };

    useEffect(() => {
        // if(!singleAppointment && !loading){
        //   if(singleAppointment?.doctor?._id == userId || singleAppointment?.user?._id == userId){
        //     let returnUrl
        //   if (user?.userType == "PATIENT") {
        //     returnUrl = `/patient/appointments`
        //   } else if (user?.userType == "DOCTOR") {
        //     returnUrl = `/doctor/appointments`
        //   }
        //     history.push(`${returnUrl}`)
        //   }
        // }
    }, [singleAppointment]);

    return (
        <>
            {videoToken && singleAppointment ? (
                <VideoCall
                    config={config}
                    sessionId={sessionId}
                    token={videoToken}
                    remaining={remaining}
                    appointment={singleAppointment}
                    user={user}
                    history={history}
                    leaveMeeting={leaveMeeting}
                    noShow={noShow}
                    privateNotes={privateNotes}
                    setPrivateNotes={setPrivateNotes}
                    getAppointment={getAppointment}
                />
            ) : (
                // <p>Not Allowed</p>
                <MeetingEnded
                    config={config}
                    sessionId={sessionId}
                    token={videoToken}
                    remaining={remaining}
                    appointment={singleAppointment}
                    user={user}
                    history={history}
                />
            )}{' '}
        </>
    );
}

export default Container;
