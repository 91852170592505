import React, { useState } from 'react';
import { connect } from 'react-redux';
import '../general.css';
import moment from 'moment';
import { Collapse, Select } from 'antd';
const { Panel } = Collapse;
const { Option } = Select;

const General = (props) => {
    const { member } = props;
    const [activeKey, setActiveKey] = useState(['1', '2', '3', '4', '5', '6', '7']);
    const [selectedPanel, setSelectedPanel] = useState('');
    function callback(keys) {
        console.log(keys);
        setActiveKey(keys);
    }
    function handleChange(value) {
        if (value == '') {
            setSelectedPanel('');
            setActiveKey(['1', '2', '3', '4', '5', '6', '7']);
        } else {
            let actKeys = [];
            actKeys = [value];
            setActiveKey(actKeys);
            setSelectedPanel(value);
        }
    }

    return (
        <div className='hospital-general-main'>
            <div className='activeTab'>
                <p>General</p>
            </div>
            <div className='sectionFilterCont'>
                <Select
                    defaultValue=''
                    value={selectedPanel}
                    style={{ width: 320, maxWidth: '100%' }}
                    onChange={handleChange}
                >
                    <Option value=''>All</Option>
                    <Option value='1'>Introduction</Option>
                    <Option value='2'>Experience</Option>
                    <Option value='3'>Education</Option>
                    <Option value='4'>Admissions</Option>
                    <Option value='5'>Associations</Option>
                    <Option value='6'>Honors & Awards</Option>
                    <Option value='7'>Verified Credentials</Option>
                </Select>
            </div>

            <Collapse defaultActiveKey={['1']} activeKey={activeKey} onChange={callback}>
                <Panel header='INTRODUCTION' key='1'>
                    <div className='general-intro-body'>
                        <p>{member ? member.profileSummary : ''}</p>
                    </div>
                </Panel>
                <Panel header='EXPERIENCE' key='2'>
                    <div className='general-intro-body-edu'>
                        <div className='general-intro-body-edu-col'>
                            <div className='genral-colums'>
                                <p>Organization</p>
                            </div>
                            <div className='genral-colums'>
                                <p>Position</p>
                            </div>
                            <div className='genral-colums'>
                                <p>Location</p>
                            </div>
                            <div className='genral-colums'>
                                <p>Duration</p>
                            </div>
                        </div>
                    </div>
                    {member &&
                        member.experiences &&
                        member.experiences.length !== 0 &&
                        member.experiences.map((item, index) => (
                            <div className='general-intro-body-edu'>
                                <div className='general-intro-body-edu-col2' key={index}>
                                    <div className='genral-colums'>
                                        <p>{item.organization}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.position}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.location}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.duration}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </Panel>
                <Panel header='EDUCATION' key='3'>
                    <div className='general-intro-body-edu'>
                        <div className='general-intro-body-edu-col'>
                            <div className='genral-colums'>
                                <p>School</p>
                            </div>
                            <div className='genral-colums'>
                                <p>Degree</p>
                            </div>
                            <div className='genral-colums'>
                                <p>Major</p>
                            </div>
                            <div className='genral-colums'>
                                <p>Graduation</p>
                            </div>
                        </div>
                    </div>
                    {member &&
                        member.educations &&
                        member.educations.length !== 0 &&
                        member.educations.map((item, index) => (
                            <div className='general-intro-body-edu'>
                                <div className='general-intro-body-edu-col2' key={index}>
                                    <div className='genral-colums'>
                                        <p>{item.school}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.degree}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.major}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.graduation}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </Panel>
                <Panel header='ADMISSIONS' key='4'>
                    <div className='general-intro-body-edu'>
                        <div className='general-intro-body-edu-col'>
                            <div className='genral-colums'>
                                <p>State / Province / Court</p>
                            </div>
                            <div className='genral-colums'>
                                <p>Date</p>
                            </div>
                        </div>
                    </div>
                    {member &&
                        member.admissions &&
                        member.admissions.length !== 0 &&
                        member.admissions.map((item, index) => (
                            <div className='general-intro-body-edu'>
                                <div className='general-intro-body-edu-col2' key={index}>
                                    <div className='genral-colums'>
                                        <p>{item.state}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.date}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </Panel>
                <Panel header='ASSOCIATIONS' key='5'>
                    <div className='general-intro-body-edu'>
                        <div className='general-intro-body-edu-col'>
                            <div className='genral-colums'>
                                <p>Organization</p>
                            </div>
                            <div className='genral-colums'>
                                <p>Status</p>
                            </div>
                            <div className='genral-colums'>
                                <p>Date</p>
                            </div>
                        </div>
                    </div>
                    {member &&
                        member.associations &&
                        member.associations.length !== 0 &&
                        member.associations.map((item, index) => (
                            <div className='general-intro-body-edu'>
                                <div className='general-intro-body-edu-col2' key={index}>
                                    <div className='genral-colums'>
                                        <p>{item.organization}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.status}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.date}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </Panel>

                <Panel header='HONORS & AWARDS' key={'6'}>
                    <div className='general-intro-body-edu'>
                        <div className='general-intro-body-edu-col'>
                            <div className='genral-colums'>
                                <p>Name / Organization </p>
                            </div>
                            <div className='genral-colums'>
                                <p>Date</p>
                            </div>
                        </div>
                    </div>
                    {member &&
                        member.honors &&
                        member.honors.length !== 0 &&
                        member.honors.map((item, index) => (
                            <div className='general-intro-body-edu'>
                                <div className='general-intro-body-edu-col2' key={index}>
                                    <div className='genral-colums'>
                                        <p>{item.organization}</p>
                                    </div>
                                    <div className='genral-colums'>
                                        <p>{item.date}</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                </Panel>

                <Panel header='VERIFIED CREDENTIALS' key={'7'}>
                    <div className='general-intro-body'>
                        {/* <p>{member ? member.profileSummary : ""}</p> */}
                        {member &&
                            member.experiences &&
                            member.experiences.filter(
                                (item, index) => item.verificationStatus == 'APPROVED'
                            ).length > 0 && <p className='heading'>Experiences</p>}
                        <table>
                            {member &&
                                member.experiences &&
                                member.experiences.length !== 0 &&
                                member.experiences.map((item, index) => {
                                    if (item.verificationStatus == 'APPROVED') {
                                        return (
                                            <tr>
                                                <td>
                                                    <div>
                                                        {item.organization && item.organization}
                                                    </div>
                                                </td>{' '}
                                                <td>
                                                    <div>{item.position && item.position}</div>
                                                </td>{' '}
                                                <td>
                                                    <div>{item.location && item.location}</div>
                                                </td>{' '}
                                            </tr>
                                        );
                                    }
                                })}
                        </table>
                        {member &&
                            member.honors &&
                            member.honors.filter(
                                (item, index) => item.verificationStatus == 'APPROVED'
                            ).length > 0 && <p className='heading'>Honors</p>}
                        {/* <p className="heading">Honors</p> */}
                        <table>
                            {member &&
                                member.honors &&
                                member.honors.length !== 0 &&
                                member.honors.map((item, index) => {
                                    if (item.verificationStatus == 'APPROVED') {
                                        return (
                                            <tr>
                                                <td>
                                                    <div>
                                                        {item.organization && item.organization}
                                                    </div>
                                                </td>{' '}
                                                <td>
                                                    <div>
                                                        {item.date &&
                                                            moment(item.date).format(
                                                                'MMM DD, YYYY'
                                                            )}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                        </table>

                        {member &&
                            member.associations &&
                            member.associations.filter(
                                (item, index) => item.verificationStatus == 'APPROVED'
                            ).length > 0 && <p className='heading'>Associations</p>}

                        <table>
                            {member &&
                                member.associations &&
                                member.associations.length !== 0 &&
                                member.associations.map((item, index) => {
                                    if (item.verificationStatus == 'APPROVED') {
                                        return (
                                            <tr>
                                                <td>
                                                    <div>
                                                        {item.organization && item.organization}
                                                    </div>
                                                </td>{' '}
                                                <td>
                                                    <div>{item.status && item.status}</div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                        </table>

                        {member &&
                            member.educations &&
                            member.educations.filter(
                                (item, index) => item.verificationStatus == 'APPROVED'
                            ).length > 0 && <p className='heading'>Educations</p>}

                        <table>
                            {member &&
                                member.educations &&
                                member.educations.length !== 0 &&
                                member.educations.map((item, index) => {
                                    if (item.verificationStatus == 'APPROVED') {
                                        return (
                                            <tr>
                                                <td>
                                                    <div>{item.school && item.school}</div>
                                                </td>{' '}
                                                <td>
                                                    <div>{item.degree && item.degree}</div>
                                                </td>{' '}
                                                <td>
                                                    <div>{item.major && item.major}</div>
                                                </td>{' '}
                                                <td>
                                                    <div>{item.graduation && item.graduation}</div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                        </table>

                        {member &&
                            member.admissions &&
                            member.admissions.filter(
                                (item, index) => item.verificationStatus == 'APPROVED'
                            ).length > 0 && <p className='heading'>Admissions</p>}

                        <table>
                            {member &&
                                member.admissions &&
                                member.admissions.length !== 0 &&
                                member.admissions.map((item, index) => {
                                    if (item.verificationStatus == 'APPROVED') {
                                        return (
                                            <tr>
                                                <td>
                                                    <div>{item.state && item.state}</div>
                                                </td>{' '}
                                                <td>
                                                    <div>
                                                        {item.date &&
                                                            moment(item.date).format(
                                                                'MMM DD, YYYY'
                                                            )}
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    }
                                })}
                        </table>
                    </div>
                </Panel>
            </Collapse>
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth
});

export default connect(mapStateToProps)(General);
