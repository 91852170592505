import * as Types from '../actions/types';
const init = {
    admin: {},
    allUserCount: {},
    customerUsers: [],
    customerProfile: {},
    lawfirmUsers: [],
    doctorUsers: [],
    lawfirmProfile: {},
    savedAdminProfile: {},
    adminUsers: [],
    adminProfile: {},
    customerUsersCount: 0,
    lawfirmUsersCount: 0,
    adminUsersCount: 0,
    blogs: [],
    allBlogsCount: 0,
    reportedPortfolios: [],
    allPortfolioItemCount: 0,
    isAdmin: false,
    errors: {},
    stripeCustomers: {},
    stripeProducts: {},
    stripeSubscriptions: {},
    emailTemplates: [],
    singleEmailTemplate: {},
    emailTemplatesCount: 0,
    mailCounts: {
        complaintCount: 0,
        feedbackCount: 0,
        salesCount: 0,
        generalCount: 0,
        supportCount: 0
    },
    mailDataList: {
        complaints: [],
        feedbacks: [],
        sales: [],
        general: [],
        support: [],
        supportCount: 0,
        feedbacksCount: 0,
        salesCount: 0,
        generalCount: 0,
        complaintsCount: 0
    }
};

const adminReducer = (state = init, action) => {
    switch (action.type) {
        case Types.SET_ADMIN: {
            return {
                ...state,
                admin: action.payload.admin,
                isAdmin: Object.keys(action.payload.admin).length !== 0,
                errors: {}
            };
        }
        case Types.GET_ALL_USER_COUNT: {
            return {
                ...state,
                allUserCount: action.payload.allUserCount
            };
        }

        case Types.GET_CUSTOMER_USERS: {
            return {
                ...state,
                customerUsers: action.payload.customerUsers,
                customerUsersCount: action.payload.customerUsersCount
            };
        }
        case Types.GET_HOSPITAL_USERS: {
            return {
                ...state,
                lawfirmUsers: action.payload.lawfirmUsers,
                lawfirmUsersCount: action.payload.lawfirmUsersCount
            };
        }

        case Types.GET_ADMIN_USERS: {
            return {
                ...state,
                adminUsers: action.payload.adminUsers,
                adminUsersCount: action.payload.adminUsersCount
            };
        }
        case Types.GET_ALL_BLOGS_ADMIN: {
            return {
                ...state,
                blogs: action.payload.blogs,
                allBlogsCount: action.payload.allBlogsCount
            };
        }
        case Types.ADMINS_ERROR: {
            return {
                ...state,
                errors: action.payload.error
            };
        }

        case Types.GET_CUSTOMER_PROFILE: {
            return {
                ...state,
                customerProfile: action.payload.customerProfile
            };
        }

        case Types.GET_HOSPITAL_PROFILE: {
            return {
                ...state,
                lawfirmProfile: action.payload.lawfirmProfile
            };
        }
        case Types.GET_REPORTED_PORTFOLIO_ITEMS: {
            return {
                ...state,
                reportedPortfolios: action.payload.reportedPortfolios,
                allPortfolioItemCount: action.payload.allPortfolioItemCount
            };
        }
        case Types.GET_ADMIN_PROFILE: {
            return {
                ...state,
                adminProfile: action.payload.adminProfile
            };
        }

        case Types.SAVE_ADMIN_PROFILE: {
            return {
                ...state,
                savedAdminProfile: action.payload.savedAdminProfile
            };
        }

        // case Types.GET_STRIPE_CUSTOMERS: {
        //   return {
        //     ...state,
        //     stripeCustomers: action.payload.stripeCustomers
        //   };
        // }

        // case Types.GET_STRIPE_PRODUCTS: {
        //   return {
        //     ...state,
        //     stripeProducts: action.payload.stripeProducts
        //   };
        // }
        // case Types.GET_STRIPE_SUBSCRIPTIONS: {
        //   return {
        //     ...state,
        //     stripeSubscriptions: action.payload.stripeSubscriptions
        //   };
        // }

        case Types.GET_EMAIL_TEMPLATES: {
            return {
                ...state,
                emailTemplates: action.payload.emailTemplates,
                emailTemplatesCount: action.payload.emailTemplatesCount
            };
        }

        case Types.GET_SINGLE_EMAIL_TEMPLATE: {
            return {
                ...state,
                singleEmailTemplate: action.payload.singleEmailTemplate
            };
        }
        case Types.GET_MAIL_LISTINGS_COUNT: {
            return {
                ...state,
                mailCounts: { ...action.payload.mailCounts }
            };
        }

        case Types.GET_MAIL_LISTINGS: {
            console.log('got here', action.payload.mailDataList, {
                ...state.mailDataList,
                ...action.payload.mailDataList
            });
            return {
                ...state,
                mailDataList: {
                    ...state.mailDataList,
                    ...action.payload.mailDataList
                }
            };
        }

        default:
            return state;
    }
};

export default adminReducer;
