import { Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import AvailabilityModal from '../locationContent/AvailabilityModal';

const { Option } = Select;

const Availability = () => {
    const { branches } = useSelector((state) => state.branch);
    const [selectedAddress, setSelectedAddress] = useState('');
    const [selectedAddressIndex, setSelectedAddressIndex] = useState(0);
    const [availabilityModal, setAvailabilityModal] = useState(false);

    const schedule = selectedAddress && branches[selectedAddressIndex].availability;

    useEffect(() => {
        if (branches && branches.length !== 0) {
            setSelectedAddress(branches[0]._id);
        }
    }, [branches]);

    const daysOfWeek = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday'
    ];

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
            <div
                className='sectionTitle'
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '0.4rem 1rem'
                }}
            >
                <h4 style={{ fontSize: 14, marginBottom: 0, lineHeight: '14px', fontWeight: 400 }}>
                    Availability
                </h4>
                <Button type='primary' size='small' onClick={() => setAvailabilityModal(true)}>
                    Update
                </Button>
            </div>

            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 10,
                    backgroundColor: 'white',
                    padding: 15,
                    border: '1px solid #ddd',
                    borderTop: 'none',
                    borderRadius: 4
                }}
            >
                <Select
                    style={{ width: '100%' }}
                    placeholder='Select Address'
                    value={selectedAddress}
                    onChange={(val, obj) => {
                        setSelectedAddressIndex(obj.key);
                        setSelectedAddress(val);
                    }}
                >
                    {branches &&
                        branches !== 0 &&
                        branches.map((it, ind) => (
                            <Option key={ind} value={it._id}>
                                {it.location &&
                                    it.location.branchAddress &&
                                    it.location.branchAddress}
                                ,{it.location && it.location.branchCity && it.location.branchCity},
                                {it.location &&
                                    it.location.branchCountry &&
                                    it.location.branchCountry}
                            </Option>
                        ))}
                </Select>

                {schedule ? (
                    <>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between'
                            }}
                        >
                            <div>TimeZone:</div>
                            <div> {schedule.timezone && schedule.timezone}</div>
                        </div>
                        <div>
                            {schedule.alwaysAvailable && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        gap: 10,
                                        background: 'red',
                                        color: 'white',
                                        borderRadius: 4,
                                        padding: '5px 0px'
                                    }}
                                >
                                    <div>24/7 Open</div>
                                </div>
                            )}
                            {!schedule.alwaysAvailable &&
                                daysOfWeek.map(
                                    (day) =>
                                        schedule[day.toLowerCase()] && (
                                            <div
                                                key={day}
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'space-between',
                                                    marginBottom: 10
                                                }}
                                            >
                                                <div>{day}: </div>
                                                <div>
                                                    {schedule[day.toLowerCase()].startTime}-
                                                    {schedule[day.toLowerCase()].endTime}
                                                </div>
                                            </div>
                                        )
                                )}
                        </div>
                    </>
                ) : (
                    <div className='lawfirm-view-timezone'>
                        <p>Schedule not fixed yet</p>
                    </div>
                )}
            </div>
            {console.log('open', availabilityModal)}
            {availabilityModal ? (
                <AvailabilityModal
                    availability={schedule}
                    modal={availabilityModal}
                    branchId={branches[selectedAddressIndex]._id}
                    index={selectedAddressIndex}
                    branch={branches[selectedAddressIndex]}
                    closeModal={() => setAvailabilityModal(false)}
                />
            ) : null}
        </div>
    );
};

export default Availability;
