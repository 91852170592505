import React from 'react';
import { OTSubscriber } from 'opentok-react';
import CheckBox from './CheckBox';
import { BsMicFill, BsFillMicMuteFill } from 'react-icons/bs';
import { FaVideo, FaVideoSlash } from 'react-icons/fa';

class Subscriber extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            audio: true,
            video: true
        };
    }

    setAudio = (audio) => {
        this.setState({ audio });
    };

    setVideo = (video) => {
        this.setState({ video });
    };

    onError = (err) => {
        this.setState({ error: `Failed to subscribe: ${err.message}` });
    };

    render() {
        return (
            <div className='subscriber'>
                {/* Subscriber */}
                {this.state.error ? (
                    <div id='error' style={{ color: 'red' }}>
                        {this.state.error}
                    </div>
                ) : null}
                <OTSubscriber
                    properties={{
                        subscribeToAudio: this.state.audio,
                        subscribeToVideo: this.state.video
                    }}
                    onError={this.onError}
                />
                <div className='checkFlex'>
                    <CheckBox
                        label='Audio'
                        initialChecked={this.state.audio}
                        onChange={this.setAudio}
                        icon={this.state.audio ? <BsMicFill /> : <BsFillMicMuteFill />}
                    />
                    <CheckBox
                        label='Video'
                        initialChecked={this.state.video}
                        onChange={this.setVideo}
                        icon={this.state.video ? <FaVideo /> : <FaVideoSlash />}
                    />
                </div>
            </div>
        );
    }
}
export default Subscriber;
