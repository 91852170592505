import React from 'react';
import './dropdown.css';
import Girl from '@/assets/img/girl2.jpg';
import { DatePicker } from 'antd';
import { AnimatePresence, motion } from 'framer-motion';
const a = [1, 2, 3, 4, 5];

const AppointmentDropdown = ({ setValues, values, type, setDropdown, drop }) => {
    console.log(type);
    function onChange(value, dateString) {
        console.log('Selected Time: ', value);
        console.log('Formatted Selected Time: ', dateString);
        setValues({
            ...values,
            [type]: 'sdkfjdskfj'
        });
        setDropdown(false);
    }

    function onOk(value) {
        console.log('onOk: ', value);
    }
    const dropVariants = {
        hidden: {
            scaleY: 0,
            originY: '0px',
            opacity: 0
        },
        visible: {
            scaleY: 1,
            originY: '0px',
            opacity: 1
        },
        exit: {
            scaleY: 0,
            originY: '0px',
            opacity: 0
        }
    };

    return (
        <motion.div
            key='dkjfkdjfk'
            className='appointment-drop-main'
            onClick={(e) => e.stopPropagation()}
            variants={dropVariants}
            initial='hidden'
            animate='visible'
            exit='exit'
        >
            <AnimatePresence exitBeforeEnter>
                {type !== 'date' && (
                    <motion.div key='new1'>
                        <div className='app-drop-search'>
                            <input placeholder='Search' />
                        </div>

                        <div className='app-drop-list'>
                            {a.map((item, index) => (
                                <div
                                    className='app-drop-service'
                                    key={item}
                                    onClick={() => {
                                        setValues({
                                            ...values,
                                            [type]: 'sdkfjdskfj'
                                        });
                                        type = 'dsjfhjdsf';
                                        setDropdown(false);
                                    }}
                                >
                                    {type !== 'client' ? (
                                        <>
                                            <p>Consultaion</p>
                                            <p>Free</p>
                                        </>
                                    ) : (
                                        <div className='app-client-container'>
                                            <div className='app-drop-img'>
                                                <img src={Girl} alt='pic' />
                                            </div>
                                            <p>Eliana De Cruz</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
            {type === 'date' && (
                <div className='app-drop-date'>
                    <DatePicker
                        showTime
                        onChange={onChange}
                        onOk={onOk}
                        style={{ width: '100%' }}
                    />
                </div>
            )}
        </motion.div>
    );
};

export default AppointmentDropdown;
