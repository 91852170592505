export const PharmacyTypes = [
    'Retail Pharmacy',
    'Hospital Pharmacy',
    'Compounding Pharmacy',
    'Specialty Pharmacy',
    'Mail Order Pharmacy',
    'Online Pharmacy',
    'Clinical Pharmacy'
]

export const PharmacyTypeOptions = PharmacyTypes.reduce((acc, value, index) => {
    return (acc = [...acc, { value: value, id: index + 1 }]);
}, []);

export const PharmacyDocumentTypes = [
    'Prescription',
    'Patient Information Form',
    'Consent Form',
    'Insurance Information',
    'Prior Authorization Form',
    'Medication Administration Records (MARs)',
    'Medication Reconciliation Form',
    'Patient Education Material',
    'Medication Packaging Label',
    'Discharge Instruction',
    'Medication Therapy Management (MTM) Documentation',
    'Authorization for Refill Request',
    'Specialty Medication Enrollment Form',
    'Patient Medication Profile',
    'Patient Consent for Medication Delivery Service',
    'Health Information Exchange (HIE) Consent',
    'Immunization Consent Form',
    'Other'
];

export const PharmacyDocumentTypeOptions = PharmacyDocumentTypes.reduce((acc, value, index) => {
    return (acc = [...acc, { value: value, id: index + 1 }]);
}, []);