import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as stripeActions from '@/actions/stripeActions';
import { DatePicker, message, Select, Spin, Alert, Button } from 'antd';
import validator from 'validator';
import moment from 'moment';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import { IoCloseCircleOutline } from 'react-icons/io5';
import ChangePhoneOrEmail from '../../modals/ChangePhoneOrEmail';
import { Switch, Tooltip } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import { specializationAreasOptions } from '@/assets/json/DoctorPracticeAreas';
import malePlaceholder from '@/assets/img/malePlaceholder.png';
import femalePlaceholder from '@/assets/img/femalePlaceholder.jpg';
import ConfirmModal from '../../modals/ConfirmModal';
import Geolocate from './../../MiniComponents/Geolocate';
import { countries } from 'country-data';
import languageOptions from '@/assets/json/Languages.json';
import {
    CoverImage,
    ProfileImage,
    NavPrompt,
    CurrencyDropdown,
    SelectComponent,
    Input,
    CountryCodeDropdown
} from '../Shared/ProfileComponents';
import ChangePassword from '../../modals/ChangePassword';
import { SocialSection, AccountSettings } from '../Shared/ProfileSections';
import Notifications from './Dashboard/Notifications';
import ProfileSection from './ProfileSection';
import ProfileCalendar from './ProfileCalendar';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import SignUpWIzard from './SignUpWIzard';

const { Option } = Select;
const genderOptions = [
    { key: 'male', text: 'Male', value: 'Male' },
    { key: 'female', text: 'Female', value: 'Female' },
    { key: 'other', text: 'Other', value: 'Other' }
];
const options = [
    { value: 'General Practitioner', id: 1 },
    { value: 'Specialist', id: 2 }
];
const specializations = [...specializationAreasOptions];

let stripePK =
    'pk_test_51OshNaP1wHcK7uYtaTkzbHiW99wgFGC1Tuxh6DyknlFbhKCk891aLsuL6ch5FdrlAe6fDPWOqPxT1TggnFgmR4nM007RIFoAkg';

const stripePromise = loadStripe(stripePK);
export class DoctorAccount extends Component {
    constructor(props) {
        super(props);

        this.state = {
            formDirty: false,
            fixedFirstName: '',
            fixedLastName: '',
            firstName: { value: '', isValid: true, message: '' },
            lastName: { value: '', isValid: true, message: '' },
            phoneNo: { value: '', isValid: true, message: '' },
            countryCode: { value: '', isValid: true, message: '' },
            dob: { stringValue: '', value: '', isValid: true, message: '' },
            email: { value: '', isValid: true, message: '' },
            password: { value: '', isValid: true, message: '' },
            allowChat: null,
            social: {
                facebook: '',
                instagram: '',
                linkedin: '',
                twitter: ''
            },
            gender: { value: '', isValid: true, message: '' },
            hospitalLanguages: [],
            languages: { value: [], isValid: true, message: '' },
            currency: { value: '', isValid: true, message: '' },
            practiceAreas: [],
            specializedRoles: [],
            toolTip: '',
            profileSummary: { value: '', isValid: true, message: '' },
            profCompDiv: true,
            coverImage: null,
            tempCoverImage: null,
            profileImage: null,
            tempProfileImage: null,
            disableEmail: true,
            disablePass: true,
            disablePhone: true,
            formSocialDirty: false,
            chatInputs: [],
            chat: false,
            chatoptions: false,
            outlook: false,
            gmail: false,
            stripe: false,
            featured: false,
            contactPhone: { value: '', isValid: true, message: '' },
            contactEmail: { value: '', isValid: true, message: '' },
            contactCountryCode: { value: '', isValid: true, message: '' },
            billingAddress: { value: '', isValid: true, message: '' },
            billingCity: { value: '', isValid: true, message: '' },
            billingState: { value: '', isValid: true, message: '' },
            billingZip: { value: '', isValid: true, message: '' },
            billingCountry: { value: '', isValid: true, message: '' },
            billingEmail: { value: '', isValid: true, message: '' },
            billingPhone: { value: '', isValid: true, message: '' },
            billingCountryCode: { value: '', isValid: true, message: '' },
            billingCoordinates: [],
            appointmentBlock: { value: '', message: '', isValid: true },
            profileCompletion: {},
            locality: localStorage.getItem('locality') ? localStorage.getItem('locality') : '',
            localityCountry: localStorage.getItem('localityCountry')
                ? localStorage.getItem('localityCountry')
                : '',
            formImageDirty: false,
            formDPDirty: false,
            passwordModal: false,
            showConfirmPassword: false,
            showCurrentPassword: false,
            showNewPassword: false,
            genderPlaceholderImg: malePlaceholder,
            deactivateAccountConfirmModal: false,
            deactivateAccountFlag: false,
            syncGoogleCalendar: false,
            syncOutlookCalendar: false,
            stripeLoading: false,
            firstLoad: true
        };
        this.curr = React.createRef();
        this.deleteAddressInfo = this.deleteAddressInfo.bind(this);
        this.changeAddressInfo = this.changeAddressInfo.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.onChange = this.onChange.bind(this);
        this.setWizard = this.setWizard.bind(this);
        this.updateBillingAddress = this.updateBillingAddress.bind(this);
        this.handleDisable = this.handleDisable.bind(this);
        this.saveCoverImage = this.saveCoverImage.bind(this);
        this.deleteCoverImage = this.deleteCoverImage.bind(this);
        this.clearCoverImage = this.clearCoverImage.bind(this);
        this.deleteProfileImage = this.deleteProfileImage.bind(this);
        this.saveProfileImage = this.saveProfileImage.bind(this);
        this.handleAddInput = this.handleAddInput.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleRemoveInput = this.handleRemoveInput.bind(this);
        this.deactivateAccount = this.deactivateAccount.bind(this);
        this.closePasswordModal = this.closePasswordModal.bind(this);
        this.syncGoogleCalendar = this.syncGoogleCalendar.bind(this);
        this.syncOutlookCalendar = this.syncOutlookCalendar.bind(this);
        this.connectStripe = this.connectStripe.bind(this);
        this.handleEnable = this.handleEnable.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { profile } = props;
        if (profile?.hospital?.languages) {
            state.hospitalLanguages = profile.hospital.languages;
        }
        if (state.formDirty == false) {
            if (props.auth.isAuthenticated && profile && props.auth.user) {
                if (profile.isSignUpWizardCompleted) {
                    state.isSignUpWizardCompleted = profile.isSignUpWizardCompleted;
                    state.wizard = false;
                } else if (!state.firstLoad) {
                    state.isSignUpWizardCompleted = false;
                    state.wizard = true;
                }
                state.firstLoad = false;
                if (profile.firstName) {
                    state.firstName.value = profile.firstName;
                    state.fixedFirstName = profile.firstName;
                }
                if (profile.lastName) {
                    state.lastName.value = profile.lastName;
                    state.fixedLastName = profile.lastName;
                }
                if (profile.dob) {
                    state.dob.value = profile.dob;
                }

                if (profile.countryCode) {
                    state.countryCode.value = profile.countryCode;
                }
                if (profile.phoneNo) {
                    state.phoneNo.value = profile.phoneNo;
                }
                if (profile.featured) {
                    state.featured = profile.featured;
                }
                if (profile.profileImage) {
                    state.profileImage = profile.profileImage;
                }
                if (profile.coverImage) {
                    state.coverImage = profile.coverImage;
                }

                if (profile.email) {
                    state.email.value = profile.email;
                }
                if (profile.currency) {
                    state.currency.value = profile.currency;
                }
                if (profile.appointmentBlock) {
                    state.appointmentBlock.value = profile.appointmentBlock;
                }
                if (profile.social) {
                    if (profile.social.facebook) {
                        state.social.facebook = profile.social.facebook;
                    }
                    if (profile.social.twitter) {
                        state.social.twitter = profile.social.twitter;
                    }
                    if (profile.social.instagram) {
                        state.social.instagram = profile.social.instagram;
                    }
                    if (profile.social.linkedin) {
                        state.social.linkedin = profile.social.linkedin;
                    }
                }
                if (profile.workType) {
                    state.workType = profile.workType;
                }
                if (profile.gender) {
                    state.gender.value = profile.gender;
                    if (profile.gender == 'Male') {
                        state.genderPlaceholderImg = malePlaceholder;
                    } else {
                        state.genderPlaceholderImg = femalePlaceholder;
                    }
                }
                if (profile.languages && profile.languages.length > 0) {
                    state.languages.value = profile.languages;
                }
                else {
                    if (localStorage.getItem('localityCountryShort') === 'BD') {
                        state.languages.value = ['English', 'Bengali'];
                  
                    }
                }
                if (profile.currency) {
                    state.currency.value = profile.currency;
                }
                else {
                    if (localStorage.getItem('localityCountryShort') === 'BD') {
                        state.currency.value = 'BDT';
                    }
                }
                if (profile.profileCompletion) {
                    state.profileCompletion = profile.profileCompletion;
                }

                if (profile.practiceAreas) {
                    state.practiceAreas = profile.practiceAreas;
                }
                if (profile.specializedRoles) {
                    state.specializedRoles = profile.specializedRoles;
                }

                // if (profile.coverImage) {
                //   state.coverImage = profile.saveCoverImage;
                //   state.tempCoverImage = profile.coverImage;
                // }
                // if (profile.profileImage) {
                //   state.profileImage = profile.profileImage;
                //   state.tempProfileImage = profile.profileImage;
                // }

                if (!state.formImageDirty) {
                    if (profile.coverImage) {
                        state.coverImage = profile.coverImage;
                    }
                }

                if (!state.formDPDirty) {
                    if (profile.profileImage) {
                        state.profileImage = profile.profileImage;
                    }
                }

                if (profile.profileSummary) {
                    state.profileSummary.value = profile.profileSummary;
                }

                if (profile.chatInputs && !state.formDirty) {
                    state.chatInputs = profile.chatInputs;
                }

                if (profile.allowChat && !state.formDirty) {
                    state.allowChat = profile.allowChat;
                }
                state.syncGoogleCalendar = profile.syncGoogleCalendar ? true : false;
                state.syncOutlookCalendar = profile.syncOutlookCalendar ? true : false;

                if (profile.contact && profile.contact.billing) {
                    state.billingAddress.value = profile.contact.billing.billingAddress;
                    state.billingCity.value = profile.contact.billing.billingCity;
                    state.billingZip.value = profile.contact.billing.billingZip;
                    state.billingCountry.value = profile.contact.billing.billingCountry;
                    state.billingState.value = profile.contact.billing.billingState;
                    state.contactEmail.value = profile.contact.email;
                    state.contactPhone.value = profile.contact.phoneNo;
                    state.contactCountryCode.value = profile.contact.countryCode;
                    state.billingEmail.value = profile.contact.billing?.billingEmail;
                    state.billingPhone.value = profile.contact.billing?.billingPhone;
                    state.billingCountryCode.value = profile.contact.billing?.billingCountryCode;
                    if (profile.contact.billing.billingCoordinates) {
                        state.billingCoordinates = [
                            profile.contact.billing.billingCoordinates.lat,
                            profile.contact.billing.billingCoordinates.lng
                        ];
                    }
                }
            }
        }
        return state;
    }

    closePasswordModal() {
        this.setState({
            passwordModal: false
        });
    }

    deactivateAccount = async () => {
        if (this.state.deactivateAccountFlag) {
            let obj = {
                userId: this.props.auth.userId,
                userType: this.props.auth.userType
            };
            let deactivate = await this.props.actions.deactivateAccount(
                { ...this.props, obj },
                this.props.history
            );
            if (deactivate) {
                this.setState({ deactivateAccountConfirmModal: false });
                setTimeout(async () => {
                    let logout = await this.props.actions.logout(this.props.history);
                }, [2000]);
            }
        } else {
            this.setState({ deactivateAccountConfirmModal: true });
        }
    };

    handleInputChange = (e, index) => {
        let state = this.state;
        const values = [...state.chatInputs];
        values[index][e.target.name] = e.target.value;
        state['chatInputs'] = values;
        state.formDirty = true;
        this.setState(state);
    };

    handleAddInput = () => {
        let state = this.state;
        if (state.chatInputs.length < 3) {
            const values = [...state.chatInputs, { short: '', message: '' }];
            state['chatInputs'] = values;
        }
        state.formDirty = true;
        this.setState(state);
    };
    handleRemoveInput = async (index) => {
        let state = this.state;

        const values = [...state.chatInputs];
        values.splice(index, 1);
        state['chatInputs'] = values;
        state.formDirty = true;
        this.setState(state);
    };

    handleDisable(e) {
        let state = this.state;
        if (e === 'hospitalName') {
            state.disbaleLawhospitalName = !state.disbaleLawhospitalName;
        }
        if (e === 'lawfirmRegNo') {
            state.disableRegNo = !state.disableRegNo;
        }
        if (e === 'phoneNo') {
            state.disablePhone = !state.disablePhone;
        }
        if (e === 'email') {
            state.disableEmail = !state.disableEmail;
        }
        if (e === 'password') {
            state.disablePass = !state.disablePass;
        }

        if (e === 'disableAll') {
            state.disbaleLawhospitalName = true;
            state.disableRegNo = true;
            state.disablePhone = true;
            state.disableEmail = true;
            state.disablePass = true;
        }
        this.setState(state);
    }
    componentDidMount() {
        let profileDate = {
            userId: this.props.auth.user._id,
            userType: this.props.auth.user.userType
        };
        this.props.actions.getProfile(profileDate, this.props.history);
    }
    saveCoverImage = async () => {
        if (this.state.coverImage && this.state.tempCoverImage) {
            let obj = new FormData();
            obj.append('userId', this.props.auth.user._id);
            obj.append('image', this.state.coverImage);
            obj.append('profileImage', false);
            obj.append('coverImage', true);

            let upload = await this.props.actions.saveProfileImageOrCoverImage(
                { obj, ...this.props },
                this.props.history
            );

            if (upload) {
                this.setState({ tempCoverImage: null });
                let profileShort = {
                    userId: this.props.auth.user._id,
                    userType: this.props.auth.user.userType
                };
                await this.props.actions.getProfile(profileShort, this.props);
            }
        }
    };
    async deleteCoverImage() {
        let obj = {
            userId: this.props.auth && this.props.auth.user && this.props.auth.user._id,
            coverImage: ''
        };
        let deleteImage = await this.props.actions.deleteCoverImage(
            { ...this.props, obj },
            this.props.history
        );
        if (deleteImage) {
            let profileShort = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            await this.props.actions.getProfile(profileShort, this.props);
            this.setState({
                deleteCover: false,
                formImageDirty: false,
                coverImage: '',
                tempCoverImage: ''
            });
        }
    }

    async clearCoverImage() {
        this.setState({
            tempCoverImage: null,
            coverImage: null,
            formImageDirty: false
        });
    }

    async deleteProfileImage() {
        let obj = {
            userId: this.props.auth && this.props.auth.user && this.props.auth.user._id,
            coverImage: ''
        };
        let deleteImage = await this.props.actions.deleteProfileImage(
            { ...this.props, obj },
            this.props.history
        );
        if (deleteImage) {
            let profileShort = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            this.setState({
                deleteProfileImage: false,
                formImageDirty: false
            });
            await this.props.actions.getProfile(profileShort, this.props);
            this.setState({
                formImageDirty: false,
                profileImage: '',
                tempProfileImage: '',
                formDPDirty: false
            });
        }
    }

    saveProfileImage = async () => {
        if (this.state.profileImage) {
            let obj = new FormData();
            obj.append('userId', this.props.auth.user._id);
            obj.append('image', this.state.profileImage);
            obj.append('profileImage', true);
            obj.append('coverImage', false);

            let upload = await this.props.actions.saveProfileImageOrCoverImage(
                { obj, ...this.props },
                this.props.history
            );

            if (upload) {
                this.setState({ tempProfileImage: null });
                let profileShort = {
                    userId: this.props.auth.user._id,
                    userType: this.props.auth.user.userType
                };
                await this.props.actions.getProfile(profileShort, this.props);
                this.setState({
                    formDPDirty: false
                });
            }
        }
    };
    onChange(e) {
        var state = this.state;
        state.formDirty = true;
        state[e.target.name].value = e.target.value;
        if (e.target.name == 'firstName') {
            if (e.target.value == '') {
                state['firstName'].isValid = false;
                state['firstName'].message = 'First name cannot be left empty';
            } else {
                state['firstName'].isValid = true;
                state['firstName'].message = '';
            }
        }

        if (e.target.name == 'lastName') {
            if (e.target.value == '') {
                state['lastName'].isValid = false;
                state['lastName'].message = 'First name cannot be left empty';
            } else {
                state['lastName'].isValid = true;
                state['lastName'].message = '';
            }
        }

        if (e.target.name == 'email') {
            if (e.target.value == '') {
                state['email'].isValid = false;
                state['email'].message = 'Email cannot be left empty';
            } else if (!validator.isEmail(e.target.value)) {
                state['email'].isValid = false;
                state['email'].message = 'Not a valid email address';
            } else {
                state['email'].isValid = true;
                state['email'].message = '';
            }
        }

        if (e.target.name == 'phoneNo') {
            if (e.target.value == '') {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number cannot be left empty';
            } else if (e.target.value.length !== 10) {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number must be 10 digits';
            } else if (!e.target.value.match(/^\d+$/)) {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number must only contain numbers';
            } else {
                state['phoneNo'].isValid = true;
                state['phoneNo'].message = '';
            }
        }

        if (e.target.name == 'contactPhone') {
            if (e.target.value == '') {
                state['contactPhone'].isValid = false;
                state['contactPhone'].message = 'Phone number cannot be left empty';
            } else if (e.target.value.length !== 10) {
                state['contactPhone'].isValid = false;
                state['contactPhone'].message = 'Phone number must be 10 digits';
            } else if (!e.target.value.match(/^\d+$/)) {
                state['contactPhone'].isValid = false;
                state['contactPhone'].message = 'Phone number must only contain numbers';
            } else {
                state['contactPhone'].isValid = true;
                state['contactPhone'].message = '';
            }
        }

        if (e.target.name == 'contactEmail') {
            if (e.target.value == '') {
                state['contactEmail'].isValid = false;
                state['contactEmail'].message = 'Email cannot be left empty';
            } else if (!validator.isEmail(e.target.value)) {
                state['contactEmail'].isValid = false;
                state['contactEmail'].message = 'Not a valid email address';
            } else {
                state['contactEmail'].isValid = true;
                state['contactEmail'].message = '';
            }
        }

        if (e.target.name == 'countryCode') {
            if (e.target.value == '') {
                state['countryCode'].isValid = false;
                state['countryCode'].message = 'Country code cannot be left empty';
            }

            if (!e.target.value.match(/^\d+$/)) {
                state['countryCode'].isValid = false;
                state['countryCode'].message = 'Country code must only contain numbers';
            }
        }

        if (e.target.name == 'contactCountryCode') {
            if (e.target.value == '') {
                state['contactCountryCode'].isValid = false;
                state['contactCountryCode'].message = 'Country code cannot be left empty';
            }

            if (!e.target.value.match(/^\d+$/)) {
                state['contactCountryCode'].isValid = false;
                state['contactCountryCode'].message = 'Country code must only contain numbers';
            }
        }

        if (e.target.name == 'billingCountryCode') {
            if (e.target.value == '') {
                state['billingCountryCode'].isValid = false;
                state['billingCountryCode'].message = 'Country code cannot be left empty';
            }

            if (!e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state['billingCountryCode'].isValid = false;
                state['billingCountryCode'].message = 'Country code must only contain numbers';
            }
            if (e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state['billingCountryCode'].isValid = true;
                state['billingCountryCode'].message = '';
            }
        }

        if (e.target.name == 'billingPhone') {
            if (e.target.value == '') {
                state['billingPhone'].isValid = false;
                state['billingPhone'].message = 'Phone number cannot be left empty';
            } else if (e.target.value.length !== 10) {
                state['billingPhone'].isValid = false;
                state['billingPhone'].message = 'Phone number must be 10 digits';
            } else if (!e.target.value.match(/^\d+$/)) {
                state['billingPhone'].isValid = false;
                state['billingPhone'].message = 'Phone number must only contain numbers';
            } else {
                state['billingPhone'].isValid = true;
                state['billingPhone'].message = '';
            }
        }
        if (e.target.name == 'billingEmail') {
            if (e.target.value == '') {
                state['billingEmail'].isValid = false;
                state['billingEmail'].message = 'Email cannot be left empty';
            } else if (!validator.isEmail(e.target.value)) {
                state['billingEmail'].isValid = false;
                state['billingEmail'].message = 'Not a valid email address';
            } else {
                state['billingEmail'].isValid = true;
                state['billingEmail'].message = '';
            }
        }

        this.setState(state);
    }

    deleteAddressInfo(index) {
        let ind = index;
        let arr = this.state.deliveryLocationInfo;
        let tempAddressInfo = this.state.deliveryLocationInfo;

        if (tempAddressInfo && tempAddressInfo.length > 1) {
            let newTemp = tempAddressInfo.splice(index, 1);
            this.setState({
                formDirty: true,
                deliveryLocationInfo: tempAddressInfo
            });
        }
    }

    changeAddressInfo(e, index) {
        let temp = this.state.deliveryLocationInfo;
        let name = e.target.name;
        if (name == 'city') {
            temp[index].city = e.target.value;
        }
        if (name == 'state') {
            temp[index].state = e.target.value;
        }
        if (name == 'zip') {
            temp[index].zip = e.target.value;
        }
        if (name == 'address') {
            temp[index].address = e.target.value;
        }

        this.setState({ deliveryLocationInfo: temp });
    }
    onChangeDate(date, dateString) {
        this.setState({
            dob: { value: dateString, isValid: true, message: '' },
            formDirty: true
        });
    }
    handleEnable(e) {
        console.log(e);
        let state = this.state;
        if (e === 'phoneNo') {
            state.disablePhone = false;
        }
        if (e === 'email') {
            state.disableEmail = false;
        }
        if (e === 'password') {
            state.disablePass = false;
        }
        this.setState(state);
    }
    componentDidMount() {
        this.props.actions.getProductTypes(this.props, this.props.history);
    }
    saveProfile = async (e) => {
        let state = this.state;
        let valid = true;
        console.log('got here', state.languages);
        if (!state.firstName.value) {
            state.firstName.isValid = false;
            state.firstName.message = 'Field is required';
            valid = false;
        }
        if (!state.lastName.value) {
            state.lastName.isValid = false;
            state.lastName.message = 'Field is required';
            valid = false;
        }
        if (!state.dob.value) {
            state.dob.isValid = false;
            state.dob.message = 'Field is required';
            valid = false;
        }

        if (!state.profileSummary.value) {
            state.profileSummary.isValid = false;
            state.profileSummary.message = 'Field is required';
            valid = false;
        }
        if (!state.gender.value) {
            state.gender.isValid = false;
            state.gender.message = 'Field is required';
            valid = false;
        }

        if (!state.languages.value.length) {
            state.languages.isValid = false;
            state.languages.message = 'Field is required';
            valid = false;
        }
        if (!state.gender.value) {
            state.gender.isValid = false;
            state.gender.message = 'Field is required';
            valid = false;
        }
        if (!state.currency.value) {
            state.currency.isValid = false;
            state.currency.message = 'Field is required';
            valid = false;
        }
        if (!state.dob.value) {
            state.dob.isValid = false;
            state.dob.message = 'Field is required';
            valid = false;
        }
        if (valid == false) {
            message.error('Fill up the required fields.');
        }
        this.setState(state);

        if (
            state.firstName.isValid &&
            state.lastName.isValid &&
            state.firstName.value !== '' &&
            state.lastName.value !== '' &&
            state.gender.value != '' &&
            state.gender.isValid &&
            state.currency.isValid &&
            state.currency.value != '' &&
            state.languages.isValid &&
            state.languages.value.length != 0 &&
            state.profileSummary.value != '' &&
            state.profileSummary.isValid &&
            state.dob.isValid &&
            state.dob.value != ''
        ) {
            let profileObj = {
                data: {
                    firstName: state.firstName.value,
                    lastName: state.lastName.value,
                    gender: state.gender.value,
                    dob: state.dob.value,
                    profileSummary: state.profileSummary.value,
                    languages: state.languages.value,
                    allowChat: state.allowChat,
                    phoneNo: state.phoneNo.value,
                    currency: state.currency.value,
                    appointmentBlock: state.appointmentBlock.value,
                    featured: state.featured,
                    countryCode:
                        state.countryCode.value != ''
                            ? state.countryCode.value.replace('+', '')
                            : null,
                    social: {
                        ...state.social
                    },
                    chatInputs: state.chatInputs,
                    contact: {
                        email: state.contactEmail.value,
                        phoneNo: state.contactPhone.value,
                        countryCode: state.contactCountryCode.value,
                        billing: {
                            billingAddress: state.billingAddress.value,
                            billingCity: state.billingCity.value,
                            billingState: state.billingState.value,
                            billingZip: state.billingZip.value,
                            billingCountry: state.billingCountry.value,
                            billingEmail: state.billingEmail.value,
                            billingPhone: state.billingPhone.value,
                            billingCountryCode: state.billingCountryCode.value,
                            billingCoordinates: {
                                lat: state.billingCoordinates[0],
                                lng: state.billingCoordinates[1]
                            }
                        }
                    }
                },
                userType: this.props.auth.user.userType,
                userId: this.props.auth.user._id
            };

            if (!valid == false) {
                await this.props.actions.saveProfile(profileObj, this.props.history);
                this.setState({ formDirty: false });

                let profileShort = {
                    userId: this.props.auth.user._id,
                    userType: this.props.auth.user.userType
                };
                await this.props.actions.getProfile(profileShort, this.props.history);
            } else {
                message.error('Fill up required fields!');
            }
        }
    };

    async updateBillingAddress(addr) {
        this.setState({ formDirty: true });
        var placeSearch, autocomplete;
        var componentForm = {
            street_number: '',
            route: '',
            postal_town: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            formattedAddress: '',
            neighbourhood: ''
        };
        let address = await geocodeByAddress(addr);

        address = address[0];
        componentForm.formattedAddress = address.formatted_address;
        for (var i = 0; i < address.address_components.length; i++) {
            var addressType = address.address_components[i].types[0];

            if (componentForm[addressType] == '') {
                let val = address.address_components[i][componentForm[addressType]];
                componentForm[addressType] = address.address_components[i].long_name;
            }
        }

        let state = this.state;
        if (address && address.geometry) {
            state.billingCoordinates = [
                address.geometry.location.lat(),
                address.geometry.location.lng()
            ];
        }

        if (componentForm.country !== '')
            state.billingCountry = {
                value: componentForm.country,
                isValid: true,
                message: ''
            };
        // debugger
        if (componentForm.postal_code !== '')
            state.billingZip = {
                value: componentForm.postal_code,
                isValid: true,
                message: ''
            };
        state.billingCity = {
            value:
                componentForm.postal_town != 'Town' && componentForm.postal_town
                    ? componentForm.postal_town
                    : componentForm.locality != 'locality'
                    ? componentForm.locality
                    : '',
            isValid: true,
            message: ''
        };
        state.billingState = {
            value:
                componentForm.administrative_area_level_1 != 'State '
                    ? componentForm.administrative_area_level_1
                    : '',
            isValid: true,
            message: ''
        };

        if (componentForm.street_number && componentForm.route) {
            state.billingAddress = {
                value: componentForm.street_number + ' ' + componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.route) {
            state.billingAddress = {
                value: componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.formattedAddress) {
            state.billingAddress = {
                value: componentForm.formattedAddress,
                isValid: true,
                message: ''
            };
        }

        this.setState(state);
    }
    setWizard = async (data) => {
        this.setState({
            wizard: data
        });
    };
    async syncGoogleCalendar(checked) {
        if (checked) {
            let getAuthUrl = await this.props.actions.getGoogleAuthorizationUrl(
                {},
                this.props.history
            );
        } else {
            let obj = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            let unsync = await this.props.actions.unsyncGoogleCalendar(
                { ...this.props, obj },
                this.props.history
            );
            if (unsync) {
                this.props.actions.getProfile(obj, this.props.history);
            }
        }
    }
    async syncOutlookCalendar(checked) {
        if (checked) {
            let getAuthUrl = await this.props.actions.getOutlookAuthorizationUrl(
                {},
                this.props.history
            );
        } else {
            let obj = {
                userId: this.props.auth.user._id,
                userType: this.props.auth.user.userType
            };
            let unsync = await this.props.actions.unsyncOutlookCalendar(
                { ...this.props, obj },
                this.props.history
            );
            if (unsync) {
                this.props.actions.getProfile(obj, this.props.history);
            }
        }
    }

    async connectStripe() {
        this.setState({ stripeLoading: true });
        let obj = {};
        const { user } = this.props.auth;

        obj.countryCode = user?.countryCode
            ? countries.all.filter(
                  (countryObj) => countryObj.countryCallingCodes[0] === user?.countryCode
              )[0]?.alpha2
            : 'US';
        let { url } = await this.props.stripeActions.setupStripeAccount({ obj });
        this.setState({ stripeLoading: false });
        if (url) {
            window.location.assign(url);
        }
    }
    render() {
        const dateFormat = 'YYYY/MM/DD';
        const locationFields = [
            { field: 'billingAddress', label: 'Billing Address' },
            { field: 'billingCountry', label: 'Billing Country' },
            { field: 'billingCity', label: 'Billing City' },
            { field: 'billingState', label: 'Billing State' },
            { field: 'billingZip', label: 'Billing Zip' }
        ];
        const { profile } = this.props;
        return (
            <div className=' user-profile'>
                <CoverImage
                    savedCoverImage={profile.coverImage}
                    tempCoverImage={this.state.tempCoverImage}
                    coverImage={this.state.coverImage}
                    formImageDirty={this.state.formImageDirty}
                    setState={(obj) => {
                        this.setState(obj);
                    }}
                    clearCoverImage={this.clearCoverImage}
                    deleteCoverImage={this.deleteCoverImage}
                    saveCoverImage={this.saveCoverImage}
                />
                <ProfileImage
                    savedProfileImage={profile.profileImage}
                    tempProfileImage={this.state.tempProfileImage}
                    profileImage={this.state.profileImage}
                    genderPlaceholderImg={this.state.genderPlaceholderImg}
                    formDPDirty={this.state.formDPDirty}
                    setState={(obj) => {
                        this.setState(obj);
                    }}
                    saveProfileImage={this.saveProfileImage}
                    deleteProfileImage={this.deleteProfileImage}
                />
                {this.props.auth && this.props.auth.profileError && (
                    <Alert
                        style={{ marginTop: 40, padding: '10px 24px' }}
                        type='error'
                        description={this.props.auth.profileError}
                    />
                )}
                <div className='viewPublicProfile'>
                    <button
                        className=''
                        onClick={() =>
                            this.props.history.push({
                                pathname: `/doctor-view/${profile._id}`,
                                state: {
                                    prevPath: 'profile'
                                }
                            })
                        }
                    >
                        View Public Profile
                    </button>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 20 }}>
                        <ProfileSection title={'Account Holder Information'}>
                            <div className='input-row'>
                                <Input
                                    value={this.state.firstName?.value}
                                    label='First Name'
                                    item={this.state.firstName}
                                    name='firstName'
                                    placeholder='First Name'
                                    type='text'
                                    onChange={this.onChange}
                                    required
                                />

                                <Input
                                    label='Last Name'
                                    value={this.state.lastName?.value}
                                    item={this.state.lastName}
                                    name='lastName'
                                    placeholder='Last Name'
                                    type='text'
                                    onChange={this.onChange}
                                    required
                                />
                            </div>
                            <div className='input-row'>
                                <label className={!this.state.dob.isValid ? 'error' : ''}>
                                    <div>
                                        Date of Birth<span style={{ color: 'red' }}>*</span>:
                                    </div>
                                    <DatePicker
                                        name='dob'
                                        placeholder='Date of Birth'
                                        value={
                                            this.state.dob.value
                                                ? moment(this.state.dob.value, dateFormat)
                                                : ''
                                        }
                                        onChange={this.onChangeDate.bind(this)}
                                    />
                                    {this.state.dob.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.dob.message}
                                        </p>
                                    )}
                                </label>
                                <SelectComponent
                                    item={this.state.gender}
                                    value={this.state.gender.value}
                                    isValid={this.state.gender.isValid}
                                    message={this.state.gender.message}
                                    label='Gender'
                                    required
                                    name='gender'
                                    setState={(obj) => {
                                        console.log(obj);
                                        this.setState(obj);
                                    }}
                                    options={genderOptions}
                                />
                            </div>

                            <div className='input-row'>
                                <label>
                                    <div>
                                        I Specialize in<span style={{ color: 'red' }}>*</span>:
                                    </div>
                                    <Select
                                        disabled
                                        mode='multiple'
                                        name='practiceAreas'
                                        placeholder='Practice Areas'
                                        value={
                                            this.state.practiceAreas ? this.state.practiceAreas : []
                                        }
                                    >
                                        {specializations.map((item, index) => (
                                            <Option key={index} value={item.value}>
                                                {item.value}
                                            </Option>
                                        ))}
                                    </Select>
                                </label>
                                <label>
                                    <div>
                                        I am a<span style={{ color: 'red' }}>*</span>:
                                    </div>
                                    <Select
                                        disabled
                                        single
                                        name='specializedRole'
                                        placeholder='Specialized Roles'
                                        value={
                                            this.state.specializedRoles
                                                ? this.state.specializedRoles
                                                : ''
                                        }
                                    >
                                        {options.map((item, index) => (
                                            <Option key={index} value={item.value}>
                                                {item.value}
                                            </Option>
                                        ))}
                                    </Select>
                                </label>
                            </div>

                            <div className='input-row'>
                                <SelectComponent
                                    item={this.state.languages}
                                    value={this.state.languages.value}
                                    isValid={this.state.languages.isValid}
                                    message={this.state.languages.message}
                                    label='Languages'
                                    required
                                    name='languages'
                                    mode='multiple'
                                    setState={(obj) => {
                                        console.log(obj);
                                        this.setState(obj);
                                    }}
                                    options={
                                        this.state.hospitalLanguages &&
                                        this.state.hospitalLanguages.length
                                            ? this.state.hospitalLanguages.map((item) => ({
                                                  key: item,
                                                  text: item,
                                                  value: item
                                              }))
                                            : languageOptions
                                    }
                                />
                                <CurrencyDropdown
                                    currency={this.state.currency}
                                    setState={(obj) => this.setState(obj)}
                                    curr={this.curr}
                                    value={this.state.currency.value}
                                />
                            </div>

                            <div className='input-row '>
                                <label
                                    className={` ${
                                        !this.state.profileSummary.isValid ? 'error' : ''
                                    } textareaLabel`}
                                >
                                    <div>
                                        Profile Summary<span style={{ color: 'red' }}>*</span>:
                                    </div>
                                    <textarea
                                        placeholder=''
                                        value={this.state.profileSummary.value}
                                        onChange={(e) =>
                                            this.setState({
                                                formDirty: true,
                                                profileSummary: {
                                                    ...this.state.profileSummary,
                                                    value: e.target.value,
                                                    isValid: true,
                                                    message: ''
                                                }
                                            })
                                        }
                                    />
                                    {this.state.profileSummary.message && (
                                        <p>
                                            {' '}
                                            <i className='fe fe-alert-triangle' />{' '}
                                            {this.state.profileSummary.message}
                                        </p>
                                    )}
                                </label>
                            </div>
                        </ProfileSection>

                        <div className='dividerL'></div>
                        <ProfileSection title={'Billing Information'}>
                            <div className='input-row'>
                                <CountryCodeDropdown
                                    countryDrop={this.state.countryDrop}
                                    setState={(obj) => this.setState(obj)}
                                    countryCode={this.state.billingCountryCode}
                                    value={this.state.billingCountryCode?.value}
                                    stateValue={'billingCountryCode'}
                                />

                                <Input
                                    label='Phone No'
                                    value={this.state.billingPhone.value}
                                    item={this.state.billingPhone}
                                    name='billingPhone'
                                    placeholder='Phone No.'
                                    type='text'
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className='input-row'>
                                <Input
                                    label='Email Address'
                                    value={this.state.billingEmail.value}
                                    item={this.state.billingEmail}
                                    name='billingEmail'
                                    placeholder='Email address'
                                    type='text'
                                    onChange={this.onChange}
                                />

                                <label>
                                    <div
                                        style={{ display: 'flex', justifyContent: 'space-between' }}
                                    >
                                        <div>Billing Location:</div>
                                        <Tooltip
                                            placement='topLeft'
                                            title={`You can use the search function to locate and choose your address`}
                                        >
                                            <span
                                                style={{
                                                    display: 'flex',
                                                    height: 14,
                                                    width: 14,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    borderRadius: 8,
                                                    fontSize: 11,
                                                    backgroundColor: 'lavender'
                                                }}
                                            >
                                                i
                                            </span>
                                        </Tooltip>
                                    </div>
                                    <Geolocate
                                        icon={<i className='fa fa-map-marker' />}
                                        chooseAddress={(address) =>
                                            this.updateBillingAddress(address)
                                        }
                                    />
                                </label>
                            </div>
                            <div className='input-row two-part'>
                                {locationFields?.slice(0, 2).map((item, index) => (
                                    <Input
                                        key={`${index}-${item?.field}`}
                                        className={
                                            !this.state[item.field].isValid
                                                ? 'error one-half'
                                                : 'one-half'
                                        }
                                        label={item.label}
                                        required
                                        item={this.state[item.field]}
                                        name={item.field}
                                        placeholder={item.label}
                                        type='text'
                                        value={this.state[item.field]?.value}
                                        onChange={this.onChange}
                                    />
                                ))}
                            </div>

                            <div className='input-row three-part'>
                                {locationFields?.slice(2, 5).map((item, index) => (
                                    <Input
                                        key={`${index}-${item?.field}`}
                                        className={
                                            !this.state[item?.field].isValid
                                                ? 'error one-half'
                                                : 'one-third'
                                        }
                                        required
                                        label={item?.label}
                                        item={this.state[item?.field]}
                                        name={item?.field}
                                        placeholder={item?.label}
                                        type='text'
                                        value={this.state[item?.field]?.value}
                                        onChange={this.onChange}
                                    />
                                ))}
                            </div>
                        </ProfileSection>
                        <div className='dividerL'></div>
                        <ProfileSection title='Appointment Settings'>
                            <div className='input-row'>
                                <Input
                                    style={{ width: '100%' }}
                                    label='Email Address'
                                    value={this.state.contactEmail.value}
                                    item={this.state.contactEmail}
                                    name='contactEmail'
                                    placeholder='Email address'
                                    type='text'
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className='input-row'>
                                <CountryCodeDropdown
                                    countryDrop={this.state.countryDrop}
                                    setState={(obj) => this.setState(obj)}
                                    countryCode={this.state.contactCountryCode}
                                    value={this.state.contactCountryCode?.value}
                                    stateValue={'contactCountryCode'}
                                />

                                <Input
                                    label='Phone No'
                                    value={this.state.contactPhone.value}
                                    item={this.state.contactPhone}
                                    name='contactPhone'
                                    placeholder='Phone No.'
                                    type='text'
                                    onChange={this.onChange}
                                />
                            </div>
                            <div
                                className='user-Chats2'
                                style={{ marginTop: 20, marginBottom: 20 }}
                            >
                                <p style={{ fontWeight: 'bold' }}>Select Appointment Blocks</p>
                                <div className='user-chat-option'>
                                    <p>Select minimum appointment block</p>
                                    <div className='user-switch'>
                                        <div
                                            className='user-block-grid'
                                            style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                gap: '1rem'
                                            }}
                                        >
                                            <Select
                                                value={this.state.appointmentBlock.value}
                                                onChange={(val) =>
                                                    this.setState({
                                                        appointmentBlock: {
                                                            value: val,
                                                            message: '',
                                                            isValid: true
                                                        },
                                                        formDirty: true
                                                    })
                                                }
                                                style={{ width: '10rem' }}
                                                placeholder='Select'
                                            >
                                                <Option value='15'>15 min</Option>
                                                <Option value='30'>30 min</Option>
                                                <Option value='60'>60 min</Option>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ProfileSection>
                        <div className='dividerL'></div>
                        <SocialSection
                            social={this.state.social}
                            setState={(obj) => this.setState(obj)}
                        />

                        <div className='dividerL'></div>

                        <ProfileSection title={'Hospital Settings'}>
                            <div className='input-row2'>
                                <div className='user-Chats'>
                                    <p>Allow Chat Messaging</p>
                                    <div className='user-chat-option'>
                                        <p>Enable Chat messaging between logged in users</p>
                                        <div className='user-switch'>
                                            <Switch
                                                checked={this.state.allowChat}
                                                onChange={(checked) =>
                                                    this.setState({
                                                        allowChat: checked,
                                                        formDirty: true
                                                    })
                                                }
                                            />
                                            {this.state.allowChat ? (
                                                <p>Enabled</p>
                                            ) : (
                                                <p>Disabled</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {this.state.allowChat && (
                                    <div className='user-Chats'>
                                        <div
                                            onClick={() => this.handleAddInput()}
                                            className='chats-add'
                                        >
                                            <div className='chats-add-legend'>
                                                <p>Add a custom reply</p>
                                                <sup
                                                    style={{
                                                        fontStyle: 'italic',
                                                        fontSize: 11,
                                                        marginLeft: 5,
                                                        fontFamily: "'Inter Var'"
                                                    }}
                                                >
                                                    <i
                                                        className='fe fe-info'
                                                        onMouseOver={() => {
                                                            this.setState({ toolTip: true });
                                                        }}
                                                        onMouseOut={() =>
                                                            this.setState({ toolTip: false })
                                                        }
                                                    />
                                                    {this.state.toolTip && (
                                                        <div className='infoToolTip'>
                                                            <span>
                                                                (You cannot add more than three
                                                                forms)
                                                            </span>
                                                        </div>
                                                    )}
                                                    {/* (This information will be displayed on your profile) */}
                                                </sup>
                                            </div>
                                            <AiOutlinePlus />
                                        </div>

                                        {this.state.chatInputs &&
                                            this.state.chatInputs.map((input, index) => (
                                                <div key={index} className='lexp-inputs'>
                                                    <div className='lexp-input-conatiner3'>
                                                        <input
                                                            autoComplete='sdhfjsd'
                                                            className={
                                                                this.state.chatInputs[index][
                                                                    'short'
                                                                ] !== ''
                                                                    ? 'dirty'
                                                                    : 'sdsd'
                                                            }
                                                            onChange={(e) =>
                                                                this.handleInputChange(e, index)
                                                            }
                                                            name='short'
                                                            value={
                                                                this.state.chatInputs[index].short
                                                            }
                                                        />
                                                        <span className='lexp-placeholder'>
                                                            {' '}
                                                            Short Form
                                                        </span>
                                                    </div>
                                                    <div className='lexp-input-conatiner4'>
                                                        <input
                                                            autoComplete='sdhfjsd'
                                                            className={
                                                                this.state.chatInputs[index][
                                                                    'message'
                                                                ] !== ''
                                                                    ? 'dirty'
                                                                    : 'sdsd'
                                                            }
                                                            onChange={(e) =>
                                                                this.handleInputChange(e, index)
                                                            }
                                                            name='message'
                                                            value={
                                                                this.state.chatInputs[index].message
                                                            }
                                                        />
                                                        <span className='lexp-placeholder'>
                                                            {' '}
                                                            Message
                                                        </span>
                                                    </div>

                                                    <IoCloseCircleOutline
                                                        style={{ color: 'red', fontSize: 24 }}
                                                        onClick={() =>
                                                            this.handleRemoveInput(index)
                                                        }
                                                    />
                                                </div>
                                            ))}
                                    </div>
                                )}
                                <div className='user-Chats'>
                                    <p>Enable Outlook Connection</p>
                                    <div className='user-chat-option'>
                                        <p>Suncronized Appointments with Microsoft Outlook</p>
                                        <div className='user-switch'>
                                            <Switch
                                                checked={this.state.syncOutlookCalendar}
                                                onChange={(checked) =>
                                                    this.syncOutlookCalendar(checked)
                                                }
                                            />
                                            {this.state.syncOutlookCalendar ? (
                                                <p>Enabled</p>
                                            ) : (
                                                <p>Disabled</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='user-Chats'>
                                    <p>Enable Google Calendar</p>
                                    <div className='user-chat-option'>
                                        <p>Syncronized Appointments with Google Calendar</p>
                                        <div className='user-switch'>
                                            <Switch
                                                checked={this.state.syncGoogleCalendar}
                                                onChange={(checked) =>
                                                    this.syncGoogleCalendar(checked)
                                                }
                                            />
                                            {this.state.syncGoogleCalendar ? (
                                                <p>Enabled</p>
                                            ) : (
                                                <p>Disabled</p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='user-Chats'>
                                    <p>Enable Stripe</p>
                                    <div className='user-chat-option'>
                                        <p>Connect with Stripe Account</p>
                                        <div className='user-switch'>
                                            <Button
                                                type='primary'
                                                onClick={(checked) => this.connectStripe(checked)}
                                            >
                                                {this.state.stripeLoading ? <Spin /> : 'Activate'}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ProfileSection>

                        <div className='dividerL'></div>

                        <AccountSettings
                            disableEmail={this.state.disableEmail}
                            disablePass={this.state.disablePass}
                            disablePhone={this.state.disablePhone}
                            onChange={this.onChange}
                            handleEnable={this.handleEnable}
                            setState={(obj) => this.setState(obj)}
                            saveProfile={this.saveProfile}
                            deactivateAccount={this.deactivateAccount}
                            email={this.state.email}
                            phoneNo={this.state.phoneNo}
                            password={this.state.password}
                            countryCode={this.state.countryCode}
                        />
                        <NavPrompt formDirty={this.state.formDirty} />

                        <ChangePassword
                            passwordModal={this.state.passwordModal}
                            history={this.props.history}
                            closePasswordModal={this.closePasswordModal}
                        />
                        {this.state.deactivateAccountConfirmModal && (
                            <ConfirmModal
                                {...this.props}
                                acceptMethod={(e) =>
                                    this.setState({ deactivateAccountFlag: true }, () =>
                                        this.deactivateAccount()
                                    )
                                }
                                onClose={() =>
                                    this.setState({ deactivateAccountConfirmModal: false })
                                }
                                headerText='Deactivate Account'
                                bodyText='Are you sure you want to deactivate this Account?'
                            />
                        )}

                        <ChangePhoneOrEmail
                            open={!this.state.disableEmail}
                            onClose={this.handleDisable}
                            headerText={'Change Email'}
                            email={this.state.email.value}
                            phoneNo={this.state.password.value}
                            onChange={this.onChange}
                            input1={'New email'}
                            input2={'Password'}
                            type={'email'}
                            history={this.props.history}
                        />
                        <ChangePhoneOrEmail
                            open={!this.state.disablePhone}
                            onClose={this.handleDisable}
                            headerText={'Change Phone Number'}
                            input1={'New Phone Number'}
                            input2={'Password'}
                            type={'phone'}
                            history={this.props.history}
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 20,
                            width: 350,
                            maxWidth: '100%'
                        }}
                    >
                        <ProfileCalendar />
                        <Notifications />
                    </div>
                </div>
                {!this.state.isSignUpWizardCompleted && (
                    <Elements stripe={stripePromise}>
                        <SignUpWIzard
                            {...this.props}
                            wizard={this.state.wizard}
                            setWizard={this.setWizard}
                        />
                    </Elements>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    stripe: state.stripe
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    stripeActions: bindActionCreators(stripeActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(DoctorAccount);
