import React, { useEffect, useState } from 'react';
import { message, Modal } from 'antd';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import bkashLogo from '@/assets/img/svgs/bkash.svg';
import stripeLogo from '@/assets/img/svgs/stripe.png';
import nogodLogo from '@/assets/img/svgs/nogod.png';
import * as appointmentActions from '@/actions/appointmentActions';
import { useDispatch, useSelector } from 'react-redux';
import * as stripeActions from '@/actions/stripeActions';
import { Radio } from 'antd';
import { AiFillCreditCard } from 'react-icons/ai';

function AppointmentPayment(props) {
    const dispatch = useDispatch();
    const { user } = useSelector((state) => state.auth);
    const { paymentMethods } = useSelector((state) => state.stripe);
    const { firstName, lastName, email } = user;
    const [stripeMethod, setStripeMethod] = useState('New Card');
    const elements = useElements();
    const stripe = useStripe();
    const {
        appointmentPaymentModal,
        setAppointmentPaymentModal,
        appointmentPaymentObj,
        setAppointmentPaymentObj,
        setCardElementError,
        setCardElementValid,
        cardElementError,
        cardElementValid
    } = props;

    const iframeStyles = {
        base: {
            color: '#000',
            fontSize: '16px',
            iconColor: '#000',
            '::placeholder': {
                color: 'gray'
            }
        },
        invalid: {
            iconColor: '#f14c48',
            color: '#f14c48'
        },
        complete: {
            iconColor: '#cbf4c9'
        }
    };

    const cardElementOpts = {
        iconStyle: 'solid',
        style: iframeStyles,
        hidePostalCode: true
    };
    const handleCardDetailsChange = (cardElement) => {
        console.log(cardElement);
        if (cardElement.error && cardElement.error.message) {
            setCardElementError(cardElement.error.message);
            setCardElementValid(false);
        } else {
            setCardElementError('');
            setCardElementValid(true);
        }
        if (cardElement.empty) {
            setCardElementValid(false);
            setCardElementError(cardElement.error.message);
        }
        // ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };
    let appointment = appointmentPaymentObj;

    const submitForm = async () => {
        let paymentMethodReq;

        if (stripeMethod == 'New Card') {
            const cardElement = elements.getElement('card');
            // const cardToken = await stripe.createToken(cardElement, {
            //   currency: 'usd'
            // })
            paymentMethodReq = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: firstName + ' ' + lastName,
                    email: email
                }
            });
        } else if (stripeMethod) {
            paymentMethodReq = stripeMethod;
        }
        if (
            // paymentMethodReq &&
            // paymentMethodReq.paymentMethod &&
            (paymentMethodReq?.paymentMethod || stripeMethod != 'New Card') &&
            cardElementValid &&
            !cardElementError
        ) {
            setCardElementError(false);
            setCardElementValid(true);
            let obj = {
                appointmentId: appointment?._id,
                // stripeToken:  paymentMethodReq.paymentMethod.id,
                stripeToken:
                    paymentMethodReq?.paymentMethod?.id ||
                    (stripeMethod != 'New Card' && stripeMethod),
                service: appointment?.service?._id,
                amount: appointment?.service?.rate
            };
            let pay = await dispatch(appointmentActions.payForAppointment({ ...props, obj }));
            if (pay) {
                setAppointmentPaymentModal(false);
                setAppointmentPaymentObj(null);
                let obj = {
                    page: 1,
                    limit: 10
                };
                await appointmentActions.getMyAppointments({
                    ...props,
                    obj
                });
            }
        } else {
            setCardElementError('Billing card is required');
            setCardElementValid(false);
            message.error('Payment Error');
        }
    };

    const getPaymentMethods = async () => {
        let obj = {};
        await dispatch(stripeActions.getCustomerStripePaymentMethods({ ...props, obj }));
    };

    useEffect(() => {
        getPaymentMethods();
    }, []);

    const memberType =
        appointment?.member.userType.slice(0, 1) +
        appointment?.member.userType.slice(1).toLowerCase();
    return (
        <Modal
            className='customModal '
            title={`Pay for Appointment`}
            open={appointmentPaymentModal}
            onCancel={() => {
                setAppointmentPaymentModal(false);
                setAppointmentPaymentObj(null);
            }}
            footer={null}
        >
            {appointment?.hospital && (
                <label style={{ flexDirection: 'row' }}>
                    <strong>Hospital:</strong> {appointment?.hospital?.hospitalName}
                </label>
            )}

            <label style={{ flexDirection: 'row' }}>
                <strong>{memberType}:</strong> {appointment?.member?.firstName}{' '}
                {appointment?.member?.lastName}
            </label>
            <label style={{ flexDirection: 'row' }}>
                <strong>Service:</strong> {appointment?.service?.name}
            </label>
            <p>Payment Amount</p>
            <strong style={{ fontSize: 48 }}>${appointment?.service?.rate}</strong>
            <p>Choose Payment Method</p>
            <div className='paymentMethods'>
                <div className='stripeButton methodBtn active'>
                    <img src={stripeLogo} />
                </div>
                <div className='bkashButton methodBtn'>
                    {' '}
                    <img src={bkashLogo} />
                </div>
                <div className='nogodButton methodBtn'>
                    {' '}
                    <img src={nogodLogo} />
                </div>
            </div>
            <div className='pms'>
                <Radio.Group onChange={(e) => setStripeMethod(e.target.value)} value={stripeMethod}>
                    {paymentMethods?.map((item, index) => (
                        <Radio
                            key={index}
                            value={item.id}
                            className='cardPM'
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                columnGap: '10px'
                            }}
                        >
                            <AiFillCreditCard /> {item?.card?.brand} {item?.card?.last4}{' '}
                            <p> - {item.id}</p>{' '}
                        </Radio>
                    ))}
                    <Radio
                        value={'New Card'}
                        className='cardPM'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '10px'
                        }}
                    >
                        <AiFillCreditCard /> New Card
                    </Radio>
                </Radio.Group>
            </div>
            {stripeMethod == 'New Card' && (
                <label
                    className={`${!cardElementValid ? 'error' : ''} fullLabel paymentStage`}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    {console.log(cardElementValid)}
                    Billing card
                    <div className='stripeCont'>
                        <CardElement options={cardElementOpts} onChange={handleCardDetailsChange} />
                    </div>
                    <p>
                        <i className='fe fe-alert-triangle' />

                        {cardElementError ? cardElementError : 'Billing card is required'}
                    </p>
                </label>
            )}
            {/* <label
        className={`${!cardElementValid ? 'error' : ''} fullLabel paymentStage`}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {console.log(cardElementValid)}
        Billing card
        <div className='stripeCont'>
          <CardElement
            options={cardElementOpts}
            onChange={handleCardDetailsChange}
          />
        </div>
        <p>
          <i className='fe fe-alert-triangle' />

          {cardElementError ? cardElementError : 'Billing card is required'}
        </p>
      </label> */}
            <button
                onClick={() => {
                    submitForm();
                }}
                className='requestApp'
            >
                Pay
            </button>
        </Modal>
    );
}

export default AppointmentPayment;
