import React, { useState } from 'react';
import '../../assets/css/orders.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '../../actions/adminActions';

function StripeDash(props) {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);

    //create user
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userType, setUserType] = useState('ADMIN');
    const [createUserModal, setCreateUserModal] = useState(false);
    const statusOptions = ['ACTIVATED', 'DEACTIVATED', 'SUSPENDED'];
    return (
        <div className='adminTable'>
            <h1>Stripe Dashboard</h1>

            <div className='userCardsRow'>
                <div
                    className='userCard'
                    onClick={() => props.history.push('/admin/stripe/products')}
                >
                    <h3> Products</h3>
                </div>
                <div
                    className='userCard'
                    onClick={() => props.history.push('/admin/stripe/customers')}
                >
                    <h3> Customers</h3>
                </div>
                <div
                    className='userCard'
                    onClick={() => props.history.push('/admin/stripe/subscriptions')}
                >
                    <h3> Subscriptions</h3>
                </div>
                {/* <div className="userCard" >
          <h3> Miscellaneous</h3>
          
        </div> */}
            </div>

            {/* <Pagination
          current={page}
          total={adminUsersCount}
          onChange={(val) => setPage(val)}
          pageSize={limit}
          pageSizeOptions={[5, 10, 20]}
          showSizeChanger={true}
          responsive={true}
          onShowSizeChange={(size, newSize) => setLimit(newSize)}
        /> */}
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        admin: state.admin
    };
}

function mapDispatchToProps(dispatch) {
    return {
        adminActions: bindActionCreators(adminActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(StripeDash);
