import React, { useState } from 'react';
import { guidelines } from '@/assets/json/MedicalDocs';
import DocLayout from './DocLayout';
import DocModalLayout from './DocModalLayout';
import { updateMedicalDocsModal } from '@/actions/generalActions';
import { useDispatch, useSelector } from 'react-redux';
import * as Types from '@/actions/types';


const Guidelines = () => {
    const dispatch = useDispatch();
    const { guidelinesModal } = useSelector((state) => state.general);

    const handleClose = () => {
        dispatch(
            updateMedicalDocsModal({
                type: Types.UPDATE_GUIDELINES_MODAL,
                payload: {
                    guidelinesModal: false
                }
            })
        );
    };

    const handleOk = () => handleClose();

    const handleCancel = () => handleClose();

    return (
        <>
            {guidelinesModal && (
                <DocModalLayout
                    title={'Community Guidelines'}
                    isOpen={guidelinesModal}
                    onCancel={handleCancel}
                    onOk={handleOk}
                    docs={guidelines}
                    lastUpdatedAt={'2023-09-01T00:00:00.000Z'}
                />
            )}
        </>
    );
};

export default Guidelines;
