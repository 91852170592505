import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';

let { REACT_APP_API_URI } = process.env;
if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const getTherapists = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (props && props.obj) {
            Axios.post(`${REACT_APP_API_URI}/api/therapists/getTherapists`, props.obj)
                .then((res) => {
                    if (res) {
                        if (props.obj.featured) {
                            dispatch({
                                type: Types.GET_FEATURED_THERAPISTS,
                                payload: {
                                    therapists: res.data.therapists
                                }
                            });
                        } else {
                            dispatch({
                                type: Types.GET_THERAPISTS,
                                payload: {
                                    therapists: res.data.therapists
                                }
                            });
                        }

                        return resolve(true);
                    }
                })
                .catch((error) => {
                    if (error && error.response) {
                        console.log(error.response.data);
                        // message.error("Therapists Data fetching failed");

                        if (props.obj.featured) {
                            dispatch({
                                type: Types.GET_FEATURED_THERAPISTS,
                                payload: {
                                    therapists: []
                                }
                            });
                        } else {
                            dispatch({
                                type: Types.GET_THERAPISTS,
                                payload: {
                                    therapists: []
                                }
                            });
                        }

                        // message.error(error.response.data.message[0].messages[0].message);
                        return resolve(false);
                    }
                });
        }
    });
};
export const getTherapistById = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/therapists/getTherapistDetail/${props.therapistId}`)
            .then((res) => {
                if (res) {
                    console.log('in here', history);

                    dispatch({
                        type: Types.GET_SINGLE_THERAPIST,
                        payload: {
                            therapistSingle: res.data.therapistSingle
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Therapist Data fetching failed');
                    history.push('/notFound');
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};


export const getTherapistSearchResults = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/therapists/getTherapistSearchResults`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_THERAPIST_SEARCH_RESULTS,
                        payload: {
                            therapists: res.data.therapists,
                            therapistsCount: res.data.therapistsCount
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Therapists retrieval failed');
                    dispatch({
                        type: Types.GET_THERAPIST_SEARCH_RESULTS,
                        payload: {
                            therapists: [],
                            therapistsCount: 0
                        }
                    });
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getTherapistStatCounts = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/therapists/getTherapistStatCounts`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_THERAPIST_STAT_COUNTS,
                        payload: {
                            counts: res.data.counts
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getExtractedTimeSlots = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/therapists/getExtractedTimeSlots`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_THERAPIST_EXTRACTED_TIME_SLOTS,
                        payload: {
                            availableDays: res.data.availableDays,
                            timeSlots: res.data.timeSlots,
                            therapistAvailability: res.data.therapistAvailability
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    // message.error(error.response.data.message? error.response.data.message : "Server error");
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getMyDoctorTimeSlots = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getExtractedTimeSlots`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_MY_DOCTOR_TIME_SLOTS,
                        payload: {
                            timeSlots: res.data.timeSlots,
                            doctorAvailability: res.data.doctorAvailability
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    // message.error(error.response.data.message? error.response.data.message : "Server error");
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getTherapistForAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/therapists/getTherapistForAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_THERAPIST_FOR_APPOINTMENT,
                        payload: {
                            therapist: res.data.therapist
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getShortDoctorList = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/therapists/getShortDoctorList`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_SHORT_DOCTOR_LIST,
                        payload: {
                            doctors: res.data.doctors
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getTherapistDashboardInfo = (props, history) => async (dispatch) => {
    try {
        const response = await Axios.get(`${REACT_APP_API_URI}/api/therapists/getTherapistDashboard`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        });
        dispatch({
            type: Types.GET_THERAPIST_DASHBOARD,
            payload: {
                dashboard: response.data.data
            }
        });
        return true;
    } catch (error) {
        if (error && error.response) {
            console.log(error.response.data);
            message.error(
                error.response.data.message ? error.response.data.message : 'Server error'
            );
            // message.error(error.response.data.message[0].messages[0].message);
            return false;
        }
    }
};

export const getLineChartData = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/therapists/getLineChartData`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_LINE_CHART_DATA,
                        payload: {
                            lineChart: res.data.lineChartData
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    dispatch({
                        type: Types.GET_LINE_CHART_DATA,
                        payload: {
                            lineChart: null
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getPieChartData = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/therapists/getPieChartData`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_PIE_CHART_DATA,
                        payload: {
                            pieChart: res.data.pieChartData
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    dispatch({
                        type: Types.GET_PIE_CHART_DATA,
                        payload: {
                            pieChart: []
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

