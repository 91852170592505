export const captchaKeys = {
    siteKey: '6Le2WpQcAAAAAIB8n094qZX4BBm5nFXlKVsbj0T-',
    secretKey: '6Le2WpQcAAAAAN-MKn7ZbYYDtcMz5KyNL0wxt0NJ'

    // siteKey: "6LeVXZQcAAAAAHCHb_bnPEDADboL8LWCOXqGFiwi",
    // secretKey: "6LeVXZQcAAAAAJZCAZhIg2GwurC8F3RiOMy64rxV"
};

export const opentokKeys = {
    apiKey: '47842111',
    secret: '18017020c07b7db0bc61e6c6e7f641358a8c624f'
};
export const facebookKeys = {
    appId: '1048266455722167',
    secret: 'ba76fce234c428a1869bb11a0118e4c6'
};
