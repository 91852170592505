import React, { useState, useEffect, memo } from 'react';
import '@/assets/css/newProfile.css';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import * as userActions from '@/actions/userActions';
import * as availabilityActions from '@/actions/availabilityActions';
import * as generalActions from '@/actions/generalActions';
import * as branchActions from '@/actions/branchActions';
import '../Shared/customModal.css';
import { useDispatch } from 'react-redux';
import Routes from '@/routes/private/Nurse';
import LayoutComponent from '../Shared/Layout';

const NurseLayout = (props) => {
    const dispatch = useDispatch();
    const { userId, userType } = useSelector((state) => state.auth);

    const { auth, history, actions, generalActions, availabilityActions,branchActions } = props;

    const setupProfile = async () => {
        if (auth && auth?.user) {
            let profileData = {
                userId: userId,
                userType: userType
            };

            actions.getProfile(profileData, history);

            let obj = {
                ...profileData,
                hospital: auth?.user?.hospital
            };
            availabilityActions.getAvailability({ ...props }, props.history);
            generalActions.getHospitalServices({ ...props, obj }, history);
            generalActions.getDocumentTypes({ ...props, obj }, history);
            obj = {
                userId: props.auth.userId,
                populateAvailability: true,
                userType: props.auth.userType
            };
            branchActions.getBranches({ ...props, obj }, props.history);
        }
    };
    useEffect(() => {
        if (userType !== 'NURSE') {
            history.push('/');
        }
        setupProfile();
    }, []);

    return <LayoutComponent minFields={true} routes={Routes} />;
};

// export default UserProfile;
const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    general: state.general,
    nurseServices:
        state.general && state.general.nurseServices && state.general.nurseServices
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
    availabilityActions: bindActionCreators(availabilityActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(NurseLayout));
