import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as stripeActions from '@/actions/stripeActions';
import '@/assets/css/stripe.css';
import AddSource from '../../Shared/Stripe/AddSource';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Empty, Alert, Button, Tag } from 'antd';
import PageHeader from '../../Shared/PageHeader';
import {
    CheckOutlined,
    ClockCircleOutlined,
    MinusCircleOutlined,
    PlusOutlined
} from '@ant-design/icons';
import ButtonGroup from 'antd/es/button/button-group';
let stripePK =
    'pk_test_51Jat9xGdKelfS2valqmKF2YldfpvfxDlazZUvA6joCR5PAABvEwf3WzpwpmjPO6rUnjY2Bhzz342eHNIRJc13EQ600VeqkMeCR';
let stripeSecret =
    'sk_test_51Jat9xGdKelfS2vaK2gVB7K6x7tmMF8bZOUiJUo4rzVcLccIPuncCToSg93bwqGKDPKYBnJCd9LYteR2wTzEoROY009irGcsmQ';
const stripePromise = loadStripe(stripePK);

const getStatusClassName = (status) => {
    switch (status) {
        case 'Connected':
            return 'connected';
        case 'Not Connected':
            return 'not-connected';
        case 'Terms of Service Violation':
        case 'Capabilities Request Required':
        case 'Listed Account':
        case 'Fraudulent Activity':
        case 'Incomplete Verification':
            return 'failed';
        case 'Verification Past Due':
        case 'Verification Pending':
            return 'pending';

        default:
            return 'not-connected';
    }
};

const getStatusIcon = (status) => {
    switch (status) {
        case 'Connected':
            return <CheckOutlined />;
        case 'Not Connected':
            return <MinusCircleOutlined />;
        case 'Terms of Service Violation':
        case 'Capabilities Request Required':
        case 'Listed Account':
        case 'Fraudulent Activity':
        case 'Incomplete Verification':
            return <MinusCircleOutlined />;
        case 'Verification Past Due':
        case 'Verification Pending':
            return <ClockCircleOutlined />;

        default:
            return <MinusCircleOutlined />;
    }
};

const StripeDashboard = () => {
    const dispatch = useDispatch();
    const { userId, userType, user, stripe, stripeVerified, stripeAccountId } = useSelector(
        (state) => state.auth
    );
    const { isStripeVerified, stripeBalance } = stripe;
    const {
        externalAccounts: { cards, bankAccounts }
    } = useSelector((state) => state.stripe);
    const [stripeLoading, setStripeLoading] = useState(false);
    const [sourceModal, setSourceModal] = useState(false);

    const getStripeDashboard = async () => {
        setStripeLoading(true);
        let obj = {};
        await dispatch(stripeActions.getStripeDashboard({ obj }));
        setStripeLoading(false);
    };

    const getExternalAccounts = async () => {
        let obj = {};
        await dispatch(stripeActions.getExternalAccounts({ obj }));
    };

    const addSource = async () => {};

    useEffect(() => {
        if (user?.stripeAccountId) {
            getStripeDashboard();
            getExternalAccounts();
        }
    }, [user?.stripeAccountId]);

    const connectStripe = async () => {
        setStripeLoading(true);
        let obj = {};
        // obj.countryCode = user?.countryCode
        //   ? countries.all.filter(
        //       (countryObj) =>
        //         countryObj.countryCallingCodes[0] === user?.countryCode
        //     )[0]?.alpha2
        //   : "CA";
        obj.countryCode = 'US';
        let { url } = await dispatch(stripeActions.setupStripeAccount({ obj }));
        setStripeLoading(false);
        if (url) {
            window.location.assign(url);
        }
    };
    return (
        <div className=' stripe-dashboard' style={{maxWidth: 1200}}>
            <PageHeader heading={'Stripe'} />
            <div
                style={{ display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'center' }}
            >
                <div style={{ display: 'flex', gap: 20, width: '100%', flexWrap: 'wrap' }}>
                    <div
                        className='stripeStatus'
                        style={{
                            borderRadius: 8,
                            width: 'fit-content',
                            display: 'flex',
                            flexDirection: 'column',
                            flex: 1,
                            margin: 0
                        }}
                    >
                        <h4 style={{ fontSize: 16, display: 'flex' }}>
                            Stripe Status:{' '}
                            <Tag
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    columnGap: 5,
                                    marginLeft: 10
                                }}
                                className={getStatusClassName(
                                    isStripeVerified.reason?.short || 'Not Connected'
                                )}
                            >
                                {isStripeVerified?.verified
                                    ? 'Connected'
                                    : isStripeVerified.reason?.short || 'Not Connected'}
                                {getStatusIcon(isStripeVerified?.reason?.short || 'Not Connected')}
                            </Tag>
                        </h4>

                        <div
                            className=''
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'flex-start',
                                flexWrap: 'wrap',
                                alignItems: 'flex-start',
                                gap: 10
                            }}
                        >
                            {!isStripeVerified.verified ? (
                                <a className='stripeBtn' onClick={() => connectStripe()}>
                                    {stripeLoading && <div className='spinner-border sm'></div>}
                                    Connect To Stripe
                                </a>
                            ) : (
                                <Button
                                    type='primary'
                                    style={{ marginTop: 15 }}
                                    onClick={() => setSourceModal(true)}
                                >
                                    <PlusOutlined /> Add Source
                                </Button>
                            )}
                            <Alert
                                style={{ padding: '5px 10px' }}
                                description={
                                    isStripeVerified.reason?.long ||
                                    'To schedule online appointments or make online payments, please ensure your Stripe account is connected.'
                                }
                                type='info'
                            />
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 10,
                            flex: 1,
                            justifyContent: 'space-between',
                            borderRadius: 8
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                gap: 20
                            }}
                        >
                            <h4 style={{ fontSize: 16 }}>Stripe Balance</h4>
                            <ButtonGroup>
                                <Button size='middle' type='primary'>
                                    Payout
                                </Button>
                                {/* <Button type='default' onClick={() => getStripeDashboard()}>
                                    Refresh
                                </Button> */}
                            </ButtonGroup>
                        </div>
                        <div className='balanceRow'>
                            <div>
                                <span className='textSecondary'>Available</span>
                                {isStripeVerified?.verified ? (
                                    <p>
                                        {stripeBalance?.available[0]?.currency.toUpperCase()}{' '}
                                        {parseFloat(
                                            stripeBalance?.available[0]?.amount / 100 || 0
                                        ).toFixed(2)}
                                    </p>
                                ) : (
                                    <p>N/A</p>
                                )}
                            </div>
                            <div>
                                <span className='textSecondary'>Pending</span>
                                {isStripeVerified?.verified ? (
                                    <p>
                                        {stripeBalance?.pending[0]?.currency.toUpperCase()}{' '}
                                        {parseFloat(
                                            stripeBalance?.pending[0]?.amount / 100 || 0
                                        ).toFixed(2)}
                                    </p>
                                ) : (
                                    <p>N/A</p>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 10,
                        width: '100%',
                        flex: 1
                    }}
                >
                    <h4 style={{ fontSize: 16 }}>External Sources</h4>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 20,
                            width: '100%',
                            flex: 1
                        }}
                    >
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, flex: 1 }}>
                            <p style={{ marginBottom: 5, fontWeight: 500 }}>Cards</p>

                            {cards?.length ? (
                                <div className='card-grid'>
                                    {cards?.slice(0, 3).map((item) => (
                                        <div className='card'>
                                            <p>
                                                <strong>Brand:</strong> {item.brand}
                                            </p>
                                            <p>
                                                <strong>Last 4:</strong> {item.last4}
                                            </p>
                                            <p>
                                                <strong>Expiry Date:</strong> {item.exp_month}/
                                                {item.exp_year}
                                            </p>
                                            <p>
                                                <strong>Capabilities:</strong>{' '}
                                                <span style={{ display: 'flex', columnGap: 5 }}>
                                                    {item.available_payout_methods?.map((it) => (
                                                        <p className='pill'> {it} </p>
                                                    ))}
                                                </span>
                                            </p>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <Empty style={{padding: '20px 0px'}} description='No Cards Found' />
                            )}
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, flex: 1 }}>
                            <p style={{ marginBottom: 5, fontWeight: 500 }}>Bank Accounts:</p>
                            {bankAccounts?.length ? (
                                <div className='card-grid'>
                                    {bankAccounts?.slice(0, 3).map((item, index) => (
                                        <div className='card'>
                                            <p>A/C Holder name: {item.account_holder_name}</p>
                                            <p>Bank name: {item.bank_name}</p>
                                            <p>Routing No: {item.routing_number}</p>
                                            <p>Country: {item.country}</p>
                                            <p>Currency: {item.currency}</p>
                                            <p>Last 4: {item.last4}</p>
                                        </div>
                                    ))}{' '}
                                </div>
                            ) : (
                                <Empty style={{padding: '20px 0px'}} description='No Bank Accounts Found' />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <Elements stripe={stripePromise}>
                <AddSource
                    stripeActions={stripeActions}
                    dispatch={dispatch}
                    open={sourceModal}
                    setOpen={setSourceModal}
                    user={user}
                />
            </Elements>
        </div>
    );
};

export default StripeDashboard;
