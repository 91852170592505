import React, { useState, memo, useEffect } from 'react';
import '../../HospitalProfile/hospitalView/lower.css';
import List from './Tablist';
import ListSidebar from './Sidebar';
import { GoGlobe } from 'react-icons/go';
import { FaThumbsUp } from 'react-icons/fa';
import { BiLike } from 'react-icons/bi';
import { IoQrCodeSharp } from 'react-icons/io5';
import { BsFillCalendarFill, BsEnvelope, BsStar, BsPhone } from 'react-icons/bs';
import * as pharmacyActions from '@/actions/pharmacyActions';
import * as userActions from '@/actions/userActions';
import { connect } from 'react-redux';
import { Alert, Popover } from 'antd';
import { bindActionCreators } from 'redux';
import { EmailShareButton } from 'react-share';
import { BiShareAlt } from 'react-icons/bi';
import QrCodeModal from '../../../Cards/Common/QrCodeModal';

const Lower = (props) => {
    // const [tab, setTab] = useState("general");
    const { tab, setTab, id, selectedAddressIndex, pharmacy, auth, selectedAddress, history } =
        props;
    const [qrModal, setQrModal] = useState(false);
    const tabs = ['doctors', 'general', 'blogs', 'following', 'reviews'];
    const handleTab = (string) => {
        if (tabs.includes(string)) {
            setTab(string);
            props.history.push(`/pharmacy-view/${id}?branchId=${selectedAddress}&tab=${string}`);
        }
    };
    const [liked, setLiked] = useState(false);
    const { pharmacySingle } = pharmacy;
    useEffect(() => {
        if (pharmacySingle) {
            let like = false;
            if (pharmacySingle.likes && auth && auth.userId) {
                let filtered = pharmacySingle.likes.filter((item) => item.user == auth.userId);
                console.log(filtered);
                if (filtered.length > 0) {
                    setLiked(true);
                } else {
                    setLiked(false);
                }
            }
        }
    }, [pharmacySingle, pharmacySingle && pharmacy.likes]);

    const likeOrUnlikeUserType = async () => {
        let obj = {
            userId: pharmacySingle._id,
            userType: 'PHARMACY',
            like: liked ? false : true
        };
        let likedLaw = await props.generalActions.likeOrUnlikeUserType(
            { ...props, obj },
            props.history
        );
        if (likedLaw) {
            await props.actions.getPharmacyById({ ...props, pharmacyId: id }, props.history);
            let obj = {
                pharmacyId: id
            };
            await props.actions.getPharmacyStatCounts({ ...props, obj }, props.history);
        }
    };
    let selectedBranch =
        pharmacySingle && pharmacySingle.branches && pharmacySingle.branches[selectedAddressIndex];

    const sendMail = async (email, subject, body) => {
        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };
    const goToSite = async () => {
        if (selectedBranch && selectedBranch.website) {
            window.open(
                `https://${selectedBranch && selectedBranch.website && selectedBranch.website}`
            );
        }
    };

    const makeCall = async () => {
        window.open(
            `tel:${selectedBranch && selectedBranch.countryCode && selectedBranch.countryCode}${
                selectedBranch && selectedBranch.phoneNo && selectedBranch.phoneNo
            }`
        );
    };

    const handleShare = async () => {
        let urlString = window.location.origin + `/pharmacy-view/${pharmacySingle._id}`;
        if (selectedBranch?._id) {
            urlString += `?branchId=${selectedBranch._id}`;
        }
        if (navigator.share) {
            try {
                await navigator.share({
                    title: pharmacy.pharmacyName,
                    text: 'Check out this content!',
                    url: urlString
                });
                console.log('Content shared successfully');
            } catch (error) {
                console.error('Error sharing content', error);
            }
        } else {
            console.log('Web Share API is not supported in your browser.');
        }
    };
    return (
        <>
            <div className='hospital-view-lower'>
                <div className='hospital-view-lower-top'>
                    <div className='hospital-view-lower-top-btns'>
                        <div
                            className={`${tab == 'general' && 'active'} hospital-view-lower-btns`}
                            onClick={() => handleTab('general')}
                        >
                            <p>General</p>
                        </div>
                        <div
                            className={`${tab == 'blogs' && 'active'} hospital-view-lower-btns`}
                            onClick={() => handleTab('blogs')}
                        >
                            <p>Posts</p>
                        </div>

                        <div
                            className={`${tab == 'reviews' && 'active'} hospital-view-lower-btns`}
                            onClick={() => handleTab('reviews')}
                        >
                            <p>Reviews</p>
                        </div>
                    </div>
                </div>
            </div>

            <div className='hospital-view-action' style={{ backgroundColor: '#45536e' }}>
                <div className='hospital-view-action-container'>
                    <div className='hospital-view-action-btn'>
                        {pharmacy.pharmacySingle &&
                            pharmacy.pharmacySingle &&
                            pharmacy.pharmacySingle.allowChat && (
                                <div
                                    className='hospital-view-message'
                                    onClick={() => props.openMessageModal()}
                                >
                                    <BsEnvelope />
                                    <p>Message</p>
                                </div>
                            )}
                        {/* {pharmacy.pharmacySingle &&
              pharmacy.pharmacySingle &&
              auth.user &&
              auth.user._id &&
              pharmacy.pharmacySingle._id != auth.user._id && (
                <div
                  className="pharmacy-view-message"
                  onClick={() => props.openReviewModal()}
                >
                  <BsEnvelope />

                  <p>Add a review</p>
                </div>
              )} */}

                        {pharmacy &&
                            pharmacy.pharmacySingle &&
                            auth &&
                            auth.userId &&
                            auth.userType != 'PHARMACY' &&
                            props.pharmacy.pharmacySingle._id != auth.userId && (
                                <div
                                    className='hospital-view-message'
                                    onClick={() => {
                                        let obj = {
                                            favouriteItem:
                                                props.pharmacy.pharmacySingle._id &&
                                                props.pharmacy.pharmacySingle._id,
                                            favouriteType: 'PHARMACY'
                                        };
                                        props.userActions.addToFavourites(
                                            { obj, ...props },
                                            props.history
                                        );
                                    }}
                                >
                                    <BsStar />

                                    <p>Add to Favourites</p>
                                </div>
                            )}
                    </div>
                    <div className='hospital-view-btns'>
                        {/* <div className={"pharmacy-views-click"}>
              <IoQrCodeSharp fontSize="1.5rem" />
            </div> */}
                        <Popover
                            content={
                                selectedBranch &&
                                selectedBranch.phoneNo &&
                                selectedBranch.countryCode
                                    ? `(${selectedBranch.countryCode}) - ${selectedBranch.phoneNo} `
                                    : 'Not Specified'
                            }
                            onClick={() => makeCall()}
                        >
                            <div
                                className={
                                    selectedBranch &&
                                    selectedBranch.phoneNo &&
                                    selectedBranch.countryCode
                                        ? 'hospital-views-click active'
                                        : 'hospital-views-click'
                                }
                                style={{ color: 'white' }}
                            >
                                <BsPhone fontSize='1.5rem' />
                            </div>
                        </Popover>
                        <Popover
                            content={
                                selectedBranch && selectedBranch.email
                                    ? selectedBranch.email
                                    : 'Not Specified'
                            }
                            onClick={() =>
                                sendMail(
                                    selectedBranch && selectedBranch.email
                                        ? selectedBranch.email
                                        : '',
                                    'Contact Pharmacy',
                                    'Hi there!'
                                )
                            }
                        >
                            <div
                                className={
                                    selectedBranch && selectedBranch.email
                                        ? 'hospital-views-click active'
                                        : 'hospital-views-click'
                                }
                                style={{ color: 'white' }}
                            >
                                <BsEnvelope fontSize='1.5rem' />
                            </div>
                        </Popover>
                        <Popover
                            content={
                                selectedBranch && selectedBranch.website
                                    ? selectedBranch.website
                                    : 'No website available'
                            }
                            onClick={() => goToSite()}
                        >
                            <div
                                className={
                                    selectedBranch && selectedBranch.website
                                        ? 'hospital-views-click active'
                                        : 'hospital-views-click'
                                }
                            >
                                <GoGlobe
                                    fontSize='1.5rem'
                                    className={
                                        selectedBranch && selectedBranch.website ? 'email' : ''
                                    }
                                />
                            </div>
                        </Popover>
                        <Popover content={'View QR Code'}>
                            <div
                                className='hospital-views-click'
                                style={{ color: 'var(--gray)' }}
                                onClick={() => setQrModal(true)}
                            >
                                <IoQrCodeSharp fontSize='1.5rem' />
                            </div>
                        </Popover>

                        {/* <div className="pharmacy-views-click" style={{ color: "white" }}>
              <FaRegHeart fontSize="1.5rem" />
            </div> */}
                        <Popover content={`${liked ? 'Unlike' : 'Like'} Pharmacy`}>
                            <div
                                className={'hospital-views-click active'}
                                onClick={() => likeOrUnlikeUserType()}
                            >
                                {liked ? (
                                    <FaThumbsUp fontSize='1.5rem' className='email' />
                                ) : (
                                    <BiLike fontSize='1.5rem' className='email' />
                                )}
                            </div>
                        </Popover>

                        {/* <EmailShareButton
                            style={{ outline: 'none' }}
                            // className="pharmacy-views-click active"
                            url={`https://Medasq-frontend-inevex.herokuapp.com/pharmacy-view/${pharmacySingle._id}`}
                        > */}
                        <Popover content='Share'>
                            <div
                                onClick={handleShare}
                                className='hospital-views-click active'
                                style={{ color: 'white' }}
                            >
                                <BiShareAlt fontSize='1.5rem' className='email' />
                            </div>
                        </Popover>
                        {/* </EmailShareButton> */}
                    </div>
                </div>
            </div>
            {history.location?.state?.prevPath === 'profile' && (
                <div style={{ padding: 20, width: 600, maxWidth: '100%', margin: 'auto' }}>
                    <Alert
                        onClick={() => history.goBack()}
                        style={{ cursor: 'pointer' }}
                        message={
                            <div>
                                This is a preview of your public profile. Click here to return to
                                your profile page.
                            </div>
                        }
                        type='warning'
                    />
                </div>
            )}
            <div className='hospital-view-lower-grid'>
                <List pharmacy={props.pharmacy} {...props} tab={tab} />
                {tab == 'general' && <ListSidebar {...props} index={props.index} />}
            </div>

            <QrCodeModal
                open={qrModal}
                onCancel={() => setQrModal(false)}
                user={props.pharmacy}
                userType={'PHARMACY'}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    pharmacy: state.pharmacy,
    pharmacySingle: state.pharmacy && state.pharmacy.pharmacySingle && state.pharmacy.pharmacySingle
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(pharmacyActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Lower));
