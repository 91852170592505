import React, { useState, useEffect, memo } from 'react';
import '@/assets/css/newProfile.css';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import * as userActions from '@/actions/userActions';
import * as branchActions from '@/actions/branchActions';
import * as generalActions from '@/actions/generalActions';
import '../Shared/customModal.css';
import { useDispatch } from 'react-redux';
import Routes from '@/routes/private/Pharmacy';
import LayoutComponent from '../Shared/Layout';

const PharmacyLayout = (props) => {
    const dispatch = useDispatch();
    const { userId, userType } = useSelector((state) => state.auth);

    const [approvedBranches, setApprovedBranches] = useState(false);
    const [branchesFetched, setBranchesFetched] = useState(false);
    const { auth, history, actions, generalActions, branchActions, profile, branches } = props;

    const setupProfile = async () => {
        if (auth && auth?.user) {
            let profileData = {
                userId: userId,
                userType: userType
            };

            const res = await actions.getProfile(profileData, history);
            console.log('res', res);
            let obj = {
                userId: auth.userId,
                populateAvailability: true,
                limit: 10,
                page: 1,
                userType
            };
            let fetchedBranches = await branchActions.getBranches({ ...props, obj }, history);
            if (fetchedBranches) {
                setBranchesFetched(true);
            }
            {
                let obj = {
                    ...profileData,
                };

                generalActions.getHospitalServices({ ...props, obj }, history);
                generalActions.getDocumentTypes({ ...props, obj }, history);
            }
        }
    };
    useEffect(() => {
        if (userType !== 'PHARMACY') {
            history.push('/');
        }
        setupProfile();
    }, []);
    useEffect(() => {
        const { branches } = props;

        if (profile && branchesFetched) {
            if (branches && branches.length !== 0) {
                let branchitem = branches[0];
                console.log(
                    branchitem,
                    branchitem.minFieldsCompleted,
                    branchitem.availability != null || branchitem.availability == {}
                );
                if (
                    branchitem &&
                    branchitem.minFieldsCompleted &&
                    (branchitem.availability != null || branchitem.availability == {})
                ) {
                    setApprovedBranches(true);
                    dispatch({
                        type: 'CLEAR_PROFILE_COMPLETION_ERROR',
                        payload: {
                            profileError: '',
                            profileBranchError: ''
                        }
                    });
                } else {
                    if (branchitem && Object.keys(branchitem).length > 0) {
                        setApprovedBranches(false);
                        dispatch({
                            type: 'PROFILE_COMPLETION_ERROR',
                            payload: {
                                profileError:
                                    'Please complete & save all the required fields and atleast one location and wait for verification to enable all features.'
                            }
                        });
                        history.push('/pharmacy/locations');
                    }
                }
            } else {
                dispatch({
                    type: 'PROFILE_COMPLETION_ERROR',
                    payload: {
                        profileError:
                            'Please complete & save all the required fields and atleast one location and wait for verification to enable all features.'
                    }
                });
            }
        }
    }, [branches, profile, branchesFetched]);

    return (
        <LayoutComponent
            minFields={true}
            routes={Routes.filter((route) => {
                if (
                    (branches && branches.length !== 0 && approvedBranches) ||
                    route.name.includes('Account') ||
                    route.name.includes('Locations')
                ) {
                    return route;
                }
            })}
        />
    );
};

// export default UserProfile;
const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    branch: state.branch,
    branches: state.branch && state.branch.branches && state.branch.branches,
    general: state.general,
    specializedRoles:
        state.general && state.general.specializedRoles && state.general.specializedRoles,
    practiceAreas: state.general && state.general.practiceAreas && state.general.practiceAreas,
    hospitalServices:
        state.general && state.general.hospitalServices && state.general.hospitalServices
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(PharmacyLayout));
