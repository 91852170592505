import React, { useEffect, useState } from 'react';
import { Modal, Button } from 'antd';

function EducationModal(props) {
    const {
        data,
        educationModal,
        setEducationModal,
        editEducation,
        setEditEducation,
        deleteEducation,
        setDeleteEducation,
        branch
    } = props;
    const dateFormat = 'YYYY/MM/DD';
    const [school, setSchool] = useState();
    const [major, setMajor] = useState();
    const [graduation, setGraduation] = useState();
    const [degree, setDegree] = useState();

    useEffect(() => {
        if (editEducation) {
            setSchool(editEducation.school);
            setMajor(editEducation.major);
            setGraduation(editEducation.graduation);
            setDegree(editEducation.degree);
        }
    }, [editEducation]);

    const handleSave = async () => {
        let profileObj = {
            data: {
                educations: [...data]
            },
            userType: props.auth.user.userType,
            userId: props.auth.user._id
        };
        if (editEducation) {
            profileObj.data.educations[editEducation.index] = {
                school: school,
                degree: degree,
                major: major,
                graduation: graduation,
                verificationStatus: 'REQUIRED'
            };
        } else {
            profileObj.data.educations = [
                ...data,
                {
                    school: school,
                    degree: degree,
                    major: major,
                    graduation: graduation,
                    verificationStatus: 'REQUIRED'
                }
            ];
        }
        if (profileObj.data.educations.length === 1) {
            profileObj.data.educations[0].current = true;
        }
        if (branch) {
            let profileObj2 = {
                userType: 'PHARMACY',
                pharmacy: branch.pharmacy,
                branchId: branch._id,
                minFieldsCompleted: true,
                pharmacist: {
                    ...branch.pharmacist
                }
            };
            if (profileObj?.data?.educations) {
                profileObj2.pharmacist.educations = [...profileObj.data.educations];
            }
            let saved = await props.actions.saveBranches(profileObj2, props.history);
            if (saved) {
                let obj = {
                    userId: props.auth.userId,
                    populateAvailability: true,
                    userType: props.auth.userType
                };
                await props.branchActions.getBranches({ ...props, obj }, props.history);
                setEditEducation(null);
                setEducationModal(false);
            }
            return;
        }
        let saved = await props.actions.saveProfile(profileObj, props.history);
        if (saved) {
            let profileShort = {
                userId: props.auth.user._id,
                userType: props.auth.user.userType
            };
            await props.actions.getProfile(profileShort, props.history);
            setEditEducation(null);
            setEducationModal(false);
        }
    };

    return (
        <Modal
            className='customModal'
            centered
            title={`${editEducation ? 'Edit' : 'Create'} Education`}
            open={educationModal}
            onCancel={() => {
                setEditEducation(null);
                setEducationModal(false);
            }}
            footer={null}
        >
            <>
                <div className='post-modal-options-grid2'>
                    <label>
                        <p>School:</p>

                        <div className='pmodal-name-cat-time'>
                            <input
                                placeholder='School Name'
                                value={school}
                                onChange={(e) => setSchool(e.target.value)}
                            />
                        </div>
                    </label>

                    <label>
                        <p>Major:</p>

                        <div className='pmodal-name-cat-time'>
                            <input
                                placeholder='Major'
                                value={major}
                                onChange={(e) => setMajor(e.target.value)}
                            />
                        </div>
                    </label>
                </div>
                <div className='post-modal-options-grid2'>
                    <label>
                        <p>Degree:</p>

                        <div className='pmodal-name-cat-time'>
                            <input
                                placeholder='Degree'
                                value={degree}
                                onChange={(e) => setDegree(e.target.value)}
                            />
                        </div>
                    </label>

                    <label>
                        <p>Graduation:</p>

                        <div className='pmodal-name-cat-time'>
                            <input
                                placeholder='Graduation'
                                value={graduation}
                                onChange={(e) => setGraduation(e.target.value)}
                            />
                        </div>
                    </label>
                </div>
                <Button onClick={() => handleSave()} type='primary'>
                    <p>{editEducation ? 'Update' : 'Add'}</p>
                </Button>
            </>
        </Modal>
    );
}

export default EducationModal;
