import React, { useState, useEffect } from 'react';
import { Modal, Form, message, Alert, Input, Button, Select } from 'antd';
import '@/assets/css/auth.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as hospitalActions from '@/actions/hospitalActions';
import { GoogleLogin } from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import Logo from '@/assets/images/logo.png';
import googleClient from '@/assets/json/GoogleAuth.json';
import { facebookKeys } from '@/assets/Keys';
import GoogleIcon from '@/assets/img/svgs/GoogleIcon';
import MicrosoftLogin from '@/components/MiniComponents/MicrosoftLogin';
import { MailOutlined, EyeTwoTone, EyeInvisibleOutlined, LockOutlined } from '@ant-design/icons';
import PhoneIcon from '@/assets/img/svgs/PhoneIcon';
import FacebookIcon from '@/assets/img/svgs/FacebookIcon';
import MicrosoftIcon from '@/assets/img/svgs/MicrosoftIcon';
import userTypes from '../../assets/json/UserTypes';
import { getWorkTypes } from '../../assets/json/WorkTypes';
import RequestMode from './RequestMode';

let workTypes = null;

const { Option } = Select;
const Register = (props) => {
    const {
        setRegProp,
        setUserTypeProp,
        closeRegister,
        phoneSignUpModal,
        signInModal,
        setEmailVerifiedMsg,
        open,
    } = props;
    const [form] = Form.useForm();
    const [organization, setOrganization] = useState('');
    const { client_id: googleClientId } = googleClient && googleClient.web;
    const { userTypeProp } = props;
    const [requestMode, setRequestMode] = useState(false);
    const [loading, setLoading] = useState(false);
    const [search, setSearch] = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [userType, setUserType] = useState(userTypeProp ? userTypeProp : 'PATIENT');
    const [originUserType, setOriginUserType] = useState(userTypeProp ? userTypeProp : 'PATIENT');

    let WORK_TYPES = getWorkTypes(userType);
    const [workType, setWorkType] = useState({
        value: WORK_TYPES[0].id,
        message: '',
        isValid: false
    });

    useEffect(() => {
        if (userType !== originUserType) {
            let obj = {
                DOCTOR: 1,
                NURSE: 2,
                CONSULTANT: 3,
                CAREGIVER: 4,
                THERAPIST: 5
            };
            setWorkType({
                ...workType,
                value: obj[originUserType],
                message: '',
                isValid: true
            });
            setOriginUserType(userType);
            return;
        }
        setWorkType({
            ...workType,
            value: WORK_TYPES[0].id,
            message: '',
            isValid: true
        });
        setOriginUserType(userType);
    }, [userType]);

    const changeUserType = (e) => {
        setUserType(e.target.value);
        setUserTypeProp(e.target.value);
        setOriginUserType(e.target.value);
    };

    const submitForm = async (e) => {
        e.preventDefault();
        setLoading(true);
        form.validateFields()
            .then(async (values) => {
                let obj = {
                    email: values.email,
                    password: values.password,
                    userType: userType,
                    scope:
                        workType.value === 1 && userType === 'HOSPITAL'
                            ? 'ALL'
                            : workType.value === 1 && userType,
                    workType: workType.value,
                    firstName: values.firstName,
                    lastName: values.lastName
                };
                let response;
                if (!requestMode) {
                    response = await props.actions.register(obj);
                } else {
                    obj.userType = userType;
                    obj.hospital = organization.value;
                    response = await props.hospitalActions.sendSignUpRequest(obj);
                }

                if (response) {
                    message.success('Sign Up successful!');
                    setLoading(false);
                    if (setEmailVerifiedMsg) {
                        setEmailVerifiedMsg(
                            `A verification email has been sent to the email address ${obj.email}, please check your email and click on the verification link to verify your email address before you can sign in.`
                        );
                    }
                    signInModal();
                } else {
                    setLoading(false);
                    message.error('Sign up failed!');
                }
            })
            .catch(() => {
                message.error('Please enter all required fields');
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const responseFacebook = (response) => {
        const data = {
            accessToken: response.accessToken,
            userID: response.userID,
            userType
        };
        props.actions.facebookLogin(data, props.history);
    };
    const responseGoogle = (response) => {
        const data = { idToken: response.tokenId, userType };
        // props.actions.googleLogin(data, props.history);
    };

    const responseMicrosoft = (response) => {
        if (response) {
            const data = {
                name: response.account.name,
                userType,
                email: response.account.userName
            };
            props.actions.microsoftLogin(data, props.history);
        }
    };

    useEffect(() => {
        if (workType.value === 3) {
            setUserType('HOSPITAL');
            setOriginUserType(userType);
        }
        if (workType.value === 2 && userType !== 'HOSPITAL') {
            setRequestMode(true);
            return;
        }
        setRequestMode(false);
    }, [workType.value]);

    const { errors } = props.auth;
    return (
        <Modal open={open} centered destroyOnClose onCancel={closeRegister} footer={null}>
            <div>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        <img src={Logo} alt='logo' style={{ width: '8rem' }} />
                    </div>
                </div>

                <div className='userType'>
                    {userTypes.map((item) => (
                        <button
                            key={item.value}
                            value={item.value}
                            disabled={item.disabled}
                            onClick={changeUserType}
                            className={`${userType === item.value && 'active'}`}
                        >
                            {item.label}
                        </button>
                    ))}
                </div>

                <div className='second-part-reg'>
                    <h3 style={{ textAlign: 'left', marginTop: 15 }}>Sign Up</h3>
                    {userType !== 'PHARMACY' && userType !== 'PATIENT' && (
                        <Select
                            name='workType'
                            placeholder='Select Work Type'
                            autoComplete='new-password'
                            autoCorrect='off'
                            value={workType.value || undefined}
                            style={{ marginBottom: 20, width: '100%' }}
                            onChange={(value) => {
                                setWorkType({
                                    ...workType,
                                    value: value || '',
                                    message: value || 'Work type is required',
                                    isValid: value ? true : false
                                });
                            }}
                        >
                            {WORK_TYPES.map((workTypeOption) => (
                                <Option key={workTypeOption.id} value={workTypeOption.id}>
                                    {workTypeOption.title}
                                </Option>
                            ))}
                        </Select>
                    )}

                    <div className='form'>
                        {!requestMode ? (
                            <Form form={form} initialValues={{ remember: true }}>
                                <Form.Item
                                    name='email'
                                    rules={[
                                        { required: true, message: 'Please provide your email.' },
                                        {
                                            type: 'email',
                                            message: 'Please provide a valid email address.'
                                        }
                                    ]}
                                    style={{ fontSize: 12 }}
                                >
                                    <Input
                                        prefix={<MailOutlined className='site-form-item-icon' />}
                                        placeholder='Email Address'
                                    />
                                </Form.Item>
                                <Form.Item
                                    name='password'
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please provide your password!'
                                        },
                                        { min: 6, message: 'Please provide a strong password!' }
                                    ]}
                                >
                                    <Input.Password
                                        iconRender={(visible) =>
                                            visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                        }
                                        prefix={<LockOutlined className='site-form-item-icon' />}
                                        type='password'
                                        placeholder='Password'
                                    />
                                </Form.Item>
                            </Form>
                        ) : (
                            <RequestMode
                                organization={organization}
                                setOrganization={setOrganization}
                                form={form}
                            />
                        )}

                        <Alert
                            message={
                                errors?.message ??
                                `After you've successfully completed the signup process, we'll send an email to the address you used for signing up. Please take a moment to check your inbox and confirm your email address before logging in.`
                            }
                            type={errors?.message ? 'error' : 'info'}
                            style={{ fontSize: 12, marginBottom: 10 }}
                        />

                        <h3
                            style={{
                                textAlign: 'left',
                                marginTop: 15,
                                marginBottom: '0px !important'
                            }}
                        >
                            Or Sign up using
                        </h3>

                        <div
                            style={{
                                display: 'flex',
                                gap: 10,
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: 10
                            }}
                        >
                            <Button
                                type='text'
                                onClick={(e) => {
                                    e.preventDefault();
                                    setRegProp(true);
                                    phoneSignUpModal();
                                }}
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <PhoneIcon />
                            </Button>
                            <FacebookLogin
                                appId={facebookKeys.appId}
                                autoLoad={false}
                                fields='name,email,picture'
                                callback={(response) => responseFacebook(response)}
                                redirectUri='https://www.jurisnexa.com/'
                                render={(renderProps) => (
                                    <Button
                                        type='text'
                                        className='social_btn'
                                        onClick={renderProps.onClick}
                                    >
                                        <FacebookIcon />
                                    </Button>
                                )}
                            />
                            <GoogleLogin
                                clientId={googleClientId}
                                onSuccess={(response) => responseGoogle(response)}
                                onFailure={(response) => responseGoogle(response)}
                                className='googleBtn'
                                isSignedIn={false}
                                render={(renderProps) => (
                                    <Button type='text' onClick={renderProps.onClick}>
                                        <GoogleIcon />
                                    </Button>
                                )}
                            />

                            <MicrosoftLogin
                                clientId={'6686bce7-4838-4fb8-8269-0c2e3005281d'}
                                authCallback={(response) => responseMicrosoft(response)}
                                prompt={'consent'}
                                graphScopes={['profile']}
                                redirectUri={'https://Medasq-frontend-inevex.herokuapp.com'}
                                children={
                                    <Button type='text'>
                                        <MicrosoftIcon />
                                    </Button>
                                }
                            />
                        </div>
                        <Button
                            loading={loading}
                            type='primary'
                            style={{ width: '100%' }}
                            onClick={submitForm}
                        >
                            {requestMode ? 'Send Request' : 'Sign Up'}
                        </Button>
                        <div style={{ flexDirection: 'column' }}>
                            <div className='btm-links-login'>
                                <p
                                    style={{
                                        fontSize: 12,
                                        cursor: 'context-menu'
                                    }}
                                >
                                    Already have an account?
                                    <span
                                        onClick={() => {
                                            signInModal();
                                        }}
                                        style={{
                                            fontSize: 12,
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            color: 'var(--primary)'
                                        }}
                                    >
                                        &nbsp;Sign In
                                    </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch),
        hospitalActions: bindActionCreators(hospitalActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Register);
