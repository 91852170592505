export const terms = {
    Introduction: [
        {
            line: `Medasq's products and services are provided by Medasq, Inc. These terms and conditions of use ("Terms") govern your use of Medasq's websites, mobile applications, products, and services (collectively, the "Services"), so please read them carefully.`
        },
        {
            line: `By accessing this website or using the Services, you are agreeing to the Terms, Medasq's Privacy Policy and Medasq's Community Guidelines. If you have any questions, please contact us.`
        }
    ],
    ['Using our services']: [
        {
            line: `You may use our Services only if you can form a binding contract with Medasq. No use of the Services is permitted by those under the age of majority in their state of residence. In no event is use of the Services permitted by those under the age of 13. If you are using the Services on behalf of any entity, then you are agreeing to the Terms on behalf of that entity.`
        },
        {
            line: `Using our Services does not give you ownership of any intellectual property rights in our Services or the content you access. Except in the context of browsing or accessing our Services in accordance with these Terms, you may not use content from our Services unless you obtain permission from its owner or are otherwise permitted by law. These Terms do not grant you the right to use any branding or logos used in our Services. Do not remove, obscure, or alter any medical notices displayed in or along with our Services.`
        },

        {
            line: `Do not use our Services on any mobile device in a way that distracts you and prevents you from obeying traffic or safety laws.`
        },
        {
            line: `We may release and make available beta products, services, and features (collectively, “Beta Services”) for a limited period of time (the “Beta Test”). Beta Services have not been completely tested, are not ready for commercial release, and may contain bugs, errors, defects, and deficiencies. We reserve the right to withdraw the Beta Services during the Beta Test. We do not guarantee that the Beta Services will be made commercially available or that any commercially available version of the Beta Services will contain the same functionality as the version made available during the Beta Test. TO THE FULLEST EXTENT PERMITTED BY LAW, Medasq AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE BETA SERVICES.`
        }
    ],
    Verification: [
        {
            line: `A Medasq's Verification reflects Medasq's assessment of a given doctor or Hospital, based upon the information obtained by or submitted to Medasq. Someone else's verification of the same doctor or Hospital may be very different, or be based upon information at different point in time. A Medasq Verification is not an endorsement of any particular doctor or Hospital, and is not a guarantee of a doctor's or Hospital’s quality, competency, or character. Nor is the Medasq verification a predictor of the outcome of any matter in which such doctor is involved. Rather, the Medasq Verification process is intended to be a starting point to gather information about doctors or Hospitals who may be suitable for your medical needs - but you should not rely solely on the Medasq’s Verification in deciding whether to contact or hire any given doctor or Hospital.`
        },
        {
            line: `Learn more about the Medasq’s Verification Process <a>here.</a>`
        }
    ],
    Information: [
        {
            line: 'Our Services display both Medasq -created content and content that is not created or developed by Medasq (the "Medical Information"). We may review third party-content to determine whether it is ilmedical or violates our policies, and we may remove or refuse to display content that we reasonably believe violates our policies or the law. But we do not routinely screen third-party content that is published via our Services. This includes the Medical Information that doctors post on Medasq, and we cannot guarantee the accuracy, adequacy or quality of any such Medical Information, or the qualifications of those posting it.'
        }
    ],
    ['Attorney-client relationship']: [
        {
            line: `The Medical Information found on Medasq is intended for general informational purposes only and should be used only as a starting point for addressing your medical issues. The Medical Information is not the provision of medical services, and accessing such information, or corresponding with or asking questions to a doctor via the Services, or otherwise using the Services, does not create an attorney-client relationship between you and Medasq, or you and any doctor. It is not a substitute for an in-person or telephonic consultation with a doctor licensed to practice in your jurisdiction about your specific medical issue, and you should not rely on such Medical Information. You understand that questions and answers or other postings to the Services are not confidential and are not subject to attorney-client privilege.`
        }
    ],
    Interactions: [
        {
            line: `Medasq is a platform where doctors unaffiliated with Medasq can offer information and interact with consumers. Any attorney-client relationship formed as a result of interactions between a doctor and consumer on Medasq is between the consumer and the doctor—not between Medasq and the consumer. Medasq cannot be held responsible for the quality or accuracy of any information or medical services provided by doctors that consumers found via Medasq. Except as provided in Subchapter 4-7 of the Rules Regulation of the Ontario State Bar, Medasq is not a doctor referral service.`
        }
    ],
    ['Premium services']: [
        {
            line: `Medasq offers optional subscription services for doctors, including, but not limited to: Medasq Elite, Medasq Advanced, Premium, and Websites (collectively, the “Premium Services”). By selecting a Premium Service you agree to pay Medasq the subscription or usage fees (the “Subscription Fees”) indicated for that service. You further agree to provide Medasq with a valid payment method, which Medasq will store and from which Medasq will automatically charge Subscription Fees as they become due. Subscription Fees will be charged on the day your Premium Service goes into effect and will cover the use of that service for the period indicated. Thereafter, you agree that monthly payments will continue to be charged to your payment method on a recurring basis until you cancel your Premium Service. The amount of the monthly recurring charge will be the then-current Subscription Fee applicable to the Premium Service you selected. You acknowledge that the amount of the recurring charge may increase if the applicable Subscription Fee increases. Medasq will collect and remit sales tax where required by state laws, based on the Premium Services and your primary office location.`
        },
        {
            line: `Premium Services, other than Advertising and Websites, must be cancelled prior to the applicable renewal date in order to avoid billing of the next month's Subscription Fee. For Advertising or Websites, Medasq must receive written notice of your cancellation or reduction in service at least thirty (30) days in advance of the applicable renewal date on which you wish to make the cancellation or reduction effective. All cancellations or reductions of advertising services must be received in writing by email (cancel.ads@Medasq.io) or by U.S. mail (51 Times Avenue, Toronto, Ontario, Canada ATTN: Cancellations). Subscription Fees for Premium Services are not prorated or refundable. In no event will a cancellation or reduction in service relieve you of your obligation to pay the Subscription Fees that are due for the period prior to the effective date of cancellation or reduction in service.`
        },
        {
            line: `If your payment method fails or your account is past due, Medasq reserves the right to either suspend or terminate your Premium Services. Any such suspension or termination will result in the cancellation of any promotional programs applicable to your Premium Services. You agree to submit any disputes regarding any charge to your account in writing to Medasq within sixty (60) days of such charge, otherwise such dispute will be waived and such charge will be final and not subject to challenge.`
        },
        {
            line: `Premium Services may include the publication of advertising or website content you provide to us (the “Premium Services Content”). You retain all rights in, and are solely responsible for, the Premium Services Content posted on Medasq or websites supported by Medasq. You give Medasq, our affiliates, providers of third party services, and subcontractors a non-exclusive, fully-paid, perpetual, royalty-free, transferable, revocable, worldwide license to use, modify, publicly perform, publicly display, reproduce, and distribute the Premium Services Content (in whole or in part) in providing the Services.`
        }
    ],
    ['Advertising and communications']: [
        {
            line: `It is solely the responsibility of doctors and law firms to ensure that any information or advertisements they post or place on the Medasq website (including without limitation any Medical Information), and any communications they may have with prospective clients through the Services, fully complies with all applicable laws and rules of professional conduct, including those concerning the unauthorized practice of law and those regulating the form, manner or content of communications with clients, advertising, or other matters.`
        }
    ],
    Copyright: [
        {
            line: 'We respond to notices of alleged copyright infringement and terminate accounts of repeat infringers according to the process set out in the U.S. Digital Millennium Copyright Act.'
        },
        {
            line: 'If you believe that any material on the Site infringes upon any copyright which you own or control, you may file a DMCA Notice of Alleged Infringement with Medasq’s Designated Copyright Agent:'
        },
        { line: 'Medasq Copyright Agent ' },
        { line: 'c/o Medasq Medical Department / John Doe' },
        { line: '51 Times Avenue, Toronto, Ontario, Canada' },
        { line: 'email: <a href = "mailto: medical@Medasq.io">medical@Medasq.io</a>' }
    ],
    Software: [
        {
            line: 'Some of our Services allow you to download client software ("Software") which may update automatically; you agree that these Terms will apply to such upgrades. So long as you comply with these Terms, we give you a limited, nonexclusive, non-transferable, revocable license to use the Software, solely to access the Services. To the extent any component of the Software may be offered under an open source license, we will make that license available to you and the provisions of that license may expressly override some of these Terms. Unless the following restrictions are prohibited by law, you agree not to reverse engineer or decompile the Services, attempt to do so, or assist anyone in doing so.'
        }
    ],

    Disclaimers: [
        {
            line: 'While Medasq strives to provide—and to allow doctors to provide—useful information regarding doctors and medical services, there are some things that we cannot guarantee. You acknowledge that such information consists of third party data and contributions, that there are certain inherent limitations to the accuracy or currency of such information, that medical and other information may be incomplete, may contain inaccuracies, or may be based on opinion. Medasq DOES NOT SCREEN SUCH INFORMATION FOR ACCURACY OR RELIABILITY. TO THE FULLEST EXTENT PERMITTED BY LAW, Medasq AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS MAKE NO WARRANTIES, EITHER EXPRESS OR IMPLIED, ABOUT THE SERVICES OR SOFTWARE. THE SERVICES AND SOFTWARE ARE PROVIDED "AS IS." Medasq ALSO DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.'
        }
    ],

    ['Limitation of liability']: [
        {
            line: 'TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT SHALL Medasq OR ANY OF ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS, OR ANY OF THEIR RESPECTIVE DIRECTORS, EMPLOYEES AND AGENTS, BE LIABLE FOR ANY SPECIAL, INDIRECT OR CONSEQUENTIAL DAMAGES, OR ANY LOSS OF PROFITS OR REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING FROM (A) THE OUTCOME OF ANY LEGAL MATTER; (B) YOUR ACCESS TO OR USE OF OR INABILITY TO ACCESS OR USE THE SERVICES OR SOFTWARE; (C) ANY CONDUCT OR CONTENT OF ANY THIRD PARTY RELATING TO THE SERVICES OR SOFTWARE; OR (D) UNAUTHORIZED ACCESS, USE OR ALTERATION OF LEGAL INFORMATION.'
        },
        {
            line: 'TO THE EXTENT PERMITTED BY LAW, THE TOTAL LIABILITY OF Medasq, AND ITS AFFILIATES, SUPPLIERS AND DISTRIBUTORS, FOR ANY CLAIM ARISING OUT OF OR RELATING IN ANY MANNER TO THE USE OF THE SERVICES OR SOFTWARE, IS LIMITED TO THE AMOUNT YOU PAID US TO USE THE SERVICES TO WHICH SUCH CLAIM RELATES IN THE 12 MONTH PERIOD PRECEDING THE FILING OF SUCH CLAIM. IN THE INTEREST OF CLARITY, THIS LIMITATION ALSO APPLIES TO PREMIUM SERVICES.'
        }
    ],

    ['User posted content']: [
        {
            line: `Medasq includes interactive areas in which you may post content and information, including peer endorsements and user reviews, questions and answers, comments, photos, and other materials (the "User Content"). You retain all rights in, and are solely responsible for, the User Content you post to Medasq. You are also solely responsible for your use of such interactive features, and use them at your own risk. By using any interactive feature, you agree to comply with the Medasq Community Guidelines. We reserve the right to remove or modify User Content for any reason, including User Content that we believe violates these Terms or our policies.`
        },
        {
            line: `When you post User Content to Medasq, you give Medasq and its affiliates a nonexclusive, royalty-free, perpetual, irrevocable and fully sublicensable right to use, reproduce, modify, adapt, publish, translate, create derivative works from, distribute, perform and display such User Content throughout the world in any media. To the extent such content is attached to a profile on the Medasq platform, the foregoing license includes a right to reproduce your profile, and any name, likeness or photograph contained in such profile. If you submit feedback or suggestions about our Services, we may use your feedback or suggestions without obligation to you.`
        }
    ],

    Indemnification: [
        {
            line: `You agree to defend, indemnify and hold harmless Medasq, its corporate affiliates, independent contractors, service providers and consultants, and each of their respective directors, employees and agents, from and against any claims, damages, costs, liabilities and expenses (including, but not limited to, reasonable attorneys' fees) arising out of or related to any User Content you post, store or otherwise transmit on or through the Services or your use of or inability to use the Services, including without limitation any actual or threatened suit, demand or claim arising out of or relating to the User Content, your conduct, your violation of these Terms or your violation of the rights of any third party.`
        }
    ],
    Modifications: [
        {
            line: `Medasq may revise these Terms from time to time, on a prospective basis, and we will always post the most up-to-date version on our website. If we determine, in our sole discretion, that a modification to these Terms materially affects your rights, we will notify you (by, for example, sending a message to your account email). By using or continuing to use or access the Services after any revisions have come into effect, you agree to be bound by the revised Terms.`
        }
    ],
    ['Mandatory Arbitration']: [
        { line: 'PLEASE READ THIS SECTION CAREFULLY, AS IT AFFECTS YOUR RIGHTS.' },
        {
            line: 'We hope we never have a dispute, but if we do, you and we agree to try for 60 days to resolve it informally. You may send us the details of your concern to medical@Medasq.io.  However, if Medasq is not able to informally resolve your complaint, you and Medasq agree to individual binding arbitration under JAMS Alternative Dispute resolution (“JAMS”) and the Federal Arbitration Act (“FAA”), and not to sue in court in front of a judge or jury. The arbitration will be conducted under the JAMS Commercial Arbitration Rules and the Supplementary Procedures for Consumer Related Disputes and the arbitrator’s decision will be final except for a limited right of review under the FAA. The following applies to any arbitration proceedings between you and Medasq:'
        },
        {
            line: 'Before moving an unresolved informal dispute into arbitration, you must first send us a Notice of Dispute describing the nature and basis of the claim or dispute and the specific relief sought. This notice may be sent via email or U.S. mail to: medical@Medasq.io or Medasq, Inc., Attention General Counsel, 51 Times Avenue, Toronto, ON Canada.'
        },
        {
            line: 'Any arbitration must be commenced by filing a demand for arbitration with JAMS within ONE (1) YEAR from when it first could be filed. Otherwise, it is permanently barred. If applicable law prohibits a one-year limitation period for asserting claims, any claim must be asserted within the shortest time period permitted by applicable law.'
        },
        {
            line: 'Whenever feasible, the arbitration will be held telephonically unless the arbitrator finds good cause to hold an in-person hearing instead. You may choose the location of any in-person hearing from either your county of residence; or, if you are a business, the county of your principal place of business; or, Medasq’s principal place of business in Ontario, Canada.'
        },
        {
            line: 'The enforcement of the arbitrator’s award will be controlled by and conducted in conformity with the FAA. Judgment upon any award may be entered in any court holding jurisdiction.'
        },
        {
            line: 'Not withstanding our agreement to arbitrate disputes as provided above, the following EXCEPTIONS will apply to the resolution of disputes between us:'
        },
        {
            line: 'Small Claims Court. If the claim qualifies, either you or Medasq may bring an action in small claims court in Ontario, Canada. Any small claims court action must be filed within one year from when it first could be filed. Otherwise, the claim is permanently barred.'
        },
        {
            line: 'Injunctive relief. Medasq may bring a lawsuit against you in any court of competent jurisdiction solely for injunctive relief to stop any unauthorized use or abuse of the Services, or any intellectual property infringement. In these instances, Medasq may seek injunctive relief without first engaging in the informal dispute resolution or arbitration process, as described above in these Terms of Use.'
        },
        {
            line: 'Disputes not Covered by Arbitration. In the event that arbitration is found to be inapplicable or unenforceable for any reason, or if you exercise the option to opt-out of arbitration as provided below, the claim at issue will be brought under judicial proceedings in federal or state courts in Ontario, Canada, and you and Medasq consent to personal jurisdiction and exclusive venue in such courts.'
        },
        {
            line: 'Option to Opt Out of Arbitration. You may opt out of this agreement to arbitrate. If you do so, neither you nor Medasq can require the other to participate in an arbitration proceeding. To opt out, you must notify us in writing within 30 days of the date that you first became subject to the arbitration provision in these Terms. To opt out you must send us a clear statement that you want to opt out of arbitration, along with your name, residence or business address, and the email address associated with your account to: medical@Medasq.io.'
        },
        {
            line: 'NO CLASS ACTIONS: You may only bring individual claims. Under no circumstances are you allowed to bring a claim as a plaintiff or a class member in a class. Class action lawsuits, class-wide arbitrations, private attorney-general actions, and any other proceedings where someone acts in a representative capacity are not allowed. Any combining of individual proceedings must have the consent of all parties.'
        }
    ],
    ['Applicable law']: [
        {
            line: 'The laws of the Province of Ontario, excluding its conflict of law provisions, will apply to any disputes arising out of or relating to these Terms or the Services. '
        }
    ],
    Termination: [
        {
            line: 'Not withstanding any of these Terms, Medasq reserves the right, without notice and in its sole discretion, to terminate your license to use the Services, and to block or prevent your access to and use of the Services. Medasq reserves the right to refuse service to anyone for any reason at any time. In the event of termination of access to the Services for any reason, you have no right to obtain a copy of any data or communications you stored or effected via the Services, or any other data.'
        }
    ],
    ['Entire Agreement']: [
        {
            line: 'These Terms constitute the entire agreement between you and Medasq with respect to the subject matter of these Terms, and supersede and replace any prior version of the Terms. These Terms create no third party beneficiary rights.'
        }
    ],
    ['Waiver, Severability, and Assignment']: [
        {
            line: 'Medasq’s failure to enforce a provision of these Terms is not a waiver of its right to do so later. If any provision of these Terms is found unenforceable, the remaining provisions of the Terms will remain in full effect and an enforceable Term or Terms will be substituted reflecting our intent as closely as possible. You may not assign any of your rights under these Terms, and any attempt to do so will be void. Medasq may assign its rights to any of its affiliates or subsidiaries, or to any successor in interest of any business associated with the Services.'
        }
    ],
    Questions: [
        {
            line: 'If you have any question regarding the use of the Site, please refer first to the FAQ. All other questions or comments about the Site or its contents should contact Customer Care.'
        }
    ]
};

export const privacyPolicy = {
    Introduction: [
        {
            line: `Welcome to our Privacy Notice. This notice applies to your interaction with our services, including our website, products, and apps (referred to collectively as the "Services"). We value your privacy and want to ensure you have a clear understanding of how we handle your information. Please take the time to read this notice carefully before using our Services. If you disagree with any aspect of this notice, we recommend refraining from accessing or using our Services.`
        }
    ],
    ['Data Collection']: [
        {
            title: '1. Usage and Log Information',
            line: "When you use our Services, we automatically collect data such as your computer's Internet Protocol (IP) address, browser type, operating system, referring and exit website addresses, access timestamps, and your interactions with our Services. This helps us analyze usage patterns, improve our Services, and diagnose server issues."
        },
        {
            title: '2. Cookies and Tracking Technologies:',
            line: 'We use "cookies" and similar technologies to enhance your experience on our website. Cookies are small data files stored on your device that help us remember your preferences and track your activities. You can manage cookie settings in your browser, but disabling cookies may limit certain features of our Services.'
        },
        {
            title: '3. Personal Information:',
            line: 'When you engage with our Services, we collect and store Personal Information you provide, such as your name, address, telephone number, email address, and payment information. This information is used to deliver our services, process transactions, and communicate with you. We may also collect verification data, particularly if you are a doctor updating your profile.'
        },
        {
            title: '4. Publicly Posted Information',
            line: 'Information you post in public areas of our Services, like your profile or interactive sections, may become publicly available and is no longer considered Personal Information. Please be aware that this information can be viewed by anyone and may be indexed by search engines.'
        }
    ],
    ['Data Use']: [
        {
            title: '1. Non-Personal Information:',
            line: 'Non-personal data, such as usage patterns, cookies, and aggregated information, helps us analyze trends, improve our Services, and may be shared with third parties for similar purposes.'
        },
        {
            title: '2. Personal Information:',
            line: 'We use Personal Information to facilitate your use of our Services, process transactions, provide requested information, and improve our business operations. We do not sell or rent your Personal Information to third parties for direct marketing purposes.'
        },
        {
            title: '3. Third-Party Service Providers: ',
            line: 'In certain cases, we may share Personal Information with third-party service providers who assist us in delivering our Services. These providers may include credit card processing or identity verification companies.'
        }
    ],
    ['Managing Preferences']: [
        {
            title: '1. Online Advertising Preferences:',
            line: 'You can manage your preferences for data collection by adjusting cookie settings in your browser. Additionally, you can utilize online advertising preference tools offered by third-party platforms.'
        },
        {
            title: '2.	Newsletter and Communications Preferences:',
            line: 'You can opt in or out of receiving newsletters or communications from us via your account settings. Instructions for unsubscribing from specific email categories are provided in each email.'
        }
    ],
    ['Requests for information']: [
        {
            line: 'We may disclose Personal Information when required by law, to enforce our policies, protect rights and safety, or in response to a lawful subpoena, warrant, or court order.'
        }
    ],
    ['Listing information']: [
        {
            line: 'You can edit or delete certain information in your account settings. Please note that some information may remain visible even after account closure, such as questions, answers, endorsements, or client reviews.'
        }
    ],
    ['Security measures']: [
        {
            line: 'We take reasonable security measures to protect your Personal Information, including encryption for sensitive data. However, please be aware that no security measures are infallible. Keep your password confidential, and notify us immediately if you suspect misuse.'
        }
    ],
    ['Third-Party Websites and Practices']: [
        {
            line: 'Our Services may contain links to third-party websites and services. We are not responsible for their privacy practices, so we encourage you to review their privacy policies when interacting with them.'
        }
    ],
    ['Transfers of information']: [
        {
            line: 'In the event of a business transfer, your Personal Information may be disclosed as part of the transfer. We will strive to notify you of such transfers, but you may not have the option to opt out in certain cases.'
        }
    ],
    Children: [
        {
            line: 'Our Services are not intended for users under 18 years of age. We do not knowingly collect Personal Information from children under 18.'
        }
    ],
    ['California privacy rights']: [
        {
            line: 'This section is provided pursuant to the Personal Information Protection and Electronic Documents Act (PIPEDA) and applicable provincial privacy laws in Canada. This section applies to our users who are residents of Canada.'
        },
        {
            title: '1. Information we collect',
            line: 'Within the last twelve (12) months, we have or may have collected the following categories of information from our users and/or consumers: identifiers; personal information as defined under Canadian privacy laws; characteristics of protected classifications under Canadian or federal law; commercial information; biometric information; internet or other similar network activity information; geolocation data; professional or employment-related information; and inferences drawn from such information.'
        },
        {
            title: '2. Categories of sources from which information is collected',
            line: 'We obtain the categories of personal information listed above from the following categories of sources: directly from you, as described above under "Personal information" and "Publicly posted information"; indirectly from other users of the Services; indirectly from third parties that interact with us in connection with the services we provide; and directly and indirectly through cookies and other technologies, as described above under "Use/log information" and "Cookies and online advertising."'
        },
        {
            title: '3. Sharing of personal information',
            line: 'We disclose your personal information for a business purpose to service providers and third parties to whom you authorize or direct us to disclose your personal information in connection with the Services. We also may share personal information about you for any other purpose(s) disclosed to you at the time we collect your information or with your consent.'
        },
        {
            title: '4. Personal information “sold” to third parties',
            line: 'In the preceding twelve (12) months, we have made available to our third-party partners personal information included in the following categories: identifiers; commercial information; internet or other similar network activity information; and inferences drawn from such information.'
        },
        {
            title: '5. Your rights under Canadian privacy laws',
            line: 'As a resident of Canada, you may have the following rights under applicable Canadian privacy laws:'
        },
        {
            line: '     a) You have the right to request access to your personal information and know how it is being used.'
        },
        {
            line: '      b) You may request that we disclose to you the categories and specific pieces of personal information that we have collected about you, as well as the purposes for which it is being used.'
        },
        {
            line: '      c) You may request that we delete your personal information, subject to certain exceptions.'
        },
        {
            line: 'To exercise these rights or opt-out of certain data processing activities, please refer to the "Managing privacy preferences" section above.'
        },

        {
            title: '6. Non-discrimination',
            line: 'We will not discriminate against you for exercising any of your privacy rights under Canadian privacy laws. Accordingly, and unless permitted by law, we will not: deny you services; charge you different prices or rates for services; provide you a different level of service; or suggest that you may receive a different price or rate for services or a different level of services based on your exercise of privacy rights. We may charge a different price or rate or provide a different level of service if the difference is reasonably related to the value provided by your personal information.'
        },
        {
            line: 'To exercise your privacy rights or for any privacy-related inquiries, please contact us by sending an email to privacy@jurisnexa.com with "Canada Privacy Rights" in the subject line or by filling out our Privacy Contact Form.'
        },
        {
            line: `You will be asked to provide certain identifying information, such as your name, email, and residency. While processing your request, we may ask you to provide further verifying documentation. We will only use personal information provided in a request to verify the requestor's identity or authority to make the request.`
        }
    ],
    ['Changes to This Privacy Policy']: [
        {
            title: 'Changes to This Privacy Policy',
            line: 'We may modify this Privacy Notice, and the most current version will be posted on our website. If we make material changes that affect your rights, we will notify you.'
        },
        {
            line: 'For questions or to exercise your privacy rights, please contact us via our website.'
        },
        {
            line: 'Thank you for reading and understanding our Privacy Notice.'
        }
    ]
};

export const guidelines = {
    Introduction: [
        {
            line: 'At Medasq, we highly value the creation and maintenance of a vibrant and respectful community where open dialogue and mutual respect thrive. Our Community Guidelines are designed to ensure a safe and positive environment for all users. By engaging with our platform, you explicitly agree to adhere to and abide by these guidelines. We kindly request that you take a moment to thoroughly review and understand them.'
        }
    ],
    ['Respectful Conduct']: [
        {
            line: 'Courtesy and Respect: In all interactions and discussions, extend courtesy and respect to all users. Discrimination, harassment, hate speech, personal attacks, or any form of disrespect is strictly prohibited.'
        }
    ],
    ['Privacy and Confidentiality']: [
        {
            line: 'Protect Privacy: Safeguard your own privacy as well as the privacy of others. Avoid sharing sensitive or confidential information in both public discussions and private messages.'
        }
    ],
    ['On-Topic Discussions']: [
        {
            line: 'Relevance: Keep your interactions and discussions relevant to the themes and topics of our platform. Avoid promoting unrelated content or engaging in spam.'
        }
    ],
    ['Avoid Medical Advice']: [
        {
            line: 'Limitations: While we encourage informative discussions, please bear in mind that our platform is not intended for providing direct medical advice. Consultations and specific medical advice should be sought through scheduled appointments with our medical professionals.'
        }
    ],
    ['Reporting Content']: [
        {
            line: 'Reporting Violations: If you come across content or behavior that violates these guidelines, we urge you to report it to our dedicated moderation team. Your vigilance helps maintain our respectful and safe community.'
        }
    ],
    ['Compliance with Laws']: [
        {
            line: 'Medical Adherence: Adhere to all applicable laws, regulations, and ethical standards when utilizing our platform.'
        }
    ],
    ['Moderation']: [
        {
            line: 'Our Right to Act: We reserve the right to take necessary actions, including removing or suspending users, in response to violations of these guidelines. This is to ensure the preservation of a respectful and secure community.'
        }
    ],
    ['Intellectual Property and Copyright']: [
        {
            line: 'Respect Intellectual Property: Show due respect for intellectual property rights and adhere to copyright laws. Do not post or share copyrighted materials without proper authorization.'
        }
    ],
    ['Authenticity and Accountability']: [
        {
            line: 'Real Identities: Use real identities and maintain full accountability for your actions and statements. Impersonation or misrepresentation is strictly prohibited.'
        }
    ],
    ['Prohibited Content']: [
        {
            line: 'Strict Prohibitions: Clearly outline the types of content that are strictly prohibited on our platform. This includes explicit or inappropriate material, spam, or any content that contravenes our policies.'
        }
    ],
    ['Language and Tone']: [
        {
            line: 'Professional Language: Encourage the use of professional and respectful language. Discourage the use of offensive or derogatory language that may harm or offend others.'
        }
    ],
    ['User Responsibility']: [
        {
            line: 'Individual Responsibility: Remind users that they are solely responsible for their own actions and for the content they post on the platform. Encourage self-moderation and personal accountability.'
        }
    ],
    ['Fair and Constructive Feedback']: [
        {
            line: 'Constructive Discussions: Emphasize the importance of providing fair and constructive feedback when discussing issues or concerns related to the platform. Encourage users to utilize appropriate channels for feedback.'
        }
    ],
    ['User Support and Assistance']: [
        {
            line: 'Access to Support: Provide users with information on how they can seek support or assistance from our platform in case they encounter any issues or have questions.'
        }
    ],
    ['Changes to Guidelines']: [
        {
            line: 'Guideline Updates: Explain the process by which these guidelines may be updated and notify users about any changes promptly.'
        }
    ],
    ['Medical Disclaimers']: [
        {
            line: 'Liability and Indemnity: Include medical disclaimers related to liability, indemnity, and other pertinent medical aspects to protect both your platform and its users.'
        },
        {
            line: 'By actively participating in Medasq, you acknowledge your commitment to fostering and preserving a respectful, positive, and ethical community where users and medical professionals can interact and access valuable medical services. Thank you for being an essential part of our thriving community.'
        },
        {
            line: 'For any questions or concerns regarding these guidelines, please do not hesitate to reach out to us at <a href = "mailto: contact@jurisnexa.com">[contact@jurisnexa.com]</a>.'
        },
        {
            line: 'Medasq Moderation Team'
        }
    ]
};
