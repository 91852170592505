import React from 'react';
import LocationDocs from './locationDocs';
const RequiredDocs = (props) => {
    const { educations, honors, associations, admissions, experiences, pharmacistObj, hospitalName } = props;
    return (
        <>
            {experiences?.map((item, index) => {
                if (item.verificationStatus == props.type) {
                    return (
                        <LocationDocs
                            verif={'experiences'}
                            docs={item.document}
                            id={item._id}
                            key={index}
                            ind={index}
                            hospitalName={hospitalName}
                            type={props.type}
                            css={props.type}
                            proof={'Experience' + ' ' + item.organization}
                            pharmacist={props.pharmacist}
                            pharmacistObj={pharmacistObj}
                            {...props}
                        />
                    );
                } else {
                    return null;
                }
            })}
            {educations?.map((item, index) => {
                if (item.verificationStatus == props.type) {
                    return (
                        <LocationDocs
                            verif={'educations'}
                            docs={item.document}
                            id={item._id}
                            key={index}
                            hospitalName={hospitalName}
                            type={props.type}
                            css={props.type}
                            proof={'Education' + ' ' + item.degree}
                            pharmacist={props.pharmacist}
                            pharmacistObj={pharmacistObj}
                            {...props}
                        />
                    );
                } else {
                    return null;
                }
            })}
            {associations?.map((item, index) => {
                if (item.verificationStatus == props.type) {
                    return (
                        <LocationDocs
                            verif={'associations'}
                            docs={item.document}
                            id={item._id}
                            key={index}
                            hospitalName={hospitalName}
                            type={props.type}
                            css={props.type}
                            proof={'Association' + ' ' + item.organization}
                            pharmacist={props.pharmacist}
                            pharmacistObj={pharmacistObj}
                            {...props}
                        />
                    );
                } else {
                    return null;
                }
            })}
            {honors?.map((item, index) => {
                if (item.verificationStatus == props.type) {
                    return (
                        <LocationDocs
                            verif={'honors'}
                            docs={item.document}
                            id={item._id}
                            key={index}
                            hospitalName={hospitalName}
                            type={props.type}
                            css={props.type}
                            proof={'Honors & Awards'}
                            pharmacist={props.pharmacist}
                            pharmacistObj={pharmacistObj}
                            {...props}
                        />
                    );
                } else {
                    return null;
                }
            })}
            {admissions?.map((item, index) => {
                if (item.verificationStatus == props.type) {
                    return (
                        <LocationDocs
                            verif={'admissions'}
                            docs={item.document}
                            id={item._id}
                            key={index}
                            hospitalName={hospitalName}
                            type={props.type}
                            css={props.type}
                            proof={'Admission' + " " + item.state}
                            pharmacist={props.pharmacist}
                            pharmacistObj={pharmacistObj}
                            {...props}
                        />
                    );
                } else {
                    return null;
                }
            })}
        </>
    );
};

export default RequiredDocs;
