import React, { useState, memo } from 'react';
import './notes.css';
import { TiMinus } from 'react-icons/ti';
import moment from 'moment';
import { Button, Empty } from 'antd';
import { RiDeleteBin6Line } from 'react-icons/ri';

const Notes = ({ notes, setNotes }) => {
    const [change, setChange] = useState('');

    const handleAddNotes = (e) => {
        if (notes.length === 0) {
            setNotes([
                {
                    value: change,
                    time: moment().clone().format('DD MMMM YYYY')
                }
            ]);
        } else {
            let newnotes = [...notes];
            newnotes = [
                ...newnotes,
                {
                    value: change,
                    time: moment().clone().format('DD MMMM YYYY')
                }
            ];
            setNotes(newnotes);
        }
        setChange('');
    };
    const handleChange = (e) => {
        setChange(e.target.value);
    };

    const deleteNote = (index) => {
        let n = notes.filter((item, ind) => index != ind);
        setNotes(n);
    };
    return (
        <div className='notes-main'>
            <div className='notes-header'>
                <p>Notes</p>

                <TiMinus />
                {/* <p>See all</p> */}
            </div>
            <div className='notes-body'>
                <div className='notesCont'>
                    <div className='notes-writing'>
                        <textarea value={change} onChange={handleChange} />
                    </div>
                    <div className='notes-btn-container'>
                        <Button type="primary" onClick={handleAddNotes}>
                            <p>Save note</p>
                        </Button>
                    </div>
                </div>

                <div className='notes-change'>Your notes</div>
                <div className='notes-container'>
                    {notes && notes.length ? (
                        notes.map((item, index) => {
                            if (item.value) {
                                return (
                                    <div key={(item, index)} className='notes-files-box'>
                                        <div className='notes-files-header'>
                                            <p>{item.value.substring(0, 10)}</p>
                                        </div>
                                        <div className='notes-time'>
                                            <p>{item.time}</p>
                                            <RiDeleteBin6Line onClick={() => deleteNote(index)} />
                                        </div>
                                    </div>
                                );
                            }
                        })
                    ) : (
                        <Empty description={'No notes uploaded'} />
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(Notes);
