import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';

let { REACT_APP_API_URI } = process.env;
if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const getNurses = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        if (props && props.obj) {
            Axios.post(`${REACT_APP_API_URI}/api/nurses/getNurses`, props.obj)
                .then((res) => {
                    if (res) {
                        if (props.obj.featured) {
                            dispatch({
                                type: Types.GET_FEATURED_NURSES,
                                payload: {
                                    nurses: res.data.nurses
                                }
                            });
                        } else {
                            dispatch({
                                type: Types.GET_NURSES,
                                payload: {
                                    nurses: res.data.nurses
                                }
                            });
                        }

                        return resolve(true);
                    }
                })
                .catch((error) => {
                    if (error && error.response) {
                        console.log(error.response.data);
                        // message.error("Nurses Data fetching failed");

                        if (props.obj.featured) {
                            dispatch({
                                type: Types.GET_FEATURED_NURSES,
                                payload: {
                                    nurses: []
                                }
                            });
                        } else {
                            dispatch({
                                type: Types.GET_NURSES,
                                payload: {
                                    nurses: []
                                }
                            });
                        }

                        // message.error(error.response.data.message[0].messages[0].message);
                        return resolve(false);
                    }
                });
        }
    });
};
export const getNurseById = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/nurses/getNurseDetail/${props.nurseId}`)
            .then((res) => {
                if (res) {
                    console.log('in here', history);

                    dispatch({
                        type: Types.GET_SINGLE_NURSE,
                        payload: {
                            nurseSingle: res.data.nurseSingle
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Nurse Data fetching failed');
                    history.push('/notFound');
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};


export const getNurseSearchResults = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/nurses/getNurseSearchResults`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_NURSE_SEARCH_RESULTS,
                        payload: {
                            nurses: res.data.nurses,
                            nursesCount: res.data.nursesCount
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error('Nurses retrieval failed');
                    dispatch({
                        type: Types.GET_NURSE_SEARCH_RESULTS,
                        payload: {
                            nurses: [],
                            nursesCount: 0
                        }
                    });
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getNurseStatCounts = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/nurses/getNurseStatCounts`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_NURSE_STAT_COUNTS,
                        payload: {
                            counts: res.data.counts
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getExtractedTimeSlots = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/nurses/getExtractedTimeSlots`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_NURSE_EXTRACTED_TIME_SLOTS,
                        payload: {
                            availableDays: res.data.availableDays,
                            timeSlots: res.data.timeSlots,
                            nurseAvailability: res.data.nurseAvailability
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    // message.error(error.response.data.message? error.response.data.message : "Server error");
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getMyDoctorTimeSlots = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/getExtractedTimeSlots`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_MY_DOCTOR_TIME_SLOTS,
                        payload: {
                            timeSlots: res.data.timeSlots,
                            doctorAvailability: res.data.doctorAvailability
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    // message.error(error.response.data.message? error.response.data.message : "Server error");
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getNurseForAppointment = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/nurses/getNurseForAppointment`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_NURSE_FOR_APPOINTMENT,
                        payload: {
                            nurse: res.data.nurse
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getShortDoctorList = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/nurses/getShortDoctorList`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_SHORT_DOCTOR_LIST,
                        payload: {
                            doctors: res.data.doctors
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getNurseDashboardInfo = (props, history) => async (dispatch) => {
    try {
        const response = await Axios.get(`${REACT_APP_API_URI}/api/nurses/getNurseDashboard`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        });
        dispatch({
            type: Types.GET_NURSE_DASHBOARD,
            payload: {
                dashboard: response.data.data
            }
        });
        return true;
    } catch (error) {
        if (error && error.response) {
            console.log(error.response.data);
            message.error(
                error.response.data.message ? error.response.data.message : 'Server error'
            );
            // message.error(error.response.data.message[0].messages[0].message);
            return false;
        }
    }
};

export const getLineChartData = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/nurses/getLineChartData`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_LINE_CHART_DATA,
                        payload: {
                            lineChart: res.data.lineChartData
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    dispatch({
                        type: Types.GET_LINE_CHART_DATA,
                        payload: {
                            lineChart: null
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

export const getPieChartData = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/nurses/getPieChartData`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_PIE_CHART_DATA,
                        payload: {
                            pieChart: res.data.pieChartData
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);

                    dispatch({
                        type: Types.GET_PIE_CHART_DATA,
                        payload: {
                            pieChart: []
                        }
                    });
                    message.error(
                        error.response.data.message ? error.response.data.message : 'Server error'
                    );
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};

