import React, { useState, useRef, useEffect } from 'react';
import ScrollMenu from 'react-horizontal-scrolling-menu';
import '@/assets/css/privacy.css';
import '@/assets/css/term.css';
import '@/assets/css/medicalDoc.css';
import '@/assets/css/marketingBanner.css';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import Content from './DocContent';
import TermsFooter from './Footer';
import MarketingPageHeader from '../common/MarketingPageHeader';

const SlideMenuItems = ({ text, selected }) => {
    return <div className={`menu-item ${selected ? 'me-active' : ''}`}>{text}</div>;
};

export const Menu = (list, selected) =>
    list.map((item, index) => {
        return <SlideMenuItems text={item} key={index} selected={selected} />;
    });

const Arrow = ({ text, className, click, setClick, hide }) => {
    return (
        <div
            onClick={() => setClick(!click)}
            style={{ fontSize: '2rem' }}
            className={hide ? `${className} hide` : className}
        >
            {text}
        </div>
    );
};

const TermsAndConditions = ({ heading, content, titles }) => {
    const [click, setClick] = useState(true);
    const [selected, setSelected] = useState(0);
    const [hidel, setHidel] = useState(false);
    const [hider, setHider] = useState(false);
    const [read, setHasRead] = useState(0);
    const [isFooterOpen, setIsFooterOpen] = useState(true);
    const initialReadStates = Array(titles.length).fill(false);
    const [readPages, setReadPages] = useState(
        initialReadStates.reduce((acc, _, index) => {
            acc[`page${index}`] = initialReadStates[index];
            return acc;
        }, {})
    );

    const ArrowLeft = Arrow({
        text: <FiChevronLeft style={{ fontSize: '5rem' }} setCount={setHasRead} count={read} />,
        className: 'arrow-prev',
        click: click,
        setClick: setClick,
        hide: hidel
    });
    const ArrowRight = Arrow({
        text: <FiChevronRight style={{ fontSize: '5rem' }} setCount={setHasRead} count={read} />,
        className: 'arrow-next',
        click: click,
        setClick: setClick,
        hide: hider
    });

    const onSelect = (key) => {
        setSelected(key);
    };

    let menu = Menu(titles, selected);

    let container = null;
    useEffect(() => {
        if (container) {
            if (container.state.translate < -30) {
                setHidel(false);
            } else {
                setHidel(true);
            }
            if (container.state.translate > -1200) {
                setHider(false);
            } else {
                setHider(true);
            }
        }
    }, [click]);

    return (
        <div className='terms-container'>
            <MarketingPageHeader heading={heading} dateTime={'10/9/2023'} />
            <div className='terms-scrollbar'>
                <div className='terms-underline'></div>
                <ScrollMenu
                    ref={(el) => (container = el)}
                    data={menu}
                    arrowLeft={ArrowLeft}
                    arrowRight={ArrowRight}
                    selected={selected}
                    onSelect={onSelect}
                />
            </div>
            <Content
                title={titles[selected]}
                content={content[titles[selected]]}
                hasRead={readPages[`page${selected}`]}
                setRead={(value) => setReadPages({ ...readPages, [`page${selected}`]: value })}
                setCount={setHasRead}
                count={read}
            />
            {isFooterOpen && (
                <TermsFooter
                    sections={titles}
                    read={read}
                    closeFooter={() => setIsFooterOpen(false)}
                    title={heading}
                />
            )}
        </div>
    );
};

export default TermsAndConditions;
