import React from 'react';
import TemplateList from './EmailTemplates/TemplateList';

function EmailTemplates(props) {
    return (
        <div className='adminTable'>
            <TemplateList {...props} />
        </div>
    );
}

export default EmailTemplates;
