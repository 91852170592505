import { lazy } from 'react';
import EmailConfirmation2 from '@components/emailConfirmation/emailConfirmation2';
import VerifyEmail from '@components/emailConfirmation/verifyEmail';
import HospitalView from '@components/NewProfile/HospitalProfile/hospitalView';
import NurseView from '@components/NewProfile/Shared/MemberDetailView';
import DoctorView from '@components/NewProfile/Shared/MemberDetailView';
import BlogSearchPage from '@components/publicBlogs/BlogSearchPage';
import GetMedicalAdvice from '../components/MedicalHelp/MedicalAdvice';
import ConsultantView from '../components/NewProfile/Shared/MemberDetailView';
import PharmacyView from '../components/NewProfile/PharmacyProfile/PharmacyView';
import TherapistView from '../components/NewProfile/Shared/MemberDetailView';
import CaregiverView from '../components/NewProfile/Shared/MemberDetailView';

const GoogleOAuthCallback = lazy(() => import('@components/OAuth/GoogleOAuthCallback'));
const OutlookOAuth2Callback = lazy(() => import('@components/OAuth/OutlookOAuth2Callback'));
const SearchResultsPage = lazy(() => import('@components/SearchResults/SearchResultsPage'));
const MedicalHelp = lazy(() => import('@components/MedicalHelp'));
const Index = lazy(() => import('@components/Index/index'));

const AboutUs = lazy(() => import('@components/AboutUs'));
const Pricing = lazy(() => import('@components/Pricing'));
const HowMedasqWorks = lazy(() => import('@components/HowMedasqWorks'));
const PartnerWithMedasq = lazy(() => import('@components/PartnerWithMedasq'));
const HelpCenter = lazy(() => import('@components/HelpCenter'));
const Faqs = lazy(() => import('@components/Faqs'));
const ContactUs = lazy(() => import('@components/ContactUs'));
const Blogs = lazy(() => import('@components/Blogs'));
const SinglePage = lazy(() => import('@components/Blogs/SingleView'));
const ViewProfile = lazy(() => import('@components/ViewProfile'));
const StripeRedirect = lazy(() => import('@components/StripeRedirect'));

export default [
    {
        exact: true,
        path: '/',
        component: Index
    },
    {
        exact: true,
        path: '/oauth2callback',
        component: GoogleOAuthCallback
    },
    {
        exact: true,
        path: '/outlookOAuth2Callback',
        component: OutlookOAuth2Callback
    },
    {
        exact: true,
        path: '/faqs',
        component: Faqs
    },
    {
        exact: true,
        path: '/pricing',
        component: Pricing
    },
    {
        exact: true,
        path: '/help-center',
        component: HelpCenter
    },
    {
        exact: true,
        path: '/view-profile/:id',
        component: ViewProfile
    },
    {
        exact: true,
        path: '/auth/activation/:token',
        component: EmailConfirmation2
    },
    {
        exact: true,
        path: '/auth/activated/:token',
        component: EmailConfirmation2
    },
    {
        exact: true,
        path: '/auth/verify-email/:token',
        component: VerifyEmail
    },
    {
        exact: true,
        path: '/our-mission',
        component: AboutUs
    },
    {
        exact: true,
        path: '/contact-us',
        component: ContactUs
    },
    {
        exact: true,
        path: '/patient-resources',
        component: HowMedasqWorks
    },
    {
        exact: true,
        path: '/partner-with-medasq',
        component: PartnerWithMedasq
    },
    {
        exact: true,
        path: '/hospital-results',
        component: SearchResultsPage
    },
    {
        exact: true,
        path: '/doctor-results',
        component: SearchResultsPage
    },
    {
        exact: true,
        path: '/nurse-results',
        component: SearchResultsPage
    },
    {
        exact: true,
        path: '/consultant-results',
        component: SearchResultsPage
    },
    {
        exact: true,
        path: '/pharmacy-results',
        component: SearchResultsPage
    },
    {
        exact: true,
        path: '/therapist-results',
        component: SearchResultsPage
    },
    {
        exact: true,
        path: '/caregiver-results',
        component: SearchResultsPage
    },
    {
        exact: true,
        path: '/hospital-view/:id',
        component: HospitalView
    },
    {
        exact: true,
        path: '/doctor-view/:id',
        component: DoctorView
    },
    {
        exact: true,
        path: '/nurse-view/:id',
        component: NurseView
    },
    {
        exact: true,
        path: '/therapist-view/:id',
        component: TherapistView
    },
    {
        exact: true,
        path: '/caregiver-view/:id',
        component: CaregiverView
    },
    {
        exact: true,
        path: '/consultant-view/:id',
        component: ConsultantView
    },
    {
        exact: true,
        path: '/pharmacy-view/:id',
        component: PharmacyView
    },
    {
        exact: true,
        path: '/blogs',
        component: Blogs
    },
    {
        switch: true, // Custom property indicating the route should be wrapped with a Switch
        routes: [
            {
                exact: true,
                path: '/blogs/search',
                component: BlogSearchPage
            },
            {
                exact: false,
                path: '/blogs/:postId',
                component: SinglePage
            }
        ]
    },
    {
        exact: true,
        path: '/medical-help',
        component: MedicalHelp
    },
    {
        exact: true,
        path: '/medical-help/get-medical-advice',
        component: GetMedicalAdvice
    },
    {
        exact: true,
        path: '/stripe-redirect',
        component: StripeRedirect
    }
];
