import React, { useEffect, useState, memo } from 'react';
import '@/assets/css/verifications.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import DocumentsType from '../Shared/Documents/documentsType';
import RegNoDocs from './Documents/regNoDocs';
import LocationDocs from './Documents/locationDocs';
import RequiredDocs from './Documents/requiredDocs';
import DocCaution from '../Shared/Documents/docCaution';
import { Progress } from 'antd';

const Verifications = (props) => {
    const [approvedBranch, setApprovedBranch] = useState(false);
    const [rejectedBranch, setRejectedBranch] = useState(false);
    const [pendingBranch, setPendingBranch] = useState(false);
    const [requiredBranch, setRequiredBranch] = useState(false);
    let [rejectedFiles, setRejectedFiles] = useState(0);
    let [requiredFiles, setRequiredFiles] = useState(0);
    let [pendingFiles, setPendingFiles] = useState(0);
    let [approvedFiles, setApprovedFiles] = useState(0);
    let [totalFiles, setTotalFiles] = useState(0);
    let [percentage, setPercentage] = useState(0);
    const { branches, profile } = props;

    const calculate = async (array) => {
        let required = 0,
            pending = 0,
            rejected = 0,
            approved = 0;
        await array.forEach((item) => {
            if (item.verificationStatus === 'REQUIRED') {
                console.log(requiredFiles);
                // setRequiredFiles(++requiredFiles)
                required++;
                setRequiredBranch(true);
            }
            if (item.verificationStatus === 'APPROVED') {
                // setApprovedFiles(++approvedFiles)
                approved++;
                setApprovedBranch(true);
            }
            if (item.verificationStatus === 'REJECTED') {
                // setRejectedFiles(++rejectedFiles)
                rejected++;
                setRejectedBranch(true);
            }
            if (item.verificationStatus === 'PENDING') {
                // setPendingFiles(++pendingFiles)
                pending++;
                setPendingBranch(true);
            }
        });
        return { required, pending, rejected, approved };
    };

    const calculateFiles = async () => {
        let count = 0;
        let required = 0;
        let rejected = 0;
        let pending = 0;
        let approved = 0;

        if (profile && profile.associations) {
            count = count + profile.associations.length;
            let newObj = await calculate(profile.associations);
            console.log(newObj, 'newObj');
            required = required + newObj.required;
            rejected = rejected + newObj.rejected;
            pending = pending + newObj.pending;
            approved = approved + newObj.approved;
        }

        if (profile && profile.honors) {
            count = count + profile.honors.length;
            let newObj = await calculate(profile.honors);
            required = required + newObj.required;
            rejected = rejected + newObj.rejected;
            pending = pending + newObj.pending;
            approved = approved + newObj.approved;
        }

        if (profile && profile.pharmacyRegistrationNumber) {
            count = count + 1;
            if (profile.pharmacyRegNoVerified == 'REQUIRED') {
                // setRequiredFiles(++requiredFiles)
                required++;
            }
            if (profile.pharmacyRegNoVerified == 'REJECTED') {
                // setRejectedFiles(++rejectedFiles)
                rejected++;
            }
            if (profile.pharmacyRegNoVerified == 'PENDING') {
                // setPendingFiles(++pendingFiles)
                pending++;
            }
            if (profile.pharmacyRegNoVerified == 'APPROVED') {
                // setApprovedFiles(++approvedFiles)
                approved++;
            }
        }

        if (branches && branches.length > 0) {
            count = count + branches.length;
            await Promise.all(
                branches.map(async (item) => {
                    console.log('got in here', branches);
                    if (item.locationVerified === 'REQUIRED') {
                        required++;
                        setRequiredBranch(true);
                    }
                    if (item.locationVerified === 'APPROVED') {
                        approved++;
                        setApprovedBranch(true);
                    }
                    if (item.locationVerified === 'REJECTED') {
                        rejected++;
                        setRejectedBranch(true);
                    }
                    if (item.locationVerified === 'PENDING') {
                        console.log('got in here');
                        pending++;
                        setPendingBranch(true);
                    }
                    let pharmacist = item?.pharmacist;
                    const creds = [
                        'associations',
                        'honors',
                        'educations',
                        'experiences',
                        'admissions'
                    ];
                    await Promise.all(
                        creds.map(async (cred) => {
                            if (pharmacist && pharmacist[cred]) {
                                count = count + pharmacist[cred].length;
                                let newObj = await calculate(pharmacist[cred]);
                                required = required + newObj.required;
                                rejected = rejected + newObj.rejected;
                                pending = pending + newObj.pending;
                                approved = approved + newObj.approved;
                            }
                        })
                    );
                })
            );
            // setBranchFiles(branches.length)
        }

        setRequiredFiles(required);
        setRejectedFiles(rejected);
        setPendingFiles(pending);
        setApprovedFiles(approved);
        setTotalFiles(count);
    };

    useEffect(() => {
        if (profile && branches) {
            calculateFiles();
        }
    }, [profile, branches]);

    useEffect(() => {
        if (totalFiles > 0 && approvedFiles > 0) {
            console.log('got in');
            setPercentage((100 * approvedFiles) / totalFiles);
        } else {
            setPercentage(0);
        }
    }, [totalFiles, approvedFiles]);

    const getOrgName = (item) => {
        return item?.pharmacist?.firstName
            ? item?.pharmacist?.firstName + ' ' + item?.pharmacist?.lastName + ' ( Pharmacist )'
            : profile.pharmacyName;
    };
    return (
        <div style={{ color: 'var(--bg-dark)' }}>
            <div className='heading-width-progress-bar'>
                <Progress type='circle' percent={percentage} />
                <div className='order-headings'>
                    <p>Upload Mandatory Documents for Verification</p>
                    <p>
                        We are dedicated to assisting law firms in establishing and maintaining
                        their online medical practice. Our digital software empowers you to
                        communicate and practice on a global scale. When seeking the appropriate
                        medical professionals to address your clients' needs, they will see a
                        verified badge on your public listing. Verified badges signify that the law
                        firm has been thoroughly vetted and approved by our verification agency.
                    </p>
                </div>
            </div>
            {/* <Progress done={percentage ? `${percentage}` : '0'} /> */}
            {profile.pharmacyRegNoVerified == 'REQUIRED' || requiredBranch ? (
                <div className='order-segments'>
                    <DocumentsType
                        title={'Mandatory Documentation'}
                        description={`Please review the following list of items required to submit your application.`}
                        document={'REQUIRED'}
                        remainingFiles={requiredFiles}
                        totalFiles={totalFiles}
                    />
                    <div className='order-sections'>
                        {profile.pharmacyRegNoVerified == 'REQUIRED' ? (
                            <RegNoDocs
                                type={'REQUIRED'}
                                hospitalName={profile && profile.pharmacyName}
                                regNo={profile.pharmacyRegistrationNumber}
                                css={'REQUIRED'}
                            />
                        ) : null}
                        {branches &&
                            branches.map((item, index) => {
                                if (item.locationVerified == 'REQUIRED') {
                                    return (
                                        <LocationDocs
                                            docs={item.document}
                                            proof={'Location'}
                                            id={item._id}
                                            branchId={item._id}
                                            ind={index}
                                            key={index}
                                            hospitalName={profile && profile.pharmacyName}
                                            branchAddress={item.location.branchAddress}
                                            type={'REQUIRED'}
                                            css={'REQUIRED'}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        <RequiredDocs
                            type={'REQUIRED'}
                            associations={props.profile.associations}
                            hospitalName={profile?.pharmacyName}
                        />
                        <RequiredDocs
                            type={'REQUIRED'}
                            honors={props.profile.honors}
                            hospitalName={profile?.pharmacyName}
                        />

                        {branches &&
                            branches.map((item, index) => {
                                const pharmacist = item?.pharmacist;
                                return (
                                    <>
                                        <RequiredDocs
                                            type={'REQUIRED'}
                                            associations={pharmacist?.associations}
                                            branchId={item._id}
                                            pharmacist={true}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'REQUIRED'}
                                            honors={pharmacist?.honors}
                                            branchId={item._id}
                                            pharmacist={true}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'REQUIRED'}
                                            educations={pharmacist?.educations}
                                            branchId={item._id}
                                            pharmacist={true}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'REQUIRED'}
                                            honors={pharmacist?.experiences}
                                            branchId={item._id}
                                            pharmacist={true}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'REQUIRED'}
                                            associations={pharmacist?.admissions}
                                            branchId={item._id}
                                            pharmacist={true}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                    </>
                                );
                            })}
                    </div>
                </div>
            ) : null}
            {profile.pharmacyRegNoVerified == 'REJECTED' || rejectedBranch ? (
                <div className='order-segments'>
                    <DocumentsType
                        title={'Rejected Documents'}
                        document={'REJECTED'}
                        remainingFiles={rejectedFiles}
                        totalFiles={totalFiles}
                    />
                    <div className='order-sections'>
                        {profile.pharmacyRegNoVerified == 'REJECTED' ? (
                            <RegNoDocs
                                type={'REJECTED'}
                                hospitalName={profile && profile.pharmacyName}
                                regNo={profile.pharmacyRegistrationNumber}
                                css={'REQUIRED'}
                            />
                        ) : null}
                        {branches &&
                            branches.map((item, index) => {
                                if (item.locationVerified == 'REJECTED') {
                                    return (
                                        <LocationDocs
                                            docs={item.document}
                                            proof={'Location'}
                                            id={item._id}
                                            key={index}
                                            hospitalName={profile && profile.pharmacyName}
                                            branchAddress={item.location.branchAddress}
                                            type={'REJECTED'}
                                            css={'REQUIRED'}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        <RequiredDocs
                            type={'REJECTED'}
                            associations={props.profile.association}
                            hospitalName={profile?.pharmacyName}
                        />
                        <RequiredDocs
                            type={'REJECTED'}
                            honors={props.profile.honors}
                            hospitalName={profile?.pharmacyName}
                        />

                        {branches &&
                            branches.map((item, index) => {
                                const pharmacist = item?.pharmacist;
                                return (
                                    <>
                                        <RequiredDocs
                                            type={'REJECTED'}
                                            associations={pharmacist?.associations}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'REJECTED'}
                                            honors={pharmacist?.honors}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'REJECTED'}
                                            admissions={pharmacist?.admissions}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'REJECTED'}
                                            educations={pharmacist?.educations}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'REJECTED'}
                                            experiences={pharmacist?.experiences}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                    </>
                                );
                            })}
                    </div>
                </div>
            ) : null}
            {profile.pharmacyRegNoVerified == 'PENDING' || pendingBranch ? (
                <div className='order-segments'>
                    <DocumentsType
                        title={`Pending Verification`}
                        description={`We are in the process of verifying your documents, and this may take some time. Thank you for your patience.`}
                        document={'PENDING'}
                        remainingFiles={pendingFiles}
                        totalFiles={totalFiles}
                    />
                    <div className='order-sections'>
                        {profile.pharmacyRegNoVerified == 'PENDING' ? (
                            <RegNoDocs
                                type={'Pending'}
                                hospitalName={profile && profile.pharmacyName}
                                regNo={profile.pharmacyRegistrationNumber}
                                css={'PENDING'}
                            />
                        ) : null}
                        {branches &&
                            branches.map((item, index) => {
                                if (item.locationVerified == 'PENDING') {
                                    return (
                                        <LocationDocs
                                            docs={item.document}
                                            proof={'Location'}
                                            id={item._id}
                                            key={index}
                                            hospitalName={profile && profile.pharmacyName}
                                            branchAddress={item?.location?.branchAddress}
                                            type={'PENDING'}
                                            css={'PENDING'}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        <RequiredDocs
                            type={'PENDING'}
                            associations={props.profile.associations}
                            hospitalName={profile?.pharmacyName}
                        />
                        <RequiredDocs
                            type={'PENDING'}
                            honors={props.profile.honors}
                            hospitalName={profile?.pharmacyName}
                        />

                        {branches &&
                            branches.map((item, index) => {
                                const pharmacist = item?.pharmacist;
                                return (
                                    <>
                                        <RequiredDocs
                                            type={'PENDING'}
                                            associations={pharmacist?.associations}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'PENDING'}
                                            honors={pharmacist?.honors}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'PENDING'}
                                            educations={pharmacist?.educations}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'PENDING'}
                                            admissions={pharmacist?.admissions}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'PENDING'}
                                            experiences={pharmacist?.experiences}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                    </>
                                );
                            })}
                    </div>
                </div>
            ) : null}
            {profile.pharmacyRegNoVerified == 'APPROVED' || approvedBranch ? (
                <div className='order-segments'>
                    <DocumentsType
                        title={`Verification Completed`}
                        description={`We have successfully verified the following documents, and your verified badge is now displayed on your profile.`}
                        document={'APPROVED'}
                        remainingFiles={approvedFiles}
                        totalFiles={totalFiles}
                    />
                    <div className='order-sections'>
                        {profile.pharmacyRegNoVerified == 'APPROVED' ? (
                            <RegNoDocs
                                type={'APPROVED'}
                                hospitalName={profile && profile.pharmacyName}
                                regNo={profile.lawFirmRegistrationNumber}
                                css={'APPROVED'}
                            />
                        ) : null}
                        {branches &&
                            branches.map((item, index) => {
                                if (item.locationVerified == 'APPROVED') {
                                    return (
                                        <LocationDocs
                                            docs={item.document}
                                            proof={'Location'}
                                            id={item._id}
                                            key={index}
                                            hospitalName={profile && profile.pharmacyName}
                                            branchAddress={item.location.branchAddress}
                                            type={'APPROVED'}
                                            css={'APPROVED'}
                                        />
                                    );
                                } else {
                                    return null;
                                }
                            })}

                        <RequiredDocs
                            type={'APPROVED'}
                            associations={props.profile.associations}
                            hospitalName={profile?.pharmacyName}
                        />
                        <RequiredDocs
                            type={'APPROVED'}
                            honors={props.profile.honors}
                            hospitalName={profile?.pharmacyName}
                        />

                        {branches &&
                            branches.map((item, index) => {
                                const pharmacist = item?.pharmacist;
                                return (
                                    <>
                                        <RequiredDocs
                                            type={'APPROVED'}
                                            associations={pharmacist?.associations}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'APPROVED'}
                                            honors={pharmacist?.honors}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'APPROVED'}
                                            educations={pharmacist?.educations}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'APPROVED'}
                                            admissions={pharmacist?.admissions}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                        <RequiredDocs
                                            type={'APPROVED'}
                                            experiences={pharmacist?.experiences}
                                            pharmacist
                                            branchId={item._id}
                                            pharmacistObj={item.pharmacist}
                                            hospitalName={getOrgName(item)}
                                        />
                                    </>
                                );
                            })}
                    </div>
                </div>
            ) : null}

            <DocCaution />
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    branch: state.branch,
    branches: state.branch && state.branch.branches && state.branch.branches
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(Verifications));
