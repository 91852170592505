import React, { useState, useEffect } from 'react';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import Table from '@/components/NewProfile/Shared/Table';
import ProspectsHeader from './ProspectsHeader';
import PageHeader from '@/components/NewProfile/Shared/PageHeader';
import * as userActions from '@/actions/userActions';
import * as prospectActions from '@/actions/prospectActions';
import useWindowSize from '@/hooks/useWindowSize'

const Prospect = (props) => {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const {width} = useWindowSize()
    const { userId } = useSelector((state) => state.auth);
    const { prospects, prospectsCount } = useSelector((state) => state.prospect);

    useEffect(() => {
        let obj = {
            userId: userId
        };
        props.prospectActions.getProspects({ ...props, obj }, props.history);
    }, []);

    const columns = [
        {
            title: <span className='dragHandler'>Date</span>,
            dataIndex: 'createdAt',
            width: 140,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.createdAt) return -1; // Handle empty values
                if (!b.createdAt) return 1; // Handle empty values
                return a.createdAt.localeCompare(b.createdAt);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 140 }
                };
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {new Date(date).toLocaleDateString('en-US', {
                            year: 'numeric',
                            month: 'short',
                            day: 'numeric'
                        })}
                    </div>
                );
            }
        },
        {
            title: <span className='dragHandler'>Name</span>,
            width: width > 1000 ? 700 : 500,
            dataIndex: 'prospect',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.prospect.firstName) return -1; // Handle empty values
                if (!b.prospect.firstName) return 1; // Handle empty values
                return a.prospect.firstName.localeCompare(b.prospect.firstName);
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                const fullName = `${record?.prospect?.firstName} ${record?.prospect?.lastName}`;
                return <div>{fullName}</div>;
            }
        },
        {
            title: <span className='dragHandler'>Email</span>,
            width: 200,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.prospect?.email) return -1; // Handle empty values
                if (!b.prospect?.email) return 1; // Handle empty values
                return a.prospect?.email.localeCompare(b.prospect?.email);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div></div>;
                if (!record.prospect?.email) return <div>Not available</div>;
                return <div>{record.prospect?.email}</div>;
            }
        },
        {
            title: <span className='dragHandler'>Phone No.</span>,
            width: 200,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.prospect?.phoneNo) return -1; // Handle empty values
                if (!b.prospect?.phoneNo) return 1; // Handle empty values
                return a.prospect?.phoneNo.localeCompare(b.prospect?.phoneNo);
            },
            render: (_, record) => {
                if (isEmptyObject(record)) return <div></div>;
                if (!record.prospect?.phoneNo) return <div>Not available</div>;
                return (
                    <div>
                        {'(' + record.prospect?.countryCode + ') -' + record.prospect?.phoneNo}
                    </div>
                );
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Action</span>,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <div>No Actions Available</div>;
            }
        }
    ];
    const numberOfBlankRows = limit - prospects.length;
    let dataSource = [...prospects, ...Array.from({ length: numberOfBlankRows }).map(() => ({}))];
    return (
        <div>
            <PageHeader heading={'Prospects'} />
            <ProspectsHeader />

            <Table
                tableName="Prospects"
                columns={columns}
                data={dataSource}
                pagination={{
                    showQuickJumper: true,
                    current: page,
                    total: prospectsCount,
                    onChange: (val) => setPage(val),
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20],
                    showSizeChanger: true,
                    responsive: true,
                    onShowSizeChange: (size, newSize) => setLimit(newSize)
                }}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    prospect: state.prospect
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    prospectActions: bindActionCreators(prospectActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Prospect);
