import React, { useEffect, useState } from 'react';
import * as adminActions from '@/actions/adminActions';
import { useDispatch, useSelector } from 'react-redux';
import { Popover, Pagination, Select } from 'antd';
const { Option } = Select;

function GeneralMail(props) {
    const dispatch = useDispatch();
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(10);
    const admin = useSelector((state) => state.admin);
    const { mailDataList } = admin;
    const { general, generalCount } = mailDataList;
    useEffect(() => {
        let obj = {
            page,
            perPage: limit,
            type: 'General'
        };
        dispatch(adminActions.getMailListings({ ...props, obj }));
    }, [limit, page]);
    let items = [];
    items = general;
    const statusOptions = ['PENDING', 'PUBLISHED', 'ARCHIVED'];
    const changeActiveStatus = async (val, item) => {
        let obj = {
            mailId: item._id,
            status: val,
            type: 'General'
        };
        let updateMail = await dispatch(adminActions.updateMail({ ...props, obj }));
        if (updateMail) {
            let obj = {
                page,
                perPage: limit,
                type: 'General'
            };
            dispatch(adminActions.getMailListings({ ...props, obj }));
        }
    };
    return (
        <div className='adminTable'>
            <h1>
                {' '}
                <i
                    style={{ cursor: 'pointer' }}
                    onClick={() => props.history.push('/admin/mail-listing')}
                    className='fe fe-chevron-left'
                />{' '}
                General Mail
            </h1>

            <div className='table'>
                <table>
                    <thead>
                        <tr className='tableHeader'>
                            <th>ID</th>
                            <th>NAME</th>
                            <th>EMAIL</th>
                            <th>MESSAGE</th>
                            <th>STATUS</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {items &&
                            items.map((item, index) => (
                                <tr className='breakrow'>
                                    <td>
                                        <div>{index + 1}</div>
                                    </td>
                                    <td>
                                        <div>{item.firstName + ' ' + item.lastName}</div>
                                    </td>
                                    <td>
                                        <div>{item.email}</div>
                                    </td>
                                    <td>
                                        <div>{item.message}</div>
                                    </td>
                                    <td>
                                        <div>
                                            <Select
                                                className='activeStatusSelect'
                                                onChange={(val, obj) =>
                                                    changeActiveStatus(val, item)
                                                }
                                                placeholder='Active Status'
                                                value={item.status && item.status}
                                                selection
                                            >
                                                {statusOptions &&
                                                    statusOptions.map((item, index) => (
                                                        <Select.Option key={index} value={item}>
                                                            {item}
                                                        </Select.Option>
                                                    ))}
                                            </Select>
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <Popover content='Edit status'>
                                                <i className='fe fe-edit' />
                                            </Popover>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            <Pagination
                current={page}
                total={generalCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </div>
    );
}

export default GeneralMail;
