import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '../../actions/adminActions';
import * as userActions from '../../actions/userActions';
import Currencies from '../../assets/json/Currencies.json';
import languageOptions from '../../assets/json/Languages.json';
import Countries from '../../assets/json/Countries.json';
import { DatePicker } from 'antd';
import { Select } from 'antd';
import validator from 'validator';
import moment from 'moment';
import ReactDOM from 'react-dom';
import NavigationPrompt from 'react-router-navigation-prompt';
import { message } from 'antd';
import Geolocate from './../MiniComponents/Geolocate';
import { geocodeByAddress } from 'react-places-autocomplete';
import girl2 from '../../assets/img/girl2.jpg';
import { UploadOutlined } from '@ant-design/icons';
import '../../assets/css/adminEditProfile.css';

const gender = [
    { key: 'male', text: 'Male', value: 'Male' },
    { key: 'female', text: 'Female', value: 'Female' },
    { key: 'other', text: 'Other', value: 'Other' }
];
const statusOptions = ['ACTIVATED', 'DEACTIVATED', 'SUSPENDED'];
class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = {
            infoActive: false,
            profCompDiv: true,
            locality: localStorage.getItem('locality') ? localStorage.getItem('locality') : '',
            localityCountry: localStorage.getItem('localityCountry')
                ? localStorage.getItem('localityCountry')
                : '',
            formDirty: false,
            fixedFirstName: '',
            fixedLastName: '',
            businessName: { value: '', message: '', isValid: true },
            firstName: { value: '', message: '', isValid: true },
            lastName: { value: '', message: '', isValid: true },
            phoneNo: { value: '', message: '', isValid: true },
            countryCode: { value: '', message: '', isValid: true },
            email: { value: '', message: '', isValid: true },
            password: { value: '', message: '', isValid: true },
            gender: { value: '', message: '', isValid: true },
            language: { value: '', message: '', isValid: true },
            currency: { value: '', message: '', isValid: true },
            activeStatus: { value: '', message: '', isValid: true },
            dob: { value: '', message: '', isValid: true },
            kitchenTypes: [],
            deliveryOpts: [],
            social: '',
            currencyDrop: false,
            countryDrop: false,
            countryDrop1: false,
            countryDrop2: false,
            filteredCurrencies: Currencies,
            filteredCountryCode: Countries,
            toolTip: '',
            profCompDiv: true,
            formContactDirty: false,
            formProfileDirty: false,
            formAccountDirty: false,
            address: { value: '', isValid: true, message: '' },
            addressLineOne: { value: '', isValid: true, message: '' },
            billingAddress: { value: '', isValid: true, message: '' },
            billingCity: { value: '', isValid: true, message: '' },
            billingState: { value: '', isValid: true, message: '' },
            billingZip: { value: '', isValid: true, message: '' },
            billingCountry: { value: '', isValid: true, message: '' },
            billingCoordinates: { lat: '', lng: '' },
            contactEmail: { value: '', message: '', isValid: true },
            contactPhone: { value: '', message: '', isValid: true },
            contactCountryCode: { value: '', message: '', isValid: true },
            businessProfile: { value: '', isValid: true, message: '' },
            profileSummary: { value: '', isValid: true, message: '' },
            locality: localStorage.getItem('locality') ? localStorage.getItem('locality') : '',
            localityCountry: localStorage.getItem('localityCountry')
                ? localStorage.getItem('localityCountry')
                : '',
            profileCompletion: {},
            infoToolTip: false
        };

        this.curr = React.createRef();
        this.deleteAddressInfo = this.deleteAddressInfo.bind(this);
        this.changeAddressInfo = this.changeAddressInfo.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.updateBillingAddress = this.updateBillingAddress.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        // if (props.businessProfile && props.businessProfile.token) {
        //   localStorage.setItem("auth_token", props.businessProfile.token);
        // }

        const { businessProfile } = props.admin;
        if (state.formDirty == false) {
            if (props.auth.isAuthenticated && businessProfile) {
                if (businessProfile.firstName) {
                    state.firstName.value = businessProfile.firstName;
                    state.fixedFirstName = businessProfile.firstName;
                }
                if (businessProfile.lastName) {
                    state.lastName.value = businessProfile.lastName;
                    state.fixedLastName = businessProfile.lastName;
                }
                if (businessProfile.dob) {
                    state.dob.value = businessProfile.dob;
                }
                if (businessProfile.phoneNo) {
                    state.phoneNo.value = businessProfile.phoneNo;
                }
                if (businessProfile.countryCode) {
                    state.countryCode.value = businessProfile.countryCode;
                }
                if (businessProfile.email) {
                    state.email.value = businessProfile.email;
                }
                if (localStorage.getItem('currency')) {
                    state.currency.value = localStorage.getItem('currency');
                }
                if (businessProfile.social) {
                    state.social = businessProfile.social;
                }
                if (businessProfile.gender) {
                    state.gender.value = businessProfile.gender;
                }

                if (businessProfile.dob) {
                    state.dob.value = businessProfile.dob;
                }
                if (businessProfile.language) {
                    state.language.value = businessProfile.language;
                }

                if (businessProfile.deliveryOpts) {
                    state.deliveryOpts = businessProfile.deliveryOpts;
                }

                if (businessProfile.kitchenTypes) {
                    state.kitchenTypes = businessProfile.kitchenTypes;
                }

                if (businessProfile.profileSummary) {
                    state.profileSummary.value = businessProfile.profileSummary;
                }

                if (businessProfile.businessName) {
                    state.businessName.value = businessProfile.businessName;
                }
                if (businessProfile.activeStatus) {
                    state.activeStatus.value = businessProfile.activeStatus;
                }

                if (businessProfile.contact) {
                    if (businessProfile.contact.email) {
                        state.contactEmail.value = businessProfile.contact.email;
                    }
                    if (businessProfile.contact.phoneNo) {
                        state.contactPhone.value = businessProfile.contact.phoneNo;
                    }
                    if (businessProfile.contact.countryCode) {
                        state.contactCountryCode.value = businessProfile.contact.countryCode;
                    }
                    if (businessProfile.contact.billing) {
                        if (businessProfile.contact.billing.billingAddress) {
                            state.billingAddress.value =
                                businessProfile.contact.billing.billingAddress;
                        }
                        if (businessProfile.contact.billing.billingCity) {
                            state.billingCity.value = businessProfile.contact.billing.billingCity;
                        }
                        if (businessProfile.contact.billing.billingState) {
                            state.billingState.value = businessProfile.contact.billing.billingState;
                        }
                        if (businessProfile.contact.billing.billingZip) {
                            state.billingZip.value = businessProfile.contact.billing.billingZip;
                        }
                        if (businessProfile.contact.billing.billingCountry) {
                            state.billingCountry.value =
                                businessProfile.contact.billing.billingCountry;
                        }
                    }
                }
            }
        }
        return state;
    }

    componentDidMount() {
        let profileData = {
            userId: this.props.match.params.businessUserId
        };
        this.props.adminActions.getBusinessProfile(
            { profileData, ...this.props },
            this.props.history
        );
        window.addEventListener('mouseup', (e) => {
            let ccLabel = document.querySelector('.cc-label');
            let ccLabel1 = document.getElementById('countryCode1');
            let ccLabel2 = document.getElementById('countryCode2');
            if (
                this.curr.current &&
                !this.curr.current.contains(e.target) &&
                this.state.currencyDrop
            ) {
                this.setState({ currencyDrop: false });
            }
            if (ccLabel && !ccLabel.contains(e.target) && this.state.countryDrop) {
                this.setState({ countryDrop: false });
            }
            if (ccLabel1 && !ccLabel1.contains(e.target) && this.state.countryDrop1) {
                this.setState({ countryDrop1: false });
            }

            if (ccLabel2 && !ccLabel2.contains(e.target) && this.state.countryDrop2) {
                this.setState({ countryDrop2: false });
            }
        });
    }

    onChange(e) {
        var state = this.state;
        if (e.target.name !== 'kitchenTypes' && e.target.name !== 'deliveryOpts') {
            state[e.target.name].value = e.target.value;
        }
        state.formDirty = true;

        if (e.target.name == 'firstName') {
            if (e.target.value == '') {
                state['firstName'].isValid = false;
                state['firstName'].message = 'First name cannot be left empty';
            } else {
                state['firstName'].isValid = true;
                state['firstName'].message = '';
            }
        }

        if (e.target.name == 'lastName') {
            if (e.target.value == '') {
                state['lastName'].isValid = false;
                state['lastName'].message = 'First name cannot be left empty';
            } else {
                state['lastName'].isValid = true;
                state['lastName'].message = '';
            }
        }

        if (e.target.name == 'businessName') {
            if (e.target.value == '') {
                state['businessName'].isValid = false;
                state['businessName'].message = 'Business name cannot be left empty';
            } else {
                state['businessName'].isValid = true;
                state['businessName'].message = '';
            }
        }

        if (e.target.name == 'businessName') {
            if (e.target.value == '') {
                state['businessName'].isValid = false;
                state['businessName'].message = 'Business name cannot be left empty';
            } else {
                state['businessName'].isValid = true;
                state['businessName'].message = '';
            }
        }

        if (e.target.name == 'email') {
            if (e.target.value == '') {
                state['email'].isValid = false;
                state['email'].message = 'Email cannot be left empty';
            } else if (!validator.isEmail(e.target.value)) {
                state['email'].isValid = false;
                state['email'].message = 'Not a valid email address';
            } else {
                state['email'].isValid = true;
                state['email'].message = '';
            }
        }

        if (e.target.name == 'phoneNo') {
            if (e.target.value == '') {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number cannot be left empty';
            } else if (e.target.value.length !== 10) {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number must be 10 digits';
            } else if (!e.target.value.match(/^\d+$/)) {
                state['phoneNo'].isValid = false;
                state['phoneNo'].message = 'Phone number must only contain numbers';
            } else {
                state['phoneNo'].isValid = true;
                state['phoneNo'].message = '';
            }
        }

        if (e.target.name == 'contactPhone') {
            if (e.target.value == '') {
                state['contactPhone'].isValid = false;
                state['contactPhone'].message = 'Phone number cannot be left empty';
            } else if (e.target.value.length !== 10) {
                state['contactPhone'].isValid = false;
                state['contactPhone'].message = 'Phone number must be 10 digits';
            } else if (!e.target.value.match(/^\d+$/)) {
                state['contactPhone'].isValid = false;
                state['contactPhone'].message = 'Phone number must only contain numbers';
            } else {
                state['contactPhone'].isValid = true;
                state['contactPhone'].message = '';
            }
        }

        if (e.target.name == 'businessPhoneNo') {
            if (e.target.value == '') {
                state['businessPhoneNo'].isValid = false;
                state['businessPhoneNo'].message = 'Business Phone number cannot be left empty';
            } else if (e.target.value.length !== 10) {
                state['businessPhoneNo'].isValid = false;
                state['businessPhoneNo'].message = 'Phone number must be 10 digits';
            } else if (!e.target.value.match(/^\d+$/)) {
                state['businessPhoneNo'].isValid = false;
                state['businessPhoneNo'].message = 'Phone number must only contain numbers';
            } else {
                state['businessPhoneNo'].isValid = true;
                state['businessPhoneNo'].message = '';
            }
        }

        if (e.target.name == 'contactEmail') {
            if (e.target.value == '') {
                state['contactEmail'].isValid = false;
                state['contactEmail'].message = 'Email cannot be left empty';
            } else if (!validator.isEmail(e.target.value)) {
                state['contactEmail'].isValid = false;
                state['contactEmail'].message = 'Not a valid email address';
            } else {
                state['contactEmail'].isValid = true;
                state['contactEmail'].message = '';
            }
        }

        if (e.target.name == 'countryCode') {
            if (e.target.value == '') {
                state['countryCode'].isValid = false;
                state['countryCode'].message = 'Country code cannot be left empty';
            }

            if (!e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state['countryCode'].isValid = false;
                state['countryCode'].message = 'Country code must only contain numbers';
            }

            if (e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state['countryCode'].isValid = true;
                state['countryCode'].message = '';
            }
        }

        if (e.target.name == 'contactCountryCode') {
            if (e.target.value == '') {
                state['contactCountryCode'].isValid = false;
                state['contactCountryCode'].message = 'Country code cannot be left empty';
            }

            if (!e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state['contactCountryCode'].isValid = false;
                state['contactCountryCode'].message = 'Country code must only contain numbers';
            }
            if (e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state['contactCountryCode'].isValid = true;
                state['contactCountryCode'].message = '';
            }
        }

        if (e.target.name == 'businessCountryCode') {
            if (e.target.value == '') {
                state['businessCountryCode'].isValid = false;
                state['businessCountryCode'].message = 'Country code cannot be left empty';
            }

            if (!e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state['businessCountryCode'].isValid = false;
                state['businessCountryCode'].message = 'Country code must only contain numbers';
            }
            if (e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state['businessCountryCode'].isValid = true;
                state['businessCountryCode'].message = '';
            }
        }

        if (e.target.name == 'businessEmail') {
            if (e.target.value == '') {
                state['businessEmail'].isValid = false;
                state['businessEmail'].message = 'Business Email cannot be left empty';
            } else if (!validator.isEmail(e.target.value)) {
                state['businessEmail'].isValid = false;
                state['businessEmail'].message = 'Not a valid email address';
            } else {
                state['businessEmail'].isValid = true;
                state['businessEmail'].message = '';
            }
        }

        if (e.target.name == 'deliveryOpts') {
            console.log(state.deliveryOpts, e.target.value);
            if (e.target.checked) {
                if (!state.deliveryOpts.includes(e.target.value)) {
                    let temp = state.deliveryOpts;
                    temp.push(e.target.value);
                    state['deliveryOpts'] = temp;
                }
            } else {
                let temp = state.deliveryOpts.filter((item) => {
                    return item !== e.target.value;
                });
                console.log(temp);
                state['deliveryOpts'] = temp;
            }
        }

        if (e.target.name == 'kitchenTypes') {
            console.log(state.kitchenTypes, e.target.value);
            if (e.target.checked) {
                if (!state.kitchenTypes.includes(e.target.value)) {
                    let temp = state.kitchenTypes;
                    temp.push(e.target.value);
                    state['kitchenTypes'] = temp;
                }
            } else {
                let temp = state.kitchenTypes.filter((item) => {
                    return item !== e.target.value;
                });

                state['kitchenTypes'] = temp;
            }
        }

        this.setState(state);
    }

    deleteAddressInfo(index) {
        let ind = index;
        let arr = this.state.addressInfo;
        let tempAddressInfo = this.state.addressInfo;
        if (tempAddressInfo.length > 1) {
            let newTemp = tempAddressInfo.splice(index, 1);
            console.log(tempAddressInfo);
            this.setState({ addressInfo: tempAddressInfo });
        }
    }

    changeAddressInfo(e, index) {
        let temp = this.state.addressInfo;
        console.log(temp, index);
        temp[index].city = e.target.value;
        console.log(temp);
        this.setState({ addressInfo: temp });
    }
    onChangeDate(date, dateString) {
        console.log(date, dateString);
        this.setState({
            formDirty: true,
            dob: { value: dateString, isValid: true, message: '' }
        });
    }

    saveProfile = async (e) => {
        let state = this.state;
        let data = null;
        let section = null;

        // if (
        //   state.businessName.value &&
        //   state.businessAddress.value &&
        //   state.businessCity.value &&
        //   state.businessCountry.value &&
        //   state.businessCountryCode.value &&
        //   state.businessPhoneNo.value &&
        //   state.businessPhoneNo.isValid &&
        //   state.businessEmail.value &&
        //   state.businessEmail.isValid &&
        //   state.businessState.value &&
        //   state.businessZip.value
        // ) {
        let profileObj = {
            firstName: state.firstName.value,
            lastName: state.lastName.value,
            gender: state.gender.value,
            dob: state.dob.value,
            profileSummary: state.profileSummary.value,
            email: state.email.value,
            phoneNo: state.phoneNo.value,
            countryCode: state.countryCode.value.replace('+', ''),
            language: state.language.value,
            currency: state.currency.value,
            password: state.password.value,
            businessName: state.businessName.value,
            activeStatus: state.activeStatus.value,
            contact: {
                email: state.contactEmail.value,
                phoneNo: state.contactPhone.value,
                countryCode: state.contactCountryCode.value.replace('+', ''),
                billing: {
                    billingAddress: state.billingAddress.value,
                    billingCity: state.billingCity.value,
                    billingState: state.billingState.value,
                    billingZip: state.billingZip.value,
                    billingCountry: state.billingCountry.value,
                    billingCoordinates: state.billingCoordinates
                }
            }
            // business: {
            //   name: state.businessName.value,
            //   email: state.businessEmail.value,
            //   phoneNo: state.businessPhoneNo.value,
            //   countryCode: state.businessCountryCode.value.replace("+", ""),
            //   businessProfile: state.businessProfile.value,
            //   location: {
            //     businessAddress: state.businessAddress.value,
            //     businessCity: state.businessCity.value,
            //     businessState: state.businessState.value,
            //     businessZip: state.businessZip.value,
            //     businessCountry: state.businessCountry.value,
            //   },
            // },
            // social: { ...state.social },
            // kitchenTypes: [...state.kitchenTypes],
            // deliveryOpts: [...state.deliveryOpts],
        };
        console.log(profileObj);

        let profileData = {
            data: profileObj,
            userType: this.props.admin.businessProfile.userType,
            userId: this.props.match.params.businessId
            // section: section,
        };
        await this.props.actions.saveProfile(profileData, this.props);
        state.formDirty = false;
        this.setState(state);
        let profileShort = {
            userType: this.props.admin.businessProfile.userType,
            userId: this.props.match.params.businessId
        };
        // await this.props.actions.getProfile(profileShort, this.props);
        this.props.history.push('/admin/businesses');
        // } else {
        //   message.error("Fill up your Business Information!");
        // }
    };

    async updateAddress(addr) {
        console.log(addr);
        this.setState({ formDirty: true });
        var placeSearch, autocomplete;
        var componentForm = {
            street_number: '',
            route: '',
            postal_town: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            formattedAddress: '',
            neighbourhood: ''
        };
        let address = await geocodeByAddress(addr);
        if (address) console.log(address);
        address = address[0];
        componentForm.formattedAddress = address.formatted_address;
        for (var i = 0; i < address.address_components.length; i++) {
            var addressType = address.address_components[i].types[0];
            console.log(addressType);
            if (componentForm[addressType] == '') {
                let val = address.address_components[i][componentForm[addressType]];
                componentForm[addressType] = address.address_components[i].long_name;
            }
        }

        console.log(componentForm);
        let state = this.state;
        // debugger
        // if (componentForm.postal_code !== "") {
        //   state.businessAddress = {
        //     value: componentForm.postal_code,
        //     isValid: true,
        //     message: "",
        //   };
        // } else if (componentForm.locality !== "") {
        //   state.businessAddress = {
        //     value: componentForm.locality,
        //     isValid: true,
        //     message: "",
        //   };
        // } else if (componentForm.country !== "" || componentForm.postal_code !== "")
        //   state.businessAddress = {
        //     value: componentForm.country,
        //     isValid: true,
        //     message: "",
        //   };
        if (componentForm.country !== '')
            state.businessCountry = {
                value: componentForm.country,
                isValid: true,
                message: ''
            };
        // debugger
        if (componentForm.postal_code !== '')
            state.businessZip = {
                value: componentForm.postal_code,
                isValid: true,
                message: ''
            };
        state.businessCity = {
            value:
                componentForm.postal_town != 'Town' && componentForm.postal_town
                    ? componentForm.postal_town
                    : componentForm.locality != 'locality'
                    ? componentForm.locality
                    : '',
            isValid: true,
            message: ''
        };
        state.businessState = {
            value:
                componentForm.administrative_area_level_1 != 'State '
                    ? componentForm.administrative_area_level_1
                    : '',
            isValid: true,
            message: ''
        };

        // state.address = {
        //   value:
        //     componentForm.street_number != "Street Number"
        //       ? componentForm.street_number
        //       : "" + componentForm.route != "Route"
        //       ? componentForm.route
        //       : "",
        //   isValid: true,
        //   message: "",
        // };

        if (componentForm.street_number && componentForm.route) {
            state.businessAddress = {
                value: componentForm.street_number + ' ' + componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.route) {
            state.businessAddress = {
                value: componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.formattedAddress) {
            state.businessAddress = {
                value: componentForm.formattedAddress,
                isValid: true,
                message: ''
            };
        }
        // debugger
        // state.latitude = address.geometry.location.lat();
        // state.longitude = address.geometry.location.lng();
        // state.showNotificationDiv = true;
        // state.isDisabled.country = state.country.value != "" ? true : false;
        // state.isDisabled.state = state.stateName.value != "" ? true : false;
        // state.isDisabled.city = state.city.value != "" ? true : false;

        this.setState(state);
    }

    async updateBillingAddress(addr) {
        console.log('hi');
        this.setState({ formDirty: true }, () => console.log(this.state.formDirty));
        var placeSearch, autocomplete;
        var componentForm = {
            street_number: '',
            route: '',
            postal_town: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            formattedAddress: '',
            neighbourhood: ''
        };
        let address = await geocodeByAddress(addr);
        if (address) console.log(address[0].geometry.location.lat());

        address = address[0];
        componentForm.formattedAddress = address.formatted_address;
        for (var i = 0; i < address.address_components.length; i++) {
            var addressType = address.address_components[i].types[0];
            console.log(addressType);
            if (componentForm[addressType] == '') {
                let val = address.address_components[i][componentForm[addressType]];
                componentForm[addressType] = address.address_components[i].long_name;
            }
        }
        console.log(componentForm);
        let state = this.state;
        if (address && address.geometry) {
            state.billingCoordinates.lat = address.geometry.location.lat();
            state.billingCoordinates.lng = address.geometry.location.lng();
        }

        // debugger
        // if (componentForm.postal_code !== "") {
        //   state.billingAddress = {
        //     value: componentForm.postal_code,
        //     isValid: true,
        //     message: "",
        //   };
        // } else if (componentForm.locality !== "") {
        //   state.billingAddress = {
        //     value: componentForm.locality,
        //     isValid: true,
        //     message: "",
        //   };
        // } else if (componentForm.country !== "" || componentForm.postal_code !== "")
        //   state.billingAddress = {
        //     value: componentForm.country,
        //     isValid: true,
        //     message: "",
        //   };
        if (componentForm.country !== '')
            state.billingCountry = {
                value: componentForm.country,
                isValid: true,
                message: ''
            };
        // debugger
        if (componentForm.postal_code !== '')
            state.billingZip = {
                value: componentForm.postal_code,
                isValid: true,
                message: ''
            };
        state.billingCity = {
            value:
                componentForm.postal_town != 'Town' && componentForm.postal_town
                    ? componentForm.postal_town
                    : componentForm.locality != 'locality'
                    ? componentForm.locality
                    : '',
            isValid: true,
            message: ''
        };
        state.billingState = {
            value:
                componentForm.administrative_area_level_1 != 'State '
                    ? componentForm.administrative_area_level_1
                    : '',
            isValid: true,
            message: ''
        };

        if (componentForm.street_number && componentForm.route) {
            state.billingAddress = {
                value: componentForm.street_number + ' ' + componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.route) {
            state.billingAddress = {
                value: componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.formattedAddress) {
            state.billingAddress = {
                value: componentForm.formattedAddress,
                isValid: true,
                message: ''
            };
        }
        this.setState(state);
    }

    render() {
        const dateFormat = 'YYYY/MM/DD';
        let filteredCurrencies = Currencies;
        let filteredCountryCode = Countries;
        return (
            <div className='adminEditProfile'>
                <div className='user-profile tab'>
                    <div className='coverImage'>
                        <div className='overlay'></div>
                        <img src='https://source.unsplash.com/random/1600x900' />
                        <div className='addCoverImage'>
                            <UploadOutlined style={{ fontSize: '30px', color: '#f7f7f7' }} />
                        </div>
                    </div>
                    <div className='profileHead'>
                        <div className='profilePic'>
                            <img src={girl2} alt='girl' />
                            <i className='fa fa-pencil' />
                        </div>
                    </div>
                    <h2>Edit Business Profile</h2>
                    <h1>Account Holder Information</h1>
                    <div className='input-fields'>
                        <div className='input-row'>
                            <label className={`${!this.state.firstName.isValid ? 'error' : ''}`}>
                                First name:
                                <input
                                    name='firstName'
                                    placeholder='Firstname'
                                    value={this.state.firstName.value}
                                    onChange={(e) => this.onChange(e)}
                                />
                                {this.state.firstName.message && (
                                    <p>
                                        {' '}
                                        <i className='fe fe-alert-triangle' />{' '}
                                        {this.state.firstName.message}
                                    </p>
                                )}
                            </label>

                            <label className={`${!this.state.lastName.isValid ? 'error' : ''}`}>
                                Last name:
                                <input
                                    name='lastName'
                                    placeholder='Firstname'
                                    value={this.state.lastName.value}
                                    onChange={(e) => this.onChange(e)}
                                />
                                {this.state.lastName.message && (
                                    <p>
                                        {' '}
                                        <i className='fe fe-alert-triangle' />{' '}
                                        {this.state.lastName.message}
                                    </p>
                                )}
                            </label>
                        </div>
                        <div className='input-row'>
                            <label>
                                Date of Birth:
                                <DatePicker
                                    name='dob'
                                    placeholder='Date of Birth'
                                    value={
                                        this.state.dob.value
                                            ? moment(this.state.dob.value, dateFormat)
                                            : ''
                                    }
                                    onChange={this.onChangeDate.bind(this)}
                                />
                                {/* <input placeholder="Date of Birth" /> */}
                            </label>
                            <label>
                                Gender:
                                <Select
                                    name='gender'
                                    showSearch={true}
                                    placeholder='Gender'
                                    value={this.state.gender.value}
                                    onChange={(e, { value }) =>
                                        this.setState({
                                            gender: { ...this.state.gender, value: value }
                                        })
                                    }
                                    options={gender}
                                />
                            </label>
                        </div>

                        {/* <div className="input-row ">
            <label
              className={`${
                !this.state.email.isValid ? "error" : ""
              } textareaLabel`}
            >
              Profile Summary:
              <textarea
                name="profileSummary"
                placeholder=""
                value={this.state.profileSummary.value}
                onChange={this.onChange}
              />
            </label>
          </div> */}
                        {/* <button
            name="profileBtn"
            className="save-btn"
            onClick={this.saveProfile}
          >
            Save
          </button> */}
                    </div>
                    <div className='dividerL'></div>

                    <h1>Billing Contact</h1>
                    <div className='input-fields'>
                        <div className='input-row two-part'>
                            <label className={`${!this.state.businessName.isValid ? 'error' : ''}`}>
                                Business Name:
                                <input
                                    name='businessName'
                                    placeholder='Business Name'
                                    value={this.state.businessName.value}
                                    onChange={this.onChange}
                                />
                            </label>
                            <label className={`${!this.state.contactEmail.isValid ? 'error' : ''}`}>
                                Email Address:
                                <input
                                    name='contactEmail'
                                    placeholder='Email address'
                                    value={this.state.contactEmail.value}
                                    onChange={this.onChange}
                                />
                            </label>
                        </div>
                        <div className='input-row'>
                            <label
                                className={`${
                                    !this.state.contactCountryCode.isValid ? 'error' : ''
                                } cc-label ${this.state.countryDrop && 'focused'}`}
                                tabIndex={0}
                            >
                                Country Code:
                                <input
                                    type='text'
                                    name='countryCode'
                                    placeholder='Country code'
                                    style={{ paddingLeft: '25 !important' }}
                                    value={this.state.contactCountryCode.value}
                                    autoComplete='off'
                                    style={
                                        this.state.countryCode.value.match(/^\d+$/) && {
                                            paddingLeft: 20
                                        }
                                    }
                                    className='cc-input'
                                    onFocus={() => this.setState({ countryDrop: true })}
                                    onChange={(e) => {
                                        if (e.target.value !== '') {
                                            filteredCountryCode = Countries.filter(
                                                (item, index) => {
                                                    const regex = new RegExp(e.target.value, 'gi');
                                                    return (
                                                        item.name.match(e.target.value) ||
                                                        item.dialCode.match(e.target.value) ||
                                                        item.isoCode.match(e.target.value)
                                                    );
                                                }
                                            );
                                            this.setState({
                                                filteredCountryCode: filteredCountryCode
                                            });
                                        } else {
                                            this.setState({ filteredCountryCode: Countries });
                                        }
                                        this.setState({
                                            formAccountDirty: true,
                                            formDirty: true,
                                            contactCountryCode: {
                                                ...this.state.contactCountryCode,
                                                value: e.target.value
                                            }
                                        });
                                    }}
                                />
                                <i className='dropdown icon'></i>
                                {this.state.contactCountryCode.value.length &&
                                this.state.contactCountryCode.value.match(/^\d+$/) ? (
                                    <i className='plus-icon'>&#43;</i>
                                ) : null}
                                {this.state.countryDrop && (
                                    <div
                                        className='countryDrop'
                                        onBlur={() => this.setState({ countryDrop: false })}
                                    >
                                        <ul>
                                            {this.state.filteredCountryCode &&
                                                this.state.filteredCountryCode.map(
                                                    (item, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (
                                                                    item &&
                                                                    item.flag &&
                                                                    item.dialCode
                                                                ) {
                                                                    this.setState({
                                                                        contactCountryCode: {
                                                                            ...this.state
                                                                                .contactCountryCode,
                                                                            value: item.dialCode.split(
                                                                                '+'
                                                                            )[1]
                                                                        },
                                                                        formDirty: true,
                                                                        countryDrop: false
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <img
                                                                src={item.flag}
                                                                height='16px'
                                                                width='24px'
                                                            />
                                                            {item.name +
                                                                ' (' +
                                                                item.isoCode +
                                                                ') ' +
                                                                item.dialCode}
                                                        </li>
                                                    )
                                                )}
                                        </ul>
                                    </div>
                                )}
                                {this.state.contactCountryCode.message && (
                                    <p>
                                        {' '}
                                        <i className='fe fe-alert-triangle' />{' '}
                                        {this.state.contactCountryCode.message}
                                    </p>
                                )}
                            </label>
                            <label className={`${!this.state.contactPhone.isValid ? 'error' : ''}`}>
                                Phone No:
                                <input
                                    autoComplete='off'
                                    name='contactPhone'
                                    placeholder='Phone No.'
                                    value={this.state.contactPhone.value}
                                    onChange={this.onChange}
                                />
                            </label>
                        </div>

                        {/* <div className="input-row">
            <label
              className={`${
                !this.state.contactCountryCode.isValid ? "error" : ""
              }`}
            >
              Country Code:
              <input
                autoComplete="off"
                name="contactCountryCode"
                placeholder="Country code"
                value={this.state.contactCountryCode.value}
                onChange={this.onChange}
              />
            </label>
       
          </div> */}

                        <div className='input-row '>
                            <label className='fullLabel'>
                                Billing Location:
                                <i className='fa fa-map-marker' />
                                <Geolocate
                                    chooseAddress={(address) => this.updateBillingAddress(address)}
                                />
                            </label>
                        </div>
                        <div className='input-row two-part'>
                            <label className='one-half'>
                                Billing Address:
                                <input
                                    autoComplete='off'
                                    name='billingAddress'
                                    value={this.state.billingAddress.value}
                                    placeholder='Billing Address'
                                    onChange={this.onChange}
                                />
                            </label>
                            <label className='one-half'>
                                Billing Country:
                                <input
                                    autoComplete='off'
                                    name='billingCountry'
                                    value={this.state.billingCountry.value}
                                    placeholder='Billing Country'
                                    onChange={this.onChange}
                                />
                            </label>
                        </div>

                        <div className='input-row three-part'>
                            <label className='one-third'>
                                City:
                                <input
                                    autoComplete='off'
                                    name='billingCity'
                                    value={this.state.billingCity.value}
                                    placeholder='City'
                                    onChange={this.onChange}
                                />
                            </label>
                            <label className='one-third'>
                                Province/State:
                                <input
                                    autoComplete='off'
                                    name='billingState'
                                    value={this.state.billingState.value}
                                    placeholder='Province/State'
                                    onChange={this.onChange}
                                />
                            </label>
                            <label className='one-third'>
                                Postal/Zip Code:
                                <input
                                    autoComplete='off'
                                    name='billingZip'
                                    value={this.state.billingZip.value}
                                    placeholder='Postal/Zip Code'
                                    onChange={this.onChange}
                                />
                            </label>
                        </div>
                    </div>
                    <div className='dividerL'></div>

                    {/* <h1>
          Business Information
          <sup
            style={{
              fontStyle: "italic",
              fontSize: 11,
              fontFamily: "'Inter Var'",
            }}
          >
            <i
              className="fe fe-info"
              onMouseOver={() => {
                this.setState({ toolTip: true });
              }}
              onMouseOut={() => this.setState({ toolTip: false })}
            />
            {this.state.toolTip && (
              <div className="infoToolTip">
                <span>
                  (This information will be displayed on your businessProfile)
                </span>
              </div>
            )}
          </sup>
          <span className="infoHidden">
            (This information will be displayed on your businessProfile)
          </span>
        </h1>
        <div className="input-fields">
          <div className="input-row">
            <label
              className={`${
                !this.state.businessName.isValid ? "error" : ""
              } fullLabel`}
            >
              Business Name:
              <input
                name="businessName"
                placeholder="Business Name"
                value={this.state.businessName.value}
                onChange={this.onChange}
              />
              {this.state.businessName.message && (
                <p>
                  {" "}
                  <i className="fe fe-alert-triangle" />{" "}
                  {this.state.businessName.message}
                </p>
              )}
            </label>
          </div>
          <div className="input-row">
            <label
              className={`${!this.state.businessEmail.isValid ? "error" : ""} `}
            >
              Business Email:
              <input
                autoComplete="off"
                name="businessEmail"
                placeholder="Email address"
                value={this.state.businessEmail.value}
                onChange={this.onChange}
              />
              {this.state.businessEmail.message && (
                <p>
                  {" "}
                  <i className="fe fe-alert-triangle" />{" "}
                  {this.state.businessEmail.message}
                </p>
              )}
            </label>
            <label>
              Business Location:
              <Geolocate
                chooseAddress={(address) => this.updateAddress(address)}
              />
            </label>
          </div>

          <div className="input-row two-part">
            <label className="one-half">
              Business Address:
              <input
                autoComplete="off"
                name="businessAddress"
                value={this.state.businessAddress.value}
                placeholder="Business Address"
                onChange={this.onChange}
              />
            </label>
            <label className="one-half">
              Business Country:
              <input
                autoComplete="off"
                name="businessCountry"
                value={this.state.businessCountry.value}
                placeholder="Business Country"
                onChange={this.onChange}
              />
            </label>
          </div>

          <div className="input-row three-part">
            <label className="one-third">
              City:
              <input
                autoComplete="off"
                placeholder="City"
                name="businessCity"
                value={this.state.businessCity.value}
                onChange={this.onChange}
              />
            </label>
            <label className="one-third">
              Province/State:
              <input
                autoComplete="off"
                placeholder="Province/State"
                name="businessState"
                value={this.state.businessState.value}
                onChange={this.onChange}
              />
            </label>
            <label className="one-third">
              Postal/Zip Code:
              <input
                autoComplete="off"
                placeholder="Postal/Zip Code"
                name="businessZip"
                value={this.state.businessZip.value}
                onChange={this.onChange}
              />
            </label>
          </div>

          <div className="input-row">
            <label
              id="countryCode2"
              className={`${
                !this.state.businessCountryCode.isValid ? "error" : ""
              } cc-label ${this.state.countryDrop2 && "focused"}`}
              tabIndex={0}
            >
              Country Code:
              <input
                type="text"
                name="countryCode"
                placeholder="Country code"
                value={this.state.businessCountryCode.value}
                autoComplete="off"
                style={
                  this.state.businessCountryCode.value.match(/^\d+$/) && {
                    paddingLeft: 20,
                  }
                }
                className="cc-input"
                onFocus={() => this.setState({ countryDrop2: true })}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    filteredCountryCode = Countries.filter((item, index) => {
                      const regex = new RegExp(e.target.value, "gi");
                      return (
                        item.name.match(e.target.value) ||
                        item.dialCode.match(e.target.value) ||
                        item.isoCode.match(e.target.value)
                      );
                    });
                    this.setState({ filteredCountryCode: filteredCountryCode });
                  } else {
                    this.setState({ filteredCountryCode: Countries });
                  }
                  this.setState({
                    formAccountDirty: true,
                    formDirty: true,
                    businessCountryCode: {
                      ...this.state.businessCountryCode,
                      value: e.target.value,
                    },
                  });
                }}
              />
              <i className="dropdown icon"></i>
              {this.state.businessCountryCode.value.length &&
              this.state.businessCountryCode.value.match(/^\d+$/) ? (
                <i className="plus-icon">&#43;</i>
              ) : null}
              {this.state.countryDrop2 && (
                <div
                  className="countryDrop"
                  onBlur={() => this.setState({ countryDrop2: false })}
                >
                  <ul>
                    {this.state.filteredCountryCode &&
                      this.state.filteredCountryCode.map((item, index) => (
                        <li
                          key={index}
                          onClick={(e) => {
                            e.preventDefault();
                            if (item && item.flag && item.dialCode) {
                              this.setState({
                                businessCountryCode: {
                                  ...this.state.businessCountryCode,
                                  value: item.dialCode.split("+")[1],
                                },
                                formDirty: true,
                                countryDrop2: false,
                              });
                            }
                          }}
                        >
                          <img src={item.flag} height="16px" width="24px" />
                          {item.name +
                            " (" +
                            item.isoCode +
                            ") " +
                            item.dialCode}
                        </li>
                      ))}
                  </ul>
                </div>
              )}
              {this.state.businessCountryCode.message && (
                <p>
                  {" "}
                  <i className="fe fe-alert-triangle" />{" "}
                  {this.state.businessCountryCode.message}
                </p>
              )}
            </label>
            <label
              className={`${!this.state.businessPhoneNo.isValid ? "error" : ""} `}
            >
              Business Phone No:
              <input
                autoComplete="off"
                name="businessPhoneNo"
                placeholder="Phone No."
                value={this.state.businessPhoneNo.value}
                onChange={this.onChange}
              />
              {this.state.businessPhoneNo.message && (
                <p>
                  {" "}
                  <i className="fe fe-alert-triangle" />{" "}
                  {this.state.businessPhoneNo.message}
                </p>
              )}
            </label>
          </div>

          <div className="input-row ">
            <label className="textareaLabel">
              Business Profile:
              <textarea
                value={this.state.businessProfile.value}
                onChange={this.onChange}
                name="businessProfile"
                placeholder=""
                rows="4"
              />
              {this.state.businessProfile.message ? (
                <p>
                  {" "}
                  <i className="fe fe-alert-triangle" />{" "}
                  {this.state.businessProfile.message}
                </p>
              ) : (
                <p> *Field is optional.</p>
              )}
            </label>
          </div>

      
        </div>

        <div className="dividerL"></div>

        <h1>Social Account Settings</h1>
        <div className="input-fields">
          <div className="input-row social">
            <label>
              Facebook:
              <i className="fa fa-facebook" />
              <input
                name="social"
                placeholder="Facebook"
                onChange={(e) =>
                  this.setState({
                    formDirty: true,
                    social: { ...this.state.social, facebook: e.target.value },
                  })
                }
                value={this.state.social && this.state.social.facebook}
              />
            </label>

            <label>
              Instagram:
              <i className="fa fa-instagram" />
              <input
                name="social"
                placeholder="Instagram"
                onChange={(e) =>
                  this.setState({
                    formDirty: true,
                    social: { ...this.state.social, instagram: e.target.value },
                  })
                }
                value={this.state.social && this.state.social.instagram}
              />
            </label>
          </div>
          <div className="input-row social">
            <label>
              Twitter:
              <i className="fa fa-twitter" />
              <input
                name="social"
                placeholder="Twitter"
                onChange={(e) =>
                  this.setState({
                    formDirty: true,
                    social: { ...this.state.social, twitter: e.target.value },
                  })
                }
                value={this.state.social && this.state.social.twitter}
              />
            </label>
            <label>
              LinkedIn
              <i className="fa fa-linkedin" />
              <input
                name="social"
                placeholder="LinkedIn"
                onChange={(e) =>
                  this.setState({
                    formDirty: true,
                    social: { ...this.state.social, linkedin: e.target.value },
                  })
                }
                value={this.state.social && this.state.social.linkedin}
              />
            </label>
          </div>

         
        </div>

        <div className="dividerL"></div>
        <h1>Kitchen Types</h1>
        <div className="input-fields checkBoxes">
          <div className="input-row checkboxRows">
            <label className="fullLabel">
              <input
                type="checkbox"
                onChange={this.onChange}
                name="kitchenTypes"
                value="Kosher"
                checked={this.state.kitchenTypes.includes("Kosher")}
              />
              <span className="checkBox">
                <i className="fe fe-check" />
              </span>
              Kosher
            </label>

            <label className="fullLabel">
              <input
                type="checkbox"
                onChange={this.onChange}
                name="kitchenTypes"
                value="Halal"
                checked={this.state.kitchenTypes.includes("Halal")}
              />
              <span className="checkBox">
                <i className="fe fe-check" />
              </span>
              Halal
            </label>

            <label className="fullLabel">
              <input
                type="checkbox"
                onChange={this.onChange}
                name="kitchenTypes"
                value="Pet free"
                checked={this.state.kitchenTypes.includes("Pet free")}
              />
              <span className="checkBox">
                <i className="fe fe-check" />
              </span>
              Pet free
            </label>
            <label className="fullLabel">
              <input
                type="checkbox"
                onChange={this.onChange}
                name="kitchenTypes"
                value="Vegetarian"
                checked={this.state.kitchenTypes.includes("Vegetarian")}
              />
              <span className="checkBox">
                <i className="fe fe-check" />
              </span>
              Vegetarian
            </label>
            <label className="fullLabel">
              <input
                type="checkbox"
                onChange={this.onChange}
                name="kitchenTypes"
                value="Peanut free"
                checked={this.state.kitchenTypes.includes("Peanut free")}
              />
              <span className="checkBox">
                <i className="fe fe-check" />
              </span>
              Peanut free
            </label>
          </div>

        </div>

        <div className="dividerL"></div>
        <h1>Delivery Options</h1>
        <div className="input-fields checkBoxes">
          <div className="input-row checkboxRows">
            <label className="fullLabel">
              <input
                type="checkbox"
                onChange={this.onChange}
                name="deliveryOpts"
                value="Delivery"
                checked={this.state.deliveryOpts.includes("Delivery")}
              />
              <span className="checkBox">
                <i className="fe fe-check" />
              </span>
              Delivery
            </label>

            <label className="fullLabel">
              <input
                type="checkbox"
                onChange={this.onChange}
                name="deliveryOpts"
                value="Pickup"
                checked={this.state.deliveryOpts.includes("Pickup")}
              />
              <span className="checkBox">
                <i className="fe fe-check" />
              </span>
              Pickup
            </label>
          </div>

        </div>

        <div className="dividerL"></div> */}

                    <h1>Account Settings</h1>
                    <div className='input-fields'>
                        <div className='input-row'>
                            <label className={`${!this.state.email.isValid ? 'error' : ''}`}>
                                Email:
                                <input
                                    name='email'
                                    placeholder='Email'
                                    value={this.state.email.value}
                                    onChange={(e) => this.onChange(e)}
                                />
                                {this.state.email.message && (
                                    <p>
                                        {' '}
                                        <i className='fe fe-alert-triangle' />{' '}
                                        {this.state.email.message}
                                    </p>
                                )}
                            </label>
                            <label className={`${!this.state.password.isValid ? 'error' : ''}`}>
                                Password:
                                <input
                                    name='password'
                                    type='password'
                                    placeholder='************'
                                    value={this.state.password.value}
                                    onChange={(e) => this.onChange(e)}
                                />
                            </label>
                        </div>
                        <div className='input-row'>
                            <label
                                id='countryCode1'
                                className={`${
                                    !this.state.countryCode.isValid ? 'error' : ''
                                } cc-label ${this.state.countryDrop1 && 'focused'}`}
                                tabIndex={0}
                            >
                                Country Code:
                                <input
                                    type='text'
                                    name='countryCode'
                                    placeholder='Country code'
                                    value={this.state.countryCode.value}
                                    autocomplete='autocomplete_off_hack_xfr4!k'
                                    style={
                                        this.state.countryCode.value.match(/^\d+$/) && {
                                            paddingLeft: 20
                                        }
                                    }
                                    className='cc-input'
                                    onFocus={() => this.setState({ countryDrop1: true })}
                                    onChange={(e) => {
                                        if (e.target.value !== '') {
                                            filteredCountryCode = Countries.filter(
                                                (item, index) => {
                                                    const regex = new RegExp(e.target.value, 'gi');
                                                    return (
                                                        item.name.match(e.target.value) ||
                                                        item.dialCode.match(e.target.value) ||
                                                        item.isoCode.match(e.target.value)
                                                    );
                                                }
                                            );
                                            this.setState({
                                                filteredCountryCode: filteredCountryCode
                                            });
                                        } else {
                                            this.setState({ filteredCountryCode: Countries });
                                        }
                                        this.setState({
                                            formAccountDirty: true,
                                            formDirty: true,
                                            countryCode: {
                                                ...this.state.countryCode,
                                                value: e.target.value
                                            }
                                        });
                                    }}
                                />
                                <i className='dropdown icon'></i>
                                {this.state.countryCode.value.length &&
                                this.state.countryCode.value.match(/^\d+$/) ? (
                                    <i className='plus-icon'>&#43;</i>
                                ) : null}
                                {this.state.countryDrop1 && (
                                    <div
                                        className='countryDrop'
                                        onBlur={() => this.setState({ countryDrop1: false })}
                                    >
                                        <ul>
                                            {this.state.filteredCountryCode &&
                                                this.state.filteredCountryCode.map(
                                                    (item, index) => (
                                                        <li
                                                            key={index}
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                if (
                                                                    item &&
                                                                    item.flag &&
                                                                    item.dialCode
                                                                ) {
                                                                    this.setState({
                                                                        countryCode: {
                                                                            ...this.state
                                                                                .countryCode,
                                                                            value: item.dialCode.split(
                                                                                '+'
                                                                            )[1]
                                                                        },
                                                                        formDirty: true,
                                                                        countryDrop1: false
                                                                    });
                                                                }
                                                            }}
                                                        >
                                                            <img
                                                                src={item.flag}
                                                                height='16px'
                                                                width='24px'
                                                            />
                                                            {item.name +
                                                                ' (' +
                                                                item.isoCode +
                                                                ') ' +
                                                                item.dialCode}
                                                        </li>
                                                    )
                                                )}
                                        </ul>
                                    </div>
                                )}
                                {this.state.countryCode.message && (
                                    <p>
                                        {' '}
                                        <i className='fe fe-alert-triangle' />{' '}
                                        {this.state.countryCode.message}
                                    </p>
                                )}
                            </label>
                            <label className={`${!this.state.phoneNo.isValid ? 'error' : ''}`}>
                                Phone No:
                                <input
                                    name='phoneNo'
                                    placeholder='Phone No.'
                                    value={this.state.phoneNo.value}
                                    onChange={(e) => this.onChange(e)}
                                />
                                {this.state.phoneNo.message && (
                                    <p>
                                        {' '}
                                        <i className='fe fe-alert-triangle' />{' '}
                                        {this.state.phoneNo.message}
                                    </p>
                                )}
                            </label>
                        </div>
                        <div className='input-row'>
                            <label>
                                Language:
                                <Select
                                    placeholder='Language'
                                    value={this.state.language.value}
                                    // search
                                    showSearch='true'
                                    selection='true'
                                    onChange={(e, { value }) =>
                                        this.setState({
                                            formDirty: true,
                                            language: { ...this.state.language, value: value }
                                        })
                                    }
                                    options={languageOptions}
                                />
                            </label>

                            <label
                                ref={this.curr}
                                className={`cur-label ${this.state.currencyDrop && 'focused'}`}
                                tabIndex={0}
                            >
                                Currency
                                <input
                                    className='cur-input'
                                    type='text'
                                    placeholder='Choose currency'
                                    value={this.state.currency.value}
                                    onFocus={() => this.setState({ currencyDrop: true })}
                                    onChange={(e) => {
                                        filteredCurrencies = Currencies.filter((item, index) => {
                                            const regex = new RegExp(e.target.value, 'gi');
                                            return (
                                                item.name.match(regex) ||
                                                item.currency.code.match(regex)
                                            );
                                        });
                                        this.setState({ filteredCurrencies: filteredCurrencies });
                                        console.log(filteredCurrencies);
                                        this.setState({
                                            currency: {
                                                ...this.state.currency,
                                                value: e.target.value
                                            }
                                        });
                                    }}
                                />
                                <i className='dropdown icon'></i>
                                {this.state.currencyDrop && (
                                    <div
                                        className='currencyDrop'
                                        onBlur={() => this.setState({ currencyDrop: false })}
                                    >
                                        <ul>
                                            {this.state.filteredCurrencies &&
                                                this.state.filteredCurrencies.map((item, index) => (
                                                    <li
                                                        key={index}
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            if (
                                                                item &&
                                                                item.currency &&
                                                                item.currency.code
                                                            ) {
                                                                console.log(item, e);
                                                                this.setState({
                                                                    currency: {
                                                                        ...this.state.currency,
                                                                        value: item.currency.code
                                                                    },
                                                                    formDirty: true,
                                                                    currencyDrop: false
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <img
                                                            src={`data:image/jpeg;base64,${item.flag}`}
                                                            height='16px'
                                                            width='24px'
                                                        />
                                                        {item.name +
                                                            ' - ' +
                                                            item.currency.code +
                                                            ` (${
                                                                item.currency.symbol !== false &&
                                                                item.currency.symbol
                                                            })`}{' '}
                                                    </li>
                                                ))}
                                        </ul>
                                    </div>
                                )}
                            </label>
                        </div>
                        {/* <div className="input-row">
            <label>
              Password:
              <input
                type="password"
                placeholder="************"
                value={this.state.pass}
                onChange={(e) => this.setState({ pass: e.target.value })}
              />
            </label>
          </div> */}
                        <div className='input-row' style={{ width: 360, maxWidth: '100%' }}>
                            <label>
                                Active Status:
                                <Select
                                    placeholder='Active Status'
                                    value={this.state.activeStatus.value}
                                    selection
                                    onChange={(e, { value }) => {
                                        console.log(value);
                                        this.setState({
                                            activeStatus: {
                                                ...this.state.activeStatus,
                                                value: value
                                            },
                                            formDirty: true
                                        });
                                    }}
                                >
                                    {statusOptions &&
                                        statusOptions.map((item, index) => (
                                            <Select.Option key={index} value={item}>
                                                {item}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </label>
                        </div>

                        <div className='input-row checkboxRows' style={{ marginTop: 20 }}>
                            <label className='fullLabel'>
                                <input type='checkbox' />
                                <span className='checkBox'>
                                    <i className='fe fe-check' />
                                </span>
                                Private Profile
                            </label>

                            <label className='fullLabel'>
                                <input type='checkbox' />
                                <span className='checkBox'>
                                    <i className='fe fe-check' />
                                </span>
                                Share Social info
                            </label>

                            <label className='fullLabel'>
                                <input type='checkbox' />
                                <span className='checkBox'>
                                    <i className='fe fe-check' />
                                </span>
                                Share Favourites
                            </label>
                            <label className='fullLabel'>
                                <input type='checkbox' />
                                <span className='checkBox'>
                                    <i className='fe fe-check' />
                                </span>
                                Allow Messaging
                            </label>
                        </div>

                        <label style={{ flexDirection: 'row' }}>
                            Deactivate your account? &nbsp;{' '}
                            <span style={{ textDecoration: 'underline', color: 'red' }}>
                                Deactivate now.
                            </span>
                        </label>

                        <button name='accountBtn' className='save-btn' onClick={this.saveProfile}>
                            Save
                        </button>
                    </div>
                    {/* <div className="businessProfile-completion">
          <button
            style={{ display: "flex" }}
            className=""
            onClick={() => {
              this.setState({ profCompDiv: !this.state.profCompDiv });
            }}
          >
            <i
              className={`fe  ${
                this.state.profCompDiv ? "fe-x-circle" : "fe-info"
              } `}
            >
              {this.state.profCompDiv && (
                <div className="businessProfile-completion-div">
                  <h5>Profile Checklist</h5>
                  <ul>
                    <li>
                      <div
                        className={`checkmark avatar ${
                          this.state.profileCompletion.businessProfile && "active"
                        }`}
                      >
                        <i className="fa fa-check" />
                      </div>
                      Profile Information Setup{" "}
                      <i className="fe fe-chevron-right" />
                    </li>
                    <li>
                      <div className="checkmark avatar">
                        <i className="fa fa-check" />
                      </div>
                      Update your businessProfile picture{" "}
                      <i className="fe fe-chevron-right" />
                    </li>
                    <li>
                      <div className="checkmark avatar active">
                        <i className="fa fa-check" />
                      </div>
                      Update your banner picture{" "}
                      <i className="fe fe-chevron-right" />
                    </li>

                    <li>
                      <div className="checkmark avatar ">
                        <i className="fa fa-check" />
                      </div>
                      Contact Information Setup{" "}
                      <i className="fe fe-chevron-right" />
                    </li>
                    <li>
                      <div
                        className={`checkmark avatar active ${
                          this.state.profileCompletion.account && "active"
                        }`}
                      >
                        <i className="fa fa-check" />
                      </div>
                      Account Information Setup{" "}
                      <i className="fe fe-chevron-right" />
                    </li>
                  </ul>
                </div>
              )}
            </i>
          </button>
        </div> */}

                    <NavigationPrompt when={this.state.formDirty}>
                        {({ onConfirm, onCancel }) => (
                            <>
                                {this.state.formDirty &&
                                    ReactDOM.createPortal(
                                        <div className='modal-overlay nav-prompt'>
                                            <div className='form-modal'>
                                                <div className='modal-title'>
                                                    <p>Your Profile is not saved yet</p>
                                                </div>
                                                <div className='modal-content'>
                                                    <p>
                                                        {' '}
                                                        You need to save your unsaved changes, do
                                                        you really want to leave without saving your
                                                        changes?
                                                    </p>
                                                </div>
                                                <div className='modal-actions'>
                                                    <button
                                                        className='btn--pink-outlined'
                                                        style={{ borderRadius: 8 }}
                                                        color='primary'
                                                        onClick={onCancel}
                                                    >
                                                        No
                                                    </button>
                                                    <button
                                                        className='btn--pink'
                                                        style={{
                                                            borderRadius: 8
                                                            // backgroundColor: "rgb(255,114,69)",
                                                        }}
                                                        color='primary'
                                                        autoFocus
                                                        onClick={onConfirm}
                                                    >
                                                        Yes
                                                    </button>
                                                </div>
                                            </div>
                                        </div>,
                                        document.querySelector('#modal-root')
                                    )}
                            </>
                        )}
                    </NavigationPrompt>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    admin: state.admin
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    adminActions: bindActionCreators(adminActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Profile);
