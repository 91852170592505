import React, { useState, useEffect } from 'react';
import { Button, Input, Modal, Select } from 'antd';

const ServicesModal = (props) => {
    const { setIsModalOpen, isModalOpen, branches, item } = props;
    const [name, setName] = useState('');
    const [locations, setLocations] = useState([]);
    const [rate, setRate] = useState('');
    const [id, setId] = useState(null);

    useEffect(() => {
        console.log('item inserted', item);
        if (item) {
            setName(item.name);
            setRate(item.rate);
            setLocations(item.branches && item.branches.map((item, index) => item._id));
            setId(item.id);
        }
    }, [item]);
    if (!isModalOpen) return null;
    const submit = async () => {
        let obj = {
            userId: props.auth.userId,
            name: name,
            rate: rate,
            branches: locations
        };
        if (item) {
            obj.lawfirmServiceId = item._id;
        }
        let createService = await props.generalActions.createPharmacyService(
            { ...props, obj },
            props.history
        );
        if (createService) {
            await props.generalActions.getPharmacyServices({ ...props, obj });
            setIsModalOpen(false);
            setName('');
            setLocations([]);
            setRate('');
        }
    };

    return (
        <Modal
            className='customModal '
            title='Add Service'
            open={isModalOpen}
            onOk={() => {
                setIsModalOpen(false);
                setName('');
                setLocations([]);
                setRate('');
            }}
            onCancel={() => {
                setIsModalOpen(false);
                setName('');
                setLocations([]);
                setRate('');
            }}
            footer={null}
        >
            <div className='post-modal-options-grid2'>
                <label>
                    <p>Service name:</p>

                    <Input
                        placeholder='Service Name'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>

                <label>
                    <p>Service Rate:</p>
                    <Input
                        placeholder='Service Rate'
                        value={rate}
                        onChange={(e) => {
                            if (e.target.value.match(/^[\d\.,]+$/) || !e.target.value) {
                                console.log('hello');
                                setRate(e.target.value);
                            }
                        }}
                    />
                </label>
            </div>
            <label>
                <p>Service Locations:</p>
                <Select
                    name='branch'
                    mode='multiple'
                    value={locations}
                    placeholder={'Select Locations'}
                    style={{ width: '100%', zIndex: 100 }}
                    onChange={(list, item) => setLocations(list)}
                >
                    {branches &&
                        branches.map((item, index) => (
                            <Select.Option value={item._id}>
                                {item?.location?.branchAddress} {item?.location?.branchState}{' '}
                                {item?.location?.branchCity} {item?.location?.branchCountry}{' '}
                            </Select.Option>
                        ))}
                </Select>
            </label>
            <Button type='primary' style={{ marginTop: '1rem' }} onClick={() => submit()}>
                <p>Add</p>
            </Button>
        </Modal>
    );
};

export default ServicesModal;
