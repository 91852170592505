import React from 'react';
import Card from './Card';
import { Button } from 'antd';

const CardRow = ({ searchPageLink, data, rowRef, type, ...props }) => {
    return (
        <div
            className='cardRow '
            ref={rowRef}
            style={{
                overflow: 'hidden',
                flexWrap: 'nowrap',
                scrollBehavior: 'smooth',
                padding: '10px'
            }}
        >
            {data && data.length > 0 ? (
                data.map((item, index) => <Card key={index} item={item} {...props} type={type} />)
            ) : (
                <div
                    className=''
                    style={{
                        margin: '0px auto',
                        alignItems: 'center',
                        height: 300,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center'
                    }}
                >
                    <p>You do not have any past appointments with any {type}s.</p>
                    <p>Search for a {type} instead.</p>
                    <Button type="primary" style={{marginTop: 10}} onClick={() => props.history.push(searchPageLink)}>
                        Search Now!
                    </Button>
                </div>
            )}
        </div>
    );
}

export default CardRow;
