import { Select } from 'antd';
import React from 'react';
import Countries from '@/assets/json/Countries.json';

const { Option } = Select;

const uniqueCountryCodes = Countries.filter(
    (item, index, self) => self.findIndex((t) => t.dialCode === item.dialCode) === index
);

const CountryCodeDropdown = ({
    setState,
    countryCode,
    stateValue,
    required = false
}) => {
    return (
        <label className={`cc-label ${!countryCode.isValid ? 'error' : ''}`} tabIndex={0}>
            <div>Country Code{required && <span style={{ color: 'red' }}>*</span>}:</div>

            <Select
                showSearch
                value={countryCode.value}
                onChange={(val) => {
                    console.log(val);
                    setState({
                        [stateValue]: {
                            ...countryCode,
                            value: val,
                            isValid: true,
                            message: ''
                        },
                        formDirty: true
                    });
                }}
            >
                {uniqueCountryCodes.map((item) => {
                    return (
                        <Option key={item.dialCode} value={item.dialCode}>
                            {item.name + ' (' + item.isoCode + ') ' + item.dialCode}
                        </Option>
                    );
                })}
            </Select>
            {countryCode.message && (
                <p>
                    {' '}
                    <i className='fe fe-alert-triangle' /> {countryCode.message}
                </p>
            )}
        </label>
    );
}

export default CountryCodeDropdown;
