import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';

let { REACT_APP_API_URI } = process.env;

if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const setBanner = (data) => (dispatch) => {
    dispatch({
        type: Types.SET_BANNER,
        payload: {
            banner: data
        }
    });
};

export const getMarketingInfo = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/marketing/getMarketingInfo`, props.obj)
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_MARKETING_INFO,
                        payload: {
                            marketingInfo: res.data.marketingInfo
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    return resolve(false);
                }
            });
    });
};

export const saveMarketingInfo = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/marketing/saveMarketingInfo`, props.obj)
            .then((res) => {
                if (res) {
                    console.log(res.data);
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    return resolve(false);
                }
            });
    });
};

export const saveMarketingSwitches = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/marketing/saveMarketingSwitches`, props.obj)
            .then((res) => {
                if (res) {
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    return resolve(false);
                }
            });
    });
};
