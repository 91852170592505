import { Modal } from 'antd';
import React from 'react';
import vCardsJS from 'vcards-js';
import QRCode from 'react-qr-code';

const QrCodeModal = ({ open, onCancel, user, userType }) => {
    const vCard = vCardsJS();
    vCard.firstName = user.firstName;
    vCard.lastName = user.lastName;
    if (user.hospitalName) {
        vCard.organization = user.hospitalName;
    }
    if (user.contact && user.contact?.email) {
        vCard.email = user.contact?.email;
    } else if (user.email) {
        if ((user.userType == 'HOSPITAL' || user.userType == 'PHARMACY') && user?.branch?.email) {
            vCard.email = user.branch.email;
        } else {
            vCard.email = user.email;
        }
    }

    if ((user.userType == 'HOSPITAL' || user.userType == 'PHARMACY') && user?.branch?.countryCode && user?.branch?.phoneNo) {
        vCard.cellPhone = user?.branch?.countryCode + user?.branch?.phoneNo;
    }
    else if (user.contact && user.contact.phoneNo && user.contact.countryCode) {
        vCard.cellPhone = user.contact.countryCode + user.contact.phoneNo;
    } else if (user.countryCode && user.phoneNo) {
        vCard.cellPhone = user.countryCode + user.phoneNo;
    }
    if (user.contact && user.contact.billing && user.contact.billing.billingAddress) {
        vCard.workAddress = user.contact.billing.billingAddress;
    }
    return (
        <Modal open={open} onCancel={onCancel} onOk={onCancel} title='QR Code' centered width={400}>
            <div
                style={{
                    height: 'auto',
                    padding: 20,
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                <QRCode
                    size={356}
                    style={{ height: 'auto', maxWidth: '100%', width: '240px' }}
                    value={vCard.getFormattedString()}
                    viewBox={`0 0 256 256`}
                />
            </div>
        </Modal>
    );
};

export default QrCodeModal;
