import React, { lazy, useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import Modals from './components/Auth/Modals';
import { generalRoutes, privateRoutes } from './routes/index';
import useWindowSize from './hooks/useWindowSize';
import Terms from './components/MedicalDocs/Terms';
import PrivacyPolicy from './components/MedicalDocs/PrivacyPolicy';
import Guidelines from './components/MedicalDocs/Guidelines';
const Header = lazy(() => import('./components/IndexShared/Header'));
const Footer = lazy(() => import('./components/IndexShared/Footer'));
const PrivateRoute = lazy(() => import('./components/PrivateRoutes/PrivateRoute'));

const Routes = (props) => {
    const { location } = props;
    const { pathname } = location;

    useEffect(() => {
        if (typeof window != 'undefined') {
            window.scrollTo(0, 0);
        }
    }, [pathname]);

    const showHeaderOrFooter = ![
        'patient',
        'doctor',
        'hospital',
        'nurse',
        'consultant',
        'pharmacy',
        'caregiver',
        'therapist'
    ].some((role) => pathname.includes(`/${role}/`));

    return (
        <>
            <Modals />
            <Switch>
                <>
                    <div
                        className='index'
                        style={{
                            paddingTop: showHeaderOrFooter ? 70 : 0
                        }}
                    >
                        {showHeaderOrFooter && <Header {...props} fixed={true} />}
                        {privateRoutes.map((route) => (
                            <PrivateRoute
                                key={route.path}
                                path={route.path}
                                component={route.component}
                            />
                        ))}
                        {generalRoutes.map((route, index) => {
                            if (route.switch) {
                                return (
                                    <Switch>
                                        {route.routes.map((nestedRoute) => (
                                            <Route
                                                exact={nestedRoute.exact}
                                                key={nestedRoute.path}
                                                path={nestedRoute.path}
                                                component={nestedRoute.component}
                                            />
                                        ))}
                                    </Switch>
                                );
                            } else {
                                return (
                                    <Route
                                        exact={route.exact}
                                        key={route.path}
                                        path={route.path}
                                        component={route.component}
                                    />
                                );
                            }
                        })}

                        {showHeaderOrFooter ? <Footer {...props} /> : ''}

                        <Terms />
                        <PrivacyPolicy />
                        <Guidelines />
                    </div>
                </>
            </Switch>
        </>
    );
};

export default Routes;
