import React, { useState, useEffect } from 'react';
import '../Shared/Appointments/appointment.css';
import AppointmentHeader from '../Shared/Appointments/AppointmentHeader';
import AppointmentPagination from '../Shared/Appointments/AppointmentPagination';
import { FaTasks } from 'react-icons/fa';
import { BsCalendar } from 'react-icons/bs';
import { motion, AnimatePresence } from 'framer-motion';
import { Calendar, Pagination, Modal } from 'antd';
import moment from 'moment';
import AppointmentModal from '../Shared/Appointments/modals/AppointmentModal';
import DataTable from '../Shared/Appointments/AppointmentDataTable';
import TableHeader from '../Shared/Appointments/TableHeader';
import * as appointmentActions from '@/actions/appointmentActions';
import * as userActions from '@/actions/userActions';
import * as availabilityActions from '@/actions/availabilityActions';
import * as doctorActions from '@/actions/doctorActions';
import * as reviewActions from '@/actions/reviewActions';
import { connect } from 'react-redux';
import ReactStars from 'react-rating-stars-component';
import { bindActionCreators } from 'redux';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import momentPlugin from '@fullcalendar/moment';
import listPlugin from '@fullcalendar/list';
import '@/assets/css/fullcalendar.css';
import ShowEventModal from '../Shared/Appointments/ViewCalendarEvent';
import CurrentTime from '../Shared/Appointments/CurrentTime';
import * as stripeActions from '@/actions/stripeActions';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CompleteAppointmentConfirmation from './Appointment/CompleteAppointmentConfirmation';
let stripePK =
    'pk_test_51Jat9xGdKelfS2valqmKF2YldfpvfxDlazZUvA6joCR5PAABvEwf3WzpwpmjPO6rUnjY2Bhzz342eHNIRJc13EQ600VeqkMeCR';
let stripeSecret =
    'sk_test_51Jat9xGdKelfS2vaK2gVB7K6x7tmMF8bZOUiJUo4rzVcLccIPuncCToSg93bwqGKDPKYBnJCd9LYteR2wTzEoROY009irGcsmQ';
const stripePromise = loadStripe(stripePK);

const Appointments = (props) => {
    const [open, setOpen] = useState(false);
    const [calenderView, setCalenderView] = useState(false);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [cardElementError, setCardElementError] = useState('');
    const [cardElementValid, setCardElementValid] = useState(true);
    const [completeAppointmentModal, setCompleteAppointmentModal] = useState(false);
    const [completeAppointmentModalItem, setCompleteAppointmentModalItem] = useState(null);
    const {
        branches,
        appointments: {
            appointments,
            appointmentsCount,
            googleEvents,
            outlookEvents,
            calendarEvents
        },
        auth,
        history
    } = props;
    const [showEvent, setShowEvent] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const { user, userType } = auth;

    //Reviews
    const [reviewModal, setReviewModal] = useState(false);
    const [rating, setRating] = useState(0);
    const [description, setDescription] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [chosenUser, setChosenUser] = useState(null);

    // const [currentTime, setCurrentTime] = useState(new Date())
    // setInterval(() => {
    //   setCurrentTime(new Date().toLocaleString())
    // }, 1000)

    const [appointmentPaymentModal, setAppointmentPaymentModal] = useState(false);
    const [appointmentPaymentObj, setAppointmentPaymentObj] = useState(null);

    const addReview = async () => {
        console.log(chosenUser);
        let obj = {
            rating: rating,
            reviewer: auth.user._id,
            reviewerType: auth.userType,
            reviewedUser: chosenUser._id,
            reviewedUserType: chosenUser.userType,
            description: description,
            shortDescription: shortDescription
        };
        let postedReview = await props.reviewActions.postReview({ ...props, obj }, props.history);
        if (postedReview) {
            setReviewModal(false);
        }
    };

    const getAppointments = async () => {
        let obj = {
            page: page,
            perPage: limit
        };
        await props.appointmentActions.getMyAppointments({
            ...props,
            obj
        });
    };
    const getCalendarEvents = async () => {
        let obj = {};
        await props.actions.getCalendarEvents({ ...props, obj }, props.history);
    };
    const cancelAppointment = async (id) => {
        console.log(id);
        let obj = {
            appointmentId: id
        };
        let cancelledAppointment = await props.appointmentActions.cancelAppointment(
            { ...props, obj },
            history
        );
        if (cancelledAppointment) {
            getAppointments();
            getCalendarEvents();
        }
    };

    const approveAppointment = async (id) => {
        console.log(id);
        let obj = {
            appointmentId: id
        };
        let approvedAppointment = await props.appointmentActions.approveAppointment(
            { ...props, obj },
            history
        );
        if (approvedAppointment) {
            getAppointments();
            getCalendarEvents();
        }
    };

    useEffect(() => {
        getAppointments();
    }, []);

    useEffect(() => {
        getCalendarEvents();
    }, [user?.syncGoogleCalendar, user?.syncOutlookCalendar]);

    const appointmentVariants = {
        hidden: {
            opacity: 0
        },
        visible: {
            opacity: 1,
            transition: { duration: 0.5 }
        },
        exit: {
            opacity: 0,
            transition: { ease: 'easeIn', duration: 0.5 }
        }
    };

    const onSelect = (value) => {
        console.log(value);
        props.history.push({
            pathname: '/patient/appointments/new',

            search: `?step=1&date=${moment(new Date(value)).format('DD/MM/YYYY')}`
        });
    };

    let appEvents =
        appointments &&
        appointments
            ?.filter((item) => item.appointmentStatus == 'ACCEPTED')
            .map((item, index) => {
                let obj = {};
                if (item?.doctor) {
                    obj.title = `Appointment with ${item?.doctor?.firstName} ${item?.doctor?.lastName}`;
                    obj.description = `Appointment with ${item?.doctor?.firstName} ${
                        item?.doctor?.lastName
                    } on ${moment(item.appointmentStartTime, 'HH:mm')}, ${
                        item.appointmentDate && moment(item.appointmentDate).format('DD/MM/YYYY')
                    }`;
                } else if (item?.lawfirm) {
                    obj.title = `Appointment with ${item?.lawfirm?.hospitalName} `;
                    obj.description = `Appointment with ${item?.lawfirm?.hospitalName} on ${moment(
                        item.appointmentStartTime,
                        'HH:mm'
                    )}, ${
                        item.appointmentDate && moment(item.appointmentDate).format('DD/MM/YYYY')
                    }`;
                }

                obj.start = moment(
                    `${moment(item?.appointmentDate).format('DD/MM/YYYY')} ${
                        item.appointmentStartTime
                    }`,
                    'DD/MM/YYYY HH:mm'
                ).toISOString();

                obj.end = moment(
                    `${moment(item?.appointmentDate).format('DD/MM/YYYY')} ${
                        item.appointmentEndTime
                    }`,
                    'DD/MM/YYYY hh:mm'
                ).toISOString();
                obj = { ...obj, ...item };
                return obj;
            });

    let events = [...appEvents, ...calendarEvents];

    const openEvent = async (evt) => {
        setSelectedEvent(evt);
        setShowEvent(true);
    };

    // useEffect(() => {
    //   if (appointments) {
    //     appointments.forEach((item, index) => {
    //       if (
    //         item.appointmentStatusOfDoctor != 'COMPLETED' &&
    //         item.appointmentStatusOfDoctor != 'CANCELLED' &&
    //         (item.appointmentStatus == "COMPLETED" || item.appointmentStatus == "MISSED" ) &&
    //         moment(
    //           `${moment(item.appointmentDate).format('DD/MM/YYYY')} ${
    //             item.appointmentEndTime
    //           }`,
    //           'DD/MM/YYYY HH:mm'
    //         ).isBefore(new Date())
    //       ) {
    //         setCompleteAppointmentModal(true)
    //         setCompleteAppointmentModalItem(item)
    //       }
    //     })
    //   }
    // }, [appointments])

    return (
        <Elements stripe={stripePromise}>
            <motion.div
                variants={appointmentVariants}
                initial='hidden'
                animate='visible'
                exit='exit'
            >
                {console.log('events', events)}
                <div className='app-headings'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start'
                        }}
                    >
                        <p style={{ fontSize: '2rem' }}>Appointments </p>
                        {/* <span style={{display:'block'}}>Server Time: {moment(currentTime).format('ddd MMMM YYYY, HH:mm:ss')}</span> */}
                        <CurrentTime />
                    </div>

                    <div className='app-switch'>
                        <FaTasks onClick={() => setCalenderView(false)} />
                        <p>|</p>
                        <BsCalendar onClick={() => setCalenderView(true)} />
                    </div>
                </div>
                <AnimatePresence exitBeforeEnter>
                    {calenderView && (
                        <motion.div
                            key='1'
                            variants={appointmentVariants}
                            initial='hidden'
                            animate='visible'
                            exit='exit'
                            className='appointment-calendar'
                        >
                            {/* <Calendar onSelect={(e)=>onSelect(e)} /> */}
                            <FullCalendar
                                plugins={[
                                    dayGridPlugin,
                                    interactionPlugin,
                                    timeGridPlugin,
                                    momentPlugin,
                                    listPlugin
                                ]}
                                windowResizeDelay={0}
                                initialView='dayGridMonth'
                                displayEventTime={true}
                                // displayEventEnd={true}
                                selectable={true}
                                editable={true}
                                dragRevertDuration={1}
                                // timeZone='Local'
                                timeFormat='hh:mm'
                                eventTimeFormat={{
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    hour12: false
                                }}
                                dateClick={(val) => onSelect(val.dateStr)}
                                eventClick={(val) => openEvent(val.event)}
                                headerToolbar={{
                                    left: 'prev,next',
                                    center: 'title',
                                    right: 'timeGridDay,timeGridWeek,dayGridMonth,listWeek'
                                }}
                                events={events}
                            />
                        </motion.div>
                    )}
                    {!calenderView && (
                        <div>
                            <AppointmentHeader auth={auth} userType={"PATIENT"} history={history} />

                            <DataTable
                                appointments={appointments}
                                userType={"PATIENT"}
                                auth={auth}
                                cancelAppointment={cancelAppointment}
                                approveAppointment={approveAppointment}
                                setChosenUser={setChosenUser}
                                setReviewModal={setReviewModal}
                                limit={limit}
                                page={page}
                                history={history}
                                appointmentPaymentObj={appointmentPaymentObj}
                                setAppointmentPaymentObj={setAppointmentPaymentObj}
                                appointmentPaymentModal={appointmentPaymentModal}
                                setAppointmentPaymentModal={setAppointmentPaymentModal}
                                cardElementError={cardElementError}
                                setCardElementError={setCardElementError}
                                cardElementValid={cardElementValid}
                                setCardElementValid={setCardElementValid}
                            />
                        </div>
                    )}
                </AnimatePresence>
                <AppointmentModal open={open} setOpen={setOpen} />

                <Modal
                    className='addReview'
                    title='Add a Review'
                    open={reviewModal}
                    onCancel={() => setReviewModal(false)}
                    footer={null}
                >
                    <label>
                        <span>Rating</span>
                        <ReactStars
                            style={{ justifyContent: 'center !important' }}
                            value={rating}
                            edit={true}
                            onChange={(value) => setRating(value)}
                            count={5}
                            size={25}
                            activeColor='goldenrod'
                        />
                    </label>

                    <label>
                        <span>Short Description</span>
                        <input
                            placeholder='short description'
                            value={shortDescription}
                            onChange={(e) => setShortDescription(e.target.value)}
                        />
                    </label>

                    <label>
                        <span>Description</span>
                        <textarea
                            placeholder='Description'
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </label>
                    <button className='red-btn' onClick={() => addReview()}>
                        Post Review
                    </button>
                </Modal>
                <ShowEventModal
                    showEvent={showEvent}
                    setShowEvent={setShowEvent}
                    selectedEvent={selectedEvent}
                    setSelectedEvent={setSelectedEvent}
                    history={history}
                    auth={auth}
                />
                <Pagination
                    style={{ marginTop: 20 }}
                    showQuickJumper
                    current={page}
                    total={appointmentsCount}
                    onChange={(val) => setPage(val)}
                    pageSize={limit}
                    pageSizeOptions={[5, 10, 20]}
                    showSizeChanger={true}
                    responsive={true}
                    onShowSizeChange={(size, newSize) => setLimit(newSize)}
                />

                {completeAppointmentModal && completeAppointmentModalItem && (
                    <CompleteAppointmentConfirmation
                        {...props}
                        userType={userType}
                        completeAppointmentModal={completeAppointmentModal}
                        appointment={completeAppointmentModalItem}
                        getAppointments={getAppointments}
                        onClose={() => {}}
                        acceptMethod={() => {}}
                    />
                )}
            </motion.div>{' '}
        </Elements>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    // branches: state.auth.userProfile.hospitalBranches,
    branches: state.branch.branches,
    availability: state.availability,
    availabilities: state.availability.availabilities,
    doctorTimeSlots:
        state.doctors &&
        state.doctors.doctorForAppointment &&
        state.doctors.doctorForAppointment.timeSlots,
    appointments: state.appointments
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    availabilityActions: bindActionCreators(availabilityActions, dispatch),
    appointmentActions: bindActionCreators(appointmentActions, dispatch),
    doctorActions: bindActionCreators(doctorActions, dispatch),
    reviewActions: bindActionCreators(reviewActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Appointments);
