import { legacy_createStore as createStore, compose, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';
import { createLogger } from 'redux-logger';
const logger = createLogger({
    collapsed: true,
    diff: true,
    diffPredicate: true
});
const middleware = [thunk];



let composeEnhancers = null;
if (process.env.NODE_ENV == 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) {
    composeEnhancers = compose(
        applyMiddleware(...middleware),
        window.__REDUX_DEVTOOLS_EXTENSION__()
    );
} else {
    composeEnhancers = compose(applyMiddleware(...middleware));
}

const store = createStore(rootReducer, composeEnhancers);

export default store;
