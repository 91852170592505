import React, { useState, useEffect, memo } from 'react';
import './members.css';
import MemberList from './Members/MemberList';
import CreateMember from './Members/CreateMember';
import UpdateMember from './Members/UpdateMember';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as hospitalActions from '@/actions/hospitalActions';
import * as branchActions from '@/actions/branchActions';
import * as generalActions from '@/actions/generalActions';
import PageHeader from '../Shared/PageHeader';
import { Select, Pagination, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const Members = (props) => {
    // const [memberType, setMemberType] = useState('Doctor');
    const [allDoctors, setAllDoctors] = useState([]);
    const [createDoctorModal, setCreateDoctorModal] = useState(false);
    const [addDoctorsLimit, setAddDoctorsLimit] = useState(0);
    const [memberToBeUpdated, setMemberToBeUpdated] = useState(null);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const history = useHistory();

    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const memberType = queryParams.get('memberType') || 'Doctor';

    useEffect(() => {
        // Update the URL with the current memberType
        queryParams.set('memberType', memberType);
        history.push({ search: queryParams.toString() });
    }, [memberType]);

    const handleMemberChange = (val) => {
        queryParams.set('memberType', val);
        history.push({ search: queryParams.toString() });
    };
    const [sort, setSort] = useState('');
    const [des, setDes] = useState({
        firstName: false,
        lastName: false,
        username: false,
        email: false,
        phoneNo: false,
        status: false
    });

    const { profile, membersCount } = props;

    useEffect(() => {
        if (profile.userLimit) {
            setAddDoctorsLimit(profile.userLimit);
        }
    }, [profile.userLimit]);

    useEffect(() => {
        if (profile?.scope) {
            switch (profile.scope) {
                case 'ALL':
                case 'DOCTOR':
                    queryParams.set('memberType', 'Doctor');
                    break;
                case 'CONSULTANT':
                    queryParams.set('memberType', 'Consultant');
                    break;
                case 'NURSE':
                    queryParams.set('memberType', 'Nurse');
                    break;
                case 'CAREGIVER':
                    queryParams.set('memberType', 'Caregiver');
                    break;
                case 'THERAPIST':
                    queryParams.set('memberType', 'Therapist');
                    break;
                default:
                    queryParams.set('memberType', 'Doctor');
                    break;
            }
            history.push({ search: queryParams.toString() });
        }
    }, [profile?.scope]);

    const getHospitalServices = async () => {
        let obj = {
            userId: props?.auth?.userId,
            // branch: item.branch,
            hospital: props?.auth?.userId
        };
        await props.generalActions.getHospitalServices({ ...props, obj });
    };

    useEffect(() => {
        getHospitalServices();
    }, []);

    const getMembers = async () => {
        let obj = {
            hospitalSingleDoctors: false,
            hospitalId: props.auth && props.auth.userId && props.auth.userId,
            page: page,
            perPage: limit,
            memberType
        };
        await props.hospitalActions.getHospitalMembers({ ...props, obj }, props.history);
    };
    useEffect(() => {
        getMembers();
    }, [memberType]);

    let id = profile ? profile.id : '';

    const handleModal = () => {
        if (membersCount == addDoctorsLimit) {
            message.error('Already created maximum number of Members');
            return;
        }
        setCreateDoctorModal(true);
    };

    return (
        <div>
            <PageHeader
                heading={`${memberType}s`}
                actions={() => (
                    <div className='doctor-limit'>
                        <p
                            style={{
                                backgroundColor: 'red',
                                color: 'white',
                                width: 'fit-content',
                                padding: '5px 15px',
                                marginLeft: 'auto',
                                borderRadius: 4,
                                fontSize: 14
                            }}
                        >
                            Users Limit: {addDoctorsLimit}, Regular:{' '}
                            {profile.numOfUsers -
                                profile.numOfFeaturedUsers +
                                '/' +
                                (profile.userLimit - profile.featuredUserLimit)}
                            , Featured:{' '}
                            {profile.numOfFeaturedUsers + '/' + profile.featuredUserLimit}
                        </p>
                    </div>
                )}
            />

            <MemberList
                handleModal={handleModal}
                members={allDoctors && allDoctors.sort()}
                memberToBeUpdated={memberToBeUpdated}
                setMemberToBeUpdated={setMemberToBeUpdated}
                createDoctorModal={createDoctorModal}
                setCreateDoctorModal={handleModal}
                limit={limit}
                memberType={memberType}
                onMemberChange={handleMemberChange}
                {...props}
            />
            <Pagination
                style={{ margin: 20, display: 'flex', justifyContent: 'flex-end' }}
                current={page}
                total={membersCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
            {createDoctorModal && (
                <CreateMember
                    open={createDoctorModal}
                    set={setCreateDoctorModal}
                    id={id}
                    addDoctorsLimit={addDoctorsLimit}
                    limit={limit}
                    page={page}
                    hospital={profile}
                    memberType={memberType}
                    getMembers={getMembers}
                    {...props}
                />
            )}
            {memberToBeUpdated && (
                <UpdateMember
                    open={memberToBeUpdated}
                    set={setMemberToBeUpdated}
                    id={id}
                    limit={limit}
                    page={page}
                    hospital={profile}
                    memberType={memberType}
                    getMembers={getMembers}
                    {...props}
                />
            )}
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    members: (state.hospital && state.hospital.members && state.hospital.members) || [],
    membersCount:
        (state.hospital && state.hospital.membersCount && state.hospital.membersCount) || 0,
    branch: state.branch,
    branches: state.branch && state.branch.branches && state.branch.branches,
    general: state.general,
    specializedRoles:
        state.general && state.general.specializedRoles && state.general.specializedRoles,
    practiceAreas: state.general && state.general.practiceAreas && state.general.practiceAreas
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    hospitalActions: bindActionCreators(hospitalActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(Members));
