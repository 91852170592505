import React from 'react';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Layout, Button, message, Space } from 'antd';
import useWindowSize from '@/hooks/useWindowSize';
import SearchBar from '../../../IndexShared/SearchBar';
import LocationFinder from '../../../IndexShared/LocationFinder';
import UserDropdown from '../../../IndexShared/UserDropdown';

const { Header } = Layout;

const LayoutHeader = (props) => {
    const { collapsed, setCollapsed } = props;
    const { width } = useWindowSize();

    return (
        <Header
            style={{
                position: 'fixed',
                width:
                    width > 768 && collapsed
                        ? 'calc(100vw - 80px)'
                        : width > 768
                        ? 'calc(100vw - 250px)'
                        : '100%',
                left: width > 768 && collapsed ? 80 : width > 768 ? 250 : 0,
                top: 0,
                zIndex: 5,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0px 20px',
                background: '#fff',
                transition: 'all 0.2s'
            }}
        >
            <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                <Button
                    type='text'
                    icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                    onClick={() => setCollapsed(!collapsed)}
                    style={{
                        fontSize: '16px',
                        width: 64,
                        height: 64
                    }}
                />
                {width > 756 && <LocationFinder />}
            </div>{' '}
            {width > 756 && (
                <>
                    <div style={{ width: 450, position: 'absolute', left: '50%', transform: 'translate3d(-50%, 0px, 0px)' }}>
                        <SearchBar {...props} />
                    </div>
                </>
            )}
            <Space style={{ marginLeft: width <= 756 ? 'auto' : '' }}>
                <UserDropdown />
            </Space>
        </Header>
    );
};

export default LayoutHeader;
