import React, { useEffect } from 'react';
import Axios from 'axios';
import Geocode from 'react-geocode';
import { GetLocationUsingLatLng } from './GetLocationUsingLatLng';
var countries = require('country-data').countries;

function GetMyLocation(props) {
    const { setLoading, changeLocation } = props;
    Geocode.setApiKey('AIzaSyBgxSzayC4ePVoGH_DKLBSRzPtszez1G2w');
    const geoLocate = () => {
        navigator.geolocation.getCurrentPosition(
            async function (position) {
                var lat = position.coords.latitude;
                var lng = position.coords.longitude;

                localStorage.setItem('lat', lat);
                localStorage.setItem('lng', lng);
                // Axios.get(
                //   "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                //     lat +
                //     "," +
                //     lng +
                //     // "&key=AIzaSyB6CeCRWx766zPt6EeOpxeBgjJXDqcCwpA"
                //     "&key=AIzaSyBgxSzayC4ePVoGH_DKLBSRzPtszez1G2w"
                // )
                let loc = await GetLocationUsingLatLng({ lng, lat });
                if (loc) {
                    if (loc?.stateShort) {
                        localStorage.setItem('localityState', loc.stateShort);
                    } else if (loc?.state) {
                        localStorage.setItem('localityState', loc.state);
                    }
                    if (loc.country) {
                        localStorage.setItem('localityCountry', loc.country);
                    }
                    if (loc.countryShort) {
                        localStorage.setItem('localityCountryShort', loc.countryShort);
                        localStorage.setItem('currency', countries[loc.countryShort].currencies[0]);
                        localStorage.setItem(
                            'calling_code',
                            countries[loc.countryShort].countryCallingCodes[0]
                        );
                        localStorage.setItem('languages', countries[loc.countryShort].languages[0]);
                    }
                    if (loc.city) {
                        localStorage.setItem('locality', loc.city);
                    }
                    if (loc.zip) {
                        localStorage.setItem('localityZip', loc.zip);
                    }
                }

                if (changeLocation) {
                    changeLocation();
                }
            },
            async (error) => {
                let data = await Axios.get('https://ip.nf/me.json');

                data = data && data.data && data.data.ip;

                var lat = data.latitude;
                var lng = data.longitude;

                localStorage.setItem('lat', lat);
                localStorage.setItem('lng', lng);

                let loc = await GetLocationUsingLatLng({ lng, lat });

                if (loc) {
                    if (loc.stateShort) {
                        localStorage.setItem('localityState', loc.stateShort);
                    } else if (loc.state) {
                        localStorage.setItem('localityState', loc.state);
                    }
                    if (loc.country) {
                        localStorage.setItem('localityCountry', loc.country);
                    }
                    if (loc.countryShort) {
                        localStorage.setItem('localityCountryShort', loc.countryShort);
                        localStorage.setItem('currency', countries[loc.countryShort].currencies[0]);
                        localStorage.setItem(
                            'calling_code',
                            countries[loc.countryShort].countryCallingCodes[0]
                        );
                    }
                    if (loc.city) {
                        localStorage.setItem('locality', loc.city);
                    }
                    if (loc.zip) {
                        localStorage.setItem('localityZip', loc.zip);
                    }
                }

                if (changeLocation) {
                    changeLocation();
                }
                // // Axios.get(
                // //   "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
                // //     lat +
                // //     "," +
                // //     lng +
                // //     // "&key=AIzaSyB6CeCRWx766zPt6EeOpxeBgjJXDqcCwpA"
                // //     "&key=AIzaSyBgxSzayC4ePVoGH_DKLBSRzPtszez1G2w"
                // // )
                // Geocode.fromLatLng(lat, lng)
                // .then(function (response) {
                //   if (response) {
                //     for (var i = 0; i < response.results.length; i++) {
                //       if (response.results[i].types[0] == "country") {
                //         var countryshort =
                //           response.results[i].address_components[0].short_name;
                //         var countrylong =
                //           response.results[i].address_components[0].long_name;

                //         localStorage.setItem("country_short", countryshort);
                //         localStorage.setItem("country_long", countrylong);
                //         localStorage.setItem("Currentcountry", countrylong);
                //         localStorage.setItem(
                //           "currency",
                //           countries[countryshort].currencies[0]
                //         );
                //         localStorage.setItem(
                //           "calling_code",
                //           countries[countryshort].countryCallingCodes[0]
                //         );
                //       }
                //       if (response.results[i].types[0] == "locality") {
                //         var locality =
                //           response.results[i].address_components[0].short_name;
                //         var localityState =
                //           response.results[i].address_components[2].long_name;
                //         var localityCountry =
                //           response.results[i].address_components[3].long_name;

                //         localStorage.setItem("locality", locality);
                //         localStorage.setItem("localityState", localityState);
                //         localStorage.setItem("localityCountry", localityCountry);
                //       //   setLocality(locality);
                //       //   setLocalityCountry(localityCountry);
                //         // alert(locality + "," + localityCountry);
                //       }
                //     }
                //   } else {
                //   }

                //   if(changeLocation){
                //     changeLocation()
                //   }
                // });
            }
        );
        setLoading(false);
    };

    useEffect(() => {
        geoLocate();
    }, []);

    return null;
}

export default GetMyLocation;
