import React, { memo } from 'react';
import StarRatingComponent from 'react-star-rating-component';

function Card(props) {
    const { type, item, pointer, setPointer, history } = props;

    let days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
    let availableDays = [];

    let avail = item.availability ? item.availability : null;
    // for(let i = 0; i< days.length; i++){
    //   if(avail && avail[days[i]]){
    //     availableDays.push(days[i])
    //   }
    // }
    days.forEach((day) => {
        if (avail && avail[day]) {
            availableDays.push(day);
        }
    });

    return (
        <div className='firstCard'>
            <div className='avatar'>
                <svg id='svg-msg'>
                    <circle id='svg-circle' className='svg-circle' cx='50%' cy='50%' r='55' />
                </svg>
                <img src={item.profileImage && item.profileImage} />
            </div>
            <div>
                <p className='name'>
                    {item.userType == "DOCTOR" ? (
                        <>
                            {item.firstName && item.firstName} {item.lastName && item.lastName}
                        </>
                    ) : (
                        item.userType == "HOSPITAL" && <>{item.hospitalName && item.hospitalName}</>
                    )}
                </p>
                <StarRatingComponent name='rate2' editing={false} starCount={5} value={3} />
                <p className='practiceAreas'>
                    {item.practiceAreas &&
                        item.practiceAreas.length > 0 &&
                        item.practiceAreas.slice(0, 2).toString().replace(',', ' | ')}
                </p>

                <p>
                    {availableDays.length > 0 ? (
                        <>
                            Available on{' '}
                            {days &&
                                availableDays.map((item, index) => {
                                    // if (avail[item]) {
                                    return (
                                        <strong key={index}>
                                            {item.charAt(0).toUpperCase() + item.slice(1)}
                                            {index < availableDays.length - 2 && ', '}
                                            {index < availableDays.length - 1 && ` and `}
                                        </strong>
                                    );
                                    // }
                                })}
                        </>
                    ) : (
                        <strong>Not Available</strong>
                    )}
                </p>
            </div>

            <div
                className='sendMessage'
                onClick={() => {
                    setPointer('2');
                    history.push(
                        `/patient/appointments/new?step=2&${
                            type.charAt(0).toLowerCase() + type.slice(1)
                        }=${item._id}&branch=${item.branch && item.branch._id}`
                    );
                }}
            >
                Select {type}
            </div>
        </div>
    );
}

export default memo(Card);
