import React from 'react';
import '../../assets/css/confirmModal.css';
import { Modal } from 'antd';

const ConfirmModal = (props) => {
    const {
        headerText,
        bodyText,
        acceptMethod,
        acceptText,
        declineText,
        onClose,
        open = true
    } = props;
    return (
        <Modal
            centered
            title={headerText}
            children={bodyText}
            open={open}
            onCancel={onClose}
            okText={acceptText || 'Yes'}
            onOk={acceptMethod}
            cancelText={declineText || 'No'}
            cancelButtonProps={{
                type: 'link',
                style: { color: '#000' }
            }}
            okButtonProps={{
                type: 'primary',
                danger: true
            }}
            destroyOnClose={true} // Unmounts the modal on close
        >
            <div>{bodyText}</div>
        </Modal>
    );
};

export default ConfirmModal;
