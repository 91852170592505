import React, { memo } from 'react';
import { Input } from 'antd';
const { TextArea } = Input;

const InputComponent = ({
    label,
    onChange,
    item,
    value,
    name,
    placeholder,
    type,
    className,
    suffix,
    textarea = false,
    innerContStyles,
    innerContClassName,
    disabled = false,
    required = false
}) => {
    return (
        <label className={`${!item?.isValid ? 'error' : ''} ${className}`}>
            <div>
                {label}
                {required && <span style={{ color: 'red' }}>*</span>}:
            </div>
            <div className={`${innerContClassName}`} style={{ ...innerContStyles }}>
                {textarea ? (
                    <TextArea
                        disabled={disabled}
                        rows='4'
                        type={type ?? 'text'}
                        name={name}
                        placeholder={placeholder}
                        value={item.value}
                        onChange={onChange}
                    />
                ) : (
                    <Input
                        disabled={disabled}
                        type={type ?? 'text'}
                        name={name}
                        placeholder={placeholder}
                        value={item.value}
                        onChange={onChange}
                    />
                )}
                {suffix}
            </div>
            {item.message && (
                <p>
                    {' '}
                    <i className='fe fe-alert-triangle' /> {item.message}
                </p>
            )}
        </label>
    );
};

export default InputComponent;
