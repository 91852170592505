import React, { useState } from 'react';
import './FinalStage.css';
import { useDispatch } from 'react-redux';
import GoogleMapReact from 'google-map-react';
import StarRatingComponent from 'react-star-rating-component';
import documentIcon from '@/assets/img/document.png';
import moment from 'moment';
import { Button } from 'antd';
const Marker = ({ text }) => (
    <div
        className='marker'
        style={{
            color: 'white',
            padding: '15px 10px',
            display: 'inline-flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '100%',
            transform: 'translate(-50%, -50%)'
        }}
    >
        <i
            className='fe fe-map-pin'
            style={{ fontSize: '30px', color: '#f1433f', position: 'relative' }}
        ></i>
    </div>
);

function DetailPage(props) {
    const dispatch = useDispatch();
    const [center, setCenter] = useState({
        lat: 59.95,
        lng: 30.33
    });
    const [zoom, setZoom] = useState(11);

    const {
        consultationMode,
        setConsultationMode,
        paymentMode,
        setPaymentMode,
        appointmentDate,
        setAppointmentDate,
        refreshDetails,
        auth,
        notes,
        selectedSlot,
        setSelectedSlot,
        hospital,
        doctors,
        files,
        pointer,
        setPointer,
        chosenService,
        setChosenService,
        chosenServiceObj,
        setChosenServiceObj,
        submitForm,
        loading,
        setLoading
    } = props;

    const { hospitalForAppointment: apptHospital } = hospital;
    const { doctorForAppointment: apptDoctor } = doctors;

    let timeSlots = [];
    let avail = {};
    console.log(apptHospital, apptDoctor);
    if (apptHospital && apptHospital.hospital && Object.keys(apptHospital.hospital).length != 0) {
        timeSlots = apptHospital.timeSlots && apptHospital.timeSlots;
        avail = apptHospital.availability && apptHospital.availability;
    } else if (apptDoctor && apptDoctor.doctor && Object.keys(apptDoctor.doctor).length != 0) {
        timeSlots = apptDoctor.timeSlots && apptDoctor.timeSlots;
        avail = apptDoctor.availability && apptDoctor.availability;
    }

    let chosenUser;
    if (apptHospital && apptHospital.hospital && Object.keys(apptHospital.hospital).length != 0) {
        chosenUser = apptHospital.hospital;
    } else if (apptDoctor && apptDoctor.doctor && Object.keys(apptDoctor.doctor).length != 0) {
        chosenUser = apptDoctor.doctor;
    }
    const dateFormat = 'DD/MM/YYYY';
    const loc =
        chosenUser && chosenUser.branch && chosenUser.branch.location && chosenUser.branch.location;
    return (
        <div className='finalPage'>
            <div className='mainContent'>
                <div className='flexRow'>
                    <div className='flexCol'>
                        <div className='avatar'>
                            {chosenUser?.profileImage && <img src={chosenUser.profileImage} />}
                        </div>
                        {chosenUser && chosenUser.userType == "DOCTOR" ? (
                            <>
                                <p className='name'>
                                    {chosenUser?.firstName} {chosenUser?.lastName}
                                </p>
                                <p className='hospitalName' style={{ fontSize: 12, color: 'gray' }}>
                                    {chosenUser?.hospitalName}
                                </p>
                            </>
                        ) : chosenUser?.userType == "HOSPITAL" ? (
                            <p className='name'>{chosenUser?.hospitalName}</p>
                        ) : (
                            <>
                                <p className='name'>Doctor/Hospital</p>
                                <p className='hospitalName'>Firm Name</p>
                            </>
                        )}

                        <StarRatingComponent name='rate2' editing={false} starCount={5} value={3} />
                        <p>
                            {loc ? (
                                <>
                                    {loc && (
                                        <>
                                            {loc.branchCity} , {loc.branchState}, {loc.branchZip},{' '}
                                            {loc.branchCountry}
                                        </>
                                    )}
                                </>
                            ) : (
                                'Kalabagan 2nd, lane, Dhaka, Bangladesh'
                            )}
                        </p>
                        <div style={{ height: 300, width: '100%', marginTop: 'auto' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{
                                    key: 'AIzaSyBgxSzayC4ePVoGH_DKLBSRzPtszez1G2w'
                                }}
                                defaultCenter={center}
                                defaultZoom={zoom}
                                center={
                                    loc &&
                                    loc.branchCoords &&
                                    loc.branchCoords &&
                                    loc.branchCoords.lat
                                        ? loc.branchCoords
                                        : center
                                }
                                className='googleMap'
                            >
                                {loc?.branchCoords?.lat ? (
                                    <Marker
                                        lat={loc?.branchCoords?.lat}
                                        lng={loc?.branchCoords?.lng}
                                        text='My Marker'
                                    />
                                ) : (
                                    <Marker lat={center?.lat} lng={center?.lng} text='My Marker' />
                                )}
                            </GoogleMapReact>
                        </div>
                    </div>

                    <div className='flexCol secondCol'>
                        <div className='header'>
                            <p>Appointment Schedule Details</p>
                        </div>

                        <div className='filesNotes'>
                            <div>
                                <p>Notes set for upload</p>
                                <div className='fnrow'>
                                    <div>
                                        <img src={documentIcon} />
                                    </div>
                                    <p>{notes && notes.length} notes added</p>
                                    <Button type="default">View All</Button>
                                </div>
                            </div>

                            <div>
                                <p>Documents set for upload</p>
                                <div className='fnrow'>
                                    <div>
                                        <img src={documentIcon} />
                                    </div>
                                    <p>{files && files.length} documents selected</p>
                                    <Button type="default">View All</Button>
                                </div>
                            </div>
                        </div>

                        <div></div>
                        {console.log(selectedSlot, timeSlots[selectedSlot])}
                        <div className='body'>
                            <p>
                                Appointment Date:{' '}
                                <span style={{ color: 'var(--primary)' }}>
                                    {appointmentDate &&
                                        moment(appointmentDate, dateFormat).format(dateFormat)}
                                </span>
                            </p>
                            <p>
                                Appointment Slot:{' '}
                                <span style={{ color: 'var(--primary)' }}>
                                    {' '}
                                    {(selectedSlot || selectedSlot === 0) && (
                                        <>
                                            {timeSlots[selectedSlot]}{' '}
                                            {timeSlots[selectedSlot + 1]
                                                ? ' - ' + timeSlots[selectedSlot + 1]
                                                : ''}{' '}
                                        </>
                                    )}{' '}
                                </span>
                            </p>
                            <p>
                                Consultation Type:{' '}
                                <span style={{ color: 'var(--primary)' }}>
                                    {consultationMode ? consultationMode : 'In-Person'}
                                </span>
                            </p>
                            <p>
                                Payment Mode:{' '}
                                <span style={{ color: 'var(--primary)' }}>
                                    {paymentMode ? paymentMode : 'Cash'}
                                </span>
                            </p>
                            <p>
                                Payment Method:{' '}
                                <span style={{ color: 'var(--primary)' }}>
                                    {paymentMode ? paymentMode : 'In-Person'}
                                </span>
                            </p>
                            <p>
                                Payment Amount:{' '}
                                <span style={{ color: 'var(--primary)' }}>
                                    $ {chosenServiceObj?.rate}
                                </span>
                            </p>
                            <p>
                                Appointment Address:{' '}
                                <span style={{ color: 'var(--primary)' }}>
                                    {' '}
                                    <p>
                                        {loc ? (
                                            <>
                                                {loc && (
                                                    <>
                                                        {loc.branchCity} , {loc.branchState},{' '}
                                                        {loc.branchZip}, {loc.branchCountry}
                                                    </>
                                                )}
                                            </>
                                        ) : (
                                            'Kalabagan 2nd, lane, Dhaka, Bangladesh'
                                        )}
                                    </p>
                                </span>
                            </p>
                        </div>
                        <div className='btnGrp'>
                            <Button type='primary' loading={loading} onClick={() => submitForm()}>
                                Request Appointment{' '}
                            </Button>
                            {console.log(paymentMode == 'Online' && chosenServiceObj.rate != '0', chosenServiceObj, paymentMode)}
                            <Button
                                type='default'
                                onClick={() =>
                                    paymentMode == 'Online' && chosenServiceObj?.rate != '0'
                                        ? setPointer(2)
                                        : setPointer(1)
                                }
                            >
                                {' '}
                                Go Back{' '}
                            </Button>
                        </div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DetailPage;
