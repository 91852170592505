import React, { useState, useRef, useEffect } from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@dmc4719/ckeditor5-custom-build/build/ckeditor';
import { CONFIG } from '../../../MiniComponents/CKeditorConfig';
import MyCKEditorUploadAdapter from '../Blogs/MyCKEditorUploadAdapter';
import { GrDocumentNotes } from 'react-icons/gr';
import { BsFiles } from 'react-icons/bs';
import { Tooltip } from 'antd';
import CheckFiles from './CheckFiles';

function InstructionWindow(props) {
    const { socket, conversationId, userType, appointment } = props;
    const [viewDocumentModal, setViewDocumentModal] = useState(null);
    const [view, setView] = useState('files');
    const editor = useRef(null);
    const [editorValue, setEditorValue] = useState({
        value: '',
        message: ''
    });
    const [instrc, setInstrc] = useState('');
    useEffect(() => {
        socket.on('receiveInstructions', (data) => {
            console.log(data);
            setInstrc(data.instructions);
        });
    }, []);

    const onChange = (event, editor) => {
        const data = editor.getData();
        if (data != '') {
            setEditorValue({ ...editorValue, value: data, message: '' });
        } else {
            setEditorValue({
                ...editorValue,
                message: 'Body field cannot be empty',
                value: data
            });
        }
        socket.emit('instruct', { instructions: data, conversationId });
    };
    return (
        <div className='instructionWindow'>
            <div className='head'>
                <div>Instructions</div>{' '}
                <div className='rightBtns'>
                    <Tooltip title='View submitted notes' placement='topRight'>
                        <GrDocumentNotes
                            onClick={() => {
                                setView('Notes');
                                setViewDocumentModal(true);
                            }}
                        />
                    </Tooltip>
                    <Tooltip title='View Submitted documents' placement='topRight'>
                        <BsFiles
                            onClick={() => {
                                setView('Files');
                                setViewDocumentModal(true);
                            }}
                        />
                    </Tooltip>
                </div>
            </div>
            <div className='body'>
                {['CAREGIVER', 'CONSULTANT', 'DOCTOR', 'THERAPIST', 'NURSE'].includes(userType) && (
                    <CKEditor
                        ref={editor}
                        config={{
                            ...CONFIG,
                            toolbar: {
                                ...CONFIG.toolbar,
                                items: [
                                    'heading',
                                    '|',
                                    'bold',
                                    'italic',
                                    'underline',
                                    'fontBackgroundColor',
                                    'fontColor',
                                    'fontSize',
                                    'bulletedList',
                                    'numberedList',
                                    'removeFormat',
                                    '|',
                                    'alignment'
                                ]
                            }
                        }}
                        // style={{ width: "100%", margin: "auto" }}
                        editor={ClassicEditor}
                        data={editorValue.value}
                        onReady={(editor) => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                            editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
                                return new MyCKEditorUploadAdapter({ loader, ...props });
                            };
                        }}
                        onChange={onChange}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />
                )}
                {userType == 'PATIENT' && (
                    <div className='instructions' dangerouslySetInnerHTML={{ __html: instrc }} />
                )}
            </div>
            {/* <textarea
        
         /> */}

            <CheckFiles
                view={view}
                setView={setView}
                appointment={appointment}
                viewDocumentModal={viewDocumentModal}
                setViewDocumentModal={setViewDocumentModal}
            />
        </div>
    );
}

export default InstructionWindow;
