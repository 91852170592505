import React, { memo } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import coverPlaceholderImg from '@/assets/images/coverPlaceholder.jpeg';

function CoverImage({
    setState,
    deleteCoverImage,
    saveCoverImage,
    clearCoverImage,
    tempCoverImage,
    coverImage,
    formImageDirty,
    savedCoverImage
}) {
    console.log(coverImage, 'tempCoverImage || coverImage')
    return (
        <div className='coverImage'>
            <div className='overlay'></div>
            {tempCoverImage || coverImage ? (
                <img src={tempCoverImage ? tempCoverImage : coverImage ? coverImage : ''} />
            ) : (
                <img src={coverPlaceholderImg} />
            )}
            {!tempCoverImage ? (
                <div
                    className='coverImageAction'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 20,
                        alignItems: 'center',
                        zIndex: 1
                    }}
                >
                    <div className='addCoverImage'>
                        <label
                            htmlFor='coverImage'
                            style={{
                                width: '100%',
                                marginTop: 0,
                                cursor: 'pointer'
                            }}
                        >
                            <input
                                type='file'
                                id='coverImage'
                                onChange={(e) => {
                                    if (e.target.files[0])
                                        setState({
                                            coverImage: e.target.files[0],
                                            formImageDirty: true,
                                            tempCoverImage: URL.createObjectURL(e.target.files[0])
                                        });
                                }}
                                style={{
                                    display: 'none'
                                }}
                            />
                            <UploadOutlined
                                style={{
                                    fontSize: '25px',
                                    color: '#f7f7f7'
                                }}
                            />
                        </label>
                    </div>
                    <p style={{ color: 'white' }}>
                        Please upload your profile background image (maximum file size: 2MB).
                    </p>
                </div>
            ) : null}

            {savedCoverImage && (
                <div className='coverButtons'>
                    <button onClick={() => deleteCoverImage()}>
                        <span>Remove</span>
                    </button>
                </div>
            )}
            {formImageDirty && tempCoverImage && (
                <>
                    {' '}
                    <div className='coverButtons'>
                        {clearCoverImage && (
                            <button onClick={() => clearCoverImage()}>
                                <span>Clear</span>
                            </button>
                        )}
                        <button onClick={() => saveCoverImage()}>
                            <span>Save</span>
                        </button>
                        <button onClick={() => deleteCoverImage()}>
                            <span>Remove</span>
                        </button>
                    </div>
                </>
            )}
        </div>
    );
}

export default memo(CoverImage, (prevProps, nextProps) => {
    if (prevProps.tempCoverImage !== nextProps.tempCoverImage) {
        return false;
    }
    if (prevProps.coverImage !== nextProps.coverImage) {
        return false;
    }
    if (prevProps.formImageDirty !== nextProps.formImageDirty) {
        return false;
    }
    if (prevProps.savedCoverImage !== nextProps.savedCoverImage) {
        return false;
    }
    return true;
});
