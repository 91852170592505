import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import * as stripeActions from '@/actions/stripeActions';
import { Radio } from 'antd';
import './paymentStage.css';
import { bkashLogo, stripeLogo, nogodLogo } from '@/assets/Images';
import { useDispatch, useSelector } from 'react-redux';
import { AiFillCreditCard } from 'react-icons/ai';
// import * as stripeActions from '@/actions/stripeActions'

function PaymentStage(props) {
    const {
        setPointer,
        auth,
        stripeToken,
        setStripeToken,
        cardElementError,
        setCardElementError,
        cardElementValid,
        setCardElementValid,
        chosenServiceObj
    } = props;
    const { firstName, lastName, email } = auth;
    const [stripeMethod, setStripeMethod] = useState('New Card');
    const stripe = useStripe();
    const elements = useElements();
    const dispatch = useDispatch();
    const { paymentMethods } = useSelector((state) => state.stripe);
    const setCard = async () => {
        let paymentMethodReq;

        if (stripeMethod == 'New Card') {
            const cardElement = elements.getElement('card');
            // const cardToken = await stripe.createToken(cardElement, {
            //   currency: 'usd'
            // })
            paymentMethodReq = await stripe.createPaymentMethod({
                type: 'card',
                card: cardElement,
                billing_details: {
                    name: firstName + ' ' + lastName,
                    email: email
                }
            });
        } else if (stripeMethod) {
            paymentMethodReq = stripeMethod;
        }

        if (
            (paymentMethodReq?.paymentMethod || stripeMethod != 'New Card') &&
            cardElementValid &&
            !cardElementError
        ) {
            setStripeToken(
                paymentMethodReq?.paymentMethod?.id || (stripeMethod != 'New Card' && stripeMethod)
            );
            setPointer(3);
            setCardElementError(false);
            setCardElementValid(true);
        } else {
            setCardElementError('Billing card is required');
            setCardElementValid(false);
        }
    };

    const getPaymentMethods = async () => {
        let obj = {};
        await dispatch(stripeActions.getCustomerStripePaymentMethods({ ...props, obj }));
    };

    useEffect(() => {
        if (stripeToken) {
            setStripeMethod(stripeToken);
        }
        getPaymentMethods();
    }, []);

    return (
        <div className='paymentStageCont'>
            <div className='left'></div>
            <div className='right'>
                <p>Payment Amount</p>
                <strong style={{ fontSize: 48 }}>
                    ${chosenServiceObj?.rate && parseFloat(chosenServiceObj?.rate).toFixed(2)}
                </strong>

                <p>Choose Payment Method</p>
                <div className='paymentMethods'>
                    <div className='stripeButton methodBtn active'>
                        <img src={stripeLogo} />
                    </div>
                    <div className='bkashButton methodBtn'>
                        {' '}
                        <img src={bkashLogo} />
                    </div>
                    <div className='nogodButton methodBtn'>
                        {' '}
                        <img src={nogodLogo} />
                    </div>
                </div>

                <PaymentComponent
                    paymentMethods={paymentMethods}
                    setPointer={setPointer}
                    auth={auth}
                    setCard={setCard}
                    cardElementError={cardElementError}
                    setCardElementError={setCardElementError}
                    cardElementValid={cardElementValid}
                    setCardElementValid={setCardElementValid}
                    stripeMethod={stripeMethod}
                    setStripeMethod={setStripeMethod}
                />
            </div>
        </div>
    );
}

function PaymentComponent({
    auth,
    setPointer,
    setCard,
    cardElementError,
    setCardElementError,
    cardElementValid,
    setCardElementValid,
    paymentMethods,
    stripeMethod,
    setStripeMethod
}) {
    const iframeStyles = {
        base: {
            color: '#000',
            fontSize: '16px',
            iconColor: '#000',
            '::placeholder': {
                color: 'gray'
            }
        },
        invalid: {
            iconColor: '#f14c48',
            color: '#f14c48'
        },
        complete: {
            iconColor: '#cbf4c9'
        }
    };

    const cardElementOpts = {
        iconStyle: 'solid',
        style: iframeStyles,
        hidePostalCode: true
    };
    const handleCardDetailsChange = (cardElement) => {
        console.log(cardElement);
        if (cardElement.error && cardElement.error.message) {
            setCardElementError(cardElement.error.message);
            setCardElementValid(false);
        } else {
            setCardElementError('');
            setCardElementValid(true);
        }
        if (cardElement.empty) {
            setCardElementValid(false);
            setCardElementError(cardElement.error.message);
        }
        // ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
    };
    return (
        <>
            {' '}
            <div className='pms'>
                <Radio.Group
                    onChange={(e) => setStripeMethod(e.target.value)}
                    value={stripeMethod}
                    style={{ flexDirection: 'column', alignItems: 'flex-start', display: 'flex' }}
                >
                    {paymentMethods?.map((item, index) => (
                        <Radio
                            key={index}
                            value={item.id}
                            className='cardPM'
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                columnGap: '10px'
                            }}
                        >
                            <AiFillCreditCard /> {item?.card?.brand} {item?.card?.last4}{' '}
                            <p> - {item.id}</p>{' '}
                        </Radio>
                    ))}
                    <Radio
                        value={'New Card'}
                        className='cardPM'
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            columnGap: '10px'
                        }}
                    >
                        <AiFillCreditCard /> New Card
                    </Radio>
                </Radio.Group>
            </div>
            {stripeMethod == 'New Card' && (
                <label
                    className={`${!cardElementValid ? 'error' : ''} fullLabel paymentStage`}
                    style={{ display: 'flex', flexDirection: 'column' }}
                >
                    {console.log(cardElementValid)}
                    Billing card
                    <div className='stripeCont'>
                        <CardElement options={cardElementOpts} onChange={handleCardDetailsChange} />
                    </div>
                    <p>
                        <i className='fe fe-alert-triangle' />

                        {cardElementError ? cardElementError : 'Billing card is required'}
                    </p>
                </label>
            )}
            <div style={{ width: '100%', columnGap: 10, display: 'flex' }}>
                <button
                    onClick={async () => {
                        setPointer(1);
                    }}
                    className='requestApp'
                >
                    Back
                </button>
                <button
                    onClick={async () => {
                        setCard();
                    }}
                    className='requestApp'
                >
                    Next
                </button>
            </div>
        </>
    );
}

export default PaymentStage;
