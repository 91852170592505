import React, { useState, useEffect } from 'react';
import { geocodeByAddress } from 'react-places-autocomplete';
import LocationFinderDropdown from './LocationFinder/LocationFinderDropdown';
import GetMyLocation from './GetMyLocation';
import '@/assets/css/locationFinder.css';
import { Button, Tooltip } from 'antd';
import useWindowSize from '../../hooks/useWindowSize';

const LocationFinder = ({ refreshData }) => {
    const { width } = useWindowSize();
    const [mapDropdown, setMapDropdown] = useState(false);
    const [locationTray, setLocationTray] = useState(2);
    const [locality, setLocality] = useState('');
    const [localityState, setLocalityState] = useState('');
    const [localityCountry, setLocalityCountry] = useState('');
    const [loading, setLoading] = useState(false);
    const [center, setCenter] = useState({
        lat: parseFloat(localStorage.getItem('lat')) || '',
        lng: parseFloat(localStorage.getItem('lng')) || ''
    });
    useEffect(() => {
        if (localStorage.getItem('lat') && localStorage.getItem('lng')) {
            setCenter({
                lat: parseFloat(localStorage.getItem('lat')),
                lng: parseFloat(localStorage.getItem('lng'))
            });
        }
        let localityInfo = localStorage.getItem('locality');
        let localityCountryInfo = localStorage.getItem('localityCountry');
        let localityState = localStorage.getItem('localityState');
        if (localityInfo) {
            setLocality(localityInfo);
        }
        if (localityState) {
            setLocalityState(localityState);
        }
        if (localityCountryInfo) {
            setLocalityCountry(localityCountryInfo);
        }
    }, []);
    const changeLocation = () => {
        const chosenLat = localStorage.getItem('chosenLat');
        const chosenLng = localStorage.getItem('chosenLng');
        if (chosenLat && chosenLng) {
            setCenter({
                lat: parseFloat(chosenLat),
                lng: parseFloat(chosenLng)
            });
        }
        const chosenLocality = localStorage.getItem('chosenLocality');
        const chosenState = localStorage.getItem('chosenState');
        const chosenCountry = localStorage.getItem('chosenCountry');
        if (chosenLocality && chosenCountry) {
            setLocality(chosenLocality);
            setLocalityCountry(chosenCountry);
        }
        if (!chosenLat || !chosenLng) {
            refreshData && refreshData();
        }
    };

    const renderLocation = () => {
        if (locality && localityCountry) {
            const chosenLocality = localStorage.getItem('chosenLocality');
            const chosenState = localStorage.getItem('chosenState');
            const chosenCountry = localStorage.getItem('chosenCountry');
            const tooltipContent = chosenCountry ? (
                <Tooltip
                    title={`${chosenLocality ? `${chosenLocality} ,` : ''}${
                        chosenState ? chosenState + ', ' : ''
                    }${chosenCountry}`}
                >
                    {`${chosenLocality ? `${chosenLocality} ,` : ''}${
                        chosenState ? chosenState + ', ' : ''
                    }${chosenCountry}`}
                </Tooltip>
            ) : (
                <Tooltip
                    title={`${locality}${', '}${
                        localityState ? localityState + ', ' : ''
                    }${localityCountry}`}
                >
                    {`${locality}${', '}${
                        localityState ? localityState + ', ' : ''
                    }${localityCountry}`}
                </Tooltip>
            );
            return tooltipContent;
        } else {
            return 'Enter your location';
        }
    };
    console.log(width > 756);
    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center',
                position: width > 756 ? 'relative' : '',
                color: 'var(--primary)'
            }}
            tabIndex={0}
        >
            <button
                type='text'
                onClick={() => {
                    setMapDropdown(!mapDropdown);
                    setLocationTray(1);
                }}
                style={{
                    cursor: 'pointer',
                    maxWidth: 200,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    fontFamily: "'Inter Var'",
                    fontWeight: 400,
                    whiteSpace: 'nowrap',
                    color: 'var(--bg-dark)',
                    marginLeft: 5,
                    padding: '0px 5px',
                    backgroundColor: 'transparent'
                }}
            >
                <i className='fe fe-map-pin' style={{ color: 'black', marginRight: 10 }}></i>
                {width > 756 && renderLocation()}
            </button>
            {mapDropdown && (
                <LocationFinderDropdown
                    mapDropdown={mapDropdown}
                    setMapDropdown={setMapDropdown}
                    geocodeByAddress={geocodeByAddress}
                    setLocationTray={setLocationTray}
                    locationTray={locationTray}
                    refreshData={refreshData}
                />
            )}
            <GetMyLocation
                setLocality={setLocality}
                setLocalityCountry={setLocalityCountry}
                loading={loading}
                setLoading={setLoading}
                changeLocation={changeLocation}
            />
        </div>
    );
};

export default LocationFinder;
