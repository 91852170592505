import { Button } from 'antd';
import React from 'react';
import { FiEdit2 } from 'react-icons/fi';
import ProfileSection from '../../DoctorProfile/ProfileSection';

export function AccountSettings({
    onChange,
    handleEnable,
    setState,
    saveProfile,
    deactivateAccount,
    disableEmail,
    disablePass,
    disablePhone,
    email,
    phoneNo,
    password,
    countryCode
}) {
    return (
        <ProfileSection title='Account Settings'>
            {' '}
            <div className='input-row'>
                <label className={`${!email.isValid ? 'error' : ''}`}>
                    Email:
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem'
                        }}
                    >
                        <input
                            name='email'
                            placeholder='Email'
                            value={email.value}
                            disabled={disableEmail}
                            onChange={onChange}
                        />
                        <div onClick={() => handleEnable('email')}>
                            <FiEdit2 />
                        </div>
                    </div>
                    {email.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {email.message}
                        </p>
                    )}
                </label>
                <label className={`${!password.isValid ? 'error' : ''}`}>
                    Password:
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem'
                        }}
                    >
                        <input
                            name='password'
                            type='password'
                            placeholder='************'
                            value={password.value}
                            disabled={disablePass}
                            onChange={onChange}
                        />
                        <div
                            onClick={() =>
                                setState({
                                    passwordModal: true
                                })
                            }
                        >
                            <FiEdit2 />
                        </div>
                    </div>
                </label>
            </div>
            <div className='input-row'>
                <label className={`${!phoneNo.isValid ? 'error' : ''}`}>
                    Phone No:
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '1rem'
                        }}
                    >
                        <input
                            name='phoneNo'
                            placeholder='Phone No.'
                            value={`${countryCode.value}${phoneNo.value}`}
                            disabled={disablePhone}
                            onChange={onChange}
                        />
                        <div onClick={() => handleEnable('phoneNo')}>
                            <FiEdit2 />
                        </div>
                    </div>
                    {phoneNo.message && (
                        <p>
                            {' '}
                            <i className='fe fe-alert-triangle' /> {phoneNo.message}
                        </p>
                    )}
                </label>
            </div>
            <Button
                type='primary'
                name='profileBtn'
                className='save-btn'
                onClick={(e) => saveProfile(e)}
            >
                Save
            </Button>
            <label
                style={{
                    flexDirection: 'row'
                }}
            >
                Deactivate your account? &nbsp;{' '}
                <span
                    style={{
                        textDecoration: 'underline',
                        color: 'red',
                        cursor: 'pointer'
                    }}
                    onClick={() => deactivateAccount()}
                >
                    Deactivate now.
                </span>
            </label>
        </ProfileSection>
    );
}
