import React from 'react';
import './DocumentHeader.css';
import { DatePicker, Row, Input, Button, Col } from 'antd';

const DocumentHeader = (props) => {
    const { search, setSearch, date, setDate, setOpen } = props;
    return (
        <Row gutter={[10, 10]} style={{ marginBottom: 20, justifyContent: 'flex-end' }}>
            <Col xs={24} lg={6}>
                <Input
                    placeholder='Search by document name or email shared with'
                    value={search}
                    onChange={(e) => setSearch(e.target.value)}
                />
            </Col>

            <Col xs={24} lg={6}>
                <DatePicker
                    style={{ width: '100%' }}
                    value={date ? date : ''}
                    onChange={(val, string) => (val ? setDate(val) : setDate(''))}
                    name='dob'
                    placeholder='Search by date'
                />
            </Col>

            <Col xs={24} lg={4}>
                <Button
                    style={{ width: '100%' }}
                    type='primary'
                    onClick={() => {
                        setOpen && setOpen(true);
                    }}
                >
                    Share Document
                </Button>
            </Col>
        </Row>
    );
};

export default DocumentHeader;
