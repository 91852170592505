import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';
let { REACT_APP_API_URI } = process.env;

if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const getSpecializedRoles = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getSpecializedRoles`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_SPECIALIZED_ROLES,
                        payload: {
                            specializedRoles: res.data.specializedRoles
                        }
                    });

                    // message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const updateSpecializedRoles = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/updateSpecializedRoles`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getPracticeAreas = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getPracticeAreas`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_PRACTICE_AREAS,
                        payload: {
                            practiceAreas: res.data.practiceAreas
                        }
                    });

                    // message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const updatePracticeAreas = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/updatePracticeAreas`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getHospitalServices = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getHospitalServices`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_HOSPITAL_SERVICES,
                        payload: {
                            hospitalServices: res.data.hospitalServices
                        }
                    });

                    // message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const createHospitalService = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/createHospitalService`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const deleteHospitalService = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/deleteHospitalService`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};


export const getDocumentTypes = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/getDocumentTypes`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_DOCUMENT_TYPES,
                        payload: {
                            documentTypes: res.data.documentTypes
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const createDocumentType = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/createDocumentType`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const deleteDocumentType = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/hospitals/deleteDocumentType`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};


export const sendMailFromCustomer = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/general/sendMailFromCustomer`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getCustomerMails = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/general/getCustomerMails`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    let data = [];
                    if (res.data && res.data.mailDataList && res.data.type) {
                        if (res.data.type == 'Support') {
                            data.support = res.data.mailDataList;
                            if (res.data.mailDataListCount) {
                                data.supportCount = res.data.mailDataListCount;
                            }
                        }
                        if (res.data.type == 'Feedback') {
                            data.feedbacks = res.data.mailDataList;
                            if (res.data.mailDataListCount) {
                                data.feedbacksCount = res.data.mailDataListCount;
                            }
                        }
                        if (res.data.type == 'Sales') {
                            data.sales = res.data.mailDataList;
                            if (res.data.mailDataListCount) {
                                data.salesCount = res.data.mailDataListCount;
                            }
                        }
                        if (res.data.type == 'General') {
                            data.general = res.data.mailDataList;
                            if (res.data.mailDataListCount) {
                                data.generalCount = res.data.mailDataListCount;
                            }
                        }
                        if (res.data.type == 'Complaints') {
                            data.complaints = res.data.mailDataList;
                            if (res.data.mailDataListCount) {
                                data.complaintsCount = res.data.mailDataListCount;
                            }
                        }
                    }

                    dispatch({
                        type: Types.GET_CUSTOMER_MAILS,
                        payload: {
                            mailDataList: data
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const likeOrUnlikeUserType = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/general/likeOrUnlikeUserType`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    message.success(res.data.message);
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const getSearchSuggestions = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/general/getSuggestions`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: Types.GET_SEARCH_SUGGESTIONS,
                        payload: {
                            hospitals: res.data.hospitals,
                            doctors: res.data.doctors,
                            practiceAreas: res.data.practiceAreas
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    if (error.response.data.message) {
                        message.error(error.response.data.message);
                    }
                    return resolve(false);
                }
            });
    });
};

export const updateMedicalDocsModal =
    ({ type, payload }) =>
    (dispatch) => {
        dispatch({
            type: type,
            payload
        });
    };
