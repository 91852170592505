import React from 'react';
import { Tag } from 'antd';
import parse from 'html-react-parser';
import NotificationsHeader from './NotificationsHeader';
import Table from '../Table';
import PageHeader from '../PageHeader';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import useNotifications from '../../../../hooks/useNotifications';
import useWindowSize from '@/hooks/useWindowSize'

const Notifications = (props) => {
    const { notifications, notificationsCount, limit, setLimit, page, setPage } =
        useNotifications();
    const {width} = useWindowSize()
    const columns = [
        {
            title: <span className='dragHandler'>Date</span>,
            dataIndex: 'createdDate',
            width: 140,
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 140 }
                };
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {new Date(date).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </div>
                );
            }
        },
        {
            title: <span className='dragHandler'>Time</span>,
            dataIndex: 'createdDate',
            width: 100,
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {new Date(date).toLocaleTimeString('en-US', {
                            hour: 'numeric',
                            minute: 'numeric'
                        })}
                    </div>
                );
            }
        },

        {
            title: <span className='dragHandler'>Description</span>,
            width: width > 1000 ? 1000 : window > 756 ? 500 : 300,
            dataIndex: 'description',
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            },
            render: (description, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <div>{parse(description)}</div>;
            }
        },
        {
            title: <span className='dragHandler'>Category</span>,
            dataIndex: 'category',
            width: 150,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.category) return -1; // Handle empty values
                if (!b.category) return 1; // Handle empty values
                return a.category.localeCompare(b.category);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            },
            render: (tag, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <Tag color='blue'>{tag}</Tag>;
            }
        },
        {
            title: <span className='dragHandler'>Action</span>,
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap' }
                };
            }
        }
    ];

    const numberOfBlankRows = limit - notifications.length;
    let dataSource = [
        ...notifications,
        ...Array.from({ length: numberOfBlankRows }).map(() => ({}))
    ];

    return (
        <div>
            <PageHeader heading={'Notifications'} />
            <NotificationsHeader />
            <Table
                tableName={'Notifications'}
                columns={columns}
                data={dataSource}
                pagination={{
                    current: page,
                    total: notificationsCount ? notificationsCount : 0,
                    onChange: (val) => setPage(val),
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20],
                    showSizeChanger: true,
                    responsive: true,
                    onShowSizeChange: (size, newSize) => setLimit(newSize)
                }}
            />
        </div>
    );
};

export default Notifications;
