import React, { useEffect, useState } from 'react';
import './sidebar.css';
import { connect } from 'react-redux';
import { Select } from 'antd';
import GoogleMapReact from 'google-map-react';
import placeholderCover from '@/assets/img/placeholderCover.png';

const Marker = ({ text }) => (
    <div
        className='marker'
        style={{
            color: 'white',
            padding: '15px 10px',
            display: 'inline-flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '100%',
            transform: 'translate(-50%, -50%)'
        }}
    >
        <i
            className='fa fa-map-marker '
            style={{
                fontSize: '35px',
                color: 'tomato',
                position: 'relative',
                textShadow: '2px 2px #ff0000'
            }}
        ></i>
    </div>
);

const ListSidebar = (props) => {
    const [schedule, setSchedule] = useState(null);

    const {
        marketingInfo,
        availabilities,
        member,
        memberType,
        selectedAddressIndex,
        setSelectedAddressIndex,
        setSelectedAddress,
        selectedAddress
    } = props;
    const [mapObj, setMapObj] = useState({
        center: {
            lat: 59.95,
            lng: 30.33
        },
        zoom: 15
    });
    const statsStyle = {};
    const bottomStyle = {};

    if (marketingInfo && marketingInfo.statsContent) {
        if (marketingInfo.statsContent.bgColor) {
            statsStyle.backgroundColor = marketingInfo.statsContent.bgColor;
        }
        if (marketingInfo.statsContent.color) {
            statsStyle.color = marketingInfo.statsContent.color;
        }
        if (marketingInfo.statsContent.fontSize) {
            statsStyle.fontSize = `${marketingInfo.statsContent.fontSize}px`;
        }
    }

    if (marketingInfo && marketingInfo.bottomBarContent) {
        if (marketingInfo.bottomBarContent.bgColor) {
            bottomStyle.background = marketingInfo.bottomBarContent.bgColor;
        }
        if (marketingInfo.bottomBarContent.color) {
            bottomStyle.color = marketingInfo.bottomBarContent.color;
        }
        if (marketingInfo.bottomBarContent.fontSize) {
            bottomStyle.fontSize = `${marketingInfo.bottomBarContent.fontSize}px`;
        }
    }

    const lines = ['header', 'grabber', 'line1', 'line2', 'line3', 'line4', 'line5'];

    const renderLines = (content) => {
        return lines.map((line) => {
            if (content[line]) {
                return (
                    <p
                        style={{
                            ...(content?.[line].style || {}),
                            padding: '10px 0px',
                            textAlign: 'center'
                        }}
                    >
                        {content?.[line]?.text}
                    </p>
                );
            }
        });
    };

    useEffect(() => {
        if (
            member.hospitalBranches &&
            member.hospitalBranches[selectedAddressIndex] &&
            member.hospitalBranches[selectedAddressIndex]._id
        ) {
            setSchedule(
                availabilities &&
                    availabilities.filter(
                        (item) => item.branch == member.hospitalBranches[selectedAddressIndex]._id
                    )[0]
            );
        } else if (availabilities?.length > 0) {
            setSchedule(availabilities[0]);
        }
    }, [member.hospitalBranches && member.hospitalBranches[selectedAddressIndex], availabilities]);

    let selectedBranchAvail =
        member?.hospitalBranches?.length > 0
            ? member.hospitalBranches[selectedAddressIndex] &&
              member.hospitalBranches[selectedAddressIndex].availability &&
              member.hospitalBranches[selectedAddressIndex].availability
            : member.availability?.[0]
            ? member.availability[0]
            : null;

    let selectedBranch =
        member &&
        member.hospitalBranches &&
        member.hospitalBranches.length > 0 &&
        member.hospitalBranches[selectedAddressIndex];

    return (
        <>
            <div className='hospital-view-sidebar'>
                {marketingInfo && marketingInfo.displayStats && (
                    <div className='hospital-view-box3 bar'>
                        {marketingInfo.statsContentType != 'image' &&
                            marketingInfo.statsContentType != 'Image' && (
                                <div className='hospital-view-box1-header'>
                                    <p>
                                        {marketingInfo.statsContent?.title?.text || 'member Stats'}
                                    </p>
                                </div>
                            )}

                        <div className='hospital-view-box1-body'>
                            <div
                                className='hospital-view-box'
                                style={
                                    (marketingInfo.statsContentType != 'image' ||
                                        marketingInfo.statsContentType != 'Image') &&
                                    marketingInfo.statsContentType.bgColor && {
                                        backgroundColor: marketingInfo.statsContentType.bgColor
                                    }
                                }
                            >
                                {marketingInfo.statsContentType == 'image' ||
                                marketingInfo.statsContentType == 'Image' ? (
                                    <img
                                        src={
                                            marketingInfo.statsContent &&
                                            marketingInfo.statsContent.image
                                                ? marketingInfo.statsContent.image
                                                : placeholderCover
                                        }
                                    />
                                ) : (
                                    <div style={{ ...statsStyle, padding: '10px 20px' }}>
                                        {renderLines(marketingInfo.statsContent)}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}

                {member?.hospitalBranches?.length > 0 && (
                    <div className='hospital-view-box1'>
                        <div className='hospital-view-box1-header'>
                            <p>
                                {' '}
                                {memberType === 'hospital' || memberType === 'doctor'
                                    ? 'Hospital'
                                    : 'Agency'}{' '}
                                Locations:
                            </p>
                        </div>
                        <div className='hospital-view-box1-body'>
                            <div className='hospital-view-address'>
                                <p>Select Location:</p>
                                <Select
                                    style={{ width: '100%' }}
                                    placeholder='Select Address'
                                    value={selectedAddress}
                                    onChange={(val, obj) => {
                                        console.log(val, obj);
                                        setSelectedAddressIndex(obj.key);
                                        setSelectedAddress(val);
                                    }}
                                >
                                    {member.hospitalBranches &&
                                        member.hospitalBranches.length !== 0 &&
                                        member.hospitalBranches.map((it, ind) => (
                                            <Option key={ind} value={it._id}>
                                                {it.location &&
                                                    it.location.branchAddress &&
                                                    it.location.branchAddress}
                                                ,
                                                {it.location &&
                                                    it.location.branchCity &&
                                                    it.location.branchCity}
                                                ,
                                                {it.location &&
                                                    it.location.branchCountry &&
                                                    it.location.branchCountry}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                        </div>
                    </div>
                )}
                <div className='hospital-view-box1'>
                    <div className='hospital-view-box1-header'>
                        <p>
                            {memberType.slice(0, 1).toUpperCase() + memberType.slice(1)} Hours of
                            Operation
                        </p>
                    </div>
                    <div className='hospital-view-box1-body'>
                        {selectedBranchAvail ? (
                            <>
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        gap: 10,
                                        padding: '5px 0px'
                                    }}
                                >
                                    <div>Timezone:</div> <div>{selectedBranchAvail.timezone}</div>
                                </div>

                                <div className='hospital-view-schedule'>
                                    {selectedBranchAvail.sunday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Sunday:</div>{' '}
                                            <div>
                                                {selectedBranchAvail.sunday.startTime}-
                                                {selectedBranchAvail.sunday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {selectedBranchAvail.monday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Monday:</div>{' '}
                                            <div>
                                                {selectedBranchAvail.monday.startTime}-
                                                {selectedBranchAvail.monday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {selectedBranchAvail.tuesday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Tuesday:</div>{' '}
                                            <div>
                                                {selectedBranchAvail.tuesday.startTime}-
                                                {selectedBranchAvail.tuesday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {selectedBranchAvail.wednesday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Wednesday:</div>{' '}
                                            <div>
                                                {selectedBranchAvail.wednesday.startTime}-
                                                {selectedBranchAvail.wednesday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {selectedBranchAvail.thursday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Thursday:</div>{' '}
                                            <div>
                                                {selectedBranchAvail.thursday.startTime}-
                                                {selectedBranchAvail.thursday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {selectedBranchAvail.friday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Friday:</div>{' '}
                                            <div>
                                                {selectedBranchAvail.friday.startTime}-
                                                {selectedBranchAvail.friday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {selectedBranchAvail.saturday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Saturday:</div>{' '}
                                            <div>
                                                {selectedBranchAvail.saturday.startTime}-
                                                {selectedBranchAvail.saturday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </>
                        ) : schedule ? (
                            <>
                                <div className='hospital-view-timezone'>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            gap: 10,
                                            padding: '5px 0px'
                                        }}
                                    >
                                        <div>Timezone:</div> <div>{schedule.timezone}</div>
                                    </div>
                                </div>
                                <div className='hospital-view-schedule'>
                                    {schedule.sunday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Sunday:</div>{' '}
                                            <div>
                                                {schedule.sunday.startTime}-
                                                {schedule.sunday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {schedule.monday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Monday:</div>{' '}
                                            <div>
                                                {schedule.monday.startTime}-
                                                {schedule.monday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {schedule.tuesday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Tuesday:</div>{' '}
                                            <div>
                                                {schedule.tuesday.startTime}-
                                                {schedule.tuesday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {schedule.wednesday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Wednesday:</div>{' '}
                                            <div>
                                                {schedule.wednesday.startTime}-
                                                {schedule.wednesday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {schedule.thursday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Thursday:</div>{' '}
                                            <div>
                                                {schedule.thursday.startTime}-
                                                {schedule.thursday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {schedule.friday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Friday:</div>{' '}
                                            <div>
                                                {schedule.friday.startTime}-
                                                {schedule.friday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                    {schedule.saturday ? (
                                        <div
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                gap: 10,
                                                padding: '5px 0px'
                                            }}
                                        >
                                            <div>Saturday:</div>{' '}
                                            <div>
                                                {schedule.saturday.startTime}-
                                                {schedule.saturday.endTime}
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                            </>
                        ) : (
                            <div className='hospital-view-timezone'>
                                <p>Schedule not fixed yet</p>
                            </div>
                        )}
                    </div>
                </div>
                {selectedBranch && (
                    <div className='hospital-view-box2'>
                        <div className='hospital-view-box1-header'>
                            <p>
                                {memberType === 'hospital' || memberType === 'doctor'
                                    ? 'Hospital'
                                    : 'Agency'}{' '}
                                Direction
                            </p>
                        </div>
                        <div className='hospital-view-box1-body'>
                            <>
                                <p style={{ marginBottom: 10 }}>
                                    Location:{' '}
                                    {selectedBranch && selectedBranch.location && (
                                        <>
                                            {selectedBranch?.location?.branchAddress} {' , '}
                                            {selectedBranch?.location?.branchCountry}
                                        </>
                                    )}{' '}
                                </p>
                                <GoogleMapReact
                                    style={{
                                        display: 'flex',
                                        height: 270,
                                        width: '100%',
                                        position: 'relative',
                                        left: 0,
                                        right: 0,
                                        margin: '0px 0px 10px 0px'
                                    }}
                                    bootstrapURLKeys={{
                                        key: 'AIzaSyB6CeCRWx766zPt6EeOpxeBgjJXDqcCwpA'
                                    }}
                                    // defaultCenter={center}
                                    defaultZoom={mapObj.zoom}
                                    center={
                                        selectedBranch &&
                                        selectedBranch.location &&
                                        selectedBranch.location.branchCoords
                                    }
                                    onClick={(e) => {
                                        // setMarkerCenter({
                                        //   lat: e.lat,
                                        //   lng: e.lng
                                        // })
                                    }}
                                    yesIWantToUseGoogleMapApiInternals
                                    onGoogleApiLoaded={({ map, maps }) =>
                                        // handleApiLoaded(map, maps)
                                        (map, maps) =>
                                            map.panTo(
                                                selectedBranch &&
                                                    selectedBranch.location &&
                                                    selectedBranch.location.branchCoords &&
                                                    selectedBranch.location.branchCoords
                                            )
                                        }
                                >
                                    <Marker
                                        lat={
                                            selectedBranch &&
                                            selectedBranch.location &&
                                            selectedBranch.location.branchCoords &&
                                            selectedBranch.location.branchCoords.lat &&
                                            selectedBranch.location.branchCoords.lat
                                        }
                                        lng={
                                            selectedBranch &&
                                            selectedBranch.location &&
                                            selectedBranch.location.branchCoords &&
                                            selectedBranch.location.branchCoords.lng &&
                                            selectedBranch.location.branchCoords.lng
                                        }
                                        text='My Marker'
                                    />
                                </GoogleMapReact>
                            </>
                        </div>
                    </div>
                )}
                {marketingInfo?.displayBottomBar && (
                    <div className='hospital-view-box3 bar'>
                        {marketingInfo.bottomBarContentType !== 'image' &&
                            marketingInfo.bottomBarContentType !== 'Image' && (
                                <div className='hospital-view-box1-header'>
                                    <p className='' style={{ color: 'black' }}>
                                        {marketingInfo.bottomBarContent.title?.text || 'Bottom Bar'}
                                    </p>
                                </div>
                            )}

                        <div className='hospital-view-box1-body'>
                            <div className='hospital-view-box' style={bottomStyle}>
                                {marketingInfo.bottomBarContentType == 'image' ||
                                marketingInfo.bottomBarContentType == 'Image' ? (
                                    <img
                                        src={
                                            marketingInfo.bottomBarContent &&
                                            marketingInfo.bottomBarContent.image
                                                ? marketingInfo.bottomBarContent.image
                                                : placeholderCover
                                        }
                                    />
                                ) : (
                                    <div style={{ ...bottomStyle, padding: '10px 0px' }}>
                                        {renderLines(marketingInfo.bottomBarContent)}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    availabilities: state?.availability?.viewAvailabilities
});

export default connect(mapStateToProps)(ListSidebar);
