import { Layout, Menu, message, Tooltip } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import '@/assets/css/newProfile.css';
import * as userActions from '@/actions/userActions';
import { Link, Switch } from 'react-router-dom';
import { Route } from 'react-router-dom';
import '../customModal.css';
import { useDispatch, useSelector } from 'react-redux';
import useWindowSize from '@/hooks/useWindowSize';
import Logo from '@/assets/images/logo.png';
import Header from '@/components/NewProfile/Shared/Layout/Header';
import { LogoutOutlined } from '@ant-design/icons';
import { useLocation, useHistory } from 'react-router-dom';

const { Sider, Content } = Layout;

const LayoutComponent = ({ routes, minFields }) => {
    const { userType } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const history = useHistory();
    const [collapsed, setCollapsed] = useState(false);
    const [activeMenu, setActiveMenu] = useState('');
    const { width } = useWindowSize();

    useEffect(() => {
        if (typeof window != 'undefined') {
            window.scrollTo(0, 0);
        }
        setActiveMenu(pathname);
    }, [pathname]);

    const logout = (e) => {
        e.preventDefault();
        dispatch(userActions.logout(history));
        message.success(<p> Logged out Successfully!!" &nbsp; &#9749;</p>);
    };

    const invisibleRoutes = ['management', 'locations', 'doctors'];
    return (
        <Layout className='newProfile' style={{ height: 'auto', position: 'relative' }}>
            <Sider
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 10
                }}
                width={250}
                trigger={null}
                collapsible
                collapsed={collapsed}
                collapsedWidth={width > 768 ? 80 : 0}
            >
                {width <= 756 && !collapsed && (
                    <div
                        onClick={() => setCollapsed(true)}
                        style={{ backgroundColor: 'rgba(0,0,0,0.1)', position: 'fixed', inset: 0 }}
                    />
                )}
                <div
                    style={{
                        height: 65,
                        padding: 15,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    {!collapsed && (
                        <Link to='/'>
                            <img src={Logo} alt='logo' style={{ width: '8rem' }} />
                        </Link>
                    )}
                </div>

                <Menu
                    className='sidebar-menu'
                    theme='dark'
                    mode='inline'
                    selectedKeys={[activeMenu]}
                    items={[
                        ...routes.map((route) => {
                            if (invisibleRoutes.includes(route.name.toLowerCase())) {
                                return null;
                            }

                            if (route.path.includes('notifications') && userType !== "PATIENT") {
                                return null;
                            }
                            return {
                                key: route.path,
                                icon: route.icon,
                                label: (
                                    <Link
                                        to={route.path}
                                        onClick={() => setActiveMenu(route.path)}
                                        data-tooltip={route.name}
                                    >
                                        <li
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center'
                                            }}
                                            className={
                                                activeMenu.includes(route.path) ? 'active' : ''
                                            }
                                        >
                                            <span className='link-title'>{route.name}</span>

                                            {!minFields && route.name.includes('Account') && (
                                                <Tooltip title='Required'>
                                                    <InfoCircleOutlined style={{ color: 'red' }} />
                                                </Tooltip>
                                            )}
                                        </li>
                                    </Link>
                                )
                            };
                        }),
                        {
                            label: (
                                <Link to='#' data-tooltip='Logout' onClick={logout}>
                                    <li>
                                        <span className='link-title'>Logout</span>
                                    </li>
                                </Link>
                            ),
                            key: 'logout',
                            icon: <LogoutOutlined />
                        }
                    ]}
                />
            </Sider>

            <Header collapsed={collapsed} setCollapsed={setCollapsed} />
            <Layout
                className={`site-layout ${collapsed ? 'sidebar-collapsed' : ''}`}
                style={{
                    marginLeft: width > 768 ? (collapsed ? 80 : 250) : 0,
                    minHeight: '100vh',
                    overflow: 'auto',
                    transition: 'all 0.25s',
                    position: 'relative'
                }}
            >
                <Content
                    className='main'
                    style={{
                        minHeight: 280,
                        padding: 24,
                        paddingTop: 90,
                        marginLeft: 0
                    }}
                >
                    {routes.map((route, index) => (
                        <>
                            <Route
                                key={index}
                                path={route.path}
                                exact={route.exact}
                                component={route.component}
                            />
                            {route.children && (
                                <Switch>
                                    {route.children.map((childRoute, childIndex) => (
                                        <Route
                                            key={childIndex}
                                            path={childRoute.path}
                                            exact={childRoute.exact}
                                            component={childRoute.component}
                                        />
                                    ))}
                                </Switch>
                            )}
                        </>
                    ))}
                </Content>
            </Layout>
        </Layout>
    );
};

export default LayoutComponent;
