import React, { useEffect, useState } from 'react';
import '@/assets/css/singleBlog.css';
import Civil from '@/assets/img/civil.jpeg';
import Criminal from '@/assets/img/criminal.jpeg';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import * as blogActions from '@/actions/blogActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import parse from 'html-react-parser';
import { message } from 'antd';
import ConfirmModal from '../modals/ConfirmModal';
import Axios from 'axios';
import { FacebookShareButton, TwitterShareButton, LinkedinShareButton } from 'react-share';

import { Select } from 'antd';

const { Option } = Select;

const { REACT_APP_API_URL } = process.env;

const SingleBlog = (props) => {
    const [singlePost, setSinglePost] = useState(null);
    const [date, setDate] = useState(null);
    const [liked, setLiked] = useState(false);
    const [comment, setComment] = useState('');
    const [comments, setComments] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [commentToBeDeleted, setCommentToBeDeleted] = useState('');
    const [facebookShareCount, setFacebookShareCount] = useState();
    const [sort, setSort] = useState(null);
    const [slideIndex, setSlideIndex] = useState(1);
    const { postId } = useParams();

    const { auth, blogs, blogActions } = props;

    useEffect(() => {
        let like = false;
        if (singlePost && singlePost.likes) {
            for (let i = 0; i < singlePost.likes.length; i++) {
                console.log(singlePost.likes[i].user, auth?.user._id);
                if (singlePost.likes[i].user == auth?.user._id) {
                    like = true;
                }
            }
            setLiked(like);
        }
    }, [singlePost && singlePost.likes]);

    const fetchShareCount = async (id) => {
        if (id) {
            let url = 'https://xukini.herokuapp.com/blogs/' + id;
            Axios.get(
                `https://graph.facebook.com/?id=${REACT_APP_API_URL}/blogs/single` +
                    id +
                    '&fields=engagement&access_token=645576173040339|0aed5bb353c9d4a80d55b0a1feac4cb5'
            )
                .then((data) => {
                    if (data.data && data.data.engagement && data.data.engagement.share_count) {
                        setFacebookShareCount(data.data.engagement.share_count);
                    }
                })
                .catch((err) => console.log(err));
        }
    };
    useEffect(() => {
        if (singlePost && singlePost._id) {
            fetchShareCount();
        }
    }, [singlePost]);
    const modalClose = () => {
        setModalOpen(false);
        setCommentToBeDeleted('');
    };
    const postComment = async () => {
        if (comment && singlePost._id) {
            let obj = {
                author: auth?.user._id,
                blog: singlePost._id,
                comment: comment
            };
            let postComment = await blogActions.postComment({ obj, ...props });
            if (postComment) {
                console.log(postComment);
                setComment('');
                let obj = {
                    postId: singlePost._id
                };
                await blogActions.getComments({ obj, ...props });
            }
        } else {
            message.error('write your comment!');
        }
    };

    useEffect(() => {
        if (blogs) {
            if (blogs.singlePost && postId == blogs.singlePost._id) setSinglePost(blogs.singlePost);
            if (blogs.singlePost && blogs.singlePost._id) fetchShareCount(postId);
        }
        if (blogs.comments) {
            setComments(blogs.comments);
        }
    }, [blogs]);

    useEffect(() => {
        blogActions.getSinglePost({ postId, ...props }, props.history);

        let obj = {
            postId
        };
        blogActions.getComments({ obj, ...props });
    }, []);

    const deleteComment = async (commentId) => {
        let deleteComment = await blogActions.deleteComment({
            commentId,
            ...props
        });
        if (deleteComment) {
            setModalOpen(false);
            setCommentToBeDeleted('');
            let obj = {
                postId: singlePost._id
            };
            await blogActions.getComments({ obj, ...props });
        }
    };

    const likePost = async (postId) => {
        let likePost = await blogActions.likePost({
            postId,
            ...props
        });
        if (likePost) {
            blogActions.getSinglePost({ postId, ...props });
        }
    };

    const unlikePost = async (postId) => {
        let unlikePost = await blogActions.unlikePost({
            postId,
            ...props
        });
        if (unlikePost) {
            blogActions.getSinglePost({ postId, ...props });
        }
    };

    useEffect(() => {
        if (singlePost) {
            setDate(moment(singlePost.createdAt).format('MMMM D YYYY').split(','));
        }
    }, [singlePost]);

    const handleSort = (value) => {
        setSort(value);
    };
    console.log(sort);
    const handleSorting = (a, b) => {
        if (sort && sort === 'Newest') {
            return new Date(b.createdAt) - new Date(a.createdAt);
        } else {
            return new Date(a.createdAt) - new Date(b.createdAt);
        }
    };
    const handleNavigation = (type, index) => {
        if (type === 'next') {
            if (slideIndex !== blogs.posts.length) {
                setSlideIndex(slideIndex + 1);
            } else if (slideIndex === blogs.posts.length) {
                setSlideIndex(1);
            }
        }
        if (type === 'prev') {
            if (slideIndex !== 1) {
                setSlideIndex(slideIndex - 1);
            } else if (slideIndex === 1) {
                setSlideIndex(blogs.posts.length);
            }
        }
    };

    return (
        <>
            {console.log('hi', singlePost)}
            {singlePost && (
                <div className='singleBlog'>
                    {console.log('hi')}
                    <div className='blog-main-content'>
                        <div className='singleBlog-tags'>
                            {singlePost
                                ? singlePost.category.map((item, index) => {
                                      if (index === 0 || index === singlePost.category.length - 1) {
                                          return <span key={index}>{item}</span>;
                                      } else {
                                          return (
                                              <>
                                                  {' '}
                                                  | <span key={index}> {item}</span>
                                              </>
                                          );
                                      }
                                  })
                                : ''}
                        </div>
                        <h2>{singlePost ? singlePost.title : ''}</h2>

                        <div className='shareSocial'>
                            <span>Share post on </span>
                            <FacebookShareButton
                                url={`${REACT_APP_API_URL}/blogs/${singlePost.id}`}
                            >
                                <i className='fa fa-facebook' />
                            </FacebookShareButton>
                            <TwitterShareButton url={`${REACT_APP_API_URL}/blogs/${singlePost.id}`}>
                                <i className='fa fa-twitter' />
                            </TwitterShareButton>
                            <LinkedinShareButton
                                url={`https://Medasq-frontend-inevex.herokuapp.com/blogs/${singlePost.id}`}
                            >
                                <i className='fa fa-linkedin' />
                            </LinkedinShareButton>
                        </div>

                        <div className='featured-image'>
                            <div className='infoDate'>
                                <p> Date: {singlePost ? date : ''}</p>
                            </div>
                            <div className='shortInfo' style={{ textAlign: 'center' }}>
                                <p>
                                    {' '}
                                    {singlePost.author.firstname} {singlePost.author.lastname}{' '}
                                </p>
                                <p>{singlePost.author.firmname}</p>
                            </div>
                            <div className='profilePicture'>
                                <img
                                    src={
                                        singlePost.author.profileImage
                                            ? singlePost.author.profileImage
                                            : Civil
                                    }
                                />
                            </div>
                            <img
                                src={singlePost ? singlePost.coverImage : Criminal}
                                alt='building.jpg'
                            />
                        </div>

                        <div className='blog-and-categories'>
                            <div className='blog-body-and-comments'>
                                <div className='blog-body ck-content'>
                                    {singlePost ? parse(singlePost.body) : ''}
                                </div>

                                <hr />

                                <div className='blogComments'>
                                    <div className='commentTabs'>
                                        <p className='commentTab'>Comments</p>
                                        {auth.isAuthenticated && (
                                            <p>
                                                <i
                                                    className={`fa fa-thumbs-up  ${
                                                        liked ? '' : 'stroke-transparent'
                                                    } `}
                                                    onClick={() => {
                                                        liked
                                                            ? unlikePost(singlePost._id)
                                                            : likePost(singlePost._id);
                                                    }}
                                                />

                                                <span>
                                                    {singlePost && singlePost.likes
                                                        ? singlePost.likes.length
                                                        : 0}
                                                </span>
                                            </p>
                                        )}
                                    </div>

                                    <div className='shareAndFilter'>
                                        <div className='commentSecShare'>
                                            <div>
                                                <TwitterShareButton
                                                    url={`https://JurisNexav2.herokuapp.com/hospital/blogs/${singlePost.id}`}
                                                >
                                                    <i className='fa fa-twitter' /> Tweet
                                                </TwitterShareButton>
                                            </div>
                                            <div>
                                                <FacebookShareButton
                                                    url={`https://JurisNexav2.herokuapp.com/hospital/blogs/${singlePost.id}`}
                                                >
                                                    <i className='fa fa-facebook' /> Share
                                                </FacebookShareButton>
                                            </div>
                                            <div>
                                                <LinkedinShareButton
                                                    url={`https://JurisNexav2.herokuapp.com/hospital/blogs/${singlePost.id}`}
                                                >
                                                    <i className='fa fa-linkedin' /> Share
                                                </LinkedinShareButton>
                                            </div>
                                        </div>

                                        <div className='filterDrop'>
                                            <Select
                                                defaultValue='Sort By Oldest'
                                                style={{ width: 160 }}
                                                bordered={false}
                                                onChange={handleSort}
                                            >
                                                <Option value='Oldest'>Sort By Oldest</Option>
                                                <Option value='Newest'>Sort By Newest</Option>
                                            </Select>
                                        </div>
                                    </div>

                                    <ul className='commentsList'>
                                        {comments &&
                                            comments.sort(handleSorting).map((item, index) => (
                                                <li key={index}>
                                                    <div className='avatar'>
                                                        {item?.author?.profileImage && (
                                                            <img
                                                                src={item?.author?.profileImage}
                                                                style={{
                                                                    height: '100%',
                                                                    width: '100%',
                                                                    objectFit: 'cover'
                                                                }}
                                                            />
                                                        )}
                                                    </div>{' '}
                                                    <div className='commenterInfo'>
                                                        <p>{item.author.firstName}</p>{' '}
                                                        <span>{item.comment}</span>
                                                    </div>
                                                    <div>
                                                        <p>
                                                            {moment().diff(
                                                                item.createdAt,
                                                                'days'
                                                            ) >= 1
                                                                ? `${moment().diff(
                                                                      item.createdAt,
                                                                      'days'
                                                                  )} days ago`
                                                                : moment().diff(
                                                                      item.createdAt,
                                                                      'hours'
                                                                  ) >= 1
                                                                ? `${moment().diff(
                                                                      item.createdAt,
                                                                      'hours'
                                                                  )} hours ago`
                                                                : moment().diff(
                                                                      item.createdAt,
                                                                      'minutes'
                                                                  ) > 1
                                                                ? `${moment().diff(
                                                                      item.createdAt,
                                                                      'minutes'
                                                                  )} minutes ago`
                                                                : `just now`}
                                                        </p>
                                                    </div>
                                                    {item.author === props.profile._id && (
                                                        <div
                                                            className='deleteComment'
                                                            onClick={() => {
                                                                setModalOpen(true);
                                                                setCommentToBeDeleted(item._id);
                                                            }}
                                                        >
                                                            <i className='fa fa-close' />
                                                        </div>
                                                    )}
                                                </li>
                                            ))}
                                    </ul>
                                    <div className='inputContainer'>
                                        {auth?.user.profileImage && (
                                            <img src={auth?.user.profileImage} alt='user_image' />
                                        )}
                                        <input
                                            value={comment}
                                            onChange={(e) => setComment(e.target.value)}
                                            placeholder='Join the discussion'
                                            onKeyDown={(e) => {
                                                e.key === 'Enter' && postComment();
                                            }}
                                        />
                                    </div>
                                    <button className='postComment' onClick={postComment}>
                                        Submit
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='navigateBlog'>
                        <div className='navigateBlogBtns'>
                            <button
                                data-tip='Previous Post'
                                onClick={() => handleNavigation('prev')}
                            >
                                <i className='fa fa-arrow-left' />
                            </button>
                            <button data-tip='Next Post' onClick={() => handleNavigation('next')}>
                                <i className='fa fa-arrow-right' />
                            </button>
                        </div>
                        {blogs &&
                            blogs.posts &&
                            blogs.posts.length > 1 &&
                            blogs.posts.map((item, index) => {
                                console.log(item.id);

                                return (
                                    <div
                                        className={
                                            slideIndex === index + 1
                                                ? 'heightLessContainer anime'
                                                : 'heightLessContainer'
                                        }
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            props.history.push(
                                                `/hospital/blogs/${blogs.posts[index].id}`
                                            );
                                        }}
                                    >
                                        <div className='nextBlogImageContainer'>
                                            <img src={item.coverImage} alt='building.jpg' />
                                        </div>
                                        <div className='nextBlogTitleContainer'>
                                            <p>{item.title}</p>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>

                    {modalOpen && (
                        <ConfirmModal
                            {...props}
                            acceptMethod={() => deleteComment(commentToBeDeleted)}
                            onClose={modalClose}
                            headerText='Delete Comment'
                            bodyText='Are you sure you want to delete this Comment?'
                        />
                    )}
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth?.userProfile,
    blogs: state.blog
    // singlePost: state.blog && state.blog.singlePost
});

const mapDispatchToProps = (dispatch) => ({
    blogActions: bindActionCreators(blogActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(SingleBlog);
