import React, { memo, useState } from 'react';
import * as userActions from '@/actions/userActions';
import * as documentActions from '@/actions/documentActions';
import * as branchActions from '@/actions/branchActions';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Button } from 'antd';

const LocationDocs = (props) => {
    const [multipleFiles, setMultipleFiles] = useState([]);
    const [loading, setLoading] = useState(false);
    const [uploadError, setUploadError] = useState('');

    const handleFileChange = (e) => {
        let files = [];
        setMultipleFiles(Array.from(e.target.files));
        setUploadError('');
    };

    const handleUpload = async (type) => {
        console.log(type);
        if (multipleFiles.length == 0) {
            setUploadError('No file Found');
            return;
        }
        setLoading(true);

        let obj = new FormData();
        console.log(props.verif, type)
        obj.append('credential', props.verif);
        obj.append('credentialId', props.id);
        obj.append('credentialType', type);
        obj.append('userType', props?.auth?.user?.userType);
        obj.append('userId', props?.auth?.user?._id);
        obj.append('image', multipleFiles[0]);
        if (props.branchId && !props.pharmacist) {
            obj.append('branchId', props.branchId);
            obj.append('locationDocument', true);
        } else if (props.branchId && props.pharmacist) {
            obj.append('branchId', props.branchId);
            obj.append('pharmacist', true);
        }
        let document = await props.documentActions.saveCredentialDocument(
            { ...props, obj },
            props.history
        );
        if (document) {
            setLoading(false);
            if (props.pharmacist || props.branchId) {
                console.log('got in');
                let obj = {
                    userId: props.auth.userId,
                    populateAvailability: true,
                    userType: props.auth.userType
                };
                await props.branchActions.getBranches({ ...props, obj }, props.history);
            }
            let profileDate = {
                userId: props?.auth?.user?._id,
                userType: props?.auth?.user?.userType
            };
            await props.actions.getProfile(profileDate, props.history);
        } else {
            setLoading(false);
        }
    };
    return (
        <div
            className={
                props.type === 'REQUIRED' || props.type === 'REJECTED'
                    ? `order-section-file ${props.css}`
                    : `order-section-second ${props.css}`
            }
        >
            <div>
                <div>
                    <p>
                        Proof of {props.proof} {props.branchAddress} For : {props.hospitalName}{' '}
                    </p>
                </div>

                {props.type === 'REQUIRED' || props.type === 'REJECTED' ? (
                    <div className='order-loc-file-input'>
                        <input
                            type='file'
                            accept='.doc,.docx,.pdf'
                            multiple
                            onChange={handleFileChange}
                        />
                    </div>
                ) : null}
            </div>
            {props.type === 'REQUIRED' || props.type === 'REJECTED' ? (
                <div style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
                    <Button
                        type='primary'
                        loading={loading}
                        onClick={() => handleUpload(props.type)}
                    >
                        <p>Upload</p>
                    </Button>

                    {uploadError !== '' ? <p>{uploadError}</p> : null}
                </div>
            ) : null}
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.lawfirmUserProfile,
    blogs: state.blog
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(LocationDocs));
