import { Divider, Tag } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';
import { DateTime, Duration } from 'luxon';

const CurrentSubscription = () => {
    const { stripeUpcomingInvoice, userProfile, productTypes, stripeInvoices } = useSelector(
        (state) => state.auth
    );
    const {
        userLimit,
        featuredUserLimit,
        featured,
        currentSubscribedPackage,
        currentSubscribedBillingCycle
    } = userProfile;

    const upcomingInvoiceDate = DateTime.fromMillis(
        stripeUpcomingInvoice.next_payment_attempt * 1000
    ).toFormat('MMMM d, yyyy');

    const lastInvoiceDate =
        stripeInvoices.length > 0 &&
        DateTime.fromMillis(stripeInvoices[stripeInvoices.length - 1].created * 1000).toFormat(
            'MMMM d, yyyy'
        );

    const ConsultantPrice = productTypes.find(
        (product) => product.productName === 'Consultant'
    )?.productPrice;
    const FeaturedConsultantPrice = productTypes.find(
        (product) => product.productName === 'Featured Consultant'
    )?.productPrice;
    const BasePackage = productTypes.find(
        (product) => product.productName === currentSubscribedPackage
    )?.productPrice;

    const billingCycle = currentSubscribedBillingCycle === 'Monthly' ? 1 : 12;
    const totalConsultantPrice = parseFloat(ConsultantPrice) * 1 * billingCycle;

    const totalFeaturedConsultantPrice = (featured ? parseFloat(FeaturedConsultantPrice) : 0) * 1 * billingCycle;
    const totalBasePackagePrice = parseFloat(BasePackage) * billingCycle;
    const totalPrice = (featured ? totalFeaturedConsultantPrice : 0) + totalBasePackagePrice;

   
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                width: 450,
                padding: 20,
                backgroundColor: 'white',
                borderRadius: 4
            }}
        >
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    marginBottom: 10
                }}
            >
                <div style={{ marginBottom: 10 }}>
                    <h3 style={{ fontSize: 20, color: 'rgba(0,0,0,0.7)', fontWeight: 500 }}>
                        Essentials
                    </h3>
                    <small>Current plan</small>
                </div>
                <div>
                    {featured && (
                        <Tag
                            style={{
                                color: '#fff',
                                backgroundColor: 'var(--primary)'
                            }}
                        >
                            Featured
                        </Tag>
                    )}
                </div>
            </div>

      
            <Divider style={{ margin: '10px 0px' }} />

            <div className='datas'>
                <div className='dataRow'>
                    <div>Total per year</div>
                    <div>${totalPrice}</div>
                </div>
                <div className='dataRow'>
                    <div>
                        Base Subscription Package (Yearly): ${parseInt(BasePackage)} *{' '}
                        {billingCycle == 'Monthly' ? 1 : '12'}
                    </div>
                    <div>${totalBasePackagePrice}</div>
                </div>
                <div className='dataRow'>
                    <div>
                        Per month: ${ConsultantPrice} * 1
                    </div>
                    <div>${ConsultantPrice}</div>
                </div>

                <div className='dataRow'>
                    <div>Payment Frequency:</div>
                    <div>{currentSubscribedBillingCycle}</div>
                </div>

                <div className='dataRow'>
                    <div>Last Payment</div>
                    <div>{lastInvoiceDate}</div>
                </div>
                <div className='dataRow'>
                    <div>Next Payment:</div>
                    <div>{upcomingInvoiceDate}</div>
                </div>

                <div className='dataRow'>
                    <div>Subscription Expires:</div>
                    <div>{upcomingInvoiceDate}</div>
                </div>
            </div>
        </div>
    );
};

export default CurrentSubscription;
