import React, { useEffect, useState } from 'react';
import '../../assets/css/orders.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '../../actions/adminActions';
import { Link } from 'react-router-dom';
import { Pagination } from 'antd';
import { Menu, Dropdown, Select } from 'antd';
import ConfirmModal from '../modals/ConfirmModal';
import CreateUserModal from './CreateUserModal';

function Customers(props) {
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(5);
    const [itemToBeDeleted, setItemToBeDeleted] = useState(null);
    const [deleteItemModal, setDeleteItemModal] = useState(false);
    const statusOptions = ['ACTIVATED', 'DEACTIVATED', 'SUSPENDED'];
    //create user
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [userType, setUserType] = useState('CUSTOMER');
    const [createUserModal, setCreateUserModal] = useState(false);

    useEffect(() => {
        let obj = {
            page: page,
            limit: limit
        };
        props.adminActions.getCustomerUsers({ obj, ...props }, props.history);
    }, [page, limit]);

    const deleteUser = async () => {
        let obj = null;
        if (itemToBeDeleted) {
            obj = {
                userId: itemToBeDeleted.userId,
                userType: itemToBeDeleted.userType
            };
            let deletedUser = await props.adminActions.deleteUser({ obj, ...props }, props.history);
            if (deletedUser) {
                let obj = {
                    page: page,
                    limit: limit
                };
                props.adminActions.getCustomerUsers({ obj, ...props }, props.history);
            }
            setItemToBeDeleted(null);
            setDeleteItemModal(false);
        }
    };

    const customerActionMenu = (props) => (
        <Menu>
            {console.log(props)}
            <Menu.Item>
                <Link to={`/admin/customers/${props.item._id}/edit`}>Edit User</Link>
            </Menu.Item>
            <Menu.Item
                onClick={() => {
                    let obj = {
                        userId: props.item._id,
                        userType: props.item.userType
                    };
                    setItemToBeDeleted(obj);
                    setDeleteItemModal(true);
                }}
            >
                <a>Delete User</a>
            </Menu.Item>
        </Menu>
    );

    const changeActiveStatus = async (val, item) => {
        let obj = {
            userType: item.userType && item.userType,
            userId: item._id && item._id,
            activeStatus: val
        };
        let changedStatus = await props.adminActions.changeActiveStatus(
            { obj, ...props },
            props.history
        );
        if (changedStatus) {
            let obj = {
                page: page,
                limit: limit
            };
            await props.adminActions.getCustomerUsers({ obj, ...props }, props.history);
        }
    };
    const { customerUsers, customerUsersCount } = props.admin;
    return (
        <div className='adminTable'>
            <h1>Customer Users</h1>

            <div className='table'>
                <table>
                    <thead>
                        <tr className='tableHeader'>
                            <th>FULL NAME</th>
                            <th>EMAIL</th>
                            <th>PHONE</th>
                            <th>STATUS</th>
                            <th>ACTION</th>
                        </tr>
                    </thead>
                    <tbody>
                        {customerUsers &&
                            customerUsers.map((item, index) => (
                                <tr className='breakrow'>
                                    <td>
                                        <div>
                                            {/* {" "}
                      <span className="collapseBtn">
                        <i className="fa fa-plus" />
                        <i className="fa fa-minus" />
                      </span>
                      Multiple */}
                                            {item.firstName && item.firstName}{' '}
                                            {item.lastName && item.lastName}
                                        </div>
                                    </td>
                                    <td>
                                        <div>{item.email && item.email}</div>
                                    </td>
                                    <td>
                                        <div>
                                            {item.phoneNo && item.countryCode ? (
                                                <>
                                                    (+{item.countryCode && item.countryCode})-
                                                    {item.phoneNo && item.phoneNo}
                                                </>
                                            ) : null}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <Select
                                                className='activeStatusSelect'
                                                onChange={(val, obj) =>
                                                    changeActiveStatus(val, item)
                                                }
                                                placeholder='Active Status'
                                                value={item.activeStatus && item.activeStatus}
                                                selection
                                            >
                                                {statusOptions &&
                                                    statusOptions.map((item, index) => (
                                                        <Select.Option key={index} value={item}>
                                                            {item}
                                                        </Select.Option>
                                                    ))}
                                            </Select>{' '}
                                        </div>
                                    </td>
                                    <td>
                                        <Dropdown
                                            trigger={['click']}
                                            overlay={() =>
                                                customerActionMenu({
                                                    item: item,
                                                    index: index
                                                })
                                            }
                                            placement='bottomLeft'
                                            arrow
                                        >
                                            <div className='three-dots'>
                                                <span></span>
                                            </div>
                                        </Dropdown>
                                    </td>
                                </tr>
                            ))}
                    </tbody>
                </table>
            </div>
            {deleteItemModal && itemToBeDeleted && (
                <ConfirmModal
                    {...props}
                    onClose={() => {
                        setDeleteItemModal(false);
                        setItemToBeDeleted(null);
                    }}
                    acceptMethod={() => deleteUser()}
                    headerText='Delete Customer User'
                    bodyText='Are you sure you want to delete this user?'
                />
            )}
            {createUserModal && (
                <CreateUserModal
                    title={'Create Customer User'}
                    email={email}
                    password={password}
                    userType={userType}
                    setEmail={setEmail}
                    setPassword={setPassword}
                    createUserModal={createUserModal}
                    setCreateUserModal={setCreateUserModal}
                />
            )}

            <Pagination
                current={page}
                total={customerUsersCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        admin: state.admin
    };
}

function mapDispatchToProps(dispatch) {
    return {
        adminActions: bindActionCreators(adminActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(Customers);
