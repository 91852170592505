import React, { memo } from 'react';
import { Select } from 'antd';

const SelectComponent = ({
    setState,
    options,
    label,
    item,
    name,
    value,
    mode = 'default',
    required = false
}) => {

    return (
        <label className={!item.isValid ? 'error' : ''}>
            <div>
                {label}
                {required && <span style={{ color: 'red' }}>*</span>}:
            </div>
            <Select
                name={name}
                showSearch
                placeholder={label}
                value={item.value}
                mode={mode==='multiple' ? 'multiple' : 'default'}
                onChange={(e, { value }) =>
                    setState({
                        formDirty: true,
                        [name]: {
                            ...item,
                            isValid: true,
                            value: mode ==='multiple' ? e : value,
                            message:
                                mode ==='multiple' && e.length === 0
                                    ? 'Field is required'
                                    : mode !=='multiple' 
                                    ? ''
                                    : item.message
                        }
                    })
                }
                options={options}
            />
            {item.message && (
                <p>
                    {' '}
                    <i className='fe fe-alert-triangle' /> {item.message}
                </p>
            )}
        </label>
    );
};
export default SelectComponent;
