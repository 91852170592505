import React from 'react';
import { Modal } from 'antd';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';

const ChangePaymentMethod = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const { user } = useSelector((state) => state.auth);
    const { firstName, lastName, email } = user ?? {};
    const {
        setPmModal,
        pmModal,
        cardElementOpts,
        handleCardDetailsChange,
        submitChangePmForm,
        actionLoading,
        setActionLoading
    } = props;

    const SubmitForm = async () => {
        setActionLoading(true);
        const cardElement = elements.getElement('card');
        const paymentMethodReq = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
            billing_details: {
                name: firstName + ' ' + lastName,
                email: email
            }
        });
        submitChangePmForm(paymentMethodReq.paymentMethod);
    };
    return (
        <Modal
            className='menusModal changePmModal'
            title='Change Payment method'
            open={pmModal}
            onOk={() => setPmModal(false)}
            onCancel={() => setPmModal(false)}
            footer={<></>}
            centered
        >
            <label className={` fullLabel`} style={{ display: 'flex', flexDirection: 'column' }}>
                Billing card
                <div className='stripeCont'>
                    <CardElement options={cardElementOpts} onChange={handleCardDetailsChange} />
                </div>
            </label>
            <button onClick={() => SubmitForm()} className='redBtn'>
                {' '}
                {actionLoading ? (
                    <div
                        className='spinner-border'
                        style={{ height: 14, width: 14, margin: '0px 10px' }}
                    ></div>
                ) : (
                    ''
                )}{' '}
                Submit
            </button>
        </Modal>
    );
}

export default ChangePaymentMethod;
