import React, { useState, memo, useEffect } from 'react';
import './lower.css';
import List from './Tablist';
import ListSidebar from './Sidebar';
import { GoGlobe } from 'react-icons/go';
import { FaThumbsUp } from 'react-icons/fa';
import { BiLike } from 'react-icons/bi';
import { IoQrCodeSharp } from 'react-icons/io5';
import { BsFillCalendarFill, BsEnvelope, BsStar, BsPhone } from 'react-icons/bs';
import * as therapistActions from '@/actions/therapistActions';
import * as userActions from '@/actions/userActions';
import { connect } from 'react-redux';
import { Alert, Popover } from 'antd';
import { bindActionCreators } from 'redux';
import { EmailShareButton } from 'react-share';
import { BiShareAlt } from 'react-icons/bi';
import QrCodeModal from '../../../Cards/Common/QrCodeModal';
import { getPhoneNumber } from '../../../Cards/Common/Functions';

const Lower = (props) => {
    const {
        tab,
        setTab,
        id,
        selectedAddressIndex,
        selectedAddress,
        member,
        memberType,
        auth,
        history
    } = props;
    console.log('props', props);
    const [qrModal, setQrModal] = useState(false);
    const tabs = ['doctors', 'general', 'blogs', 'following', 'reviews'];
    const handleTab = (string) => {
        if (tabs.includes(string)) {
            setTab(string);
            let url = `/${memberType}-view/${id}?`;
            if (selectedAddress) {
                url += `branchId=${selectedAddress}&`;
            }
            url += `tab=${string}`;
            props.history.push(url);
        }
    };
    const [liked, setLiked] = useState(false);
    const { formatted: formattedPhoneNo, phone } = getPhoneNumber(member);
    useEffect(() => {
        if (member) {
            let like = false;
            if (member.likes && auth && auth.userId) {
                let filtered = member.likes.filter((item) => item.user == auth.userId);

                if (filtered.length > 0) {
                    setLiked(true);
                } else {
                    setLiked(false);
                }
            }
        }
    }, [member, member && member.likes]);

    const likeOrUnlikeUserType = async () => {
        let obj = {
            userId: member._id,
            userType: memberType.toUpperCase(),
            like: liked ? false : true
        };
        let likedLaw = await props.generalActions.likeOrUnlikeUserType(
            { ...props, obj },
            props.history
        );
        if (likedLaw) {
            await props.actions[
                `get${memberType.slice(0,1).toUpperCase() + memberType.slice(1)}ById`
            ]({ ...props, therapistId: id }, props.history);
            let obj = {
                therapistId: id
            };
            await props.actions[
                `get${memberType.slice(0,1).toUpperCase() + memberType.slice(1)}StatCounts`
            ]({ ...props, obj }, props.history);
        }
    };
    let selectedBranch = member && member.branches && member.branches[selectedAddressIndex];

    const sendMail = async (email, subject, body) => {
        window.open(`mailto:${email}?subject=${subject}&body=${body}`);
    };
    const goToSite = async () => {};

    const makeCall = async () => {
        window.open(`tel:${phone}`);
    };

    const handleShare = async () => {
        let urlString = window.location.origin + `/member-view/${member._id}`;

        if (navigator.share) {
            try {
                await navigator.share({
                    title: member.firstName + ' ' + member.lastName,
                    text: 'Check out this content!',
                    url: urlString
                });
                console.log('Content shared successfully');
            } catch (error) {
                console.error('Error sharing content', error);
            }
        } else {
            console.log('Web Share API is not supported in your browser.');
        }
    };
    return (
        <>
            <div className='hospital-view-lower'>
                <div className='hospital-view-lower-top'>
                    <div className='hospital-view-lower-top-btns'>
                        <div
                            className={`${tab == 'general' && 'active'} hospital-view-lower-btns`}
                            onClick={() => handleTab('general')}
                        >
                            <p>General</p>
                        </div>
                        <div
                            className={`${tab == 'blogs' && 'active'} hospital-view-lower-btns`}
                            onClick={() => handleTab('blogs')}
                        >
                            <p>Posts</p>
                        </div>

                        <div
                            className={`${tab == 'reviews' && 'active'} hospital-view-lower-btns`}
                            onClick={() => handleTab('reviews')}
                        >
                            <p>Reviews</p>
                        </div>
                    </div>
                </div>
            </div>
            {history.location?.state?.prevPath === 'profile' && (
                <div style={{ padding: 20 }}>
                    <Alert
                        onClick={() => history.goBack()}
                        style={{ cursor: 'pointer' }}
                        message={
                            <div>
                                This is a preview of your public profile. Click here to return to
                                your profile page.
                            </div>
                        }
                        type='info'
                    />
                </div>
            )}
            <div className='hospital-view-action'>
                <div className='hospital-view-action-container'>
                    <div className='hospital-view-action-btn'>
                        <div
                            className='hospital-view-book'
                            onClick={() =>
                                props.history.push(`/patient/appointments/new?step=2&member=${id}`)
                            }
                        >
                            <BsFillCalendarFill />
                            <p>Book Appointment</p>
                        </div>

                        {member.member && member.member && member.member.allowChat && (
                            <div
                                className='hospital-view-message'
                                onClick={() => props.openMessageModal()}
                            >
                                <BsEnvelope />
                                <p>Message</p>
                            </div>
                        )}

                        {member &&
                            member.member &&
                            auth &&
                            auth.userId &&
                            auth.userType != 'member' &&
                            member._id != auth.userId && (
                                <div
                                    className='hospital-view-message'
                                    onClick={() => {
                                        let obj = {
                                            favouriteItem: member?._id,
                                            favouriteType: member?.userType
                                        };
                                        props.userActions.addToFavourites(
                                            { obj, ...props },
                                            props.history
                                        );
                                    }}
                                >
                                    <BsStar />

                                    <p>Add to Favourites</p>
                                </div>
                            )}
                    </div>
                    <div className='hospital-view-btns'>
                        {/* <div className={"hospital-views-click"}>
              <IoQrCodeSharp fontSize="1.5rem" />
            </div> */}
                        <Popover
                            content={formattedPhoneNo ? formattedPhoneNo : 'Not Specified'}
                            onClick={() => makeCall()}
                        >
                            <div
                                className={
                                    phone ? 'hospital-views-click active' : 'hospital-views-click'
                                }
                                style={{ color: 'white' }}
                            >
                                <BsPhone fontSize='1.5rem' />
                            </div>
                        </Popover>
                        <Popover
                            content={
                                member && member?.contact?.email
                                    ? member?.contact?.email
                                    : member && member.email
                                    ? member.email
                                    : 'Not Specified'
                            }
                            onClick={() =>
                                sendMail(
                                    member && member?.contact?.email
                                        ? member?.contact?.email
                                        : member && member.email
                                        ? member.email
                                        : '',
                                    'Contact member',
                                    'Hi there!'
                                )
                            }
                        >
                            <div
                                className={
                                    selectedBranch && selectedBranch.email
                                        ? 'hospital-views-click active'
                                        : 'hospital-views-click'
                                }
                                style={{ color: 'white' }}
                            >
                                <BsEnvelope fontSize='1.5rem' />
                            </div>
                        </Popover>
                        <Popover
                            content={
                                selectedBranch && selectedBranch.website
                                    ? selectedBranch.website
                                    : 'No website available'
                            }
                            onClick={() => goToSite()}
                        >
                            <div
                                className={
                                    selectedBranch && selectedBranch.website
                                        ? 'hospital-views-click active'
                                        : 'hospital-views-click'
                                }
                            >
                                <GoGlobe
                                    fontSize='1.5rem'
                                    className={
                                        selectedBranch && selectedBranch.website ? 'email' : ''
                                    }
                                />
                            </div>
                        </Popover>
                        <Popover content={'View QR Code'}>
                            <div className='hospital-views-click' style={{ color: 'var(--gray)' }}>
                                <IoQrCodeSharp fontSize='1.5rem' />
                            </div>
                        </Popover>

                        {/* <div className="hospital-views-click" style={{ color: "white" }}>
              <FaRegHeart fontSize="1.5rem" />
            </div> */}
                        <Popover content={`${liked ? 'Unlike' : 'Like'} member`}>
                            <div
                                className={'hospital-views-click active'}
                                onClick={() => likeOrUnlikeUserType()}
                            >
                                {liked ? (
                                    <FaThumbsUp fontSize='1.5rem' className='email' />
                                ) : (
                                    <BiLike fontSize='1.5rem' className='email' />
                                )}
                            </div>
                        </Popover>

                        <Popover content='Share'>
                            <div
                                className='hospital-views-click active'
                                style={{ color: 'white' }}
                                onClick={handleShare}
                            >
                                <BiShareAlt fontSize='1.5rem' className='email' />
                            </div>
                        </Popover>
                    </div>
                </div>
            </div>
            <div className='hospital-view-lower-grid'>
                <List member={member} memberType={memberType} {...props} tab={tab} />
                {tab == 'general' && <ListSidebar {...props} index={props.index} />}
            </div>

            <QrCodeModal
                open={qrModal}
                onCancel={() => setQrModal(false)}
                user={member}
                userType={'member'}
            />
        </>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile
});
const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(therapistActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(memo(Lower));
