import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../assets/css/auth.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '../../actions/userActions';
import { Form, message, Button, Input, Alert, Modal } from 'antd';
import Logo from '@/assets/images/logo.png';
import {
    MailOutlined,
    PhoneFilled,
    EyeTwoTone,
    EyeInvisibleOutlined,
    LockOutlined,
    UserOutlined
} from '@ant-design/icons';

const ResetPass = (props) => {
    const { userTypeProp, open, closeResetPass } = props;
    const [form] = Form.useForm();

    const resetKey = new URLSearchParams(window.location.search).get('token');
    const uType = new URLSearchParams(window.location.search).get('userType');

    const submitForm = async (e) => {
        e.preventDefault();
        form.validateFields()
            .then(async (values) => {
                let obj = {
                    resetToken: resetKey,
                    new_password: values.password,
                    confirm_password: values.cPassword,
                    userType: uType
                };
                let reset = await props.actions.resetPassword({ ...props, obj }, props.history);
                if (reset) {
                    props.closeResetPass();
                    props.signInModal();
                } else {
                    message.error('Server error');
                }
            })
            .catch((err) => console.log(err));
    };
    const { errors } = props.auth;

    return (
        <Modal open={open} centered destroyOnClose onCancel={closeResetPass} footer={null}>
            <div className='form'>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        <img src={Logo} alt='logo' style={{ width: '8rem' }} />
                    </div>
                </div>

                <h3>Reset Password</h3>

                <h5 style={{ marginTop: 5, fontSize: 14, color: 'gray' }}>Enter a new password.</h5>
                <div className='form'>
                    <Form form={form}>
                        <Form.Item
                            name='password'
                            rules={[
                                {
                                    required: true,
                                    message: 'Please enter your password!'
                                }
                            ]}
                        >
                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                                prefix={<LockOutlined className='site-form-item-icon' />}
                                placeholder='Password'
                            />
                        </Form.Item>

                        <Form.Item
                            name='cPassword'
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                                {
                                    required: true,
                                    message: 'Please confirm your password!'
                                },
                                ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (!value || getFieldValue('password') === value) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(
                                            new Error('The two passwords do not match!')
                                        );
                                    }
                                })
                            ]}
                        >
                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                                }
                                prefix={<LockOutlined className='site-form-item-icon' />}
                                placeholder='Confirm Password'
                            />
                        </Form.Item>
                    </Form>
                    {errors?.message && <Alert message={errors?.message} type='error' />}

                    <Button type='primary' style={{ width: '100%' }} onClick={submitForm}>
                        Reset Password
                    </Button>
                </div>

                <div
                    className='btm-links-login'
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        fontFamily: 'Inter Var'
                    }}
                >
                    <p style={{ fontSize: 12, cursor: 'context-menu' }}>
                        <span
                            style={{ fontSize: 12, fontWeight: 'bold', cursor: 'pointer', color: 'var(--primary)' }}
                            onClick={() => {
                                props.closeForgotPass();
                                props.signInModal();
                            }}
                        >
                            Sign in&nbsp;
                        </span>
                        to your account
                    </p>
                    <p style={{ fontSize: 12, cursor: 'context-menu' }}>
                        Don't have an account?
                        <span
                            to='#'
                            style={{ fontSize: 12, fontWeight: 'bold', cursor: 'pointer', color: 'var(--primary)' }}
                            onClick={() => {
                                props.closeForgotPass();
                                props.signUpModal();
                            }}
                        >
                            &nbsp;Sign Up
                        </span>
                    </p>
                </div>
            </div>
        </Modal>
    );
};

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(ResetPass);
