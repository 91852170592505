import React, { useState, useEffect, useRef } from 'react';
import { Button, message, Modal, Select } from 'antd';
import validator from 'validator';
import { UploadOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';

const ShareDocumentModal = (props) => {
    const { open, setOpen, document, setDocument, page, limit } = props;
    const [email, setEmail] = useState('');
    const [emails, setEmails] = useState([]);
    const [emailError, setEmailError] = useState('');
    const [documentType, setDocumentType] = useState('');
    const { documentTypes } = useSelector((state) => state.documents);
    let inputRef = useRef();

    useEffect(() => {
        ResetFileInput();
    }, [open]);
    
    useEffect(() => {
        if (documentTypes && documentTypes.length) {
            const generalDocuments = documentTypes.find((docType) => docType.name === "General Document");
            if (generalDocuments) {
                setDocumentType(generalDocuments._id);
            }
        }
    }, [documentTypes]);

    const ResetFileInput = () => {
        if (inputRef && inputRef.current) {
            inputRef.current.value = null;
        }
    };

    const shareDocument = async () => {
        let formObj = new FormData();
        formObj.append('userId', props.auth.userId);
        formObj.append('userType', props.auth.userType);
        formObj.append('file', document);
        formObj.append('documentType', documentType)
        if (!document) {
            message.error('Choose a document to share');
            return;
        }
        if (emails.length == 0) {
            message.error('Choose atleast one email to share the document with');
            return;
        }
        emails.map((item, index) => formObj.append(`emails[${index}]`, item));
        emails.forEach((item, index) => console.log(item, index));
        let uploadedDocument = await props.documentActions.uploadDocument(
            { ...props, obj: formObj },
            props.history
        );
        if (uploadedDocument) {
            let obj = {
                userId: props.auth.userId,
                userType: props.auth.userType,
                emails: emails,
                docId: uploadedDocument.docId
            };
            let shareDoc = await props.documentActions.shareDocument(
                { ...props, obj },
                props.history
            );
            if (shareDoc) {
                message.success('Successfully shared documents with emails');
                let obj = {
                    page: page,
                    perPage: limit
                };
                await props.documentActions.getDocumentList({ ...props, obj }, props.history);
                setOpen(false);
                setDocument(null);
                setEmails([]);
                setEmail('');
                ResetFileInput();
            } else {
                message.error('Failed to share documents with emails');
            }
        }
    };

    const filterEmails = async (item, index) => {
        let allEmails = [...emails];
        setEmails(allEmails.filter((it) => it != item));
    };
    return (
        <Modal
            className='customModal '
            title={'Share Document'}
            open={open}
            onOk={() => {
                setOpen(false);
                setDocument(null);
                setEmails([]);
                setEmail('');
                ResetFileInput();
            }}
            onCancel={() => {
                setOpen(false);
                setDocument(null);
                setEmails([]);
                setEmail('');
                ResetFileInput();
            }}
            footer={null}
        >
            {!document ? (
                <p style={{ marginBottom: 10 }}>No Document uploaded</p>
            ) : (
                <>
                    {' '}
                    <p style={{ marginBottom: 10 }}>
                        <strong>Name:</strong> {document.name}{' '}
                    </p>{' '}
                    <p style={{ marginBottom: 10 }}>
                        <strong>Size:</strong> {document.size / 1000} Kb
                    </p>
                </>
            )}

            <Select placeholder='Select Document Type' style={{ width: '100%', marginBottom: 20 }} value={documentType || undefined} onChange={(value)=>setDocumentType(value)}>
                {documentTypes &&
                    documentTypes.length &&
                    documentTypes.map((item) => (
                        <Select.Option value={item._id}>{item.name}</Select.Option>
                    ))}
            </Select>
            
            <label className='buttonLabel' htmlFor='document'>
                <input
                    ref={inputRef}
                    id='document'
                    type='file'
                    onChange={(e) => setDocument(e.target.files[0])}
                    style={{ display: 'none' }}
                />
                Choose Document
            </label>
            <label className='inputEmails'>
                <div className=''>
                    <input value={email} onChange={(e) => setEmail(e.target.value)} />
                    {emailError && <p className='error'>{emailError}</p>}
                </div>
                <button
                    onClick={(e) => {
                        e.preventDefault();
                        let values = [...emails];
                        if (!values.includes(email)) {
                            if (email != '' && validator.isEmail(email)) {
                                values.push(email.trim());
                                setEmails(values);
                                setEmailError('');
                                setEmail('');
                            } else if (!validator.isEmail(email)) {
                                setEmailError('Choose a valid email address');
                            } else {
                                setEmailError('Provide an email to share document with');
                            }
                        } else {
                            setEmailError('Email already marked to be shared with');
                        }
                    }}
                >
                    Add Email
                </button>
            </label>
            <label>
                <p
                    style={{
                        fontSize: '12px',
                        fontWeight: 400,
                        borderBottom: '1px solid #dddd',
                        marginBottom: '5px',
                        marginTop: 20
                    }}
                >
                    Emails to be shared with:
                </p>
                <div className='emailItems'>
                    {emails && emails.length ? (
                        emails.map((item, index) => (
                            <p className='emailItem'>
                                {item}{' '}
                                <span onClick={() => filterEmails(item, index)}>
                                    <i className='fe fe-x' />
                                </span>
                            </p>
                        ))
                    ) : (
                        <p>No emails shared with</p>
                    )}
                </div>
            </label>
            <Button
                type='primary'
                icon={<UploadOutlined />}
                onClick={() => shareDocument()}
            >
                Upload Document
            </Button>
        </Modal>
    );
};

export default ShareDocumentModal;
