import * as Types from '../actions/types';

const init = {
    appointments: [],
    appointmentsCount: 0,
    appointmentError: '',
    hospitalsAndDoctors: {
        hospitals: [],
        hospitalsCount: 0,
        doctors: [],
        doctorsCount: 0
    },
    singleAppointment: null,
    videoToken: '',
    calendarEvents: [],
    googleEvents: [],
    outlookEvents: []
};

const appointmentReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_MY_APPOINTMENTS: {
            return {
                ...state,
                appointments: action.payload.appointments,
                appointmentsCount: action.payload.appointmentsCount
            };
        }
        case Types.GET_APPOINTMENT_HOSPITALS: {
            return {
                ...state,
                hospitalsAndDoctors: {
                    ...state.hospitalsAndDoctors,
                    hospitals: action.payload.hospitals,
                    hospitalsCount: action.payload.hospitalsCount
                }
            };
        }
        case Types.GET_APPOINTMENT_DOCTORS: {
            return {
                ...state,
                hospitalsAndDoctors: {
                    ...state.hospitalsAndDoctors,
                    doctors: action.payload.doctors,
                    doctorsCount: action.payload.doctorsCount
                }
            };
        }
        case Types.GET_VIDEO_TOKEN: {
            return {
                ...state,
                videoToken: action.payload.token
            };
        }

        case Types.GET_GOOGLE_CALENDAR_EVENTS: {
            return {
                ...state,
                // calendarEvents: action.payload.calendarEvents
                googleEvents: action.payload.googleEvents
            };
        }
        case Types.GET_OUTLOOK_CALENDAR_EVENTS: {
            return {
                ...state,
                outlookEvents: action.payload.outlookEvents
            };
        }
        case Types.GET_CALENDAR_EVENTS: {
            return {
                ...state,
                calendarEvents: action.payload.calendarEvents
            };
        }
        case Types.GET_SINGLE_APPOINTMENT: {
            return {
                ...state,
                singleAppointment: action.payload.singleAppointment
            };
        }

        case Types.SET_APPOINTMENT_ERROR: {
            return {
                ...state,
                appointmentError: action.payload.error
            };
        }

        case Types.LOGOUT: {
            return init;
        }
        default:
            return state;
    }
};

export default appointmentReducer;
