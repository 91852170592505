export const nurseTypes = [
    'Adult-Gerontology Nurse Practitioner (AGNP)',
    'Bariatric Registered Nurse',
    'Cardiac Nurse',
    'Certified Nursing Assistant (CNA)',
    'Certified Registered Nurse Anesthetist (CRNA)',
    'Clinical Nurse Specialist (CNS)',
    'Clinical Nurse Supervisor',
    'Critical Care Nurse',
    'Emergency Room Nurse',
    'Family Nurse Practitioner (FNP)',
    'Forensic Nurse',
    'Health Informatics Nurse Specialist',
    'Home Care Registered Nurse',
    'Intensive Care Unit (ICU) Registered Nurse',
    'Labor and Delivery Nurse',
    'Licensed Practical Nurse (LPN)',
    'Licensed Vocational Nurse (LVN)',
    'Managed Care Nurse',
    'Medical-Surgical Nurse',
    'Mental Health Nurse',
    'Military Registered Nurse',
    'Neonatal Nurse',
    'Nurse Anesthesia Practice',
    'Nurse Educator',
    'Nurse Informatics Specialist',
    'Nurse Midwife',
    'Nursing Administrator',
    'Oncology Registered Nurse',
    'Orthopedic Nurse',
    'Pediatric Nurse',
    'Plastic Surgery Registered Nurse',
    'Post-Anesthesia Care Unit (PACU) Nurse',
    'Psychiatric Mental Health Nurse Practitioner (PMHNP)',
    'Public Health Nurse',
    'Radiology Nurse',
    'Registered Nurse (RN)',
    'Rehabilitation Registered Nurse',
    'School Nurse',
    'Travel Nurse',
    'Women’s Health Nurse',
    'General Nurse (No Formal Training)'
];

export const bnmcRegistrationTypes = [
    'Junior Midwifery',
    'Diploma in Nursing Science and Orthopeadic Nursing',
    'Diploma in Orthopeadic Nursing (1 Year)',
    'Community Based Skilled Birth Attendants',
    'Diploma in Nursing Science and Midwifery (4 Year)',
    'Assistant Nurse',
    'Diploma in Psychiatric Nursing',
    'Diploma in Nursing (3 Year)',
    'Diploma in Rehabilitation Nursing',
    'Diploma in Intensive Care Nursing',
    'N/A',
    'Diploma in Midwifery (1 Year)',
    'Diploma in Midwifery (14 Months)',
    'Master of Science in Nursing (Mental Health and Psychiatric Nursing)',
    'Master of Science in Nursing (Community Health Nursing)',
    'Master of Science in Nursing (Child Health Nursing)',
    'Master of Science in Nursing (Women Health and Midwifery Nursing)',
    'Master of Science in Nursing (Adult and Elderly Health Nursing)',
    'Master of Science in Nursing (Nursing Management)',
    'Senior Certificate in Nursing',
    'Senior Certificate in Midwifery',
    'Diploma in Nursing Science and Midwifery (3 Year)',
    'Community Paramedic',
    'Diploma in Ophthalmic Nursing',
    'Diploma in Chest Disease Nursing',
    'Family Welfare Visitors',
    'Diploma in Midwifery (3 Year)',
    'B. Sc in Nursing (4 Year)',
    'B. Sc in Nursing (Post Basic)',
    'B. Sc in Public Health Nursing-Post Basic',
    'Diploma in Renal Nursing (1 Year)',
    'Diploma in Cardiac Nursing',
    'Diploma in Pediatric Nursing',
    'Diploma in Trauma Nursing',
    'Diploma in Oncology Nursing',
    'Diploma in Critical Care Nursing',
    'Bachelor of Science in Midwifery (Post Basic)'
];

export const groupedNurseTypes = nurseTypes.reduce((acc, option) => {
    if (!acc[option.group]) {
        acc[option.group] = [];
    }
    acc[option.group].push(option);
    return acc;
}, {});
