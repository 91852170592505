import React from 'react';

const ProfileSection = ({ children, title }) => {
    return (
        <div className='profile-section' style={{ width: '100%' }}>
            <h1>{title}</h1>
            <div className='input-fields'>{children}</div>
        </div>
    );
};

export default ProfileSection;
