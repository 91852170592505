import React, { memo } from 'react';

const RegNoDocs = (props) => {
    console.log('reg', props);
    return (
        <div className={`Order-sections-first ${props.css}`}>
            <div>
                <p>Registration Number For Hospital: {props.hospitalName}</p>
                <p>Hospital Registration Number: {props.regNo}</p>
            </div>
        </div>
    );
};

export default memo(RegNoDocs);
