import React from 'react';
import {
    UserOutlined,
    FileOutlined,
    CalendarOutlined,
    HeartOutlined,
    BellOutlined,
    StarOutlined,
    MessageOutlined,
    DollarOutlined,
    EditOutlined
} from '@ant-design/icons';

import Reviews from '@components/NewProfile/Shared/Reviews';
import PaymentHistory from '@components/NewProfile/Shared/Payments';
import Favourites from '@components/NewProfile/Shared/Favourites';
import Appointments from '@components/NewProfile/PatientProfile/Appointments';
import EditBlog from '@components/NewProfile/Shared/Blogs/Edit';
import SingleBlog from '@components/Blogs/SingleView';
import CreateBlog from '@components/NewProfile/Shared/Blogs/Create';
import Documents from '@components/NewProfile/Shared/Documents';
import NewAppointment from '@components/NewProfile/PatientProfile/Appointment/NewAppointment';
import Profile from '@components/NewProfile/PatientProfile/Profile';
import Messaging from '@components/NewProfile/Shared/Messaging/Messaging';
import Blogs from '@components/NewProfile/Shared/Blogs';
import Notifications from '@components/NewProfile/Shared/Notifications/Notifications';
import VideoContainer from '@components/NewProfile/Shared/Meeting/VideoContainer';
import MeetingEnded from '@components/NewProfile/Shared/Meeting/MeetingEnded';

export default [
    {
        name: 'My Account',
        path: '/patient/profile',
        component: Profile,
        icon: <UserOutlined />,
        exact: true
    },
    {
        name: 'Documents',
        path: '/patient/documents',
        component: Documents,
        icon: <FileOutlined />,
        exact: true
    },
    {
        name: 'Appointments',
        path: '/patient/appointments',
        component: Appointments,
        icon: <CalendarOutlined />,
        exact: true,
        children: [
            {
                path: '/patient/appointments/new',
                component: NewAppointment,
                exact: true
            },
            {
                path: '/patient/appointments/meeting/:appointmentId/:sessionId/:token',
                component: VideoContainer,
                exact: true
            },
            {
                path: '/patient/appointments/meeting/:appointmentId/meeting-ended',
                component: MeetingEnded,
                exact: true
            }
        ]
    },
    {
        name: 'Favourites',
        path: '/patient/favourites',
        component: Favourites,
        icon: <HeartOutlined />,
        exact: true
    },
    {
        name: 'Notifications',
        path: '/patient/notifications',
        component: Notifications,
        icon: <BellOutlined />,
        exact: true
    },
    {
        name: 'Reviews',
        path: '/patient/reviews',
        component: Reviews,
        icon: <StarOutlined />,
        exact: true
    },
    {
        name: 'Messaging',
        path: '/patient/chats',
        component: Messaging,
        icon: <MessageOutlined />,
        exact: true
    },
    {
        name: 'Payments',
        path: '/patient/paymenthistory',
        component: PaymentHistory,
        icon: <DollarOutlined />,
        exact: true
    },
    {
        name: 'Blogs',
        path: '/patient/blogs',
        component: Blogs,
        icon: <EditOutlined />,
        exact: true,
        children: [
            {
                path: '/patient/blogs/createblog',
                component: CreateBlog,
                exact: true
            },
            {
                path: '/patient/blogs/:postId',
                component: SingleBlog,
                exact: true
            },
            {
                path: '/patient/blogs/edit/:id',
                component: EditBlog,
                exact: true
            }
        ]
    }
];
