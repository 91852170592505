import React from 'react';
import Blogs from './HospitalTabs/Blogs';
import Doctors from './HospitalTabs/Doctors';
import Reviews from './HospitalTabs/Reviews';
import General from './HospitalTabs/General';
import './list.css';
const List = (props) => {
    return (
        <div className='hospital-view-list'>
            {props.tab && props.tab === 'doctors' ? (
                <Doctors doctors={props.hospital.doctors} {...props} />
            ) : props.tab === 'reviews' ? (
                <Reviews {...props} />
            ) : props.tab === 'blogs' ? (
                <Blogs {...props} />
            ) : props.tab === 'general' ? (
                <General {...props} />
            ) : null}
        </div>
    );
};

export default List;
