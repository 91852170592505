import * as Types from '../actions/types';
const init = {
    topBnaner: false,
    marketingInfo: {}
};

const adminReducer = (state = init, action) => {
    switch (action.type) {
        case Types.SET_BANNER: {
            return {
                ...state,
                topBnaner: action.payload.banner
            };
        }
        case Types.GET_MARKETING_INFO: {
            return {
                ...state,
                marketingInfo: action.payload.marketingInfo
            };
        }

        default:
            return state;
    }
};

export default adminReducer;
