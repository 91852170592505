import * as Types from '../actions/types';

const init = {
    documentList: [],
    documentTypes: [],
    documentListCount: 0, 
    areDocumentsLocked: true
};

const documentReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_DOCUMENT_LIST: {
            return {
                ...state,
                documentList: action.payload.documentList,
                documentListCount: action.payload.count
            };
        }
        case Types.GET_ALL_DOCUMENT_TYPES: {
            return {
                ...state,
                documentTypes: action.payload.documentTypes,
            };
        }

        case Types.UNLOCK_DOCUMENTS: {
            return {
                ...state,
                areDocumentsLocked: false
            };
        }
        case Types.LOGOUT: {
            return init;
        }

        default:
            return state;
    }
};

export default documentReducer;
