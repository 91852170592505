import React, { useEffect, useState } from 'react';
import './preview.css';
import UpdateContent from '../Content/UpdateContent';
import { useSelector } from 'react-redux';
import { Switch, Button } from 'antd';

const initialState = {
    text: '',
    style: {
        fontFamily: 'Inter Var',
        fontSize: '14px',
        color: '#000',
        fontWeight: 400
    }
};

const TopBar = (props) => {
    const { handleSwitch, saveMarketingInfo } = props;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { marketingInfo } = useSelector((state) => state.marketing);

    const { topBarContentType, displayTopBar, topBarContent } = marketingInfo || {};

    const [bannerType, setBannerType] = useState('Text');

    useEffect(() => {
        setBannerType(topBarContentType);
    }, [topBarContentType]);

    const [content, setContent] = useState({
        title: { ...initialState },
        header: { ...initialState },
        grabber: { ...initialState },
        line1: { ...initialState },
        line2: { ...initialState },
        line3: { ...initialState },
        line4: { ...initialState },
        image: '',
        backgroundColor: '#fff'
    });

    useEffect(() => {
        if (topBarContent) {
            setContent(topBarContent);
        }
    }, [topBarContent]);

    return (
        <div>
            <div
                style={{
                    display: 'flex',
                    gap: 20,
                    marginBottom: 10,
                    justifyContent: 'space-between'
                }}
            >
                <div style={{ display: 'flex', gap: 10 }}>
                    <Switch
                        checked={displayTopBar}
                        onChange={(checked) => handleSwitch(checked, 'TOPBAR')}
                    />
                    <div>Display Top Bar</div>
                </div>
                <Button type='default' onClick={() => setIsModalOpen(true)}>
                    Edit
                </Button>
            </div>
            {isModalOpen && (
                <UpdateContent
                    open={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    type={'TOPBAR'}
                    contentType={bannerType}
                    toggleContentType={(e) => {
                        setBannerType(e.target.value);
                    }}
                    save={saveMarketingInfo}
                    content={content}
                />
            )}
        </div>
    );
};

export default TopBar;
