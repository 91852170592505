import React from 'react';
import { OTPublisher } from 'opentok-react';
import CheckBox from './CheckBox';
// import { AiOutlineAudio, AiOutlineAudioMuted} from 'react-icons/ai'
// import {MdOutlineScreenShare} from 'react-icons/md'
import { BsMicFill, BsFillMicMuteFill } from 'react-icons/bs';
import { FaVideo, FaVideoSlash } from 'react-icons/fa';

class Publisher extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error: null,
            audio: true,
            video: true,
            videoSource: 'camera'
        };
    }

    setAudio = (audio) => {
        console.log(audio);
        this.setState({ audio });
    };

    setVideo = (video) => {
        console.log(video);
        this.setState({ video });
    };

    changeVideoSource = (videoSource) => {
        this.state.videoSource !== 'camera'
            ? this.setState({ videoSource: 'camera' })
            : this.setState({ videoSource: 'screen' });
    };

    onError = (err) => {
        this.setState({ error: `Failed to publish: ${err.message}` });
    };

    render() {
        return (
            <div className='publisher'>
                {/* <span className='checkFlex'>Your Camera</span> */}
                {this.state.error ? (
                    <div id='error' style={{ color: 'red' }}>
                        {this.state.error}
                    </div>
                ) : null}
                <OTPublisher
                    properties={{
                        publishAudio: this.state.audio,
                        publishVideo: this.state.video,
                        videoSource: this.state.videoSource === 'screen' ? 'screen' : undefined
                    }}
                    onError={this.onError}
                />
                <div className='checkFlex'>
                    {/* <CheckBox
            label='Share Screen'
            initialChecked={false}
            onChange={this.changeVideoSource}
            // icon={}
          /> */}

                    <CheckBox
                        label='Audio'
                        initialChecked={this.state.audio}
                        onChange={this.setAudio}
                        icon={this.state.audio ? <BsMicFill /> : <BsFillMicMuteFill />}
                    />

                    <CheckBox
                        label='Video'
                        initialChecked={this.state.video}
                        onChange={this.setVideo}
                        icon={this.state.video ? <FaVideo /> : <FaVideoSlash />}
                    />
                </div>
            </div>
        );
    }
}
export default Publisher;
