import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as adminActions from '@/actions/adminActions';

function MailListingCounts(props) {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(adminActions.getMailListingsCount({ ...props }));
    }, []);

    const { complaintCount, feedbackCount, salesCount, generalCount, supportCount } =
        props.mailCounts;

    return (
        <div>
            <h1>Mail Listing</h1>

            <div className='userCardsRow'>
                <div
                    className='userCard'
                    onClick={() => props.history.push('/admin/mail-listing/complaints')}
                >
                    <h3>
                        <span>{complaintCount ? complaintCount : 0}</span> Complaints Mail
                    </h3>
                </div>
                <div
                    className='userCard'
                    onClick={() => props.history.push('/admin/mail-listing/feedback')}
                >
                    <h3>
                        {' '}
                        <span>{feedbackCount ? feedbackCount : 0}</span> Feedback Mail
                    </h3>
                </div>
                <div
                    className='userCard'
                    onClick={() => props.history.push('/admin/mail-listing/sales')}
                >
                    <h3>
                        {' '}
                        <span>{salesCount ? salesCount : 0}</span> Sales Mail
                    </h3>
                </div>
                <div
                    className='userCard'
                    onClick={() => props.history.push('/admin/mail-listing/support')}
                >
                    <h3>
                        {' '}
                        <span>{supportCount ? supportCount : 0}</span>
                        Support Mail
                    </h3>
                </div>
                <div
                    className='userCard'
                    onClick={() => props.history.push('/admin/mail-listing/general')}
                >
                    <h3>
                        {' '}
                        <span>{generalCount ? generalCount : 0}</span>
                        General Mail
                    </h3>
                </div>
            </div>
        </div>
    );
}

function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        admin: state.admin,
        mailCounts: state.admin && state.admin.mailCounts && state.admin.mailCounts
    };
}

function mapDispatchToProps(dispatch) {
    return {
        adminActions: bindActionCreators(adminActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(MailListingCounts);
