import { geocodeByAddress } from 'react-places-autocomplete';

export const LocationFiller = async (addr, setCoordinates, setCity, setCountry) => {
    let address = await geocodeByAddress(addr);

    var componentForm = {
        street_number: '',
        route: '',
        postal_town: '',
        locality: '',
        administrative_area_level_1: '',
        country: '',
        postal_code: '',
        formattedAddress: '',
        neighbourhood: ''
    };

    address = address[0];
    componentForm.formattedAddress = address.formatted_address;
    for (var i = 0; i < address.address_components.length; i++) {
        var addressType = address.address_components[i].types[0];
        console.log(addressType);
        if (componentForm[addressType] == '') {
            let val = address.address_components[i][componentForm[addressType]];
            componentForm[addressType] = address.address_components[i].long_name;
        }
    }

    let lat, lng;
    if (address && address.geometry && address.geometry.location) {
        lat = address.geometry.location.lat();
        lng = address.geometry.location.lng();
        console.log([lng, lat]);
        setCoordinates([lng, lat]);
    }
    if (componentForm.country !== '') setCountry(componentForm.country);

    setCity(
        componentForm.postal_town != 'Town' && componentForm.postal_town
            ? componentForm.postal_town
            : componentForm.locality != 'locality'
            ? componentForm.locality
            : ''
    );

    setCoordinates([lng, lat]);
};
