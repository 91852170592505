import React, { useState, useEffect } from 'react';
import { Button, Input, Modal, message } from 'antd';

const PracticeAreasModal = (props) => {
    const [name, setName] = useState('');
    const { setIsModalOpen, isModalOpen, practiceAreasData, item } = props;
    const [id, setId] = useState(null);

    useEffect(() => {
        if (item) {
            setName(item.practiceArea);
            setId(item.id);
        }
    }, [item]);

    const submit = async () => {
        if(!name) return message.error('Please enter practice area name')
        if (!id) {
            let arr = [
                ...practiceAreasData,
                {
                    practiceArea: name,
                    id: practiceAreasData.length + 1
                }
            ].map((item) => item.practiceArea);

            let obj = {
                userId: props.auth.userId,
                practiceAreas: arr
            };
            let updateRole = await props.generalActions.updatePracticeAreas(
                { ...props, obj },
                props.history
            );
            if (updateRole) {
                await props.generalActions.getPracticeAreas({ ...props, obj });
                setIsModalOpen(false);
                setName('');
            }
        } else {
            let arr = [...practiceAreasData];

            arr[id - 1].practiceArea = name;

            arr = arr.map((item, index) => item.practiceArea);

            let obj = {
                userId: props.auth.userId,
                practiceAreas: arr
            };
            let updatePA = await props.generalActions.updatePracticeAreas(
                { ...props, obj },
                props.history
            );
            if (updatePA) {
                await props.generalActions.getPracticeAreas({ ...props, obj });
                setIsModalOpen(false);
                setName('');
            }
        }
    };

    return (
        <Modal
            className='customModal'
            centered
            title={item ? 'Edit Practice Area' : 'Add Practice area'}
            open={isModalOpen}
            onOk={() => {
                setIsModalOpen(false);
                setName('');
            }}
            onCancel={() => {
                setIsModalOpen(false);
                setName('');
            }}
            footer={null}
        >
            <div className='post-modal-options-grid2'>
                <label>
                    <p>Practice area name:</p>
                    <Input
                        placeholder='Practice area'
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </label>
            </div>
            <Button type='primary' onClick={() => submit()}>
                <p>{item ? 'Update' : 'Add'}</p>
            </Button>
        </Modal>
    );
};

export default PracticeAreasModal;
