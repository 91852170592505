import Axios from 'axios';
import * as Types from './types';
import { message } from 'antd';

let { REACT_APP_API_URI } = process.env;

if (process.env.NODE_ENV == 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

export const createAvailability = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/availability/createAvailability`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    return resolve(false);
                }
            });
    });
};

export const updateAvailability = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/availability/updateAvailability`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    return resolve(false);
                }
            });
    });
};

export const getAvailability = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.get(`${REACT_APP_API_URI}/api/availability/getAvailability`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    console.log('got in availabilities', res.data.availabilities);
                    dispatch({
                        type: Types.GET_AVAILABILITY,
                        payload: {
                            availabilities: res.data.availabilities
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    return resolve(false);
                }
            });
    });
};

export const getViewAvailability = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/availability/getViewAvailability`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types.GET_VIEW_AVAILABILITY,
                        payload: {
                            viewAvailabilities: res.data.viewAvailabilities
                        }
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    return resolve(false);
                }
            });
    });
};

export const createDoctorAvailability = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/doctors/createUserAvailability`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success('Successfully created Availability');
                    dispatch({
                        type: Types.CLEAR_AVAILABILITY_ERROR
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response);
                    if (error.response.data) {
                        dispatch({
                            type: Types.AVAILABILITY_ERROR,
                            payload: {
                                errors: error.response.data.errorsArr
                                    ? error.response.data.errorsArr
                                    : [],
                                errorMsg: error.response.message
                                    ? error.response.message
                                    : 'Server error'
                            }
                        });
                    }
                }
                return resolve(false);
            });
    });
};

export const createUserAvailability = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/availability/createUserAvailability`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    message.success('Successfully created Availability');
                    dispatch({
                        type: Types.CLEAR_AVAILABILITY_ERROR
                    });
                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response);
                    if (error.response.data) {
                        dispatch({
                            type: Types.AVAILABILITY_ERROR,
                            payload: {
                                errors: error.response.data.errorsArr
                                    ? error.response.data.errorsArr
                                    : [],
                                errorMsg: error.response.message
                                    ? error.response.message
                                    : 'Server error'
                            }
                        });
                    }
                }
                return resolve(false);
            });
    });
};

export const getExtractedTimeSlots = (props, history) => (dispatch) => {
    return new Promise((resolve, reject) => {
        Axios.post(`${REACT_APP_API_URI}/api/availability/getExtractedTimeSlots`, props.obj, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.auth_token
            }
        })
            .then((res) => {
                if (res) {
                    dispatch({
                        type: Types[`GET_${props.obj.userType}_EXTRACTED_TIME_SLOTS`],
                        payload: {
                            availableDays: res.data.availableDays,
                            timeSlots: res.data.timeSlots,
                            availability: res.data.availability
                        }
                    });

                    return resolve(true);
                }
            })
            .catch((error) => {
                if (error && error.response) {
                    console.log(error.response.data);
                    // message.error(error.response.data.message? error.response.data.message : "Server error");
                    // message.error(error.response.data.message[0].messages[0].message);
                    return resolve(false);
                }
            });
    });
};
