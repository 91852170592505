import React, { useState, useEffect } from 'react';
import '../Appointments/appointment.css';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as documentActions from '@/actions/documentActions';
import DocumentHeader from './DocumentHeader';
import ShareDocumentModal from './modal/ShareDocumentModal';
import EditShareDocumentModal from './modal/EditShareDocumentModal';
import Table from '../Table';
import ViewDocumentModal from './modal/ViewDocumentModal';
import Axios from 'axios';
import FileDownload from 'js-file-download';
import { Button, Pagination, Space, Tag, Tooltip } from 'antd';
import moment from 'moment';
import PageHeader from '../PageHeader';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import { LockOutlined } from '@ant-design/icons';
import DocumentLockModal from './modal/DocumentLockModal';

let { REACT_APP_API_URI } = process.env;

if (process.env.NODE_ENV === 'development') {
    REACT_APP_API_URI = 'http://localhost:5000';
}

const Documents = (props) => {
    const { userId, userType } = useSelector((state) => state.auth);
    const { documentList, documentListCount, areDocumentsLocked } = useSelector(
        (state) => state.documents
    );

    console.log('locked', areDocumentsLocked);
    const [isLocked, setIsLocked] = useState(true);
    const [viewDocument, setViewDocument] = useState(null);
    const [editDocument, setEditDocument] = useState(null);
    const [open, setOpen] = useState(false);
    const [document, setDocument] = useState(false);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState('');
    const [date, setDate] = useState('');
    const [sortBy, setSortBy] = useState('Newest First');

    useEffect(() => {
        getDocumentTypes();
    }, []);

    useEffect(() => {
        if (!areDocumentsLocked) {
            getDocumentList();
        }
    }, [search, date, sortBy, areDocumentsLocked]);

    const getDocumentList = () => {
        let obj = {
            page: page,
            perPage: limit
        };
        if (search != '') {
            obj.search = search;
        }
        if (date != '') {
            obj.date = moment(date).format('DD/MM/YY');
        }
        if (sortBy != '') {
            obj.sortBy = sortBy;
        }
        props.documentActions.getDocumentList({ ...props, obj }, props.history);
    };

    const getDocumentTypes = () => {
        let hospitalId = null;
        let pharmacyId = null;
        if (userType === 'HOSPITAL') {
            hospitalId = userId;
        } else if (userType === 'DOCTOR' && props.auth.user?.hospital?._id) {
            hospitalId = props.auth.user?.hospital._id;
        } 
        
        else if (userType === 'PHARMACY') {
            pharmacyId = userId;
        }
        props.documentActions.getDocumentTypes(
            {
                hospital: hospitalId ? hospitalId : null,
                pharmacy: pharmacyId ? pharmacyId : null
            },
            props.history
        );
    };

    const downloadDocument = (document) => {
        if (document) {
            console.log(document);
            Axios({
                url: `${REACT_APP_API_URI}/api/documents/files/${document.filename}`,
                method: 'GET',
                responseType: 'blob'
            }).then((res) => FileDownload(res.data, `${document.originalname}`));
        }
    };

    const deleteDocument = async (document) => {
        if (document) {
            let obj = {
                docId: document.document && document.document._id && document.document._id
            };
            let deleteDoc = await props.documentActions.deleteDocument(
                { ...props, obj },
                props.history
            );
            if (deleteDoc) {
                getDocumentList();
            }
        }
    };

    const columns = [
        {
            title: <span className='dragHandler'>Date</span>,
            width: 140,
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.updatedAt) return -1; // Handle empty values
                if (!b.updatedAt) return 1; // Handle empty values
                return a.updatedAt.localeCompare(b.updatedAt);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 140 }
                };
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {record.updatedAt &&
                            new Date(record.updatedAt).toLocaleDateString('en-us', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric'
                            })}
                    </div>
                );
            }
        },
        {
            title: <span className='dragHandler'>Time</span>,
            width: 100,
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            },
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.updatedAt) return -1; // Handle empty values
                if (!b.updatedAt) return 1; // Handle empty values
                return a.updatedAt.localeCompare(b.updatedAt);
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {record.updatedAt &&
                            new Date(record.updatedAt).toLocaleTimeString('en-us', {
                                hour: 'numeric',
                                minute: 'numeric'
                            })}
                    </div>
                );
            }
        },
        {
            title: <span className='dragHandler'>Document Name</span>,
            dataIndex: 'document',
            width: 500,
            onHeaderCell: () => {
                return {
                    style: {
                        whiteSpace: 'nowrap',
                        minWidth: 500,
                        textOverflow: 'ellipsis',
                        overflow: 'hidden'
                    }
                };
            },
            render: (document) => {
                return <div>{document && document.originalname}</div>;
            }
        },
        {
            title: <span className='dragHandler'>Size (KB)</span>,
            dataIndex: 'document',
            width: 100,
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'nowrap', minWidth: 100 }
                };
            },
            render: (document) => {
                return <div>{document && document.size / 1000}</div>;
            }
        },
        {
            title: <span className='dragHandler'>Shared By</span>,
            dataIndex: 'sharerEmail',
            width: 200,
            render: (sharerEmail, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <Tag>{sharerEmail}</Tag>;
            }
        },
        {
            title: <span className='dragHandler'>Shared With</span>,
            dataIndex: 'receiverEmails',
            width: 200,
            render: (receiverEmails, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div style={{ display: 'flex', gap: 5 }}>
                        {receiverEmails?.map((email) => (
                            <Tag>{email}</Tag>
                        ))}
                    </div>
                );
            }
        },
        {
            title: <span className='dragHandler'>Action</span>,
            render: (_, record) => {
                if (isEmptyObject(record)) {
                    return null;
                }
                return (
                    <Space>
                        {userId && record.sharer == userId && (
                            <>
                                <Tooltip title='Edit'>
                                    <Button type='default' onClick={() => setEditDocument(record)}>
                                        Edit
                                    </Button>
                                </Tooltip>
                                <Tooltip title='Delete'>
                                    <Button type='default' onClick={() => deleteDocument(record)}>
                                        Delete
                                    </Button>
                                </Tooltip>
                            </>
                        )}
                        <Button type='default' onClick={() => setViewDocument(record)}>
                            View
                        </Button>
                    </Space>
                );
            }
        }
    ];

    const numberOfBlankRows = limit - documentList.length;
    let dataSource = [
        ...documentList,
        ...Array.from({ length: numberOfBlankRows }).map(() => ({}))
    ];

    return (
        <div>
            <PageHeader heading={'Documents'} />
            <DocumentHeader
                open={open}
                setOpen={setOpen}
                type={'docs'}
                search={search}
                setSearch={setSearch}
                date={date}
                setDate={setDate}
                sortBy={sortBy}
                setSortBy={setSortBy}
            />
            <div style={{ position: 'relative' }}>
                <Table tableName='Documents' columns={columns} data={dataSource} />
                {areDocumentsLocked && (
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: 'rgba(255,255,255,1)',
                            // filter: 'blur(12px)',
                            zIndex: 1,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <DocumentLockModal isLocked={areDocumentsLocked} />
                    </div>
                )}
            </div>

            {open && (
                <ShareDocumentModal
                    document={document}
                    setDocument={setDocument}
                    open={open}
                    page={page}
                    limit={limit}
                    setOpen={setOpen}
                    {...props}
                />
            )}

            {editDocument && (
                <EditShareDocumentModal
                    document={document}
                    setDocument={setDocument}
                    open={open}
                    page={page}
                    limit={limit}
                    setOpen={setOpen}
                    setEditDocument={setEditDocument}
                    editDocument={editDocument}
                    {...props}
                />
            )}

            {viewDocument && (
                <ViewDocumentModal
                    {...props}
                    downloadDocument={downloadDocument}
                    viewDocument={viewDocument}
                    setViewDocument={setViewDocument}
                />
            )}

            <Pagination
                style={{ marginTop: 20 }}
                showQuickJumper
                current={page}
                total={documentListCount}
                onChange={(val) => setPage(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    documents: state.documents,
    documentList: state.documents && state.documents.documentList
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    documentActions: bindActionCreators(documentActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Documents);
