import { Button, Divider, Progress, Space } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const ChooseNewPlan = ({ openPmModal, openSubscriptionChangeModal, openSubscriptionCancelModal }) => {
    const { userProfile } = useSelector((state) => state.auth);
    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 10,
                alignItems: 'flex-start',
                flex: 1,
                backgroundColor: 'white',
                border: '1px solid #f0f0f0',
                paddingBottom: 0,
                borderRadius: 4
            }}
        >
            <div
                style={{
                    display: 'flex',
                    gap: 10,
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                    padding: 20,
                    borderBottom: '1px solid #f0f0f0'
                }}
            >
                <h3 style={{ fontSize: 20, color: 'rgba(0,0,0,0.7)', fontWeight: 500 }}>
                    Change Plans
                </h3>
                <Space>
                    <Button type='primary' onClick={openPmModal}>
                        Payment Method
                    </Button>
                    <Divider type='vertical' style={{ backgroundColor: 'rgba(0,0,0,0.2)' }} />
                    <Button type='primary' onClick={openSubscriptionChangeModal}>
                        Plan
                    </Button>
                </Space>
            </div>
         
            <div
                style={{
                    width: '100%',
                    backgroundColor: '#fafafa',
                    padding: 20,
                    marginTop: 'auto',
                    display: 'flex',
                    justifyContent: 'flex-end',
                }}
            >
                <Button onClick={openSubscriptionCancelModal}>Cancel Subscription</Button>
            </div>
        </div>
    );
};

export default ChooseNewPlan;
