import React, { useState, useEffect } from 'react';
// import './management.css';
import '@/assets/css/favourites.css';
import './Members/doctorModals.css';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as generalActions from '@/actions/generalActions';
import * as branchActions from '@/actions/branchActions';

import ServicesModal from './Management/ServicesModal';
import RolesModal from './Management/RolesModal';
import PracticeAreasModal from './Management/PracticeAreasModal';
import DocumentTypesModal from './Management/DocumentTypesModal';
import { Pagination } from 'antd';
import ManagementHeader from './Management/ManagementHeader';
import ManagementTable from './Management/Table';
import PageHeader from '../Shared/PageHeader';

const Management = (props) => {
    const { userType } = useSelector((state) => state.auth);
    const { location, history } = props;
    const [activeTab, setActiveTab] = useState('Services');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPage, setTotalPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [items, setItems] = useState([]);
    const [count, setCount] = useState(0);
    const [specRoles, setSpecRoles] = useState({});
    const [practiceAreas, setPracticeAreas] = useState({});
    const [hospitalServices, setHospitalServices] = useState({});
    const [roleData, setRoleData] = useState([]);
    const [practiceAreasData, setPracticeAreasData] = useState([]);
    const [hospitalServicesData, setHospitalServicesData] = useState([]);
    const [itemToBeUpdated, setItemToBeUpdated] = useState(null);
    const [documentTypesData, setDocumentTypesData] = useState([]);

    useEffect(() => {
        // get tab from query and setActiveTab
        const urlParams = new URLSearchParams(location.search);
        const tab = urlParams.get('tab');
        if (tab) {
            if (tab !== 'Services' && userType !== 'HOSPITAL')
                return history.push(`/${userType.toLowerCase()}/management?tab=Services`);
            setActiveTab(tab);
        }
    }, []);

    useEffect(() => {
        const { specializedRoles, practiceAreas, hospitalServices, documentTypes } = props.general;
        let specRole, practAreas, hospServies, docTypes;
        if (specializedRoles) {
            setSpecRoles(specializedRoles);
            specRole =
                specializedRoles.specializedRoles &&
                specializedRoles.specializedRoles.map((item, index) => {
                    return { roleName: item, id: index + 1 };
                });
            setRoleData(specRole);
        }

        if (practiceAreas) {
            setPracticeAreas(practiceAreas);
            practAreas =
                practiceAreas.practiceAreas &&
                practiceAreas.practiceAreas.map((item, index) => {
                    return { practiceArea: item, id: index + 1 };
                });
            setPracticeAreasData(practAreas);
        }
        if (hospitalServices) {
            setHospitalServices(hospitalServices);
            hospServies =
                hospitalServices &&
                hospitalServices.map((item, index) => {
                    return {
                        name: item.name,
                        rate: item.rate,
                        branches: item.branches,
                        _id: item._id,
                        id: index + 1
                    };
                });
            setHospitalServicesData(hospServies);
        }

        if (documentTypes) {
            setDocumentTypesData(documentTypes);
        }

        shuffleItems(specRole, practAreas, hospServies, documentTypes);
    }, [props.general, props.specializedRoles, props.hospitalServices, props.practiceAreas]);

    const shuffleItems = async (specRole = [], practAreas = [], lawServices = []) => {
        let tabData = [];
        if (activeTab == 'Services') {
            tabData = lawServices;
        }
        if (activeTab == 'Roles') {
            tabData = specRole;
        }
        if (activeTab == 'Practice Areas') {
            tabData = practAreas;
        }
        if (activeTab == 'Document Types') {
            tabData = documentTypesData;
        }
        setCount(tabData.length);
        setTotalPage(Math.ceil(tabData.length / limit));
        let indexOfLastItem = pageNumber * limit;
        let indexOfFirstitem = indexOfLastItem - limit;
        setItems(tabData.slice(indexOfFirstitem, indexOfLastItem));
    };

    useEffect(() => {
        shuffleItems(roleData, practiceAreasData, hospitalServicesData, documentTypesData);
    }, [
        pageNumber,
        activeTab,
        limit,
        props.general,
        roleData,
        practiceAreasData,
        hospitalServicesData,
        documentTypesData
    ]);

    const handleActveTab = (tab) => {
        setActiveTab(tab);
        history.push(`/${userType.toLowerCase()}/management?tab=${encodeURIComponent(tab)}`);
    };
    let tabList = ['Services'];
    if (userType === 'HOSPITAL') {
        tabList = [...tabList, 'Roles', 'Practice Areas', 'Document Types'];
    }

    const handleModal = () => {
        setIsModalOpen(true);
    };

    const deleteSpecializedRole = async (item) => {
        let arr = [...roleData];
        arr = arr
            .filter((it) => it.roleName != item.roleName && it.id != item.id)
            .map((item) => item.roleName);

        let obj = {
            userId: props.auth.userId,
            specializedRoles: arr
        };

        let updatePA = await props.generalActions.updateSpecializedRoles(
            { ...props, obj },
            props.history
        );
        if (updatePA) {
            await props.generalActions.getSpecializedRoles({ ...props, obj });
            setPageNumber(1);
        }
    };

    const deletePracticeArea = async (item, index) => {
        let arr = [...practiceAreasData];
        arr = arr.filter((it, ind) => it.practiceArea != item.practiceArea && it.id != item.id);
        console.log(arr);
        arr = arr.map((item, index) => item.practiceArea);
        console.log(arr);
        let obj = {
            userId: props.auth.userId,
            practiceAreas: arr
        };
        let updatePA = await props.generalActions.updatePracticeAreas(
            { ...props, obj },
            props.history
        );
        if (updatePA) {
            await props.generalActions.getPracticeAreas({ ...props, obj });
            setPageNumber(1);
        }
    };

    const deleteHospitalService = async (item, index) => {
        let obj = {
            userId: props.auth.userId,
            branch: item.branch,
            hospitalServiceId: item._id,
            [userType.toLowerCase()]: props.auth && props.auth.userId && props.auth.userId,
            userType: props.auth && props.auth.userType && props.auth.userType,
            hospital: props.auth?.user?.hospital || props.auth.user._id

        };
        let updateLS = await props.generalActions.deleteHospitalService(
            { ...props, obj },
            props.history
        );
        if (updateLS) {
            await props.generalActions.getHospitalServices({ ...props, obj });
            setPageNumber(1);
        }
    };

    const deleteDocumentType = async (item) => {
        let obj = {
            documentTypeId: item._id,
            lawfirm: props.auth && props.auth.userId && props.auth.userId
        };
        let updateLS = await props.generalActions.deleteDocumentType(
            { ...props, obj },
            props.history
        );
        if (updateLS) {
            await props.generalActions.getDocumentTypes({ ...props, obj });
            setPageNumber(1);
        }
    };

    useEffect(() => {
        if (!isModalOpen) {
            setItemToBeUpdated(null);
        }
    }, [isModalOpen]);

    return (
        <div className='management-main'>
            <PageHeader heading={'Management'} />
            {activeTab === 'Services' && isModalOpen ? (
                <ServicesModal
                    branches={props.branch && props.branch.branches && props.branch.branches}
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    item={itemToBeUpdated}
                    setItem={setItemToBeUpdated}
                    {...props}
                />
            ) : activeTab == 'Roles' && isModalOpen ? (
                <RolesModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    {...props}
                    roleData={roleData}
                    setRoleData={setRoleData}
                    item={itemToBeUpdated}
                    setItem={setItemToBeUpdated}
                />
            ) : activeTab === 'Practice Areas' && isModalOpen ? (
                <PracticeAreasModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    {...props}
                    practiceAreasData={practiceAreasData}
                    setPracticeAreasData={setPracticeAreasData}
                    item={itemToBeUpdated}
                    setItem={setItemToBeUpdated}
                />
            ) : activeTab === 'Document Types' && isModalOpen ? (
                <DocumentTypesModal
                    isModalOpen={isModalOpen}
                    setIsModalOpen={setIsModalOpen}
                    item={itemToBeUpdated}
                    setItem={setItemToBeUpdated}
                    {...props}
                />
            ) : null}
            <ManagementHeader
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                handleActveTab={handleActveTab}
                setPageNumber={setPageNumber}
                tabList={tabList}
                handleModal={handleModal}
                history={history}
                userType={userType}
            />

            <ManagementTable
                deleteRole={deleteSpecializedRole}
                deletePA={deletePracticeArea}
                deleteLS={deleteHospitalService}
                deleteDT={deleteDocumentType}
                data={items}
                type={activeTab}
                item={itemToBeUpdated}
                setItem={setItemToBeUpdated}
                isModalOpen={isModalOpen}
                setOpen={setIsModalOpen}
                limit={limit}
            />

            <Pagination
                style={{ marginTop: 20 }}
                current={pageNumber}
                total={count}
                onChange={(val) => setPageNumber(val)}
                pageSize={limit}
                pageSizeOptions={[5, 10, 20]}
                showSizeChanger={true}
                responsive={true}
                onShowSizeChange={(size, newSize) => setLimit(newSize)}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    general: state.general,
    specializedRoles:
        state.general && state.general.specializedRoles && state.general.specializedRoles,
    practiceAreas: state.general && state.general.practiceAreas && state.general.practiceAreas,
    branch: state.branch,
    branches: state.branch && state.branch.branches && state.branch.branches
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    generalActions: bindActionCreators(generalActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Management);
