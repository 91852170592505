import React from 'react';
import { Button, Row, Col } from 'antd';

const NotificationsHeader = () => {
    return (
        <Row gutter={[10, 10]} style={{ marginBottom: 20, justifyContent: 'flex-end' }}>
            <Col xs={24} lg={4}>
                <Button
                    style={{ flex: 1, display: 'flex', justifyContent: 'center', width: '100%' }}
                    type='default'
                >
                    Archive All
                </Button>{' '}
            </Col>
            <Col xs={24} lg={4}>
                {' '}
                <Button
                    style={{ flex: 1, display: 'flex', justifyContent: 'center', width: '100%' }}
                    type='default'
                >
                    Mark All as Read
                </Button>
            </Col>
        </Row>
    );
};

export default NotificationsHeader;
