import React, { useState, useEffect } from 'react';
import { connect, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as reviewActions from '@/actions/reviewActions';
import { Button, Select, Tooltip } from 'antd';
import ReactStars from 'react-rating-stars-component';
import PageHeader from '../PageHeader';
import Table from '@/components/NewProfile/Shared/Table';
import ReviewsHeader from './ReviewsHeader';
import { isEmptyObject } from '@/components/Utilities/ObjectUtils';
import useWindowSize from '@/hooks/useWindowSize';

const Reviews = (props) => {
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(1);
    const { width } = useWindowSize();
    const { reviews, reviewsCount } = useSelector((state) => state.reviews);

    useEffect(() => {
        let obj = {
            page: page,
            perPage: limit
        };
        props.reviewActions.getMyReviews({ ...props, obj }, props.history);
    }, []);

    const approveReview = async (reviewId) => {
        let obj = {
            reviewId
        };
        let approved = await props.reviewActions.approveReview({ ...props, obj }, props.history);
        if (approved) {
            await props.reviewActions.getMyReviews(props, props.history);
        }
    };

    const archiveReview = async (reviewId) => {
        let obj = {
            reviewId
        };
        let rejected = await props.reviewActions.archiveReview({ ...props, obj }, props.history);
        if (rejected) {
            await props.reviewActions.getMyReviews(props, props.history);
        }
    };

    const columns = [
        {
            title: <span className='dragHandler'>Date</span>,
            width: 150,
            dataIndex: 'createdDate',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.createdDate) return -1; // Handle empty values
                if (!b.createdDate) return 1; // Handle empty values
                return a.createdDate.localeCompare(b.createdDate);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <div>
                        {new Date(date).toLocaleDateString('en-us', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric'
                        })}
                    </div>
                );
            }
        },
        {
            title: <span className='dragHandler'>Doctor | Hospital</span>,
            width: 300,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                let fullName = '';
                const { reviewedUserType, reviewedUser } = record;
                if (
                    (reviewedUserType == 'Doctor' || reviewedUserType == 'Nurse') &&
                    record.reviewedUser
                ) {
                    if (record.reviewedUser.firstName && record.reviewedUser.lastName) {
                        fullName =
                            record.reviewedUser.firstName + ' ' + record.reviewedUser.lastName;
                    }
                }
                if (reviewedUserType == 'Hospital' && record.reviewedUser) {
                    fullName = record.reviewedUser.hospitalName;
                }

                return <div>{fullName}</div>;
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 150 }
                };
            }
        },
        {
            title: <span className='dragHandler'>Short</span>,
            width: 300,
            dataIndex: 'shortDescription',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.shortDescription) return -1; // Handle empty values
                if (!b.shortDescription) return 1; // Handle empty values
                return a.shortDescription.localeCompare(b.shortDescription);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 120 }
                };
            },
            render: (shortDescription, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <div>{shortDescription}</div>;
            }
        },
        {
            title: <span className='dragHandler'>Review</span>,
            width: width > 1000 ? 600 : 500,
            dataIndex: 'description',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.description) return -1; // Handle empty values
                if (!b.description) return 1; // Handle empty values
                return a.description.localeCompare(b.description);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 200 }
                };
            },
            render: (description, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return <div>{description}</div>;
            }
        },

        {
            title: <span className='dragHandler'>Rating</span>,
            width: 120,
            dataIndex: 'rating',
            sorter: (a, b) => {
                if (isEmptyObject(a) || isEmptyObject(b)) {
                    return 0; // Return 0 for empty objects to indicate no sorting
                }
                if (!a.rating) return -1; // Handle empty values
                if (!b.rating) return 1; // Handle empty values
                return a.rating.localeCompare(b.rating);
            },
            onHeaderCell: () => {
                return {
                    style: { whiteSpace: 'pre-wrap', minWidth: 100 }
                };
            },
            render: (date, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 23 }} />;
                return (
                    <Tooltip title={item.rating}>
                        <ReactStars
                            style={{ justifyContent: 'center !important' }}
                            value={item.rating}
                            edit={false}
                            count={5}
                            size={15}
                            activeColor='var(--primary)'
                        />
                    </Tooltip>
                );
            }
        },

        {
            title: <span className='dragHandler'>Action</span>,
            render: (_, record) => {
                if (isEmptyObject(record)) return <div style={{ height: 22 }}></div>;
                if (
                    record.reviewStatus == 'PENDING' &&
                    record.reviewedUser &&
                    record.reviewedUser._id ==
                        (props.auth && props.auth.user && props.auth.user._id)
                ) {
                    return (
                        <div>
                            <Tooltip title='Archive Review'>
                                <Button
                                    type='default'
                                    onClick={() => {
                                        archiveReview(record._id);
                                    }}
                                >
                                    {' '}
                                    Archive
                                </Button>
                            </Tooltip>{' '}
                            <Tooltip title='Approve Review'>
                                <Button
                                    type='default'
                                    onClick={() => {
                                        approveReview(record._id);
                                    }}
                                ></Button>
                            </Tooltip>
                        </div>
                    );
                } else {
                    return <div>No action available</div>;
                }
            }
        }
    ];

    const numberOfBlankRows = limit - reviews.length;
    let dataSource = [...reviews, ...Array.from({ length: numberOfBlankRows }).map(() => ({}))];
    return (
        <div>
            <PageHeader heading={'Reviews'} />
            <ReviewsHeader />

            <Table
                tableName='Reviews'
                columns={columns}
                data={dataSource}
                pagination={{
                    current: page,
                    total: reviewsCount,
                    onChange: (val) => setPage(val),
                    pageSize: limit,
                    pageSizeOptions: [5, 10, 20],
                    showSizeChanger: true,
                    responsive: true,
                    onShowSizeChange: (size, newSize) => setLimit(newSize)
                }}
            />
        </div>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth,
    reviews: state.reviews
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    reviewActions: bindActionCreators(reviewActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
