import React, { useEffect, useState } from 'react';
import './meeting.css';
import { useDispatch, useSelector } from 'react-redux';
import * as appointmentActions from '@/actions/appointmentActions';
import { useHistory, useParams } from 'react-router-dom';

function MeetingEnded(props) {
    const history = useHistory();
    let { appointmentId } = useParams();
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const { singleAppointment } = useSelector((state) => state.appointments);
    const {
        user: { firstName, lastName, userType, _id: userId }
    } = useSelector((state) => state.auth);

    const getSingleAppointment = async () => {
        setLoading(true);
        let obj = {
            appointmentId: appointmentId
        };
        await dispatch(appointmentActions.getSingleAppointment({ ...props, obj }));
        setLoading(false);
    };

    useEffect(() => {
        getSingleAppointment();
    }, []);

    const completeAppointment = async () => {
        let obj = {
            userType,
            userId,
            appointmentId
        };
        let complete = await dispatch(appointmentActions.completeAppointment({ ...props, obj }));

        if (complete) {
            getSingleAppointment();
        }
    };

    useEffect(() => {
        // let obj = {
        //   userType,
        //   userId,
        //   appointmentId
        // }
        // let complete = await dispatch(
        //   appointmentActions.completeAppointment({ ...props, obj })
        // )
    }, []);

    return (
        <div className='meetingEnded'>
            <div className='centerBox'>
                {loading ? (
                    <p>Loading...</p>
                ) : (
                    <>
                        {singleAppointment && (
                            <>
                                {userType == "DOCTOR" ? (
                                    <>
                                        <h4>
                                            Meeting with Client{' '}
                                            {`${singleAppointment?.user?.firstName}${' '}${
                                                singleAppointment?.user?.lastName
                                            }`}{' '}
                                            has Ended
                                        </h4>
                                        {singleAppointment?.appointmentStatusOfDoctor ==
                                            'NO SHOW' && (
                                            <p>
                                                You have been marked as{' '}
                                                <strong
                                                    style={{
                                                        padding: 5,
                                                        backgroundColor: 'red',
                                                        color: 'white',
                                                        borderRadius: 4
                                                    }}
                                                >
                                                    No Show
                                                </strong>
                                            </p>
                                        )}
                                    </>
                                ) : (
                                    userType == "PATIENT" && (
                                        <>
                                            <h4>
                                                Meeting with Doctor{' '}
                                                {`${singleAppointment?.doctor?.firstName}${' '}${
                                                    singleAppointment?.doctor?.lastName
                                                }`}{' '}
                                                has Ended
                                            </h4>
                                            {singleAppointment?.appointmentStatusOfClient ==
                                                'NO SHOW' && (
                                                <p>
                                                    You have been marked as{' '}
                                                    <strong
                                                        style={{
                                                            padding: 5,
                                                            backgroundColor: 'red',
                                                            color: 'white',
                                                            borderRadius: 4
                                                        }}
                                                    >
                                                        No Show
                                                    </strong>
                                                </p>
                                            )}
                                        </>
                                    )
                                )}
                            </>
                        )}

                        {(userType == "DOCTOR" &&
                            singleAppointment?.appointmentStatus == 'PENDING' &&
                            singleAppointment?.appointmentStatusOfDoctor != 'COMPLETED' &&
                            singleAppointment?.appointmentStatusOfDoctor != 'CANCELLED') ||
                        (userType == "PATIENT" &&
                            singleAppointment?.appointmentStatus == 'PENDING' &&
                            singleAppointment?.appointmentStatusOfClient != 'COMPLETED' &&
                            singleAppointment?.appointmentStatusOfClient != 'CANCELLED') ? (
                            <div
                                style={{
                                    display: 'flex',
                                    columnGap: 10,
                                    flexWrap: 'wrap',
                                    marginTop: 50
                                }}
                            >
                                <button onClick={() => completeAppointment()}>
                                    Mark meeting as complete?
                                </button>

                                <button
                                    onClick={() =>
                                        history.push(
                                            `/${
                                                userType == "DOCTOR"
                                                    ? 'doctor'
                                                    : userType == "PATIENT" && 'user'
                                            }/appointments?tab=list`
                                        )
                                    }
                                >
                                    Go back to Appointments list
                                </button>
                            </div>
                        ) : (
                            <button
                                style={{ marginTop: 50 }}
                                className='ant-btn ant-btn-primary'
                                onClick={() =>
                                    history.push(
                                        `/${
                                            userType == "DOCTOR"
                                                ? 'doctor'
                                                : userType == "PATIENT" && 'user'
                                        }/appointments?tab=list`
                                    )
                                }
                            >
                                Go back to Appointments list
                            </button>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}

export default MeetingEnded;
