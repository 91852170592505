import React from 'react';
import { Checkbox } from 'antd';
import { PharmacyTypes } from '../../../assets/json/PharmacyTypes';

function RightSide(props) {
    const specializedRole = [
        { value: 'Senior-Partner', id: 1 },
        { value: 'Partner', id: 2 },
        { value: 'Assosiate', id: 3 },
        { value: 'Medical-Assistant', id: 4 },
        { value: 'Law-Clerk', id: 5 }
    ];
    return (
        <div className='rightSide'>
            <label className='inputCont'>
                Consultation
                <Checkbox value={'Free'}>Free</Checkbox>
                <Checkbox value={'Online'}>Online</Checkbox>
                <Checkbox value={'In-Person'}>In-Person</Checkbox>
            </label>
            <label className='inputCont'>
                Pharmacy Types
                {PharmacyTypes &&
                    PharmacyTypes.map((item, index) => (
                        <Checkbox key={item} value={item}>
                            {item}
                        </Checkbox>
                    ))}
            </label>
        </div>
    );
}

export default RightSide;
