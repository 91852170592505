export const getPhoneNumber = (user) => {
    let phoneNo = user?.phoneNo;
    let countryCode = user?.countryCode;

    if (user.contact && user.contact.phoneNo) {
        phoneNo = user.contact.phoneNo;
        countryCode = user.contact.countryCode;
    } else if (user.contact && user.contact.billing && user.contact.billing.billingPhone) {
        phoneNo = user.contact.billing.billingPhoneNo;
        countryCode = user.contact.billing.billingCountryCode;
    }

    return {
        formatted:
            countryCode && phoneNo ? `(${countryCode}) ${phoneNo}` : 'No Phone Number Provided',
        phone: countryCode && phoneNo ? `${countryCode}${phoneNo}` : null
    };
};
