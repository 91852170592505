import React from 'react';
import { Select, Col, Row, Button, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

const { Option } = Select;
const dateFormat = 'YYYY/MM/DD';
const sortby = ['Oldest First', 'Newest First'];

const sortOrder = ['Ascending', 'Descending'];

const LocationHeader = (props) => {
    const { addBranch, history } = props;
    return (
        <Row gutter={[10, 10]} style={{marginBottom: 20, justifyContent: 'flex-end'}}>
            <Col xs={24} lg={6}>
                <Input placeholder='Search by Location address, city, country or email' />
            </Col>

            <Col xs={24} lg={6}>
                <Select placeholder='Sort By' showSearch style={{ width: '100%' }}>
                    {sortby.map((item, index) => (
                        <Option key={index} value={item}>
                            {item}
                        </Option>
                    ))}
                </Select>
            </Col>

            <Col xs={24} lg={4}>
                <Button style={{width: '100%'}} onClick={() => addBranch()} icon={<PlusOutlined />} type='primary'>
                    {'Add Location'}
                </Button>
            </Col>
        </Row>
    );
};

export default LocationHeader;
