import * as Types from '../actions/types';
const init = {
    prospects: [],
    prospectsCount: 0
};

const prospectReducer = (state = init, action) => {
    switch (action.type) {
        case Types.GET_PROSPECTS: {
            return {
                ...state,
                prospects: action.payload.prospects,
                prospectsCount: action.payload.prospectsCount
            };
        }

        default:
            return state;
    }
};

export default prospectReducer;
