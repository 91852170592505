import React from 'react';

class ConnectionStatus extends React.Component {
    render() {
        const { remaining } = this.props;
        let status = this.props.connected ? 'Connected' : 'Disconnected';
        return (
            <div className='connectionStatus'>
                <strong>Status:</strong> {status}
                <small>{` (Time left: ${remaining ? remaining : 0}  minute(s).)`}</small>
            </div>
        );
    }
}
export default ConnectionStatus;
