import { Modal, Select, ColorPicker, Space, Input, Button, Radio } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import OutputScreen from '../Output';

const { Option } = Select;

const initialState = {
    text: '',
    style: {
        fontFamily: 'Inter Var',
        fontSize: '14px',
        color: '#000',
        fontWeight: 400
    }
};
const UpdateContent = ({ content, open, onClose, type, contentType, toggleContentType, save }) => {
    const [data, setData] = useState({
        title: { ...initialState },
        header: { ...initialState },
        grabber: { ...initialState },
        line1: { ...initialState },
        line2: { ...initialState },
        line3: { ...initialState },
        line4: { ...initialState },
        image: '',
        backgroundColor: '#fff'
    });
    const fileInputRef = useRef();
    const handleColorChange = (item, color) => {
        setData((prevData) => ({
            ...prevData,
            [item]: {
                ...prevData[item],
                style: {
                    ...prevData[item].style,
                    color: '#' + color.toHex()
                }
            }
        }));
    };

    const handleFontSizeChange = (item, fontSize) => {
        setData((prevData) => ({
            ...prevData,
            [item]: {
                ...prevData[item],
                style: {
                    ...prevData[item].style,
                    fontSize
                }
            }
        }));
    };

    const handleFontWeightChange = (item, fontWeight) => {
        setData((prevData) => ({
            ...prevData,
            [item]: {
                ...prevData[item],
                style: {
                    ...prevData[item].style,
                    fontWeight
                }
            }
        }));
    };

    const handleBackgroundColorChange = (item, color) => {
        setData((prevData) => ({
            ...prevData,
            backgroundColor: '#' + color.toHex()
        }));
    };

    useEffect(() => {
        setData((prev) => ({ ...prev, ...content }));
    }, [content]);

    const fontSizes = ['12px', '14px', '16px', '20px', '24px', '28px', '32px'];
    const fontWeights = ['100', '200', '300', '400', '500', '600', '700', '800', '900'];
    return (
        <Modal
            centered
            open={open}
            onCancel={onClose}
            title={`Update ${type || ''} Content`}
            footer={() => (
                <Space>
                    <Button type='default' onClick={onClose}>Cancel</Button>
                    <Button
                        type='primary'
                        onClick={async () => {
                            await save(type, data, contentType);
                            onClose();
                        }}
                    >
                        Save
                    </Button>
                </Space>
            )}
        >
            <div style={{ marginTop: 20 }}>
                <div
                    style={{
                        fontWeight: 600
                    }}
                >
                    Preview
                </div>
                <OutputScreen inputs={data} contentType={contentType} />

                <div style={{ marginBottom: 10 }}>
                    <div style={{ fontWeight: 600 }}>Content Type:</div>
                    <Radio.Group value={contentType} onChange={toggleContentType}>
                        <Radio value='Text'>Text</Radio>
                        <Radio value='Image'>Image</Radio>
                    </Radio.Group>
                </div>
                {contentType?.toLowerCase() === 'text' ? (
                    <>
                        {Object.keys(data)
                            .filter((item) => item !== 'image' && item !== 'backgroundColor')
                            .map((item) => (
                                <div key={item}>
                                    <div
                                        style={{
                                            fontWeight: 600
                                        }}
                                    >
                                        {item.slice(0, 1).toUpperCase() + item.slice(1)}
                                    </div>
                                    <div>
                                        <Input
                                            type='text'
                                            value={data[item].text}
                                            onChange={(e) => {
                                                const value = e.target.value;
                                                setData((prevData) => ({
                                                    ...prevData,
                                                    [item]: {
                                                        ...prevData[item],
                                                        text: value
                                                    }
                                                }));
                                            }}
                                        />
                                    </div>

                                    <Space
                                        style={{
                                            alignItems: 'center',
                                            display: 'flex',
                                            gap: 10,
                                            margin: '10px 0px',
                                            flexWrap: 'wrap'
                                        }}
                                    >
                                        <div
                                            style={{
                                                height: '100%',
                                                display: 'flex',
                                                gap: 5,
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div>Color:</div>
                                            <ColorPicker
                                                type='color'
                                                value={data[item]?.style?.color}
                                                onChange={(value) => handleColorChange(item, value)}
                                            />
                                        </div>

                                        <div
                                            style={{
                                                height: '100%',
                                                display: 'flex',
                                                gap: 5,
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div>Font Size:</div>
                                            <Select
                                                value={data[item]?.style?.fontSize}
                                                onChange={(value) =>
                                                    handleFontSizeChange(item, value)
                                                }
                                            >
                                                {fontSizes.map((item) => (
                                                    <Option value={item}>{item}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                        <div
                                            style={{
                                                height: '100%',
                                                display: 'flex',
                                                gap: 5,
                                                alignItems: 'center'
                                            }}
                                        >
                                            <div>Font Weight:</div>
                                            <Select
                                                value={data[item]?.style?.fontWeight}
                                                onChange={(value) =>
                                                    handleFontWeightChange(item, value)
                                                }
                                            >
                                                {fontWeights.map((item) => (
                                                    <Option value={item}>{item}</Option>
                                                ))}
                                            </Select>
                                        </div>
                                    </Space>
                                </div>
                            ))}
                        <div
                            style={{
                                fontWeight: 600
                            }}
                        >
                            Background Color:
                        </div>
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                gap: 5,
                                alignItems: 'center'
                            }}
                        >
                            <div>Background Color:</div>
                            <ColorPicker
                                type='color'
                                value={data['backgroundColor']}
                                onChange={(value) =>
                                    handleBackgroundColorChange('backgroundColor', value)
                                }
                            />
                        </div>
                    </>
                ) : (
                    <>
                        <div
                            style={{
                                height: '100%',
                                display: 'flex',
                                gap: 5,
                                alignItems: 'center'
                            }}
                        >
                            <input
                                ref={fileInputRef}
                                type='file'
                                style={{ display: 'none' }}
                                onChange={(e) =>
                                    setData((prev) => ({ ...prev, image: e.target.files[0] }))
                                }
                            />
                            <Button type='primary' onClick={() => fileInputRef?.current.click()}>
                                Upload
                            </Button>
                        </div>
                    </>
                )}
            </div>
        </Modal>
    );
};

export default UpdateContent;
