import React, { memo } from 'react';
import { AiOutlineUpload } from 'react-icons/ai';
import { IoSaveOutline } from 'react-icons/io5';
import { MdRemove } from 'react-icons/md';
import { Alert, Popover } from 'antd';

function ProfileImage({
    setState,
    saveProfileImage,
    deleteProfileImage,
    tempProfileImage,
    profileImage,
    genderPlaceholderImg,
    formDPDirty,
    savedProfileImage
}) {
    return (
        <div className='profileHead'>
            {console.log('hello')}
            <div className='profilePic'>
                {tempProfileImage || profileImage ? (
                    <img
                        src={tempProfileImage ? tempProfileImage : profileImage ? profileImage : ''}
                    />
                ) : (
                    <img src={genderPlaceholderImg} />
                )}
                {!tempProfileImage ? (
                    <>
                        <label
                            htmlFor='profileImage'
                            style={{
                                width: '100%',
                                position: 'absolute',
                                bottom: 0
                            }}
                        >
                            <input
                                type='file'
                                id='profileImage'
                                onChange={(e) => {
                                    if (e.target.files[0])
                                        setState({
                                            profileImage: e.target.files[0],
                                            tempProfileImage: URL.createObjectURL(
                                                e.target.files[0]
                                            ),
                                            formDPDirty: true
                                        });
                                }}
                                style={{
                                    display: 'none'
                                }}
                            />
                            <Popover title="Upload Profile Image" content='Please upload your profile image (Maximum file size: 2MB).'>
                                <div className='popoverStyle right'>
                                    <AiOutlineUpload />
                                </div>
                            </Popover>
                        </label>
                    </>
                ) : null}

                {formDPDirty && tempProfileImage && (
                    <label
                        style={{
                            width: '100%',
                            position: 'absolute',
                            bottom: 0
                        }}
                    >
                        {' '}
                        <div className=''>
                            <Popover content='Save profile image'>
                                <div onClick={saveProfileImage} className='popoverStyle right'>
                                    <IoSaveOutline />
                                </div>
                            </Popover>
                            <Popover content='Delete profile image'>
                                <div onClick={deleteProfileImage} className='popoverStyle left'>
                                    <MdRemove />
                                </div>
                            </Popover>
                        </div>
                    </label>
                )}

                {savedProfileImage && (
                    <div className=''>
                        <Popover content='Delete profile image'>
                            <div onClick={deleteProfileImage} className='popoverStyle left'>
                                <MdRemove />
                            </div>
                        </Popover>
                    </div>
                )}
            </div>
        </div>
    );
}

export default memo(ProfileImage, (prevProps, nextProps) => {
    if (prevProps.tempProfileImage !== nextProps.tempProfileImage) {
        return false;
    }
    if (prevProps.profileImage !== nextProps.profileImage) {
        return false;
    }
    if (prevProps.formDPDirty !== nextProps.formDPDirty) {
        return false;
    }
    if (prevProps.savedProfileImage !== nextProps.savedProfileImage) {
        return false;
    }
    return true;
});
