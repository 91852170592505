import React from 'react';
import { useSelector } from 'react-redux';
import placeholderCover from '@/assets/images/coverPlaceholder.jpeg';
import profilePlaceholderImg from '@/assets/images/profilePlaceholder.jpeg';

const PreviewSection = (props) => {
    const { handleSwitch, saveMarketingInfo } = props;
    const { userProfile } = useSelector((state) => state.auth);
    const { marketingInfo } = useSelector((state) => state.marketing);
    const { topBarContent, topBarContentType } = marketingInfo || {};
    let topBarStyle = {
        backgroundColor: topBarContent?.backgroundColor || 'rgba(0, 0, 0, 0.5)'
    };
    
    const lines = ['title','header', 'grabber', 'line1', 'line2', 'line3', 'line4', 'line5'];

    const renderLines = ( content ) => {
       return lines.map((line) => {
            if (content[line]) {
                return (
                    <p
                        style={{
                            ...(content?.[line].style || {}),
                            padding: '10px 0px',
                            textAlign: 'center'
                        }}
                    >
                        {content?.[line]?.text}
                    </p>
                );
            }
        })
    }

    return (
        <div
            className='marketing-preview'
            style={{
                marginBottom: 40,
                border: '1px solid #ddd',
                padding: 15,
                // backgroundColor: 'white',
                borderRadius: 4
            }}
        >
            <div className='hospital-upper'>
                {marketingInfo && marketingInfo.displayTopBar ? (
                    <div className='doctor-view-cover-split'>
                        <div className='hospital-view-img-split'>
                            {userProfile.coverImage ? (
                                <img
                                    src={
                                        userProfile.coverImage
                                            ? userProfile.coverImage
                                            : placeholderCover
                                    }
                                />
                            ) : (
                                <img src={placeholderCover} />
                            )}
                        </div>
                        <div
                            className='hospital-view-img-split2'
                            style={
                                (topBarContentType != 'image' || topBarContentType != 'Image') &&
                                topBarContent.bgColor && {
                                    backgroundColor: topBarContent.backgroundColor
                                }
                            }
                        >
                            {topBarContentType == 'image' || topBarContentType == 'Image' ? (
                                <img
                                    src={
                                        topBarContent && topBarContent.image
                                            ? topBarContent.image
                                            : profilePlaceholderImg
                                    }
                                />
                            ) : (
                                <div
                                    style={{
                                        ...topBarStyle,
                                        padding: 10,
                                        width: '100%',
                                        textAlign: 'center'
                                    }}
                                >
                                    {renderLines(topBarContent)}
                                </div>
                            )}
                        </div>
                    </div>
                ) : (
                    <div className='hospital-view-img'>
                        <img
                            src={userProfile.coverImage ? userProfile.coverImage : placeholderCover}
                        />
                    </div>
                )}

                <div className='hospital-profile-container'>
                    <div className='hospital-upper-left'>
                        <div className='hospital-image-container'>
                            <div className='hospital-veiw-image'>
                                <img
                                    src={
                                        userProfile.profileImage
                                            ? userProfile.profileImage
                                            : profilePlaceholderImg
                                    }
                                    alt='proPic'
                                    style={{
                                        height: '100%',
                                        width: '100%',
                                        objectFit: 'cover'
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div></div>
        </div>
    );
};

export default PreviewSection;
