import React, { Component, memo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as branchActions from '@/actions/branchActions';
import Currencies from '@/assets/json/Currencies.json';
import Countries from '@/assets/json/UpdatedCountries.json';
import validator from 'validator';
import { message, Tooltip, Pagination } from 'antd';
import { GetLocationUsingLatLng } from '../../IndexShared/GetLocationUsingLatLng';
import { geocodeByAddress } from 'react-places-autocomplete';
import PageHeader from '@/components/NewProfile/Shared/PageHeader';
import './location.css';
import { getLocationShorts } from '../../Utilities/LocationShorts';
import CreateLocation from './locationContent/CreateLocation';
import LocationDataTable from './locationContent/LocationDataTable';
import '../Shared/Appointments/appointment.css';
import LocationHeader from './locationContent/LocationHeader';
import UpdatedCountries from '@/assets/json/UpdatedCountries.json';
// import { languages } from 'country-data';

const { REACT_APP_API_URI } = process.env;

const gender = [
    { key: 'male', text: 'Male', value: 'Male' },
    { key: 'female', text: 'Female', value: 'Female' },
    { key: 'other', text: 'Other', value: 'Other' }
];

const Marker = ({ text }) => (
    <div
        className='marker'
        style={{
            color: 'white',
            padding: '15px 10px',
            display: 'inline-flex',
            textAlign: 'center',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '100%',
            transform: 'translate(-50%, -50%)'
        }}
    >
        <i
            className='fe fe-map-pin'
            style={{ fontSize: '30px', color: '#f1433f', position: 'relative' }}
        ></i>
    </div>
);

class Location extends Component {
    constructor(props) {
        super(props);

        this.state = {
            infoActive: false,
            profCompDiv: true,
            locality: localStorage.getItem('locality') ? localStorage.getItem('locality') : '',
            localityCountry: localStorage.getItem('localityCountry')
                ? localStorage.getItem('localityCountry')
                : '',
            formDirty: false,
            profCompDiv: true,
            accordion: false,
            modal: false,
            address: { value: '', isValid: true, message: '' },
            addressLineOne: { value: '', isValid: true, message: '' },
            hospitalName: { value: '', isValid: true, message: '' },
            branches: [],
            availabilities: [],
            profileSummary: { value: '', isValid: true, message: '' },
            profileCompletion: {},
            infoToolTip: false,
            limit: 10,
            page: 1,
            validated: true
        };
        this.onChangeCC = this.onChangeCC.bind(this);
        this.deleteAddressInfo = this.deleteAddressInfo.bind(this);
        this.changeAddressInfo = this.changeAddressInfo.bind(this);
        this.saveProfile = this.saveProfile.bind(this);
        this.checkFields = this.checkFields.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeDate = this.onChangeDate.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.addBranch = this.addBranch.bind(this);
        this.deleteBranch = this.deleteBranch.bind(this);
        this.handleSelectionCanadaPost = this.handleSelectionCanadaPost.bind(this);
        this.setModal = this.setModal.bind(this);
        this.handleApiLoaded = this.handleApiLoaded.bind(this);
        this.onChangeMapCoords = this.onChangeMapCoords.bind(this);
        this.onChangeAddressUsingMap = this.onChangeAddressUsingMap.bind(this);
        this.changeLimitOrPage = this.changeLimitOrPage.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const { profile, branches } = props;
        if (branches) {
            for (let index = 0; index < branches.length; index++) {
                state.availabilities[index] = branches[index].availability;
            }
        }
        if (profile.hospitalName) {
            state.hospitalName.value = profile.hospitalName;
        }
        if (state.formDirty == false) {
            if (props.auth && props.auth.isAuthenticated && profile) {
                if (profile.profileCompletion) {
                    state.profileCompletion = profile.profileCompletion;
                }

                if (branches) {
                    console.log('got here', state.branches.length ,branches.lengt)
                    let location = getLocationShorts();
                    for (let index = 0; index < branches.length; index++) {
                        let item = branches[index];
                        if (state.branches.length > branches.length) {
                            state.branches = state.branches.filter(
                                (item) => !item.branchId || item.branchId == branches[index]._id
                            );
                        }
                        let currency, languages
                        if(localStorage.getItem('localityCountryShort') === 'BD'){
                            currency = "BDT"
                            languages = ["English", "Bengali"]
                        }
                        console.log(item?.languages || languages)
                        if (state.branches.length < branches.length) {
                     
                            
                            let branch = {
                                branchId: '',
                                name: { value: '', isValid: true, message: '' },
                                email: { value: '', isValid: true, message: '' },
                                phoneNo: { value: '', isValid: true, message: '' },
                                countryCode: { value: '', isValid: true, message: '' },
                                profileSummary: { value: '', isValid: true, message: '' },
                                website: { value: '', isValid: true, message: '' },
                                availability: {},
                                languages: { value: languages || [], message: '', isValid: true },
                                currency: { value: currency || '', message: '', isValid: true },
                                branchCountry: {
                                    value: '',
                                    isValid: true,
                                    message: '',
                                    isDisabled: false
                                },
                                branchAddress: {
                                    value: '',
                                    isValid: true,
                                    message: '',
                                    isDisabled: false
                                },
                                branchCity: {
                                    value: '',
                                    isValid: true,
                                    message: '',
                                    isDisabled: false
                                },
                                branchState: {
                                    value: '',
                                    isValid: true,
                                    message: '',
                                    isDisabled: false
                                },
                                branchZip: {
                                    value: '',
                                    isValid: true,
                                    message: '',
                                    isDisabled: false
                                },
                                branchCoords: { lat: '', lng: '' },

                                social: {
                                    facebook: '',
                                    linkedin: '',
                                    instagram: '',
                                    twitter: ''
                                },
                                adjustPin: false,
                                center: {
                                    lat:
                                        location.coordinates &&
                                        location.coordinates.length > 0 &&
                                        location.coordinates[1],
                                    lng:
                                        location.coordinates &&
                                        location.coordinates.length > 0 &&
                                        location.coordinates[0]
                                },
                                zoom: 15,
                                markerCenter: {
                                    lat:
                                        location.coordinates &&
                                        location.coordinates.length > 0 &&
                                        location.coordinates[1],
                                    lng:
                                        location.coordinates &&
                                        location.coordinates.length > 0 &&
                                        location.coordinates[0]
                                },
                                countryDrop: false,
                                currencyDrop: false,
                                filteredCountryCode: Countries,
                                accordion: false,
                                modal: false,
                                locationModal: false,
                                firstSave: true
                            };
                            state.branches.push(branch)
                        }

                        

                        if (item._id) {
                            state.branches[index].branchId = item._id;
                        }
                        if (item.availability) {
                            state.branches[index].availability = item.availability;
                        }
                        
                        if (item.languages.length) {
                            state.branches[index].languages.value = item.languages;
                        }
                        else {
                            state.branches[index].languages.value = languages
                        }
                        if (item.currency) {
                            state.branches[index].currency.value = item.currency;
                        }
                        if (item.name) {
                            state.branches[index].name.value = item.name;
                        }
                        if (item.email) {
                            state.branches[index].email.value = item.email;
                        }
                        if (item.phoneNo) {
                            state.branches[index].phoneNo.value = item.phoneNo;
                        }
                        if (item.countryCode) {
                            state.branches[index].countryCode.value = item.countryCode;
                        }
                        if (item.profileSummary) {
                            state.branches[index].profileSummary.value = item.profileSummary;
                        }
                        if (item.social) {
                            state.branches[index].social = item.social;
                        }
                        if (item.website) {
                            state.branches[index].website.value = item.website;
                        }

                        if (item.location) {
                            if (item.location.branchAddress) {
                                state.branches[index].branchAddress.value =
                                    item.location.branchAddress;
                            }
                            if (item.location.branchCity) {
                                state.branches[index].branchCity.value = item.location.branchCity;
                            }
                            if (item.location.branchState) {
                                state.branches[index].branchState.value = item.location.branchState;
                            }
                            if (item.location.branchZip) {
                                state.branches[index].branchZip.value = item.location.branchZip;
                            }
                            if (item.location.branchCountry) {
                                state.branches[index].branchCountry.value =
                                    item.location.branchCountry;
                                let foundCountryCode = UpdatedCountries.find(
                                    (it) => it.name == item.location.branchCountry
                                ).dialCode;
                                if (foundCountryCode && !item.countryCode) {
                                    state.branches[index].countryCode.value = foundCountryCode;
                                }
                            }
                            state.branches[index].firstSave = item.firstSave;

                            if (item.location.branchCoords) {
                                state.branches[index].branchCoords = {
                                    lat: item.location.branchCoords.lat
                                        ? item.location.branchCoords.lat
                                        : '',
                                    lng: item.location.branchCoords.lng
                                        ? item.location.branchCoords.lng
                                        : ''
                                };
                                state.branches[index].center = item.location.branchCoords;
                                state.branches[index].markerCenter = item.location.branchCoords;
                            }
                        }

                        if (
                            index == 0 &&
                            props.auth.profileError &&
                            props.auth.profileError.includes('atleast one location')
                        ) {
                            state.branches[index].firstStart = true;
                            state.branches[index].locationModal = true;
                            if (
                                state.branches[index].firstSave == false &&
                                Object.keys(state.branches[index].availability).length == 0
                            ) {
                                state.branches[index].modal = true;
                            }
                        }
                    }
                }
            }
        }
        return state;
    }

    onChangeCC(value, index, item) {
        var state = this.state;

        state.branches[index].countryCode.value = value;

        state.formDirty = true;
        this.setState(state);
    }
    setModal(index) {
        console.log(index);
        let state = this.state;
        state.branches[index].modal = !state.branches[index].modal;
        this.setState(state);
    }

    onChange(e, index) {
        console.log(e, index);
        var state = this.state;
        if (e.target.name !== 'latitude' && e.target.name !== 'longitude') {
            state.branches[index][e.target.name].value = e.target.value;
        }
        state.formDirty = true;

        if (e.target.name == 'profileSummary') {
            state.branches[index].profileSummary.value = e.target.value;
        }

        if (e.target.name == 'latitude') {
            state.branches[index].branchCoords.lat = e.target.value;
        }
        if (e.target.name == 'longitude') {
            state.branches[index].branchCoords.lng = e.target.value;
        }
        if (
            state.branches[index].branchCoords.lat != '' &&
            state.branches[index].branchCoords.lng != ''
        ) {
            state.branches[index].branchCoords.message = '';
        }

        // if (e.target.name == 'website') {
        //     if (e.target.value == '') {
        //         state.branches[index].website.isValid = false;
        //         state.branches[index].website.message = 'Website is required';
        //     } else {
        //         state.branches[index].website.isValid = true;
        //         state.branches[index].website.message = '';
        //     }
        // }

        if (e.target.name == 'email') {
            if (e.target.value == '') {
                state.branches[index].email.isValid = false;
                state.branches[index].email.message = 'Email is required';
            } else if (!validator.isEmail(e.target.value)) {
                state.branches[index].email.isValid = false;
                state.branches[index].email.message = 'Not a valid email address';
            } else {
                state.branches[index].email.isValid = true;
                state.branches[index].email.message = '';
            }
        }

        if (e.target.name == 'phoneNo') {
            if (e.target.value == '') {
                state.branches[index].phoneNo.isValid = false;
                state.branches[index].phoneNo.message = 'Phone number is required';
            } else if (e.target.value.length !== 10) {
                state.branches[index].phoneNo.isValid = false;
                state.branches[index].phoneNo.message = 'Phone number must be 10 digits';
            } else if (!e.target.value.match(/^\d+$/)) {
                state.branches[index].phoneNo.isValid = false;
                state.branches[index].phoneNo.message = 'Phone number must only contain numbers';
            } else {
                state.branches[index].phoneNo.isValid = true;
                state.branches[index].phoneNo.message = '';
            }
        }

        if (e.target.name == 'countryCode') {
            console.log(e.target.value);
            if (e.target.value == '') {
                state.branches[index].countryCode.isValid = false;
                state.branches[index].countryCode.message = 'Country code is required';
            }

            if (!e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state.branches[index].countryCode.isValid = false;
                state.branches[index].countryCode.message =
                    'Country code must only contain numbers';
            }

            if (e.target.value.match(/^(\+?\d{1,3}|\d{1,4})$/gm)) {
                state.branches[index].countryCode.isValid = true;
                state.branches[index].countryCode.message = '';
            }
        }
        this.checkFields(index);
        this.setState(state);
    }

    checkFields(index) {
        let state = this.state;
        let valid = true;
        let branch = state.branches[index];

        state.formDirty = true;

        if (branch.email == '') {
            // branch.email.isValid = false;
            // branch.email.message = "Email is required";
            // valid = false;
        } else if (branch.email.value != '' && !validator.isEmail(branch.email.value)) {
            branch.email.isValid = false;
            branch.email.message = 'Not a valid email address';
            valid = false;
        } else {
            branch.email.isValid = true;
            branch.email.message = '';
        }

        if (branch.branchCoords.lat == '' || branch.branchCoords.lng == '') {
            valid = false;
            branch.branchCoords.message = 'Both latitude and longitude is required';
        } else {
            branch.branchCoords.message = '';
        }

        if (branch.phoneNo.value == '') {
            branch.phoneNo.isValid = false;
            branch.phoneNo.message = 'Phone number is required';
            valid = false;
        } else if (branch.phoneNo.value.toString().length !== 10) {
            branch.phoneNo.isValid = false;
            branch.phoneNo.message = 'Phone number must be 10 digits';
            valid = false;
        } else if (!branch.phoneNo.value.toString().match(/^\d+$/)) {
            branch.phoneNo.isValid = false;
            branch.phoneNo.message = 'Phone number must only contain numbers';
            valid = false;
        } else {
            branch.phoneNo.isValid = true;
            branch.phoneNo.message = '';
        }

        if (branch.countryCode.value == '') {
            branch.countryCode.isValid = false;
            branch.countryCode.message = 'Country code is required';
            valid = false;
        }

        if (!branch.countryCode.value.match(/^\+?\d{1,4}$/gm)) {
            branch.countryCode.isValid = false;
            branch.countryCode.message = 'Country code must only contain numbers';
            valid = false;
        }

        if (branch.countryCode.value.match(/^\+?\d{1,4}$/gm)) {
            branch.countryCode.isValid = true;
            branch.countryCode.message = '';
        }

        if (branch.branchAddress.value == '') {
            branch.branchAddress.isValid = false;
            branch.branchAddress.message = 'Branch address is required';
            valid = false;
        } else {
            branch.branchAddress.isValid = true;
            branch.branchAddress.message = '';
        }

        if (branch.branchCity.value == '') {
            branch.branchCity.isValid = false;
            branch.branchCity.message = 'Branch city is required';
            valid = false;
        } else {
            branch.branchCity.isValid = true;
            branch.branchCity.message = '';
        }

        if (branch.branchCountry.value == '') {
            branch.branchCountry.isValid = false;
            branch.branchCountry.message = 'Branch address is required';
            valid = false;
        } else {
            branch.branchCountry.isValid = true;
            branch.branchCountry.message = '';
        }

        if (branch.branchState.value == '') {
            branch.branchState.isValid = false;
            branch.branchState.message = 'Branch state is required';
            valid = false;
        } else {
            branch.branchState.isValid = true;
            branch.branchState.message = '';
        }

        if (branch.branchZip.value == '') {
            branch.branchZip.isValid = false;
            branch.branchZip.message = 'Branch zip is required';
            valid = false;
        } else {
            branch.branchZip.isValid = true;
            branch.branchZip.message = '';
        }

        if (branch.currency.value == '') {
            branch.currency.isValid = false;
            branch.currency.message = 'Currency is required';
            valid = false;
        } else {
            branch.currency.isValid = true;
            branch.currency.message = '';
        }

        if (branch.languages.value.length == 0) {
            branch.languages.isValid = false;
            branch.languages.message = 'Language is required';
            valid = false;
        } else {
            branch.languages.isValid = true;
            branch.languages.message = '';
        }
        if (valid == false) {
            state.validated = false;
        } else {
            state.validated = true;
        }
        this.setState(state);

        return valid;
    }

    deleteAddressInfo(index) {
        let ind = index;
        let arr = this.state.addressInfo;
        let tempAddressInfo = this.state.addressInfo;
        if (tempAddressInfo.length > 1) {
            let newTemp = tempAddressInfo.splice(index, 1);
            console.log(tempAddressInfo);
            this.setState({ addressInfo: tempAddressInfo });
        }
    }

    changeAddressInfo(e, index) {
        let temp = this.state.addressInfo;
        console.log(temp, index);
        temp[index].city = e.target.value;
        console.log(temp);
        this.setState({ addressInfo: temp });
    }
    onChangeDate(date, dateString) {
        console.log(date, dateString);
        this.setState({
            formDirty: true,
            dob: { value: dateString, isValid: true, message: '' }
        });
    }

    deleteBranch = async (index, id) => {
        let state = this.state;
        // if (
        //   this.props.branches.length !== 0 &&
        //   this.props.branches[index] &&
        //   id == state.branches[index].branchId
        // ) {
        //   let obj = {
        //     branchId: state.branches[index].branchId
        //   }
        //   let response = await this.props.branchActions.deleteBranch(
        //     { ...this.props, obj },
        //     this.props.history
        //   )

        //   if (response) {
        //     console.log(response)
        //     // let profileShort = {
        //     //   userId: this.props.auth.user._id,
        //     //   userType: this.props.auth.user.userType
        //     // };
        //     // const firmuser = await this.props.actions.getProfile(
        //     //   profileShort,
        //     //   this.props.history
        //     // );

        //     let obj = {
        //       userId: this.props.auth.userId,
        //       populateAvailability: true
        //     }
        //     this.props.branchActions.getBranches(
        //       { ...this.props, obj },
        //       this.props.history
        //     )
        //   }
        // }
        if (!state.branches[index].branchId) {
            state.branches.splice(index, 1);
        }
        state.formDirty = false;
        this.setState(state);
    };

    changeLimitOrPage = async (value, string) => {
        let newLimit = this.state.limit;
        let newPage = this.state.page;
        if (string == 'limit') {
            newLimit = value;
            this.setState({ limit: value });
        }
        if (string == 'page') {
            newPage = value;
            this.setState({ page: value });
        }
        let obj = {
            userId: this.props.auth.userId,
            populateAvailability: true,
            limit: newLimit,
            page: newPage
        };
        await this.props.branchActions.getBranches({ ...this.props, obj }, this.props.history);
    };

    saveProfile = async (e, index) => {
        let state = this.state;
        let valid = true;
        valid = this.checkFields(index);
        console.log(valid);
        let branch = state.branches[index];

        if (valid) {
            let profileObj = {
                hospital: this.props.auth.userId,
                branchId: branch.branchId,
                email: branch.email.value,
                phoneNo: branch.phoneNo.value,
                countryCode: branch.countryCode.value,
                profileSummary: branch.profileSummary.value,
                languages: branch.languages.value,
                currency: branch.currency.value,
                website: branch.website.value,
                location: {
                    branchCountry: branch.branchCountry.value,
                    branchAddress: branch.branchAddress.value,
                    branchCity: branch.branchCity.value,
                    branchState: branch.branchState.value,
                    branchZip: branch.branchZip.value,
                    branchCoords: branch.branchCoords
                }
            };
            delete profileObj.filteredCountryCode;
            delete profileObj.countryDrop;
            delete profileObj.accordion;

            let response = await this.props.actions.saveBranches(profileObj, this.props);

            if (response) {
                let obj = {
                    userId: this.props.auth.userId,
                    populateAvailability: true,
                    limit: this.state.limit,
                    page: this.state.page
                };
                await this.props.branchActions.getBranches(
                    { ...this.props, obj },
                    this.props.history
                );
                // this.setModal(index)
            }

            state.formDirty = false;
            console.log(branch)
            if (!Object.keys(branch.availability).length) {
                branch.modal = true;
            } else {
                branch.locationModal = false;
            }
        } else {
            message.error('Resolve all errors');
        }

        this.setState(state);
    };

    async updateAddress(addr, index) {
        
        this.setState({ formDirty: true });
        var placeSearch, autocomplete;
        var componentForm = {
            street_number: '',
            route: '',
            postal_town: '',
            locality: '',
            administrative_area_level_1: '',
            country: '',
            postal_code: '',
            formattedAddress: '',
            neighbourhood: ''
        };
        let address = await geocodeByAddress(addr);
        address = address[0];
        componentForm.formattedAddress = address.formatted_address;
        for (var i = 0; i < address.address_components.length; i++) {
            var addressType = address.address_components[i].types[0];
            if (componentForm[addressType] == '') {
                let val = address.address_components[i][componentForm[addressType]];
                componentForm[addressType] = address.address_components[i].long_name;
            }
        }

        let state = this.state;
        if (address && address.geometry) {
            state.branches[index].branchCoords.lat = address.geometry.location.lat();
            state.branches[index].branchCoords.lng = address.geometry.location.lng();
            state.branches[index].center = {
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng()
            };
            state.branches[index].markerCenter = {
                lat: address.geometry.location.lat(),
                lng: address.geometry.location.lng()
            };
        }

        if (componentForm.country !== '')
            state.branches[index].branchCountry = {
                value: componentForm.country,
                isValid: true,
                message: ''
            };
        // debugger
        if (componentForm.postal_code !== '')
            state.branches[index].branchZip = {
                value: componentForm.postal_code,
                isValid: true,
                message: ''
            };
        state.branches[index].branchCity = {
            value:
                componentForm.postal_town != 'Town' && componentForm.postal_town
                    ? componentForm.postal_town
                    : componentForm.locality != 'locality'
                    ? componentForm.locality
                    : '',
            isValid: true,
            message: ''
        };
        state.branches[index].branchState = {
            value:
                componentForm.administrative_area_level_1 != 'State '
                    ? componentForm.administrative_area_level_1
                    : '',
            isValid: true,
            message: ''
        };

        if (componentForm.street_number && componentForm.route) {
            state.branches[index].branchAddress = {
                value: componentForm.street_number + ' ' + componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.route) {
            state.branches[index].branchAddress = {
                value: componentForm.route,
                isValid: true,
                message: ''
            };
        } else if (componentForm.formattedAddress) {
            state.branches[index].branchAddress = {
                value: componentForm.formattedAddress,
                isValid: true,
                message: ''
            };
        }

        this.setState(state, () => this.checkFields(index));
    }

    async onChangeAddressUsingMap(latlng, index) {
        let state = this.state;
        state.formDirty = true;
        let res = await GetLocationUsingLatLng({ lng: latlng.lng, lat: latlng.lat });
        if (res) {
            console.log(res, res.address, res.city, res.country);
            state.branches[index].branchAddress.value = res.address;
            state.branches[index].branchZip.value = res.zip;
            state.branches[index].branchCountry.value = res.country;
            state.branches[index].branchState.value = res.state;
            state.branches[index].branchCity.value = res.city;
            state.branches[index].adjustPin = false;
        }

        this.setState(state);
    }
    onChangeMapCoords(e, index) {
        var state = this.state;
        state.formDirty = true;
        state.branches[index].branchCoords.lat = e.center.lat();
        state.branches[index].branchCoords.lng = e.center.lng();
        state.branches[index].markerCenter = {
            lat: e.center.lat(),
            lng: e.center.lng()
        };
        //  state.markerCenter= {
        //     lat: e.center.lat(),
        //     lng: e.center.lng()
        //   }
        this.setState(state, () => this.checkFields(index));
    }

    addBranch() {
        let location = getLocationShorts();
        console.log(
            location,
            location.coordinates && location.coordinates.length > 0 && location.coordinates[1]
        );
        let state = this.state;
        state.formDirty = true;
        let branchObj = {
            branchId: '',
            name: {
                value: this.state.hospitalName.value,
                isValid: true,
                message: ''
            },
            email: { value: '', isValid: true, message: '' },
            phoneNo: { value: '', isValid: true, message: '' },
            countryCode: { value: '', isValid: true, message: '' },
            website: { value: '', isValid: true, message: '' },
            availability: {},
            profileSummary: {
                value: '',
                isValid: true,
                message: '',
                isDisabled: false
            },
            branchCountry: {
                value: '',
                isValid: true,
                message: '',
                isDisabled: false
            },
            branchAddress: {
                value: '',
                isValid: true,
                message: '',
                isDisabled: false
            },
            branchCoords: {
                lat: '',
                lng: ''
            },
            branchCity: {
                value: '',
                isValid: true,
                message: '',
                isDisabled: false
            },
            branchState: {
                value: '',
                isValid: true,
                message: '',
                isDisabled: false
            },
            branchZip: { value: '', isValid: true, message: '' },
            currency: { value: '', isValid: true, message: '' },
            languages: {
                value: [],
                isValid: true,
                message: ''
            },
            social: {
                facebook: '',
                linkedin: '',
                instagram: '',
                twitter: ''
            },
            countryDrop: false,
            filteredCountryCode: Countries,
            accordion: false,
            modal: false,
            locationModal: true,
            center: {
                lat:
                    location.coordinates &&
                    location.coordinates.length > 0 &&
                    location.coordinates[1],
                lng:
                    location.coordinates &&
                    location.coordinates.length > 0 &&
                    location.coordinates[0]
            },
            zoom: 15,
            markerCenter: {
                lat:
                    location.coordinates &&
                    location.coordinates.length > 0 &&
                    location.coordinates[1],
                lng:
                    location.coordinates &&
                    location.coordinates.length > 0 &&
                    location.coordinates[0]
            }
        }
        
        let localityCountry = localStorage.getItem('localityCountryShort');
        
        if(localityCountry == 'BD'){
            branchObj.languages.value = ['Bengali', 'English'];
            branchObj.currency.value = 'BDT';
            branchObj.countryCode.value = '+880';
        }
        state.branches.push(branchObj);
        for (let i = 0; i < state.branches.length; i++) {
            state.branches[i].accordion = false;
        }
        this.setState(state);
    }

    handleSelectionCanadaPost(item, index) {
        console.log('item', item);
        let state = this.state;
        if (item && item.coordinates) {
            state.branches[index].branchCoords.lat = item.coordinates.lat
                ? item.coordinates.lat
                : '';
            state.branches[index].branchCoords.lng = item.coordinates.lng
                ? item.coordinates.lng
                : '';
        }

        state.branches[index].branchCountry = {
            value: item.CountryName ? item.CountryName : '',
            isValid: true,
            message: ''
        };

        state.branches[index].branchZip = {
            value: item.PostalCode ? item.PostalCode : '',
            isValid: true,
            message: ''
        };
        state.branches[index].branchCity = {
            value: item.City ? item.City : '',
            isValid: true,
            message: ''
        };
        state.branches[index].branchState = {
            value: item.Province ? item.Province : '',
            isValid: true,
            message: ''
        };
        state.branches[index].branchAddress = {
            value: item.Line1 ? item.Line1 : '',
            isValid: true,
            message: ''
        };
        state.formDirty = true;
        this.setState(state, () => console.log(this.state));
    }
    handleApiLoaded(map, maps, markerCenter) {
        // use map and maps objects
        if (map) {
            map.panTo(markerCenter);
        }
    }

    render() {
        let country = localStorage.getItem('country_short');

        return (
            <div>
                <PageHeader heading={'Hospital Locations'} />

                {this.props.auth && this.props.auth.profileError && (
                    <div className='errorDiv'>
                        <p className='error'>{this.props.auth.profileError}</p>
                    </div>
                )}
                {console.log(this.state.branches)}
                {this.state.branches &&
                    this.state.branches.map((item, index) => (
                        <>
                            {item.locationModal && (
                                <CreateLocation
                                    onChange={this.onChange}
                                    hospitalName={this.state.hospitalName}
                                    updateAddress={this.updateAddress}
                                    item={item}
                                    index={index}
                                    onChangeCC={this.onChangeCC}
                                    onChangeMapCoords={this.onChangeMapCoords}
                                    onChangeAddressUsingMap={this.onChangeAddressUsingMap}
                                    currentRef={this}
                                    availability={
                                        this.state?.availabilities?.length > 0 &&
                                        this.state?.availabilities?.filter(
                                            (it) => item?.branchId && item?.branchId == it?.branch
                                        )[0]
                                    }
                                    closeLocationModal={() => {
                                        let state = this.state;
                                        state.branches[index].locationModal = false;
                                        if (index == 0) {
                                            state.branches[index].firstStart = false;
                                        }
                                        this.setState(state, () =>
                                            this.deleteBranch(index, state.branches[index].branchId)
                                        );
                                    }}
                                    state={this.state}
                                    setState={(st) => this.setState(st)}
                                    closeCurrencyDrop={() => {
                                        let state = this.state;
                                        state.branches[index].currencyDrop = false;
                                        this.setState(state);
                                    }}
                                    closeCountryDrop={() => {
                                        let state = this.state;
                                        state.branches[index].countryDrop = false;
                                        this.setState(state);
                                    }}
                                    Marker={Marker}
                                    deleteBranch={() => this.deleteBranch()}
                                    checkFields={this.checkFields}
                                    validated={this.state.validated}
                                    {...this.props}
                                />
                            )}
                        </>
                    ))}
                <LocationHeader addBranch={() => this.addBranch()} />
                <LocationDataTable
                    locations={this.state.branches}
                    history={this.props.history}
                    state={this.state}
                    setState={(st) => this.setState(st)}
                    limit={this.state.limit}
                    checkFields={this.checkFields}
                    {...this.props}
                />

                <Pagination
                    style={{ margin: 20, display: 'flex', justifyContent: 'flex-end' }}
                    current={this.state.page}
                    total={this.props.branchesCount ? this.props.branchesCount : 0}
                    onChange={(val) => this.changeLimitOrPage(val, 'page')}
                    pageSize={this.state.limit}
                    pageSizeOptions={[5, 10, 20]}
                    showSizeChanger={true}
                    responsive={true}
                    onShowSizeChange={(size, newSize) => this.changeLimitOrPage(newSize, 'limit')}
                />
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    profile: state.auth.userProfile,
    branch: state.branch,
    branches: state.branch && state.branch.branches && state.branch.branches,
    branchesCount: state.branch && state.branch.branchesCount && state.branch.branchesCount
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(userActions, dispatch),
    branchActions: bindActionCreators(branchActions, dispatch)
});
export default connect(mapStateToProps, mapDispatchToProps)(Location);
