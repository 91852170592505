export const ConsultantTypes = [
    'Acupuncturist Consultant',
    'Allergist/Immunologist Consultant',
    'Anesthesiologist Consultant',
    'Cardiologist Consultant',
    'Chiropractor Consultant',
    'Clinical Documentation Improvement (CDI) Consultant',
    'Clinical Research Consultant',
    'Counselor/Therapist Consultant',
    'Critical Care Consultant',
    'Dermatologist Consultant',
    'Emergency Medicine Consultant',
    'Endocrinologist Consultant',
    'Gastroenterologist Consultant',
    'General Practitioner Consultant',
    'Geriatrician Consultant',
    'Health Coach Consultant',
    'Health Economics Consultant',
    'Accreditation Consultant',
    'Analytics Consultant',
    'Architecture/Design Consultant',
    'Artificial Intelligence (AI) Consultant',
    'Augmented Reality (AR) Consultant',
    'Behavioral Health Consultant',
    'Business Development Consultant',
    'Change Management Consultant',
    'Communication/PR Consultant',
    'Compliance Consultant',
    'Credentialing Consultant',
    'Customer Experience Consultant',
    'Cybersecurity Consultant',
    'Data Governance Consultant',
    'Data Privacy and Security Consultant',
    'Environmental Consultant',
    'Environmental Health and Safety Consultant',
    'Ethics Consultant',
    'Facilities Design Consultant',
    'Facilities Maintenance Consultant',
    'Facilities Management Consultant',
    'Facilities Planning Consultant',
    'Facilities Safety Consultant',
    'Finance Consultant',
    'Human Resources Consultant',
    'Informatics Consultant',
    'Informatics Implementation Consultant',
    'Information Management Consultant',
    'Information Technology (HIT) Security Consultant',
    'Innovation Consultant',
    'Insurance Consultant',
    'Interoperability Consultant',
    'IT Consultant',
    'Lean/Six Sigma Consultant',
    'Legal Consultant',
    'Machine Learning Consultant',
    'Management Consultant',
    'Market Research Consultant',
    'Marketing Consultant',
    'Mobile App Development Consultant',
    'Operations Consultant',
    'Organizational Development Consultant',
    'Performance Improvement Consultant',
    'Policy Consultant',
    'Population Health Consultant',
    'Population Health Management Consultant',
    'Product Development Consultant',
    'Project Management Consultant',
    'Public Health Consultant',
    'Quality Improvement Consultant',
    'Regulatory Compliance Consultant',
    'Revenue Cycle Management Consultant',
    'Risk Assessment Consultant',
    'Risk Management Consultant',
    'Robotics Consultant',
    'Strategic Planning Consultant',
    'Supply Chain Consultant',
    'Supply Chain Management Consultant',
    'Sustainability Consultant',
    'Telecommunications Consultant',
    'Telehealth Consultant',
    'Training and Development Consultant',
    'Virtual Reality (VR) Consultant',
    'Wearable Technology Consultant',
    'Hematologist',
    'Hospitalist',
    'Infectious Disease Specialist',
    'Integrative Medicine Practitioner',
    'Medical Billing Consultant',
    'Medical Coding/Billing Compliance Consultant',
    'Medical Consultant',
    'Medical Device Consultant',
    'Medical Education Consultant',
    'Medical Equipment Consultant',
    'Medical Illustrator',
    'Medical Legal Consultant',
    'Medical Practice Management Consultant',
    'Medical Tourism Consultant',
    'Medical Writer',
    'Midwife',
    'Naturopathic Physician',
    'Nephrologist',
    'Neurologist',
    'Nurse Practitioner',
    'Nutritionist/Dietitian',
    'Obstetrician/Gynecologist (OB/GYN)',
    'Occupational Therapist',
    'Oncologist',
    'Ophthalmologist',
    'Orthopedic Surgeon',
    'Otolaryngologist (ENT Specialist)',
    'Pain Management Specialist',
    'Pathologist',
    'Pediatrician',
    'Pharmacist',
    'Physiatrist (Physical Medicine and Rehabilitation Specialist)',
    'Physical Therapist Consultant',
    'Physician Consultant',
    'Plastic Surgeon Consultant',
    'Psychiatrist Consultant',
    'Psychologist Consultant',
    'Pulmonologist Consultant',
    'Radiologist Consultant',
    'Rheumatologist Consultant',
    'Sleep Medicine Consultant',
    'Social Worker Consultant',
    'Speech Consultant',
    'Sports Medicine Consultant',
    'Telemedicine Consultant',
    'Travel Medicine Specialist',
    'Urology Consultant'
];

export const ConsultantTypeOptions = ConsultantTypes.reduce((acc, value, index) => {
    return (acc = [...acc, { value: value, id: index + 1 }]);
}, []);