import React from 'react';

const CLIENT_ID = '64186221620-sp5ef0aoh285hj2548hvenm76qfp322l.apps.googleusercontent.com';
const API_KEY = 'AIzaSyD6VhimoZovIVfp4G_j8DraiIcA5qYwfcQ';

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = 'https://www.googleapis.com/auth/calendar';

function FC(props) {
    var gapi = window.gapi;
    const handleClick = (e) => {
        e.preventDefault();

        console.log('being clicked');
        gapi.load('client:auth2', () => {
            console.log('loaded client', gapi);
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoverDocs: DISCOVERY_DOCS,
                scope: SCOPES
            });

            gapi.client.load('calendar', 'v3', () => console.log('bam!'));
            gapi.auth2
                .getAuthInstance()
                .signIn()
                .then(async (user) => {
                    console.log(user);

                    var event = {
                        summary: 'Awesome Event!',
                        location: '800 Howard St., San Francisco, CA 94103',
                        description: 'Really great refreshments',
                        start: {
                            dateTime: '2021-12-25T00:00:00.000Z',
                            timeZone: 'Asia/Dhaka'
                        },
                        end: {
                            dateTime: '2021-12-25T17:00:00.000Z',
                            timeZone: 'Asia/Dhaka'
                        },
                        recurrence: ['RRULE:FREQ=DAILY;COUNT=2'],
                        attendees: [{ email: 'lpage@example.com' }, { email: 'sbrin@example.com' }],
                        reminders: {
                            useDefault: false,
                            overrides: [
                                { method: 'email', minutes: 24 * 60 },
                                { method: 'popup', minutes: 10 }
                            ]
                        }
                    };

                    var request = gapi.client.calendar.events.insert({
                        calendarId: 'primary',
                        resource: event
                    });

                    request.execute((event) => {
                        console.log(event);
                        window.open(event.htmlLink);
                    });

                    /*
              Uncomment the following block to get events
          */
                    /**/
                    // get events
                    gapi.client.calendar.events
                        .list({
                            calendarId: 'primary',
                            timeMin: new Date().toISOString(),
                            showDeleted: false,
                            singleEvents: true,
                            maxResults: 10,
                            orderBy: 'startTime'
                        })
                        .then((response) => {
                            console.log('res: ', response);
                            const events = response.result.items;
                            console.log('EVENTS: ', events);
                            localStorage.setItem('events', JSON.stringify(events));
                        });
                });
        });
    };

    const handleClick2 = async () => {
        if (gapi && gapi.auth2 && gapi.auth2.getAuthInstance()) {
            gapi.auth2
                .getAuthInstance()
                .signOut()
                .then(() => console.log('worked'));
        }
    };

    return (
        <div>
            <button className='app-search-btn' onClick={(e) => handleClick(e)}>
                Create Event
            </button>
            <button className='app-search-btn' onClick={(e) => handleClick2(e)}>
                Sign out
            </button>
        </div>
    );
}

export default FC;
