import React, { useState, useRef, useEffect } from 'react';
import './doctorModals.css';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from '@/actions/userActions';
import * as hospitalActions from '@/actions/hospitalActions';
import { message, Select, Radio, Tooltip } from 'antd';
import validator from 'validator';
import PhoneInput from 'react-phone-input-2';
import { Modal } from 'antd';
import { CAREGIVER_TYPES } from '@/assets/json/CaregiverTypes';
import { ConsultantTypes as CONSULTANT_TYPES } from '@/assets/json/ConsultantTypes';
import { nurseTypes as NURSE_TYPES, bnmcRegistrationTypes } from '@/assets/json/NurseTypes';
import { THERAPIST_TYPES } from '@/assets/json/TherapistTypes';

const { Option } = Select;
const initState = {
    value: '',
    message: '',
    isValid: true
};
const CreateMember = (props) => {
    const {
        branches,
        specializedRoles,
        practiceAreas,
        hospitalServices,
        limit,
        page,
        open,
        hospital,
        memberType,
        getMembers
    } = props;
    const [emailMessage, setEmailMessage] = useState(null);
    const [passwordMessage, setPassMessage] = useState(null);
    const [confPassMessage, setConfPassMessage] = useState(null);
    const [phoneNoMessage, setphoneNoMessage] = useState(null);
    const [countryCodeMessage, setCountryCodeMessage] = useState(null);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [numOfUsers, setNumOfMembers] = useState(props.profile ? props.profile.numOfUsers : 0);
    const [catMessage, setCatMessage] = useState(null);
    const [roleMessage, setRoleMessage] = useState(null);
    const [fnameMessage, setfnameMessage] = useState(null);
    const [lnameMessage, setlnameMessage] = useState(null);
    const [bnmcRegistrationNumberMessage, setBnmcRegistrationNumberMessage] = useState(null);
    const [bnmcRegistrationTypeMessage, setBnmcRegistrationTypeMessage] = useState(null);

    const [typeMessage, setTypeMessage] = useState(null);
    const [branchMessage, setBranchMessage] = useState(null);
    const [disable, setDisable] = useState({
        phoneNo: false,
        countryCode: false,
        email: false,
        password: false,
        confirmPass: false
    });
    const [errorMessage, setErrorMessage] = useState(null);

    const [countryCode, setCountryCode] = useState({
        value: localStorage.getItem('calling_code')
            ? localStorage.getItem('calling_code').replace('+', '')
            : '',
        message: '',
        isValid: true
    });

    const getTypes = (member) => {
        let returnType = CONSULTANT_TYPES;
        switch (member) {
            case 'Consultant':
                return CONSULTANT_TYPES;
            case 'Caregiver':
                return CAREGIVER_TYPES;
            case 'Nurse':
                return NURSE_TYPES;
            case 'Therapist':
                return THERAPIST_TYPES;
            default:
                break;
        }
        return returnType;
    };
    const types = getTypes(memberType);

    const [memberInputs, setMemberInputs] = useState({
        username: '',
        firstName: '',
        countryCode: '',
        lastName: '',
        featured: false,
        title: '',
        email: '',
        phoneNo: '',
        password: '',
        confirmPass: '',
        hospitalId: '',
        specializedRole: '',
        category: [],
        branch: '',
        services: [],
        hospitalBranches: [],
        type: '',
        bnmcRegistrationNumber: '',
        bnmcRegistrationType: ''
    });
    const [filteredServices, setFilteredServices] = useState([]);

    const onSelectPracticeAreas = (selectedList, selectedItem) => {
        let arr = memberInputs.category;
        setMemberInputs({
            ...memberInputs,
            category: selectedList
        });
        if (selectedList.length > 0) {
            setCatMessage('');
        } else {
            setCatMessage('Area of Expertise is required');
        }
    };

    useEffect(() => {
        if (props.profile) {
            setMemberInputs({ ...memberInputs, hospitalId: props.profile.id });
            setNumOfMembers(props.profile.numbersOfDoctors);
        }
    }, [props.profile]);

    const handleBranchInput = (e, target) => {
        if (e != '') {
            setBranchMessage(null);
        }
        setMemberInputs({
            ...memberInputs,
            hospitalBranches: e
        });
    };
    const handleInputChange = (e) => {
        if (e.target.name === 'phoneNo') {
            if (
                (e.target.value.length <= 10 && e.target.value.match(/^[0-9]+$/)) ||
                e.target.value.length == 0
            ) {
                if (countryCode.value) {
                    setMemberInputs({
                        ...memberInputs,
                        phoneNo: e.target.value
                    });
                    setPassMessage('');
                }
            }
        } else {
            if (e.target.name == 'email') {
                if (e.target.value.length > 0) {
                    if (!validator.isEmail(e.target.value)) {
                        setEmailMessage('Not a valid Email address');
                    } else {
                        setEmailMessage('');
                    }
                } else if (memberInputs.phoneNo == '') {
                    if (e.target.value == 0) {
                        setEmailMessage('Email is required if phone number is not provided');
                    } else {
                        setEmailMessage('');
                    }
                }
            }

            if (e.target.name == 'password' && e.target.value.length < 6) {
                setPassMessage('Password is not strong enough');
            } else if (e.target.name == 'password' && e.target.value.length > 0) {
                if (memberInputs.confirmPass != e.target.value) {
                    setConfPassMessage('Passwords do not match');
                } else {
                    setConfPassMessage('');
                }
                setPassMessage('');
            }
            if (e.target.name == 'confirmPass' && e.target.value != memberInputs.password) {
                setConfPassMessage('Passwords do not match');
            } else if (e.target.name == 'confirmPass' && e.target.value == memberInputs.password) {
                setConfPassMessage('');
            }

            if (e.target.name == 'firstName') {
                if (e.target.value) {
                    setfnameMessage('');
                } else {
                    setfnameMessage('First name is required');
                }
            }
            if (e.target.name == 'lastName') {
                if (e.target.value) {
                    setlnameMessage('');
                } else {
                    setlnameMessage('Last name is required');
                }
            }

            if (e.target.name == 'bnmcRegistrationNumber') {
                if (e.target.value) {
                    setBnmcRegistrationNumberMessage('');
                } else {
                    setBnmcRegistrationNumberMessage('BNMC Registration number is required');
                }
            }
            
            setMemberInputs({
                ...memberInputs,
                [e.target.name]: e.target.value
            });
        }
    };

    const onSumbitInputs = async () => {
        let errors = false;
        let regularDoctorLimit = hospital.userLimit - hospital.featuredUserLimit;
        if (memberInputs.featured && hospital.featuredUserLimit == hospital.numOfFeaturedUsers) {
            message.error('You have reached your limit of featured members');
            return;
        }
        if (hospital.userLimit == hospital.numOfUsers) {
            message.error('You have reached your limit of members');
            return;
        }

        if (
            !memberInputs.featured &&
            regularDoctorLimit == hospital.numOfUsers - hospital.numOfFeaturedUsers
        ) {
            message.error('You have reached your limit of regular members');
            return;
        }

        if (props.profile) {
            if (!memberInputs.firstName) {
                setfnameMessage('First Name is required');
                errors = true;
            } else {
                setfnameMessage(null);
            }
            if (!memberInputs.lastName) {
                setlnameMessage('Last Name is required');
                errors = true;
            } else {
                setlnameMessage(null);
            }

            if (memberType === 'Doctor') {
                if (memberInputs.category.length === 0) {
                    setCatMessage('Area of Expertise is required');
                    errors = true;
                } else {
                    setCatMessage(null);
                }
                if (memberInputs.specializedRole.length === 0) {
                    setRoleMessage('Specialized roles are required');
                    errors = true;
                } else {
                    setRoleMessage(null);
                }
            }

            if (memberType !== 'Doctor') {
                if (!memberInputs.type) {
                    setTypeMessage(`${memberType} type is required`);
                    errors = true;
                }
            } else {
                setTypeMessage(null);
            }

            if (memberType === 'Nurse') {
                if (!memberInputs.bnmcRegistrationNumber) {
                    setBnmcRegistrationNumberMessage('BNMC Registration Number is required');
                    errors = true;
                } else {
                    setBnmcRegistrationNumberMessage(null);
                }

                if (!memberInputs.bnmcRegistrationType) {
                    setBnmcRegistrationTypeMessage('BNMC Registration Type is required');
                    errors = true;
                } else {
                    setBnmcRegistrationTypeMessage(null);
                }
            }

            if (memberInputs.email && !memberInputs.password) {
                setPassMessage('Passwod is required');
                errors = true;
            } else {
                if (memberInputs.password && !memberInputs.email) {
                    setEmailMessage('Email is required if password is provided');
                } else {
                    setEmailMessage('');
                }
                setPassMessage(null);
            }

            if (memberInputs.password && !memberInputs.confirmPass) {
                // setPassMessage("Passwod is required");
                if (memberInputs.password != memberInputs.confirmPass) {
                    setConfPassMessage('Passwords do not match');
                } else {
                    setConfPassMessage('Confirm password is required');
                }
                errors = true;
            } else {
                // setPassMessage(null);
                setConfPassMessage('');
            }

            if (!memberInputs.email) {
                if (!memberInputs.phoneNo) {
                    setEmailMessage('Email is required');
                    errors = true;
                }
            } else if (memberInputs.email && !validator.isEmail(memberInputs.email)) {
                setEmailMessage('Email is not valid');
                errors = true;
            } else {
                setEmailMessage(null);
                setphoneNoMessage(null);
                setErrorMessage(null);
            }
            if (!memberInputs.phoneNo) {
                if (!memberInputs.email) {
                    setphoneNoMessage('Phone No is required');
                    errors = true;
                }
            } else {
                setEmailMessage(null);
                setphoneNoMessage(null);
                setErrorMessage(null);
            }

            if (memberInputs.password !== memberInputs.confirmPass) {
                message.error('Passwords do not match');
                errors = true;
                return;
            }

            if (memberInputs.password && memberInputs.password.length < 6) {
                setPassMessage('Password is not strong enough');
                errors = true;
                return;
            } else if (memberInputs.password && memberInputs.password.length > 0) {
                setPassMessage('');
            }

            if (!memberInputs.hospitalBranches.length) {
                setBranchMessage('Branch is required');
                errors = true;
                return;
            }

            if (memberInputs.phoneNo && !countryCode.value) {
                setCountryCodeMessage('Country code is required when phone no is provided');
                errors = true;
                return;
            } else {
                setCountryCodeMessage(null);
            }

            if (memberInputs.email && !memberInputs.password) {
                setPassMessage('Password is required if email is provided');
                errors = true;
                return;
            } else {
                setPassMessage('');
            }
            
            if (
                (memberInputs.phoneNo || memberInputs.email) &&
                memberInputs.firstName &&
                memberInputs.lastName &&
                memberInputs.hospitalBranches.length > 0 &&
                memberInputs.type &&
                !errors
            ) {
                if (
                    (memberInputs.category.length === 0 || memberInputs.specializedRole.length === 0) &&
                    memberType === 'Doctor'
                ) {
                    return;
                }
                if (
                    (memberInputs.email && !memberInputs.password) ||
                    (!memberInputs.email && memberInputs.password)
                    // ||
                    // (!memberInputs.email &&
                    //   (!memberInputs.password || !memberInputs.confirmPass))
                ) {
                    message.error(
                        'Either email and password or phoneNo with country code should be provided'
                    );
                    setErrorMessage(
                        'Either email and password or phoneNo with country code should be provided'
                    );
                    // return
                }
                if (memberInputs.email === '') {
                    if (memberInputs.phoneNo.length !== 10) {
                        setphoneNoMessage('Phone No is not valid');
                        return;
                    } else {
                        setphoneNoMessage(null);
                    }
                }

                setErrorMessage(null);
                let obj = {};
                obj = {
                    password: memberInputs.password,
                    email: memberInputs.email,
                    userType: memberType.toUpperCase(),
                    firstName: memberInputs.firstName,
                    lastName: memberInputs.lastName,
                    specializedRoles: memberInputs.specializedRole,
                    practiceAreas: memberInputs.category,
                    services: memberInputs.services,
                    hospitalBranches: memberInputs.hospitalBranches,
                    featured: memberInputs.featured,
                    bnmcRegistrationNumber: memberInputs.bnmcRegistrationNumber,
                    bnmcRegistrationType: memberInputs.bnmcRegistrationType,
                    type: memberInputs.type,
                    memberType,
                    workType: 1
                };

                if (memberInputs.phoneNo && countryCode.value) {
                    obj.phoneNo = memberInputs.phoneNo;
                    obj.countryCode = countryCode.value;
                }

                let newDoctor = await props.hospitalActions.createDoctor(
                    { ...props, obj },
                    props.history
                );
                if (newDoctor) {
                    message.success('Member added');
                    props.set(false);
                    let obj = {
                        hospitalSingleDoctors: false,
                        hospitalId: props.auth && props.auth.userId && props.auth.userId,
                        page: page,
                        perPage: limit
                    };
                    await props.hospitalActions.getHospitalDoctors(
                        { ...props, obj },
                        props.history
                    );
                    let profileShort = {
                        userId: props.auth.user._id,
                        userType: props.auth.user.userType
                    };
                    await props.actions.getProfile(profileShort, props);
                    getMembers();
                }
            }
        } else {
            message.error('you have reached the limit of adding members');
        }
    };

    const closeModal = () => {
        props.set(false);
        setEmailMessage(null);
        setphoneNoMessage(null);
        setCatMessage(null);
        setfnameMessage(null);
        setlnameMessage(null);
        setPassMessage(null);
        setRoleMessage(null);
        setConfPassMessage(null);

        setMemberInputs({
            username: '',
            firstName: '',
            countryCode: '',
            lastName: '',
            title: '',
            email: '',
            phoneNo: '',
            password: '',
            confirmPass: '',
            hospitalId: '',
            specializedRole: '',
            category: [],
            branch: '',
            hospitalBranches: [],
            services: [],
            featured: false
        });
        // setDisable({
        //   phoneNo: false,
        //   countryCode: false,
        //   email: false,
        //   password: false,
        //   confirmPass: false
        // });
    };

    useEffect(() => {
        if (memberInputs.hospitalBranches && memberInputs.hospitalBranches.length > 0) {
            var arr1 = [...hospitalServices?.map((item) => item._id)];
            var arr2 = [...memberInputs.hospitalBranches];
            let res = arr1.filter((item) => !arr2.includes(item));
            let filtered = hospitalServices?.filter((item) => res.includes(item._id));
            setFilteredServices(filtered);
            if (filtered.length > 0) {
                setMemberInputs({ ...memberInputs, services: [filtered[0]._id] });
            }
        } else {
            setFilteredServices([]);
        }
    }, [memberInputs.hospitalBranches]);

    useEffect(() => {
        if (branches && branches.length > 0 && branches[0]?._id) {
            setMemberInputs({ ...memberInputs, hospitalBranches: [branches[0]?._id] });
        }
    }, [branches, open]);

    if (!open) return null;

    return (
        <Modal
            centered
            className='customModal '
            title={`Add ${memberType}`}
            open={open}
            onOk={() => {
                closeModal();
            }}
            onCancel={() => {
                closeModal();
            }}
            maskClosable={false}
            footer={null}
        >
            <>
                <p style={{ textAlign: 'center', fontSize: 14, marginBottom: 20 }}>
                    *Either Phone number or Email field is required.
                </p>

                <div className='post-modal-options-grid2'>
                    <div className='inputHolder'>
                        <p>Featured:</p>

                        <Radio.Group
                            onChange={(e) => {
                                if (
                                    e.target.value == true &&
                                    hospital?.numOfFeaturedUsers < hospital?.featuredUserLimit
                                ) {
                                    setMemberInputs({ ...memberInputs, featured: e.target.value });
                                } else if (e.target.value == false) {
                                    setMemberInputs({ ...memberInputs, featured: false });
                                }
                            }}
                            value={memberInputs.featured}
                        >
                            <Radio
                                disabled={
                                    hospital?.numOfFeaturedUsers == hospital?.featuredUserLimit
                                }
                                value={true}
                            >
                                Yes
                            </Radio>
                            <Radio value={false}>No</Radio>
                        </Radio.Group>
                    </div>
                </div>
                <div className='post-modal-options-grid2'>
                    <div className='inputHolder'>
                        <p>
                            First Name<span style={{ color: 'var(--primary)' }}>*</span>:
                        </p>

                        <div className='pmodal-name-cat-time'>
                            <input
                                value={memberInputs.firstName}
                                placeholder='First name'
                                name='firstName'
                                onChange={handleInputChange}
                                autoComplete='hackx'
                            />
                        </div>
                        {fnameMessage ? (
                            <p style={{ color: 'red', fontSize: 11 }}>{fnameMessage}</p>
                        ) : null}
                    </div>
                    <div className='inputHolder'>
                        <p>
                            Last Name<span style={{ color: 'var(--primary)' }}>*</span>:
                        </p>
                        <div className='pmodal-name-cat-time'>
                            <input
                                value={memberInputs.lastName}
                                placeholder='Last name'
                                name='lastName'
                                onChange={handleInputChange}
                                autoComplete='hackx'
                            />
                        </div>
                        {lnameMessage ? (
                            <p style={{ color: 'red', fontSize: 11 }}>{lnameMessage}</p>
                        ) : null}
                    </div>
                </div>
                <div className='post-modal-options-grid2'>
                    <div className='inputHolder'>
                        <p>
                            Work Locations<span style={{ color: 'var(--primary)' }}>*</span>:
                        </p>
                        {/* <div className="pmodal-name-cat-time"> */}
                        <Select
                            name='branch'
                            mode='multiple'
                            value={memberInputs.hospitalBranches}
                            style={{ width: '100%', zIndex: 100 }}
                            onChange={handleBranchInput}
                        >
                            {branches &&
                                branches.map((item, index) => (
                                    <Select.Option value={item._id}>
                                        Location {index + 1} -{' '}
                                        {item.location &&
                                            item.location.branchAddress &&
                                            item.location.branchAddress}{' '}
                                        {item.location &&
                                            item.location.branchState &&
                                            item.location.branchState}{' '}
                                        {item.location &&
                                            item.location.branchCity &&
                                            item.location.branchCity}{' '}
                                        {item.location &&
                                            item.location.branchCountry &&
                                            item.location.branchCountry}{' '}
                                    </Select.Option>
                                ))}
                        </Select>
                        {/* </div> */}
                        {branchMessage ? (
                            <p style={{ color: 'red', fontSize: 11 }}>{branchMessage}</p>
                        ) : null}
                    </div>
                </div>
                {memberType !== 'Doctor' && (
                    <div className='post-modal-options-grid2'>
                        <div className='inputHolder'>
                            <p>
                                {memberType} Type <span style={{ color: 'var(--primary)' }}>*</span>
                                :
                            </p>

                            <Select
                                name='type'
                                placeholder={`${memberType} Type`}
                                autoComplete='new-password'
                                autoCorrect='off'
                                style={{ width: '100%', zIndex: 100 }}
                                value={memberInputs.type || undefined}
                                onChange={(value) => {
                                    setMemberInputs({ ...memberInputs, type: value });
                                    if (value) {
                                        setTypeMessage('');
                                    } else {
                                        setTypeMessage(`type is required`);
                                    }
                                }}
                            >
                                {types.map((type) => (
                                    <Option key={type} value={type}>
                                        {type}
                                    </Option>
                                ))}
                            </Select>

                            {typeMessage ? (
                                <p style={{ color: 'red', fontSize: 11 }}>{typeMessage}</p>
                            ) : null}
                        </div>
                    </div>
                )}

                {memberType === 'Nurse' && (
                    <div className='post-modal-options-grid2'>
                        <div className='inputHolder'>
                            <p>
                                BNMC Registration Type{' '}
                                <span style={{ color: 'var(--primary)' }}>*</span>:
                            </p>

                            <Select
                                name='type'
                                placeholder={`${memberType} Type`}
                                autoComplete='new-password'
                                autoCorrect='off'
                                style={{ width: '100%', zIndex: 100 }}
                                value={memberInputs.bnmcRegistrationType || undefined}
                                onChange={(value) => {
                                    setMemberInputs({ ...memberInputs, bnmcRegistrationType: value });
                                    if (value) {
                                        setBnmcRegistrationTypeMessage('');
                                    } else {
                                        setBnmcRegistrationTypeMessage(`type is required`);
                                    }
                                }}
                            >
                                {bnmcRegistrationTypes.map((type) => (
                                    <Option key={type} value={type}>
                                        {type}
                                    </Option>
                                ))}
                            </Select>

                            {bnmcRegistrationTypeMessage ? (
                                <p style={{ color: 'red', fontSize: 11 }}>{typeMessage}</p>
                            ) : null}
                        </div>
                    </div>
                )}

                {memberType === 'Nurse' && (
                    <div className='post-modal-options-grid2'>
                        <div className='inputHolder'>
                            <p>BNMC RegistrationNumber:</p>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    gap: '1rem'
                                }}
                            >
                                <div className='pmodal-name-cat-time2'>
                                    <input
                                        // disabled={disable.email}
                                        placeholder='BNMC Registration Number'
                                        autoComplete='hackx'
                                        name='bnmcRegistrationNumber'
                                        value={memberInputs.bnmcRegistrationNumber}
                                        onChange={handleInputChange}
                                        style={{ width: '100%', backgroundColor: 'white' }}
                                    />
                                </div>
                            </div>
                            {bnmcRegistrationNumberMessage ? (
                                <p style={{ color: 'red', fontSize: 11 }}>
                                    {bnmcRegistrationNumberMessage}
                                </p>
                            ) : null}
                        </div>
                    </div>
                )}
                {memberType === 'Doctor' && (
                    <>
                        <div className='post-modal-options-grid2'>
                            <div className='inputHolder'>
                                <p>
                                    Specialized Roles
                                    <span style={{ color: 'var(--primary)' }}>*</span>:
                                </p>
                                {/* <div className="pmodal-name-cat-time2"> */}
                                <Select
                                    mode='single'
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder='Specialized Role'
                                    value={memberInputs.specializedRole || undefined}
                                    onChange={(val) => {
                                        setMemberInputs({ ...memberInputs, specializedRole: val });
                                        if (val) {
                                            setRoleMessage('');
                                        } else {
                                            setRoleMessage('Specialized role is required');
                                        }
                                    }}
                                >
                                    {specializedRoles?.specializedRoles &&
                                        specializedRoles.specializedRoles?.map((item, index) => (
                                            <Option key={index} value={item}>
                                                {item}
                                            </Option>
                                        ))}
                                </Select>

                                {roleMessage ? (
                                    <p style={{ color: 'red', fontSize: 11 }}>{roleMessage}</p>
                                ) : null}
                            </div>
                        </div>
                        <div className='post-modal-options-grid2'>
                            <div className='inputHolder'>
                                <p>
                                    Practice Areas<span style={{ color: 'var(--primary)' }}>*</span>
                                    :
                                </p>
                                <Select
                                    mode='multiple'
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder='Expertise Category'
                                    value={memberInputs.category}
                                    onChange={onSelectPracticeAreas}
                                >
                                    {practiceAreas?.practiceAreas &&
                                        practiceAreas.practiceAreas?.map((item, index) => (
                                            <Option key={index} value={item}>
                                                {item}
                                            </Option>
                                        ))}
                                </Select>

                                {catMessage ? (
                                    <p style={{ color: 'red', fontSize: 11 }}>{catMessage}</p>
                                ) : null}
                            </div>
                        </div>

                        <div className='post-modal-options-grid2'>
                            <div className='inputHolder'>
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <p>
                                        Services<span style={{ color: 'var(--primary)' }}>*</span>:
                                    </p>
                                    <Tooltip
                                        placement='topLeft'
                                        title={`Expand your service offerings through the 'Management' tab.`}
                                    >
                                        <span
                                            style={{
                                                display: 'flex',
                                                height: 14,
                                                width: 14,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                borderRadius: 8,
                                                fontSize: 11,
                                                backgroundColor: 'lavender'
                                            }}
                                        >
                                            i
                                        </span>
                                    </Tooltip>
                                </div>
                                <Select
                                    mode='multiple'
                                    allowClear
                                    style={{ width: '100%' }}
                                    placeholder='Select Services'
                                    value={memberInputs.services}
                                    onChange={(val) =>
                                        setMemberInputs({ ...memberInputs, services: val })
                                    }
                                >
                                    {filteredServices &&
                                        filteredServices.map((item, index) => (
                                            <Option key={index} value={item._id}>
                                                {item.name}
                                            </Option>
                                        ))}
                                </Select>
                            </div>
                        </div>
                    </>
                )}

                <div className='post-modal-options-grid2'>
                    <div className='inputHolder'>
                        <p>Email:</p>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '1rem'
                            }}
                        >
                            <div className='pmodal-name-cat-time2'>
                                <input
                                    // disabled={disable.email}
                                    placeholder='Email'
                                    autoComplete='hackx'
                                    name='email'
                                    value={memberInputs.email}
                                    onChange={handleInputChange}
                                    style={{ width: '100%', backgroundColor: 'white' }}
                                />
                            </div>
                        </div>
                        {emailMessage ? (
                            <p style={{ color: 'red', fontSize: 11 }}>{emailMessage}</p>
                        ) : null}
                    </div>
                </div>
                <div className='post-modal-options-grid2'>
                    <div className='inputHolder'>
                        <p>Country Code:</p>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '1rem'
                            }}
                        >
                            <div className='pmodal-name-cat-time3'>
                                <PhoneInput
                                    inputStyle={{
                                        width: '100% !important',
                                        padding: '0px 0px !important'
                                    }}
                                    style={{ width: '6rem' }}
                                    // country={"us"}
                                    value={countryCode.value}
                                    placeholder='Country Code'
                                    onChange={(code) =>
                                        setCountryCode({ ...countryCode, value: code })
                                    }
                                />
                            </div>
                        </div>
                        {countryCodeMessage ? (
                            <p style={{ color: 'red', fontSize: 11 }}>{countryCodeMessage}</p>
                        ) : null}
                    </div>

                    <div className='inputHolder'>
                        <p>Phone no.:</p>
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                gap: '1rem'
                            }}
                        >
                            <div className='pmodal-name-cat-time'>
                                <input
                                    autoComplete='hackx'
                                    className='doctor-phoneNo-input'
                                    placeholder='Phone no.'
                                    name='phoneNo'
                                    value={memberInputs.phoneNo}
                                    onChange={handleInputChange}
                                />
                            </div>
                        </div>
                        {phoneNoMessage ? (
                            <p style={{ color: 'red', fontSize: 11 }}>{phoneNoMessage}</p>
                        ) : null}
                    </div>
                </div>

                <div className='post-modal-options-grid2'>
                    <div className='inputHolder'>
                        <p>Password:</p>
                        <div className='pmodal-name-cat-time'>
                            <input
                                disabled={disable.password}
                                placeholder='Password'
                                name='password'
                                value={memberInputs.password}
                                onChange={handleInputChange}
                            />
                        </div>
                        {passwordMessage ? (
                            <p style={{ color: 'red', fontSize: 11 }}>{passwordMessage}</p>
                        ) : null}
                    </div>
                    <div className='inputHolder'>
                        <p>Confirm Password:</p>
                        <div className='pmodal-name-cat-time'>
                            <input
                                disabled={disable.confirmPass}
                                placeholder='Confirm password'
                                name='confirmPass'
                                value={memberInputs.confirmPass}
                                onChange={handleInputChange}
                            />
                        </div>
                        {confPassMessage ? (
                            <p style={{ color: 'red', fontSize: 11 }}>{confPassMessage}</p>
                        ) : null}
                    </div>

                    {errorMessage ? (
                        <p style={{ color: 'red', fontSize: 11 }}>{errorMessage}</p>
                    ) : null}
                </div>
                <div className='postmodal-btn' onClick={onSumbitInputs}>
                    <p>Add</p>
                </div>
            </>
        </Modal>
    );
};
function mapStateToProps(state, ownProps) {
    return {
        auth: state.auth,
        profile: state.auth.userProfile,
        general: state.general,
        hospitalServices: state.general && state.general.hospitalServices
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(userActions, dispatch),
        hospitalActions: bindActionCreators(hospitalActions, dispatch)
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(CreateMember);
